<app-modal #modal [modalConfig]="modalConfig">
  <form [formGroup]="contactPersonForm">
    <div class="ms-6 mt-5">
      <img src="../../../../assets/media/logos/CONTACT_PERSON_ADD.png" />
    </div>
    <div class="row mt-2 ms-2">
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder required">{{ 'FIRST_NAME' | translate }}</label>
        <input
          type="text"
          class="form-control form-control-lg form-control-solid ng-pristine ng-invalid ng-touched"
          value=""
          formControlName="firstName"
          [ngClass]="{ 'is-invalid': submit && contactPersonForm.controls['firstName']?.errors?.required }"
        />
        <div *ngIf="submit && contactPersonForm.controls['firstName']?.errors?.required" class="invalid-feedback">
          {{ 'ENTRY_IS_REQUIRED' | translate }}
        </div>
      </div>
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder"> {{ 'LAST_NAME' | translate }}</label>
        <input
          type="text"
          class="form-control form-control-lg form-control-solid ng-pristine ng-invalid ng-touched"
          value=""
          formControlName="lastName"
        />
      </div>
    </div>
    <div class="colWidth mt-5 ms-5">
      <label class="form-label fs-6 fw-bolder"> {{ 'COMMENT' | translate }}</label>
      <input
        type="text"
        class="form-control form-control-lg form-control-solid ng-pristine ng-invalid ng-touched"
        value=""
        formControlName="comment"
      />
    </div>
    <div class="colWidth mt-5 ms-5">
      <label class="form-label fs-6 fw-bolder"> {{ 'E_MAIL' | translate }}</label>
      <input
        type="text"
        class="form-control form-control-lg form-control-solid ng-pristine ng-invalid ng-touched"
        value=""
        formControlName="email"
      />
    </div>
    <div class="colWidth mt-5 ms-5 mb-10">
      <label class="form-label fs-6 fw-bolder"> {{ 'PHONE_NUMBER' | translate }}</label>
      <input
        type="text"
        class="form-control form-control-lg form-control-solid ng-pristine ng-invalid ng-touched"
        value=""
        formControlName="phoneNumber"
      />
    </div>
  </form>
</app-modal>
