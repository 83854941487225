<div class="d-flex">
  <div class="container rounded mt-5">
    <app-error-modal #errorModal [modalConfig]="modalConfig"></app-error-modal>
    <app-success-modal #successModal [modalConfig]="modalConfig"></app-success-modal>
    <div class="card">
      <div class="collapse show">
        <div class="card-body border-top">
          <app-button
            [title]="'BACK' | translate"
            *ngIf="id !== loggedInUser.id!"
            buttonType="link"
            size="large"
            prefixIcon="assets/media/icons/duotune/arrows/arr074.svg"
            (handleClick)="back($event)"
          ></app-button>
          <div class="d-flex flex-wrap align-items-center">
            <div id="kt_signin_password_edit" class="flex-row-fluid false" *ngIf="dataFetched">
              <form [formGroup]="updateUserForm" (ngSubmit)="onSubmit()">
                <div class="d-flex flex-wrap align-items-center justify-content-between">
                  <div class="col-md-3 mt-6 mb-8">
                    <app-user-profile-image [form]="userDetailsForm"></app-user-profile-image>
                  </div>
                  <div class="d-flex flex-column flex-wrap min-width">
                    <label class="form-label fs-6 fw-bolder ms-2 mb-4">{{ 'STANDARD_LANGUAGE' | translate }}</label>
                    <app-select
                      [options]="languages"
                      type="primary"
                      size="large"
                      (statusSelectChange)="getLanguage($event)"
                      [selectedValue]="userDetailsForm.get('defaultLanguage')?.value"
                    >
                    </app-select>
                  </div>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                  <div class="flex-row w-100">
                    <app-user-personal-data
                      [form]="userDetailsForm"
                      [submitted]="submitted"
                      [update]="true"
                    ></app-user-personal-data>
                  </div>
                </div>
                <div class="d-flex flex-wrap align-items-center w-100">
                  <div class="flex-row w-100 mt-5 ms-2 me-1">
                    <app-knowledge [form]="expertiseProjectsForm"></app-knowledge>
                  </div>
                </div>
                <div class="d-flex flex-wrap align-items-center w-100 mt-5 ms-2">
                  <div class="flex-row w-100 me-4">
                    <app-spoken-languages [form]="personalProfileForm" [submitted]="submitted"></app-spoken-languages>
                  </div>
                </div>
                <div class="reset_password_div ms-2">
                  <div class="mt-6 mb-8" *ngIf="id === loggedInUser.id!">
                    <button type="submit" class="btn btn-primary" (click)="updatePassword()">
                      {{ 'RESET_PASSWORD' | translate }}
                    </button>
                  </div>
                  <ng-container *ngIf="id !== loggedInUser.id!">
                    <ng-container *ngIf="authService.currentUserRole === 'PROJECT_ADMIN'">
                      <div class="mt-6 mb-8">
                        <button
                          *ngIf="!user.archived"
                          type="submit"
                          class="btn btn-primary action-button"
                          (click)="archiveUser()"
                        >
                          {{ 'DEACTIVATE_USER' | translate }}
                        </button>
                        <button
                          *ngIf="user.archived"
                          type="submit"
                          class="btn btn-primary action-button"
                          (click)="archiveUser()"
                        >
                          {{ 'ACTIVATE_USER' | translate }}
                        </button>
                        <button
                          *ngIf="user.archived"
                          type="submit"
                          class="btn btn-primary action-button"
                          (click)="activationMail(id)"
                        >
                          {{ 'RE_SEND_ACTIVATION_EMAIL' | translate }}
                        </button>
                        <button *ngIf="!activeUser" type="submit" class="btn btn-primary action-button">
                          {{ 'SAVE' | translate }}
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                  <div class="mt-6 mb-8 user_save_button me-4" *ngIf="activeUser">
                    <button type="submit" class="btn btn-primary">{{ 'SAVE' | translate }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5" *ngIf="!showCards">
    <div class="mb-5">
      <app-chart-widget
        [description]="'ASSIGNED_PROJECTS' | translate"
        labelColor="dark"
        textColor="gray-300"
        [label1]="managingDescription"
        [label2]="memberOfDescription"
        [dashboardInfo]="userDashboardInfo$"
        [chartSize]="100"
        [userId]="user.id!"
        [isFromUser]="true"
      ></app-chart-widget>
    </div>
    <div class="mb-5">
      <app-dashboard-widget
        [description]="'LAST_LOGIN' | translate | titlecase"
        [isFirstCard]="true"
        [stats]="userDashboardInfo?.lastLogin"
        labelColor="dark"
        textColor="gray-300"
        [showDescriptionDate]="true"
        [dashboardInfo]="userDashboardInfo$"
        [isFromUser]="true"
      ></app-dashboard-widget>
    </div>
    <div class="mb-5">
      <app-dashboard-widget
        [description]="'YOUR_LAST_TIMEBOOKING' | translate"
        [isFirstCard]="false"
        [stats]="userDashboardInfo?.lastTimeSheetEntry"
        labelColor="dark"
        textColor="gray-300"
        [showDescriptionStringSecond]="true"
        [dashboardInfo]="userDashboardInfo$"
        [isFromUser]="true"
      ></app-dashboard-widget>
    </div>
  </div>
</div>
