import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { map, Observable } from 'rxjs'
import { ApiResponse } from '../dto/ApiResponse'
import { Task } from '../model/task.model'
import { TaskFlexC } from '../model/flexc-task.model'

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private taskUrl: string = environment.apiBase + '/tasks'

  constructor(private http: HttpClient) {}

  public getTaskByProjectId(projectId: string): Observable<Task[]> {
    return this.http
      .get<ApiResponse<Task[]>>(this.taskUrl + '/get-all-by-project/' + projectId)
      .pipe(map((res: ApiResponse<Task[]>) => res.content))
  }

  public getAllCompanyAndProjectTasks(projectId: string) {
    return this.http.get(this.taskUrl + '/get-all-from-company-and-by-project/' + projectId)
  }

  public getCompanyTask(): Observable<ApiResponse<TaskFlexC[]>> {
    return this.http.get<ApiResponse<TaskFlexC[]>>(this.taskUrl + '/get-all-company-tasks-with-no-project')
  }

  public getAllCompanyTask(): Observable<ApiResponse<TaskFlexC[]>> {
    return this.http.get<ApiResponse<TaskFlexC[]>>(this.taskUrl + '/get-all-company-tasks-with-project')
  }

  public newTaskEntry(body: any): Observable<ApiResponse<TaskFlexC>> {
    return this.http.post<ApiResponse<TaskFlexC>>(this.taskUrl + '/create-task', body)
  }

  public deleteTask(taskId: number): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(this.taskUrl + '/delete-task/' + taskId)
  }

  public unarchiveTask(taskId: number): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(this.taskUrl + '/unarchive-task/' + taskId)
  }

  public getTaskByProjectIdUnarchived(projectId: number): Observable<Task[]> {
    return this.http
      .get<ApiResponse<Task[]>>(this.taskUrl + '/get-all-by-project-unarchived/' + projectId)
      .pipe(map((res: ApiResponse<Task[]>) => res.response))
  }

  public filterCompanyTasks(
    path: string,
    page: number,
    size: number,
    sortBy: string,
    sortDirection?: string,
    search?: any
  ): Observable<ApiResponse<TaskFlexC[]>> {
    let paramMap: HttpParams = new HttpParams()
      .set('page', page.toString())
      .append('size', size.toString())
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))

    if (search && search !== '') {
      paramMap = paramMap.append('searchInput', search)
    }

    const url = `${this.taskUrl}/${path}`

    return this.http.get<ApiResponse<TaskFlexC[]>>(url, { params: paramMap })
  }
}
