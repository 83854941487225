import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: any[], isTimesheetDescription: boolean = false): string {
    if (value == undefined) {
      return ''
    }
    if (!isTimesheetDescription) {
      let width = window.innerWidth
      var limit = args.length > 0 ? parseInt(args[0], 10) : 31
      if (width < 480 || (width > 765 && width < 1200)) {
        limit = 20
      }
      const indexOfLastWordWithSpace = value.lastIndexOf(' ')
      const indexOfLastWordWithComa = value.lastIndexOf(',')
      const trail = args.length > 1 ? args[1] : '...'
      if (indexOfLastWordWithSpace != -1) {
        return value.length > limit ? this.createValue(value.split(' '), limit) + trail : value
      } else if (indexOfLastWordWithComa != -1) {
        return value.length > limit ? this.createValue(value.split(','), limit) + trail : value
      } else {
        return value.length > limit ? value.substring(0, limit) + trail : value
      }
    } else {
      let finalText = ''
      let startingIndex = 0
      let endIndex = 50
      if (value.length > 50) {
        // for (let index = 0; index < 5; index++) {
        //   finalText += value.substring(startingIndex, endIndex) + (index + 1 === 5 ? '' : '<br>')
        //   startingIndex = endIndex
        //   endIndex = 50 * (index + 2)
        // }
        let truncatedText = value.substring(0, 250)
        let lines = this.splitTextIntoLines(truncatedText, 50)
        if (lines.length > 5) {
          lines = lines.slice(0, 5)
        }
        finalText = lines.join('<br>')
      } else {
        finalText += value
      }
      return value.length > 250 ? finalText + '...' : finalText
    }
  }

  private createValue(words: string[], limit: number) {
    let value = ''
    let i = 0
    while (i < words.length && value.length + words[i].length <= limit) {
      value += words[i++] + ' '
    }
    return value
  }

  private splitTextIntoLines(text: string, charsPerLine: number): string[] {
    const words = text.split(/(?<=\s|,|\.)/)
    let lines = []
    let currentLine = ''

    for (const word of words) {
      if ((currentLine + word).length <= charsPerLine) {
        currentLine += word
      } else {
        lines.push(currentLine.trim())
        currentLine = word
      }
    }

    if (currentLine.length > 0) {
      lines.push(currentLine.trim())
    }

    return lines
  }
}
