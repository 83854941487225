import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LanguageSwitcherComponent } from './language-switcher.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzButtonModule } from 'ng-zorro-antd/button'

@NgModule({
  declarations: [LanguageSwitcherComponent],
  imports: [CommonModule, NgbModule, NzDropDownModule, NzButtonModule],
  exports: [LanguageSwitcherComponent],
})
export class LanguageSwitcherModule {}
