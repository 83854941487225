import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslationModule } from 'app/modules/i18n'

@Component({
  selector: 'app-no-fetch-date',
  standalone: true,
  imports: [CommonModule, NgbModule, TranslationModule],
  templateUrl: './no-fetch-date.component.html',
  styleUrls: ['./no-fetch-date.component.scss'],
})
export class NoFetchDateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
