import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component, Input, Output, Provider, forwardRef } from '@angular/core'
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NzSelectModule, NzSelectSizeType } from 'ng-zorro-antd/select'
import { NzDividerModule } from 'ng-zorro-antd/divider'

import { EventEmitter } from '@angular/core'
import { LocalizedDatePipe } from 'app/utility/localized-date.pipe'
import { ModalComponent } from '../modal/modal.component'
import { BehaviorSubject } from 'rxjs'
import { TranslateModule } from '@ngx-translate/core'

export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectWithNewComponent),
  multi: true,
}

@Component({
  standalone: true,
  selector: 'app-select-with-new',
  imports: [
    CommonModule,
    NzSelectModule,
    NzDividerModule,
    FormsModule,
    InlineSVGModule,
    LocalizedDatePipe,
    ModalComponent,
    TranslateModule,
  ],
  templateUrl: './select-with-new.component.html',
  styleUrls: ['./select-with-new.component.scss'],
  providers: [WRAPPER_CONTROL_VALUE_ACCESSOR],
})
export class SelectWithNewComponent {
  @Input() size: NzSelectSizeType = 'default'
  @Input() options: any
  @Input() selectedValue: any
  @Input() type: string
  @Input() placeholder: string = ''
  @Input() allowClear: boolean = false
  @Input() showSearch: boolean = true
  @Input() label: any
  @Input() inputDisable: boolean = false
  @Input() isTimeSheetMonths: boolean = false
  @Input() isCustomers: boolean = false
  @Input() addNewOptions: any
  @Output() statusSelectChange = new EventEmitter()

  openModalSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)

  isOpenSelect: boolean = false

  constructor(private cdr: ChangeDetectorRef) {}

  public modelChange(event: any) {
    this.statusSelectChange.emit(event)
  }

  compareFn = (o1: any, o2: any): boolean => {
    if (!this.isTimeSheetMonths) {
      if (o1 && o2) {
        if (typeof o1 === 'object' && typeof o2 === 'object') {
          if (o1.id && o2.id) {
            return o1.id === o2.id
          } else {
            return o1.value === o2.value
          }
        }
        return o1 === o2
      }
      return false
    } else {
      return o1[0] !== undefined && o2 !== undefined ? o1[0]?.toISOString() === o2?.toISOString() : o1 === o2
    }
  }

  isDateType(option: any): boolean {
    return option instanceof Date
  }

  onOpenChange(event: any) {
    this.isOpenSelect = event
  }

  openAddNewModal() {
    //close dropdown before creating modal
    this.isOpenSelect = false
    this.openModalSubject.next(true)
  }

  addNew(event: any) {
    if (event) {
      event = {
        ...event,
        label: event.name,
      }
      if (!this.options) {
        this.options = new Array()
      }
      this.options.push(event)
      this.options = this.options.sort((a: any, b: any) => a.label!.toLowerCase().localeCompare(b.label!.toLowerCase()))
      this.selectedValue = event
      this.statusSelectChange.emit(event)
    }
  }
}
