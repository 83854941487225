const role_array: any[] = [
  {
    id: '4e2b0f7c-c23a-46a8-a30a-f6630a628c3d',
    name: 'ADMIN',
    label: 'ADMIN',
    value: '4e2b0f7c-c23a-46a8-a30a-f6630a628c3d',
  },
  {
    id: 'ed8cf9a1-1d58-4304-b577-5303fa9509da',
    name: 'BACKOFFICE',
    label: 'BACKOFFICE',
    value: 'ed8cf9a1-1d58-4304-b577-5303fa9509da',
  },
  {
    id: '6a6801e6-acc9-430c-a419-6a45875cd0f7',
    name: 'NORMAL_USER',
    label: 'NORMAL_USER',
    value: '6a6801e6-acc9-430c-a419-6a45875cd0f7',
  },
]
export { role_array }
