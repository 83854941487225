import { CustomerService } from './../../core/services/customer.service'
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Customer } from 'app/pages/core/model/flexc-customer.model'
import { SharedService } from '../shared.service'
import { ContactPeron } from 'app/pages/core/model/contact-person.model'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter } from 'rxjs'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { AuthService } from 'app/modules/auth'
import { Address } from 'app/pages/core/model/address.model'
import { environment } from 'environments/environment'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'
import { UserImageSharedService } from 'app/pages/users/user-image-shared.service'
import { DynamicContentService } from 'app/pages/core/services/dynamic-content.service'
import { COUNTRY_LIST } from 'app/utility/country-list'

@Component({
  selector: 'app-createcustomer',
  templateUrl: './createcustomer.component.html',
  styleUrls: ['./createcustomer.component.scss'],
})
export class CreateCustomerComponent implements OnInit {
  @Input() isModal: boolean = false

  @ViewChild('modal') private modalComponent: ModalComponent
  @ViewChild('modalSuccess') private modalComponentSucess: ModalComponent
  @ViewChild('modalError') private modalComponentError: ModalComponent
  openModalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  contactPersonSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined)
  searchValue$ = new BehaviorSubject<string>('')

  countriesList: string[] = Object.keys(COUNTRY_LIST).map(countryKey => COUNTRY_LIST[countryKey].countryName)
  separateDialCode = false
  contactPersonModal: ContactPeron
  roleBackoffice: boolean = false
  hasContactPeople: boolean = false
  modify: boolean = false
  cust: boolean = false
  contactPersonClicked = false
  imageSrc: string = ''
  validateForm: boolean = false
  id: string = ''
  addressId: number = 0
  updateCust: boolean = false
  archived: boolean = true
  customer: Customer
  customerNameError: boolean = false
  customerEndNameError: boolean = false
  customerId?: string
  image?: File

  get contactPerson() {
    return this.customerForm.get('contactPerson') as FormArray
  }

  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>('')
  modalBodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')
  showFirstForm: boolean = true
  object: Customer = {
    id: '',
    name: '',
    endName: '',
    comment: '',
    address: {
      state: '',
      city: '',
      postcode: '',
      streetName: '',
      streetNumber: '',
    },
    archived: false,
    contactPeople: [],
  }
  contactPersonobject: any = {
    firstName: '',
    email: '',
    phoneNumber: '',
    endCustomer: '',
    leadOwner: '',
    comment: '',
    archived: 0,
    customer: {
      id: 0,
    },
  }
  contactPeople: ContactPeron[] = []

  modalConfig: ModalConfig = {
    newModalTitle: this.titleForModal,
    modalBodyTitle: this.modalBodyTitle,
    dismissButtonLabel: 'No',
    closeButtonLabel: 'Yes',
    onClose: () => {
      return true
    },
    onDismiss: () => {
      return true
    },
  }

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    public SharedService: SharedService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private _router: Router,
    public authService: AuthService,
    private querySharedService: QuerySharedService,
    private imageSharedService: UserImageSharedService,
    private dynamicContentService: DynamicContentService
  ) {}

  customerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    endName: new FormControl(''),
    comment: new FormControl(''),
    image: new FormControl(),
    phoneNumber: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    postcode: new FormControl(''),
    streetName: new FormControl(''),
    streetNumber: new FormControl(''),
    contactPerson: this.fb.array([]),
  })

  ngOnInit(): void {
    if (this.route.snapshot.routeConfig?.path === 'update-customer/:id') {
      this.id = this.route.snapshot.paramMap.get('id')!
      if (this.id != null) {
        this.customerService.getOneCustomer(this.id).subscribe((res: ApiResponse<Customer>) => {
          this.customer = res.response
          this.updateCust = true
          this.customerForm.get('name')?.setValue(res.response.name!)
          this.customerForm.get('endName')?.setValue(res.response.endName!)
          this.customerForm.get('comment')?.setValue(res.response.comment!)
          this.customerForm.get('state')?.setValue(res.response.address.state)
          this.customerForm.get('city')?.setValue(res.response.address.city)
          this.customerForm.get('postcode')?.setValue(res.response.address.postcode)
          this.customerForm.get('streetName')?.setValue(res.response.address.streetName)
          this.customerForm.get('streetNumber')?.setValue(res.response.address.streetNumber)
          this.imageSrc = res.response.imageUrl
            ? `${environment.imgUrl}${res.response.imageUrl}`
            : 'assets/media/logos/ICON_COMPANY.svg'
          this.object.archived = res.response.archived
          this.archived = res.response.archived!
          this.addressId = res.response.address.id!
          if (res.response) {
            this.hasContactPeople = true
          }
          this.contactPeople = res.response.contactPeople
          this.customerButtonDisabled()
        })
      }
    }
    this.searchCleanup()
  }

  get formControls() {
    return this.customerForm.controls
  }

  changeCountry(country: string) {
    this.customerForm.get('state')?.setValue(country)
  }

  onSubmit() {
    this.validateForm = true
    this.customerEndNameError = false

    if (this.customerForm.valid && !this.customerNameError) {
      const customer = this.createCustomerObject()

      if (this.updateCust) {
        this.validateForm = false

        if (this.contactPerson?.length) {
          this.createContactPerson()
        }

        this.createCustomerObject()
        let address: Address
        address = customer.address
        address.id = this.addressId

        customer.id = this.id
        customer.address.id = this.addressId

        this.customerService.updateCustomer(customer).subscribe({
          next: ({ response }: ApiResponse<Customer>) => {
            if (response.id && customer.address) {
              this.updateAddress(customer.address)
            }

            if (!this.imageSharedService.getImageFile()) {
              this.removeImage()
            } else if (this.SharedService.getImageFile()) {
              this.customerService.uploadImage(customer.id!).subscribe((res: ApiResponse<Customer>) => {
                if (res.response.id) {
                  this.handleSuccess('CUSTOMER_UPDATED_SUCCESSFULLY')
                } else {
                  this.handleError('CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG')
                }
              })
            }
          },
          error: (error: any) => {
            this.handleUpdateError(error)
          },
        })
      } else {
        this.updateCustomerHttp(customer)
      }
    }
  }

  updateCustomerHttp(customer: any) {
    this.customerService.createCustomer(customer).subscribe({
      next: ({ response }: ApiResponse<Customer>) => {
        this.isModal && this.dynamicContentService.setResult(response)
        if (response?.id && this.SharedService.getImageFile()) {
          this.uploadImage(response?.id)
        } else {
          const isSuccess = !!response?.id
          this.handleCustomerResponse(
            isSuccess,
            'CUSTOMER_CREATED_SUCCESSFULLY',
            'CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG'
          )
        }
      },
      error: (error: any) => {
        this.handleUpdateError(error)
      },
    })
  }

  createCustomerObject(): Customer {
    const customer: Customer = {
      name: this.customerForm.get('name')?.value || '',
      endName: this.customerForm.get('endName')?.value || null,
      comment: this.customerForm.get('comment')?.value || '',
      image: this.customerForm.get('image')?.value || '',
      address: {
        state: this.customerForm.get('state')?.value || '',
        city: this.customerForm.get('city')?.value || '',
        postcode: this.customerForm.get('postcode')?.value || '',
        streetName: this.customerForm.get('streetName')?.value || '',
        streetNumber: this.customerForm.get('streetNumber')?.value || '',
      },
      contactPeople: this.contactPerson.value,
    }

    return customer
  }

  setContactPeople() {
    if (this.route.snapshot.routeConfig!.path === 'update-customer/:id') {
      this.contactPeople = []
      let id = this.route.snapshot.paramMap.get('id')
      if (id != null) {
        this.customerService.getAllContactPeople(id).subscribe((res: ApiResponse<ContactPeron[]>) => {
          this.updateCust = true
          if (res.response) {
            this.contactPeople = res.response
            this.hasContactPeople = true
          }
        })
      }
    }
  }

  showContactPersonInput() {
    const contactPerson = this.fb.group({
      firstName: new FormControl('', Validators.required),
      email: new FormControl(''),
      phoneNumber: new FormControl(''),
      lastName: new FormControl(''),
      comment: new FormControl(''),
    })
    this.contactPerson.push(contactPerson)
  }

  createContactPerson() {
    if (!this.id?.length) {
      this.onSubmit()
    }

    for (let i = 0; i < this.contactPerson.value?.length; i++) {
      let contactPersonobject: ContactPeron
      contactPersonobject = this.contactPerson.value[i]
      contactPersonobject.customer = this.customer
      this.customerService.createContactPerson(contactPersonobject).subscribe((response: ApiResponse<ContactPeron>) => {
        if (response.response.id) {
          this.ngOnInit()
        } else {
          this.openModalError()
        }
      })
    }
  }

  readURL(event: any) {
    const file = (event.target as HTMLInputElement).files![0]
    this.SharedService.setImageFile(file)
    this.imageSharedService.setImageFile(file)
    const reader = new FileReader()
    reader.onload = () => {
      this.imageSrc = reader.result as string
      this.customerForm.get('image')?.setValue(file)
    }
    reader.readAsDataURL(file)
  }

  async openModal() {
    return await this.modalComponent.open()
  }

  async openModalSuccess() {
    return await this.modalComponentSucess.open()
  }

  async openModalError() {
    return await this.modalComponentError.open()
  }

  customerButtonDisabled() {
    if (this.customerForm.invalid || this.authService.currentUserRole == 'PROJECT_BACKOFFICE') {
      this.roleBackoffice = true
    }
  }

  send(person: ContactPeron) {
    this.contactPeople.forEach(element => {
      if (element.id === person.id) {
        this.contactPersonSubject.next(person)
        this.openModalSubject.next(true)
      }
    })
  }

  saveFinished(event: any) {
    if (event) {
      this.setContactPeople()
    }
  }

  updateAddress(address: Address): void {
    this.customerService.updateAddress(address).subscribe({
      next: (response: ApiResponse<Address>) => {
        if (!this.SharedService.getImageFile()) {
          response.response.id && !this.SharedService.getImageFile()
            ? null
            : this.handleError('CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG')
        }
      },
      error: (error: any) => {
        this.handleUpdateError(error)
      },
    })
  }

  handleUpdateError(error: any): void {
    if (error?.error?.message === 'Customer name must be unique') {
      this.customerNameError = true
    } else if (error?.error?.message === 'Customer end name must be unique') {
      this.customerEndNameError = true
    } else {
      this.handleError('CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG')
    }
  }

  handleSuccess(message: string): void {
    this.validateForm = false
    this.modalBodyTitle.next(this.translate.instant(message))
    this.openModalSuccess()
    !this.isModal && this._router.navigate(['/customer/customer-list'])
  }

  handleError(message: string): void {
    this.validateForm = false
    !this.isModal && this._router.navigate(['/customer/customer-list'])
    this.modalBodyTitle.next(this.translate.instant(message))
    this.openModalError()
  }

  handleCustomerResponse(isSuccess: boolean, successMessage: string, errorMessage: string): void {
    this.customerForm.reset()
    !this.isModal && this._router.navigate(['/customer/customer-list'])
    this.modalBodyTitle.next(this.translate.instant(isSuccess ? successMessage : errorMessage))
    this.openModalSuccess()
  }

  uploadImage(customerId: string) {
    this.customerService.uploadImage(customerId).subscribe((res: ApiResponse<Customer>) => {
      const isSuccess = !!res.response?.id
      this.handleCustomerResponse(
        isSuccess,
        'CUSTOMER_CREATED_SUCCESSFULLY',
        'CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG'
      )
    })
  }

  back(event: any) {
    let query = this.querySharedService.getQueryForRoute('customer/customer-list')
    this._router.navigate(['customer/customer-list'], {
      queryParams: query,
      queryParamsHandling: 'merge',
    })
  }

  removeContactPerson(i: number) {
    this.contactPerson.removeAt(i)
  }

  setImageToDefault() {
    this.imageSrc = 'assets/media/logos/ICON_COMPANY.svg'
    this.imageSharedService.setImageFile(undefined)
  }

  removeImage() {
    if (!this.customer.id || !this.imageSrc) {
      return
    }
    this.customerService.deleteCustomerImage(this.customer.id!).subscribe({
      next: res => {
        if (res) {
          this.handleSuccess('CUSTOMER_UPDATED_SUCCESSFULLY')
        } else {
          this.handleError('CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG')
        }
      },
    })
  }

  checkCustomerName(event: any) {
    this.searchValue$.next(event.target.value)
  }

  searchCleanup() {
    this.searchValue$
      .pipe(
        filter(val => val !== ''),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((res: any) => {
        this.chekcIfCustomerNameIsTakenHttp(res)
      })
  }

  chekcIfCustomerNameIsTakenHttp(name: string) {
    this.customerService.checkIfCustomerNameIsTaken(name).subscribe((res: ApiResponse<boolean>) => {
      this.customerNameError = res.response
    })
  }
}
