import { Injectable } from '@angular/core'
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public Toast: any

  public showSuccess(title: string) {
    Swal.fire({
      position: 'top-right',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: 1500,
      toast: true,
    })
  }

  public showWarning(title: string) {
    Swal.fire({
      position: 'top-right',
      icon: 'warning',
      title: title,
      showConfirmButton: false,
      timer: 1500,
      toast: true,
    })
  }
}
