<div class="card-body border-top p-9 w-900">
  <div class="d-flex flex-wrap align-items-center">
    <div class="wraper">
      <span>{{ 'ALLOCATE_AND_FINISH' | translate }}</span>
      <p>{{ 'ALLOCATE_AND_FINISH' | translate }}</p>
      <div class="d-flex justify-content-center md-10 mt-9">
        <div class="workingDaysWraper">
          <div
            class="progress-bar-custom"
            role="progressbar"
            style="position: absolute; background-color: #1bc5bd; height: 100%; border-radius: 20px"
            [style.width]="procentageProgresBar + '%'"
          ></div>
          <p class="text">{{ 'TOTAL_WORKING_DAYS' | translate }}</p>
          <p class="text">
            {{ this.planedDays }} {{ 'OF' | translate }} {{ this.totalWorkingDays | number : '1.0-0' }}
            {{ 'DAYS' | translate }}
          </p>
        </div>
      </div>
      <div *ngIf="greather" class="invalid-feedback d-block">
        {{ 'PLANED_DAYS_GREATHER_THAN_TOTAL_WORKING_DAYS' | translate }}
      </div>
      <div class="tableWraper">
        <div class="table-responsive">
          <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr class="fw-bolder text-muted">
                <th class="min-w-150px">{{ 'STAFF' | translate }}</th>
                <th class="min-w-120px">{{ 'PLANNED' | translate }}</th>
                <th class="min-w-120px">{{ 'MANAGER' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let worker of projectMembers; let i = index">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="imgFormatting">
                      <img [src]="worker?.user?.imageUrl || 'assets/media/logos/ICON_USER.svg'" class="profile-image" />
                    </div>
                    <div class="d-flex justify-content-start flex-column">
                      <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">
                        {{ worker?.user?.firstName }} {{ worker?.user?.lastName }}
                      </a>
                      <span class="text-muted fw-bold text-muted d-block fs-7">
                        {{ worker?.user?.portfolio?.jobTitle }}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    class="plannedDays"
                    value="{{ worker.allocation ?? 0 }}"
                    [tabindex]="i * 3 + 1"
                    (change)="calculateDays($event, worker.user)"
                  />
                </td>
                <td>
                  <div class="form-check form-check-sm form-check-custom form-check-solid">
                    <input class="form-check-input widget-9-check" type="checkbox" (click)="checkBoxChange(worker)" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between" style="width: 100%">
      <div>
        <app-button [title]="'BACK' | translate" (handleClick)="goBack()"></app-button>
      </div>
      <div>
        <button class="btn btn-primary-save me-5" style="min-width: 75px" (click)="updateProject()">
          {{ 'SAVE_DRAFT' | translate }}
        </button>
        <button class="btn btn-primary-project" style="border: 1px solid #1bc5bd" (click)="updateProject(true)">
          {{ 'START_PROJECT' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
