<div class="card-header border-0 d-flex">
  <div class="row w-100">
    <app-button
      [title]="
        this.isFromMyProjects
          ? ('BACK_TO_MY_PROJECTS' | translate | lowercase)
          : ('BACK_TO_PROJECTS' | translate | lowercase)
      "
      buttonType="link"
      size="large"
      prefixIcon="assets/media/icons/duotune/arrows/arr074.svg"
      (handleClick)="back()"
    >
    </app-button>

    <div class="d-flex align-items-center flexClass">
      <div class="d-flex project-info-details">
        <img [src]="imgSrc || 'assets/media/logos/ICON_PROJECT.svg'" alt="Project Image" />
        <h1 class="card-title flex-column me-5 ms-5">
          <span class="fw-bold text-dark">{{ project?.name }}</span>
          <span class="project-description">{{ project?.description }}</span>
        </h1>
      </div>
      <div class="edit_status_button">
        <div *ngIf="this.authService.currentUserRole === 'PROJECT_ADMIN'" class="text-center d-flex">
          <button type="submit" class="btn btn-primary btn-hover" (click)="editProject()">
            <span>{{ 'EDIT_PROJECT' | translate }}</span>
          </button>
        </div>
        <div *ngIf="authService.currentUserRole" class="colorBtn" ngbDropdown #myDrop="ngbDropdown" name="dropdown">
          <button
            type="button"
            class="button"
            id="dropdownManual"
            ngbDropdownAnchor
            (focus)="myDrop.open()"
            [disabled]="authService.currentUserRole !== 'PROJECT_ADMIN'"
          >
            <span class="status_name">{{ (project?.projectStatus)!.name | translate }}</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownManual">
            <button ngbDropdownItem *ngFor="let status of projectStatuses" (click)="changeStatus(status)">
              {{ status!.name.toUpperCase() | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card-body py-3">
  <div class="row mb-3">
    <div class="d-flex align-items-center flex-column mt-3 mb-3 w-100">
      <div *ngIf="project.end && !notStarted" class="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
        <div>
          <span>{{ 'PROJECT_PERIOD' | translate }}</span>
          <span class="ms-1" *ngIf="project.end">({{ formatedStartDate }} - {{ formatedDate }})</span>
        </div>
        <span> {{ procentageLeft | number : '1.1-1' }}%</span>
      </div>
      <div *ngIf="project.end && !notStarted" class="progress h-6px w-100">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width]="procentageLeft + '%'"
          [ngStyle]="{ 'background-color': getColorBasedOnPercentage(procentageLeft) }"
        ></div>
      </div>
      <div *ngIf="!project.end" class="d-flex fw-bold fs-6 w-100 mt-auto mb-5">
        <span *ngIf="!project.end">{{ 'PROJECT_START' | translate }} {{ this.formatedStartDate }}</span>
      </div>
    </div>
    <div *ngIf="project.start && notStarted" class="d-flex justify-content-start">
      <p class="fw-bold fs-6">Project start</p>
      <p class="fw-bold fs-6">: {{ project.start | date : 'dd.MM.YYYY' }}</p>
    </div>
    <span *ngIf="project.end && !notStarted" class="text-muted fw-bold text-muted d-block fs-7"
      >{{ daysLeft | number : '1.1-1' }} {{ 'OF' | translate }}
      {{ totalDaysLeft | number : '1.1-1' }}
      {{ 'REMAINING_DAYS' | translate }}
    </span>
  </div>
  <div *ngIf="project?.budgetEstimation" class="row">
    <div class="d-flex align-items-center flex-column mt-3 mb-3 w-100">
      <div class="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
        <span>{{ 'PROJECT_BUDGET' | translate }}</span>
        <span> {{ project?.usedBudgetInPercentage | number : '1.1-1' }}%</span>
      </div>
      <div class="progress h-6px w-100">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width]="project?.usedBudgetInPercentage + '%'"
          [ngStyle]="{ 'background-color': getColorBasedOnPercentage(project?.usedBudgetInPercentage!) }"
        ></div>
      </div>
    </div>
    <span *ngIf="project?.daysLeft" class="text-muted fw-bold text-muted d-block fs-7"
      >{{ project?.daysLeft | number : '1.1-1' }} {{ 'OF' | translate }}
      {{ project?.budgetEstimation | number : '1.1-1' }}
      {{ 'REMAINING_DAYS' | translate }}
    </span>
    <span *ngIf="project?.budgetEstimation && !project.daysLeft" class="text-muted fw-bold text-muted d-block fs-7"
      >{{ project?.budgetEstimation | number : '1.1-1' }} {{ 'OF' | translate }}
      {{ project?.budgetEstimation | number : '1.1-1' }}
      {{ 'REMAINING_DAYS' | translate }}
    </span>
  </div>
</div>
