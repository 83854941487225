<div class="wraper">
  <span> {{ 'TOPIC_KNOWHOW' | translate }}</span>
  <p>{{ 'TOPIC_DESCRIPTION' | translate }}</p>
  <i>{{ 'KNOW_HOW_DESCRIPTION' | translate }}</i>
  <ng-container *ngIf="itemsListdb.length">
    <div class="mb-5">
      <app-multi-select
        [options]="itemsListdb"
        class="know-how-chips"
        [label]="'name'"
        [selectedValue]="this.selectedKnowledges"
        [isKnowladge]="true"
        [allowClear]="true"
        (statusSelectChange)="onStatusSelectChange($event)"
      >
      </app-multi-select>
    </div>
  </ng-container>
  <span class="mt-5"> {{ 'BUILD_THE_TEAM' | translate }}</span>
  <p>{{ 'TEAM_MEMBERS_PLAN' | translate }}</p>
  <div
    id="kt_docs_search_handler_basic"
    data-kt-search-keypress="true"
    data-kt-search-min-length="2"
    data-kt-search-enter="true"
    data-kt-search-layout="inline"
  >
    <div class="searchWraperWraper">
      <app-input search [placeholder]="'SEARCH' | translate" (statusSelectChange)="searchUsers($event)"></app-input>
    </div>
    <div class="py-5">
      <div data-kt-search-element="suggestions"></div>
      <div data-kt-search-element="results" class="d-none"></div>
      <div data-kt-search-element="empty" class="text-center d-none"></div>
    </div>
  </div>
  <app-table
    class="mb-5"
    [customHeadings]="tableHeadings"
    [dataList]="tableData"
    [selectable]="true"
    [rowClickable]="true"
    [pageSize]="5"
    [hiddenColumns]="['id', 'knowledgeFlag', 'isChecked', 'active']"
    [frontendPagination]="false"
    (selectionChangeOneItem)="changeUserSelect($event)"
    (paginationChange)="mapPagination($event)"
    [pagination]="paginationObject"
    (sortingChange)="mapSorting($event)"
    [sort]="sortObject"
  >
  </app-table>
</div>
