<div class="wraper" *ngIf="project">
  <div>
    <app-button [title]="'BACK' | translate | titlecase" style="margin-right: 15px" (handleClick)="back()"></app-button>
  </div>
  <div>
    <span class="text-header">{{ 'BUDGET_OVERVIEW' | translate }}</span>
    <div class="d-flex justify-content-center md-10 mt-9">
      <div class="workingDaysWraper">
        <div class="progress-bar-custom" role="progressbar"></div>
        <p class="text">{{ 'TOTAL_WORKING_DAYS' | translate }}</p>
        <p class="text">{{ project.budgetEstimation }} {{ 'DAYS' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="searchWrapper">
    <app-input search [placeholder]="'SEARCH' | translate" (statusSelectChange)="searchParam($event)"></app-input>
  </div>
  <div
    class="table-responsive"
    [ngStyle]="{
      'overflow-y': 'hidden',
      height: tableData.length * tableRowHight + headerRowHight + paginationRowHight + 'px'
    }"
  >
    <app-table
      class="project-budget select-selector"
      [customHeadings]="tableHeadings"
      [dataList]="tableData"
      (inputValueChanged)="onInputValueChanged($event)"
      [search]="searchTerm"
      [hiddenColumns]="['userId', 'userAllocation', 'userConsumedBudget']"
      [frontendPagination]="false"
      [pagination]="paginationObject"
      (paginationChange)="updateAndMapPagination($event)"
      (sortingChange)="mapSorting($event)"
      [sort]="sortObject"
    >
    </app-table>
  </div>
  <div class="sumWraper mb-2">
    <div class="textButtonWraper">
      <span *ngIf="!greatheBudgetThenTotal" class="text-sum">{{ 'SUM_BUDGET_DISTRIBUTION_OF' | translate }}</span>
      <span *ngIf="greatheBudgetThenTotal" class="text-sum-greather">{{
        'SUM_OF_ASSIGNED_DAYS_IS_HIGHER_THEN_TOTAL_WORKING_DAYS' | translate
      }}</span>
      <input
        type="number"
        readonly
        [ngClass]="greatheBudgetThenTotal ? 'greatherBudget ms-3' : 'plannedDaysSum ms-3'"
        [value]="sumOfAlocatedDays | number : '1.2-2'"
      />
    </div>
  </div>
  <div class="button-wraper">
    <button class="btn backButton" (click)="back()">
      {{ 'BACK' | translate }}
    </button>
    <button class="btn btn-primary" (click)="updateProject(true)" [disabled]="!isManager && isBackoffice">
      {{ 'SAVE' | translate }}
    </button>
  </div>
</div>
<app-success-modal #modalSuccess [modalConfig]="modalConfig">
  <div class="modal-wrapper"></div>
</app-success-modal>
<app-error-modal #modalError [modalConfig]="modalConfig">
  <div class="modal-wrapper"></div>
</app-error-modal>
