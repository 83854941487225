<div class="select_wrapper" *ngIf="!isListOfCustomers">
  <nz-select
    *ngIf="options?.length"
    class="select_field"
    [ngClass]="{ status: type === 'status', primary: type === 'primary' }"
    [nzSize]="size"
    [(ngModel)]="selectedValue"
    [compareWith]="compareFn"
    [nzAllowClear]="allowClear"
    [nzShowArrow]="true"
    [disabled]="inputDisable"
    [nzPlaceHolder]="placeholder"
    (ngModelChange)="modelChange($event)"
    [nzShowSearch]="showSearch"
  >
    <nz-option
      *ngFor="let option of options"
      [nzValue]="option"
      [nzLabel]="isTimeSheetMonths ? (option | localizedDate : 'MMMM yyyy') : option[label] ?? option"
    ></nz-option>
  </nz-select>
</div>
<div class="select_wrapper">
  <ng-container *ngIf="isListOfCustomers">
    <nz-select
      [(ngModel)]="selectedValue"
      class="select_field"
      [ngClass]="{ status: type === 'status', primary: type === 'primary' }"
      [compareWith]="compareFn"
      [nzShowSearch]="showSearch"
      [nzAllowClear]="allowClear"
      [nzShowArrow]="true"
      (ngModelChange)="modelChange($event)"
    >
      <nz-option-group *ngFor="let optionGroups of options" [nzLabel]="optionGroups.name">
        <nz-option *ngFor="let option of optionGroups.values" [nzLabel]="option.name" [nzValue]="option"></nz-option>
      </nz-option-group>
    </nz-select>
  </ng-container>
</div>
