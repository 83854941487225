<!--HEADER SPECIFIC TIMESHEET LIST-->
<div class="timesheet_wrapper">
  <div class="pt-5 d-flex" [ngClass]="speficifTimesheet || isViewTimesheetData ? 'admin_header' : 'otherRole_header'">
    <div
      class="userProgresWraper"
      *ngIf="(speficifTimesheet && (roleAdmin || manager)) || (isViewTimesheetData && (roleAdmin || manager))"
    >
      <app-button
        [title]="'BACK' | translate | titlecase"
        style="margin-right: 15px"
        (handleClick)="back()"
      ></app-button>
      <div class="d-flex align-items-center me-5 me-xl-13 user-image-name">
        <div class="symbol symbol-50px symbol-circle me-3">
          <img [src]="userData?.imageUrl || 'assets/media/logos/ICON_USER.svg'" alt="user_img" />
        </div>
        <div class="m-0">
          <span class="fw-bold text-gray-800 text-hover-primary"
            >{{ userData?.firstName }} {{ userData?.lastName }}</span
          >
          <span class="fw-semibold text-gray-400 d-block">{{ userData?.portfolio?.jobTitle }}</span>
        </div>
      </div>
      <div style="width: 300px" *ngIf="!isViewTimesheetData">
        <div *ngIf="!projectUserBudget && !daysTotal" class="days_booked">
          <span>{{ calculatedTime || 0 | number : '1.2-2' }} {{ 'DAYS_BOOKED' | translate }}</span>
        </div>
        <app-progress-bar
          *ngIf="projectUserBudget || daysTotal"
          [percentage]="projectUserBudget"
          [daysLeft]="daysLeft"
          [total]="daysTotal"
        ></app-progress-bar>
      </div>
    </div>
    <!--END-->
    <!--HEADER TIMESHEET LIST-->

    <div [ngClass]="roleAdmin ? 'admin' : 'otherRole'" class="table-header-wrapper">
      <div class="d-flex align-items-center first-items">
        <ng-container *ngIf="!blockedUser">
          <ng-container
            *ngIf="
              authService.currentUserRole !== 'PROJECT_BACKOFFICE' ||
              (authService.currentUserRole === 'PROJECT_BACKOFFICE' && !speficifTimesheet)
            "
          >
            <app-button
              [title]="'ADD' | translate"
              buttonType="primary"
              class="button-timesheet"
              (click)="isUpdate = false"
              [routerLink]="['./']"
              [queryParams]="{ showModal: 'trueCreate' }"
              prefixIcon="assets/media/icons/duotune/text/txt001.svg"
            ></app-button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="authService.currentUserRole === 'PROJECT_ADMIN' || manager || !speficifTimesheet">
          <app-button
            [title]="'DELETE' | translate"
            (handleClick)="onDeleteTimesheet()"
            [ngClass]="{ 'disabled-card': !listOfCheckedTimesheets.length }"
          ></app-button>
        </ng-container>
        <div
          *ngIf="authService.currentUserRole !== 'PROJECT_MEMBER' || speficifTimesheet"
          class="d-flex align-items-center justify-content-center marginLeft"
          [ngClass]="{ 'disabled-card': !listOfCheckedTimesheets.length }"
        >
          <app-menu-dropdown
            class="card_row_controls"
            [actions]="exportActions"
            title="{{ 'EXPORT' | translate }}"
          ></app-menu-dropdown>
        </div>
        <div
          *ngIf="authService.currentUserRole !== 'PROJECT_MEMBER' || speficifTimesheet"
          class="d-flex align-items-center justify-content-center"
          [ngClass]="{ 'disabled-card': !listOfCheckedTimesheets.length }"
        >
          <app-menu-dropdown
            class="card_row_controls"
            [actions]="statusActions"
            title="{{ 'STATUS' | translate }}"
          ></app-menu-dropdown>
        </div>
      </div>
      <div class="d-flex align-items-center selected_month">
        <div class="d-flex align-items-center justify-content-center selected_month__inner">
          <app-select
            *ngIf="selectedYearMonth"
            [options]="yearsMonthsFilter"
            [isTimeSheetMonths]="true"
            [selectedValue]="[selectedYearMonth]"
            (statusSelectChange)="filterSelectedMonth($event)"
          >
          </app-select>
        </div>
        <div class="sum_div">
          <div class="d-flex align-items-center sum_hours" style="height: 40px">
            {{ 'SUM' | translate }}: {{ calculatedTime | number : '1.2' }} h
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--END-->
  <!--TIMESHEET TABLE-->
  <div class="timesheet_table_wrapper">
    <app-table
      class="table-timesheet"
      [applyCustomHeadingOnClass]="columnWithCustomHeadings"
      [customHeadings]="tableHeadings"
      [sortableColumns]="sortableColumns"
      [dataList]="tableData"
      [rowClickable]="true"
      [search]="searchTerm"
      [hiddenColumns]="['id', 'billed', 'approved']"
      (rowClick)="openModalEditNew($event)"
      [frontendPagination]="false"
      (selectionChange)="selectionChange($event)"
      [selectable]="true"
    >
    </app-table>
  </div>
</div>

<app-timesheet-input
  [openModalSubject]="openModalSubject"
  [user]="userData"
  [timeSheet]="selectedTimeSheet"
  [isUpdate]="isUpdate"
  (modalCloseEmitter)="modalCloseEvent($event)"
  (modalSaveEmitter)="modalSave($event)"
></app-timesheet-input>
<app-success-modal #modalSuccess [modalConfig]="modalConfig">
  <div class="modal">{{ 'SAVED_SUCCESSFULLY' | translate }}</div>
</app-success-modal>
<app-error-modal #modalError [modalConfig]="modalConfig">
  <div class="modal">{{ 'ERROR_WHILE_SAVING_DATA' | translate }}</div>
</app-error-modal>
<app-modal-delete-superuser #modalDeleteSuperUser [modalConfig]="modalConfig1"> </app-modal-delete-superuser>
