import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { Observable } from 'rxjs'
import { ApiResponse } from '../dto/ApiResponse'
import { Language } from '../model/language'
import { LanguageDto } from '../model/language.model'

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public lenguageUrl: string = environment.apiBase + '/languages'

  constructor(private http: HttpClient) {}

  public getAllLanguages(): Observable<ApiResponse<Language[]>> {
    return this.http.get<ApiResponse<Language[]>>(this.lenguageUrl + '/get-all')
  }

  getAllLanguagesNoPagination(): Observable<ApiResponse<LanguageDto[]>> {
    return this.http.get<ApiResponse<LanguageDto[]>>(this.lenguageUrl + '/get-active')
  }
}
