import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Project } from 'app/pages/core/model/project.model'
import { DaysCount } from '../../getDaysCount.service'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { ProjectService } from 'app/pages/core/services/project.service'

@Component({
  selector: 'app-project-modify',
  templateUrl: './project-modify.component.html',
  styleUrls: ['./project-modify.component.scss'],
})
export class ProjectModifyComponent implements OnInit {
  public id: string = ''
  public project: Project
  public daysLeft: number = 0
  public teamDaysLeft: number = 0
  public procentageLeft: number = 0
  public procentageTeamLeft: number = 0
  public notStarted: boolean = false
  public formatedDate: any
  public projectStatus: string
  public formatedStartDate: any
  public roleBackoffice: boolean = false
  public editProject: boolean = false
  totalDaysLeft: number

  constructor(private route: ActivatedRoute, private daysCount: DaysCount, private projectService: ProjectService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']!
      if (this.id !== null) {
        this.projectService.getOneProject(this.id).subscribe(({ response }: ApiResponse<Project>) => {
          this.project = response
          this.getDaysLeft()
          this.teamDaysLeft = this.daysCount.getTeamDaysLeft(this.daysLeft, this.project.plannedPeople!)
          this.procentageTeamLeft = this.daysCount.getprocentageTeamLeft(
            this.teamDaysLeft,
            this.project.timePeriod!,
            this.project.plannedPeople!
          )
          if (this.daysLeft > this.project.timePeriod!) {
            this.notStarted = true
          }
          this.formatDate()
          this.projectStatus = this.project.projectStatus?.name!
        })
      }
    })
  }

  getDaysLeft() {
    let today = new Date()
    let endDate = this.project.end
    this.daysLeft = this.daysCount.getBudget(today, endDate)
    this.totalDaysLeft = this.daysCount.getBudget(this.project.start, this.project.end)
    this.procentageLeft = this.daysCount.getProcentageLeft(this.daysLeft, this.totalDaysLeft)
  }

  formatDate() {
    let date = new Date(this.project.end)
    let dateStart = new Date(this.project.start)
    this.formatedDate = this.daysCount.getDate(date)
    this.formatedStartDate = this.daysCount.getDate(dateStart)
  }
}
