<ng-template #modal>
  <div class="modal-content">
    <div class="modal-header ms-8 mt-3 mb-4">
      <!--begin::Modal title-->
      <h2>
        {{ modalConfig.newModalTitle | async }}
        <span style="color: #1bc5bd">{{ modalConfig?.secondaryTitle | async }}</span>
      </h2>
      <!--end::Modal title-->
      <!--begin::Close-->
      <div
        class="btn btn-sm btn-icon btn-active-color-primary"
        (click)="dismiss()"
        *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
      >
        <span class="svg-icon svg-icon-1" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"></span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Close-->
    </div>
    <div class="modal-body">
      <p>{{ modalConfig.modalBodyTitle | async }}</p>
      <p>
        <ng-content> </ng-content>
      </p>
    </div>

    <div [ngClass]="{ 'modal-footerDelete': modalConfig.onDelete, 'modal-footer': !modalConfig.onDelete }">
      <button
        type="button"
        class="btn btn-danger me-20"
        (click)="delete()"
        *ngIf="modalConfig.onDelete"
        [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()"
      >
        {{ modalConfig.deleteButtonLabel }}
      </button>
      <div [ngClass]="{ 'modal-footerend': !modalConfig.onDelete }">
        <button
          type="button"
          class="btn color"
          (click)="dismiss()"
          *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
          [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()"
        >
          {{ modalConfig.dismissButtonLabel }}
        </button>
        <button
          type="button"
          class="btn noHover"
          (click)="close()"
          *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
          [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()"
        >
          {{ modalConfig.closeButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
