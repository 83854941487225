import { Injectable } from '@angular/core'
import { UserModel } from '../models/user.model'
import { Router } from '@angular/router'
import { KeycloakService } from 'keycloak-angular'
export type UserType = UserModel | undefined

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private keycloakService: KeycloakService) {}

  get currentUserRole(): string {
    let role = this.keycloakService.getUserRoles()
    let currentRole = role[0]
    return currentRole
  }

  logout() {
    this.keycloakService.logout()
  }

  get isProjectAdmin() {
    return this.currentUserRole === 'PROJECT_ADMIN'
  }

  get isAdminOwner() {
    return this.currentUserRole === 'OWNER_ADMIN'
  }

  get isProjectBackoffice() {
    return this.currentUserRole === 'PROJECT_BACKOFFICE'
  }

  get isNormalUser() {
    return this.currentUserRole === 'PROJECT_MEMBER'
  }
}
