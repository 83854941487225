import { Injectable } from '@angular/core'
@Injectable({
  providedIn: 'root',
})
export class QuerySharedService {
  private queryMap: Map<string, any> = new Map()
  private filterMap: Map<string, any> = new Map()

  constructor() {}

  addQueryForRoute(route: string, query: any) {
    this.queryMap.set(route, query)
  }

  getQueryForRoute(route: string) {
    return this.queryMap.get(route)
  }

  addFilterForRoute(route: string, filter: any) {
    this.filterMap.set(route, filter)
  }

  getFilterForRoute(route: string) {
    return this.filterMap.get(route)
  }
}
