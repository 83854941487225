export const locale = {
  lang: 'rs',
  data: {
    DATE_AND_STATUS: 'Vreme',
    DESCRIPTION: 'Deskripcija',
    START_TIME: 'Početak rada',
    START_BREAK: 'Početak pauze',
    END_BREAK: 'Kraj pauze',
    END_TIME: 'Kraj rada',
    SUM_HOURS: 'Ukupno sati',
    PROGRESS: 'Napredak',
    APPROVED: 'Odobreno',
    PDF_PRINT: 'PDF štampa',
    ADD_NEW_ENTRY: 'Dodaj novi unos',
    ADD_NEW_ENTRY_FOR: 'Dodaj novi unos za',
    DATE: 'Datum',
    FILE_UPLOAD: 'Otpremanje datoteka',
    TASK: 'Zadatak',
    PLACEHOLDER: 'Placeholder',
    ALL_PROJECTS: 'Svi projekti',
    ENTRIES: 'Unosi',
    TASK_OVERVIEW: 'Pregled taskova',
    CUSTOMERS: 'Klijenti',
    LIST_OF_CUSTOMERS: 'Lista klijenata',
    COMPANY_TASK_OVERVIEW: 'Kompanija',
    PROJECT_TASK_OVERVIEW: 'Projekat',
    CREATED: 'Kreirano',
    TASK_NAME: 'Naziv zadatka',
    USAGE: 'Upotreba',
    TASK_NAME_IS_REQUIRED: 'Unos je obavezan',
    DESCRIPTION_IS_REQUIRED: 'Unos je obavezan',
    PROJECT_IS_REQUIRED: 'Unos je obavezan',
    ENTER_NEW_COMPANY_TASK: 'Dodaj novi kompanijski zadatak',
    DISCARD: 'Odbaci',
    SAVE_AND_CLOSE: 'Sačuvaj i zatvori',
    ADD_NEW_TASK: 'Dodaj novi zadatak',
    ENTER_NEW_PROJECT_TASK: 'Unesi novi zadatak projekta',
    NO_DATA_TO_SHOW: 'Nema podataka za prikazivanje',
    PROJECT_WILL_START_SOON: 'Projekat će uskoro početi',
    PROJECT: 'Projekt',
    SHOWING: 'Prikazano',
    TO: 'od',
    OF: 'od',
    USERS: 'Korisnici',
    COMPANIES: 'Kompanije',
    ADD_SUPER_ADMIN: 'Dodaj Super Admina',
    SUPER_ADMIN_LIST: 'Lista Super Admina',
    ADD_COMPANY: 'Dodaj kompaniju',
    COMPANY_LIST: 'Lista Kompanija',
    SUPER_ADMIN_MENU: 'MENI SUPER ADMIN',
    SUPER_ADMIN_COMPANY_LIST: 'Super Admin Lista Kompanija',
    ID: 'ID',
    USERNAME: 'Korisničko ime',
    FIRST_NAME: 'Ime',
    LAST_NAME: 'Prezime',
    COMPANY: 'Kompanija',
    EMAIL: 'E-mail',
    ENTER_COMPANY_NAME: 'Unesite ime kompanije',
    COMPANY_ADMIN: 'Admin kompanije',
    COMPANY_ADMIN_FULL_NAME: 'Ime admina kompanije',
    ENTER_COMPANY_ADMIN_FULL_NAME: 'Unesite ime admina kompanije',
    COMPANY_ADMIN_EMAIL: 'Admin komapnije email',
    ENTER_COMPANY_ADMIN_EMAIL: 'Unesite email admina kompanije',
    COMPANY_ADMIN_PHONE_NUMBER: 'Admin kompanije telefon',
    ENTER_COMPANY_ADMIN_PHONE_NUMBER: 'Unesite telefon admina kompanije',
    COMPANY_NAME: 'Ime kompanije',
    COMPANY_NAME_IS_REQUIRED: 'Unos je obavezan',
    STREET_NAME_IS_REQUIRED: 'Unos je obavezan',
    STREET_NUMBER_IS_REQUIRED: 'Unos je obavezan',
    POSTCODE_IS_REQUIRED: 'Unos je obavezan',
    CITY_IS_REQUIRED: 'Unos je obavezan',
    STATE_IS_REQUIRED: 'Unos je obavezan',
    COMPANY_ADMIN_FULL_NAME_IS_REQUIRED: 'Unos je obavezan',
    COMPANY_ADMIN_EMAIL_IS_REQUIRED: 'Unos je obavezan',
    COMPANY_ADMIN_PHONE_NUMBER_IS_REQUIRED: 'Unos je obavezan',
    CREATE_COMPANY: 'Kreiraj Kompaniju',
    SUPER_ADMIN_SUPER_USER_LIST: 'SUPER ADMIN LISTA SUPER USERA',
    COMPANY_NR: 'BROJ KOMPANIJE',
    CUSTOMER_SINCE: 'KORISNIK OD',
    STATUS: 'Status',
    ARCHIVED_USER: 'Arhiviran korisnik',
    ACCEPTED: 'ODOBREN',
    DECLINED: 'ODBIJEN',
    DRAFT: 'NEDOVRŠEN',
    OFFER: 'PONUDA',
    RUNNING: 'POKRENUT',
    PAUSED: 'PAUZIRAN',
    FINISHED: 'ZAVRŠEN',
    CREATE_SUPER_USER_ADMIN: 'Kreirajte super korisnika',
    SUPER_USER_USERNAME: 'Korisnicko ime super korisnika',
    ENTER_SUPER_USER_USERNAME: 'Unesite korisnicko ime super korisnika',
    SUPER_USER_USERNAME_IS_REQUIRED: 'Unos je obavezan',
    SUPER_USER_FIRST_NAME: 'Ime super korisnika',
    ENTER_SUPER_USER_FIRST_NAME: 'Unesite ime super korisnika',
    SUPER_USER_FIRST_NAME_IS_REQUIRED: 'Unos je obavezan',
    SUPER_USER_LAST_NAME: 'Prezime super korisnika',
    ENTER_SUPER_USER_LAST_NAME: 'Unesite prezime super korisnika',
    SUPER_USER_LAST_NAME_IS_REQUIRED: 'Unos je obavezan',
    SUPER_USER_EMAIL: 'E mail super korisnika',
    ENTER_SUPER_USER_EMAIL: 'Unesite e mail super korisnika',
    SUPER_USER_EMAIL_IS_REQUIRED: 'Unos je obavezan',
    CREATE_SUPER_USER: 'Kreiraj Super Korisnika',
    FIVE_DAYS_A_WEEK: '5 radnih dana',
    SIX_DAYS_A_WEEK: '6 radnih dana',
    SEVEN_DAYS_A_WEEK: '7 radnih dana',
    BOOK_YOUR_TIME: 'Unesite radno vreme',
    DAYS: 'dana',
    PLANED_DAYS_GREATHER_THAN_TOTAL_WORKING_DAYS: 'Broj planiranih radnih dana je veći od ukupnog broja radnih dana',
    HELLO: 'Pozdrav',
    ENTER_JOB_TITLE: 'Unesite naziv posla',
    JOB_TITLE_IS_REQUIRED: 'Unos je obavezan',
    SKILL_LEVEL: 'Nivo znanja',
    COUNTRY: 'Država',
    START: 'Početak',
    END: 'Kraj',
    PROJECT_DESCRIPTION: 'Opis projekta',
    ENTER_COUNTRY: 'Unesite državu',
    ENTER_PROJECT_NAME: 'Unesite ime projekta',
    ENTER_PROJECT_DESCRIPTION: 'Unesite opis projekta',
    CUSTOMER_CREATED_SUCCESSFULLY: 'Korisnik uspešno kreiran',
    CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG: 'Korisnik nije uspešno kreiran',
    USER_SAVED_SUCCESSFULY: 'Korisnik uspešno sučuvan',
    EXPERTISE: 'Ekspertiza',
    KNOW_HOW: 'Kako',
    PROJECTS: 'Projekti',
    SPOKEN_LANAGUES: 'Jezici',
    ADD_PERSONAL_EXPERIENCE: 'Dodaj lično iskustvo',
    ADD_LANGUAGE: 'Dodaj jezik',
    KNOWLEDGE_OVERVIEW: 'Pregled Znanja',
    NAME: 'Ime',
    ADD_KNOWLEDGE: 'Dodaj znanje',
    KNOWLEDGE_NAME: 'Naziv znanja',
    ENTER_NEW_KNOWLEDGE: 'Unesite naziv znanja',
    KNOWLEDGE_NAME_IS_REQUIRED: 'Unos je obavezan',
    KNOWLEDGE_CREATED_SUCCESSFULLY: 'Znanje je uspešno uneseno',
    KNOWLEDGE_NOT_CREATED_SOMETHING_WENT_WRONG: 'Znanje nije kreirano, nešto nije u redu',
    ENTER_COMPANY_TASK: 'Unesi Zadatak Kompanije',
    ENTER_PROJECT_TASK: 'Unesi Zadatak Projekta',
    TASK_CREATED_SUCCESSFULLY: 'Zadatak je uspešno unet',
    TASK_NOT_CREATED_SOMETHING_WENT_WRONG: 'Zadatak nije uspešno unet, nešto nije u redu',
    OVERVIEW: 'Pregled',
    ADD: 'Dodati',
    USER: 'Korisnik',
    CUSTOMER: 'Klijent',
    DASHBOARD: 'Dashboard',
    PROJECT_OVERVIEW: 'Pregled',
    TASK_WITH_THAT_NAME_ALREADY_EXIST: 'Unos već postoji',
    KNOWLEADGE_ALREADY_EXIST: 'Unos već postoji',
    DATE_IS_REQUIRED: 'Unos je obavezan',
    LEFT: 'Ostalo',
    DONE: 'Završeno',
    START_WORK: 'Početak rada',
    START_WORK_TIME_IS_REQUIRED: 'Unos je obavezan',
    BREAK_TIME_CANT_START_BEFORE_YOU_START_WORKING: 'Pauza ne može početi pre početka radnog vremena',
    BREAK_TIME_END_IS_BEFORE_BREAK_TIME_START: 'Vreme kraja pauze je pre vremena početka pauze',
    END_WORK: 'Kraj rada',
    'END_ WORK_TIME_IS_REQUIRED': 'Unos je obavezan',
    END_WORKING_TIME_IS_BEFORE_END_BREAK_TIME: 'Kraj rada je pre kraja pauze',
    TASKS: 'Zadaci',
    CLICK: 'Dodajte ili prevucite fajl u formatima: .jpg .jpeg .png .pdf',
    ENTER_NEW_ENTERY_FOR: 'Unesite novi unos za',
    PLEASE_CHOSE_PROJECT_FIRST: 'Molimo odaberite projekat',
    THIS_ENTRY_ALREADY_EXISTS: 'Unos već postoji',
    CREATED_BY: 'KREIRAO',
    KNOWHOW: 'Znanje',
    RECORDS_PER_PAGE: 'Prikaza po strani',
    IN_TOTAL: 'ukupno unosa',
    JOB_TITLE: 'Pozicija',
    ROLE: 'Rola',
    INDUSTRY: 'Industrija',
    ADD_CONTACT: 'Dodaj Kontakt',
    PLEASE_CHOOSE_PROJECT_START_DATE: 'Odaberite startni datum projekta',
    SIGN_OUT: 'Odjava',
    First_name: 'Ime',
    NO_TASKS_FOUND_FOR_SELECTED_PROJECT: 'Nema taskova za selektovani projekat',
    NO_PROJECTS_FOUND: 'Nema dostupnih projekata - kontaktirajte administratora vaše kompanije',
    ROLE_PROJECT_ADMIN: 'Administrator',
    ROLE_PROJECT_MEMBER: 'User',
    ROLE_PROJECT_BACKOFFICE: 'Backoffice',
    NORMAL_USER: 'Korisnik',
    ROLE_OWNER_ADMIN: 'Superadmin',
    PLEASE_CHOOSE_CUSTOMER: 'Odaberite korisnika',
    CHOOSE_AT_LEAST_ONE_CUSTOMER: 'Izaberite najmanje jednog korisnika',
    CUSTOMERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Korisnici su uspešno arhivirani/unarhivirani',
    CHOOSE_AT_LEAST_ONE_USER_ADMINISTRATOR_CANNOT_BE_ARCHIVED:
      'Izaberite najmanje jednog korisnika administrator ne moze biti arhiviran',
    USERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Korisnici su uspešno arhivirani/unarhivirani',
    COMPANIES_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Kompanije su uspešno arhivirane/unarhivirane',
    CHOOSE_AT_LEAST_ONE_COMPANY: 'Izaberite najmanje jednu kompaniju',
    USER_MAIN_MENU_SIDEBAR: 'Korisnik',
    E_MAIL: 'E-Mail',
    BACK: 'Nazad',
    SAVE_AND_CONTINUE: 'Sačuvaj i nastavi',
    PROJECT_NUMBER: 'Broj projekta',
    PROJECT_OFFER: 'Broj ponude',
    PROJECT_NAME: 'Naziv projekta',
    START_DATE: 'Početni datum',
    END_DATE: 'Krajnji datum',
    NO_END: 'Bez kraja',
    WEEK_MODE: 'Broj radnih dana',
    TIME_PERIOD: 'Ukupno vremena',
    PLANED_PEOPLE: 'Planirani ljudi',
    TOTAL_WORKING_DAYS: 'Ukupno radnih dana',
    ADD_USERS_TO_THE_TEAM: 'Dodaj korisnike u tim',
    ALLOCATE: 'Alociranje',
    PLANNED: 'Planirano',
    EXPORT: 'Izvezi',
    KNOWLEDGE_AND_THE_TEAM: 'Znanje i tim',
    ALLOCATE_AND_FINISH: 'Alociranje',
    TOPIC_KNOWHOW: 'Topik/Kako',
    BUILD_THE_TEAM: 'Napravi tim',
    DUE_DATA: 'Do Datuma',
    OVERVIEW_APPROVALS: 'Pregled odobrenja',
    EXPORT_AND_VERIFY_HOURS: 'Izvezi i potvrdi sate',
    NO_FETCH_DATE: 'Nema podataka',
    ERROR_WHILE_SAVING_DATA: 'Greska pri azuriranju podataka',
    SAVED_SUCCESSFULLY: 'Uspesno sacuvnao',
    CREATE_NEW_PASSWORD: 'KREIRAJ NOVU LOZINKU',
    PASSWORD: 'Lozinka',
    CONFIRM_PASSWORD: 'Potvrdi lozinku',
    CREATE: 'Kreiraj',
    USER_PASSWORD_HAS_BEEN_SUCCESSFULLY_CREATED: 'Lozinka korisnika je uspesno kreirana!',
    SHOW_ACTIVE: 'Aktivan',
    SHOW_INACTIVE: 'Neaktivan',
    FRONTEND: 'FrontEnd',
    DRAFT_STATUS: 'NEDOVRŠEN',
    RUNNING_STATUS: 'POKRENUT',
    PAUSED_STATUS: 'PAUZIRAN',
    FINISHED_STATUS: 'ZAVRŠEN',
    MY_PROFILE: 'Moj profil',
    KNOWLEDGE: 'Znanja',
    LIGHT: 'Svetlo',
    DARK: 'Tamno',
    SYSTEM: 'Sistemski',
    NO_ITEMS_FOUND: 'NEMA REZULTATA',
    PASSWORD_DOES_NOT_MATCH: 'Lozinka ne odgovara',
    PASSWORD_VALIDATOR:
      'Lozinka mora da sadrži: najmanje jedno veliko slovo [A-Z], najmanje jedno malo slovo [a-z], najmanje jedan specijalni karakter i najmanje 8 karaktera. Razmak nije dozvoljen!',
    RESET_PASSWORD: 'Promeni lozinku',
    CUSTOMER_NAME_MUST_BE_UNIQUE: 'Ime korisnika mora da bude jedinstveno',
    CUSTOMER_END_NAME_MUST_BE_UNIQUE: 'Ime krajnjeg usera mora da bude jedinstveno',
    MY_TIMESHEET: 'Moj raspored',
    MY_PROJECTS: 'Moji projekti',
    ADMIN_MENU: 'MENI ADMINISTRATORA',
    ADD_USER: 'Dodaj korisnika',
    ADD_CUSTOMER: 'Dodaj klijenta',
    ADD_PROJECTS: 'Dodaj projekat',
    TEMPLATES: 'Šabloni',
    ABOUT: 'O',
    ENGLISH: 'Engleski',
    GERMAN: 'Nemački',
    SERBIAN: 'Srpski',
    USER_ROLE: 'Uloga korisnika',
    NO_MAIL: 'Bez mail-a',
    SPOKEN_LANGUAGES: 'Znanje jezika',
    SAVE: 'Sačuvaj',
    PROJECT_LIST: 'Lista projekata',
    OVERVIEW_OF_ALL_PROJECTS: 'Pregled svih projekata',
    PROJECT_STATUS_FILTER: 'Filter statusa projekta',
    SEARCH: 'Pretraži',
    NUMBERS: 'Broj',
    STATE: 'Stanje',
    TEAM: 'Tim',
    DAYS_LEFT: 'dana još',
    END_CUSTOMER: 'Krajnji klijent',
    COMMENT: 'Komentar',
    STREET: 'Ulica',
    NUMBER: 'Broj',
    ZIP: 'Poštanski broj',
    CITY: 'Grad',
    MASS_ACTIVITY: 'Aktivnosti',
    USER_DETAILS: 'Detalji korisnika',
    PROJECT_DETAILS: 'Detalji projekta',
    TIME_AND_BUDGET: 'Vreme i budžet',
    BASIC_DATA: 'Osnovne podatke možete dodati ovde',
    SHORT_DESCRIPTION: 'Kratak opis (vidljivo za sve članove projekta)',
    PROJECT_NAME_IS_REQUIRED: 'Unos je obavezan',
    TOPIC_DESCRIPTION: 'Znanje koje je potrebno za ovaj projekat',
    STAFF: 'Osoblje',
    CAPACITY: 'Kapacitet',
    TIMESHEET: 'Raspored',
    ADD_PROJECT: 'Dodaj projekat',
    ARCHIVE: 'Arhiviraj',
    UNARCHIVE: 'Dearhiviraj',
    CHANGE_PROFILE_IMAGE: 'Izmeni profilnu sliku',
    PHONE_NUMBER: 'Broj telefona',
    USERNAME_IS_REQUIRED: 'Unos je obavezan',
    USER_ROLE_IS_REQUIRED: 'Unos je obavezan',
    EMAIL_IS_REQUIRED: 'Unos je obavezan',
    FIRST_NAME_IS_REQUIRED: 'Ime je obavezno',
    LAST_NAME_IS_REQUIRED: 'Unos je obavezan',
    BACKOFFICE_MENU: 'MENI KANCELARIJE ZA PODRŠKU',
    USER_MENU: 'KORISNIČKI MENI',
    START_PROJECT: 'Započni projekat',
    MEMBERS: 'članova',
    ARCHIVED_PROJECTS: 'Arhivirani projekti',
    EDIT_PROJECT: 'Izmeni projekat',
    PROJECT_PERIOD: 'Trajanje projekta',
    PROJECT_BUDGET: 'Budžet projekta',
    USED: 'iskorišćeno',
    REMAINING_DAYS: 'ostalo je radnih dana',
    COLLAGUES: 'kolege',
    STATUS_BUDGET: 'status budžeta',
    ACTIONS: 'Aktivnosti',
    DAYS_TOTAL: 'ukupno dana',
    EDIT: 'Izmeni',
    ADD_ADITIONAL_TIME_PERIOD: 'Dodaj dodatno vreme',
    DEFINE_PERIOD: 'Definišite period i budžet',
    KNOW_HOW_DESCRIPTION: 'Npr. senior, kvalifikacije, sertifikati, specijalna znanja, jezik itd.',
    USERNAME_EXIST: 'Unos već postoji',
    CUSTOMER_REQUIRED: 'Unos je obavezan',
    MANAGER: 'Menadžer',
    ENTER_COMPANY_ADMIN_FIRST_NAME: 'Unesite ime admina komapnije',
    ENTER_COMPANY_ADMIN_LAST_NAME: 'Unesite prezime admina kompanije',
    ENTER_COMPANY_ADMIN_USERNAME: 'Unesite username admina kompanije',
    COMPANY_ADMIN_FIRST_NAME_IS_REQUIRED: 'Unos je obavezan',
    COMPANY_ADMIN_LAST_NAME_IS_REQUIRED: 'Unos je obavezan',
    COMPANY_ADMIN_USERNAME_IS_REQUIRED: 'Unos je obavezan',
    TEAM_MEMBERS_PLAN: 'Članovi tima koje planirate za ovaj projekat',
    PROJECT_START_DATE: 'Datum početka projekta',
    PROJECT_NUMBER_EXIST: 'Unos već postoji',
    PROJECT_INFO_UPDATE_SUCESFULY: 'Informacije u projektu su uspešno izmenjene',
    ASSIGN: 'Dodeli',
    PROJECT_SAVED_SUCCESSFULLY: 'Projekat uspešno sačuvan',
    CAN_BE_USED: 'Može koristiti bilo ko',
    ONLY_USABLE: 'Samo upotrebljivo u projektu',
    ACTION: 'Akcija',
    FIRST_LAST_NAME_MESSAGE: 'Dozvoljena su slova i najviše tri reči',
    SUCCESSFULLY_DELETED: 'Uspesno obrisan korisnik',
    CANCEL: 'Odbaci',
    DELETE_SUPER_ADMIN: 'Brisanje Super Admin',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_USER: 'Da li želite obrisati korisnika',
    THE_PASSWORD_MUST_INCLUDE_AT_LEAST_ONE_UPPERCASE_LETTER_ONE_NUMBER_AND_ONE_SPECIAL_CHARACTER:
      'Sifra treba da sadrzi najmanje jedno veliko slovo (A-Z), jedan broj (0-9) i jedan specijalni karakter (@#$%^&+=!._)',
    MARK_AS_APPROVED: 'Označi kao odbreno',
    MARK_AS_BILLED: 'Označi kao fakturisano',
    EXPORT_PDF: 'Izvezi PDF',
    EXPORT_CSV: 'Izvezi .csv',
    PRINT_PDF: 'Odštampaj PDF',
    USERNAME_CANNOT_CONTAIN_WHITE_SPACE: 'Username ne sme da sadrži razmak',
    ARE_YOR_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_BILLED: 'Da li želite staviti status na plaćen?',
    ARE_YOU_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_APPROVED: 'Da li želite staviti status na odobren?',
    DO_YOU_REALLY_WANT_TO_DELETE_TIMESHEET: 'Da li želite obrisati timesheet?',
    APPROVE_ALL: 'Odobri sve',
    TIMESHEET_ACTION: 'Opcije za raspored vremena',
    ACTIVE_USER: 'Aktivan Korisnik',
    INACTIVE_USER: 'Neaktivan Korisnik',
    PROJECT_NAME_ALREADY_TAKEN: 'Ime projekta je zauzeto',
    PROJECT_END: 'Kraj projekta',
    PROJECT_START: 'Početak projekta',
    CUSTOMER_FIRST_NAME: 'Ime klijenta',
    CUSTOMER_END_NAME: 'Prezime klijenta',
    DELETE: 'Izbriši',
    CANNOT_DELETE_BILLED_OR_APPROVED_TIMESHEET: 'Ne možete obrisati naplćen ili odobren timesheet',
    WE_CANNOT_BILL_FOR_THE_UNAPPROVED_TIMESHEET: 'Ne možete naplatiti neodobren timesheet',
    NO: 'Ne',
    YES: 'Da',
    LOCKED: 'Zaključan',
    INFO: 'INFO',
    THIS_VALUE_WILL_BE_USED_FOR_THE_CALCULATION_OF_THE_PROJECT_BUDGET_IN_DAYS:
      'Ova vrednost će se koristiti za obračun budzeta',
    WORKING_DAYS_ESTIMATION: 'Procena Radnih Dana',
    RESET_TO_CALCULATION: 'Resetuj Kalkulaciju',
    CALCULATE_BUDGET: 'Izračunaj budžet',
    ACTIVATE_USER: 'Aktiviraj',
    DEACTIVATE_USER: 'Dektiviraj',
    DELETE_USER: 'Obriši Korisnika',
    RE_SEND_ACTIVATION_EMAIL: 'Ponovo Pošalji Aktivacioni E-mail',
    USER_DELETED_SUCCESSFULY: 'Korisnik uspesno obrisan',
    CHANGE_BUDGET: 'Promeni Budzet',
    BUDGET_OVERVIEW: 'Pregled budžeta',
    COLLEAGUES: 'Članovi',
    SUM_BUDGET_DISTRIBUTION_OF: 'Zbir budžeta od',
    SUM_OF_ASSIGNED_DAYS_IS_HIGHER_THEN_TOTAL_WORKING_DAYS: 'Zbir budžeta je veći od ukupnog broja dana',
    REVOKE_STATUS: 'Opozovi Status',
    REVOKE_STATUS_MODAL: 'Da li zelite da opozovete status?',
    ENTRY_IS_REQUIRED: 'Unos je obavezan.',
    ENTRY_ALREADY_EXIST: 'Unos već postoji.',
    ATTENTION_YOU_ARE_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'Pažnja: Unosite timesheet van vremenskog okvira projekta. Timesheet će se uneti, ali će Projekt Menadžer biti obavešten.',
    TIME: 'VREME',
    ENDTIME: 'KRAJ',
    STARTTIME: 'POČETAK',
    HOUR: 'SATI',
    BREAK: 'PAUZA',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'Pažnja: Unos timesheeta će biti veći od vašeg budžeta na projektu. Timesheet će se uneti, ali će Projekt Menadžer biti obavešten.',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_AND_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'Pažnja: Unos timesheeta će biti veći od vašeg budžeta na projektu i koji je van vremenskog okvira budžeta. Timesheet će se uneti, ali će Projekt Menadžer biti obavešten.',
    USER_HAS_BEEN_SUCCESSFULLY_UPDATED: 'Korisnik uspešno izmenjen',
    INVALID_EMAIL_FORMAT: 'Pogrešan e-mail format',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_TASK: 'Da li želite da obrišete zadatak?',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_KNOWLEDGE: 'Da li želite da obrišete znanje?',
    ALL_USERS: 'Svi korisnici',
    ALL_CUSTOMERS: 'Svi klijenti',
    ADD_NEW: 'Dodaj novi',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS_EIGHT_HOURS: 'Obračun se zasniva na: 1 radni dan je 8 sati',
    ERROR_UNARCHIVING_KNOWLEDGE: 'Greska pri dearhiviranju znanja',
    ERROR_DELETING_KNOWLEDGE: 'Greska pri brisanju znanja',
    ERROR_UNARCHIVING_TASK: 'Greska pri dearhiviranju zadatka',
    ERROR_DELETING_TASK: 'Greska pri brisanju zadatka',
    COMPLETED: 'Završeni',
    ACTIVE: 'Aktivni',
    DRAFT_LOWER_CASE: 'Nedovršeni',
    YOUR_LAST_LOGIN: 'Vaša poslednja prijava',
    YOUR_LAST_TIMEBOOKING: 'Vaš poslednji unos rasporeda',
    YOUR_ASSIGNED_PROJECTS: 'Vaši dodeljeni projekti',
    MANAGING: 'Upravljani',
    MEMBER_OF: 'Član',
    WORKING_HOURS_ESTIMATION: 'Broj Radnih Sati',
    USERS_ACTIVE_INACTIVE_SUCCESSFULLY: 'Korisnici su uspešno aktivirani/deaktivirani',
    PAUSED_LOWER_CASE: 'Pauzirani',
    ACTIVE_USERS: 'Aktivni Korisnici',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_TASK:
      'Da li ste sigurni da želite da arhivirate ovaj zadatak? Neće biti dostupan za nove rezervacije vremena.',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_KNOWLEDGE:
      'Da li ste sigurni da želite da arhivirate ovo znanje? Neće biti dostupno za nove projekte i portfolije.',
    IN_USE: 'koristi se',
    NOT_IN_USE: 'ne koristi se',
    AFRICAN: 'Afrički',
    ALBANIAN: 'Albanski',
    ARABIC: 'Arapski',
    BELARUSIAN: 'Beloruski',
    BOSNIAN: 'Bosanski',
    BULGARIAN: 'Bugarski',
    CHINESE: 'Kineski',
    CROATIAN: 'Hrvatski',
    CZECH: 'Češki',
    DANISH: 'Danski',
    DUTCH: 'Norvežanski',
    EGYPTIAN: 'Egipatski',
    ESTONIAN: 'Estonski',
    FILIPINO: 'Filipinski',
    FRENCH: 'Francuski',
    GREEK: 'Grčki',
    HUNGARIAN: 'Mađarski',
    ICELANDIC: 'Islandski',
    INDIAN: 'Indijski',
    IRISH: 'Irski',
    ITALIAN: 'Italijanski',
    JAPANESE: 'Japanski',
    KOREAN: 'Korejski',
    KURDISH: 'Kurdski',
    LATVIAN: 'Latvijski',
    LITHUANIAN: 'Litvanski',
    LUXEMBOURGISH: 'Luksemburški',
    MACEDONIAN: 'Makedonski',
    NORWEGIAN: 'Norveški',
    PERSIAN: 'Persijski',
    POLISH: 'Poljski',
    PORTUGUESE: 'Portugalski',
    ROMANIAN: 'Rumunski',
    RUSSIAN: 'Ruski',
    SCOTTISH: 'Škotski',
    SLOVENIAN: 'Slovenski',
    SLOVAK: 'Slovački',
    SPANISH: 'Španski',
    SWEDISH: 'Švedski',
    SYRIAN: 'Sirijski',
    THAI: 'Tajlandski',
    TURKISH: 'Turski',
    UKRAINIAN: 'Ukrajinski',
    VIETNAMESE: 'Vijetnamski',
    UPDATE_IMAGE_SIZE_ERROR: 'Fajl je prevelik. Maksimalna dozvoljena veličina je 5 MB.',
    LOCK_USERS: 'Zaključaj / Odključaj',
    ADD_ENTRIES: 'Dodaj unos',
    SAVE_DRAFT: 'Sačuvaj draft',
    LAST_LOGIN: 'POSLEDNJA PRIJAVA',
    MAXIMUM_CHARACTERS_IS_50: 'Maksimalan broj karaktera je 50',
    CREATED_ON: 'KREIRANO',
    BLOCKED: 'Blokiran',
    COMPANY_DATA: 'Podaci Kompanije',
    FULL_COMPANY_NAME: 'Ime Kompanije',
    STREET_AND_NUMBER: 'Ulica i Broj',
    LICENSE_AND_SUBSCRIPTION: 'Licenca i Subskripcija',
    CHANGE_SUBSCRIPTION: 'Promeni Subskripciju',
    WORKING_TIME: 'Radno Vreme',
    WORKING_HOURS_DAY: 'Radni Sati / Dan',
    SETTINGS: 'Podešavanja',
    VAT_Number: 'VAT Broj',
    ATTENTION: 'PAŽNJA',
    BUDGET_IS_CALCULATED_IN_WORKING_DAYS: 'budžet je ukalkulisan u radne dane',
    FOR_EXAMPLE: 'Na primer: 8h = 1 radni dan',
    WORKING_DAY: 'radni dan',
    THIS_CHANGE_HAS_A_DIRECT_EFFECT_ON_THE_DISPLAY_OF_THE_BUDGET_IN_DAYS:
      'Ove promene imaju direktan efekat na prikaz budžeta u danima',
    FORR_ALL_USERS: 'za sve korisnike.',
    NEXT: 'Dalje',
    OVERVIEW_OF_LANGUAGE_SKIL: 'Pregled znanja jezika',
    NATIV_KNOWLEDGE: 'Maternji jezik',
    NEGOTIABLE_KNOWLEDGE: 'Mogućnost pregovaranje',
    FLUENT_KNOWLEDGE: 'Tečan govor i pisanje',
    GOOD_KNOWLEDGE: 'Dobro poznavanje',
    BASIC_KNOWLEDGE: 'Osnovno poznavanje',
    DELETE_PROFILE_IMAGE: 'Izbriši profilnu sliku',
    SUM: 'UKUPNO',
    VALUE_MUST_BE_BETWEEN_1_AND_12: 'Unesena vrednost mora biti između 1 i 12',
    PROJECT_FILTER: 'Filter Projekta',
    HERE_YOU_CAN_CHANGE_THE_BASIS_ON_WHICH_THE_PROJECT:
      'Ovde možete promeniti osnovu na kojoj se budžet projekta obračunava u radnim danima',
    THE_NEW_VALUE_FOR_A_USER_BUDGET_CANNOT_BE_ADJUSTED_TO_BE_LESS_THAN_THE_BUDGET_ALREADY_USED:
      'Nova vrednost ne može biti manja od trenutno korištenog budžeta',
    MINIMUM_CHARACTERS_IS_50: 'Morate uneti minimalno 2 karaktera',
    HOURS: 'sati',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS: 'Obračun se zasniva na: 1 radni dan je:',
    COMPANY_UPDATED_SUCCESSFULLY: 'Kompanija je uspešno ažurirana!',
    UPDATE_ENTRY_INFORMATION_FOR: 'Ažuriraj informacije o unosu za',
    CUSTOMER_UPDATED_SUCCESSFULLY: 'Korisnik je uspešno ažuriran',
    ADMIN: 'Administrator',
    BACKOFFICE: 'Sekretar',
    AGRICULTURAL_ECONOMICS: 'Poljoprivredna Ekonomija',
    SERVICES: 'Usluga',
    INSURANCE: 'Osiguranje',
    CONSTRUCTION_INDUSTRIES: 'Građevinska Industrija',
    REAL_ESTATE: 'Nekretnine',
    METAL_AND_ELECTRICAL_INDUSTRY: 'Metalna i Elektrotehnička Industrija',
    IT_SERVICES: 'IT Industrija',
    SPORTS_AND_FITNESS: 'Sport i Fitness',
    ADVERTISING_AND_MARKETING: 'Oglasi i Marketing',
    CRAFT: 'Zanatska Industrija',
    TRANSPORTATION_AND_LOGISTICS: 'Transport i Logistika',
    AUTOMOTIVE_INDUSTRY: 'Auto Industrija',
    CHEMISTRY: 'Hemijska Industrija',
    PUBLIC_ADMINISTRATION: 'Javna Administracija',
    RETAIL_AND_TRADE: 'Maloprodaja i Trgovina',
    MEDIA: 'Medijska Industrija',
    HEALTHCARE: 'Zdravstvo',
    FOOD_INDUSTRY: 'Prehrambena Industrija',
    PHARMACEUTICALS: 'Industrija Lekova',
    BANKING_AND_FINANCIAL_SERVICES: 'Bankarske i Finansijske Usluge',
    ENERGY_AND_ENVIRONMENT: 'Energija i Životna Sredina',
    NON_PROFIT_ORGANIZATIONS: 'Neprofitne Organizacije',
    TELECOMMUNICATIONS: 'Telekomunikacije',
    TEXTILES_AND_APPAREL_INDUSTRY: 'Tekstilna i Aparatna Industrija',
    OTHER: 'Ostalo',
    E_COMMERCE: 'Online Prodaja',
    TOURISM_AND_GASTRONOMY: 'Turizam i Gastronomija',
    PLANT_CONSTRUCTION_AND_MACHINERY_MANUFACTURING: 'Izgradnja Postrojenja i Proizvodnja Mašina',
    AVIATION_AND_ASTRONAUTIC: 'Avijacija i Astronautika',
    DAYS_BOOKED: 'bukiranih dana',
    ADD_NEW_CUSTOMER: 'Dodaj novog klijenta',
    SYSTEM_ERROR: 'Sistemska greška.',
    PAGE_NOT_FOUND: 'Stranica nije pronađena.',
    RETURN_TO: 'Vrati se na',
    HOME: 'početnu',
    LOADING_PROJECTS: 'Učitavanje projekata',
    FILE: 'Datoteka',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_CUSTOMER: 'Da li želite da obrišete zadatak?',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_CUSTOMER: 'Da li ste sigurni da želite da arhivirate ovaj korisnika?',
    SHOW_ARCHIVED: 'Prikazi Arhivirane',
    ASSIGNED_PROJECTS: 'Dodeljeni projekti',
    ALL_ENTRIES: 'Svi Unosi',
    STANDARD_LANGUAGE: 'Pocetna podesavanja',
    DEFAULT_SETTINGS: 'Podrazumevani Jezik',
    COMPANY_REGISTRATION_CONFIRMATION:
      'Hvala Vam, vaš nalog je uspešno kreiran. Molim Vas proverite vaš e-mail za sledeće korake.',
    DASHBOARD_PROJECTS: 'projekta',
    DASHBOARD_PROJECT: 'Projekat',
    DASHBOARD_CUSTOMERS: 'Klijenti',
    DASHBOARD_CUSTOMER: 'Klijent',
    DASHBOARD_USER: 'Aktivan korisnik',
    DASHBOARD_USERS: 'Aktivni Korisnici',
    ACTIVE_PROJECTS: 'Aktivni Projekti',
    INACTIVE_PROJECTS: 'Neaktivni Projekti',
    RUNNING_AND_PAUSED_STATUS: 'Pokrenut i Pauziran Status',
    DRAFT_AND_FINISHED_STATUS: 'Nedovršen i Završen Status',
    ALL_EXISTING_PROJECTS: 'Svi postojeći projekti',
    EMAIL_TAKEN: 'Email već postoji!',
    COMPANY_NAME_TAKEN: 'Naziv kompanije već postoji!',
    PASSWORD_COMPLEXITY_ERROR_TEXT: 'Ne zadovoljava složenost lozinke',
    APPROVE_ALL_TIMESHEET: 'Odobri sve Timesheetove',
    EXPORT_ALL_TIMESHEETS_EXCEL: 'Izvezi sve Timesheetove ( Excel )',
    EXPORT_ALL_TIMESHEETS_CSV: 'Izvezi sve Timesheetove ( Csv )',
    EXPORT_ALL_TIMESHEETS_PDF: 'Izvezi sve Timesheetove ( Pdf )',
    PENDING: 'Na Čekanju',
    BILLED: 'Naplaćen',
    ASK_FOR_APPROAVAL_TEXT: 'Samo rasporedi čiji status odobrenja je na čekanju će biti odobreni!',
    TEAM_MEMBERS: 'Članovi tima',
    ARCHIVED_CUSTOMERS: 'Arhivirani klijenti',
    ACTIVE_CUSTOMERS: 'Aktivni klijenti',
    CREATE_YOUR_COMPANY_ACCOUNT: 'NAPRAVITE NALOG KOMPANIJE',
    DATA_EXPLORER: 'Pretraživač podataka',
    REPORTS: 'Izveštaji',
    BACK_TO_PROJECTS: 'nazad na projekte',
    USED_BUDGET: 'Upotrebljen budžet',
    BOOK: 'Unesi',
    BACK_TO_MY_PROJECTS: 'nazad na moji projekte',
    PLEASE_FILL_ALL: 'Popunite sva polja da biste započeli sa 9to5.cloud',
    TERMS_AND_PRIVACY_POLICY:
      '<span class="marked">*</span> Slanjem obrasca potvrđujem da sam pročitao <a href="https://www.9to5.cloud/datenschutz" target="_blank">politiku privatnosti</a> i da se slažem sa obradom mojih ličnih podataka od strane 9to5.cloud u navedene svrhe. Ako dam svoju saglasnost, mogu da opozovem svoju saglasnost u bilo kom trenutku. Štaviše, slanjem obrasca prihvatam <a href="https://www.9to5.cloud/agb" target="_blank">opšte uslove</a>.',
    QUICK_START_TITLE: 'QUICK START GUIDE',
    QUICK_START_GUIDE: 'Započnite i saznajte više o funkcijama 9to5!',
    USER_EMAIL_VALIDATION: 'Molimo koristite važeću e-mail',
    MOBILE_CHOOSE_PROJECT: 'Izaberite projekat',
    SELECT_TIME: 'Izaberite vreme',
    MOBILE_SELECT_TASK: 'Izaberite zadatak',
    MOBILE_CHOOSE_FILE: 'Izaberite fajl',
    MOBILE_GALLERY: 'Galerija',
    MOBILE_CAMERA: 'Kamera',
    MOBILE_DOCUMENT: 'Fajl',
    MOBILE_PROJECTS_MANAGED_BY_ME: 'Projekti kojima upravljam:',
    MOBILE_HOME_LAST_ENTRY: 'Poslednji unos:',
    MOBILE_PROJECTS_NOBODY_ASSIGNED: 'NIKO DODELJEN',
    MOBILE_PROJECTS_SELECT_ALL: 'Izaberite sve',
    MOBILE_PROJECTS_ENTRY_FOR: 'Unos zu',
    MOBILE_PROJECTS_OF: 'od',
    MOBILE_BOOK_TIME: 'UNESITE VREME',
    JANUARY: 'Januar',
    FEBRUARY: 'Februar',
    MARCH: 'Mart',
    APRIL: 'April',
    MAY: 'Maj',
    JUNE: 'Jun',
    JULY: 'Juli',
    AUGUST: 'Avgust',
    SEPTEMBER: 'Septembar',
    OCTOBER: 'Oktobar',
    NOVEMBER: 'Novembar',
    DECEMBER: 'Decembar',
  },
}
