import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CreateProjectComponent } from './create-project/create-project.component'
import { ProjectOverviewComponent } from './project-overview/project-overview.component'
import { ProjectModifyComponent } from './project-overview/project-modify/project-modify.component'
import { ProjectMembersCardsComponent } from './project-overview/project-members-cards/project-members-cards.component'
import { NoneManagerGuard } from 'app/utility/none-manager.guard'
import { ProjectUsersBudgetComponent } from 'app/pages/projects/project-overview/project-users-budget/project-users-budget.component'
import { AddUsersComponent } from './project-overview/add-users/add-users.component'
import { AllProjectsOverviewComponent } from './all-projects-overview/all-projects-overview.component'
import { MonthOverviewComponent } from './project-overview/month-overview/month-overview.component'
import { UserMonthOverviewComponent } from './project-overview/user-month-overview/user-month-overview.component'
import { CreateNewProjectFlowComponent } from './create-new-project-flow/create-new-project-flow.component'
import { CreateProjectStepOneComponent } from './create-new-project-flow/create-project-step-one/create-project-step-one.component'
import { CreateProjectStepTwoComponent } from './create-new-project-flow/create-project-step-two/create-project-step-two.component'
import { CreateProjectStepThreeComponent } from './create-new-project-flow/create-project-step-three/create-project-step-three.component'
import { CreateProjectStepFourComponent } from './create-new-project-flow/create-project-step-four/create-project-step-four.component'
import { ProjectApprovalsCardComponent } from './project-overview/project-approvals-card/project-approvals-card.component'
import { SpecificTimesheetComponent } from '../timesheet/specific-timesheet/specific-timesheet.component'
const routes: Routes = [
  {
    path: 'create-project',
    component: CreateProjectComponent,
  },
  {
    path: 'create-new-project',
    component: CreateNewProjectFlowComponent,
    children: [
      {
        path: 'step-1/:id',
        component: CreateProjectStepOneComponent,
      },
      {
        path: 'step-2/:id',
        component: CreateProjectStepTwoComponent,
      },
      {
        path: 'step-3/:id',
        component: CreateProjectStepThreeComponent,
      },
      {
        path: 'step-4/:id',
        component: CreateProjectStepFourComponent,
      },
    ],
  },
  {
    path: 'my-projects-list',
    component: AllProjectsOverviewComponent,
    data: {
      currentUser: true,
    },
  },
  {
    path: 'projects-list',
    component: AllProjectsOverviewComponent,
  },
  {
    path: 'projects-list/:id',
    component: AllProjectsOverviewComponent,
  },
  {
    path: 'edit/:id',
    component: ProjectModifyComponent,
    children: [
      {
        path: 'step-1/:id',
        component: CreateProjectStepOneComponent,
      },
      {
        path: 'step-2/:id',
        component: CreateProjectStepTwoComponent,
      },
    ],
  },
  {
    path: 'project-overview/:id',
    component: ProjectOverviewComponent,
    canActivate: [NoneManagerGuard],
    children: [
      {
        path: 'team-members',
        component: ProjectMembersCardsComponent,
      },
      {
        path: 'team-members/user-budget',
        component: ProjectUsersBudgetComponent,
      },
      {
        path: 'team-members/add-user',
        component: AddUsersComponent,
      },
      {
        path: 'used-budget',
        component: ProjectApprovalsCardComponent,
      },
      {
        path: 'used-budget/overview-month-booked',
        component: MonthOverviewComponent,
      },
      {
        path: 'used-budget/specific-timesheet/:id/:projectId',
        component: SpecificTimesheetComponent,
      },
      {
        path: 'team-members/overview-month-user',
        component: UserMonthOverviewComponent,
      },
      {
        path: 'team-members/specific-timesheet/:id/:projectId',
        component: SpecificTimesheetComponent,
      },
    ],
  },
  {
    path: 'update-project/:id',
    component: CreateProjectComponent,
  },
  { path: 'projects', redirectTo: 'projects/projects-list', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule {}
