import { KnowHow } from './../core/model/know-how'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class UserSharedService {
  private knowladgeList: any[] = new Array()
  private projectsArray: KnowHow[] = new Array()
  passwordSet: boolean = false
  usernameExist: boolean = false
  customerNameExist: boolean = false
  emailExist: boolean = false
  constructor() {}

  public setKowladge(list: any[]) {
    this.knowladgeList = list
  }

  public getKnowladge() {
    return this.knowladgeList
  }
  public setUsernameExist(username: boolean) {
    this.usernameExist = username
  }

  public getUsernameExist() {
    return this.usernameExist
  }
  public resetProjects() {
    this.projectsArray = []
  }

  public setProject(project: any) {
    this.projectsArray.push(project)
  }

  public getKnowHows() {
    return this.projectsArray
  }
  public setPassword(setPaswword: boolean) {
    this.passwordSet = setPaswword
  }

  public getPassword() {
    return this.passwordSet
  }

  setEmailExist(email: boolean) {
    this.emailExist = email
  }

  public getEmailExist() {
    return this.emailExist
  }

  setCustomerNameExist(customerNameExist: boolean) {
    this.customerNameExist = customerNameExist
  }

  public getCustomerNameExist() {
    return this.customerNameExist
  }
}
