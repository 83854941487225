import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { NzTableModule } from 'ng-zorro-antd/table'
import * as dayjs from 'dayjs'
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { NzConfigService } from 'ng-zorro-antd/core/config'
import { cloneDeep, isEqual } from 'lodash'
import { FormsModule } from '@angular/forms'
import { TABLE_TYPE } from './table.component.model'
import { TableFilterPipe } from 'app/utility/table-filter.pipe'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzProgressModule } from 'ng-zorro-antd/progress'
import { SelectComponent } from '../select/select.component'
import { PROJECTSTATUS } from 'app/pages/statusProject'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { ProgressBarComponent } from '../progress-bar/progress-bar.component'
import { SanitizedHtmlPipe } from './sanitized-html.pipe'
import { MenuDropdownComponent } from '../menu-dropdown/menu-dropdown.component'
import { TruncatePipe } from './truncate.pipe'
import { ElipsisDirective } from './elipsis.directive'
import { EllipsisPipe } from './ellipsis.pipe'
import { TextBreakPipe } from './text-break.pipe'

dayjs.extend(isSameOrBefore)

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzTableModule,
    TranslateModule,
    TableFilterPipe,
    InlineSVGModule,
    NzAvatarModule,
    NzProgressModule,
    SelectComponent,
    NzCheckboxModule,
    ProgressBarComponent,
    SanitizedHtmlPipe,
    MenuDropdownComponent,
    TruncatePipe,
    ElipsisDirective,
    EllipsisPipe,
    TextBreakPipe,
  ],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterViewInit, OnChanges {
  @Input() dataList: any[]
  @Input() customHeadings: any
  @Input() sortableColumns: any
  @Input() selectable: boolean
  @Input() selectedByRowClick: boolean
  @Input() disableCellPropagation: boolean
  @Input() pageSize: number = 5
  @Input() allowSelectAll: boolean = true
  @Input() hiddenColumns: string[] = []
  @Input() rowClickable: boolean
  @Input() search: string = ''
  @Input() selectDisabled: boolean
  @Input() pagination: any
  @Input() sort: { column: string; order: string | null } = { column: '', order: null }
  @Input() frontendPagination: boolean = true
  @Input() applyCustomHeadingOnClass: string[]
  @Output() selectionChange = new EventEmitter<any[]>()
  @Output() selectAll = new EventEmitter<any[]>()
  @Output() selectionChangeOneItem = new EventEmitter<any[]>()
  @Output() rowClick = new EventEmitter<any[]>()
  @Output() cellClick = new EventEmitter<any[]>()
  @Output() inputValueChanged = new EventEmitter<{ data: any; inputValue: any }>()
  @Output() checkedValueChanged = new EventEmitter<{ data: any; inputValue: any }>()
  @Output() paginationChange = new EventEmitter()
  @Output() sortingChange = new EventEmitter()
  @Output() statusSelectChange = new EventEmitter()
  @Output() selectedTimesheet = new EventEmitter()
  rowKeys: string[]
  columns: any[]
  selectedItems: any[] = []
  objectData: any = ''
  TABLE_TYPE = TABLE_TYPE
  pageSizeOptions = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ]
  defaultDate = new Date(1970, 1, 1, 1, 1)

  showTeamMembers = window.innerWidth <= 1250 ? 2 : 3

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showTeamMembers = window.innerWidth <= 1250 ? 2 : 3
  }

  projectStatuses = this.getTranslatedStatuses()
  getTranslatedStatuses() {
    return [
      {
        value: PROJECTSTATUS.DRAFT,
        label: this.translate.instant('DRAFT'),
      },
      {
        value: PROJECTSTATUS.FINISHED,
        label: this.translate.instant('FINISHED'),
      },
      {
        value: PROJECTSTATUS.PAUSED,
        label: this.translate.instant('PAUSED'),
      },
      {
        value: PROJECTSTATUS.RUNNING,
        label: this.translate.instant('RUNNING'),
      },
    ]
  }

  constructor(private nzConfigService: NzConfigService, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customHeadings || changes.dataList) {
      this.reRenderTable()
    }
    if (this.dataList.length) {
      this.selectedItems = []
      this.dataList.forEach(element => {
        if (element.isChecked) {
          this.selectedItems.push(element)
        }
      })
    }
  }
  sizeChange(event: any) {
    if (event.value) {
      this.pageSize = event.value
      this.pagination.pageSize = event.value
    } else {
      this.pageSize = event
      this.pagination.pageSize = event
    }

    this.pagination.currentPage = 0
    this.modelChange()
  }
  pageIndexChange(event: any) {
    this.pagination && (this.pagination.currentPage = event - 1)
    this.modelChange()
  }
  ngAfterViewInit(): void {
    this.showTeamMembers = window.innerWidth <= 1175 ? 2 : 3
    this.nzConfigService.set('theme', { primaryColor: '#1BC5BD' })
    this.reRenderTable()
    this.translate.onLangChange.subscribe(() => (this.projectStatuses = this.getTranslatedStatuses()))
  }

  determineSortType(type: string, key: string) {
    switch (type) {
      case 'date':
        return (a: any, b: any) =>
          dayjs(a[key].value, 'h:mm A').isSameOrBefore(dayjs(b[key].value, 'h:mm A')) ? 1 : -1
      case 'number':
        return (a: any, b: any) => a[key] - b[key]
      case 'length':
        return (a: any, b: any) => a[key].list.length - b[key].list.length
      case 'percentage':
        return (a: any, b: any) => a[key].percentage - b[key].percentage
      // Meant to be expanded in need of currently unexistent type sorting
      default:
        // Default is string
        return (a: any, b: any) => {
          if (a[key]?.type && b[key]?.type) {
            return a[key].string.localeCompare(b[key].string)
          }
          return a[key]?.localeCompare(b[key])
        }
    }
  }

  itemIsSelected(data: any) {
    return !!this.selectedItems.find(selected => isEqual(selected, data))
  }

  onItemSelected(data: any) {
    if (data) {
      this.itemIsSelected(data)
        ? (this.selectedItems = this.selectedItems.filter(selected => !isEqual(selected, data)))
        : this.selectedItems.push(data)
      this.selectionChange.emit(this.selectedItems)
      this.selectionChangeOneItem.emit(data)
    }
  }

  onSelectAll() {
    this.selectedItems = this.selectedItems.length < this.dataList.length ? cloneDeep(this.dataList) : []
    this.selectionChange.emit(this.selectedItems)
    this.selectAll.emit(this.dataList)
  }

  reRenderTable() {
    this.rowKeys = this.dataList?.length
      ? Object.keys(this.dataList[0]).filter(
          key => key !== 'key' && !(this.hiddenColumns.length && this.hiddenColumns.includes(key))
        )
      : []

    this.columns = this.rowKeys.map(key => ({
      key,
      title: this.customHeadings ? this.customHeadings[key] : key,
      ...(this.sortableColumns &&
        this.sortableColumns[key] && { compare: this.determineSortType(this.sortableColumns[key], key) }),
      filters: this.customHeadings
        ? this.customHeadings[key]?.filters != null
          ? this.customHeadings[key].filters
          : null
        : null,
      action: this.customHeadings
        ? this.customHeadings[key]?.action != null
          ? this.customHeadings[key].action
          : null
        : null,
    }))
  }

  handleRowClick(data: any) {
    if (!this.rowClickable) return
    this.selectedByRowClick ? this.onItemSelected(data) : this.rowClick.emit(data)
  }

  handleCellClick(event: Event, data: any) {
    // this.disableCellPropagation = true will disable propagation, and will result in `this.rowClick` not being usable
    this.disableCellPropagation && event.stopPropagation()
    this.cellClick.emit(data)
  }

  sortChange(column: string, order: string | null) {
    // (nzSortOrderChange) gets triggered twice when there's sort exiting and new one is triggered on different column
    if (this.sort.column !== column) {
      order = 'ascend'
    }
    if (order === null) order = 'ascend'

    this.sort = {
      column,
      order,
    }
    this.sortingChange.emit(this.sort)
  }

  public modelChange() {
    this.paginationChange.emit(this.pagination)
  }

  handleInput(event: Event, data: any) {
    const inputElement = event.target as HTMLInputElement

    if (inputElement) {
      if (parseFloat(inputElement.value) < 0) {
        inputElement.value = Math.abs(parseFloat(inputElement.value)).toString()
      }
      const inputValue = parseFloat(inputElement.value)

      if (isNaN(inputValue)) {
        inputElement.value = data.userAllocation
      } else if (data.userConsumedBudget && inputValue < data.userConsumedBudget) {
        inputElement.value = data.userAllocation ? data.userAllocation : 0
      }
      this.inputValueChanged.emit({ data: data, inputValue: inputValue })
    }
  }

  handleCheckbox(event: Event, data: any) {
    this.checkedValueChanged.emit({ data: data, inputValue: event })
  }

  statusChanges(projectId: string, statusId: string) {
    this.statusSelectChange.emit({ projectId: projectId, statusId: statusId })
  }
}

export const useStringAsTranslationObject = (name: string, split?: boolean) => ({
  type: split ? TABLE_TYPE.MULTILINE_TRANSLATED_STRING : TABLE_TYPE.TRANSLATED_STRING,
  string: name,
})

export const createHeaderWithFilter = (
  name: string,
  filters: any[],
  action: (event: any) => void,
  split?: boolean
) => ({
  type: TABLE_TYPE.TRANSLATED_STRING,
  string: name,
  filters: filters,
  action: action,
  split: split || false,
})
