import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class companyImageShared {
  selectedFile: File
  deletedFile: boolean = false
  constructor() {}

  setImageFile(file: File) {
    this.selectedFile = file
    this.deletedFile = false
  }
  getImageFile() {
    return this.selectedFile
  }
  deleteImage() {
    this.deletedFile = true
  }
  getDeleted() {
    return this.deletedFile
  }
}
