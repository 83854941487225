import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DynamicContentService {
  private _contentFormResult: BehaviorSubject<any> = new BehaviorSubject(null)

  get contentFormResult() {
    return this._contentFormResult
  }

  setResult(result: any) {
    this._contentFormResult.next(result)
  }
}
