import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { coerceBooleanProperty, BooleanInput } from '@angular/cdk/coercion'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-switch',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input() checked: boolean = false
  @Output() handleChange: EventEmitter<Event> = new EventEmitter()

  private _disabled = false
  @Input()
  get disabled() {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
  }
  constructor() {}

  ngOnInit(): void {}

  handleSwitchChange(event: Event) {
    this.handleChange.emit(event)
  }
}
