import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DropdownComponent } from '../dropdown/dropdown.component'
import { ButtonComponent } from '../button/button.component'
import { IButtonAction } from './menu-dropdown.component.model'
import { TranslateModule } from '@ngx-translate/core'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzIconModule } from 'ng-zorro-antd/icon'

@Component({
  selector: 'app-menu-dropdown',
  standalone: true,
  imports: [CommonModule, DropdownComponent, ButtonComponent, TranslateModule, NzDropDownModule, NzIconModule],
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent {
  @Input() title: string
  @Input() actions: IButtonAction[]
  constructor() {}
}
