<div class="select_wrapper">
  <ng-template #renderTemplate>
    <div class="new">
      <a class="ant-select-item ant-select-item-option ng-star-inserted" (click)="openAddNewModal()">
        <i nz-icon nzType="plus"></i>
        + {{ addNewOptions.text | translate }}
      </a>
    </div>
  </ng-template>
  <nz-select
    *ngIf="options?.length || isCustomers"
    class="select_field"
    (nzOpenChange)="onOpenChange($event)"
    [(nzOpen)]="isOpenSelect"
    [ngClass]="{ status: type === 'status', primary: type === 'primary' }"
    [nzSize]="size"
    [(ngModel)]="selectedValue"
    [compareWith]="compareFn"
    [nzAllowClear]="allowClear"
    [nzShowArrow]="true"
    [disabled]="inputDisable"
    [nzDropdownClassName]="'custom'"
    [nzPlaceHolder]="placeholder"
    (ngModelChange)="modelChange($event)"
    [nzShowSearch]="showSearch"
    [nzDropdownRender]="renderTemplate"
  >
    <nz-option
      *ngFor="let option of options"
      [nzValue]="option"
      [nzLabel]="isTimeSheetMonths ? (option | localizedDate : 'MMMM yyyy') : option[label] ?? option"
    ></nz-option>
  </nz-select>
</div>
<app-modal
  [openModalSubject$]="openModalSubject"
  [content]="addNewOptions.content"
  (fetchResult)="addNew($event)"
></app-modal>
