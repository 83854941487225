import { DatePipe } from '@angular/common'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DaysCount {
  constructor(private datepipe: DatePipe) {}

  getBudget(start: any, end: any) {
    let startDate = new Date(start)
    let endDate = new Date(end)
    let numOfDates = this.getBusinessDatesCount(startDate, endDate)
    return numOfDates
  }
  getBusinessDatesCount(startDate: any, endDate: any) {
    let count = 0
    const curDate = new Date(startDate.getTime())
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++
      curDate.setDate(curDate.getDate() + 1)
    }
    return count
  }
  getDate(date: Date) {
    let year
    let day
    let month
    year = date.getFullYear()
    month = date.toLocaleString('en-us', { month: '2-digit' })
    day = date.getDate()
    return this.datepipe.transform(date, 'dd.MM.yyyy')
  }

  getTeamDaysLeft(daysLeft: number, teamMembers: number) {
    return daysLeft * teamMembers
  }
  getProcentageLeft(daysLeft: number, timePeriod: number) {
    return 100 - (daysLeft / timePeriod) * 100
  }
  getprocentageTeamLeft(daysLeft: number, timePeriod: number, teamMembers: number) {
    return 100 - (daysLeft / (timePeriod * teamMembers)) * 100
  }
  getDaysLeft(endDate: any) {
    let count = 0
    const curDate = new Date()
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++
      curDate.setDate(curDate.getDate() + 1)
    }
    return count
  }
}
