import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, from } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { KeycloakService } from 'keycloak-angular'
import { UserSharedService } from 'app/pages/users/user-shared.service'

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService, private userShared: UserSharedService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.keycloakService.isLoggedIn()).pipe(
      mergeMap(isSessionActive => {
        if (
          req.url.includes('/api/companies/register-company') ||
          req.url.includes('/api/companies/is-name-taken-registration') ||
          req.url.includes('/api/users/is-username-taken-registration') ||
          req.url.includes('/api/users/is-email-taken-registration')
        ) {
          return next.handle(req)
        }
        if (isSessionActive || this.userShared.getPassword()) {
          return next.handle(req)
        } else {
          this.keycloakService.logout()
          return new Observable<HttpEvent<any>>()
        }
      })
    )
  }
}
