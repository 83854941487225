const languages: any[] = [
  {
    id: 1,
    name: 'SERBIAN',
  },
  {
    id: 2,
    name: 'ENGLISH',
  },
  {
    id: 3,
    name: 'GERMAN',
  },
  {
    id: 4,
    name: 'AFRICAN',
  },
  {
    id: 5,
    name: 'ALBANIAN',
  },
  {
    id: 6,
    name: 'ARABIC',
  },
  {
    id: 7,
    name: 'BELARUSIAN',
  },
  {
    id: 8,
    name: 'BOSNIAN',
  },
  {
    id: 9,
    name: 'BULGARIAN',
  },
  {
    id: 10,
    name: 'CHINESE',
  },
  {
    id: 11,
    name: 'CROATIAN',
  },
  {
    id: 12,
    name: 'CZECH',
  },
  {
    id: 13,
    name: 'DANISH',
  },
  {
    id: 14,
    name: 'DUTCH',
  },
  {
    id: 15,
    name: 'EGYPTIAN',
  },
  {
    id: 16,
    name: 'ESTONIAN',
  },
  {
    id: 17,
    name: 'FILIPINO',
  },
  {
    id: 18,
    name: 'FRENCH',
  },
  {
    id: 19,
    name: 'GREEK',
  },
  {
    id: 20,
    name: 'HUNGARIAN',
  },
  {
    id: 21,
    name: 'ICELANDIC',
  },
  {
    id: 22,
    name: 'INDIAN',
  },
  {
    id: 23,
    name: 'IRISH',
  },
  {
    id: 24,
    name: 'ITALIAN',
  },
  {
    id: 25,
    name: 'JAPANESE',
  },
  {
    id: 26,
    name: 'KOREAN',
  },
  {
    id: 27,
    name: 'KURDISH',
  },
  {
    id: 28,
    name: 'LATVIAN',
  },
  {
    id: 29,
    name: 'LITHUANIAN',
  },
  {
    id: 30,
    name: 'LUXEMBOURGISH',
  },
  {
    id: 31,
    name: 'MACEDONIAN',
  },
  {
    id: 32,
    name: 'NORWEGIAN',
  },
  {
    id: 33,
    name: 'PERSIAN',
  },
  {
    id: 34,
    name: 'POLISH',
  },
  {
    id: 35,
    name: 'PORTUGUESE',
  },
  {
    id: 36,
    name: 'ROMANIAN',
  },
  {
    id: 37,
    name: 'RUSSIAN',
  },
  {
    id: 38,
    name: 'SCOTTISH',
  },
  {
    id: 39,
    name: 'SLOVENIAN',
  },
  {
    id: 40,
    name: 'SLOVAK',
  },
  {
    id: 41,
    name: 'SPANISH',
  },
  {
    id: 42,
    name: 'SWEDISH',
  },
  {
    id: 43,
    name: 'SYRIAN',
  },
  {
    id: 44,
    name: 'THAI',
  },
  {
    id: 45,
    name: 'TURKISH',
  },
  {
    id: 46,
    name: 'UKRAINIAN',
  },
  {
    id: 47,
    name: 'VIETNAMESE',
  },
]

export { languages }
