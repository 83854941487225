import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-specific-user-timesheet-view',
  templateUrl: './specific-user-timesheet-view.component.html',
  styleUrls: ['./specific-user-timesheet-view.component.scss'],
})
export class SpecificUserTimesheetViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
