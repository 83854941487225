import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { Observable } from 'rxjs'
import { ApiResponse } from '../dto/ApiResponse'
import { KnowledgeDto } from '../model/knowledge.model'
import { Project } from '../model/project.model'

@Injectable({
  providedIn: 'root',
})
export class KnowladgeService {
  knowledgeUrl: string = environment.apiBase + '/knowledges'
  usersUrl: string = environment.apiBase + '/users'
  projectUrl: string = environment.apiBase + '/projects'

  constructor(private http: HttpClient) {}

  getAllKnowledges(): Observable<ApiResponse<KnowledgeDto[]>> {
    return this.http.get<ApiResponse<KnowledgeDto[]>>(this.knowledgeUrl + '/find-all')
  }

  getAllKnowledgesNoPagination(): Observable<ApiResponse<KnowledgeDto[]>> {
    return this.http.get<ApiResponse<KnowledgeDto[]>>(this.knowledgeUrl + '/get-all')
  }

  removeKnowledge(projectId: string, knowledgeId: number): Observable<ApiResponse<Project>> {
    return this.http.delete<ApiResponse<Project>>(
      this.projectUrl + '/remove-knowledge/' + projectId + '/' + knowledgeId
    )
  }

  newKnowledgeEntry(body: any): Observable<ApiResponse<KnowledgeDto>> {
    return this.http.post<ApiResponse<KnowledgeDto>>(this.knowledgeUrl + '/create-knowledge', body)
  }

  deleteKnowledge(knowledgeId: number): Observable<ApiResponse<KnowledgeDto>> {
    return this.http.delete<ApiResponse<KnowledgeDto>>(this.knowledgeUrl + '/delete-knowledge/' + knowledgeId)
  }

  getAllTemplateKnowledges(
    page: number,
    size: number,
    sortBy: string,
    sortDirection?: string,
    search?: any
  ): Observable<ApiResponse<any[]>> {
    let paramMap: HttpParams = new HttpParams()
      .set('page', page.toString())
      .append('size', size.toString())
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))

    if (search && search !== '') {
      paramMap = paramMap.append('searchInput', search)
    }

    return this.http.get<ApiResponse<any[]>>(`${this.knowledgeUrl}/find-all`, { params: paramMap })
  }

  unarchiveKnowledge(knowledgeId: string): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(this.knowledgeUrl + '/unarchive-knowledge/' + knowledgeId)
  }

  getAllUnarchivedKnowledges(): Observable<ApiResponse<KnowledgeDto[]>> {
    return this.http.get<ApiResponse<KnowledgeDto[]>>(this.knowledgeUrl + '/get-all-unarchived')
  }

  filterUsersByKnowledgeOrLanguageOrSearch(
    body: any,
    page: number,
    size: number,
    sortBy?: string,
    sortDirection?: string
  ) {
    const requestOptions: Object = {
      params: {
        ...{ page: page },
        ...(size && { size: size }),
        ...(sortBy && { sort: `${sortBy},${sortDirection}` }),
      },
    }
    return this.http.post<ApiResponse<any>>(
      this.usersUrl + '/filter-by-search-term-and-knowledge',
      body,
      requestOptions
    )
  }
}
