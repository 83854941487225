<div style="background-color: #ffffff; border-radius: 6px; padding: 5px">
  <div class="d-flex align-items-center mt-4 mb-4 ms-2" style="padding: 0 29px">
    <div class="d-flex">
      <app-button
        [title]="'BACK' | translate | titlecase"
        style="margin-right: 15px"
        (handleClick)="back()"
      ></app-button>
    </div>
    <div class="card_title">
      {{ monthOfYear }} <span>{{ year }}</span> | SUM : {{ days | number : '1.2-2' }} DAYS ({{
        hours | number : '1.2-2'
      }}
      HOURS)
    </div>
  </div>
  <div class="table-wrapper">
    <app-table
      class="project-members-table"
      [customHeadings]="tableHeadings"
      *ngIf="tableData"
      [rowClickable]="true"
      [dataList]="tableData"
      [hiddenColumns]="['id', 'statusColor']"
      (rowClick)="redirectToAnotherPage($event)"
      [sortableColumns]="[]"
    ></app-table>
  </div>
</div>
<app-approve-timesheets-modal
  [openModalSubject$]="openModalApproveTimesheetsSubject$"
  (approveTimesheets)="approveTimesheets($event)"
></app-approve-timesheets-modal>
