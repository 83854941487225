import { Pipe, PipeTransform } from '@angular/core'
import { Project } from 'app/pages/core/model/project.model'
import { BehaviorSubject } from 'rxjs'
@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  transform(projectList: any[], status: number): Project[] {
    return status ? projectList.filter(project => project.status.value === status) : projectList
  }
}
