import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-all-projects-overview',
  templateUrl: './all-projects-overview.component.html',
  styleUrls: ['./all-projects-overview.component.scss'],
})
export class AllProjectsOverviewComponent implements OnInit {
  activeStatues: any[] = [
    {
      value: 'd46058cd-4f73-4dab-b051-af0c97910da8',
      label: 'RUNNING',
    },
    {
      value: '0ad5387e-9f03-4ff7-af9d-504d4d899754',
      label: 'PAUSED',
    },
  ]

  inactiveStatues: any[] = [
    {
      value: 'a4956773-4af7-4c04-9587-b200dc93187b',
      label: 'DRAFT',
    },
    {
      value: '18e397fe-6cfd-4b1c-ae10-fa9927745b7a',
      label: 'FINISHED',
    },
  ]

  currentStep$: BehaviorSubject<number> = new BehaviorSubject(0)
  statuses$: BehaviorSubject<string[]> = new BehaviorSubject(this.activeStatues as string[])

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const paramValue = params['activeTab']
      //convert to number for truthy chek
      if (+paramValue) {
        this.currentStep$.next(+paramValue)
        this.filterStatuesByStep()
      } else {
        this.navigateToTab(1)
      }
    })
  }

  filterStatuesByStep() {
    if (this.currentStep$.value === 1) {
      this.statuses$.next(this.activeStatues)
    } else if (this.currentStep$.value === 2) {
      this.statuses$.next(this.inactiveStatues)
    } else {
      this.statuses$.next([...this.activeStatues, ...this.inactiveStatues])
    }
    this.navigateToTab(this.currentStep$.value)
  }

  setStep(step: number) {
    this.currentStep$.next(step)
    this.filterStatuesByStep()
  }

  navigateToTab(tabNumber: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { activeTab: tabNumber },
      queryParamsHandling: 'merge',
    })
  }
}
