import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { UserImageSharedService } from '../../user-image-shared.service'
import { UserService } from 'app/pages/core/services/user.service'
import { User } from 'app/pages/core/model/flexc-user.model'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { ActivatedRoute } from '@angular/router'
import { environment } from 'environments/environment'
import { ModalConfig } from 'app/_metronic/partials/layout/modals/modal.config'
import { ErrorModalComponent } from 'app/_metronic/partials/layout/modals/error-modal/error-modal.component'
import { BehaviorSubject } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss'],
})
export class UserProfileImageComponent implements OnInit {
  @ViewChild('errorModal') private modalComponentError: ErrorModalComponent

  @Input() form: FormGroup | undefined
  @Output() modalCloseEmitter: EventEmitter<boolean> = new EventEmitter()

  imageSrc: string = ''
  image?: File
  userId?: string

  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.translate.instant('UPDATE_IMAGE_SIZE_ERROR')
  )

  modalConfig: ModalConfig = {
    modalBodyTitle: this.titleForModal,
    closeButtonLabel: 'Ok',
    onClose: () => {
      this.modalClose()
      return false
    },
    onDismiss: () => {
      this.modalClose()
      return true
    },
  }

  modalClose() {
    this.modalCloseEmitter.emit(true)
  }

  constructor(
    private projectSharedService: UserImageSharedService,
    private userService: UserService,
    private route: ActivatedRoute,
    private userImage: UserImageSharedService,
    private translate: TranslateService,
    private userImageShared: UserImageSharedService
  ) {}

  ngOnInit(): void {
    this.image && this.userImage.setImageFile(this.image)
    this.imageSrc = 'assets/media/logos/ICON_USER.svg'

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.userId = params['id']
        if (this.projectSharedService.getImageUrl()) {
          this.imageSrc = `${environment.imgUrl}${this.projectSharedService.getImageUrl()}`
        }
      }
    })
  }

  async openErrorModal() {
    return await this.modalComponentError.open()
  }

  readURL(event: any) {
    const file = (event.target as HTMLInputElement).files![0]

    const maxSizeInBytes = 5 * 1024 * 1024
    if (file.size > maxSizeInBytes) {
      this.openErrorModal()
      return
    } else {
      this.projectSharedService.setImageFile(file)

      const reader = new FileReader()
      reader.onload = () => {
        this.imageSrc = reader.result as string
        this.form!.get('profileImage')?.setValue(file)
      }
      reader.readAsDataURL(file)
    }
  }

  get formControls() {
    return this.form!.controls
  }

  deleteImage() {
    this.imageSrc = 'assets/media/logos/ICON_USER.svg'
    this.projectSharedService.setImageFile(undefined)
    if (!this.userId || !this.imageSrc) {
      return
    }
    this.userService.deleteUserImage(this.userId).subscribe({
      next: res => {
        this.projectSharedService.emitImageChange()
      },
    })
  }
}
