import { Injectable, NgZone } from '@angular/core'
import { NotificationService } from './notification.service'
import { Observable } from 'rxjs'
import { KeycloakService } from 'keycloak-angular'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { KeycloakProfile } from 'keycloak-js'

@Injectable({
  providedIn: 'root',
})
export class SseService {
  userKeycloakId: string
  constructor(
    private _zone: NgZone,
    private notificationSevice: NotificationService,
    private keycloakService: KeycloakService
  ) {
    this.keycloakService.loadUserProfile().then((val: KeycloakProfile) => {
      this.userKeycloakId = val.id!
    })
  }
  getServerSentEvent(url: string): Observable<any> {
    return new Observable<any>(obs => {
      this.keycloakService.getToken().then((res: any) => {
        const es = new EventSourcePolyfill(url, {
          headers: {
            Authorization: 'Bearer ' + res,
            'Content-Type': 'text/event-stream',
            'Cache-Control': 'no-cache',
            Connection: 'keep-alive',
            'X-Accel-Buffering': 'no',
          },
          heartbeatTimeout: 120000000000,
          withCredentials: true,
        })
        es.addEventListener(
          'keycloakId',
          (evt: any) => {
            if (evt.data === this.userKeycloakId) {
              this.notificationSevice.increasNotificationsByOne()
              obs.next(evt.data)
            }
          },
          false
        )
      })
    })
  }

  private getEventSource(url: string): EventSource {
    return new EventSource(url)
  }
}
