<div class="card-body border-top p-9 w-900">
  <div class="d-flex flex-wrap align-items-center mb-10">
    <div id="kt_signin_password_edit" class="flex-row-fluid false">
      <form [formGroup]="projectInfoForm">
        <div class="row">
          <div class="projectDetailsWraper">
            <div>
              <span> {{ 'PROJECT_DETAILS' | translate }}</span>
              <p>{{ 'BASIC_DATA' | translate }}</p>
            </div>
            <div>
              <div class="col-md-3 border-right mt-6 mb-8">
                <div class="image-input image-input-circle" data-kt-image-input="true">
                  <img
                    id="profile-image"
                    class="border border-gray-500 border-active active"
                    [src]="imageSrc || 'assets/media/logos/ICON_PROJECT.svg'"
                  />
                  <label
                    class="btn btn-icon btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow img"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="{{ 'CHANGE_PROFILE_IMAGE' | translate }}"
                  >
                    <i class="bi bi-pencil-fill fs-7"></i>
                    <input [disabled]="this.projectOverview" type="file" accept="image/*" (change)="readURL($event)" />
                  </label>
                  <label
                    class="btn btn-icon btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow img"
                    data-kt-image-input-action="remove"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    (click)="deleteImage()"
                    title="{{ 'DELETE_PROFILE_IMAGE' | translate }}"
                  >
                    <i class="bi bi-trash fs-7"></i>
                  </label>
                  <span
                    class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow"
                    data-kt-image-input-action="cancel"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Cancel avatar"
                  >
                    <i class="bi bi-x fs-2"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 border-right wraper">
            <div class="p-3 py-5">
              <div class="row mt-2">
                <div class="col-md-6">
                  <label class="form-label fs-6 fw-bolder marginBottom">{{ 'PROJECT_NUMBER' | translate }} </label>
                  <app-input formControlName="projectNumber"></app-input>
                  <div *ngIf="projectNumberTaken && projectNumber !== ''" class="invalid-feedback entry_exist">
                    {{ 'ENTRY_ALREADY_EXIST' | translate }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="form-label fs-6 fw-bolder marginBottom">{{ 'PROJECT_OFFER' | translate }} </label>
                  <app-input formControlName="projectOffer"></app-input>
                  <div *ngIf="projectOfferTaken && projectOffer !== ''" class="invalid-feedback entry_exist">
                    {{ 'ENTRY_ALREADY_EXIST' | translate }}
                  </div>
                </div>
              </div>
              <div class="row mt-7">
                <div class="col-md-12">
                  <label class="form-label fs-6 fw-bolder required marginBottom">
                    {{ 'PROJECT_NAME' | translate }}
                  </label>
                  <app-input formControlName="projectName"></app-input>
                  <div
                    *ngIf="submittedTrue && formControls['projectName']?.errors?.required"
                    class="invalid-feedback entry_required"
                  >
                    {{ 'ENTRY_IS_REQUIRED' | translate }}
                  </div>
                  <div *ngIf="projectNameTaken" class="invalid-feedback entry_exist">
                    {{ 'ENTRY_ALREADY_EXIST' | translate }}
                  </div>
                  <div *ngIf="formControls['projectName'].hasError('maxlength')" class="invalid-feedback entry_exist">
                    {{ 'MAXIMUM_CHARACTERS_IS_50' | translate }}
                  </div>
                </div>
              </div>
              <div class="row mt-7">
                <label class="form-label fs-6 fw-bolder required mb-3">{{ 'CUSTOMER' | translate }}</label>
                <app-select-with-new
                  [options]="customers"
                  [label]="'label'"
                  type="primary"
                  size="large"
                  (statusSelectChange)="setValueToCustomer($event)"
                  [addNewOptions]="addNewOptions"
                ></app-select-with-new>
                <div
                  *ngIf="submittedTrue && formControls['customer']?.errors?.required"
                  style="display: block"
                  class="invalid-feedback entry_required"
                >
                  {{ 'ENTRY_IS_REQUIRED' | translate }}
                </div>
              </div>
              <div class="row mt-7">
                <div class="col-md-12">
                  <label class="form-label fs-6 fw-bolder marginBottom required">
                    {{ 'SHORT_DESCRIPTION' | translate }}
                  </label>
                  <div>
                    <app-input formControlName="description"></app-input>
                  </div>
                </div>
                <div
                  *ngIf="submittedTrue && formControls['description']?.errors?.required"
                  class="invalid-feedback entry_required"
                >
                  {{ 'ENTRY_IS_REQUIRED' | translate }}
                </div>
                <div *ngIf="formControls['description'].hasError('minlength')" class="invalid-feedback entry_exist">
                  {{ 'MINIMUM_CHARACTERS_IS_50' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
