import { Injectable } from '@angular/core'
import { SharedService } from 'app/pages/projects/shared.service'

@Injectable({
  providedIn: 'root',
})
export class HoursConverterService {
  constructor(private sharedService: SharedService) {}

  convertDaysToHoursAndMinutes(decimalHours: number) {
    var days = Math.floor(decimalHours / 24)
    var remainder = decimalHours % 24
    var hours = Math.floor(remainder)

    var minutes = Math.floor(60 * (remainder - hours))
    if (days != 0) {
      hours = hours + 24 * days
    }
    if (hours === 0 && minutes === 0) {
      return '0h'
    }
    return `${hours != 0 ? hours + 'h' : ''} ${minutes != 0 ? minutes + 'm' : ''}`.trim()
  }

  convertDaysAndHours(decimalDays: number) {
    let days = Math.floor(decimalDays)
    let hours = decimalDays * this.sharedService.workingHours - days * this.sharedService.workingHours
    return `${days != 0 ? days + 'd' : ''} ${hours != 0 ? Math.floor(hours) + 'h' : ''}`.trim()
  }
}
