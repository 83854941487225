<ng-template #modalSuccess>
  <div class="modal-content" style="background-color: #1bc5bd">
    <div class="modal-header" style="border: none; padding-bottom: 0">
      <div></div>
      <div
        class="btn btn-sm btn-icon btn-active-color-primary"
        (click)="dismiss()"
        *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
      >
        <span
          class="svg-icon svg-icon-1"
          [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
          style="color: #fff"
        ></span>
      </div>
    </div>
    <div class="modal-body">
      <div style="display: flex; align-items: center; justify-content: center">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/arrows/arr016.svg'"
          [setSVGAttributes]="{ width: '200', height: '200' }"
          style="color: #fff"
        ></span>
      </div>
      <p>{{ modalConfig.modalBodyTitle | async }}</p>
    </div>
  </div>
</ng-template>
