<ng-template #modalDeleteSuperUser>
  <div class="modal-content">
    <div
      class="x-icon"
      (click)="dismiss()"
      *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
    >
      <span class="svg-icon svg-icon-1" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"></span>
    </div>
    <div class="modal-body">
      <div class="imageModal">
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/delete_modal.svg'"></span>
      </div>
      <div>
        <p>{{ modalConfig.modalBodyTitle | async }}</p>
      </div>
    </div>
    <div class="buttons_footer">
      <button
        type="button"
        class="btn color"
        (click)="dismiss()"
        *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
        [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()"
      >
        {{ modalConfig.dismissButtonLabel }}
      </button>
      <button
        type="button"
        class="btn color"
        (click)="close()"
        *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
        [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()"
      >
        {{ modalConfig.closeButtonLabel }}
      </button>
    </div>
  </div>
</ng-template>
