import { environment } from 'environments/environment'
import { KeycloakService } from 'keycloak-angular'

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keyCloakUrl,
        realm: '9_to_5',
        clientId: 'web-client',
      },
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
    })
}
