import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { User } from 'app/pages/core/model/flexc-user.model'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { UserService } from 'app/pages/core/services/user.service'
import { SharedService } from '../../shared.service'
import { environment } from 'environments/environment'
import { cloneDeep } from 'lodash'

@Component({
  selector: 'app-project-workers',
  templateUrl: './project-workers.component.html',
  styleUrls: ['./project-workers.component.scss'],
})
export class ProjectWorkersComponent implements OnInit {
  @Input() projectWorkersForm: FormGroup

  @Input() submitted: boolean

  @Input() projectOverview: boolean

  @Input() projectUpdate: boolean

  projectMembers: ProjectMember[]

  @Input() totalWorkingDays: number

  project: Project
  public planedDays: number = 0
  public procentageProgresBar: number = 0
  public greather: boolean = false

  constructor(public sharedService: SharedService, private userService: UserService) {}

  ngOnInit(): void {
    this.planedDays = 0
    this.sharedService.setDays(0)
    this.project = this.sharedService.getProject()
    this.projectMembers = []
    this.projectMembers = cloneDeep(this.project.projectMembers)

    for (const iterator of this.projectMembers) {
      iterator.allocation = 0
      if (iterator.user.imageUrl!) {
        iterator.user.imageUrl = environment.imgUrl + iterator.user.imageUrl!
      }
    }
  }

  calculateDays(event: any, worker: User) {
    this.greather = false
    this.planedDays = 0
    let day: number
    day = parseInt(event.target.value)

    if (Number.isNaN(day)) {
      this.projectMembers.forEach(element => {
        if (element.user.id === worker.id) {
          element.allocation = 0
        }
      })
    } else {
      this.projectMembers.forEach(element => {
        if (element.user.id === worker.id) {
          element.allocation = day
        }
      })
    }
    this.setAllocation()
    this.setProcentageBar()

    if (this.planedDays > this.totalWorkingDays) {
      this.greather = true
      this.planedDays = 0
      this.projectMembers.forEach(element => {
        if (element.user.id === worker.id) {
          element.allocation = 0
        }
      })
      this.setAllocation()
      this.setProcentageBar()
    }
  }

  setProcentageBar() {
    if (this.totalWorkingDays > 0) {
      this.procentageProgresBar = this.planedDays / this.totalWorkingDays
      if (this.procentageProgresBar > 1) {
        this.procentageProgresBar = 100
      } else {
        this.procentageProgresBar = this.procentageProgresBar * 100
      }
    }
  }

  checkBoxChange(worker: ProjectMember) {
    const foundWorker = this.projectMembers.find(element => element === worker)
    if (foundWorker) {
      foundWorker.manager = true
    }
    this.sharedService.getProject().projectMembers = this.projectMembers
  }

  setAllocation() {
    this.projectMembers.forEach(element => {
      this.planedDays += element.allocation!
    })
    this.sharedService.getProject().projectMembers = this.projectMembers
  }
}
