import { Component, Input, OnInit } from '@angular/core'
import { KnowledgeDto } from 'app/pages/core/model/knowledge.model'
import { UserSharedService } from '../../user-shared.service'
import { TagModel } from 'ngx-chips/core/tag-model'
import { Observable, of } from 'rxjs'
import { FormGroup, FormControl } from '@angular/forms'
import { KnowladgeService } from 'app/pages/core/services/knowledge.service'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { AuthService } from 'app/modules/auth'

interface AutoCompleteModel {
  value: KnowledgeDto
  display: String
}

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.scss'],
})
export class KnowledgeComponent implements OnInit {
  @Input() form!: FormGroup

  items: any[] = []
  itemsListdb: AutoCompleteModel[] = []
  itemlistKnowledge: KnowledgeDto[] = []
  searchTerm: string = ''
  paginationObject: any = {
    pageSize: 50,
    currentPage: 0,
    totalElements: 0,
  }

  constructor(
    private userSharedService: UserSharedService,
    public knowladgeService: KnowladgeService,
    public authService: AuthService
  ) {}

  tehnologyForm = new FormGroup({
    items: new FormControl(''),
  })

  ngOnInit(): void {
    this.fetchAllKnowledges()

    this.itemlistKnowledge = this.userSharedService.getKnowladge()
    this.itemlistKnowledge.forEach(element => {
      this.items.push(element)
    })
  }

  fetchAllKnowledges() {
    this.knowladgeService
      .getAllTemplateKnowledges(this.paginationObject.currentPage, this.paginationObject.pageSize, this.searchTerm)
      .subscribe((results: ApiResponse<KnowledgeDto[]>) => {
        if (results.content) {
          results.content.forEach(element => {
            this.itemsListdb.push({ value: element, display: element.name })
          })
          this.itemsListdb = this.itemsListdb.filter(
            item => !this.itemlistKnowledge.map(knowledge => knowledge.name).includes(item.display.toString())
          )
          this.itemlistKnowledge = this.userSharedService.getKnowladge()
          this.itemlistKnowledge.forEach(element => {
            if (!this.items.some(item => item.name === element.name)) {
              this.items.push(element)
            }
          })
        }
      })
  }

  onInputChange(inputText: any) {
    this.searchTerm = inputText.target.value
    this.fetchAllKnowledges()
  }
  public onAdding(tag: TagModel): Observable<TagModel> {
    let itemAdd: any = tag
    if (!itemAdd.value.id) {
      this.createKnowledgeEntry(itemAdd.value)
    } else {
      this.itemlistKnowledge.push(itemAdd.value)
      this.itemsListdb = this.itemsListdb.filter(item => item.display != itemAdd.display)
      this.userSharedService.setKowladge(this.itemlistKnowledge)
    }
    return of()
  }
  public onItemRemoved(tag: TagModel): Observable<TagModel> {
    let knowledge: any = tag
    this.itemlistKnowledge = this.itemlistKnowledge.filter(item => item.id != knowledge.id)
    this.userSharedService.setKowladge(this.itemlistKnowledge)
    this.itemsListdb.push({
      value: knowledge,
      display: knowledge.name,
    })
    return of()
  }

  createKnowledgeEntry(name: string) {
    let knowledgeBody: KnowledgeDto = {} as KnowledgeDto

    knowledgeBody.name = name
    this.knowladgeService.newKnowledgeEntry(knowledgeBody).subscribe((res: ApiResponse<KnowledgeDto>) => {
      if (res.httpStatus === 'CREATED') {
        this.itemlistKnowledge.push(res.response)
      }
    })
  }
}
