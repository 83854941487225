import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { ModalConfig } from 'app/_metronic/partials'
import { BehaviorSubject, filter } from 'rxjs'

@Component({
  selector: 'app-create-new-project-flow',
  templateUrl: './create-new-project-flow.component.html',
  styleUrls: ['./create-new-project-flow.component.scss'],
})
export class CreateNewProjectFlowComponent implements OnInit {
  @Input() isEdit: boolean = false
  @HostBinding('class.fullWidth') hostFullWidth: boolean = false
  currentStep$: BehaviorSubject<number> = new BehaviorSubject(1)
  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>('')
  modalBodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')
  modalConfig: ModalConfig = {
    newModalTitle: this.titleForModal,
    modalBodyTitle: this.modalBodyTitle,
    dismissButtonLabel: 'No',
    closeButtonLabel: 'Yes',
    onClose: () => true,
    onDismiss: () => true,
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((res: any) => {
      this.hostFullWidth = false
      if (res.url.includes('step-1')) {
        this.currentStep$.next(1)
      }
      if (res.url.includes('step-2')) {
        this.currentStep$.next(2)
      }
      if (res.url.includes('step-3')) {
        this.currentStep$.next(3)
        this.hostFullWidth = true
      }
      if (res.url.includes('step-4')) {
        this.currentStep$.next(4)
      }
    })

    this.hostFullWidth = false
    if (this.router.url.includes('step-1')) {
      this.currentStep$.next(1)
    }
    if (this.router.url.includes('step-2')) {
      this.currentStep$.next(2)
    }
    if (this.router.url.includes('step-3')) {
      this.currentStep$.next(3)
      this.hostFullWidth = true
    }
    if (this.router.url.includes('step-4')) {
      this.currentStep$.next(4)
    }
  }
}
