<div class="row">
  <div class="card mb-5 mb-xl-20">
    <div class="collapse show">
      <div class="card-body border-top p-9 w-900">
        <div class="d-flex flex-wrap align-items-center">
          <div class="flex-row-fluid false">
            <div class="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
              <div class="stepper-nav mb-5">
                <div
                  class="stepper-item"
                  [ngClass]="{
                    current: currentStep$.value === 1,
                    completed: currentStep$.value > 1
                  }"
                >
                  <h3 class="stepper-title">{{ 'PROJECT_DETAILS' | translate }}</h3>
                  <h3 class="stepper-number">1</h3>
                </div>
                <div
                  class="stepper-item"
                  [ngClass]="{
                    current: currentStep$.value === 2,
                    completed: currentStep$.value > 2
                  }"
                >
                  <h3 class="stepper-title">{{ 'TIME_AND_BUDGET' | translate }}</h3>
                  <h3 class="stepper-number">2</h3>
                </div>
                <div
                  class="stepper-item"
                  *ngIf="!isEdit"
                  [ngClass]="{
                    current: currentStep$.value === 3,
                    completed: currentStep$.value > 3
                  }"
                >
                  <h3 class="stepper-title">{{ 'KNOWLEDGE_AND_THE_TEAM' | translate }}</h3>
                  <h3 class="stepper-number">3</h3>
                </div>
                <div
                  class="stepper-item"
                  *ngIf="!isEdit"
                  [ngClass]="{
                    current: currentStep$.value === 4,
                    completed: currentStep$.value > 4
                  }"
                >
                  <h3 class="stepper-title">{{ 'ALLOCATE_AND_FINISH' | translate }}</h3>
                  <h3 class="stepper-number">4</h3>
                </div>
              </div>
              <div class="current">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-success-modal #modalSuccess [modalConfig]="modalConfig">
  <div class="modal">
    {{ 'SAVED_SUCCESSFULLY' | translate }}
  </div>
</app-success-modal>
<app-error-modal #modalError [modalConfig]="modalConfig">
  <div class="modal">
    {{ 'ERROR_WHILE_SAVING_DATA' | translate }}
  </div>
</app-error-modal>
