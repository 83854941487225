<nz-table
  #basicTable
  [nzData]="dataList | tableFilter : search : hiddenColumns"
  class="app-table"
  [nzPageIndex]="pagination?.currentPage + 1 || 1"
  [nzShowTotal]="rangeTemplate"
  [nzScroll]="{ y: 'max-content', x: 'max-content' }"
  [nzNoResult]="noResultsTemplate"
  (nzPageIndexChange)="pageIndexChange($event)"
  [nzTotal]="pagination?.totalElements!"
  [nzFrontPagination]="frontendPagination"
  [nzPageSize]="pageSize"
>
  <thead>
    <tr>
      <th
        scope="col"
        *ngIf="selectable && dataList.length"
        [nzChecked]="selectedItems.length === dataList.length"
        [nzDisabled]="!allowSelectAll"
        [nzIndeterminate]="!!selectedItems.length && selectedItems.length < dataList.length"
        (nzCheckedChange)="onSelectAll()"
      ></th>
      <ng-container *ngFor="let column of columns">
        <ng-container *ngIf="column.filters">
          <th
            (nzSortOrderChange)="sortChange(column.key, $event)"
            *ngIf="!sortableColumns || sortableColumns.hasOwnProperty(column.key); else nonSortable"
            [nzSortOrder]="sort.order"
            [nzFilters]="column.filters"
            (nzFilterChange)="column.action($event)"
          >
            <div *ngIf="column.title?.type === TABLE_TYPE.TRANSLATED_STRING">
              {{ column.title?.string | translate }}
            </div>
            <div *ngIf="!column.title?.type">{{ column.title }}</div>
            <div *ngIf="column.key === sort.column">
              <div
                [setSVGAttributes]="{ height: '20px' }"
                *ngIf="sort.order === 'descend'"
                [inlineSVG]="'assets/media/icons/duotune/arrows/arr066.svg'"
              ></div>
              <div
                [setSVGAttributes]="{ height: '20px' }"
                *ngIf="sort.order === 'ascend'"
                [inlineSVG]="'assets/media/icons/duotune/arrows/arr065.svg'"
              ></div>
            </div>
          </th>
        </ng-container>
        <ng-container *ngIf="!column.filters">
          <th
            (nzSortOrderChange)="sortChange(column.key, $event)"
            *ngIf="!sortableColumns || sortableColumns.hasOwnProperty(column.key); else nonSortable"
            [nzSortOrder]="sort.order"
          >
            <div *ngIf="column.title?.type === TABLE_TYPE.TRANSLATED_STRING">
              {{ column.title?.string | translate }}
            </div>
            <div *ngIf="column.title?.type === TABLE_TYPE.MULTILINE_TRANSLATED_STRING" class="min-width">
              {{ column.title?.string | translate }}
            </div>
            <div *ngIf="!column.title?.type">{{ column.title }}</div>
            <div *ngIf="column.key === sort.column">
              <div
                [setSVGAttributes]="{ height: '20px' }"
                *ngIf="sort.order === 'descend'"
                [inlineSVG]="'assets/media/icons/duotune/arrows/arr066.svg'"
              ></div>
              <div
                [setSVGAttributes]="{ height: '20px' }"
                *ngIf="sort.order === 'ascend'"
                [inlineSVG]="'assets/media/icons/duotune/arrows/arr065.svg'"
              ></div>
            </div>
          </th>
        </ng-container>
        <ng-template #nonSortable>
          <th>
            <div *ngIf="column.title?.type === TABLE_TYPE.TRANSLATED_STRING">
              {{ column.title?.string | translate }}
            </div>
            <div *ngIf="column.title?.type === TABLE_TYPE.MULTILINE_TRANSLATED_STRING" style="width: 50px">
              {{ column.title?.string | translate }}
            </div>
            <div *ngIf="!column.title?.type">{{ column.title }}</div>
          </th>
        </ng-template>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of basicTable.data"
      (click)="handleRowClick(data)"
      [ngClass]="{
        clickable: rowClickable,
        gray_row: data.archived,
        inactive_user: data.active !== undefined && !data.active,
        lightGreen_row: data.knowledgeFlag,
        'approved-background': data.approved,
        cursorPointer: data.blocked?.url,
        'billed-background': data.billed
      }"
    >
      <td *ngIf="selectable" [nzChecked]="itemIsSelected(data)" (nzCheckedChange)="onItemSelected(data)"></td>
      <td
        class="table-data"
        [ngClass]="{
          image_min_width: data[key]?.type === TABLE_TYPE.SVG || data[key]?.type === TABLE_TYPE.IMAGE,
          grayedImage: data.active !== undefined && !data.active
        }"
        *ngFor="let key of rowKeys"
        (click)="handleCellClick($event, data[key])"
      >
        <div *ngIf="key === 'statusIcon'" [ngStyle]="{ width: '70px' }"></div>
        <img class="table-data_image" *ngIf="data[key]?.type === TABLE_TYPE.IMAGE" [src]="data[key].url" />
        <div class="table-data_image" style="min-width: 59px" *ngIf="data[key]?.type === TABLE_TYPE.SVG">
          <div
            [inlineSVG]="data[key].url"
            [setSVGAttributes]="
              data[key].options
                ? {
                    height: data[key].options.height,
                    width: data[key].options.width,
                    borderRadius: data[key].options.borderRadius,
                    color: '#1BC5BD'
                  }
                : { height: '45px', width: '45px', borderRadius: '45px', color: '#1BC5BD' }
            "
            (click)="$event.stopPropagation()"
          ></div>
        </div>
        <div class="table-data_image" *ngIf="data[key]?.type === TABLE_TYPE.CLICKABLE_SVG" style="min-width: 59px">
          <div
            [inlineSVG]="data[key].url"
            [setSVGAttributes]="
              data[key].options
                ? {
                    height: data[key].options.height,
                    width: data[key].options.width,
                    borderRadius: data[key].options.borderRadius,
                    color: '#1BC5BD'
                  }
                : { height: '45px', width: '45px', borderRadius: '45px', color: '#1BC5BD' }
            "
            (click)="data[key].onClick(); $event.stopPropagation()"
          ></div>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.TEXT_WITH_DESCRIPTION">
          <div class="two_row-cell" *ngIf="data[key]?.string?.length && data[key]?.description?.length">
            <span class="title-span">{{ data[key]?.string | ellipsis : data[key]?.limit / 2 }}</span>
            <span class="subtitle-span" [ngClass]="{ title_active: data.active }">{{
              data[key]?.description | ellipsis : data[key]?.limit / 2
            }}</span>
          </div>
          <div class="two_row-cell split-text" *ngIf="data[key]?.string?.length && !data[key]?.description?.length">
            <span class="title-span" [ngClass]="{ title_active: data.active }">{{
              data[key]?.string | ellipsis : data[key]?.limit
            }}</span>
          </div>
          <div class="two_row-cell split-text" *ngIf="!data[key]?.string?.length && data[key]?.description?.length">
            <span class="subtitle-span">{{ data[key]?.description | ellipsis : data[key]?.limit }}</span>
          </div>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.USERS">
          <div class="users-cell">
            <nz-avatar-group>
              <ng-container *ngFor="let member of data[key]?.list; let i = index">
                <nz-avatar
                  *ngIf="i < showTeamMembers"
                  [nzSize]="48"
                  [nzSrc]="member.image"
                  [ngStyle]="{ 'background-color': i === 0 ? 'transparent' : '#1e1e2d' }"
                >
                </nz-avatar>
                <nz-avatar
                  *ngIf="i === showTeamMembers"
                  [nzSize]="48"
                  [nzText]="'+' + (data[key]?.list.length - showTeamMembers)"
                  [ngStyle]="{ 'background-color': '#1e1e2d' }"
                >
                </nz-avatar>
              </ng-container>
            </nz-avatar-group>
          </div>
        </div>
        <div
          *ngIf="data[key]?.type === TABLE_TYPE.CHECK"
          (click)="$event.stopPropagation()"
          style="display: flex; justify-content: center; align-items: center; min-width: 50px"
        >
          <label nz-checkbox [(ngModel)]="data[key].checked!" (ngModelChange)="handleCheckbox($event, data)"></label>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.INPUT">
          <input
            type="number"
            class="plannedDays no-arrows"
            [value]="data[key]?.string"
            [step]="data[key]?.step || 1"
            [readonly]="data[key]?.readonly"
            (change)="handleInput($event, data)"
          />
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.DATE">
          <div *ngIf="data[key]?.value.getFullYear() !== defaultDate.getFullYear()">
            {{ data[key]?.value | date : 'dd.MM.YYYY' }} {{ data[key]?.value | date : 'HH:mm' }}
          </div>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.DATE_PARSED" style="max-width: 80px">
          <div *ngIf="data[key]?.value.getFullYear() !== defaultDate.getFullYear()">
            <span [ngClass]="data[key].classes[0]"> {{ data[key]?.value | date : 'dd.MM.YYYY' }}<br /> </span>
            <span [ngClass]="data[key].classes[1]">
              {{ data[key]?.value | date : 'EEEE' }}
            </span>
          </div>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.NUMBER">
          <span class="style_span">{{ data[key]?.string | number : '1.2-2' }}</span>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.BAR_GRAPH" class="table-graph-cell">
          <div *ngIf="data[key]?.total">
            <app-progress-bar
              [percentage]="data[key]?.percentage"
              [daysLeft]="data[key]?.daysLeft"
              [total]="data[key]?.total"
            ></app-progress-bar>
          </div>
          <div *ngIf="data[key]?.booked && !data[key]?.total" class="border">
            <span>{{ data[key]?.booked | number : '1.2-2' }} {{ 'DAYS_BOOKED' | translate }}</span>
          </div>
          <div *ngIf="!data[key]?.total && !data[key]?.booked" class="border">
            <span>0 {{ 'DAYS_BOOKED' | translate }}</span>
          </div>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.STATUS" (click)="$event.stopPropagation()">
          <app-select
            [inputDisable]="selectDisabled"
            [allowClear]="false"
            [showSearch]="false"
            [label]="'label'"
            [options]="projectStatuses"
            size="large"
            type="status"
            [selectedValue]="data[key]"
            (statusSelectChange)="data[key].statusChanges(data[key]?.projectId, $event)"
          ></app-select>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.STATUS_ICON" class="table-status-wrapper">
          <div class="d-flex align-items-center">
            <img style="width: 30px; height: 30px" src="{{ data[key]?.icon }}" alt="Image" />
            <span class="style_span">{{ data[key]?.string | translate }}</span>
          </div>
        </div>
        <div
          style="max-width: 250px"
          *ngIf="data[key]?.type === TABLE_TYPE.STATUS_TIMESHEET"
          (click)="$event.stopPropagation()"
        >
          <app-menu-dropdown
            class="card_row_controls"
            [actions]="data.actions.statusChanges"
            title="{{ 'TIMESHEET_ACTION' | translate }}"
          >
          </app-menu-dropdown>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.TRANSLATED_STRING" [ngClass]="data[key].class">
          <span [innerHTML]="data[key].string | translate | sanitizedHtml"> </span>
        </div>
        <div
          *ngIf="data[key]?.type === TABLE_TYPE.ADVANCED_TRANSLATED_STRING"
          style="width: 100px"
          [ngClass]="data[key].class"
        >
          <span [innerHTML]="data[key].string | translate | sanitizedHtml"> </span>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.BUDGET_OVERVIEW_STATUSES" class="row">
          <div *ngFor="let status of data[key]?.data" class="col">
            <img style="width: 30px; height: 30px" src="{{ status?.icon }}" alt="Image" />
            <span style="margin-left: 3px">{{ status.string }}</span>
          </div>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.CLICKABLE_IMAGE">
          <a
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            (click)="data[key].onClick(); $event.stopPropagation()"
          >
            <span [inlineSVG]="data[key].url" class="svg-icon svg-icon-3"></span>
          </a>
        </div>
        <div *ngIf="data[key]?.type === TABLE_TYPE.CLICKABLE_IMAGE_LIST">
          <div class="btn-group" role="group" aria-label="Basic example">
            <a
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 element"
              *ngFor="let item of data[key]?.items"
              (click)="item.onClick(); $event.stopPropagation()"
            >
              <span [inlineSVG]="item.url" class="svg-icon svg-icon-3"></span>
            </a>
          </div>
        </div>
        <div *ngIf="!data[key]?.type">
          <div
            class="min-width-row"
            [innerHTML]="data[key]?.value ?? data[key] | ellipsis : data[key].limit || 16 | textBreak : data[key].limit"
          ></div>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
<ng-template #rangeTemplate let-range="range" let-total>
  <div class="range-wrapper">
    <app-select
      [options]="pageSizeOptions"
      [showSearch]="false"
      [label]="'label'"
      [selectedValue]="pageSizeOptions[0]"
      (statusSelectChange)="sizeChange($event)"
    ></app-select>
    <span class="number-of-text-wrapper">
      {{ 'RECORDS_PER_PAGE' | translate }} - {{ 'IN_TOTAL' | translate }}: {{ total }}
    </span>
  </div>
</ng-template>
<ng-template #noResultsTemplate>
  <div class="no-data_wrapper">
    <div
      [setSVGAttributes]="{ weight: '150px', height: '150px' }"
      [inlineSVG]="'assets/media/icons/duotune/general/no-data.svg'"
      class="no-data_image"
    ></div>
    <span class="no-data_text">{{ 'NO_DATA_TO_SHOW' | translate }}</span>
  </div>
</ng-template>
