import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs'
import { User } from '../model/flexc-user.model'
import { environment } from 'environments/environment'
import { ApiResponse } from '../dto/ApiResponse'

import { KeycloakService } from 'keycloak-angular'
import { KeycloakProfile } from 'keycloak-js'
import { ProjectMember } from '../model/projectUsers.model'
import { superUser } from '../model/superUser.model'
import { UserImageSharedService } from 'app/pages/users/user-image-shared.service'
import { UserDashboardDto } from '../dto/UserDashboardDto'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userUrl: string = environment.apiBase + '/users'
  private projectUrl: string = environment.apiBase + '/projects'
  loggedInUser: BehaviorSubject<User> = new BehaviorSubject<User>(new User())

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
    private projectSharedService: UserImageSharedService
  ) {
    this.keycloakService.loadUserProfile().then((val: KeycloakProfile) => {
      this.getUserByKeycloakId(val.id!).subscribe((res: ApiResponse<User>) => {
        this.loggedInUser.next(res.response)
      })
    })
  }

  public createUser(body: User): Observable<ApiResponse<User>> {
    return this.http.post<ApiResponse<User>>(this.userUrl + '/create-user', body).pipe(catchError(this.handleError))
  }

  public getAllUsers(): Observable<ApiResponse<User[]>> {
    return this.http.get<ApiResponse<User[]>>(this.userUrl + '/get-all')
  }

  public getUserByID(userId: string): Observable<ApiResponse<User>> {
    return this.http.get<ApiResponse<User>>(this.userUrl + '/' + userId)
  }

  public deleteUserById(userId: string): Observable<ApiResponse<User>> {
    return this.http.delete<ApiResponse<User>>(this.userUrl + '/delete-user/' + userId)
  }

  public updateUserByID(user: User): Observable<ApiResponse<User>> {
    return this.http.put<ApiResponse<User>>(this.userUrl + '/update-user', user)
  }

  public archivingUser(userId: string): Observable<ApiResponse<User>> {
    return this.http
      .put<ApiResponse<User>>(this.userUrl + '/archiving-user/' + userId, null)
      .pipe(catchError(this.handleError))
  }

  public getUserArchived(
    page: number,
    size: number,
    archived: boolean,
    sortBy: string,
    sortDirection?: string,

    searchInput?: any
  ) {
    let paramMap: HttpParams = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('archived', archived)
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))
    if (searchInput && searchInput != '') {
      paramMap = paramMap.append('searchInput', searchInput)
    }

    return this.http.get<ApiResponse<User[]>>(`${this.userUrl}/find-all`, { params: paramMap })
  }

  public getUsers(
    page: number,
    size: number,
    sortBy?: string,
    sortDirection?: string,
    archived?: boolean,
    searchInput?: any
  ) {
    let paramMap: HttpParams = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))
    // .append('archived', archived)
    if (archived) {
      paramMap = paramMap.append('archived', archived)
    }
    if (searchInput && searchInput != '') {
      paramMap = paramMap.append('searchInput', searchInput)
    }

    return this.http.get<ApiResponse<User[]>>(`${this.userUrl}/find-all`, { params: paramMap })
  }

  public getAllArchivedUsers(): Observable<ApiResponse<User[]>> {
    return this.http.get<ApiResponse<User[]>>(this.userUrl + '/get-all-archived-users')
  }

  public getUsersForProject(projectId: string): Observable<ApiResponse<User[]>> {
    return this.http.get<ApiResponse<User[]>>(this.userUrl + '/get-users-for-project/' + projectId)
  }

  public getUsersOnProject(
    projectId: string,
    page: number,
    size: number,
    sortBy: string,
    sortDirection?: string,
    search?: any
  ) {
    let paramMap: HttpParams = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))

    if (search && search != '') {
      paramMap = paramMap.append('searchInput', search)
    }

    return this.http.get<ApiResponse<ProjectMember[]>>(this.userUrl + '/get-project-members/' + projectId, {
      params: paramMap,
    })
  }

  public getUsersOnProjectWithoutPagination(projectId: string) {
    return this.http.get<ApiResponse<ProjectMember[]>>(this.userUrl + '/get-project-members/' + projectId)
  }

  public getUserByKeycloakId(keyclakId: string): Observable<ApiResponse<User>> {
    return this.http.get<ApiResponse<User>>(this.userUrl + '/get-by-keycloak-id/' + keyclakId)
  }

  setUserLang(defaultLang: string, keyclakId: string): Observable<any> {
    return this.http.put<any>(this.userUrl + `/change-default-lang/${defaultLang}/by-keycloak-id/${keyclakId}`, {})
  }

  public getLoggedInUser(): Observable<User> {
    return this.loggedInUser.asObservable()
  }

  public getUsersForProjectFinal(projectId: string): Observable<ApiResponse<ProjectMember[]>> {
    return this.http.get<ApiResponse<ProjectMember[]>>(this.userUrl + '/get-project-members/' + projectId)
  }

  public getSuperUsersUnarchived(): Observable<ApiResponse<User[]>> {
    return this.http.get<ApiResponse<User[]>>(this.userUrl + '/get-unarchived-super-users')
  }
  public getSuperUsersArchived(): Observable<ApiResponse<User[]>> {
    return this.http.get<ApiResponse<User[]>>(this.userUrl + '/get-archived-super-users')
  }
  public deleteSuperUser(id: string): Observable<ApiResponse<User>> {
    return this.http.delete<ApiResponse<User>>(this.userUrl + '/delete-super-user/' + id)
  }

  public isUsernameTaken(username: string): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.userUrl + '/is-username-taken', { username })
  }

  isUsernameTakenRegistration(user: User): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(this.userUrl + '/is-username-taken-registration', user)
  }

  public isEmailTaken(email: string): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.userUrl + '/is-email-taken', { email })
  }

  isEmailTakenRegistration(user: User): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(this.userUrl + '/is-email-taken-registration', user)
  }

  private handleError(error: any) {
    let errorMessage = ''
    if (error.error.message != null) {
      errorMessage = `${error.error.message}`
    } else if (error.status != null) {
      errorMessage = `Error Code: ${error.status}`
    } else {
      errorMessage = `Error Code: ${error}`
    }
    return throwError(errorMessage)
  }

  public createSuperUser(body: superUser): Observable<ApiResponse<superUser>> {
    return this.http.post<ApiResponse<superUser>>(this.userUrl + '/create-super-admin', body)
  }

  public archiveUsers(body: User[]): Observable<ApiResponse<User[]>> {
    return this.http.put<ApiResponse<User[]>>(this.userUrl + '/archiving-mass-activity/', body)
  }

  public getUsersNotInProject(
    projectId: string,
    page?: number,
    size?: number,
    searchInput?: string
  ): Observable<ApiResponse<User[]>> {
    let paramMap: HttpParams = new HttpParams()
      .set('size', size ? size.toString() : '')
      .set('page', page ? page.toString() : '')
      .set('searchInput', searchInput || '')

    return this.http.get<ApiResponse<User[]>>(`${this.userUrl}/get-users-not-in-project/${projectId}`, {
      params: paramMap,
    })
  }
  public uploadImage(id: string): Observable<ApiResponse<User>> {
    const selectedFile: File = this.projectSharedService.getImageFile()!
    const fd = new FormData()
    fd.append('image', selectedFile, selectedFile.name)
    return this.http.put<ApiResponse<User>>(this.userUrl + '/update-user/' + id + '/' + 'image', fd)
  }

  public getUserDashboardInfo(): Observable<ApiResponse<UserDashboardDto>> {
    return this.http.get<ApiResponse<any>>(this.userUrl + '/get-user-dashboard-info')
  }
  getUserDashboardInfoId(userId: string): Observable<ApiResponse<UserDashboardDto>> {
    const url = `${this.userUrl}/get-user-dashboard-info?userId=${userId}`
    return this.http.get<ApiResponse<UserDashboardDto>>(url)
  }
  public getAdminDashboardInfo(): Observable<ApiResponse<UserDashboardDto>> {
    return this.http.get<ApiResponse<any>>(this.userUrl + '/get-admin-dashboard-info')
  }

  resendActivation(userId: string): Observable<ApiResponse<User>> {
    const url = `${this.userUrl}/resend-activation/${userId}`
    return this.http.post<ApiResponse<User>>(url, {})
  }

  deleteUserImage(userId: string): Observable<ApiResponse<User>> {
    return this.http.delete<ApiResponse<User>>(this.userUrl + '/update-user/' + userId + '/delete-image')
  }
}
