<div class="users-list">
  <div class="users-list_controls">
    <div class="users-list_controls_column">
      <app-menu-dropdown [title]="'MASS_ACTIVITY' | translate" [actions]="menuActions"></app-menu-dropdown>
      <div class="users-list_controls_switch-wrapper" *ngIf="!showInactive">
        <app-switch [checked]="showInactive" (handleChange)="archivedSwitchChange()"></app-switch>
        {{ 'SHOW_ACTIVE' | translate }}
      </div>
      <div class="users-list_controls_switch-wrapper" *ngIf="showInactive">
        <app-switch [checked]="showInactive" (handleChange)="archivedSwitchChange()"></app-switch>
        {{ 'SHOW_INACTIVE' | translate }}
      </div>
    </div>
    <div class="users-list_controls_column">
      <app-button
        [title]="'ADD' | translate"
        buttonType="primary"
        prefixIcon="assets/media/icons/duotune/text/txt001.svg"
        routerLinkActive="active"
        routerLink="/users/create-user"
      ></app-button>
      <app-input
        search
        [placeholder]="'SEARCH' | translate"
        (statusSelectChange)="searchParam($event)"
        [(ngModel)]="searchTerm"
      ></app-input>
    </div>
  </div>
  <app-table
    class="users-table"
    *ngIf="!loading"
    [dataList]="tableData"
    [customHeadings]="tableHeadings"
    [sortableColumns]="sortableColumns"
    [pageSize]="5"
    [selectable]="true"
    [rowClickable]="true"
    [search]="searchTerm"
    (selectionChange)="selectionChange($event)"
    (rowClick)="redirectToSingleUserPage($event)"
    [hiddenColumns]="['id']"
    [frontendPagination]="false"
    (paginationChange)="mapPagination($event)"
    [pagination]="paginationObject"
    (sortingChange)="mapSorting($event)"
    [sort]="sortObject"
  ></app-table>
</div>

<app-success-modal #modalSuccess [modalConfig]="modalConfig">
  <div class="modal-wrapper"></div>
</app-success-modal>
<app-error-modal #modalError [modalConfig]="modalConfig">
  <div class="modal-wrapper"></div>
</app-error-modal>
