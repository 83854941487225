import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { User } from 'app/pages/core/model/flexc-user.model'
import { ToastService } from 'app/pages/core/services/toast.service'
import { UserService } from 'app/pages/core/services/user.service'
import { BehaviorSubject } from 'rxjs'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { TranslateService } from '@ngx-translate/core'
import { environment } from 'environments/environment'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { IButtonAction } from 'app/standalone/menu-dropdown/menu-dropdown.component.model'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  @ViewChild('modalSuccess') private modalComponentSucess: ModalComponent
  @ViewChild('modalError') private modalComponentError: ModalComponent

  tableData: any[] = []
  userList: User[] = new Array()
  loading: boolean
  selectedUsers: any[]
  showInactive: boolean = false
  searchTerm: string = ''
  menuActions: IButtonAction[] = this.generateActionByShowArchivedSwitch()

  tableHeadings = {
    image: '',
    firstName: useStringAsTranslationObject('FIRST_NAME'),
    lastName: useStringAsTranslationObject('LAST_NAME'),
    username: useStringAsTranslationObject('USERNAME'),
    jobTitle: useStringAsTranslationObject('JOB_TITLE'),
    role: useStringAsTranslationObject('ROLE'),
    lastLogin: useStringAsTranslationObject(!this.showInactive ? 'LAST_LOGIN' : 'CREATED_AT'),
  }

  sortableColumns = {
    firstName: 'string',
    lastName: 'string',
    username: 'string',
    jobTitle: 'string',
    role: 'string',
    lastLogin: 'date',
  }

  paginationObject: any = {
    pageSize: 5,
    currentPage: 0,
    totalElements: 0,
  }
  sortObject: any = {
    column: 'firstName',
    order: 'ascend',
  }

  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>('')
  modalBodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')

  modalConfig: ModalConfig = {
    newModalTitle: this.titleForModal,
    modalBodyTitle: this.modalBodyTitle,
    dismissButtonLabel: 'No',
    closeButtonLabel: 'Yes',
    onClose: () => {
      return true
    },
    onDismiss: () => {
      return true
    },
  }

  constructor(
    public userService: UserService,
    public toastService: ToastService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private querySharedService: QuerySharedService
  ) {}

  ngOnInit(): void {
    this.fetchRouteParams()
    this.fetchData()
    this.translate.onLangChange.subscribe((res: any) => {
      this.menuActions = this.generateActionByShowArchivedSwitch()
    })
  }

  fetchRouteParams() {
    if (this.route.snapshot.queryParamMap.get('pageSize')) {
      this.paginationObject.pageSize = +this.route.snapshot.queryParamMap.get('pageSize')!
    }
    if (this.route.snapshot.queryParamMap.get('currentPage')) {
      this.paginationObject.currentPage = +this.route.snapshot.queryParamMap.get('currentPage')!
    }
    if (this.route.snapshot.queryParamMap.get('column')) {
      this.sortObject.column = this.route.snapshot.queryParamMap.get('column')
    }
    if (this.route.snapshot.queryParamMap.get('order')) {
      this.sortObject.order = this.route.snapshot.queryParamMap.get('order')
    }
    if (this.route.snapshot.queryParamMap.get('showInactive')) {
      this.showInactive = this.route.snapshot.queryParamMap.get('showInactive') == 'true'
    }
    if (this.route.snapshot.queryParamMap.get('searchInput')) {
      this.searchTerm = this.route.snapshot.queryParamMap.get('searchInput')!
    }
  }

  fetchData() {
    this.userService
      .getUserArchived(
        this.paginationObject.currentPage,
        this.paginationObject.pageSize,
        this.showInactive,
        this.mapSortBy(this.sortObject.column),
        this.mapSortDirection(this.sortObject.order),
        this.searchTerm
      )
      .subscribe(this.handleUsersResponse)
  }

  mapPagination(event: any) {
    this.paginationObject = event
    this.fetchData()
  }
  mapSorting(event: any) {
    this.sortObject = event
    this.fetchData()
  }

  searchParam(event: any) {
    this.paginationObject.currentPage = 0
    this.searchTerm = event
    if (this.searchTerm.charAt(this.searchTerm.length - 1) === ' ') {
      this.searchTerm = this.searchTerm.trim()
    }
    if (this.searchTerm.charAt(0) === ' ') {
      this.searchTerm = this.searchTerm.trimStart()
    }
    this.fetchData()
  }
  setHeading() {
    this.tableHeadings = {
      image: '',
      firstName: useStringAsTranslationObject('FIRST_NAME'),
      lastName: useStringAsTranslationObject('LAST_NAME'),
      username: useStringAsTranslationObject('USERNAME'),
      jobTitle: useStringAsTranslationObject('JOB_TITLE'),
      role: useStringAsTranslationObject('ROLE'),
      lastLogin: useStringAsTranslationObject(!this.showInactive ? 'LAST_LOGIN' : 'CREATED_ON'),
    }
  }
  private mapSortBy(sortBy: string) {
    switch (sortBy) {
      case 'firstName':
        return 'firstName'
      case 'lastName':
        return 'lastName'
      case 'username':
        return 'username'
      case 'jobTitle':
        return 'portfolio.jobTitle'
      case 'role':
        return 'userRole.name'
      case 'lastLogin':
        return 'lastLogin'
      default:
        return 'firstName'
    }
  }

  private mapSortDirection(order: string) {
    return order === 'descend' ? 'DESC' : order === 'ascend' ? 'ASC' : 'ASC'
  }

  handleUsersResponse = (res: ApiResponse<User[]>) => {
    this.paginationObject.totalElements = res.totalElements
    this.userList = res.content || []
    this.tableData = this.userList.map(user => ({
      image: {
        type: user.imageUrl ? TABLE_TYPE.IMAGE : TABLE_TYPE.SVG,
        url: user.imageUrl ? `${environment.imgUrl}${user.imageUrl}` : 'assets/media/logos/ICON_USER.svg',
      },
      firstName: {
        value: user.firstName,
        limit: 32,
      },
      lastName: {
        value: `${user.lastName}-${user.lastName}`,
        limit: 32,
      },
      username: {
        value: user.username,
        limit: 25,
      },
      jobTitle: {
        value: user.portfolio?.jobTitle,
        limit: 50,
      },
      role: user.userRole.name ? useStringAsTranslationObject(user.userRole.name) : '',
      id: user.id,
      lastLogin: {
        type: TABLE_TYPE.DATE,
        value: user.lastLogin ? new Date(user.lastLogin!) : new Date(user.createdAt!),
      },
    }))
    this.loading = false
  }

  generateActionByShowArchivedSwitch() {
    return [
      {
        title: this.showInactive ? 'ACTIVE_USER' : 'INACTIVE_USER',
        onClick: () => this.handleUserSelection(),
        disabled: !this.selectedUsers?.length,
        icon: this.showInactive
          ? './assets/media/icons/duotune/general/unarchive.svg'
          : './assets/media/icons/duotune/general/archive.svg',
      },
    ]
  }

  redirectToSingleUserPage(user: any) {
    this.querySharedService.addQueryForRoute('users/users-list', {
      ...this.paginationObject,
      ...this.sortObject,
      showInactive: this.showInactive,
      searchInput: this.searchTerm,
    })
    void this.router.navigate([`/users/update-edit-user/${user.id}`])
  }

  updateMassActivity() {
    this.menuActions = this.generateActionByShowArchivedSwitch()
  }

  selectionChange(event: any) {
    this.selectedUsers = event
    this.updateMassActivity()
  }

  resetSelection() {
    this.selectedUsers = []
  }

  archivedSwitchChange() {
    this.paginationObject.currentPage = 0
    this.showInactive = !this.showInactive
    this.setHeading()
    this.fetchData()
    this.updateMassActivity()
    this.resetSelection()
  }

  handleUserSelection() {
    const requestData: User[] = this.selectedUsers.map(selectedUser =>
      this.userList.find(user => user.id === selectedUser.id)
    ) as User[]
    // Should be implemented in similar fashion to `fetchData` after `unarchiveUsers()` is implemented
    this.userService.archiveUsers(requestData).subscribe(
      ({ response }: ApiResponse<User[]>) => {
        if (response) {
          // TODO: This part should be grouped with body of `unarchiveUsers()` when it gets implemented
          // Only difference would probably be modal content
          this.resetSelection()
          this.fetchData()
          this.modalBodyTitle.next(this.translate.instant('USERS_ACTIVE_INACTIVE_SUCCESSFULLY'))
          this.openModalSuccess()
        }
      },
      error => {
        this.resetSelection()
        this.modalBodyTitle.next(this.translate.instant('CHOOSE_AT_LEAST_ONE_USER_ADMINISTRATOR_CANNOT_BE_ARCHIVED'))
        this.openModalError()
      }
    )
  }

  async openModalSuccess() {
    return await this.modalComponentSucess.open()
  }

  async openModalError() {
    return await this.modalComponentError.open()
  }
}
