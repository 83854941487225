<div class="row">
  <div class="col-12">
    <app-timesheet-list
      [projectUserBudget]="budget"
      [manager]="manager"
      [blocked]="blocked"
      [daysLeft]="daysLeftUser"
      [daysTotal]="daysTotal"
      (modalSaveEmitter)="modalSave($event)"
      class="card card-xxl-stretch"
    ></app-timesheet-list>
  </div>
</div>
