import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { SharedService } from '../shared.service'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { TranslateService } from '@ngx-translate/core'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { ProjectStatus } from 'app/pages/core/model/project-status.model'
import { PROJECTSTATUS } from 'app/pages/statusProject'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit, OnDestroy {
  @Input() selectedMembers: ProjectMember[]

  @Input() totalWorkingDays: number

  @ViewChild('modalSuccess') private modalComponentSucess: ModalComponent

  public submitted: boolean = false
  public submittedStepper: boolean = false
  public projectInfoForm: FormGroup
  public projectTimeForm: FormGroup
  public projectWorkersForm: FormGroup
  public projectTeamForm: FormGroup
  public projectOverview: boolean = false
  public id: string = ''
  public projectUpdate: boolean = false
  public customerDto: any
  public firstFormValid: boolean = false
  projectNumberTaken: boolean = false
  projectNameTaken: boolean = false
  projectOfferTaken: boolean = false

  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>('')
  modalBodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')

  modalConfig: ModalConfig = {
    newModalTitle: this.titleForModal,
    modalBodyTitle: this.modalBodyTitle,
    dismissButtonLabel: 'No',
    closeButtonLabel: 'Yes',
    onClose: () => true,
    onDismiss: () => true,
  }

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private _router: Router,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private translate: TranslateService,
    private querySharedService: QuerySharedService
  ) {}
  currentStep$: BehaviorSubject<number> = new BehaviorSubject(1)

  public false: boolean = false
  public weekMode: number = 0

  ngOnInit(): void {
    this.projectInfoForm = this.fb.group({
      projectName: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', [Validators.required, Validators.minLength(2)]],
      customer: ['', [Validators.required]],
      imageUrl: [''],
      archived: false,
      projectNumber: [''],
      projectOffer: [''],
    })
    this.projectTimeForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      planedPeople: [''],
      weekMode: [],
      noEnd: [],
      projectDays: [],
      calculatedBudget: [],
      budgetEstimation: [],
    })
    this.projectWorkersForm = this.fb.group({
      aprrovers: [[], Validators.required],
      members: [[], Validators.required],
    })
    this.projectTeamForm = this.fb.group({
      items: [[]],
    })
    if (this.route.snapshot.routeConfig!.path === 'update-project/:id') {
      this.projectOverview = true
      let id = this.route.snapshot.paramMap.get('id')
      this.id = JSON.parse(this.route.snapshot.paramMap.get('id')!)
      if (id != null) {
        this.populateForm()
      }
      this.projectInfoForm.controls['projectName']?.disable()
      this.projectInfoForm.controls['customer']?.disable()
      this.projectInfoForm.controls['description']?.disable()
      this.projectInfoForm.controls['imageUrl']?.disable()
      this.projectTimeForm.controls['startDate'].disable()
      this.projectWorkersForm.controls['aprrovers'].disable()
    } else if (this.route.snapshot.routeConfig!.path === 'edit-project/:id') {
      this.projectUpdate = true
      let id = this.route.snapshot.paramMap.get('id')
      this.id = JSON.parse(this.route.snapshot.paramMap.get('id')!)
      if (id != null) {
        this.populateForm()
        this.projectWorkersForm.controls['aprrovers'].disable()
        this.projectInfoForm.controls['customer']?.disable()
      }
    }
  }
  populateForm() {
    this.projectService.getOneProject(this.id).subscribe((res: ApiResponse<Project>) => {
      this.projectInfoForm.get('name')?.setValue(res.response.name)
      this.projectInfoForm.get('customer')?.setValue(res.response.customer.name || res.response.customer.endName)
      this.projectInfoForm.get('description')?.setValue(res.response.description)
      this.projectInfoForm.get('archived')?.setValue(res.response.archived)
      this.projectWorkersForm.get('members')?.setValue(res.response.projectMembers)
      this.projectWorkersForm.get('aprrovers')?.setValue(res.response.projectApproversDTO)
      this.projectTimeForm.get('startDate')?.setValue(res.response.start)
      this.projectTimeForm.get('endDate')?.setValue(res.response.end)
      this.projectTimeForm.get('budgetEstimation')?.setValue(res.response.budgetEstimation)
      this.selectedMembers = res.response.projectMembers
    })
  }

  submitForm(isProjectStatusSetToActive: boolean = false) {
    this.projectNumberTaken = false
    this.projectOfferTaken = false
    this.submitted = true
    this.submittedStepper = true
    this.projectNameTaken = false
    let project: Project
    project = {
      id: '',
      start: this.projectTimeForm.get('startDate')?.value,
      end: this.projectTimeForm.get('endDate')?.value,
      description: this.projectInfoForm.get('description')?.value,
      name: this.projectInfoForm.get('projectName')?.value.trim(),
      imageUrl: this.projectInfoForm.get('imageUrl')?.value,
      customer: this.projectInfoForm.get('customer')?.value,
      projectMembers: this.projectWorkersForm.get('members')?.value,
      projectApproversDTO: this.projectWorkersForm.get('aprrovers')?.value,
      archived: this.projectInfoForm.get('archived')?.value,
      projectNumber: this.projectInfoForm.get('projectNumber')?.value,
      projectOffer: this.projectInfoForm.get('projectOffer')?.value,
      weekMode: this.projectTimeForm.get('weekMode')?.value,
      plannedPeople: this.projectTimeForm.get('planedPeople')?.value,
      timePeriod: this.projectTimeForm.get('projectDays')?.value,
    }

    if (isProjectStatusSetToActive) {
      project = this.sharedService.getProject()
      project.projectStatus = { id: PROJECTSTATUS.RUNNING } as ProjectStatus
      this.sharedService.setProject(project)
    }

    if (this.id) {
      project = this.sharedService.getProject()
      project.id = this.id
      project.start = this.projectTimeForm.get('startDate')?.value
      project.start = this.sharedService.formatDate(project.start)
      if (this.projectTimeForm.value.noEnd) {
        project.noEndDate = true
      } else {
        project.end = this.projectTimeForm.get('endDate')?.value
        project.end = this.sharedService.formatDate(project.end)
      }
      this.sharedService.setProject(project)
      if (this.currentStep$.value !== 4) {
        this.projectService.updateProject(project).subscribe((response: ApiResponse<Project>) => {
          if (response.response.id) {
            this.submitted = false
            this.submittedStepper = true
            if (this.currentStep$.value === 5) {
              this.sharedService.setInputSearchValue('')
              this.handleSuccess('SAVED_SUCCESSFULLY | translate')
              this._router.navigate(['/projects/projects-list'])
            }
          }
        })
      }
    } else {
      this.sharedService.setProject(project)
      this.projectService.createProject(project).subscribe(
        (response: ApiResponse<Project>) => {
          if (response.response.id) {
            this.id = response.response.id!
            this.sharedService.getImageFile()
              ? this.projectService.uploadImage(response.response.id!).subscribe((res: ApiResponse<Project>) => {
                  this.setProperties()
                })
              : this.setProperties()
          }
        },
        (error: any) => {
          const nextStep = this.currentStep$.value - 1
          this.currentStep$.next(nextStep)
        }
      )
    }
  }

  setProperties() {
    this.submitted = false
    this.submittedStepper = false
    this.projectUpdate = true
    this.firstFormValid = false
  }

  nextStep(isProjectStatusSetToActive: boolean = false) {
    this.submittedStepper = true
    const nextStep = this.currentStep$.value + 1
    if (this.currentStep$.value < 5) {
      if (this.currentStep$.value === 1 && !this.projectInfoForm.valid && this.validProjectNumberOfferInput()) {
        return
      }
      if (this.currentStep$.value === 2 && !this.projectTimeForm.valid) {
        return
      }
      this.currentStep$.next(nextStep)
    }
    this.submitForm(isProjectStatusSetToActive)
    this.getWorkingDays()
  }

  prevStep() {
    const prevStep = this.currentStep$.value - 1
    if (prevStep === 0) {
      return
    }
    this.currentStep$.next(prevStep)
  }

  clearDays() {
    this.sharedService.clearDays()
  }

  getWorkingDays() {
    this.totalWorkingDays = this.sharedService.getCalculatedBudget()
  }

  ngOnDestroy() {
    this.clearDays()
    this.sharedService.clearTimePeriod()
    this.sharedService.clearCalculatedBudget()
    this.sharedService.setAllSelected(false)
    this.sharedService.setInputSearchValue('')
  }

  handleSuccess(message: string): void {
    this.submitted = false
    this.modalBodyTitle.next(this.translate.instant('PROJECT_SAVED_SUCCESSFULLY'))
    this.openModalSuccess()
    this._router.navigate(['/customer/customer-list'])
  }

  async openModalSuccess() {
    return await this.modalComponentSucess.open()
  }

  validProjectNumberOfferInput(): boolean {
    return !this.sharedService.getProjectNaumberExist() && !this.sharedService.getProjectOfferExist()
  }

  back(event: any) {
    let query = this.querySharedService.getQueryForRoute('projects/projects-list')
    this._router.navigate(['projects/projects-list'], {
      queryParams: query,
      queryParamsHandling: 'merge',
    })
  }
}
