import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { User } from 'app/pages/core/model/flexc-user.model'
import { KnowledgeDto } from 'app/pages/core/model/knowledge.model'
import { LanguageDto } from 'app/pages/core/model/language.model'
import { SpokenLanguages } from 'app/pages/core/model/spoken-languages'
import { KnowledgeOrLanguage } from 'app/pages/core/model/knowledge-or-language'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { KnowladgeService } from 'app/pages/core/services/knowledge.service'
import { LanguageService } from 'app/pages/core/services/language.service'
import { ProjectService } from 'app/pages/core/services/project.service'
import { UserService } from 'app/pages/core/services/user.service'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { environment } from 'environments/environment'
import { BehaviorSubject, filter, debounceTime, distinctUntilChanged } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { forkJoin } from 'rxjs'

@Component({
  selector: 'app-create-project-step-three',
  templateUrl: './create-project-step-three.component.html',
  styleUrls: ['./create-project-step-three.component.scss'],
})
export class CreateProjectStepThreeComponent implements OnInit {
  setProjectMembers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  usersChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  searchValue$ = new BehaviorSubject<string>('')
  itemsListdb: KnowledgeOrLanguage[] = []
  tableData: any[] = []
  selectedItems: KnowledgeOrLanguage[] = []
  listOfKnowledge: KnowledgeDto[] = []
  listOfLanguage: LanguageDto[] = []
  searchTerm: string = ''
  projectId: string
  projectData: Project
  selectedKnowledges: KnowledgeDto[] = []
  selectedLanguages: LanguageDto[] = []
  paginationObject: any = {
    pageSize: 5,
    currentPage: 0,
    totalElements: 0,
  }

  sortObject: any = {
    column: '',
    order: null,
  }

  tableHeadings = {
    image: '',
    firstName: useStringAsTranslationObject('FIRST_NAME'),
    lastName: useStringAsTranslationObject('LAST_NAME'),
    jobTitle: useStringAsTranslationObject('JOB_TITLE'),
    knowledge: useStringAsTranslationObject('KNOWLEDGE'),
  }

  sortableColumns = {
    firstName: 'string',
    lastName: 'string',
    jobTitle: 'string',
  }

  constructor(
    public knowladgeService: KnowladgeService,
    public languageService: LanguageService,
    public projectService: ProjectService,
    public userService: UserService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.searchUserCleanup()
    this.getKnowledgeAndLanguageData()

    this.route.params.subscribe(params => {
      this.projectId = params['id']
      this.getProjectData()
    })
    this.fetchUserData()
  }

  getProjectData() {
    this.projectService.getOneProject(this.projectId).subscribe(({ response }: ApiResponse<Project>) => {
      this.projectData = response
      if (this.projectData.knowledges) {
        this.selectedKnowledges = this.projectData.knowledges
      }
      if (this.projectData.languages) {
        this.selectedLanguages = this.projectData.languages
      }
    })
  }

  getKnowledgeAndLanguageData() {
    forkJoin({
      knowledges: this.knowladgeService.getAllKnowledgesNoPagination(),
      languages: this.languageService.getAllLanguagesNoPagination(),
    }).subscribe(({ knowledges, languages }) => {
      const knowledgeList = knowledges.response.map(
        (item: KnowledgeDto) => ({ ...item, type: 'knowledge' } as KnowledgeOrLanguage)
      )
      const languageList = languages.response.map(
        (item: LanguageDto) =>
          ({ ...item, type: 'language', name: this.capitalizeFirstLetter(item.name) } as KnowledgeOrLanguage)
      )
      this.itemsListdb = [...knowledgeList, ...languageList]
    })
  }

  capitalizeFirstLetter(text: string): string {
    if (!text) return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  fetchUserData() {
    this.searchUsersHttp(this.searchTerm, this.selectedKnowledges, this.selectedLanguages)
  }

  mapPagination(event: any) {
    this.paginationObject = event
    this.fetchUserData()
  }

  mapSorting(event: any) {
    this.sortObject = event
    this.fetchUserData()
  }

  searchUsers(event: any) {
    if (event) {
      this.searchValue$.next(event)
    } else {
      setTimeout(() => {
        this.searchTerm = ''
        this.searchUsersHttp(this.searchTerm, this.selectedKnowledges, this.selectedLanguages)
      }, 550)
    }
  }

  searchUserCleanup() {
    this.searchValue$
      .pipe(
        filter(val => val !== ''),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((res: any) => {
        this.searchTerm = res
        this.paginationObject.currentPage = 0
        this.searchUsersHttp(this.searchTerm, this.selectedKnowledges, this.selectedLanguages)
      })
  }

  searchUsersHttp(searchTerm?: string, knowledges?: KnowledgeDto[], languages?: LanguageDto[]) {
    let body = {
      searchTerm: searchTerm,
      knowledgeIds: knowledges?.map((val: KnowledgeDto) => val.id),
      spokenLanguageIds: languages?.map((val: LanguageDto) => val.id),
    }
    this.knowladgeService
      .filterUsersByKnowledgeOrLanguageOrSearch(
        body,
        this.paginationObject.currentPage,
        this.paginationObject.pageSize,
        this.mapSortBy(this.sortObject.column),
        this.mapSortDirection(this.sortObject.order)
      )
      .subscribe((res: any) => {
        res.content.forEach((element: any) => {
          element.isChecked = this.projectData?.projectMembers.some(member => element.id === member.user.id)
        })
        this.highlightKnowledges(res)
        this.mapData(res.content, res.totalElements)
      })
  }

  highlightKnowledges(res: any) {
    if (this.selectedKnowledges.length > 0 || this.selectedLanguages.length > 0) {
      res.content.forEach((val: User) => {
        val?.portfolio?.knowledges!.forEach((knowledge: KnowledgeDto) => {
          if (this.selectedKnowledges.find((val: KnowledgeDto) => val.id === knowledge.id) !== undefined) {
            val.knowledgeFlag = true
          }
        })
        val?.portfolio?.spokenLanguages!.forEach((spokenLanguage: any) => {
          if (
            this.selectedLanguages.find((selected: LanguageDto) => selected.id === spokenLanguage.language.id) !==
            undefined
          ) {
            val.knowledgeFlag = true
          }
        })
      })
    }
  }

  mapData(userList: any[], totalElements?: number) {
    this.paginationObject.totalElements = totalElements!
    if (userList) {
      this.tableData = userList.map(element => {
        // Extract knowledge names
        const knowledgeNames =
          element.portfolio?.knowledges?.map((knowledge: { name: string }) => knowledge.name).join(', ') || ''

        // Extract spoken languages, with only the first letter capitalized
        const spokenLanguages =
          element.portfolio?.spokenLanguages
            ?.map((language: { language: { name: string } }) => {
              const languageName = language.language.name.toLowerCase()
              return languageName.charAt(0).toUpperCase() + languageName.slice(1)
            })
            .join(', ') || ''

        // Combine knowledge and spoken languages, adding a comma only if there is knowledge
        const combinedKnowledgesAndLanguages = knowledgeNames
          ? knowledgeNames + (spokenLanguages ? `, ${spokenLanguages}` : '')
          : this.capitalizeFirstLetter(spokenLanguages)

        return {
          image: {
            type: element.imageUrl ? TABLE_TYPE.IMAGE : TABLE_TYPE.SVG,
            url: element.imageUrl ? `${environment.imgUrl}${element.imageUrl}` : './assets/media/logos/ICON_USER.svg',
          },
          firstName: {
            value: element.firstName,
            numberOfRows: 2,
            numberOfCharsPerRow: 16,
            totalNumberOfChars: 32,
          },
          lastName: {
            value: element.lastName,
            numberOfRows: 2,
            numberOfCharsPerRow: 16,
            totalNumberOfChars: 32,
          },
          jobTitle: {
            value: element.portfolio?.jobTitle,
            numberOfRows: 2,
            numberOfCharsPerRow: 25,
            totalNumberOfChars: 50,
          },
          knowledge: {
            type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
            numberOfRows: 1,
            numberOfCharsPerRow: 50,
            totalNumberOfChars: 50,
            string: `${
              !element.active ? `(${this.translate.instant('INACTIVE')}) ` : ''
            }${combinedKnowledgesAndLanguages}`,
          },
          id: element.id,
          knowledgeFlag: element.knowledgeFlag,
          languageFlag: element.languageFlag,
          isChecked: element.isChecked,
          active: element.active,
        }
      })
    } else {
      this.tableData = []
    }
  }

  onStatusSelectChange(selectedValues: KnowledgeOrLanguage[]) {
    // Filter the selected items by type
    const selectedKnowledges = selectedValues.filter(item => item.type === 'knowledge') as KnowledgeDto[]
    const selectedLanguages = selectedValues.filter(item => item.type === 'language') as LanguageDto[]
    this.selectedKnowledges = selectedKnowledges
    this.selectedLanguages = selectedLanguages
    this.updateSelectedItems()
    this.paginationObject.currentPage = 0
    this.searchUsersHttp(this.searchTerm, this.selectedKnowledges, this.selectedLanguages)
    this.updateProjectKnowledgesAndLanguages()
  }

  private updateSelectedItems() {
    this.selectedItems = [
      ...this.selectedKnowledges.map(k => ({ ...k, type: 'knowledge' } as KnowledgeOrLanguage)),
      ...this.selectedLanguages.map(l => ({ ...l, type: 'language' } as KnowledgeOrLanguage)),
    ]
  }

  private updateProjectKnowledgesAndLanguages() {
    this.projectData.knowledges = this.selectedKnowledges
    this.projectData.languages = this.selectedLanguages
  }

  saveFinished(event: any) {
    if (event) {
      this.doUpdate()
    }
  }

  doUpdate() {
    this.usersChange.next(true)
    if (this.projectData.knowledges || this.projectData.languages) {
      this.projectService.updateProject(this.projectData).subscribe((response: ApiResponse<Project>) => {})
    }
  }

  changeUserSelect(event: any) {
    if (this.projectData.projectMembers.find((val: ProjectMember) => val.user.id === event.id) === undefined) {
      this.userService.getUserByID(event.id).subscribe(({ response }: ApiResponse<User>) => {
        response.knowledgeFlag = true
        response.languageFlag = true
        response.isChecked = true
        this.projectData.projectMembers.push({ user: response } as ProjectMember)
      })
    } else {
      this.projectData.projectMembers.splice(
        this.projectData.projectMembers.indexOf(
          this.projectData.projectMembers.find((val: ProjectMember) => val.user.id === event.id)!
        ),
        1
      )
    }
  }

  private mapSortBy(sortBy: string) {
    switch (sortBy) {
      case 'firstName':
        return 'firstName'
      case 'lastName':
        return 'lastName'
      case 'username':
        return 'username'
      case 'jobTitle':
        return 'portfolio.jobTitle'
      default:
        return ''
    }
  }

  private mapSortDirection(order: string) {
    return order === 'descend' ? 'DESC' : order === 'ascend' ? 'ASC' : undefined
  }

  selectAll(event: any) {
    let allInPMList: boolean = true

    for (const iterator of event) {
      if (this.projectData.projectMembers.find((val: ProjectMember) => val.user.id === iterator.id) === undefined) {
        allInPMList = false
        break
      }
    }

    if (!allInPMList) {
      for (const iterator of event) {
        if (this.projectData.projectMembers.find((val: ProjectMember) => val.user.id === iterator.id) === undefined) {
          this.userService.getUserByID(iterator.id).subscribe(({ response }: ApiResponse<User>) => {
            response.knowledgeFlag = true
            response.isChecked = true
            this.projectData.projectMembers.push({ user: response } as ProjectMember)
          })
        }
      }
    } else {
      for (const iterator of event) {
        this.projectData.projectMembers.splice(
          this.projectData.projectMembers.indexOf(
            this.projectData.projectMembers.find((val: ProjectMember) => val.user.id === iterator.id)!
          ),
          1
        )
      }
    }
  }

  saveProject() {
    this.projectService.updateProject(this.projectData).subscribe(({ response }: ApiResponse<Project>) => {
      if (response) {
        this._router.navigate(['../../step-4/', this.projectId], { relativeTo: this.route })
      }
    })
  }

  goBack() {
    this._router.navigate(['../../step-2/', this.projectId], { relativeTo: this.route })
  }
}
