<div class="item_wrapper" [ngClass]="{ 'item_wrapper-max-height': isFromUser }">
  <div class="d-flex flex-column">
    <div class="card card-flush card-1" [ngClass]="{ 'item_wrapper-max-height': isFromUser }">
      <div class="card-header pt-5">
        <div class="card-title d-flex flex-column">
          <span *ngIf="showDescriptionString" class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{ stats }}</span>

          <span *ngIf="showDescriptionDate" class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{
            stats | date : 'dd.MM.YYYY'
          }}</span>
          <span *ngIf="showDescriptionStringSecond" class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{
            statsText | date : 'dd.MM.YYYY'
          }}</span>
          <span class="text-gray-400 pt-1 fw-semibold fs-6">{{ description }}</span>
        </div>
        <div class="img_wrapper" *ngIf="isFirstCard && showDescriptionDate">
          <img src="../../../../assets/media/svg/custom/Group 3.svg" alt="vector_img" />
        </div>
        <div class="img_wrapper_second" *ngIf="!isFirstCard && showDescriptionStringSecond">
          <img src="../../../../assets/media/svg/custom/Vector.svg" alt="vector_img" />
        </div>
        <div class="img_wrapper_second" *ngIf="!isFirstCard && showDescriptionDate">
          <img src="../../../../assets/media/svg/custom/Vector.svg" alt="vector_img" />
        </div>
      </div>

      <div class="card-body d-flex flex-column justify-content-end pe-0">
        <div [hidden]="!showUsersGroup" class="symbol-group symbol-hover flex-nowrap users-group">
          <div
            *ngFor="let item of items"
            class="symbol symbol-35px symbol-circle"
            data-bs-toggle="tooltip"
            [title]="item.name"
          >
            <ng-container *ngIf="item.src">
              <img alt="Pic" [src]="item.src" />
            </ng-container>
            <ng-container *ngIf="!item.src">
              <span class="symbol-label fw-bold" [ngClass]="'bg-' + item.state + ' ' + 'text-inverse-' + item.state">{{
                item.initials
              }}</span>
            </ng-container>
          </div>
          <a class="symbol symbol-35px symbol-circle">
            <span
              *ngIf="remainingUsersNumber > 0"
              class="symbol-label fs-8 fw-bold"
              [ngClass]="'bg-' + labelColor + ' ' + 'text-' + textColor"
              >+ {{ remainingUsersNumber }}</span
            >
          </a>
        </div>
        <div [hidden]="!showButtons" class="card-toolbar buttons">
          <ul class="nav" id="kt_chart_widget_8_tabs">
            <li class="nav-item li">
              <app-button
                [title]="allUsersButtonLabel | translate"
                buttonType="primary"
                *ngIf="showUsersButton"
                (click)="navigateToAll()"
              ></app-button>
              <app-button
                [title]="allCustomersButtonLabel | translate"
                buttonType="primary"
                *ngIf="showCustomersButton"
                (click)="navigateToAll()"
              ></app-button>
            </li>
            <li class="nav-item">
              <app-button
                [title]="newButtonLabel | translate"
                buttonType="link"
                additionalClass="showcase"
                (click)="navigateToAddNew()"
              ></app-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="img_wrapper" *ngIf="isFirstCard && showDescriptionDate">
        <img src="../../../../assets/media/svg/custom/Group 3.svg" alt="vector_img" />
      </div>
      <div class="image_wrapper_bottom_right" *ngIf="showUsersGroup && showUsersButton">
        <img src="../../../../assets/media/icons/duotune/layouts/dashboard_icon1.svg" alt="vector_img" />
      </div>
      <div class="image_wrapper_top_right" *ngIf="showCustomersButton">
        <img src="../../../../assets/media/icons/duotune/abstract/abs014.svg" alt="vector_img" />
      </div>
      <div class="img_wrapper_second" *ngIf="!isFirstCard && showDescriptionDate">
        <img src="../../../../assets/media/svg/custom/Vector.svg" alt="vector_img" />
      </div>
    </div>
    <div *ngIf="isFromUser && !isFirstCard" class="button-padding">
      <button class="btn btn-primary" [routerLink]="'./view-timesheet-data'">{{ 'ALL_ENTRIES' | translate }}</button>
    </div>
  </div>
</div>
