<div class="card-body">
  <div class="d-flex flex-wrap align-items-center">
    <div id="kt_signin_password_edit" class="flex-row-fluid false">
      <div class="row">
        <span class="title">{{ 'USER_DETAILS' | translate }}</span>
      </div>
      <form [formGroup]="userDetailsForm">
        <div class="row">
          <div>
            <div class="col-md-3 mt-6 mb-8">
              <app-user-profile-image [form]="userDetailsForm"></app-user-profile-image>
            </div>
          </div>
          <div class="col-md-12 border-right wraper">
            <app-user-personal-data [form]="userDetailsForm" [submitted]="submitted"></app-user-personal-data>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
