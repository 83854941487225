import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { catchError, map, Observable, throwError } from 'rxjs'
import { ApiResponse } from '../dto/ApiResponse'
import { Timesheet } from '../model/timesheet.model'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { User } from '../model/flexc-user.model'
import * as dayjs from 'dayjs'
import { TimeSheetApproval } from '../model/TimeSheetApproval.model'
import { TranslateService } from '@ngx-translate/core'
import { EXPORT_TIMESHEET_HEADERS_CSV } from '../enums/exportTimesheetHeadersCsv'
import { base64Logo } from '../dto/Base64Logo'
import { LocalizedDatePipe } from 'app/utility/localized-date.pipe'
import { last } from 'lodash'
;(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs

const EXPORT_TIMESHEET_HEADERS = {
  date: 'DATE',
  projectName: 'PROJECT',
  description: 'DESCRIPTION',
  startWork: 'START_TIME',
  startPause: 'START_BREAK',
  endPause: 'END_BREAK',
  endWork: 'END_TIME',
  hourSummary: 'SUM_HOURS',
}

@Injectable({
  providedIn: 'root',
})
export class TimeSheetService {
  private timesheetUrl: string = environment.apiBase + '/time-sheets'
  private datePipe: LocalizedDatePipe = new LocalizedDatePipe(this.translate)

  constructor(private http: HttpClient, private translate: TranslateService) {}

  public getTimeSheetByUserId(userId: number): Observable<Timesheet> {
    return this.http
      .get<ApiResponse<Timesheet>>(this.timesheetUrl + '/' + userId)
      .pipe(map((res: ApiResponse<Timesheet>) => res.response))
  }

  public filterTimesheets(params: HttpParams): Observable<Timesheet[]> {
    return this.http
      .get<ApiResponse<Timesheet[]>>(this.timesheetUrl + '/filter-time-sheets/', { params: params })
      .pipe(map((res: ApiResponse<Timesheet[]>) => res.response))
  }

  public newTimeSheetEntery(body: any) {
    return this.http.post(this.timesheetUrl + '/create-time-sheet', body)
  }

  updateTimeSheetEntery(body: any): Observable<any> {
    return this.http.put(this.timesheetUrl + '/update-time-sheet', body).pipe(
      catchError((error: any) => {
        return throwError('Something went wrong. Please try again later.')
      })
    )
  }

  checkIfTsIsDuplicate(body: any): Observable<boolean> {
    return this.http.put<boolean>(this.timesheetUrl + '/verify-time-sheet-entry', body)
  }

  public timesheetsApprovalUpdateStatus(body: TimeSheetApproval) {
    return this.http.put(this.timesheetUrl + '/time-sheets-approval-update-status', body)
  }

  public approveTimesheets(params: HttpParams) {
    return this.http.put(this.timesheetUrl + '/approve-time-sheet-status', {}, { params: params })
  }

  public massTimeSheetEntries(body: any) {
    return this.http.post(this.timesheetUrl + '/create-all-time-sheets', body)
  }

  public getAllMonthsForFilterByUserId(userId: string): Observable<Date[]> {
    return this.http
      .get<ApiResponse<Date[]>>(this.timesheetUrl + '/get-available-months/' + userId)
      .pipe(map((res: ApiResponse<Date[]>) => res.response))
  }

  public approveTimesheetEntry(timesheetList: any) {
    return this.http.post(this.timesheetUrl + '/set-approval-status-bulk', {
      timeSheets: timesheetList,
      approvalStatus: {
        id: 1,
      },
    })
  }

  public billedTimesheetEntry(timesheetList: any) {
    return this.http.post(this.timesheetUrl + '/set-approval-status-bulk', {
      timeSheets: timesheetList,
      approvalStatus: {
        id: 4,
      },
    })
  }

  public updateStatusForTimesheetEntry(timesheetList: any, status: any) {
    return this.http.post(this.timesheetUrl + '/set-approval-status-bulk', {
      timeSheets: timesheetList,
      approvalStatus: status,
    })
  }

  public exportTimesheetById(timesheetIds: any, userData: User, exportAsCsv?: boolean, printPft?: boolean) {
    return this.http
      .put(`${this.timesheetUrl}/export-selected-time-sheets`, timesheetIds)
      .subscribe(({ response }: any) => {
        const mappedResponse = this.mapResponseForExport(response)

        exportAsCsv && userData
          ? this.convertTimesheetToCSV(mappedResponse)
          : this.convertTimesheetToPDF(mappedResponse, printPft)
      })
  }

  separateByUser(timeSheets: any) {
    return timeSheets?.reduce((acc: any, record: any) => {
      const found = acc.find(
        (storedRecord: { name: string; data: any }) => storedRecord.name === `${record.firstName} ${record.lastName}`
      )
      if (found) {
        acc = acc.map((stored: { name: string; data: any }) =>
          stored.name === found.name ? { ...stored, data: stored.data.concat([record]) } : stored
        )
      } else {
        acc.push({ name: `${record.firstName} ${record.lastName}`, data: [record] })
      }
      return acc
    }, [])
  }

  mapResponseForExport(response: any) {
    return {
      timeSheets: [
        ...response
          .map((timesheet: any) => ({
            ...timesheet,
            date: dayjs(timesheet.startWork).format('YYYY-MM-DD'),
            startWork: timesheet.startWork ? dayjs(timesheet.startWork).format('HH:mm') : '',
            startPause: timesheet.startPause ? this.dateOrNotTime(timesheet.startPause) : '',
            endPause: timesheet.endPause ? this.dateOrNotTime(timesheet.endPause) : '',
            endWork: timesheet.endWork ? dayjs(timesheet.endWork).format('HH:mm') : '',
            hourSummary: (Math.round((timesheet.hourSummary + Number.EPSILON) * 100) / 100)
              .toLocaleString('en-US', { maximumFractionDigits: 2 })
              .replace('.', ','),
            projectName: timesheet.projectName,
          }))
          .sort((a: any, b: any) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
          .map((timesheet: any) => ({
            ...timesheet,
            date: dayjs(timesheet.date).format('DD.MM.YYYY'),
          })),
      ],
    }
  }

  private dateOrNotTime(date: string | undefined) {
    return date ? dayjs(date).format('HH:mm') : ''
  }
  public removeSpecialCharacters(inputString: string): string {
    return inputString.replace(/[^a-zA-Z0-9 ]/g, '')
  }

  public fileName({ projectName, firstName, lastName }: any): string {
    const formattedYearMonth = dayjs(new Date()).format('YYYYMM')
    const formattedProject = this.removeSpecialCharacters(projectName).replace(/ /g, '_')
    const formattedFirstName = this.removeSpecialCharacters(firstName).replace(/ /g, '_')
    const formattedLastName = this.removeSpecialCharacters(lastName).replace(/ /g, '_')
    const concatenatedString = `${formattedYearMonth}_${formattedProject}_${formattedFirstName}_${formattedLastName}`

    return concatenatedString.toUpperCase()
  }

  convertTimesheetToCSV(exportData: any) {
    const replacer = (_key: string, value: any) => (value === null ? '' : value)
    const header = [
      'date',
      'projectName',
      'firstName',
      'lastName',
      'description',
      'startWork',
      'startPause',
      'endPause',
      'endWork',
      'hourSummary',
    ]
    const csv = [
      header
        .map(
          heading =>
            this.translate.instant(
              EXPORT_TIMESHEET_HEADERS_CSV[heading as keyof typeof EXPORT_TIMESHEET_HEADERS_CSV]
            ) || heading
        )
        .join(','),
      ...exportData.timeSheets.map((timesheet: any) =>
        header.map(fieldName => JSON.stringify(timesheet[fieldName], replacer)).join(',')
      ),
    ].join('\r\n')

    const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' })
    let downloadLink = document.createElement('a')
    const url = URL.createObjectURL(blob)
    const filename =
      dayjs(new Date()).format('YYYY') +
      dayjs(new Date()).format('MM') +
      '_' +
      exportData.timeSheets[0].projectName.toUpperCase().replace(/ /g, '_') +
      '_' +
      exportData.timeSheets[0].firstName.toUpperCase() +
      '_' +
      exportData.timeSheets[0].lastName.toUpperCase()
    //if Safari open in new window to save file with random filename.
    navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1 &&
      downloadLink.setAttribute('target', '_blank')

    downloadLink.setAttribute('href', url)
    downloadLink.setAttribute('download', filename + '.csv')
    downloadLink.style.visibility = 'hidden'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  convertTimesheetToPDF(exportData: any, print?: boolean) {
    const tableLayouts = {
      headerTable: {
        defaultBorder: false,
      },
      mainTable: {
        hLineWidth: (i: number) => (i === 0 ? 0 : 1),
        vLineWidth: (i: number) => 0.5,
        hLineColor: (i: number) => 'black',
        vLineColor: (i: number) => 'black',
        paddingLeft: (i: number) => 3,
        paddingRight: (i: number, node: any) => (i === node.table.widths.length - 1 ? 0 : 8),
      },
    }

    const { firstName, lastName, date } = exportData.timeSheets[0]

    const columnCount = Object.keys(EXPORT_TIMESHEET_HEADERS).length
    const descriptionColumnWidth = 25
    const projectColumnWidth = 15
    const columnWidth = (100 - descriptionColumnWidth - projectColumnWidth) / (columnCount - 2)

    const totalHours = this.calculateTotalHours(exportData.timeSheets)
    const pdfName = this.fileName(exportData.timeSheets[0])

    const customRow = [
      ...Array(columnCount - 3).fill({
        text: '',
        border: [false, false, false, false],
      }),
      {
        text: `${this.getMonth(date)}`,
        border: [false, false, false, false],
      },
      {
        text: `${this.translate.instant('SUM')}:`,
        alignment: 'right',
        border: [true, true, false, true],
      },
      {
        text: `${totalHours}`,
        alignment: 'left',
        border: [false, true, true, true],
      },
    ]

    customRow.forEach(rowItem => {
      rowItem.bold = true
      rowItem.fontSize = 7
      rowItem.fillColor = '#d8d8d8'
    })

    let docDefinition = {
      info: {
        title: `${pdfName}`,
      },
      defaultStyle: {
        fontSize: 7,
      },
      content: [
        {
          layout: 'headerTable',
          table: {
            widths: [`${columnWidth}%`, `${columnWidth + projectColumnWidth}%`, '*'],
            headerRows: 1,
            body: [
              [
                {
                  image: base64Logo,
                  width: 45,
                },
                {
                  text: '\n\n 9to5.cloud TIMESHEET',
                  bold: true,
                  fontSize: 8,
                },
                {
                  text: `\n\n ${firstName.toUpperCase()} ${lastName.toUpperCase()}`,
                  bold: true,
                  fontSize: 8,
                  alignment: 'left',
                },
              ],
            ],
          },
        },
        '\n\n',
        {
          layout: 'mainTable',
          table: {
            headerRows: 1,
            widths: Object.keys(EXPORT_TIMESHEET_HEADERS).map(key =>
              key === 'description'
                ? `${descriptionColumnWidth}%`
                : key === 'projectName'
                ? `${projectColumnWidth}%`
                : `${columnWidth}%`
            ),
            body: [
              [
                ...Object.keys(EXPORT_TIMESHEET_HEADERS).map(key => ({
                  text: this.translate.instant(EXPORT_TIMESHEET_HEADERS[key as keyof typeof EXPORT_TIMESHEET_HEADERS]),
                  bold: true,
                  border: [false, false, false, true],
                })),
              ],
              ...exportData.timeSheets.map((sheet: any) => {
                const date = sheet['date']

                return Object.keys(EXPORT_TIMESHEET_HEADERS).map(key => {
                  const cell = {
                    text: sheet[key],
                    fillColor:
                      key === 'date'
                        ? '#ffffff'
                        : key === 'hourSummary'
                        ? '#d8d8d8'
                        : this.isWeekend(date)
                        ? '#a5a5a5'
                        : '#ffffff',
                    border: [true, false, true, true],
                  }

                  const coloredColumns = ['description', 'startPause', 'endPause', 'hourSummary']
                  if (coloredColumns.includes(key) && !this.isWeekend(date)) {
                    cell.fillColor = '#d8d8d8'
                  }

                  return cell
                })
              }),
              customRow,
            ],
          },
        },
      ],
    }

    // Other implementation for pdfMake are also available like '.open()` or `.print()`
    print
      ? pdfMake.createPdf(docDefinition, tableLayouts).print()
      : pdfMake.createPdf(docDefinition, tableLayouts).download(`${pdfName}`)
  }

  public exportExcel(body: any, firstName: string, lastName: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    return this.http
      .put(this.timesheetUrl + '/export-selected-time-sheets-excel', body, {
        headers: headers,
        responseType: 'blob',
      })
      .subscribe(
        (response: Blob) => {
          this.downloadFile(response, firstName, lastName)
        },
        error => {}
      )
  }

  private downloadFile(blob: Blob, firstName: string, lastName: string) {
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')

    link.href = url

    link.download = firstName + '' + lastName + '.' + 'timesheet.xlsx'

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)
  }

  public getMonth(dateString: string): string {
    const [day, month, year] = dateString.split('.').map(Number)
    const translatedMonth = this.datePipe.transform(new Date(year, month - 1, day), 'MMMM')
    return `${translatedMonth.charAt(0).toUpperCase()}${translatedMonth.slice(1)}`
  }

  public isWeekend(date: string): boolean {
    if (!date) {
      return false
    }

    const pattern = /(\d{2})\.(\d{2})\.(\d{4})/
    const [, day, month, year] = date.match(pattern) || []
    const dateValue = new Date(`${year}-${month}-${day}`)

    return dateValue.getDay() === 0 || dateValue.getDay() === 6
  }

  public calculateTotalHours(timesheets: any[]): string {
    const totalHours = timesheets.reduce((sum, timesheet) => {
      const parsedHourSummary = parseFloat(timesheet.hourSummary.replace(',', '.'))
      return sum + parsedHourSummary
    }, 0)

    return totalHours.toLocaleString('en-US', { maximumFractionDigits: 2 }).replace('.', ',')
  }

  public deleteTimesheet(body: string) {
    const timeSheetId = body
    return this.http.delete(`${this.timesheetUrl}/delete-time-sheet/${timeSheetId}`)
  }

  public deleteMassTimesheet(body: any) {
    const url = `${this.timesheetUrl}/mass-delete-time-sheet`
    return this.http.request('delete', url, { body })
  }

  public uploadFile(id: number, file: any): Observable<ApiResponse<Timesheet>> {
    const fd = new FormData()
    fd.append('file', file, file.name)
    return this.http.put<ApiResponse<Timesheet>>(`${this.timesheetUrl}/update-time-sheet/${id}/file`, fd)
  }
}
