import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { LanguageService } from 'app/pages/core/services/language.service'
import { languages } from './languages'
import { BehaviorSubject, map } from 'rxjs'
import { Language } from 'app/pages/core/model/language'

@Component({
  selector: 'app-spoken-languages',
  templateUrl: './spoken-languages.component.html',
  styleUrls: ['./spoken-languages.component.scss'],
})
export class SpokenLanguagesComponent implements OnInit {
  @Input() form: FormGroup
  @Input() submitted: boolean

  public languagesPlaceholder: string = this.translate.instant('ADD_LANGUAGE')
  public languages: any[]
  public allLanguages: any[]

  public arr = languages
  public languageExist: boolean = false

  public input$ = new BehaviorSubject<string>('')
  public items$ = new BehaviorSubject<any[]>([])

  constructor(private fb: FormBuilder, private translate: TranslateService, private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageService.getAllLanguages().subscribe(res => {
      this.languages = res.response
      this.languages.forEach((lang: Language) => (lang.label = this.translate.instant(lang.name!)))
      this.languages.sort((a, b) => a.label!.localeCompare(b.label!))
      this.allLanguages = this.languages
      this.items$.next(this.languages)
    })
    this.input$.pipe(map(term => this.searchLanguage(term))).subscribe((res: any) => {
      this.items$.next(res)
    })
    this.languagesArray.valueChanges.subscribe(res => {
      this.items$.next(this.allLanguages?.filter(l => !res.map((sl: any) => sl.language?.id).includes(l.id)))
    })
    this.translate.onLangChange.subscribe((res: any) => {
      if (res) {
        this.languages.forEach((lang: Language) => (lang.label = this.translate.instant(`${lang.name!}`)))
        this.languages.sort((a, b) => a.label!.localeCompare(b.label!))
        this.allLanguages = this.languages
        this.items$.next([...this.languages])
      }
    })
  }

  private searchLanguage(term: string): any[] {
    if (this.items$.value && term) {
      return this.items$.value.filter(language => {
        return this.translate.instant(language?.name.toUpperCase()).toLowerCase().includes(term.toLowerCase())
      })
    }
    return this.allLanguages
  }

  get languagesArray() {
    return this.form.get('languages') as FormArray
  }

  addLanguage() {
    let cityForm = this.fb.group({
      language: ['', Validators.required],
      skill: [1, Validators.required],
    })
    this.languagesArray.push(cityForm)
  }

  deleteLenguage(i: any) {
    this.languagesArray.removeAt(i)
  }

  setNewValueForChoosenLanguage(event: any, indexOfElementInFormArray: number) {
    let langsArray = this.formControls['languages'] as FormArray
    let previousValues = langsArray.controls[indexOfElementInFormArray].value
    langsArray.controls[indexOfElementInFormArray].setValue({ skill: previousValues.skill, language: event })
  }

  get formControls() {
    return this.form.controls
  }
}
