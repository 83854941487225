import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Project } from 'app/pages/core/model/project.model'
import { DaysCount } from '../getDaysCount.service'
import { SharedService } from '../shared.service'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit {
  public id: string = ''
  public project: Project
  public daysLeft: number = 0
  public teamDaysLeft: number = 0
  public procentageLeft: number = 0
  public procentageTeamLeft: number = 0
  public notStarted: boolean = false
  public formatedDate: any
  public projectStatus: string
  public formatedStartDate: any
  public roleBackoffice: boolean = false
  public editProject: boolean = false
  totalDaysLeft: number
  isFromMyProjects: boolean = false

  constructor(
    private route: ActivatedRoute,
    private daysCount: DaysCount,
    private sharedService: SharedService,
    private _router: Router,
    private querySharedService: QuerySharedService
  ) {}

  ngOnInit(): void {
    this.sharedService.isFromMyProjects.subscribe((val: any) => (this.isFromMyProjects = val ?? false))

    if (this.route.snapshot.routeConfig!.path === 'project-overview/:id') {
      let id = this.route.snapshot.paramMap.get('id')
      this.id = this.route.snapshot.paramMap.get('id')!
      this.sharedService.setProjectId(this.id)
      if (id !== null) {
        this.sharedService.getProjectGuard().subscribe(project => {
          this.project = project
          this.sharedService.setProject(this.project)
          this.sharedService.setProjectMembersManager(this.project.projectMembers!)
          this.getDaysLeft()
          this.teamDaysLeft = this.daysCount.getTeamDaysLeft(this.daysLeft, this.project.plannedPeople!)
          this.procentageTeamLeft = this.daysCount.getprocentageTeamLeft(
            this.teamDaysLeft,
            this.project.timePeriod!,
            this.project.plannedPeople!
          )
          if (this.daysLeft > this.project.timePeriod!) {
            this.notStarted = true
          }
          this.formatDate()
          this.projectStatus = this.project.projectStatus?.name!
        })
      }
    }
  }

  getDaysLeft() {
    let today = new Date()
    let endDate = this.project.end
    this.daysLeft = this.daysCount.getBudget(today, endDate)
    this.totalDaysLeft = this.daysCount.getBudget(this.project.start, this.project.end)
    this.procentageLeft = this.daysCount.getProcentageLeft(this.daysLeft, this.totalDaysLeft)
  }

  formatDate() {
    let date = new Date(this.project.end)
    let dateStart = new Date(this.project.start)
    this.formatedDate = this.daysCount.getDate(date)
    this.formatedStartDate = this.daysCount.getDate(dateStart)
  }

  saveFinished(event: any) {
    if (event) {
      this.editProject = true
    }
  }

  isLinkActive(link: string) {
    if (link === 'USED_BUDGET') {
      return this._router.url.includes('used-budget')
    } else if (link === 'TEAM_MEMBERS') {
      return this._router.url.includes('team-members')
    } else {
      return false
    }
  }

  back(event: any) {
    let query = this.querySharedService.getQueryForRoute('projects/projects-list')
    this._router.navigate(['projects/projects-list'], {
      queryParams: {
        ...query,
        filter: this.querySharedService.getFilterForRoute('projects/projects-list'),
      },
      queryParamsHandling: 'merge',
    })
  }
}
