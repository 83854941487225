import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { DynamicContentService } from 'app/pages/core/services/dynamic-content.service'

@Component({
  selector: 'app-dynamic-modal-content',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: '<ng-template #dynamicComponent></ng-template>',
})
export class DynamicModalContent implements AfterViewInit {
  @ViewChild('dynamicComponent', { read: ViewContainerRef }) dynamicComponent: ViewContainerRef

  @Input() content: any

  constructor() {}

  ngAfterViewInit(): void {
    let ref: any = this.dynamicComponent.createComponent(this.content)
    ref.instance.isModal = true
  }
}
