<div>
  <app-project-details-card
    *ngIf="project"
    [project]="project"
    [daysLeft]="daysLeft"
    [totalDaysLeft]="totalDaysLeft"
    [teamDaysLeft]="teamDaysLeft"
    [procentageLeft]="procentageLeft"
    [procentageTeamLeft]="procentageTeamLeft"
    [notStarted]="notStarted"
    [formatedDate]="formatedDate"
    [projectStatus]="projectStatus"
    [formatedStartDate]="formatedStartDate"
    class="card card-xxl-stretch mb-xl-3"
  ></app-project-details-card>
</div>
<div style="padding: 10px">
  <app-create-new-project-flow [isEdit]="true"></app-create-new-project-flow>
</div>

<!-- <div class="card card-padding">
  <div class="d-flex align-items-start cards-gap">
    <div class="d-flex flex-column cards-gap flex-grow-1">
      <app-project-details *ngIf="dataLoaded" [editProjectForm]="editProjectForm"
        [submitted]="submitted"></app-project-details>
      <app-topic-and-knowhow *ngIf="dataLoaded" [editProjectForm]="editProjectForm"
        [submitted]="submitted"></app-topic-and-knowhow>
    </div>
    <div class="flex-grow-1">
      <app-time-and-budget *ngIf="dataLoaded" [editProjectForm]="editProjectForm" [submitted]="submitted"
        [weekModeValue]="weekModeValue"></app-time-and-budget>
    </div>
    <div>
    </div>
  </div>
</div>
<app-success-modal #modalSuccess [modalConfig]="modalConfig">
  <div class="modal">{{ 'SAVED_SUCCESSFULLY' | translate }}</div>
</app-success-modal> -->
