import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { ModalComponent } from './modal/modal.component'
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap'
import { SuccessModalComponent } from './success-modal/success-modal.component'
import { ErrorModalComponent } from './error-modal/error-modal.component'
import { ModalDeleteSuperuserComponent } from './modal-delete-superuser/modal-delete-superuser.component'
import { ModalAskUserForDoubleBookingComponent } from './modal-ask-user-for-double-booking/modal-ask-user-for-double-booking.component'
import { TranslateModule } from '@ngx-translate/core'
import { ApproveTimesheetsModalComponent } from './approve-timesheets-modal/approve-timesheets-modal.component'

@NgModule({
  declarations: [
    ModalComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    ModalDeleteSuperuserComponent,
    ModalAskUserForDoubleBookingComponent,
    ApproveTimesheetsModalComponent,
  ],
  imports: [CommonModule, InlineSVGModule, RouterModule, NgbModalModule, TranslateModule],
  exports: [
    ModalComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    ModalDeleteSuperuserComponent,
    ModalAskUserForDoubleBookingComponent,
    ApproveTimesheetsModalComponent,
  ],
})
export class ModalsModule {}
