<form [formGroup]="form" *ngIf="form">
  <div class="row mt-2">
    <div class="col-md-12">
      <label class="form-label fs-6 fw-bolder">{{ 'TOPIC_KNOWHOW' | translate }}</label
      ><br />
      <br />
      <form [formGroup]="tehnologyForm">
        <tag-input
          formControlName="items"
          (onAdd)="onAdding($event)"
          (onRemove)="onItemRemoved($event)"
          [displayBy]="'name'"
          [(ngModel)]="items"
          placeholder="Add"
          [onlyFromAutocomplete]="!authService.isProjectAdmin"
          secondaryPlaceholder=""
        >
          <tag-input-dropdown [autocompleteItems]="itemsListdb" [showDropdownIfEmpty]="true" [focusFirstElement]="true">
          </tag-input-dropdown>
        </tag-input>
      </form>
    </div>
  </div>
</form>
