import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { FormGroup, Validators } from '@angular/forms'
import { role_array } from './role_array'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { UserService } from 'app/pages/core/services/user.service'
import { fromEvent, pluck, debounceTime, switchMap, Observable } from 'rxjs'
import { AuthService } from 'app/modules/auth'
import { UserSharedService } from '../../user-shared.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-user-personal-data',
  templateUrl: './user-personal-data.component.html',
  styleUrls: ['./user-personal-data.component.scss'],
})
export class UserPersonalDataComponent implements OnInit, AfterViewInit {
  @ViewChild('username', { static: false }) usernameInput: ElementRef
  @ViewChild('email', { static: false }) emailInput: ElementRef

  @Input() submitted: boolean
  @Input() form: FormGroup

  @Input() update: boolean = false

  usernameExist: boolean = false
  blankSpace: boolean = false
  arr: any[]
  emailExist: boolean = false
  selectedFilter: number

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public userSharedService: UserSharedService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateRole()
    this.blur()
    this.arr.forEach(r => (r.label = this.translate.instant(r.label)))
    this.translate.onLangChange.subscribe(() => {
      this.translateRole()
    })
  }

  translateRole() {
    this.arr = role_array.map(role => {
      return {
        ...role,
        label: this.translate.instant(role.label),
      }
    })
  }

  ngAfterViewInit() {
    this.form
      .get('username')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          return this.searchRequest(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.usernameExist = result.response.valueOf()
        this.userSharedService.setUsernameExist(this.usernameExist)
      })
    this.form
      .get('email')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          return this.searchRequestEmail(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.emailExist = result.response.valueOf()
        this.userSharedService.setEmailExist(this.emailExist)
      })
  }

  searchRequest(changedValue: any): Observable<ApiResponse<Boolean>> {
    if (changedValue == '') {
      this.blankSpace = true
    } else {
      this.blankSpace = false
    }
    return this.userService.isUsernameTaken(changedValue)
  }

  searchRequestEmail(email: any): Observable<ApiResponse<Boolean>> {
    return this.userService.isEmailTaken(email)
  }

  public blur() {
    const id = this.form.get('userRole')!.value
    const selectedElement = this.arr.find(element => element.id === id)

    if (selectedElement) {
      this.selectedFilter = selectedElement
    }
    if (this.form.get('noEmail')!.value) {
      this.form
        .get('email')
        ?.removeValidators([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$'),
        ])
      this.form.get('email')?.setValue(null)
      this.emailExist = false
      this.userSharedService.setEmailExist(this.emailExist)
    } else {
      this.form
        .get('email')
        ?.setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$')])
    }
  }

  setValueForUserRole(event: any) {
    this.form.controls['userRole'].setValue(event?.id)
  }

  get formControls() {
    return this.form!.controls
  }
}
