import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { catchError, map, Observable, throwError } from 'rxjs'
import { ApiResponse } from '../dto/ApiResponse'
import { ProjectReview } from '../model/project-review'
import { Project } from '../model/project.model'
import { ProjectStatus } from '../model/project-status.model'
import { ApprovalListResponse } from '../model/TimeSheetApproval.model'
import { User } from '../model/flexc-user.model'
import { ProjectUserModel } from '../model/projectUser.model'
import { SharedService } from 'app/pages/projects/shared.service'
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public projectUrl: string = environment.apiBase + '/projects'

  constructor(private http: HttpClient, private sharedService: SharedService) {}

  createProject(body: Project): Observable<ApiResponse<Project>> {
    return this.http
      .post<ApiResponse<Project>>(this.projectUrl + '/create-project', body)
      .pipe(catchError(this.handleError))
  }

  createEmptyProject(): Observable<ApiResponse<string>> {
    return this.http
      .post<ApiResponse<string>>(this.projectUrl + '/create-empty-project', {})
      .pipe(catchError(this.handleError))
  }

  handleError(error: any): Observable<never> {
    return throwError('An error occurred. Please try again later.')
  }

  archivingProject(projectId: string): Observable<ApiResponse<Project>> {
    return this.http.put<ApiResponse<Project>>(this.projectUrl + '/archiving-project/' + projectId, {})
  }

  getAllArchivedProjects(): Observable<ApiResponse<Project[]>> {
    return this.http.get<ApiResponse<Project[]>>(this.projectUrl + '/get-all-archived-projects')
  }

  getAllUnarchivedProjects(): Observable<ApiResponse<Project[]>> {
    return this.http.get<ApiResponse<Project[]>>(this.projectUrl + '/get-all-unarchived-projects')
  }

  getAllUnarchivedProjectsResponseOnly(): Observable<Project[]> {
    return this.http
      .get<ApiResponse<Project[]>>(this.projectUrl + '/get-all-unarchived-projects')
      .pipe(map((res: ApiResponse<Project[]>) => res.response))
  }

  getAllUnarchivedProjectsWihtSortingResponseOnly(): Observable<Project[]> {
    return this.http.get<ApiResponse<Project[]>>(this.projectUrl + '/get-all-unarchived-projects').pipe(
      map((res: ApiResponse<Project[]>) => res.response),
      map((val: Project[]) =>
        val.filter((filteringItem: Project) => filteringItem?.name).sort((a, b) => a.name.localeCompare(b.name))
      )
    )
  }

  getByProjectStatusId(projectStatusid: string): Observable<ApiResponse<Project[]>> {
    return this.http.get<ApiResponse<Project[]>>(this.projectUrl + '/get-by-project-status/' + projectStatusid)
  }

  reviewProject(projectReview: ProjectReview): Observable<ApiResponse<Project>> {
    return this.http.put<ApiResponse<Project>>(this.projectUrl + '/review-project', projectReview)
  }

  getAllProjects(
    page: number,
    size: number,
    sortBy: string,
    sortDirection?: string,
    statuses?: any,
    search?: any
  ): Observable<ApiResponse<Project[]>> {
    let paramMap: HttpParams = new HttpParams()
      .set('page', page)
      .append('size', size)
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))

    if (search && search != '') {
      paramMap = paramMap.append('searchInput', search)
    }
    if (statuses && statuses != '') {
      paramMap = paramMap.append('statuses', statuses)
    }
    return this.http.get<ApiResponse<Project[]>>(`${this.projectUrl}/find-all`, { params: paramMap })
  }

  getOneProject(id: string): Observable<ApiResponse<Project>> {
    return this.http.get<ApiResponse<Project>>(this.projectUrl + '/' + id)
  }

  updateProject(body: any): Observable<ApiResponse<Project>> {
    return this.http.put<ApiResponse<Project>>(this.projectUrl + '/update-project', body)
  }

  updateProjectStatus(id: string, projectStatus: ProjectStatus): Observable<ApiResponse<Project>> {
    const body = {
      id: id,
      projectStatus: projectStatus,
    }
    return this.http.put<ApiResponse<Project>>(`${this.projectUrl}/change-project-status`, body)
  }

  getUsersForProject(project: Project): Observable<ApiResponse<User[]>> {
    return this.http.put<ApiResponse<User[]>>(this.projectUrl + '/update-project-knowledges/', project)
  }

  getUsersForProjects(project: Project): Observable<ApiResponse<ProjectUserModel[]>> {
    return this.http.put<ApiResponse<ProjectUserModel[]>>(this.projectUrl + '/update-project-knowledges/', project)
  }

  getUsersProject(
    userId: string,
    page: number,
    size: number,
    sortBy: string,
    sortDirection?: string,
    search?: any,
    selectedStatus?: any
  ): Observable<ApiResponse<Project[]>> {
    let paramMap: HttpParams = new HttpParams()
      .set('userId', userId)
      .set('page', page)
      .append('size', size)
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))

    if (search && search != '') {
      paramMap = paramMap.append('searchInput', search)
    }
    if (selectedStatus && selectedStatus != '') {
      paramMap = paramMap.append('statuses', selectedStatus)
    }
    return this.http.get<ApiResponse<Project[]>>(`${this.projectUrl}/get-projects-by-user?`, { params: paramMap })
  }

  getUsersProjectTimesheet(userId: string): Observable<Project[]> {
    return this.http
      .get<ApiResponse<Project[]>>(this.projectUrl + '/get-projects-by-user?userId=' + userId)
      .pipe(map((res: ApiResponse<Project[]>) => res.response))
  }

  getUsersProjectTimesheetWithoutUserId(): Observable<Project[]> {
    return this.http
      .get<ApiResponse<Project[]>>(this.projectUrl + '/get-projects-by-user')
      .pipe(map((res: ApiResponse<Project[]>) => res.content))
  }

  getTimeSheetApprovals(projectId: string): Observable<ApiResponse<ApprovalListResponse[]>> {
    return this.http.get<ApiResponse<ApprovalListResponse[]>>(
      `${this.projectUrl}/get-time-sheet-approvals/${projectId}`
    )
  }

  getApprovalSummaryByMonth(projectId: string): Observable<ApiResponse<ApprovalListResponse[]>> {
    return this.http.get<ApiResponse<ApprovalListResponse[]>>(
      `${this.projectUrl}/get-budget-overview?projectId=${projectId}`
    )
  }

  getTimeSheetApprovalsForPDF(projectId: string) {
    return this.http.get(`${this.projectUrl}/get-time-sheet-approvals-for-pdf/${projectId}`)
  }

  getTimeSheetForPDF(projectId: string, startMonth: string) {
    return this.http.get(`${this.projectUrl}/get-time-sheets-for-pdf?projectId=${projectId}&startMonth=${startMonth}`)
  }

  public uploadImage(id: string): Observable<ApiResponse<Project>> {
    const selectedFile: File = this.sharedService.getImageFile()
    const fd = new FormData()
    fd.append('image', selectedFile, selectedFile.name)
    return this.http.put<ApiResponse<Project>>(this.projectUrl + '/update-project/' + id + '/' + 'image', fd)
  }

  uploadProjectImage(id: string, file: File): Observable<ApiResponse<Project>> {
    const fd = new FormData()
    fd.append('image', file, file.name)
    return this.http.put<ApiResponse<Project>>(this.projectUrl + '/update-project/' + id + '/' + 'image', fd)
  }

  updateImg(id: string, selectedFile: File): Observable<ApiResponse<Project>> {
    const fd = new FormData()
    fd.append('image', selectedFile, selectedFile.name)
    return this.http.put<ApiResponse<Project>>(this.projectUrl + '/update-project/' + id + '/' + 'image', fd)
  }

  getManagerProject(id: string): Observable<ApiResponse<Project[]>> {
    return this.http.get<ApiResponse<Project[]>>(this.projectUrl + '/get-projects-by-manager?userId=' + id)
  }

  deleteUserFromProject(userId: string, projectId: string): Observable<ApiResponse<Project>> {
    const url = `${this.projectUrl}/remove-member/${projectId}/${userId}`
    return this.http.delete<ApiResponse<Project>>(url).pipe(
      catchError((error: any) => {
        return throwError('Something went wrong. Please try again later.')
      })
    )
  }

  isProjectManager(projectId: string): Observable<ApiResponse<boolean>> {
    return this.http.get<ApiResponse<boolean>>(this.projectUrl + `/is-project-manager/${projectId}`)
  }

  isProjectNumberTaken(projectNumber: string): Observable<ApiResponse<Boolean>> {
    return this.http.put<ApiResponse<Boolean>>(this.projectUrl + '/exists-by-project-field', { projectNumber })
  }

  isProjectOfferTaken(projectOffer: string): Observable<ApiResponse<Boolean>> {
    return this.http.put<ApiResponse<Boolean>>(this.projectUrl + '/exists-by-project-field', { projectOffer })
  }

  isProjectNameTaken(name: string): Observable<ApiResponse<Boolean>> {
    return this.http.put<ApiResponse<Boolean>>(this.projectUrl + '/exists-by-project-field', { name })
  }

  getSumOfAssignedDays(projectId: string): Observable<ApiResponse<Project[]>> {
    return this.http.get<ApiResponse<Project[]>>(this.projectUrl + `/get-summary-of-assigned-days/${projectId}`)
  }

  deleteProjectImage(projectId: string): Observable<ApiResponse<Project>> {
    return this.http.delete<ApiResponse<Project>>(this.projectUrl + `/update-project/${projectId}` + '/delete-image')
  }

  getAllUnarchivedProjectsMap(): Observable<Project[]> {
    return this.http
      .get<ApiResponse<Project[]>>(this.projectUrl + '/get-all-projects-map')
      .pipe(map((res: ApiResponse<Project[]>) => res.response))
  }

  getProgresBarValue(userId: string, projectId: string): Observable<ApiResponse<any>> {
    const url = `${this.projectUrl}/get-progress-bar-timesheet-entry?userId=${userId}&projectId=${projectId}`
    return this.http.get<ApiResponse<any>>(url)
  }

  getApprovalUsersSummary(monthStart: any, projectId: any): Observable<ApiResponse<any>> {
    const url = `${this.projectUrl}/get-approval-users-summary?monthStart=${monthStart}&projectId=${projectId}`
    return this.http.get<ApiResponse<any>>(url)
  }
  getApprovalSummary(monthStart: any, projectId: any): Observable<ApiResponse<any>> {
    const url = `${this.projectUrl}/get-approval-summary?monthStart=${monthStart}&projectId=${projectId}`
    return this.http.get<ApiResponse<any>>(url)
  }
  getUsersTimesheetSummary(userId: any, projectId: any): Observable<ApiResponse<any>> {
    const url = `${this.projectUrl}/get-users-time-sheet-summary?projectId=${projectId}&userId=${userId}`
    return this.http.get<ApiResponse<any>>(url)
  }

  exportApprovalTimesheets(monthStart: any, projectId: any, userId: any): Observable<ApiResponse<any>> {
    const url = `${this.projectUrl}/approval-users-export?monthStart=${monthStart}&projectId=${projectId}&userId=${userId}`
    return this.http.get<ApiResponse<any>>(url)
  }

  exportApprovalTimesheetsExcel(monthStart: any, projectId: any, userId: any): Observable<Blob> {
    const url = `${this.projectUrl}/approval-users-export-excel?monthStart=${monthStart}&projectId=${projectId}&userId=${userId}`

    return this.http.get(url, { responseType: 'arraybuffer' }).pipe(
      map(response => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        return blob
      })
    )
  }
}
