import { CommonModule } from '@angular/common'
import { Component, Input, Output, Provider, forwardRef } from '@angular/core'
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NzSelectModule, NzSelectSizeType } from 'ng-zorro-antd/select'
import { EventEmitter } from '@angular/core'
import { LocalizedDatePipe } from 'app/utility/localized-date.pipe'
export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
}

@Component({
  standalone: true,
  selector: 'app-select',
  imports: [CommonModule, NzSelectModule, FormsModule, InlineSVGModule, LocalizedDatePipe],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [WRAPPER_CONTROL_VALUE_ACCESSOR],
})
export class SelectComponent {
  @Input() size: NzSelectSizeType = 'default'
  @Input() options: any
  @Input() selectedValue: any
  @Input() type: string
  @Input() placeholder: string = ''
  @Input() allowClear: boolean = false
  @Input() showSearch: boolean = true
  @Input() isListOfCustomers: boolean = false
  @Input() label: any
  @Input() inputDisable: boolean = false
  @Input() isTimeSheetMonths: boolean = false
  @Output() statusSelectChange = new EventEmitter()

  public modelChange(event: any) {
    this.statusSelectChange.emit(event)
  }

  compareFn = (o1: any, o2: any): boolean => {
    if (!this.isTimeSheetMonths) {
      if (o1 && o2) {
        if (typeof o1 === 'object' && typeof o2 === 'object') {
          if (o1.id && o2.id) {
            return o1.id === o2.id
          } else {
            return o1.value === o2.value
          }
        }
        return o1 === o2
      }
      return false
    } else {
      return o1[0] !== undefined && o2 !== undefined ? o1[0]?.toISOString() === o2?.toISOString() : o1 === o2
    }
  }

  isDateType(option: any): boolean {
    return option instanceof Date
  }
}
