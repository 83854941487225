import { NgModule, APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ClipboardModule } from 'ngx-clipboard'
import { TranslateModule } from '@ngx-translate/core'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { DatePipe, DecimalPipe } from '@angular/common'
import { UsersModule } from './pages/users/users.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { AuthInterceptorInterceptor } from './pages/core/services/auth-interceptor.interceptor'
import { ProjectsModule } from './pages/projects/projects.module'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { initializeKeycloak } from '../app/utility/app.init'
import { TagInputModule } from 'ngx-chips'
import { registerLocaleData } from '@angular/common'
import localeDe from '@angular/common/locales/de'
import localeSr from '@angular/common/locales/sr-Latn'
import { SpinnerComponent } from './pages/spinner/spinner.component'
import { LoadingInterceptor } from './pages/core/services/loading.interceptor'
import { en_US, NZ_I18N, de_DE, sr_RS, ru_RU } from 'ng-zorro-antd/i18n'
import { ModalsModule } from './_metronic/partials/layout/modals/modals.module'

registerLocaleData(localeDe)
registerLocaleData(localeSr)

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-'

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER)
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      }
    }
    return null
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '.'

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER)
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      }
    }
    return null
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      const day = date.day < 10 ? '0' + date.day : date.day
      const month = date.month < 10 ? '0' + date.month : date.month
      return day + this.DELIMITER + month + this.DELIMITER + date.year
    }
    return ''
  }
}
@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US
          case 'de':
            return de_DE
          case 'ru':
            return ru_RU
          case 'sr':
            return sr_RS
          default:
            return en_US
        }
      },
      deps: [LOCALE_ID],
    },
    DecimalPipe,
    DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    UsersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    ReactiveFormsModule,
    NgSelectModule,
    ProjectsModule,
    KeycloakAngularModule,
    TagInputModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    FormsModule,
    ModalsModule,
  ],
})
export class AppModule {}
