import { Injectable } from '@angular/core'
import { SharedService } from '../../shared.service'
import { FormGroup } from '@angular/forms'
import { Dayjs } from 'dayjs'

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor(private sharedService: SharedService) {}

  getBudget(start: Dayjs, end: Dayjs, weekMode: number) {
    return this.getBusinessDaysCount(start, end, weekMode)
  }

  countWeekdays(startDate: Date, endDate: Date, weekMode: number) {
    // Convert dates to millisecond timestamps
    const startMs = startDate.getTime()
    const endMs = endDate.getTime()

    // Initialize a counter for weekdays
    let workingDays = 0
    let saturdayCount = 0
    let sundayCount = 0
    let weekendDays = 0

    // Loop through each day between the start and end date
    for (let i = startMs; i <= endMs; i += 1000 * 60 * 60 * 24) {
      const day = new Date(i)

      // Check if the day is a weekday (not Saturday or Sunday)
      if (day.getDay() === 0 || day.getDay() === 6) {
        weekendDays++
        if (day.getDay() === 6) {
          saturdayCount++
        }
        if (day.getDay() === 0) {
          sundayCount++
        }
      } else {
        workingDays++
      }
    }

    if (weekMode === 5) {
      return workingDays
    } else if (weekMode === 6) {
      return workingDays + saturdayCount
    } else {
      return workingDays + saturdayCount + sundayCount
    }
  }

  private getBusinessDaysCount(startDate: Dayjs, endDate: Dayjs, weekMode: number): number {
    let count = 0
    let saturdayCount = 0
    let sundayCount = 0
    let counterDate = startDate

    while (counterDate.isSameOrBefore(endDate)) {
      const dayOfWeek = counterDate.day()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++
      }
      if (dayOfWeek === 6) {
        saturdayCount++
      }
      if (dayOfWeek === 0) {
        sundayCount++
      }
      counterDate = counterDate.add(1, 'day')
    }

    return weekMode == 5 ? count : weekMode == 6 ? count + saturdayCount : count + saturdayCount + sundayCount
  }

  blur(projectForm: FormGroup) {
    this.sharedService.setNoEnd()
    projectForm.get('noEnd')?.setValue(this.sharedService.getNoEnd())
    if (this.sharedService.getNoEnd()) {
      projectForm.get('endDate')?.setValue('')
      projectForm.controls['endDate'].disable()
    } else {
      projectForm.controls['endDate'].enable()
    }
  }
}
