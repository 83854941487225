<div class="widget" [ngClass]="{ 'item_wrapper-max-height': isFromUser }">
  <div class="d-flex flex-column">
    <div class="card card-flush" [ngClass]="{ 'item_wrapper-max-height': isFromUser }">
      <div class="card-header pt-5 d-flex">
        <div class="card-title d-flex flex-column">
          <span *ngIf="showDescription" class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{{ projectNumber }}</span>
          <span class="text-gray-400 pt-1 fw-semibold fs-6">{{ description }}</span>
        </div>
        <div *ngIf="!isFromUser" class="button-padding-top">
          <app-button
            [title]="allProjectsButton | translate"
            buttonType="primary"
            (click)="navigateToProjects()"
          ></app-button>
        </div>
      </div>

      <div class="card-body pt-2 pb-4 align-items-center">
        <div
          class="d-flex flex-column content-justify-center flex-row-fluid all-bullet"
          [ngClass]="{ 'item-margin-bottom': isFromUser }"
        >
          <div class="d-flex fw-semibold align-items-center">
            <div class="bullet w-8px h-3px rounded-2 bg-success me-3 bullet-1"></div>
            <div class="text-gray-500 flex-grow-1 me-4">{{ label1 }}</div>
            <div class="fw-bolder text-gray-700 text-xxl-end">{{ stats1 }}</div>
          </div>

          <div class="d-flex fw-semibold align-items-center my-3">
            <div class="bullet w-8px h-3px rounded-2 bg-primary me-3 bullet-2"></div>
            <div class="text-gray-500 flex-grow-1 me-2" style="min-width: 65px">{{ label2 }}</div>
            <div class="fw-bolder text-gray-700 text-xxl-end">{{ stats2 }}</div>
          </div>

          <div *ngIf="showDescription" class="d-flex fw-semibold align-items-center">
            <div class="bullet w-8px h-3px rounded-2 me-3" style="background-color: #ffa5aa"></div>
            <div class="text-gray-500 flex-grow-1 me-4">{{ label4 }}</div>
            <div class="fw-bolder text-gray-700 text-xxl-end">{{ stats4 }}</div>
          </div>

          <div *ngIf="showDescription" class="d-flex fw-semibold align-items-center my-3">
            <div class="bullet w-8px h-3px rounded-2 me-3" style="background-color: #e4e6ef"></div>
            <div class="text-gray-500 flex-grow-1 me-4">{{ label3 }}</div>
            <div class="fw-bolder text-gray-700 text-xxl-end">{{ stats3 }}</div>
          </div>
        </div>
        <div
          class="d-flex flex-center me-5"
          [ngClass]="{ 'your-class-for-non-user': !isFromUser, 'your-class-for-user': isFromUser }"
        >
          <div
            class="chart-position"
            id="kt_card_widget_17_chart"
            [ngStyle]="{ 'min-width': chartSize + 'px', 'min-height': chartSize + 'px' }"
            [attr.data-kt-size]="chartSize"
            [attr.data-kt-line]="chartLine"
          ></div>
        </div>
      </div>
    </div>
    <div *ngIf="isFromUser" class="button-padding">
      <button class="btn btn-primary" [routerLink]="'../../../projects/projects-list/' + userId">
        {{ 'ALL_PROJECTS' | translate }}
      </button>
    </div>
  </div>
</div>
