import { ContactPeron } from 'app/pages/core/model/contact-person.model'
import { Injectable } from '@angular/core'
import { Customer } from '../core/model/flexc-customer.model'
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  customer: Customer
  contactPerson: ContactPeron
  exist: boolean = false
  contactPersonChange: boolean = false
  selectedFile: File
  constructor() {}
  setCustomer(customer: Customer) {
    this.exist = true
    this.customer = customer
  }
  clearCustomer() {
    this.exist = false
  }
  getCustomer() {
    return this.customer
  }
  setContactPerson(person: ContactPeron) {
    this.contactPerson = person
    this.contactPersonChange = true
  }
  getContactPerson() {
    return this.contactPerson
  }
  setModifyCutomer() {
    this.exist = true
  }
  setImageFile(file: File) {
    this.selectedFile = file
  }
  getImageFile() {
    return this.selectedFile
  }
}
