import { Component, EventEmitter, Input, Output } from '@angular/core'
import { coerceBooleanProperty, BooleanInput } from '@angular/cdk/coercion'
import { CommonModule } from '@angular/common'
import { BUTTON_SIZES } from './button.component.model'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NzButtonModule, NzButtonType } from 'ng-zorro-antd/button'

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, InlineSVGModule, NzButtonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  private _disabled = false
  private _active = false
  private _fullWidth = false
  private _disableWrapperPadding = false
  private _noRadius = false
  private _alignLeft = false

  @Input() type: string = 'button'
  @Input() buttonType: NzButtonType = 'default'
  @Input() size: string = BUTTON_SIZES.MEDIUM
  @Input() title: string
  @Input() prefixIcon!: string
  @Input() suffixIcon!: string
  @Input() additionalClass: string = ''
  @Output() handleClick: EventEmitter<any> = new EventEmitter()

  @Input()
  get disableWrapperPadding() {
    return this._disableWrapperPadding
  }
  set disableWrapperPadding(value: BooleanInput) {
    this._disableWrapperPadding = coerceBooleanProperty(value)
  }

  @Input()
  get fullWidth() {
    return this._fullWidth
  }
  set fullWidth(value: BooleanInput) {
    this._fullWidth = coerceBooleanProperty(value)
  }

  @Input()
  get disabled() {
    return this._disabled
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value)
  }

  @Input()
  get active() {
    return this._active
  }
  set active(value: BooleanInput) {
    this._active = coerceBooleanProperty(value)
  }

  @Input()
  get noRadius() {
    return this._noRadius
  }
  set noRadius(value: BooleanInput) {
    this._noRadius = coerceBooleanProperty(value)
  }

  @Input()
  get alignLeft() {
    return this._alignLeft
  }
  set alignLeft(value: BooleanInput) {
    this._alignLeft = coerceBooleanProperty(value)
  }

  constructor() {}

  iconIsSvg(src: string) {
    return src.includes('.svg')
  }

  svgAttributesBySize() {
    if (this.size === BUTTON_SIZES.SMALL) {
      return { height: '16px', width: '12px' }
    } else if (BUTTON_SIZES.MEDIUM) {
      return { height: '20px', width: '16px' }
    } else {
      return { height: '22px', width: '18px' }
    }
  }

  onClick(event: Event) {
    this.handleClick.emit(event)
  }
}
