import { Injectable } from '@angular/core'
import { Project } from '../core/model/project.model'

@Injectable({
  providedIn: 'root',
})
export class CountProjectDatesService {
  constructor() {}

  getDaysLeftProjectBudget(project: Project) {
    project.projectBudgetLeftDays =
      project.usedBudgetInPercentage && project.budgetEstimation && project.usedBudget
        ? project?.budgetEstimation! - project?.usedBudget!
        : project.budgetEstimation
    project.usedBudgetInPercentage = project.usedBudgetInPercentage || 0
    project.budgetEstimation = project.budgetEstimation || 0
    project.usedBudget = project.usedBudget || 0
    return project
  }
}
