import { Component, Input, OnChanges, OnInit, Output, Provider, SimpleChanges, forwardRef } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NzSelectModule, NzSelectSizeType } from 'ng-zorro-antd/select'
import { EventEmitter } from '@angular/core'

export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true,
}
@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [CommonModule, NzSelectModule, FormsModule, InlineSVGModule],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() size: NzSelectSizeType = 'default'
  @Input() options: any
  @Input() selectedValue: any
  @Input() type: string
  @Input() label: any
  @Input() placeholder: string = ''
  @Input() allowClear: boolean = false
  @Input() inputDisable: boolean = false
  @Input() showPlaceholder: boolean = false
  @Input() isProjectFilter: boolean = false
  @Input() isTimesheetInput: boolean = false
  @Input() isKnowladge: boolean = false

  @Output() statusSelectChange = new EventEmitter()

  projectFilterCounterPlaceholder: number = 0

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // this.projectFilterCounterPlaceholder = changes.selectedValue?.currentValue.length ?? 0

    if (changes.selectedValue) {
      const newSelectedValue = changes.selectedValue.currentValue
      if (Array.isArray(newSelectedValue)) {
        this.projectFilterCounterPlaceholder = newSelectedValue.length
      } else {
        this.projectFilterCounterPlaceholder = 0
      }
    }
  }

  public modelChange(event: any) {
    this.projectFilterCounterPlaceholder = event.length
    this.statusSelectChange.emit(event)
  }

  compareFn = (o1: any, o2: any): boolean => {
    if (o1 && o2) {
      return o1.id === o2.id
    }
    return false
  }
}
