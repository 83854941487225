<div class="table-graph-cell">
  <div class="table-graph-cell_label">
    <span>
      {{ percentage | number : '1.0-1' }}% {{ 'DONE' | translate | lowercase }} / {{ daysLeft | number : '1.0-1' }}
      {{ 'DAYS_LEFT' | translate }}
    </span>
    <span>{{ total | number : '1.0-1' }} {{ 'DAYS_TOTAL' | translate }}</span>
  </div>
  <nz-progress
    [nzPercent]="percentage"
    [nzShowInfo]="false"
    [ngClass]="{
      'green-pgb': percentage >= 1 && percentage <= 49,
      'yellow-pgb': percentage >= 50 && percentage <= 84,
      'red-pgb': percentage >= 85
    }"
  ></nz-progress>
</div>
