<div class="select_wrapper">
  <ng-container *ngIf="!isProjectFilter && !isTimesheetInput && !isKnowladge">
    <nz-select
      *ngIf="options?.length"
      class="select_field multi_select_field"
      [ngClass]="{ status: type === 'status', primary: type === 'primary' }"
      [nzSize]="size"
      [(ngModel)]="selectedValue"
      [nzPlaceHolder]="placeholder"
      (ngModelChange)="modelChange($event)"
      [nzAllowClear]="allowClear"
      [nzShowArrow]="true"
      [disabled]="inputDisable"
      nzMode="multiple"
    >
      <nz-option *ngFor="let option of options" [nzValue]="option" [nzLabel]="option[label]"></nz-option>
    </nz-select>
  </ng-container>

  <ng-container *ngIf="isProjectFilter && options?.length">
    <div class="overlay">
      <p class="displayPlaceholder">{{ placeholder + ' - ' + projectFilterCounterPlaceholder }}</p>
      <nz-select
        *ngIf="options?.length"
        class="select_field_placeholder"
        [ngClass]="{ status: type === 'status', primary: type === 'primary' }"
        [nzSize]="size"
        [(ngModel)]="selectedValue"
        [nzPlaceHolder]="placeholder"
        (ngModelChange)="modelChange($event)"
        [nzAllowClear]="allowClear"
        [nzShowArrow]="true"
        [disabled]="inputDisable"
        nzMode="multiple"
      >
        <nz-option *ngFor="let option of options" [nzValue]="option" [nzLabel]="option[label]"></nz-option>
      </nz-select>
    </div>
  </ng-container>

  <ng-container *ngIf="isTimesheetInput">
    <nz-select
      [(ngModel)]="selectedValue"
      nzAllowClear
      class="select_field multi_select_field"
      nzMode="multiple"
      [compareWith]="compareFn"
      [nzAllowClear]="allowClear"
      [nzShowArrow]="true"
      (ngModelChange)="modelChange($event)"
    >
      <nz-option-group *ngFor="let optionGroups of options" [nzLabel]="optionGroups.name">
        <nz-option *ngFor="let option of optionGroups.values" [nzLabel]="option.name" [nzValue]="option"></nz-option>
      </nz-option-group>
    </nz-select>
  </ng-container>

  <ng-container *ngIf="isKnowladge">
    <nz-select
      class="select_field multi_select_field"
      [ngClass]="{ status: type === 'status', primary: type === 'primary' }"
      [nzSize]="size"
      [(ngModel)]="selectedValue"
      [compareWith]="compareFn"
      [nzPlaceHolder]="placeholder"
      (ngModelChange)="modelChange($event)"
      [nzAllowClear]="allowClear"
      [nzShowArrow]="true"
      [disabled]="inputDisable"
      nzMode="multiple"
    >
      <nz-option *ngFor="let option of options" [nzValue]="option" [nzLabel]="option[label]"></nz-option>
    </nz-select>
  </ng-container>
</div>
