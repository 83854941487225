import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CreateUserComponent } from './create-user/create-user.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UsersRoutingModule } from './users-routing.module'
import { UsersListComponent } from './users-list/users-list.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CreatePasswordComponent } from './create-password/create-password.component'
import { TranslateModule } from '@ngx-translate/core'
import { UserDetailsComponent } from './create-user/user-details/user-details.component'
import { PersonalProfileComponent } from './create-user/personal-profile/personal-profile.component'
import { ExpertiseAndProjectsComponent } from './create-user/expertise-and-projects/expertise-and-projects.component'
import { TagInputModule } from 'ngx-chips'
import { ModalsModule } from 'app/_metronic/partials/layout/modals/modals.module'
import { NoFetchDateComponent } from '../../standalone/no-fetch-date/no-fetch-date.component'
import { UserProfileImageComponent } from './create-user/user-profile-image/user-profile-image.component'
import { UserPersonalDataComponent } from './create-user/user-personal-data/user-personal-data.component'
import { SpokenLanguagesComponent } from './create-user/spoken-languages/spoken-languages.component'
import { PersonalExperienceComponent } from './create-user/personal-experience/personal-experience.component'
import { KnowledgeComponent } from './create-user/knowledge/knowledge.component'
import { KnowHowComponent } from './create-user/know-how/know-how.component'
import { UpdateEditUserComponent } from './update-edit-user/update-edit-user.component'
import { NgxPaginationModule } from 'ngx-pagination'
import { TableComponent } from 'app/standalone/table/table.component'
import { MenuDropdownComponent } from 'app/standalone/menu-dropdown/menu-dropdown.component'
import { SwitchComponent } from 'app/standalone/switch/switch.component'
import { ButtonComponent } from 'app/standalone/button/button.component'
import { InputComponent } from 'app/standalone/input/input.component'
import { SelectComponent } from 'app/standalone/select/select.component'
import { DashboardWidgetComponent } from 'app/standalone/dashboard-widget/dashboard-widget.component'
import { ChartWidgetComponent } from 'app/standalone/chart-widget/chart-widget.component'
import { SpecificUserTimesheetViewComponent } from './specific-user-timesheet-view/specific-user-timesheet-view.component'
import { TimesheetListComponent } from '../timesheet/timesheet-list/timesheet-list.component'
import { LanguageSwitcherModule } from 'app/_metronic/partials/layout/language-switcher/language-switcher.module'
import { PasswordInputComponent } from 'app/standalone/password-input/password-input.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
@NgModule({
  declarations: [
    CreateUserComponent,
    UsersListComponent,
    CreatePasswordComponent,
    UserDetailsComponent,
    PersonalProfileComponent,
    ExpertiseAndProjectsComponent,
    UserProfileImageComponent,
    UserPersonalDataComponent,
    SpokenLanguagesComponent,
    PersonalExperienceComponent,
    KnowledgeComponent,
    KnowHowComponent,
    UpdateEditUserComponent,
    SpecificUserTimesheetViewComponent,
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UsersRoutingModule,
    NgbModule,
    TranslateModule,
    TagInputModule,
    ModalsModule,
    NoFetchDateComponent,
    NgxPaginationModule,
    NzIconModule,
    NzToolTipModule,
    TableComponent,
    MenuDropdownComponent,
    SwitchComponent,
    ButtonComponent,
    InputComponent,
    SelectComponent,
    DashboardWidgetComponent,
    ChartWidgetComponent,
    TimesheetListComponent,
    LanguageSwitcherModule,
    PasswordInputComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UsersModule {}
