import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { Timesheet } from 'app/pages/core/model/timesheet.model'
import { TimeSheetService } from 'app/pages/core/services/timesheet.service'
import { UserService } from 'app/pages/core/services/user.service'
import { ModalComponent, ModalConfig, ModalsModule } from 'app/_metronic/partials'
import { BehaviorSubject, concat, finalize, of, Subject, takeUntil } from 'rxjs'
import { User } from 'app/pages/core/model/flexc-user.model'
import { UserRoles } from 'app/pages/core/enums/userRole.enum'
import { AuthService } from 'app/modules/auth'
import { KeycloakService } from 'keycloak-angular'
import { KeycloakProfile } from 'keycloak-js'
import { IButtonAction } from 'app/standalone/menu-dropdown/menu-dropdown.component.model'
import { ApprovalStatus } from 'app/pages/core/enums/approvalStatus.enum'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { TimesheetInputService } from 'app/standalone/timesheet-input.service'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { environment } from 'environments/environment'
import { HttpParams } from '@angular/common/http'
import { ROLES } from './../../../pages/roles'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import * as dayjs from 'dayjs'
import { SharedService } from 'app/pages/projects/shared.service'
import {
  TableComponent,
  createHeaderWithFilter,
  useStringAsTranslationObject,
} from 'app/standalone/table/table.component'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { CommonModule, Location } from '@angular/common'
import { ProjectNamePipe } from './project-name.pipe'
import { ProgressBarComponent } from 'app/standalone/progress-bar/progress-bar.component'
import { ButtonComponent } from 'app/standalone/button/button.component'
import { SelectComponent } from 'app/standalone/select/select.component'
import { MultiSelectComponent } from 'app/standalone/multi-select/multi-select.component'
import { TimesheetInputComponent } from 'app/standalone/timesheet-input/timesheet-input.component'
import { MenuDropdownComponent } from 'app/standalone/menu-dropdown/menu-dropdown.component'
import { TruncatePipe } from 'app/standalone/table/truncate.pipe'

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ModalsModule,
    TranslateModule,
    ProjectNamePipe,
    ProgressBarComponent,
    ButtonComponent,
    RouterModule,
    SelectComponent,
    MultiSelectComponent,
    TableComponent,
    TimesheetInputComponent,
    MenuDropdownComponent,
  ],
})
export class TimesheetListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() timeSheet!: Timesheet
  @Input() isFromBookYourTime: boolean = false
  @Input() isViewTimesheetData: boolean = false
  @Input() projectUserBudget: number
  @Input() manager: boolean = false
  @Input() blocked: boolean
  @Input() searchTerm: string
  @Input() daysLeft: number
  @Input() daysTotal: number

  @Output() modalSaveEmitter: EventEmitter<boolean> = new EventEmitter()
  @ViewChild('modalSuccess') private modalComponent: ModalComponent
  @ViewChild('modalError') private modalComponentError: ModalComponent
  @ViewChild('modalDeleteSuperUser') private ModalDeleteSuperuserComponent: ModalComponent

  openModalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  modalBodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')
  listOfTimesheets!: Timesheet[]
  listOfTimesheetsOriginal: Timesheet[]
  listOfProjectsForFilter: any = new Array()
  selectedProjectsValue: any = []
  listOfCheckedTimesheets: Timesheet[] = new Array()
  userId: string
  yearsMonthsFilter: Date[] = []
  currentYearMont: any
  userData!: User
  UserRoles = UserRoles
  roleAdmin: boolean = false
  roleNormalUser: boolean = false
  someYearSelected: boolean = false
  allSelected: boolean = false
  isUpdate: boolean = false
  selectedDate!: Date
  selectedTimeSheet: Timesheet
  calculatedTime: number = 0
  selectedYearMonth: Date
  selectedSearchProject: any
  speficifTimesheet: boolean = false
  blockedUser: boolean = false
  currentUser?: User
  billed: boolean = false
  approved: boolean = false
  removeApprove: boolean = false
  deleteTImsheet: boolean = false
  project: Project = {} as Project
  projectId!: string
  counter: number = 0
  tableData: any = []
  idUser: number
  selectedTimesheets: any[]
  exportActions: IButtonAction[] = this.generateExportActions()
  statusActions: IButtonAction[] = this.generateStatusActions()
  destroyed = new Subject()

  downloadFilePath: string = environment.downloadFilePath

  tableHeadings = {
    date: useStringAsTranslationObject('DATE'),
    statusIcon: useStringAsTranslationObject('STATUS'),
    project: useStringAsTranslationObject('PROJECT'),
    description: useStringAsTranslationObject('DESCRIPTION'),
    files: useStringAsTranslationObject('FILE'),
    startTime: useStringAsTranslationObject('START_TIME', true),
    startBreak: useStringAsTranslationObject('START_BREAK', true),
    endBreak: useStringAsTranslationObject('END_BREAK', true),
    endTime: useStringAsTranslationObject('END_TIME', true),
    sumHours: useStringAsTranslationObject('SUM_HOURS', true),
  }

  sortableColumns = {
    date: 'date',
    project: 'text',
    description: 'length',
    startTime: 'text',
    startBreak: 'text',
    endBreak: 'text',
    endTime: 'text',
    sumHours: 'text',
  }

  columnWithCustomHeadings = ['PROJECT', 'DESCRIPTION', 'START_BREAK', 'END_BREAK']

  generateExportActions() {
    return [
      {
        title: 'EXPORT_PDF',
        onClick: () => this.exportTimesheetById(false),
        icon: 'assets/media/icons/duotune/general/export.svg',
        disabled: !this.listOfCheckedTimesheets.length,
      },
      {
        title: 'EXPORT_CSV',
        onClick: () => this.exportTimesheetById(true),
        icon: 'assets/media/icons/duotune/general/export.svg',
        disabled: !this.listOfCheckedTimesheets.length,
      },
      {
        title: 'EXPORT_EXCEL',
        onClick: () => this.exportExcel(),
        icon: 'assets/media/icons/duotune/general/export.svg',
        disabled: !this.listOfCheckedTimesheets.length,
      },
    ]
  }

  generateStatusActions() {
    return [
      {
        icon: 'assets/media/icons/duotune/general/approved.svg',
        title: 'MARK_AS_APPROVED',
        onClick: () => {
          this.setBodyTitleApprove(this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_APPROVED'))
          this.ModalDeleteSuperuserComponent.open()
        },
      },
      {
        icon: 'assets/media/icons/duotune/general/billed.svg',
        title: 'MARK_AS_BILLED',
        onClick: () => {
          this.setBodyTitleBilled(this.translate.instant('ARE_YOR_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_BILLED'))
          this.ModalDeleteSuperuserComponent.open()
        },
      },
      {
        icon: 'assets/media/icons/duotune/general/revoke_status.svg',
        title: 'REVOKE_STATUS',
        onClick: () => {
          this.setBodyTitleRemoveApprove(this.translate.instant('REVOKE_STATUS_MODAL'))
          this.ModalDeleteSuperuserComponent.open()
        },
      },
    ]
  }

  modalConfig: ModalConfig = {
    modalBodyTitle: this.modalBodyTitle,
    onClose: () => {
      return true
    },
    onDismiss: () => {
      return true
    },
  }

  setBodyTitleBilled(title: string) {
    this.billed = true
    this.deleteTImsheet = false
    this.modalBodyTitle.next(title)

    this.modalConfig1 = {
      modalBodyTitle: this.modalBodyTitle,
      dismissButtonLabel: this.translate.instant('NO'),
      closeButtonLabel: this.translate.instant('YES'),
      onClose: () => {
        if (this.billed) {
          this.verifyStatus(ApprovalStatus.BILLED)
        } else if (this.deleteTImsheet) {
          this.massTimesheetDelete()
        } else {
          this.verifyStatus(ApprovalStatus.APPROVED)
        }
        return true
      },
      onDismiss: () => {
        return true
      },
    }
  }

  setBodyTitleApprove(title: string) {
    this.billed = false
    this.deleteTImsheet = false
    this.modalBodyTitle.next(title)

    this.modalConfig1 = {
      modalBodyTitle: this.modalBodyTitle,
      dismissButtonLabel: this.translate.instant('NO'),
      closeButtonLabel: this.translate.instant('YES'),
      onClose: () => {
        if (this.billed) {
          this.verifyStatus(ApprovalStatus.BILLED)
        } else if (this.deleteTImsheet) {
          this.massTimesheetDelete()
        } else {
          this.verifyStatus(ApprovalStatus.APPROVED)
        }
        return true
      },
      onDismiss: () => {
        return true
      },
    }
  }

  setBodyTitleRemoveApprove(title: string) {
    this.modalBodyTitle.next(title)

    this.modalConfig1 = {
      modalBodyTitle: this.modalBodyTitle,
      dismissButtonLabel: this.translate.instant('NO'),
      closeButtonLabel: this.translate.instant('YES'),
      onClose: () => {
        this.removeApprovalStatus()
        return true
      },
      onDismiss: () => {
        return true
      },
    }
  }

  setBodyTitleDelete(title: string) {
    this.billed = false
    this.deleteTImsheet = true
    this.modalBodyTitle.next(title)

    this.modalConfig1 = {
      modalBodyTitle: this.modalBodyTitle,
      dismissButtonLabel: this.translate.instant('CANCEL'),
      closeButtonLabel: this.translate.instant('DELETE'),
      onClose: () => {
        if (this.billed) {
          this.verifyStatus(ApprovalStatus.BILLED)
        } else if (this.deleteTImsheet) {
          this.massTimesheetDelete()
        } else {
          this.verifyStatus(ApprovalStatus.APPROVED)
        }
        return true
      },
      onDismiss: () => {
        return true
      },
    }
  }

  modalConfig1: ModalConfig = {
    modalBodyTitle: this.modalBodyTitle,
    dismissButtonLabel: this.translate.instant('CANCEL'),
    closeButtonLabel: 'Yes',
    onClose: () => {
      if (this.billed) {
        this.verifyStatus(ApprovalStatus.BILLED)
      } else if (this.deleteTImsheet) {
        this.massTimesheetDelete()
      } else {
        this.verifyStatus(ApprovalStatus.APPROVED)
      }
      return true
    },
    onDismiss: () => {
      return true
    },
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private timeSheetService: TimeSheetService,
    private userService: UserService,
    public authService: AuthService,
    private keycloakService: KeycloakService,
    private translate: TranslateService,
    private timesheetSharedService: TimesheetInputService,
    private projectService: ProjectService,
    public sharedService: SharedService,
    private truncatePipe: TruncatePipe,
    private location: Location
  ) {}

  ngOnDestroy(): void {
    this.destroyed.next(true)
    this.destroyed.complete()
  }

  mapTimesheetForTable(timesheet: Timesheet[]) {
    return timesheet.map(timesheet => ({
      id: timesheet.id,
      date: {
        type: TABLE_TYPE.DATE_PARSED,
        value: new Date(timesheet.start!),
        classes: ['blue_text fw-bolder', 'text-muted'],
      },
      statusIcon: {
        type: TABLE_TYPE.SVG,
        url:
          timesheet.approvalStatus.name === 'BILLED'
            ? '../../../../assets/media/logos/billed.svg'
            : timesheet.approvalStatus.name === 'APPROVED'
            ? '../../../../assets/media/logos/approved.svg'
            : '',
        options: {
          height: '15px',
          width: '17px',
          borderRadius: '17px',
        },
      },
      project: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: timesheet.project.name,
        description: timesheet.project.description,
        limit: 60,
      },
      description: {
        type: TABLE_TYPE.ADVANCED_TRANSLATED_STRING,
        string: timesheet.descriptions
          .map(d =>
            this.truncatePipe
              .transform(d.text ?? '', [50, '...'], true)
              ?.concat(d.tasks.length === 0 ? ' ' : d.text?.length! > 50 ? ',<br>' : ', ')
              .concat(
                d.tasks
                  .map((t, i, arr) => {
                    return i <= 3
                      ? `<span style="color:#1BC5BD;font-size: 13px; font-style: normal; font-weight: 700;">${t.name}${
                          arr.length - 1 === i ? ' ' : ', '
                        }</span>`
                      : ''
                  })
                  .join(' ')
                  .concat(
                    d.tasks.length > 3
                      ? '<span style="color:#1BC5BD;font-size: 13px; font-style: normal; font-weight: 700;">...</span>'
                      : ''
                  )
              )
          )
          .join('</span>  '),
        class: 'text-muted min-width',
      },
      files: {
        type: TABLE_TYPE.CLICKABLE_SVG,
        url: timesheet.file ? 'assets/media/icons/duotune/general/fileIcon.svg' : '',
        onClick: () => this.openFileInNewTab(timesheet.file),
        class: `table-data_image ng-star-inserted min-width ${timesheet.file ? 'readonly-checkbox' : ''}`,
        options: {
          height: '30px',
          width: '30px',
        },
      },
      startTime: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: `${dayjs(timesheet.start).format('HH:mm')}`,
        class: 'fw-bolder min-width',
      },
      startBreak: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: timesheet.startPause ? `${dayjs(timesheet.startPause).format('HH:mm')}` : '&nbsp',
        class: timesheet.startPause ? 'text-muted' : 'min-width',
      },
      endBreak: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: timesheet.endPause ? `${dayjs(timesheet.endPause).format('HH:mm')}` : '&nbsp',
        class: timesheet.startPause ? 'text-muted' : 'min-width',
      },
      endTime: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: `${dayjs(timesheet.end).format('HH:mm')}`,
        class: 'fw-bolder min-width',
      },
      sumHours: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: timesheet.hourSum.toFixed(2),
        class: 'blue_text fw-bolder min-width',
      },
      billed: timesheet.approvalStatus.name === 'BILLED',
      approved: timesheet.approvalStatus.name === 'APPROVED',
    }))
  }

  redirectToSingleTimesheetPage(showModal: boolean) {
    void this.router.navigate([`/projects/project-overview/${showModal}`])
  }

  ngAfterViewInit(): void {
    this.userService.getLoggedInUser().subscribe((res: User) => {
      res.id &&
        this.projectService.isProjectManager(res.id!).subscribe((res: ApiResponse<boolean>) => {
          if (res.response) {
            this.manager = true
          }
        })
      this.roleAdmin = this.authService.isProjectAdmin
      if (res.id) {
        this.currentUser = res
        this.currentYearMont = new Date()
        this.route.params.subscribe(params => {
          if (!this.isFromBookYourTime && !this.isViewTimesheetData) {
            this.userId = params['id']
            this.projectId = params['projectId']
            this.getUserDataById()
          } else if (this.isViewTimesheetData) {
            this.userId = params['id']
            this.userService.getUserByID(this.userId).subscribe(res => {
              this.userData = res.response
              this.configureUserData(this.userData)
            })
          } else {
            this.userData = this.currentUser!
            this.userId = this.currentUser!.id!
          }
          this.getDataForMonthFilter()
        })
      }
    })
    this.route.queryParams.subscribe(params => {
      if (params?.showModal) {
        this.openModalSubject.next(true)
      }
    })
  }

  async openModal() {
    return await this.modalComponent.open()
  }

  async openModalError() {
    if (!this.deleteTImsheet && !this.billed) {
      this.modalBodyTitle.next('')
    } else if (this.billed) {
      this.setBodyTitleBilled(this.translate.instant('WE_CANNOT_BILL_FOR_THE_UNAPPROVED_TIMESHEET'))
    } else {
      this.setBodyTitleDelete(this.translate.instant('CANNOT_DELETE_BILLED_OR_APPROVED_TIMESHEET'))
    }
    return await this.modalComponentError.open()
  }

  ngOnInit(): void {
    this.speficifTimesheet = !this.router.url.includes('/timesheet/one-timesheet-overview')
    this.speficifTimesheet =
      !this.router.url.includes('update-edit-user') && !this.router.url.includes('/timesheet/one-timesheet-overview')

    this.blockedUser = this.router.url.includes('blocked=true')
    this.translate.onLangChange.subscribe((res: any) => {
      this.exportActions = this.generateExportActions()
      this.statusActions = this.generateStatusActions()
    })
  }

  timesheetExportDropdownItems = [
    { id: 1, name: '.pdf file', avatar: './assets/media/icons/duotune/general/pdf.svg' },
    { id: 2, name: '.csv file', avatar: './assets/media/icons/duotune/general/svg.svg' },
  ]

  getDataForId() {
    const paramMap = new HttpParams().set('userId', this.userId).append('currentMonthBool', true)

    if (this.project?.id) {
      paramMap.append('projectIds', this.project.id)
    }

    const timesheets$ =
      this.isFromBookYourTime || this.isViewTimesheetData
        ? this.timeSheetService.filterTimesheets(paramMap)
        : this.timeSheetService.filterTimesheets(paramMap.append('projectIds', this.project.id))

    timesheets$.pipe(takeUntil(this.destroyed)).subscribe((content: Timesheet[]) => {
      this.listOfTimesheets = content
      this.tableData = this.mapTimesheetForTable(this.listOfTimesheets)
      this.setSearchProjects()
      this.calculateTime()
      this.filterTimesheetsByRole()
    })
  }

  getDataForMonthFilter(event?: any) {
    if (event?.start) {
      this.currentYearMont = new Date(event.start)
      this.selectedYearMonth = this.currentYearMont
    }
    this.yearsMonthsFilter = []
    let newDate: Date
    this.route.queryParams.subscribe(params => {
      if (params) {
        newDate = new Date(Date.parse(params['selectedMonth'] + ' 1, ' + params['selectedYear']))
      }
      this.timeSheetService.getAllMonthsForFilterByUserId(this.userId).subscribe(content => {
        if (content) {
          content.forEach(iterator => {
            let date = new Date(iterator)
            this.yearsMonthsFilter.push(date)
            if (
              date.getMonth() === this.currentYearMont.getMonth() &&
              date.getFullYear() === this.currentYearMont.getFullYear()
            ) {
              this.someYearSelected = true
            }
          })

          this.selectedYearMonth =
            this.yearsMonthsFilter.find((val: Date) => val.toDateString() === newDate.toDateString()) ??
            this.yearsMonthsFilter[0]
          this.filterSelectedMonth(null, this.selectedYearMonth)
        }
      })
    })
  }

  getUserDataByKeycloakId() {
    this.keycloakService.loadUserProfile().then((val: KeycloakProfile) => {
      this.userService.getUserByKeycloakId(val.id!).subscribe(res => {
        this.userData = res.response
        this.userData.userRole.name = this.UserRoles[this.userData?.userRole?.name as keyof typeof UserRoles]
      })
    })
  }

  getUserDataById() {
    if (this.authService.currentUserRole === ROLES.PROJECT_ADMIN && !this.speficifTimesheet) {
      this.userService.getUserByID(this.userId).subscribe(res => {
        this.userData = res.response
        this.configureUserData(this.userData)
      })
    } else {
      this.projectService.getOneProject(this.projectId).subscribe((res: ApiResponse<Project>) => {
        this.project = res.response
        this.userService
          .getUsersOnProject(this.projectId, 0, 50, 'user.firstName')
          .subscribe((res: ApiResponse<ProjectMember[]>) => {
            if (res.content) {
              this.project.projectMembers = res.content
              const projectMember = this.project.projectMembers.find(element => element.user.id === this.userId)
              if (projectMember) {
                const { user } = projectMember
                let total = projectMember.allocation!
                this.daysLeft = total * ((100 - projectMember?.usedAllocationInPercentage!) / 100)
                this.projectUserBudget = projectMember?.usedAllocationInPercentage ?? 0
                this.userData = user
                this.configureUserData(this.userData)
              }
            }
          })
      })
    }
  }

  configureUserData(userData: User) {
    if (userData.userRole?.name) {
      userData.userRole.name = this.UserRoles[userData.userRole.name as keyof typeof UserRoles]
    }
    if (
      userData.imageUrl &&
      !userData.imageUrl.includes('ICON_USER.svg') &&
      !userData.imageUrl.includes(environment.imgUrl)
    ) {
      userData.imageUrl = `${environment.imgUrl}${userData.imageUrl}`
    }
  }

  modalCloseEvent(event: any) {
    this.router.navigate([], {
      queryParams: {
        showModal: undefined,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    })
  }

  modalSave(event: any) {
    if (event) {
      let currentDate = event.start ? new Date(event.start) : new Date()
      currentDate.setDate(1)
      if (!this.isViewTimesheetData) {
        this.getUserDataById()
        this.filterSelectedMonth(null, currentDate)
      }
      this.openModal()
      this.getDataForId()
      this.modalSaveEmitter.next(true)
    } else {
      this.openModalError()
    }
    this.isUpdate = false
    this.getDataForMonthFilter(event)
  }

  filterSelectedMonth(event?: any, currentDate?: Date) {
    if (event) {
      this.selectedYearMonth = event
    }
    if (event || currentDate) {
      this.selectedDate = event ? new Date(event) : currentDate!

      const paramMap: HttpParams = new HttpParams()
        .set('monthStart', this.selectedDate.toISOString().split('T')[0])
        .append('userId', this.userId)
      const timesheets$ =
        this.isFromBookYourTime || this.isViewTimesheetData
          ? this.timeSheetService.filterTimesheets(paramMap)
          : this.timeSheetService.filterTimesheets(paramMap.append('projectIds', this.projectId as string))
      timesheets$.pipe(takeUntil(this.destroyed)).subscribe((content: Timesheet[]) => {
        this.listOfTimesheets = content
        this.listOfTimesheetsOriginal = content
        this.tableData = this.mapTimesheetForTable(this.listOfTimesheetsOriginal)
        this.setSearchProjects()
        this.calculateTime()
        this.filterTimesheetsByRole()
      })
    } else {
      this.getDataForId()
    }
    this.selectedProjectsValue = []
  }

  handleChange(event: any) {
    this.selectedSearchProject = event instanceof Event ? this.selectedSearchProject : event
    this.listOfTimesheets = this.listOfTimesheetsOriginal.filter(row =>
      this.selectedSearchProject.some((project: { id: string }) => row.project.id === project.id)
    )
    this.counter = this.selectedSearchProject?.length! ? this.selectedSearchProject?.length : 0
    if (this.listOfTimesheets.length == 0) {
      this.listOfTimesheets = this.listOfTimesheetsOriginal
    }
    this.tableData = this.mapTimesheetForTable(this.listOfTimesheets)
    this.setSearchProjects()
  }

  checkAll(event?: any) {
    if (this.listOfTimesheets) {
      if (event?.currentTarget?.checked) {
        for (const iterator of this.listOfTimesheets) {
          if (iterator.approvalStatus.name !== 'APPROVED') {
            this.listOfCheckedTimesheets.push(iterator)
          }
        }
      } else {
        this.listOfCheckedTimesheets = []
      }
      this.allSelected = !this.allSelected
      this.updateActionsState()
    }
  }

  checkBoxChange(timeSheet: Timesheet, event: any) {
    if (event.currentTarget.checked) {
      this.listOfCheckedTimesheets.push(timeSheet)
    } else {
      this.listOfCheckedTimesheets.splice(this.listOfCheckedTimesheets.indexOf(timeSheet), 1)
    }
    this.updateActionsState()
  }

  openModalEditNew(event: any) {
    const timeSheetRow = this.listOfTimesheets.find(f => f.id === event.id)!
    if (timeSheetRow?.approvalStatus?.name !== 'APPROVED' && timeSheetRow?.approvalStatus?.name !== 'BILLED') {
      this.selectedTimeSheet = timeSheetRow
      this.isUpdate = true
      this.router.navigate([this.router.url], {
        queryParams: { showModal: 'true' },
      })
    }
  }

  calculateTime() {
    this.calculatedTime = 0
    if (this.listOfTimesheets) {
      for (const iterator of this.listOfTimesheets) {
        this.calculatedTime += iterator.hourSum
      }
    }
  }

  setSearchProjects() {
    if (this.speficifTimesheet) {
      //disabled because of not loading data after return to project overveiw
      // this.listOfTimesheets = this.listOfTimesheets.filter(timesheet => timesheet.project.id === this.project.id)
    }
    if (this.listOfTimesheets) {
      let returnList = new Array()
      for (const iterator of this.listOfTimesheets) {
        if (!returnList.some(element => element.name === iterator.project.name)) {
          returnList.push({ name: iterator.project.name, id: iterator.project.id })
        }
      }
      this.listOfProjectsForFilter = returnList
      if (!this.speficifTimesheet) {
        this.tableHeadings.project = createHeaderWithFilter(
          'PROJECT',
          this.listOfProjectsForFilter.map((p: any) => ({
            text: p.name,
            value: p.id,
            byDefault: this.selectedSearchProject?.map((p: any) => p.id).includes(p.id),
          })),
          (event: any) => {
            this.handleChange(event.map((e: any) => ({ id: e })))
          }
        )
      }
    }
  }

  removeApprovalStatus() {
    const approvedTimesheetsIds = this.listOfCheckedTimesheets
      .filter((timeSheet: Timesheet) => timeSheet.approvalStatus.id === ApprovalStatus.APPROVED)
      .map((timeSheet: Timesheet) => {
        return {
          id: timeSheet.id,
        }
      })

    const billedTimesheetsIds = this.listOfCheckedTimesheets
      .filter((timeSheet: Timesheet) => timeSheet.approvalStatus.id === ApprovalStatus.BILLED)
      .map((timeSheet: Timesheet) => {
        return {
          id: timeSheet.id,
        }
      })

    const updateApprovedStatus$ = approvedTimesheetsIds.length
      ? this.timeSheetService.updateStatusForTimesheetEntry(approvedTimesheetsIds, { id: ApprovalStatus.PENDING })
      : of()
    const updateBilledStatus$ = billedTimesheetsIds.length
      ? this.timeSheetService.updateStatusForTimesheetEntry(billedTimesheetsIds, { id: ApprovalStatus.APPROVED })
      : of()
    let hasError = false

    concat(updateApprovedStatus$, updateBilledStatus$)
      .pipe(
        finalize(() => {
          if (!hasError) {
            this.modalBodyTitle.next('')
            this.openModal()
            this.listOfCheckedTimesheets = []
            this.allSelected = false
            let currentDate = this.selectedYearMonth ? this.selectedYearMonth : new Date()
            currentDate.setDate(1)
            this.filterSelectedMonth(null, currentDate)
          }
        })
      )
      .subscribe((error: any) => {
        this.modalBodyTitle.next(error.error.message)
        this.openModalError()
        hasError = true
      })
  }

  verifyStatus(timesheetStatus: any) {
    this.timeSheetService
      .updateStatusForTimesheetEntry(
        this.listOfCheckedTimesheets.map((timeSheet: Timesheet) => {
          return {
            id: timeSheet.id,
          }
        }),
        { id: timesheetStatus }
      )
      .subscribe(
        (res: any) => {
          if (res.httpStatus === 'OK') {
            this.modalBodyTitle.next('')
            this.openModal()
            this.listOfCheckedTimesheets = []
            this.allSelected = false
            let currentDate = this.selectedYearMonth ? this.selectedYearMonth : new Date()
            currentDate.setDate(1)
            this.filterSelectedMonth(null, currentDate)
          } else {
            this.openModalError()
          }
        },
        error => {
          this.modalBodyTitle.next(error.error.message)
          this.openModalError()
        }
      )
  }

  filterTimesheetsByRole() {
    if (!this.userData?.userRole) return

    if (this.currentUser?.userRole.id === ROLES.PROJECT_USER) {
      this.listOfTimesheets.forEach(t => {
        if (t.approvalStatus.id === ApprovalStatus.BILLED) {
          t.approvalStatus.id = ApprovalStatus.APPROVED
          t.approvalStatus.name = 'APPROVED'
        }
      })
    }
  }

  updateActionsState() {
    this.exportActions.forEach(action => {
      action.disabled = !this.listOfCheckedTimesheets.length
    })
  }

  exportTimesheetById(exportAsCsv?: boolean) {
    if (!this.userData) return
    this.timeSheetService.exportTimesheetById(
      this.listOfCheckedTimesheets.map(timesheet => ({ id: timesheet.id })),
      this.userData,
      exportAsCsv
    )
  }

  exportExcel() {
    if (!this.userData) return
    this.timeSheetService.exportExcel(
      this.listOfCheckedTimesheets.map(timesheet => ({ id: timesheet.id })),
      this.userData.firstName,
      this.userData.lastName
    )
  }

  onDeleteTimesheet() {
    this.deleteTImsheet = true
    this.setBodyTitleDelete(this.translate.instant('DO_YOU_REALLY_WANT_TO_DELETE_TIMESHEET'))
    this.ModalDeleteSuperuserComponent.open()
  }

  openModalSuccess() {
    this.setBodyTitleDelete('')
    this.modalComponent.open()
  }
  resetButtonColor($event: MouseEvent): void {
    const button = $event.currentTarget as HTMLButtonElement
    button.blur()
  }

  selectionChange(event: any[]) {
    this.listOfCheckedTimesheets = this.listOfTimesheets.filter(f => event.map(e => e.id).includes(f.id))
    this.updateActionsState()
  }

  massTimesheetDelete() {
    const timesheetsToDelete: string[] = []
    this.listOfCheckedTimesheets.forEach(element => {
      timesheetsToDelete.push(element.id)
    })

    this.timeSheetService.deleteMassTimesheet(timesheetsToDelete).subscribe(
      response => {
        this.listOfCheckedTimesheets = []
        this.timesheetSharedService.emitInputTimesheet()
        this.getDataForId()
        this.getUserDataById()
        this.openModalSuccess()
        this.currentYearMont = new Date()
        this.getDataForMonthFilter()
      },
      error => {
        console.error('Error deleting timesheets', error)
      }
    )
  }

  openFileInNewTab(file?: any) {
    if (file) {
      window.open(this.downloadFilePath + file, '_blank')
    }
  }

  back() {
    this.location.back()
  }
}
