import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { TranslationService } from './modules/i18n'
// language list
import { locale as enLang } from './modules/i18n/vocabs/en'
import { locale as chLang } from './modules/i18n/vocabs/ch'
import { locale as esLang } from './modules/i18n/vocabs/es'
import { locale as jpLang } from './modules/i18n/vocabs/jp'
import { locale as deLang } from './modules/i18n/vocabs/de'
import { locale as frLang } from './modules/i18n/vocabs/fr'
import { locale as rsLang } from './modules/i18n/vocabs/rs'
import { locale as ruLang } from './modules/i18n/vocabs/ru'
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service'
import { KeycloakService } from 'keycloak-angular'
import { SseService } from './pages/core/services/sse.service'
import { NotificationService } from './pages/core/services/notification.service'
import { environment } from 'environments/environment'
import { CompanyService } from './pages/core/services/company.service'
import { SharedService } from './pages/projects/shared.service'

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private keycloakService: KeycloakService,
    private sseService: SseService,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private sharedService: SharedService
  ) {
    // register translations
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang, rsLang, ruLang)
  }

  ngOnInit() {
    this.modeService.init()
    this.keycloakService.isLoggedIn().then(res => {
      if (res) {
        environment.subscribeUrl
        this.sseService.getServerSentEvent(environment.subscribeUrl + '/subscribe').subscribe()
        this.notificationService.initNotifications()
        this.companyService.getCompanySettings().subscribe(res => {
          this.sharedService.workingHours = res.response.workingHours
        })
      }
    })
  }
}
