<div class="row">
  <div class="card mb-5 mb-xl-20">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
      *ngIf="this.projectOverview"
    >
      <div *ngIf="this.projectOverview" class="card-title-overview">
        <div class="card-title m-0 fw-bolder m-0">{{ 'PROJECTS.CREATE_PROJECTS.PROJECT_OVERWIEV' | translate }}</div>
        <button class="btn btn-primary mt-3 mb-3" [routerLink]="['/projects/edit-project/', this.id]">
          {{ 'PROJECTS.CREATE_PROJECTS.EDIT' | translate }}
        </button>
      </div>
      <div *ngIf="this.projectUpdate" class="card-title m-0 fw-bolder m-0">
        {{ 'PROJECTS.CREATE_PROJECTS.PROJECT_UPDATE' | translate }}
      </div>
    </div>
    <div class="collapse show">
      <div class="card-body border-top p-9 w-900">
        <app-button
          [title]="'BACK' | translate"
          buttonType="link"
          size="large"
          prefixIcon="assets/media/icons/duotune/arrows/arr074.svg"
          (handleClick)="back($event)"
        ></app-button>
        <div class="d-flex flex-wrap align-items-center mb-10">
          <div id="kt_signin_password_edit" class="flex-row-fluid false">
            <div class="stepper stepper-links d-flex flex-column pt-15" id="kt_create_account_stepper">
              <div class="stepper-nav mb-5">
                <div
                  class="stepper-item"
                  [ngClass]="{
                    current: currentStep$.value === 1
                  }"
                >
                  <h3 class="stepper-title">{{ 'PROJECT_DETAILS' | translate }}</h3>
                </div>
                <div
                  class="stepper-item"
                  [ngClass]="{
                    current: currentStep$.value === 2,
                    completed: currentStep$.value > 2
                  }"
                >
                  <h3 class="stepper-title">{{ 'TIME_AND_BUDGET' | translate }}</h3>
                </div>
                <div
                  class="stepper-item"
                  [ngClass]="{
                    current: currentStep$.value === 3,
                    completed: currentStep$.value > 3
                  }"
                >
                  <h3 class="stepper-title">{{ 'KNOWLEDGE_AND_THE_TEAM' | translate }}</h3>
                </div>
                <div
                  class="stepper-item"
                  [ngClass]="{
                    current: currentStep$.value === 4,
                    completed: currentStep$.value > 4
                  }"
                >
                  <h3 class="stepper-title">{{ 'ALLOCATE_AND_FINISH' | translate }}</h3>
                </div>
              </div>
              <div class="current">
                <ng-container *ngIf="currentStep$.value === 1">
                  <app-project-info
                    class="w-100"
                    [projectInfoForm]="projectInfoForm"
                    [projectOverview]="projectOverview"
                    [submittedTrue]="submittedStepper"
                    [projectNameTaken]="projectNameTaken"
                    [projectNumberTaken]="projectNumberTaken"
                    [projectOfferTaken]="projectOfferTaken"
                  ></app-project-info>
                </ng-container>
                <ng-container *ngIf="currentStep$.value === 2">
                  <app-project-time
                    class="w-100"
                    [projectTimeForm]="projectTimeForm"
                    *ngIf="projectTimeForm"
                    [submittedTrue]="submittedStepper"
                  ></app-project-time>
                </ng-container>
                <ng-container *ngIf="currentStep$.value === 3">
                  <app-project-team
                    class="w-100"
                    [projectOverview]="projectOverview"
                    [submitted]="submitted"
                  ></app-project-team>
                </ng-container>
                <ng-container *ngIf="currentStep$.value === 4">
                  <app-project-workers
                    class="w-100"
                    [projectWorkersForm]="projectWorkersForm"
                    [projectOverview]="projectOverview"
                    [projectUpdate]="projectUpdate"
                    [submitted]="submitted"
                    [totalWorkingDays]="totalWorkingDays"
                  ></app-project-workers>
                </ng-container>
                <ng-container *ngIf="currentStep$.value">
                  <div
                    [ngClass]="{
                      marginLeft: currentStep$.value === 1,
                      buttonWraper: currentStep$.value === 1 || currentStep$.value === 2,
                      buttonsWidth: currentStep$.value === 3 || currentStep$.value === 4
                    }"
                  >
                    <button
                      *ngIf="currentStep$.value !== 1"
                      type=" button"
                      class="btn btn-back-project mt-3"
                      [ngClass]="{
                        margin_third_step: currentStep$.value === 3 || currentStep$.value === 4,
                        margin_button_second_step: currentStep$.value === 2
                      }"
                      (click)="prevStep()"
                    >
                      {{ 'BACK' | translate }}
                    </button>
                    <button
                      *ngIf="currentStep$.value !== 4"
                      type="button"
                      class="btn btn-primary-project mt-3"
                      style="border: 1px solid #1bc5bd"
                      (click)="nextStep()"
                    >
                      <span class="indicator-label">
                        <ng-container>
                          {{ 'SAVE_AND_CONTINUE' | translate }}
                        </ng-container>
                      </span>
                    </button>
                    <div class="lastStapWraper mt-3" *ngIf="currentStep$.value === 4">
                      <button class="btn btn-primary-save me-5" (click)="nextStep()" style="min-width: 75px">
                        {{ 'SAVE_DRAFT' | translate }}
                      </button>
                      <button
                        class="btn btn-primary-project"
                        (click)="nextStep(true)"
                        style="border: 1px solid #1bc5bd"
                      >
                        {{ 'START_PROJECT' | translate }}
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-success-modal #modalSuccess [modalConfig]="modalConfig">
  <div class="modal">{{ 'SAVED_SUCCESSFULLY' | translate }}</div>
</app-success-modal>
<app-error-modal #modalError [modalConfig]="modalConfig">
  <div class="modal">{{ 'ERROR_WHILE_SAVING_DATA' | translate }}</div>
</app-error-modal>
