import { Company } from './company.model'
import { PortofolioDTO } from './portofolio.model'
import { UserRoleModel } from './user-role.model'

export class User {
  public username: string
  public firstName: string
  public lastName: string
  public email: string
  public noMail: boolean
  public phoneNumber?: string
  public archived: boolean
  public profileImage?: string
  public userRole: UserRoleModel
  public password?: string
  public id?: string
  public createdAt?: string
  public modified?: string
  public portfolio?: PortofolioDTO
  public companyDTO?: Company
  public isChecked?: boolean
  public imageUrl?: string
  public keycloakId?: string
  public active?: boolean
  public lastLogin?: string | Date
  public knowledgeFlag?: boolean
  public languageFlag?: boolean
  public defaultLanguage?: string
}
