export interface ICountry {
  countryName: string
  ISO2: string
  ISO3: string
  topLevelDomain: string
  FIPS: string
  ISONumeric: number | string
  GeoNameID?: number
  E164: number
  phoneCode: number | string
  Continent: string
  Capital?: string
  timeZoneInCapital: string
  Currency?: string
  languageCodes?: string
  Languages?: string
  areaKM2: number
  internetHosts?: number
  internetUsers?: number | string
  phonesMobile?: number | string
  phonesLandline?: number | string
  GDP?: number | string
  englishShortName?: string
  frenchShortName?: string
  numeric?: number
  japaneseShortName?: string
}

export interface ICountryList {
  [key: string]: ICountry
}

export const COUNTRY_LIST: ICountryList = {
  AF: {
    countryName: 'Afghanistan',
    ISO2: 'AF',
    ISO3: 'AFG',
    topLevelDomain: 'af',
    FIPS: 'AF',
    ISONumeric: '004',
    GeoNameID: 1149361,
    E164: 93,
    phoneCode: 93,
    Continent: 'Asia',
    Capital: 'Kabul',
    timeZoneInCapital: 'Asia/Kabul',
    Currency: 'Afghani',
    languageCodes: 'fa-AF,ps,uz-AF,tk',
    Languages:
      'Afghan Persian or Dari (official) 50%, Pashto (official) 35%, Turkic languages (primarily Uzbek and Turkmen) 11%, 30 minor languages (primarily Balochi and Pashai) 4%, much bilingualism, but Dari functions as the lingua franca',
    areaKM2: 647500,
    internetHosts: 223,
    internetUsers: 1000000,
    phonesMobile: 18000000,
    phonesLandline: 13500,
    GDP: 20650000000,
    englishShortName: 'Afghanistan',
    frenchShortName: "Afghanistan (l')",
    numeric: 4,
    japaneseShortName: 'アフガニスタン',
  },
  AL: {
    countryName: 'Albania',
    ISO2: 'AL',
    ISO3: 'ALB',
    topLevelDomain: 'al',
    FIPS: 'AL',
    ISONumeric: '008',
    GeoNameID: 783754,
    E164: 355,
    phoneCode: 355,
    Continent: 'Europe',
    Capital: 'Tirana',
    timeZoneInCapital: 'Europe/Tirane',
    Currency: 'Lek',
    languageCodes: 'sq,el',
    Languages:
      'Albanian 98.8% (official - derived from Tosk dialect), Greek 0.5%, other 0.6% (including Macedonian, Roma, Vlach, Turkish, Italian, and Serbo-Croatian), unspecified 0.1% (2011 est.)',
    areaKM2: 28748,
    internetHosts: 15528,
    internetUsers: 1300000,
    phonesMobile: 3500000,
    phonesLandline: 312000,
    GDP: 12800000000,
    englishShortName: 'Albania',
    frenchShortName: "Albanie (l')",
    numeric: 8,
    japaneseShortName: 'アルバニア',
  },
  DZ: {
    countryName: 'Algeria',
    ISO2: 'DZ',
    ISO3: 'DZA',
    topLevelDomain: 'dz',
    FIPS: 'AG',
    ISONumeric: '012',
    GeoNameID: 2589581,
    E164: 213,
    phoneCode: 213,
    Continent: 'Africa',
    Capital: 'Algiers',
    timeZoneInCapital: 'Africa/Algiers',
    Currency: 'Dinar',
    languageCodes: 'ar-DZ',
    Languages:
      'Arabic (official), French (lingua franca), Berber dialects: Kabylie Berber (Tamazight), Chaouia Berber (Tachawit), Mzab Berber, Tuareg Berber (Tamahaq)',
    areaKM2: 2381740,
    internetHosts: 676,
    internetUsers: 4700000,
    phonesMobile: 37692000,
    phonesLandline: 3200000,
    GDP: 215700000000,
    englishShortName: 'Algeria',
    frenchShortName: "Algérie (l')",
    numeric: 12,
    japaneseShortName: 'アルジェリア',
  },
  AS: {
    countryName: 'American Samoa',
    ISO2: 'AS',
    ISO3: 'ASM',
    topLevelDomain: 'as',
    FIPS: 'AQ',
    ISONumeric: '016',
    GeoNameID: 5880801,
    E164: 1,
    phoneCode: '1-684',
    Continent: 'Oceania',
    Capital: 'Pago Pago',
    timeZoneInCapital: 'Pacific/Pago_Pago',
    Currency: 'Dollar',
    languageCodes: 'en-AS,sm,to',
    Languages:
      'Samoan 90.6% (closely related to Hawaiian and other Polynesian languages), English 2.9%, Tongan 2.4%, other Pacific islander 2.1%, other 2%',
    areaKM2: 199,
    internetHosts: 2387,
    phonesLandline: 10000,
    GDP: 462200000,
    englishShortName: 'American Samoa',
    frenchShortName: 'Samoa américaines (les)',
    numeric: 16,
    japaneseShortName: 'アメリカ領サモア',
  },
  AD: {
    countryName: 'Andorra',
    ISO2: 'AD',
    ISO3: 'AND',
    topLevelDomain: 'ad',
    FIPS: 'AN',
    ISONumeric: '020',
    GeoNameID: 3041565,
    E164: 376,
    phoneCode: 376,
    Continent: 'Europe',
    Capital: 'Andorra la Vella',
    timeZoneInCapital: 'Europe/Andorra',
    Currency: 'Euro',
    languageCodes: 'ca',
    Languages: 'Catalan (official), French, Castilian, Portuguese',
    areaKM2: 468,
    internetHosts: 28383,
    internetUsers: 67100,
    phonesMobile: 65000,
    phonesLandline: 39000,
    GDP: 4800000000,
    englishShortName: 'Andorra',
    frenchShortName: "Andorre (l')",
    numeric: 20,
    japaneseShortName: 'アンドラ',
  },
  AO: {
    countryName: 'Angola',
    ISO2: 'AO',
    ISO3: 'AGO',
    topLevelDomain: 'ao',
    FIPS: 'AO',
    ISONumeric: '024',
    GeoNameID: 3351879,
    E164: 244,
    phoneCode: 244,
    Continent: 'Africa',
    Capital: 'Luanda',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Kwanza',
    languageCodes: 'pt-AO',
    Languages: 'Portuguese (official), Bantu and other African languages',
    areaKM2: 1246700,
    internetHosts: 20703,
    internetUsers: 606700,
    phonesMobile: 9800000,
    phonesLandline: 303000,
    GDP: 124000000000,
    englishShortName: 'Angola',
    frenchShortName: "Angola (l')",
    numeric: 24,
    japaneseShortName: 'アンゴラ',
  },
  AI: {
    countryName: 'Anguilla',
    ISO2: 'AI',
    ISO3: 'AIA',
    topLevelDomain: 'ai',
    FIPS: 'AV',
    ISONumeric: 660,
    GeoNameID: 3573511,
    E164: 1,
    phoneCode: '1-264',
    Continent: 'North America',
    Capital: 'The Valley',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-AI',
    Languages: 'English (official)',
    areaKM2: 102,
    internetHosts: 269,
    internetUsers: 3700,
    phonesMobile: 26000,
    phonesLandline: 6000,
    GDP: 175400000,
    englishShortName: 'Anguilla',
    frenchShortName: 'Anguilla',
    numeric: 660,
    japaneseShortName: 'アンギラ',
  },
  AQ: {
    countryName: 'Antarctica',
    ISO2: 'AQ',
    ISO3: 'ATA',
    topLevelDomain: 'aq',
    FIPS: 'AY',
    ISONumeric: '010',
    GeoNameID: 6697173,
    E164: 672,
    phoneCode: 672,
    Continent: 'Antarctica',
    timeZoneInCapital: 'Antarctica/Troll',
    areaKM2: 14000000,
    internetHosts: 7764,
    englishShortName: 'Antarctica',
    frenchShortName: "Antarctique (l')",
    numeric: 10,
    japaneseShortName: '南極大陸',
  },
  AG: {
    countryName: 'Antigua and Barbuda',
    ISO2: 'AG',
    ISO3: 'ATG',
    topLevelDomain: 'ag',
    FIPS: 'AC',
    ISONumeric: '028',
    GeoNameID: 3576396,
    E164: 1,
    phoneCode: '1-268',
    Continent: 'North America',
    Capital: "St. John's",
    timeZoneInCapital: 'America/Antigua',
    Currency: 'Dollar',
    languageCodes: 'en-AG',
    Languages: 'English (official), local dialects',
    areaKM2: 443,
    internetHosts: 11532,
    internetUsers: 65000,
    phonesMobile: 179800,
    phonesLandline: 35000,
    GDP: 1220000000,
    englishShortName: 'Antigua and Barbuda',
    frenchShortName: 'Antigua-et-Barbuda',
    numeric: 28,
    japaneseShortName: 'アンティグア・バーブーダ',
  },
  AR: {
    countryName: 'Argentina',
    ISO2: 'AR',
    ISO3: 'ARG',
    topLevelDomain: 'ar',
    FIPS: 'AR',
    ISONumeric: '032',
    GeoNameID: 3865483,
    E164: 54,
    phoneCode: 54,
    Continent: 'South America',
    Capital: 'Buenos Aires',
    timeZoneInCapital: 'America/Argentina/Buenos_Aires',
    Currency: 'Peso',
    languageCodes: 'es-AR,en,it,de,fr,gn',
    Languages: 'Spanish (official), Italian, English, German, French, indigenous (Mapudungun, Quechua)',
    areaKM2: 2766890,
    internetHosts: 11232000,
    internetUsers: 13694000,
    phonesMobile: 58600000,
    phonesLandline: 1,
    GDP: 484600000000,
    englishShortName: 'Argentina',
    frenchShortName: "Argentine (l')",
    numeric: 32,
    japaneseShortName: 'アルゼンチン',
  },
  AM: {
    countryName: 'Armenia',
    ISO2: 'AM',
    ISO3: 'ARM',
    topLevelDomain: 'am',
    FIPS: 'AM',
    ISONumeric: '051',
    GeoNameID: 174982,
    E164: 374,
    phoneCode: 374,
    Continent: 'Asia',
    Capital: 'Yerevan',
    timeZoneInCapital: 'Asia/Yerevan',
    Currency: 'Dram',
    languageCodes: 'hy',
    Languages: 'Armenian (official) 97.9%, Kurdish (spoken by Yezidi minority) 1%, other 1% (2011 est.)',
    areaKM2: 29800,
    internetHosts: 194142,
    internetUsers: 208200,
    phonesMobile: 3223000,
    phonesLandline: 584000,
    GDP: 10440000000,
    englishShortName: 'Armenia',
    frenchShortName: "Arménie (l')",
    numeric: 51,
    japaneseShortName: 'アルメニア',
  },
  AW: {
    countryName: 'Aruba',
    ISO2: 'AW',
    ISO3: 'ABW',
    topLevelDomain: 'aw',
    FIPS: 'AA',
    ISONumeric: 533,
    GeoNameID: 3577279,
    E164: 297,
    phoneCode: 297,
    Continent: 'North America',
    Capital: 'Oranjestad',
    timeZoneInCapital: 'America/Curacao',
    Currency: 'Guilder',
    languageCodes: 'nl-AW,es,en',
    Languages:
      'Papiamento (a Spanish-Portuguese-Dutch-English dialect) 69.4%, Spanish 13.7%, English (widely spoken) 7.1%, Dutch (official) 6.1%, Chinese 1.5%, other 1.7%, unspecified 0.4% (2010 est.)',
    areaKM2: 193,
    internetHosts: 40560,
    internetUsers: 24000,
    phonesMobile: 135000,
    phonesLandline: 43000,
    GDP: 2516000000,
    englishShortName: 'Aruba',
    frenchShortName: 'Aruba',
    numeric: 533,
    japaneseShortName: 'アルバ',
  },
  AU: {
    countryName: 'Australia',
    ISO2: 'AU',
    ISO3: 'AUS',
    topLevelDomain: 'au',
    FIPS: 'AS',
    ISONumeric: '036',
    GeoNameID: 2077456,
    E164: 61,
    phoneCode: 61,
    Continent: 'Oceania',
    Capital: 'Canberra',
    timeZoneInCapital: 'Australia/Sydney',
    Currency: 'Dollar',
    languageCodes: 'en-AU',
    Languages:
      'English 76.8%, Mandarin 1.6%, Italian 1.4%, Arabic 1.3%, Greek 1.2%, Cantonese 1.2%, Vietnamese 1.1%, other 10.4%, unspecified 5% (2011 est.)',
    areaKM2: 7686850,
    internetHosts: 17081000,
    internetUsers: 15810000,
    phonesMobile: 24400000,
    phonesLandline: 10470000,
    GDP: 1488000000000,
    englishShortName: 'Australia',
    frenchShortName: "Australie (l')",
    numeric: 36,
    japaneseShortName: 'オーストラリア',
  },
  AT: {
    countryName: 'Austria',
    ISO2: 'AT',
    ISO3: 'AUT',
    topLevelDomain: 'at',
    FIPS: 'AU',
    ISONumeric: '040',
    GeoNameID: 2782113,
    E164: 43,
    phoneCode: 43,
    Continent: 'Europe',
    Capital: 'Vienna',
    timeZoneInCapital: 'Europe/Vienna',
    Currency: 'Euro',
    languageCodes: 'de-AT,hr,hu,sl',
    Languages:
      'German (official nationwide) 88.6%, Turkish 2.3%, Serbian 2.2%, Croatian (official in Burgenland) 1.6%, other (includes Slovene, official in Carinthia, and Hungarian, official in Burgenland) 5.3% (2001 census)',
    areaKM2: 83858,
    internetHosts: 3512000,
    internetUsers: 6143000,
    phonesMobile: 13590000,
    phonesLandline: 3342000,
    GDP: 417900000000,
    englishShortName: 'Austria',
    frenchShortName: "Autriche (l')",
    numeric: 40,
    japaneseShortName: 'オーストリア',
  },
  AZ: {
    countryName: 'Azerbaijan',
    ISO2: 'AZ',
    ISO3: 'AZE',
    topLevelDomain: 'az',
    FIPS: 'AJ',
    ISONumeric: '031',
    GeoNameID: 587116,
    E164: 994,
    phoneCode: 994,
    Continent: 'Asia',
    Capital: 'Baku',
    timeZoneInCapital: 'Asia/Baku',
    Currency: 'Manat',
    languageCodes: 'az,ru,hy',
    Languages: 'Azerbaijani (Azeri) (official) 92.5%, Russian 1.4%, Armenian 1.4%, other 4.7% (2009 est.)',
    areaKM2: 86600,
    internetHosts: 46856,
    internetUsers: 2420000,
    phonesMobile: 10125000,
    phonesLandline: 1734000,
    GDP: 76010000000,
    englishShortName: 'Azerbaijan',
    frenchShortName: "Azerbaïdjan (l')",
    numeric: 31,
    japaneseShortName: 'アゼルバイジャン',
  },
  BS: {
    countryName: 'Bahamas',
    ISO2: 'BS',
    ISO3: 'BHS',
    topLevelDomain: 'bs',
    FIPS: 'BF',
    ISONumeric: '044',
    GeoNameID: 3572887,
    E164: 1,
    phoneCode: '1-242',
    Continent: 'North America',
    Capital: 'Nassau',
    timeZoneInCapital: 'America/Nassau',
    Currency: 'Dollar',
    languageCodes: 'en-BS',
    Languages: 'English (official), Creole (among Haitian immigrants)',
    areaKM2: 13940,
    internetHosts: 20661,
    internetUsers: 115800,
    phonesMobile: 254000,
    phonesLandline: 137000,
    GDP: 8373000000,
    englishShortName: 'Bahamas (the)',
    frenchShortName: 'Bahamas (les)',
    numeric: 44,
    japaneseShortName: 'バハマ',
  },
  BH: {
    countryName: 'Bahrain',
    ISO2: 'BH',
    ISO3: 'BHR',
    topLevelDomain: 'bh',
    FIPS: 'BA',
    ISONumeric: '048',
    GeoNameID: 290291,
    E164: 973,
    phoneCode: 973,
    Continent: 'Asia',
    Capital: 'Manama',
    timeZoneInCapital: 'Asia/Bahrain',
    Currency: 'Dinar',
    languageCodes: 'ar-BH,en,fa,ur',
    Languages: 'Arabic (official), English, Farsi, Urdu',
    areaKM2: 665,
    internetHosts: 47727,
    internetUsers: 419500,
    phonesMobile: 2125000,
    phonesLandline: 290000,
    GDP: 28360000000,
    englishShortName: 'Bahrain',
    frenchShortName: 'Bahreïn',
    numeric: 48,
    japaneseShortName: 'バーレーン',
  },
  BD: {
    countryName: 'Bangladesh',
    ISO2: 'BD',
    ISO3: 'BGD',
    topLevelDomain: 'bd',
    FIPS: 'BG',
    ISONumeric: '050',
    GeoNameID: 1210997,
    E164: 880,
    phoneCode: 880,
    Continent: 'Asia',
    Capital: 'Dhaka',
    timeZoneInCapital: 'Asia/Dhaka',
    Currency: 'Taka',
    languageCodes: 'bn-BD,en',
    Languages: 'Bangla (official, also known as Bengali), English',
    areaKM2: 144000,
    internetHosts: 71164,
    internetUsers: 617300,
    phonesMobile: 97180000,
    phonesLandline: 962000,
    GDP: 140200000000,
    englishShortName: 'Bangladesh',
    frenchShortName: 'Bangladesh (le)',
    numeric: 50,
    japaneseShortName: 'バングラデシュ',
  },
  BB: {
    countryName: 'Barbados',
    ISO2: 'BB',
    ISO3: 'BRB',
    topLevelDomain: 'bb',
    FIPS: 'BB',
    ISONumeric: '052',
    GeoNameID: 3374084,
    E164: 1,
    phoneCode: '1-246',
    Continent: 'North America',
    Capital: 'Bridgetown',
    timeZoneInCapital: 'America/Barbados',
    Currency: 'Dollar',
    languageCodes: 'en-BB',
    Languages: 'English (official), Bajan (English-based creole language, widely spoken in informal settings)',
    areaKM2: 431,
    internetHosts: 1524,
    internetUsers: 188000,
    phonesMobile: 347000,
    phonesLandline: 144000,
    GDP: 4262000000,
    englishShortName: 'Barbados',
    frenchShortName: 'Barbade (la)',
    numeric: 52,
    japaneseShortName: 'バルバドス',
  },
  BY: {
    countryName: 'Belarus',
    ISO2: 'BY',
    ISO3: 'BLR',
    topLevelDomain: 'by',
    FIPS: 'BO',
    ISONumeric: 112,
    GeoNameID: 630336,
    E164: 375,
    phoneCode: 375,
    Continent: 'Europe',
    Capital: 'Minsk',
    timeZoneInCapital: 'Europe/Minsk',
    Currency: 'Ruble',
    languageCodes: 'be,ru',
    Languages:
      'Belarusian (official) 23.4%, Russian (official) 70.2%, other 3.1% (includes small Polish- and Ukrainian-speaking minorities), unspecified 3.3% (2009 est.)',
    areaKM2: 207600,
    internetHosts: 295217,
    internetUsers: 2643000,
    phonesMobile: 10675000,
    phonesLandline: 4407000,
    GDP: 69240000000,
    englishShortName: 'Belarus',
    frenchShortName: 'Bélarus (le)',
    numeric: 112,
    japaneseShortName: 'ベラルーシ',
  },
  BE: {
    countryName: 'Belgium',
    ISO2: 'BE',
    ISO3: 'BEL',
    topLevelDomain: 'be',
    FIPS: 'BE',
    ISONumeric: '056',
    GeoNameID: 2802361,
    E164: 32,
    phoneCode: 32,
    Continent: 'Europe',
    Capital: 'Brussels',
    timeZoneInCapital: 'Europe/Brussels',
    Currency: 'Euro',
    languageCodes: 'nl-BE,fr-BE,de-BE',
    Languages:
      'Dutch (official) 60%, French (official) 40%, German (official) less than 1%, legally bilingual (Dutch and French)',
    areaKM2: 30510,
    internetHosts: 5192000,
    internetUsers: 8113000,
    phonesMobile: 12880000,
    phonesLandline: 4631000,
    GDP: 507400000000,
    englishShortName: 'Belgium',
    frenchShortName: 'Belgique (la)',
    numeric: 56,
    japaneseShortName: 'ベルギー',
  },
  BZ: {
    countryName: 'Belize',
    ISO2: 'BZ',
    ISO3: 'BLZ',
    topLevelDomain: 'bz',
    FIPS: 'BH',
    ISONumeric: '084',
    GeoNameID: 3582678,
    E164: 501,
    phoneCode: 501,
    Continent: 'North America',
    Capital: 'Belmopan',
    timeZoneInCapital: 'America/Belize',
    Currency: 'Dollar',
    languageCodes: 'en-BZ,es',
    Languages:
      'Spanish 46%, Creole 32.9%, Mayan dialects 8.9%, English 3.9% (official), Garifuna 3.4% (Carib), German 3.3%, other 1.4%, unknown 0.2% (2000 census)',
    areaKM2: 22966,
    internetHosts: 3392,
    internetUsers: 36000,
    phonesMobile: 164200,
    phonesLandline: 25400,
    GDP: 1637000000,
    englishShortName: 'Belize',
    frenchShortName: 'Belize (le)',
    numeric: 84,
    japaneseShortName: 'ベリーズ',
  },
  BJ: {
    countryName: 'Benin',
    ISO2: 'BJ',
    ISO3: 'BEN',
    topLevelDomain: 'bj',
    FIPS: 'BN',
    ISONumeric: 204,
    GeoNameID: 2395170,
    E164: 229,
    phoneCode: 229,
    Continent: 'Africa',
    Capital: 'Porto-Novo',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'fr-BJ',
    Languages:
      'French (official), Fon and Yoruba (most common vernaculars in south), tribal languages (at least six major ones in north)',
    areaKM2: 112620,
    internetHosts: 491,
    internetUsers: 200100,
    phonesMobile: 8408000,
    phonesLandline: 156700,
    GDP: 8359000000,
    englishShortName: 'Benin',
    frenchShortName: 'Bénin (le)',
    numeric: 204,
    japaneseShortName: 'ベナン',
  },
  BM: {
    countryName: 'Bermuda',
    ISO2: 'BM',
    ISO3: 'BMU',
    topLevelDomain: 'bm',
    FIPS: 'BD',
    ISONumeric: '060',
    GeoNameID: 3573345,
    E164: 1,
    phoneCode: '1-441',
    Continent: 'North America',
    Capital: 'Hamilton',
    timeZoneInCapital: 'Atlantic/Bermuda',
    Currency: 'Dollar',
    languageCodes: 'en-BM,pt',
    Languages: 'English (official), Portuguese',
    areaKM2: 53,
    internetHosts: 20040,
    internetUsers: 54000,
    phonesMobile: 91000,
    phonesLandline: 69000,
    GDP: 5600000000,
    englishShortName: 'Bermuda',
    frenchShortName: 'Bermudes (les)',
    numeric: 60,
    japaneseShortName: 'バミューダ',
  },
  BT: {
    countryName: 'Bhutan',
    ISO2: 'BT',
    ISO3: 'BTN',
    topLevelDomain: 'bt',
    FIPS: 'BT',
    ISONumeric: '064',
    GeoNameID: 1252634,
    E164: 975,
    phoneCode: 975,
    Continent: 'Asia',
    Capital: 'Thimphu',
    timeZoneInCapital: 'Asia/Thimphu',
    Currency: 'Ngultrum',
    languageCodes: 'dz',
    Languages:
      'Sharchhopka 28%, Dzongkha (official) 24%, Lhotshamkha 22%, other 26% (includes foreign languages) (2005 est.)',
    areaKM2: 47000,
    internetHosts: 14590,
    internetUsers: 50000,
    phonesMobile: 560000,
    phonesLandline: 27000,
    GDP: 2133000000,
    englishShortName: 'Bhutan',
    frenchShortName: 'Bhoutan (le)',
    numeric: 64,
    japaneseShortName: 'ブータン',
  },
  BO: {
    countryName: 'Bolivia',
    ISO2: 'BO',
    ISO3: 'BOL',
    topLevelDomain: 'bo',
    FIPS: 'BL',
    ISONumeric: '068',
    GeoNameID: 3923057,
    E164: 591,
    phoneCode: 591,
    Continent: 'South America',
    Capital: 'Sucre',
    timeZoneInCapital: 'America/La_Paz',
    Currency: 'Boliviano',
    languageCodes: 'es-BO,qu,ay',
    Languages:
      'Spanish (official) 60.7%, Quechua (official) 21.2%, Aymara (official) 14.6%, Guarani (official), foreign languages 2.4%, other 1.2%',
    areaKM2: 1098580,
    internetHosts: 180988,
    internetUsers: 1103000,
    phonesMobile: 9494000,
    phonesLandline: 880600,
    GDP: 30790000000,
    englishShortName: 'Bolivia (Plurinational State of)',
    frenchShortName: 'Bolivie (État plurinational de)',
    numeric: 68,
    japaneseShortName: 'ボリビア',
  },
  BA: {
    countryName: 'Bosnia and Herzegovina',
    ISO2: 'BA',
    ISO3: 'BIH',
    topLevelDomain: 'ba',
    FIPS: 'BK',
    ISONumeric: '070',
    GeoNameID: 3277605,
    E164: 387,
    phoneCode: 387,
    Continent: 'Europe',
    Capital: 'Sarajevo',
    timeZoneInCapital: 'Europe/Belgrade',
    Currency: 'Marka',
    languageCodes: 'bs,hr-BA,sr-BA',
    Languages: 'Bosnian (official), Croatian (official), Serbian (official)',
    areaKM2: 51129,
    internetHosts: 155252,
    internetUsers: 1422000,
    phonesMobile: 3350000,
    phonesLandline: 878000,
    GDP: 18870000000,
    englishShortName: 'Bosnia and Herzegovina',
    frenchShortName: 'Bosnie-Herzégovine (la)',
    numeric: 70,
    japaneseShortName: 'ボスニア・ヘルツェゴビナ',
  },
  BW: {
    countryName: 'Botswana',
    ISO2: 'BW',
    ISO3: 'BWA',
    topLevelDomain: 'bw',
    FIPS: 'BC',
    ISONumeric: '072',
    GeoNameID: 933860,
    E164: 267,
    phoneCode: 267,
    Continent: 'Africa',
    Capital: 'Gaborone',
    timeZoneInCapital: 'Africa/Maputo',
    Currency: 'Pula',
    languageCodes: 'en-BW,tn-BW',
    Languages:
      'Setswana 78.2%, Kalanga 7.9%, Sekgalagadi 2.8%, English (official) 2.1%, other 8.6%, unspecified 0.4% (2001 census)',
    areaKM2: 600370,
    internetHosts: 1806,
    internetUsers: 120000,
    phonesMobile: 3082000,
    phonesLandline: 160500,
    GDP: 15530000000,
    englishShortName: 'Botswana',
    frenchShortName: 'Botswana (le)',
    numeric: 72,
    japaneseShortName: 'ボツワナ',
  },
  BR: {
    countryName: 'Brazil',
    ISO2: 'BR',
    ISO3: 'BRA',
    topLevelDomain: 'br',
    FIPS: 'BR',
    ISONumeric: '076',
    GeoNameID: 3469034,
    E164: 55,
    phoneCode: 55,
    Continent: 'South America',
    Capital: 'Brasilia',
    timeZoneInCapital: 'America/Sao_Paulo',
    Currency: 'Real',
    languageCodes: 'pt-BR,es,en,fr',
    Languages: 'Portuguese (official and most widely spoken language)',
    areaKM2: 8511965,
    internetHosts: 26577000,
    internetUsers: 75982000,
    phonesMobile: 248324000,
    phonesLandline: 44300000,
    GDP: 2190000000000,
    englishShortName: 'Brazil',
    frenchShortName: 'Brésil (le)',
    numeric: 76,
    japaneseShortName: 'ブラジル',
  },
  // IO: {
  //   countryName: 'British Indian Ocean Territory',
  //   ISO2: 'IO',
  //   ISO3: 'IOT',
  //   topLevelDomain: 'io',
  //   FIPS: 'IO',
  //   ISONumeric: '086',
  //   GeoNameID: 1282588,
  //   E164: 246,
  //   phoneCode: 246,
  //   Continent: 'Asia',
  //   Capital: 'Diego Garcia',
  //   timeZoneInCapital: 'Indian/Chagos',
  //   Currency: 'Dollar',
  //   languageCodes: 'en-IO',
  //   Languages: 'English',
  //   areaKM2: 60,
  //   internetHosts: 75006,
  //   internetUsers: null,
  //   phonesMobile: null,
  //   phonesLandline: null,
  //   GDP: null,
  //   englishShortName: 'British Indian Ocean Territory (the)',
  //   frenchShortName: "Indien (le Territoire britannique de l'océan)",
  //   numeric: 86,
  //   japaneseShortName: 'イギリス領インド洋地域',
  // },
  VG: {
    countryName: 'British Virgin Islands',
    ISO2: 'VG',
    ISO3: 'VGB',
    topLevelDomain: 'vg',
    FIPS: 'VI',
    ISONumeric: '092',
    GeoNameID: 3577718,
    E164: 1,
    phoneCode: '1-284',
    Continent: 'North America',
    Capital: 'Road Town',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-VG',
    Languages: 'English (official)',
    areaKM2: 153,
    internetHosts: 505,
    internetUsers: 4000,
    phonesMobile: 48700,
    phonesLandline: 12268,
    GDP: 1095000000,
    englishShortName: 'Virgin Islands (British)',
    frenchShortName: 'Vierges britanniques (les Îles)',
    numeric: 92,
    japaneseShortName: 'イギリス領ヴァージン諸島',
  },
  BN: {
    countryName: 'Brunei',
    ISO2: 'BN',
    ISO3: 'BRN',
    topLevelDomain: 'bn',
    FIPS: 'BX',
    ISONumeric: '096',
    GeoNameID: 1820814,
    E164: 673,
    phoneCode: 673,
    Continent: 'Asia',
    Capital: 'Bandar Seri Begawan',
    timeZoneInCapital: 'Asia/Brunei',
    Currency: 'Dollar',
    languageCodes: 'ms-BN,en-BN',
    Languages: 'Malay (official), English, Chinese',
    areaKM2: 5770,
    internetHosts: 49457,
    internetUsers: 314900,
    phonesMobile: 469700,
    phonesLandline: 70933,
    GDP: 16560000000,
    englishShortName: 'Brunei Darussalam',
    frenchShortName: 'Brunéi Darussalam (le)',
    numeric: 96,
    japaneseShortName: 'ブルネイ・ダルサラーム',
  },
  BG: {
    countryName: 'Bulgaria',
    ISO2: 'BG',
    ISO3: 'BGR',
    topLevelDomain: 'bg',
    FIPS: 'BU',
    ISONumeric: 100,
    GeoNameID: 732800,
    E164: 359,
    phoneCode: 359,
    Continent: 'Europe',
    Capital: 'Sofia',
    timeZoneInCapital: 'Europe/Sofia',
    Currency: 'Lev',
    languageCodes: 'bg,tr-BG',
    Languages: 'Bulgarian (official) 76.8%, Turkish 8.2%, Roma 3.8%, other 0.7%, unspecified 10.5% (2011 est.)',
    areaKM2: 110910,
    internetHosts: 976277,
    internetUsers: 3395000,
    phonesMobile: 10780000,
    phonesLandline: 2253000,
    GDP: 53700000000,
    englishShortName: 'Bulgaria',
    frenchShortName: 'Bulgarie (la)',
    numeric: 100,
    japaneseShortName: 'ブルガリア',
  },
  BF: {
    countryName: 'Burkina Faso',
    ISO2: 'BF',
    ISO3: 'BFA',
    topLevelDomain: 'bf',
    FIPS: 'UV',
    ISONumeric: 854,
    GeoNameID: 2361809,
    E164: 226,
    phoneCode: 226,
    Continent: 'Africa',
    Capital: 'Ouagadougou',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Franc',
    languageCodes: 'fr-BF',
    Languages:
      'French (official), native African languages belonging to Sudanic family spoken by 90% of the population',
    areaKM2: 274200,
    internetHosts: 1795,
    internetUsers: 178100,
    phonesMobile: 9980000,
    phonesLandline: 141400,
    GDP: 12130000000,
    englishShortName: 'Burkina Faso',
    frenchShortName: 'Burkina Faso (le)',
    numeric: 854,
    japaneseShortName: 'ブルキナファソ',
  },
  BI: {
    countryName: 'Burundi',
    ISO2: 'BI',
    ISO3: 'BDI',
    topLevelDomain: 'bi',
    FIPS: 'BY',
    ISONumeric: 108,
    GeoNameID: 433561,
    E164: 257,
    phoneCode: 257,
    Continent: 'Africa',
    Capital: 'Bujumbura',
    timeZoneInCapital: 'Africa/Maputo',
    Currency: 'Franc',
    languageCodes: 'fr-BI,rn',
    Languages:
      'Kirundi 29.7% (official), Kirundi and other language 9.1%, French (official) and French and other language 0.3%, Swahili and Swahili and other language 0.2% (along Lake Tanganyika and in the Bujumbura area), English and English and other language 0.06%, more than 2 languages 3.7%, unspecified 56.9% (2008 est.)',
    areaKM2: 27830,
    internetHosts: 229,
    internetUsers: 157800,
    phonesMobile: 2247000,
    phonesLandline: 17400,
    GDP: 2676000000,
    englishShortName: 'Burundi',
    frenchShortName: 'Burundi (le)',
    numeric: 108,
    japaneseShortName: 'ブルンジ',
  },
  KH: {
    countryName: 'Cambodia',
    ISO2: 'KH',
    ISO3: 'KHM',
    topLevelDomain: 'kh',
    FIPS: 'CB',
    ISONumeric: 116,
    GeoNameID: 1831722,
    E164: 855,
    phoneCode: 855,
    Continent: 'Asia',
    Capital: 'Phnom Penh',
    timeZoneInCapital: 'Asia/Phnom_Penh',
    Currency: 'Riels',
    languageCodes: 'km,fr,en',
    Languages: 'Khmer (official) 96.3%, other 3.7% (2008 est.)',
    areaKM2: 181040,
    internetHosts: 13784,
    internetUsers: 78500,
    phonesMobile: 19100000,
    phonesLandline: 584000,
    GDP: 15640000000,
    englishShortName: 'Cambodia',
    frenchShortName: 'Cambodge (le)',
    numeric: 116,
    japaneseShortName: 'カンボジア',
  },
  CM: {
    countryName: 'Cameroon',
    ISO2: 'CM',
    ISO3: 'CMR',
    topLevelDomain: 'cm',
    FIPS: 'CM',
    ISONumeric: 120,
    GeoNameID: 2233387,
    E164: 237,
    phoneCode: 237,
    Continent: 'Africa',
    Capital: 'Yaounde',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'en-CM,fr-CM',
    Languages: '24 major African language groups, English (official), French (official)',
    areaKM2: 475440,
    internetHosts: 10207,
    internetUsers: 749600,
    phonesMobile: 13100000,
    phonesLandline: 737400,
    GDP: 27880000000,
    englishShortName: 'Cameroon',
    frenchShortName: 'Cameroun (le)',
    numeric: 120,
    japaneseShortName: 'カメルーン',
  },
  CA: {
    countryName: 'Canada',
    ISO2: 'CA',
    ISO3: 'CAN',
    topLevelDomain: 'ca',
    FIPS: 'CA',
    ISONumeric: 124,
    GeoNameID: 6251999,
    E164: 1,
    phoneCode: 1,
    Continent: 'North America',
    Capital: 'Ottawa',
    timeZoneInCapital: 'America/Toronto',
    Currency: 'Dollar',
    languageCodes: 'en-CA,fr-CA,iu',
    Languages:
      'English (official) 58.7%, French (official) 22%, Punjabi 1.4%, Italian 1.3%, Spanish 1.3%, German 1.3%, Cantonese 1.2%, Tagalog 1.2%, Arabic 1.1%, other 10.5% (2011 est.)',
    areaKM2: 9984670,
    internetHosts: 8743000,
    internetUsers: 26960000,
    phonesMobile: 26263000,
    phonesLandline: 18010000,
    GDP: 1825000000000,
    englishShortName: 'Canada',
    frenchShortName: 'Canada (le)',
    numeric: 124,
    japaneseShortName: 'カナダ',
  },
  CV: {
    countryName: 'Cape Verde',
    ISO2: 'CV',
    ISO3: 'CPV',
    topLevelDomain: 'cv',
    FIPS: 'CV',
    ISONumeric: 132,
    GeoNameID: 3374766,
    E164: 238,
    phoneCode: 238,
    Continent: 'Africa',
    Capital: 'Praia',
    timeZoneInCapital: 'Atlantic/Cape_Verde',
    Currency: 'Escudo',
    languageCodes: 'pt-CV',
    Languages: 'Portuguese (official), Crioulo (a blend of Portuguese and West African words)',
    areaKM2: 4033,
    internetHosts: 38,
    internetUsers: 150000,
    phonesMobile: 425300,
    phonesLandline: 70200,
    GDP: 1955000000,
    englishShortName: 'Cabo Verde',
    frenchShortName: 'Cabo Verde',
    numeric: 132,
    japaneseShortName: 'カーボベルデ',
  },
  KY: {
    countryName: 'Cayman Islands',
    ISO2: 'KY',
    ISO3: 'CYM',
    topLevelDomain: 'ky',
    FIPS: 'CJ',
    ISONumeric: 136,
    GeoNameID: 3580718,
    E164: 1,
    phoneCode: '1-345',
    Continent: 'North America',
    Capital: 'George Town',
    timeZoneInCapital: 'America/Cayman',
    Currency: 'Dollar',
    languageCodes: 'en-KY',
    Languages: 'English (official) 90.9%, Spanish 4%, Filipino 3.3%, other 1.7%, unspecified 0.1% (2010 est.)',
    areaKM2: 262,
    internetHosts: 23472,
    internetUsers: 23000,
    phonesMobile: 96300,
    phonesLandline: 37400,
    GDP: 2250000000,
    englishShortName: 'Cayman Islands (the)',
    frenchShortName: 'Caïmans (les Îles)',
    numeric: 136,
    japaneseShortName: 'ケイマン諸島',
  },
  CF: {
    countryName: 'Central African Republic',
    ISO2: 'CF',
    ISO3: 'CAF',
    topLevelDomain: 'cf',
    FIPS: 'CT',
    ISONumeric: 140,
    GeoNameID: 239880,
    E164: 236,
    phoneCode: 236,
    Continent: 'Africa',
    Capital: 'Bangui',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'fr-CF,sg,ln,kg',
    Languages: 'French (official), Sangho (lingua franca and national language), tribal languages',
    areaKM2: 622984,
    internetHosts: 20,
    internetUsers: 22600,
    phonesMobile: 1070000,
    phonesLandline: 5600,
    GDP: 2050000000,
    englishShortName: 'Central African Republic (the)',
    frenchShortName: 'République centrafricaine (la)',
    numeric: 140,
    japaneseShortName: '中央アフリカ共和国',
  },
  TD: {
    countryName: 'Chad',
    ISO2: 'TD',
    ISO3: 'TCD',
    topLevelDomain: 'td',
    FIPS: 'CD',
    ISONumeric: 148,
    GeoNameID: 2434508,
    E164: 235,
    phoneCode: 235,
    Continent: 'Africa',
    Capital: "N'Djamena",
    timeZoneInCapital: 'Africa/Ndjamena',
    Currency: 'Franc',
    languageCodes: 'fr-TD,ar-TD,sre',
    Languages: 'French (official), Arabic (official), Sara (in south), more than 120 different languages and dialects',
    areaKM2: 1284000,
    internetHosts: 6,
    internetUsers: 168100,
    phonesMobile: 4200000,
    phonesLandline: 29900,
    GDP: 13590000000,
    englishShortName: 'Chad',
    frenchShortName: 'Tchad (le)',
    numeric: 148,
    japaneseShortName: 'チャド',
  },
  CL: {
    countryName: 'Chile',
    ISO2: 'CL',
    ISO3: 'CHL',
    topLevelDomain: 'cl',
    FIPS: 'CI',
    ISONumeric: 152,
    GeoNameID: 3895114,
    E164: 56,
    phoneCode: 56,
    Continent: 'South America',
    Capital: 'Santiago',
    timeZoneInCapital: 'America/Santiago',
    Currency: 'Peso',
    languageCodes: 'es-CL',
    Languages:
      'Spanish 99.5% (official), English 10.2%, indigenous 1% (includes Mapudungun, Aymara, Quechua, Rapa Nui), other 2.3%, unspecified 0.2%',
    areaKM2: 756950,
    internetHosts: 2152000,
    internetUsers: 7009000,
    phonesMobile: 24130000,
    phonesLandline: 3276000,
    GDP: 281700000000,
    englishShortName: 'Chile',
    frenchShortName: 'Chili (le)',
    numeric: 152,
    japaneseShortName: 'チリ',
  },
  CN: {
    countryName: 'China',
    ISO2: 'CN',
    ISO3: 'CHN',
    topLevelDomain: 'cn',
    FIPS: 'CH',
    ISONumeric: 156,
    GeoNameID: 1814991,
    E164: 86,
    phoneCode: 86,
    Continent: 'Asia',
    Capital: 'Beijing',
    timeZoneInCapital: 'Asia/Shanghai',
    Currency: 'Yuan Renminbi',
    languageCodes: 'zh-CN,yue,wuu,dta,ug,za',
    Languages:
      'Standard Chinese or Mandarin (official; Putonghua, based on the Beijing dialect), Yue (Cantonese), Wu (Shanghainese), Minbei (Fuzhou), Minnan (Hokkien-Taiwanese), Xiang, Gan, Hakka dialects, minority languages',
    areaKM2: 9596960,
    internetHosts: 20602000,
    internetUsers: 389000000,
    phonesMobile: 1100000000,
    phonesLandline: 278860000,
    GDP: 9330000000000,
    englishShortName: 'China',
    frenchShortName: 'Chine (la)',
    numeric: 156,
    japaneseShortName: '中国',
  },
  CX: {
    countryName: 'Christmas Island',
    ISO2: 'CX',
    ISO3: 'CXR',
    topLevelDomain: 'cx',
    FIPS: 'KT',
    ISONumeric: 162,
    GeoNameID: 2078138,
    E164: 61,
    phoneCode: 61,
    Continent: 'Asia',
    Capital: 'Flying Fish Cove',
    timeZoneInCapital: 'Indian/Christmas',
    Currency: 'Dollar',
    languageCodes: 'en,zh,ms-CC',
    Languages: 'English (official), Chinese, Malay',
    areaKM2: 135,
    internetHosts: 3028,
    internetUsers: 464,
    englishShortName: 'Christmas Island',
    frenchShortName: "Christmas (l'Île)",
    numeric: 162,
    japaneseShortName: 'クリスマス島',
  },
  CC: {
    countryName: 'Cocos Islands',
    ISO2: 'CC',
    ISO3: 'CCK',
    topLevelDomain: 'cc',
    FIPS: 'CK',
    ISONumeric: 166,
    GeoNameID: 1547376,
    E164: 61,
    phoneCode: 61,
    Continent: 'Asia',
    Capital: 'West Island',
    timeZoneInCapital: 'Indian/Cocos',
    Currency: 'Dollar',
    languageCodes: 'ms-CC,en',
    Languages: 'Malay (Cocos dialect), English',
    areaKM2: 14,
    englishShortName: 'Cocos (Keeling) Islands (the)',
    frenchShortName: 'Cocos (les Îles)/ Keeling (les Îles)',
    numeric: 166,
    japaneseShortName: 'ココス諸島',
  },
  CO: {
    countryName: 'Colombia',
    ISO2: 'CO',
    ISO3: 'COL',
    topLevelDomain: 'co',
    FIPS: 'CO',
    ISONumeric: 170,
    GeoNameID: 3686110,
    E164: 57,
    phoneCode: 57,
    Continent: 'South America',
    Capital: 'Bogota',
    timeZoneInCapital: 'America/Bogota',
    Currency: 'Peso',
    languageCodes: 'es-CO',
    Languages: 'Spanish (official)',
    areaKM2: 1138910,
    internetHosts: 4410000,
    internetUsers: 22538000,
    phonesMobile: 49066000,
    phonesLandline: 6291000,
    GDP: 369200000000,
    englishShortName: 'Colombia',
    frenchShortName: 'Colombie (la)',
    numeric: 170,
    japaneseShortName: 'コロンビア',
  },
  KM: {
    countryName: 'Comoros',
    ISO2: 'KM',
    ISO3: 'COM',
    topLevelDomain: 'km',
    FIPS: 'CN',
    ISONumeric: 174,
    GeoNameID: 921929,
    E164: 269,
    phoneCode: 269,
    Continent: 'Africa',
    Capital: 'Moroni',
    timeZoneInCapital: 'Indian/Comoro',
    Currency: 'Franc',
    languageCodes: 'ar,fr-KM',
    Languages: 'Arabic (official), French (official), Shikomoro (a blend of Swahili and Arabic)',
    areaKM2: 2170,
    internetHosts: 14,
    internetUsers: 24300,
    phonesMobile: 250000,
    phonesLandline: 24000,
    GDP: 658000000,
    englishShortName: 'Comoros (the)',
    frenchShortName: 'Comores (les)',
    numeric: 174,
    japaneseShortName: 'コモロ',
  },
  CK: {
    countryName: 'Cook Islands',
    ISO2: 'CK',
    ISO3: 'COK',
    topLevelDomain: 'ck',
    FIPS: 'CW',
    ISONumeric: 184,
    GeoNameID: 1899402,
    E164: 682,
    phoneCode: 682,
    Continent: 'Oceania',
    Capital: 'Avarua',
    timeZoneInCapital: 'Pacific/Rarotonga',
    Currency: 'Dollar',
    languageCodes: 'en-CK,mi',
    Languages: 'English (official) 86.4%, Cook Islands Maori (Rarotongan) (official) 76.2%, other 8.3%',
    areaKM2: 240,
    internetHosts: 3562,
    internetUsers: 6000,
    phonesMobile: 7800,
    phonesLandline: 7200,
    GDP: 183200000,
    englishShortName: 'Cook Islands (the)',
    frenchShortName: 'Cook (les Îles)',
    numeric: 184,
    japaneseShortName: 'クック諸島',
  },
  CR: {
    countryName: 'Costa Rica',
    ISO2: 'CR',
    ISO3: 'CRI',
    topLevelDomain: 'cr',
    FIPS: 'CS',
    ISONumeric: 188,
    GeoNameID: 3624060,
    E164: 506,
    phoneCode: 506,
    Continent: 'North America',
    Capital: 'San Jose',
    timeZoneInCapital: 'America/Costa_Rica',
    Currency: 'Colon',
    languageCodes: 'es-CR,en',
    Languages: 'Spanish (official), English',
    areaKM2: 51100,
    internetHosts: 147258,
    internetUsers: 1485000,
    phonesMobile: 6151000,
    phonesLandline: 1018000,
    GDP: 48510000000,
    englishShortName: 'Costa Rica',
    frenchShortName: 'Costa Rica (le)',
    numeric: 188,
    japaneseShortName: 'コスタリカ',
  },
  HR: {
    countryName: 'Croatia',
    ISO2: 'HR',
    ISO3: 'HRV',
    topLevelDomain: 'hr',
    FIPS: 'HR',
    ISONumeric: 191,
    GeoNameID: 3202326,
    E164: 385,
    phoneCode: 385,
    Continent: 'Europe',
    Capital: 'Zagreb',
    timeZoneInCapital: 'Europe/Belgrade',
    Currency: 'Kuna',
    languageCodes: 'hr-HR,sr',
    Languages:
      'Croatian (official) 95.6%, Serbian 1.2%, other 3% (including Hungarian, Czech, Slovak, and Albanian), unspecified 0.2% (2011 est.)',
    areaKM2: 56542,
    internetHosts: 729420,
    internetUsers: 2234000,
    phonesMobile: 4970000,
    phonesLandline: 1640000,
    GDP: 59140000000,
    englishShortName: 'Croatia',
    frenchShortName: 'Croatie (la)',
    numeric: 191,
    japaneseShortName: 'クロアチア',
  },
  CU: {
    countryName: 'Cuba',
    ISO2: 'CU',
    ISO3: 'CUB',
    topLevelDomain: 'cu',
    FIPS: 'CU',
    ISONumeric: 192,
    GeoNameID: 3562981,
    E164: 53,
    phoneCode: 53,
    Continent: 'North America',
    Capital: 'Havana',
    timeZoneInCapital: 'America/Havana',
    Currency: 'Peso',
    languageCodes: 'es-CU',
    Languages: 'Spanish (official)',
    areaKM2: 110860,
    internetHosts: 3244,
    internetUsers: 1606000,
    phonesMobile: 1682000,
    phonesLandline: 1217000,
    GDP: 72300000000,
    englishShortName: 'Cuba',
    frenchShortName: 'Cuba',
    numeric: 192,
    japaneseShortName: 'キューバ',
  },
  CW: {
    countryName: 'Curacao',
    ISO2: 'CW',
    ISO3: 'CUW',
    topLevelDomain: 'cw',
    FIPS: 'UC',
    ISONumeric: 531,
    GeoNameID: 7626836,
    E164: 599,
    phoneCode: 599,
    Continent: 'North America',
    Capital: 'Willemstad',
    timeZoneInCapital: 'America/Curacao',
    Currency: 'Guilder',
    languageCodes: 'nl,pap',
    Languages:
      'Papiamentu (a Spanish-Portuguese-Dutch-English dialect) 81.2%, Dutch (official) 8%, Spanish 4%, English 2.9%, other 3.9% (2001 census)',
    areaKM2: 444,
    GDP: 5600000000,
    englishShortName: 'Curaçao',
    frenchShortName: 'Curaçao',
    numeric: 531,
  },
  CY: {
    countryName: 'Cyprus',
    ISO2: 'CY',
    ISO3: 'CYP',
    topLevelDomain: 'cy',
    FIPS: 'CY',
    ISONumeric: 196,
    GeoNameID: 146669,
    E164: 357,
    phoneCode: 357,
    Continent: 'Europe',
    Capital: 'Nicosia',
    timeZoneInCapital: 'Asia/Nicosia',
    Currency: 'Euro',
    languageCodes: 'el-CY,tr-CY,en',
    Languages:
      'Greek (official) 80.9%, Turkish (official) 0.2%, English 4.1%, Romanian 2.9%, Russian 2.5%, Bulgarian 2.2%, Arabic 1.2%, Filippino 1.1%, other 4.3%, unspecified 0.6% (2011 est.)',
    areaKM2: 9250,
    internetHosts: 252013,
    internetUsers: 433900,
    phonesMobile: 1110000,
    phonesLandline: 373200,
    GDP: 21780000000,
    englishShortName: 'Cyprus',
    frenchShortName: 'Chypre',
    numeric: 196,
    japaneseShortName: 'キプロス',
  },
  CZ: {
    countryName: 'Czech Republic',
    ISO2: 'CZ',
    ISO3: 'CZE',
    topLevelDomain: 'cz',
    FIPS: 'EZ',
    ISONumeric: 203,
    GeoNameID: 3077311,
    E164: 420,
    phoneCode: 420,
    Continent: 'Europe',
    Capital: 'Prague',
    timeZoneInCapital: 'Europe/Prague',
    Currency: 'Koruna',
    languageCodes: 'cs,sk',
    Languages: 'Czech 95.4%, Slovak 1.6%, other 3% (2011 census)',
    areaKM2: 78866,
    internetHosts: 4148000,
    internetUsers: 6681000,
    phonesMobile: 12973000,
    phonesLandline: 2100000,
    GDP: 194800000000,
    englishShortName: 'Czechia',
    frenchShortName: 'Tchéquie (la)',
    numeric: 203,
    japaneseShortName: 'チェコ共和国',
  },
  CD: {
    countryName: 'Democratic Republic of the Congo',
    ISO2: 'CD',
    ISO3: 'COD',
    topLevelDomain: 'cd',
    FIPS: 'CG',
    ISONumeric: 180,
    GeoNameID: 203312,
    E164: 243,
    phoneCode: 243,
    Continent: 'Africa',
    Capital: 'Kinshasa',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'fr-CD,ln,kg',
    Languages:
      'French (official), Lingala (a lingua franca trade language), Kingwana (a dialect of Kiswahili or Swahili), Kikongo, Tshiluba',
    areaKM2: 2345410,
    internetHosts: 2515,
    internetUsers: 290000,
    phonesMobile: 19487000,
    phonesLandline: 58200,
    GDP: 18560000000,
    englishShortName: 'Congo (the Democratic Republic of the)',
    frenchShortName: 'Congo (la République démocratique du)',
    numeric: 180,
    japaneseShortName: 'コンゴ民主共和国',
  },
  DK: {
    countryName: 'Denmark',
    ISO2: 'DK',
    ISO3: 'DNK',
    topLevelDomain: 'dk',
    FIPS: 'DA',
    ISONumeric: 208,
    GeoNameID: 2623032,
    E164: 45,
    phoneCode: 45,
    Continent: 'Europe',
    Capital: 'Copenhagen',
    timeZoneInCapital: 'Europe/Copenhagen',
    Currency: 'Krone',
    languageCodes: 'da-DK,en,fo,de-DK',
    Languages: 'Danish, Faroese, Greenlandic (an Inuit dialect), German (small minority)',
    areaKM2: 43094,
    internetHosts: 4297000,
    internetUsers: 4750000,
    phonesMobile: 6600000,
    phonesLandline: 2431000,
    GDP: 324300000000,
    englishShortName: 'Denmark',
    frenchShortName: 'Danemark (le)',
    numeric: 208,
    japaneseShortName: 'デンマーク',
  },
  DJ: {
    countryName: 'Djibouti',
    ISO2: 'DJ',
    ISO3: 'DJI',
    topLevelDomain: 'dj',
    FIPS: 'DJ',
    ISONumeric: 262,
    GeoNameID: 223816,
    E164: 253,
    phoneCode: 253,
    Continent: 'Africa',
    Capital: 'Djibouti',
    timeZoneInCapital: 'Africa/Djibouti',
    Currency: 'Franc',
    languageCodes: 'fr-DJ,ar,so-DJ,aa',
    Languages: 'French (official), Arabic (official), Somali, Afar',
    areaKM2: 23000,
    internetHosts: 215,
    internetUsers: 25900,
    phonesMobile: 209000,
    phonesLandline: 18000,
    GDP: 1459000000,
    englishShortName: 'Djibouti',
    frenchShortName: 'Djibouti',
    numeric: 262,
    japaneseShortName: 'ジブチ',
  },
  DM: {
    countryName: 'Dominica',
    ISO2: 'DM',
    ISO3: 'DMA',
    topLevelDomain: 'dm',
    FIPS: 'DO',
    ISONumeric: 212,
    GeoNameID: 3575830,
    E164: 1,
    phoneCode: '1-767',
    Continent: 'North America',
    Capital: 'Roseau',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-DM',
    Languages: 'English (official), French patois',
    areaKM2: 754,
    internetHosts: 723,
    internetUsers: 28000,
    phonesMobile: 109300,
    phonesLandline: 14600,
    GDP: 495000000,
    englishShortName: 'Dominica',
    frenchShortName: 'Dominique (la)',
    numeric: 212,
    japaneseShortName: 'ドミニカ',
  },
  DO: {
    countryName: 'Dominican Republic',
    ISO2: 'DO',
    ISO3: 'DOM',
    topLevelDomain: 'do',
    FIPS: 'DR',
    ISONumeric: 214,
    GeoNameID: 3508796,
    E164: 1,
    phoneCode: '1-809, 1-829, 1-849',
    Continent: 'North America',
    Capital: 'Santo Domingo',
    timeZoneInCapital: 'America/Santo_Domingo',
    Currency: 'Peso',
    languageCodes: 'es-DO',
    Languages: 'Spanish (official)',
    areaKM2: 48730,
    internetHosts: 404500,
    internetUsers: 2701000,
    phonesMobile: 9038000,
    phonesLandline: 1065000,
    GDP: 59270000000,
    englishShortName: 'Dominican Republic (the)',
    frenchShortName: 'dominicaine (la République)',
    numeric: 214,
    japaneseShortName: 'ドミニカ共和国',
  },
  TL: {
    countryName: 'East Timor',
    ISO2: 'TL',
    ISO3: 'TLS',
    topLevelDomain: 'tl',
    FIPS: 'TT',
    ISONumeric: 626,
    GeoNameID: 1966436,
    E164: 670,
    phoneCode: 670,
    Continent: 'Oceania',
    Capital: 'Dili',
    timeZoneInCapital: 'Asia/Dili',
    Currency: 'Dollar',
    languageCodes: 'tet,pt-TL,id,en',
    Languages: 'Tetum (official), Portuguese (official), Indonesian, English',
    areaKM2: 15007,
    internetHosts: 252,
    internetUsers: 2100,
    phonesMobile: 621000,
    phonesLandline: 3000,
    GDP: 6129000000,
    englishShortName: 'Timor-Leste',
    frenchShortName: 'Timor-Leste (le)',
    numeric: 626,
    japaneseShortName: '東ティモール',
  },
  EC: {
    countryName: 'Ecuador',
    ISO2: 'EC',
    ISO3: 'ECU',
    topLevelDomain: 'ec',
    FIPS: 'EC',
    ISONumeric: 218,
    GeoNameID: 3658394,
    E164: 593,
    phoneCode: 593,
    Continent: 'South America',
    Capital: 'Quito',
    timeZoneInCapital: 'America/Guayaquil',
    Currency: 'Dollar',
    languageCodes: 'es-EC',
    Languages: 'Spanish (Castillian) 93% (official), Quechua 4.1%, other indigenous 0.7%, foreign 2.2%',
    areaKM2: 283560,
    internetHosts: 170538,
    internetUsers: 3352000,
    phonesMobile: 16457000,
    phonesLandline: 2310000,
    GDP: 91410000000,
    englishShortName: 'Ecuador',
    frenchShortName: "Équateur (l')",
    numeric: 218,
    japaneseShortName: 'エクアドル',
  },
  EG: {
    countryName: 'Egypt',
    ISO2: 'EG',
    ISO3: 'EGY',
    topLevelDomain: 'eg',
    FIPS: 'EG',
    ISONumeric: 818,
    GeoNameID: 357994,
    E164: 20,
    phoneCode: 20,
    Continent: 'Africa',
    Capital: 'Cairo',
    timeZoneInCapital: 'Africa/Cairo',
    Currency: 'Pound',
    languageCodes: 'ar-EG,en,fr',
    Languages: 'Arabic (official), English and French widely understood by educated classes',
    areaKM2: 1001450,
    internetHosts: 200430,
    internetUsers: 20136000,
    phonesMobile: 96800000,
    phonesLandline: 8557000,
    GDP: 262000000000,
    englishShortName: 'Egypt',
    frenchShortName: "Égypte (l')",
    numeric: 818,
    japaneseShortName: 'エジプト',
  },
  SV: {
    countryName: 'El Salvador',
    ISO2: 'SV',
    ISO3: 'SLV',
    topLevelDomain: 'sv',
    FIPS: 'ES',
    ISONumeric: 222,
    GeoNameID: 3585968,
    E164: 503,
    phoneCode: 503,
    Continent: 'North America',
    Capital: 'San Salvador',
    timeZoneInCapital: 'America/El_Salvador',
    Currency: 'Dollar',
    languageCodes: 'es-SV',
    Languages: 'Spanish (official), Nahua (among some Amerindians)',
    areaKM2: 21040,
    internetHosts: 24070,
    internetUsers: 746000,
    phonesMobile: 8650000,
    phonesLandline: 1060000,
    GDP: 24670000000,
    englishShortName: 'El Salvador',
    frenchShortName: 'El Salvador',
    numeric: 222,
    japaneseShortName: 'エルサルバドル',
  },
  GQ: {
    countryName: 'Equatorial Guinea',
    ISO2: 'GQ',
    ISO3: 'GNQ',
    topLevelDomain: 'gq',
    FIPS: 'EK',
    ISONumeric: 226,
    GeoNameID: 2309096,
    E164: 240,
    phoneCode: 240,
    Continent: 'Africa',
    Capital: 'Malabo',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'es-GQ,fr',
    Languages: 'Spanish (official) 67.6%, other (includes French (official), Fang, Bubi) 32.4% (1994 census)',
    areaKM2: 28051,
    internetHosts: 7,
    internetUsers: 14400,
    phonesMobile: 501000,
    phonesLandline: 14900,
    GDP: 17080000000,
    englishShortName: 'Equatorial Guinea',
    frenchShortName: 'Guinée équatoriale (la)',
    numeric: 226,
    japaneseShortName: '赤道ギニア',
  },
  ER: {
    countryName: 'Eritrea',
    ISO2: 'ER',
    ISO3: 'ERI',
    topLevelDomain: 'er',
    FIPS: 'ER',
    ISONumeric: 232,
    GeoNameID: 338010,
    E164: 291,
    phoneCode: 291,
    Continent: 'Africa',
    Capital: 'Asmara',
    timeZoneInCapital: 'Africa/Asmara',
    Currency: 'Nakfa',
    languageCodes: 'aa-ER,ar,tig,kun,ti-ER',
    Languages:
      'Tigrinya (official), Arabic (official), English (official), Tigre, Kunama, Afar, other Cushitic languages',
    areaKM2: 121320,
    internetHosts: 701,
    internetUsers: 200000,
    phonesMobile: 305300,
    phonesLandline: 60000,
    GDP: 3438000000,
    englishShortName: 'Eritrea',
    frenchShortName: "Érythrée (l')",
    numeric: 232,
    japaneseShortName: 'エリトリア',
  },
  EE: {
    countryName: 'Estonia',
    ISO2: 'EE',
    ISO3: 'EST',
    topLevelDomain: 'ee',
    FIPS: 'EN',
    ISONumeric: 233,
    GeoNameID: 453733,
    E164: 372,
    phoneCode: 372,
    Continent: 'Europe',
    Capital: 'Tallinn',
    timeZoneInCapital: 'Europe/Tallinn',
    Currency: 'Euro',
    languageCodes: 'et,ru',
    Languages: 'Estonian (official) 68.5%, Russian 29.6%, Ukrainian 0.6%, other 1.2%, unspecified 0.1% (2011 est.)',
    areaKM2: 45226,
    internetHosts: 865494,
    internetUsers: 971700,
    phonesMobile: 2070000,
    phonesLandline: 448200,
    GDP: 24280000000,
    englishShortName: 'Estonia',
    frenchShortName: "Estonie (l')",
    numeric: 233,
    japaneseShortName: 'エストニア',
  },
  ET: {
    countryName: 'Ethiopia',
    ISO2: 'ET',
    ISO3: 'ETH',
    topLevelDomain: 'et',
    FIPS: 'ET',
    ISONumeric: 231,
    GeoNameID: 337996,
    E164: 251,
    phoneCode: 251,
    Continent: 'Africa',
    Capital: 'Addis Ababa',
    timeZoneInCapital: 'Africa/Addis_Ababa',
    Currency: 'Birr',
    languageCodes: 'am,en-ET,om-ET,ti-ET,so-ET,sid',
    Languages:
      'Oromo (official working language in the State of Oromiya) 33.8%, Amharic (official national language) 29.3%, Somali (official working language of the State of Sumale) 6.2%, Tigrigna (Tigrinya) (official working language of the State of Tigray) 5.9%, Sidamo 4%, Wolaytta 2.2%, Gurage 2%, Afar (official working language of the State of Afar) 1.7%, Hadiyya 1.7%, Gamo 1.5%, Gedeo 1.3%, Opuuo 1.2%, Kafa 1.1%, other 8.1%, English (major foreign language taught in schools), Arabic (2007 est.)',
    areaKM2: 1127127,
    internetHosts: 179,
    internetUsers: 447300,
    phonesMobile: 20524000,
    phonesLandline: 797500,
    GDP: 47340000000,
    englishShortName: 'Ethiopia',
    frenchShortName: "Éthiopie (l')",
    numeric: 231,
    japaneseShortName: 'エチオピア',
  },
  FK: {
    countryName: 'Falkland Islands',
    ISO2: 'FK',
    ISO3: 'FLK',
    topLevelDomain: 'fk',
    FIPS: 'FK',
    ISONumeric: 238,
    GeoNameID: 3474414,
    E164: 500,
    phoneCode: 500,
    Continent: 'South America',
    Capital: 'Stanley',
    timeZoneInCapital: 'Atlantic/Stanley',
    Currency: 'Pound',
    languageCodes: 'en-FK',
    Languages: 'English 89%, Spanish 7.7%, other 3.3% (2006 est.)',
    areaKM2: 12173,
    internetHosts: 110,
    internetUsers: 2900,
    phonesMobile: 3450,
    phonesLandline: 1980,
    GDP: 164500000,
    englishShortName: 'Falkland Islands (the) [Malvinas]',
    frenchShortName: 'Falkland (les Îles)/Malouines (les Îles)',
    numeric: 238,
    japaneseShortName: 'フォークランド諸島（マルビナス）',
  },
  FO: {
    countryName: 'Faroe Islands',
    ISO2: 'FO',
    ISO3: 'FRO',
    topLevelDomain: 'fo',
    FIPS: 'FO',
    ISONumeric: 234,
    GeoNameID: 2622320,
    E164: 298,
    phoneCode: 298,
    Continent: 'Europe',
    Capital: 'Torshavn',
    timeZoneInCapital: 'Atlantic/Faroe',
    Currency: 'Krone',
    languageCodes: 'fo,da-FO',
    Languages: 'Faroese (derived from Old Norse), Danish',
    areaKM2: 1399,
    internetHosts: 7575,
    internetUsers: 37500,
    phonesMobile: 61000,
    phonesLandline: 24000,
    GDP: 2320000000,
    englishShortName: 'Faroe Islands (the)',
    frenchShortName: 'Féroé (les Îles)',
    numeric: 234,
    japaneseShortName: 'フェロー諸島',
  },
  FJ: {
    countryName: 'Fiji',
    ISO2: 'FJ',
    ISO3: 'FJI',
    topLevelDomain: 'fj',
    FIPS: 'FJ',
    ISONumeric: 242,
    GeoNameID: 2205218,
    E164: 679,
    phoneCode: 679,
    Continent: 'Oceania',
    Capital: 'Suva',
    timeZoneInCapital: 'Pacific/Fiji',
    Currency: 'Dollar',
    languageCodes: 'en-FJ,fj',
    Languages: 'English (official), Fijian (official), Hindustani',
    areaKM2: 18270,
    internetHosts: 21739,
    internetUsers: 114200,
    phonesMobile: 858800,
    phonesLandline: 88400,
    GDP: 4218000000,
    englishShortName: 'Fiji',
    frenchShortName: 'Fidji (les)',
    numeric: 242,
    japaneseShortName: 'フィジー',
  },
  FI: {
    countryName: 'Finland',
    ISO2: 'FI',
    ISO3: 'FIN',
    topLevelDomain: 'fi',
    FIPS: 'FI',
    ISONumeric: 246,
    GeoNameID: 660013,
    E164: 358,
    phoneCode: 358,
    Continent: 'Europe',
    Capital: 'Helsinki',
    timeZoneInCapital: 'Europe/Helsinki',
    Currency: 'Euro',
    languageCodes: 'fi-FI,sv-FI,smn',
    Languages:
      'Finnish (official) 94.2%, Swedish (official) 5.5%, other (small Sami- and Russian-speaking minorities) 0.2% (2012 est.)',
    areaKM2: 337030,
    internetHosts: 4763000,
    internetUsers: 4393000,
    phonesMobile: 9320000,
    phonesLandline: 890000,
    GDP: 259600000000,
    englishShortName: 'Finland',
    frenchShortName: 'Finlande (la)',
    numeric: 246,
    japaneseShortName: 'フィンランド',
  },
  FR: {
    countryName: 'France',
    ISO2: 'FR',
    ISO3: 'FRA',
    topLevelDomain: 'fr',
    FIPS: 'FR',
    ISONumeric: 250,
    GeoNameID: 3017382,
    E164: 33,
    phoneCode: 33,
    Continent: 'Europe',
    Capital: 'Paris',
    timeZoneInCapital: 'Europe/Paris',
    Currency: 'Euro',
    languageCodes: 'fr-FR,frp,br,co,ca,eu,oc',
    Languages:
      'French (official) 100%, rapidly declining regional dialects and languages (Provencal, Breton, Alsatian, Corsican, Catalan, Basque, Flemish)',
    areaKM2: 547030,
    internetHosts: 17266000,
    internetUsers: 45262000,
    phonesMobile: 62280000,
    phonesLandline: 39290000,
    GDP: 2739000000000,
    englishShortName: 'France',
    frenchShortName: 'France (la)',
    numeric: 250,
    japaneseShortName: 'フランス',
  },
  PF: {
    countryName: 'French Polynesia',
    ISO2: 'PF',
    ISO3: 'PYF',
    topLevelDomain: 'pf',
    FIPS: 'FP',
    ISONumeric: 258,
    GeoNameID: 4030656,
    E164: 689,
    phoneCode: 689,
    Continent: 'Oceania',
    Capital: 'Papeete',
    timeZoneInCapital: 'Pacific/Tahiti',
    Currency: 'Franc',
    languageCodes: 'fr-PF,ty',
    Languages:
      'French (official) 61.1%, Polynesian (official) 31.4%, Asian languages 1.2%, other 0.3%, unspecified 6% (2002 census)',
    areaKM2: 4167,
    internetHosts: 37949,
    internetUsers: 120000,
    phonesMobile: 226000,
    phonesLandline: 55000,
    GDP: 5650000000,
    englishShortName: 'French Polynesia',
    frenchShortName: 'Polynésie française (la)',
    numeric: 258,
    japaneseShortName: 'フランス領ポリネシア',
  },
  GA: {
    countryName: 'Gabon',
    ISO2: 'GA',
    ISO3: 'GAB',
    topLevelDomain: 'ga',
    FIPS: 'GB',
    ISONumeric: 266,
    GeoNameID: 2400553,
    E164: 241,
    phoneCode: 241,
    Continent: 'Africa',
    Capital: 'Libreville',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'fr-GA',
    Languages: 'French (official), Fang, Myene, Nzebi, Bapounou/Eschira, Bandjabi',
    areaKM2: 267667,
    internetHosts: 127,
    internetUsers: 98800,
    phonesMobile: 2930000,
    phonesLandline: 17000,
    GDP: 19970000000,
    englishShortName: 'Gabon',
    frenchShortName: 'Gabon (le)',
    numeric: 266,
    japaneseShortName: 'ガボン',
  },
  GM: {
    countryName: 'Gambia',
    ISO2: 'GM',
    ISO3: 'GMB',
    topLevelDomain: 'gm',
    FIPS: 'GA',
    ISONumeric: 270,
    GeoNameID: 2413451,
    E164: 220,
    phoneCode: 220,
    Continent: 'Africa',
    Capital: 'Banjul',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Dalasi',
    languageCodes: 'en-GM,mnk,wof,wo,ff',
    Languages: 'English (official), Mandinka, Wolof, Fula, other indigenous vernaculars',
    areaKM2: 11300,
    internetHosts: 656,
    internetUsers: 130100,
    phonesMobile: 1526000,
    phonesLandline: 64200,
    GDP: 896000000,
    englishShortName: 'Gambia (the)',
    frenchShortName: 'Gambie (la)',
    numeric: 270,
    japaneseShortName: 'ガンビア',
  },
  GE: {
    countryName: 'Georgia',
    ISO2: 'GE',
    ISO3: 'GEO',
    topLevelDomain: 'ge',
    FIPS: 'GG',
    ISONumeric: 268,
    GeoNameID: 614540,
    E164: 995,
    phoneCode: 995,
    Continent: 'Asia',
    Capital: 'Tbilisi',
    timeZoneInCapital: 'Asia/Tbilisi',
    Currency: 'Lari',
    languageCodes: 'ka,ru,hy,az',
    Languages: 'Georgian (official) 71%, Russian 9%, Armenian 7%, Azeri 6%, other 7%',
    areaKM2: 69700,
    internetHosts: 357864,
    internetUsers: 1300000,
    phonesMobile: 4699000,
    phonesLandline: 1276000,
    GDP: 15950000000,
    englishShortName: 'Georgia',
    frenchShortName: 'Géorgie (la)',
    numeric: 268,
    japaneseShortName: 'ジョージア',
  },
  DE: {
    countryName: 'Germany',
    ISO2: 'DE',
    ISO3: 'DEU',
    topLevelDomain: 'de',
    FIPS: 'GM',
    ISONumeric: 276,
    GeoNameID: 2921044,
    E164: 49,
    phoneCode: 49,
    Continent: 'Europe',
    Capital: 'Berlin',
    timeZoneInCapital: 'Europe/Berlin',
    Currency: 'Euro',
    languageCodes: 'de',
    Languages: 'German (official)',
    areaKM2: 357021,
    internetHosts: 20043000,
    internetUsers: 65125000,
    phonesMobile: 107700000,
    phonesLandline: 50700000,
    GDP: 3593000000000,
    englishShortName: 'Germany',
    frenchShortName: "Allemagne (l')",
    numeric: 276,
    japaneseShortName: 'ドイツ',
  },
  GH: {
    countryName: 'Ghana',
    ISO2: 'GH',
    ISO3: 'GHA',
    topLevelDomain: 'gh',
    FIPS: 'GH',
    ISONumeric: 288,
    GeoNameID: 2300660,
    E164: 233,
    phoneCode: 233,
    Continent: 'Africa',
    Capital: 'Accra',
    timeZoneInCapital: 'Africa/Accra',
    Currency: 'Cedi',
    languageCodes: 'en-GH,ak,ee,tw',
    Languages:
      'Asante 14.8%, Ewe 12.7%, Fante 9.9%, Boron (Brong) 4.6%, Dagomba 4.3%, Dangme 4.3%, Dagarte (Dagaba) 3.7%, Akyem 3.4%, Ga 3.4%, Akuapem 2.9%, other (includes English (official)) 36.1% (2000 census)',
    areaKM2: 239460,
    internetHosts: 59086,
    internetUsers: 1297000,
    phonesMobile: 25618000,
    phonesLandline: 285000,
    GDP: 45550000000,
    englishShortName: 'Ghana',
    frenchShortName: 'Ghana (le)',
    numeric: 288,
    japaneseShortName: 'ガーナ',
  },
  GI: {
    countryName: 'Gibraltar',
    ISO2: 'GI',
    ISO3: 'GIB',
    topLevelDomain: 'gi',
    FIPS: 'GI',
    ISONumeric: 292,
    GeoNameID: 2411586,
    E164: 350,
    phoneCode: 350,
    Continent: 'Europe',
    Capital: 'Gibraltar',
    timeZoneInCapital: 'Europe/Gibraltar',
    Currency: 'Pound',
    languageCodes: 'en-GI,es,it,pt',
    Languages: 'English (used in schools and for official purposes), Spanish, Italian, Portuguese',
    areaKM2: 7,
    internetHosts: 3509,
    internetUsers: 20200,
    phonesMobile: 34750,
    phonesLandline: 23100,
    GDP: 1106000000,
    englishShortName: 'Gibraltar',
    frenchShortName: 'Gibraltar',
    numeric: 292,
    japaneseShortName: 'ジブラルタル',
  },
  GR: {
    countryName: 'Greece',
    ISO2: 'GR',
    ISO3: 'GRC',
    topLevelDomain: 'gr',
    FIPS: 'GR',
    ISONumeric: 300,
    GeoNameID: 390903,
    E164: 30,
    phoneCode: 30,
    Continent: 'Europe',
    Capital: 'Athens',
    timeZoneInCapital: 'Europe/Athens',
    Currency: 'Euro',
    languageCodes: 'el-GR,en,fr',
    Languages: 'Greek (official) 99%, other (includes English and French) 1%',
    areaKM2: 131940,
    internetHosts: 3201000,
    internetUsers: 4971000,
    phonesMobile: 13354000,
    phonesLandline: 5461000,
    GDP: 243300000000,
    englishShortName: 'Greece',
    frenchShortName: 'Grèce (la)',
    numeric: 300,
    japaneseShortName: 'ギリシャ',
  },
  GL: {
    countryName: 'Greenland',
    ISO2: 'GL',
    ISO3: 'GRL',
    topLevelDomain: 'gl',
    FIPS: 'GL',
    ISONumeric: 304,
    GeoNameID: 3425505,
    E164: 299,
    phoneCode: 299,
    Continent: 'North America',
    Capital: 'Nuuk',
    timeZoneInCapital: 'America/Godthab',
    Currency: 'Krone',
    languageCodes: 'kl,da-GL,en',
    Languages: 'Greenlandic (East Inuit) (official), Danish (official), English',
    areaKM2: 2166086,
    internetHosts: 15645,
    internetUsers: 36000,
    phonesMobile: 59455,
    phonesLandline: 18900,
    GDP: 2160000000,
    englishShortName: 'Greenland',
    frenchShortName: 'Groenland (le)',
    numeric: 304,
    japaneseShortName: 'グリーンランド',
  },
  GD: {
    countryName: 'Grenada',
    ISO2: 'GD',
    ISO3: 'GRD',
    topLevelDomain: 'gd',
    FIPS: 'GJ',
    ISONumeric: 308,
    GeoNameID: 3580239,
    E164: 1,
    phoneCode: '1-473',
    Continent: 'North America',
    Capital: "St. George's",
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-GD',
    Languages: 'English (official), French patois',
    areaKM2: 344,
    internetHosts: 80,
    internetUsers: 25000,
    phonesMobile: 128000,
    phonesLandline: 28500,
    GDP: 811000000,
    englishShortName: 'Grenada',
    frenchShortName: 'Grenade (la)',
    numeric: 308,
    japaneseShortName: 'グレナダ',
  },
  GU: {
    countryName: 'Guam',
    ISO2: 'GU',
    ISO3: 'GUM',
    topLevelDomain: 'gu',
    FIPS: 'GQ',
    ISONumeric: 316,
    GeoNameID: 4043988,
    E164: 1,
    phoneCode: '1-671',
    Continent: 'Oceania',
    Capital: 'Hagatna',
    timeZoneInCapital: 'Pacific/Guam',
    Currency: 'Dollar',
    languageCodes: 'en-GU,ch-GU',
    Languages:
      'English 43.6%, Filipino 21.2%, Chamorro 17.8%, other Pacific island languages 10%, Asian languages 6.3%, other 1.1% (2010 est.)',
    areaKM2: 549,
    internetHosts: 23,
    internetUsers: 90000,
    phonesMobile: 98000,
    phonesLandline: 67000,
    GDP: 4600000000,
    englishShortName: 'Guam',
    frenchShortName: 'Guam',
    numeric: 316,
    japaneseShortName: 'グアム島',
  },
  GT: {
    countryName: 'Guatemala',
    ISO2: 'GT',
    ISO3: 'GTM',
    topLevelDomain: 'gt',
    FIPS: 'GT',
    ISONumeric: 320,
    GeoNameID: 3595528,
    E164: 502,
    phoneCode: 502,
    Continent: 'North America',
    Capital: 'Guatemala City',
    timeZoneInCapital: 'America/Guatemala',
    Currency: 'Quetzal',
    languageCodes: 'es-GT',
    Languages: 'Spanish (official) 60%, Amerindian languages 40%',
    areaKM2: 108890,
    internetHosts: 357552,
    internetUsers: 2279000,
    phonesMobile: 20787000,
    phonesLandline: 1744000,
    GDP: 53900000000,
    englishShortName: 'Guatemala',
    frenchShortName: 'Guatemala (le)',
    numeric: 320,
    japaneseShortName: 'グアテマラ',
  },
  GG: {
    countryName: 'Guernsey',
    ISO2: 'GG',
    ISO3: 'GGY',
    topLevelDomain: 'gg',
    FIPS: 'GK',
    ISONumeric: 831,
    GeoNameID: 3042362,
    E164: 44,
    phoneCode: '44-1481',
    Continent: 'Europe',
    Capital: 'St Peter Port',
    timeZoneInCapital: 'Europe/London',
    Currency: 'Pound',
    languageCodes: 'en,fr',
    Languages: 'English, French, Norman-French dialect spoken in country districts',
    areaKM2: 78,
    internetHosts: 239,
    internetUsers: 48300,
    phonesMobile: 43800,
    phonesLandline: 45100,
    GDP: 2742000000,
    englishShortName: 'Guernsey',
    frenchShortName: 'Guernesey',
    numeric: 831,
    japaneseShortName: 'ガーンジー',
  },
  GN: {
    countryName: 'Guinea',
    ISO2: 'GN',
    ISO3: 'GIN',
    topLevelDomain: 'gn',
    FIPS: 'GV',
    ISONumeric: 324,
    GeoNameID: 2420477,
    E164: 224,
    phoneCode: 224,
    Continent: 'Africa',
    Capital: 'Conakry',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Franc',
    languageCodes: 'fr-GN',
    Languages: 'French (official)',
    areaKM2: 245857,
    internetHosts: 15,
    internetUsers: 95000,
    phonesMobile: 4781000,
    phonesLandline: 18000,
    GDP: 6544000000,
    englishShortName: 'Guinea',
    frenchShortName: 'Guinée (la)',
    numeric: 324,
    japaneseShortName: 'ギニア',
  },
  GW: {
    countryName: 'Guinea-Bissau',
    ISO2: 'GW',
    ISO3: 'GNB',
    topLevelDomain: 'gw',
    FIPS: 'PU',
    ISONumeric: 624,
    GeoNameID: 2372248,
    E164: 245,
    phoneCode: 245,
    Continent: 'Africa',
    Capital: 'Bissau',
    timeZoneInCapital: 'Africa/Bissau',
    Currency: 'Franc',
    languageCodes: 'pt-GW,pov',
    Languages: 'Portuguese (official), Crioulo, African languages',
    areaKM2: 36120,
    internetHosts: 90,
    internetUsers: 37100,
    phonesMobile: 1100000,
    phonesLandline: 5000,
    GDP: 880000000,
    englishShortName: 'Guinea-Bissau',
    frenchShortName: 'Guinée-Bissau (la)',
    numeric: 624,
    japaneseShortName: 'ギニアビサウ',
  },
  GY: {
    countryName: 'Guyana',
    ISO2: 'GY',
    ISO3: 'GUY',
    topLevelDomain: 'gy',
    FIPS: 'GY',
    ISONumeric: 328,
    GeoNameID: 3378535,
    E164: 592,
    phoneCode: 592,
    Continent: 'South America',
    Capital: 'Georgetown',
    timeZoneInCapital: 'America/Guyana',
    Currency: 'Dollar',
    languageCodes: 'en-GY',
    Languages: 'English, Amerindian dialects, Creole, Caribbean Hindustani (a dialect of Hindi), Urdu',
    areaKM2: 214970,
    internetHosts: 24936,
    internetUsers: 189600,
    phonesMobile: 547000,
    phonesLandline: 154200,
    GDP: 3020000000,
    englishShortName: 'Guyana',
    frenchShortName: 'Guyana (le)',
    numeric: 328,
    japaneseShortName: 'ガイアナ',
  },
  HT: {
    countryName: 'Haiti',
    ISO2: 'HT',
    ISO3: 'HTI',
    topLevelDomain: 'ht',
    FIPS: 'HA',
    ISONumeric: 332,
    GeoNameID: 3723988,
    E164: 509,
    phoneCode: 509,
    Continent: 'North America',
    Capital: 'Port-au-Prince',
    timeZoneInCapital: 'America/Port-au-Prince',
    Currency: 'Gourde',
    languageCodes: 'ht,fr-HT',
    Languages: 'French (official), Creole (official)',
    areaKM2: 27750,
    internetHosts: 555,
    internetUsers: 1000000,
    phonesMobile: 6095000,
    phonesLandline: 50000,
    GDP: 8287000000,
    englishShortName: 'Haiti',
    frenchShortName: 'Haïti',
    numeric: 332,
    japaneseShortName: 'ハイチ',
  },
  HN: {
    countryName: 'Honduras',
    ISO2: 'HN',
    ISO3: 'HND',
    topLevelDomain: 'hn',
    FIPS: 'HO',
    ISONumeric: 340,
    GeoNameID: 3608932,
    E164: 504,
    phoneCode: 504,
    Continent: 'North America',
    Capital: 'Tegucigalpa',
    timeZoneInCapital: 'America/Tegucigalpa',
    Currency: 'Lempira',
    languageCodes: 'es-HN',
    Languages: 'Spanish (official), Amerindian dialects',
    areaKM2: 112090,
    internetHosts: 30955,
    internetUsers: 731700,
    phonesMobile: 7370000,
    phonesLandline: 610000,
    GDP: 18880000000,
    englishShortName: 'Honduras',
    frenchShortName: 'Honduras (le)',
    numeric: 340,
    japaneseShortName: 'ホンジュラス',
  },
  HK: {
    countryName: 'Hong Kong',
    ISO2: 'HK',
    ISO3: 'HKG',
    topLevelDomain: 'hk',
    FIPS: 'HK',
    ISONumeric: 344,
    GeoNameID: 1819730,
    E164: 852,
    phoneCode: 852,
    Continent: 'Asia',
    Capital: 'Hong Kong',
    timeZoneInCapital: 'Asia/Hong_Kong',
    Currency: 'Dollar',
    languageCodes: 'zh-HK,yue,zh,en',
    Languages:
      'Cantonese (official) 89.5%, English (official) 3.5%, Putonghua (Mandarin) 1.4%, other Chinese dialects 4%, other 1.6% (2011 est.)',
    areaKM2: 1092,
    internetHosts: 870041,
    internetUsers: 4873000,
    phonesMobile: 16403000,
    phonesLandline: 4362000,
    GDP: 272100000000,
    englishShortName: 'Hong Kong',
    frenchShortName: 'Hong Kong',
    numeric: 344,
    japaneseShortName: '香港',
  },
  HU: {
    countryName: 'Hungary',
    ISO2: 'HU',
    ISO3: 'HUN',
    topLevelDomain: 'hu',
    FIPS: 'HU',
    ISONumeric: 348,
    GeoNameID: 719819,
    E164: 36,
    phoneCode: 36,
    Continent: 'Europe',
    Capital: 'Budapest',
    timeZoneInCapital: 'Europe/Budapest',
    Currency: 'Forint',
    languageCodes: 'hu-HU',
    Languages:
      'Hungarian (official) 99.6%, English 16%, German 11.2%, Russian 1.6%, Romanian 1.3%, French 1.2%, other 4.2%',
    areaKM2: 93030,
    internetHosts: 3145000,
    internetUsers: 6176000,
    phonesMobile: 11580000,
    phonesLandline: 2960000,
    GDP: 130600000000,
    englishShortName: 'Hungary',
    frenchShortName: 'Hongrie (la)',
    numeric: 348,
    japaneseShortName: 'ハンガリー',
  },
  IS: {
    countryName: 'Iceland',
    ISO2: 'IS',
    ISO3: 'ISL',
    topLevelDomain: 'is',
    FIPS: 'IC',
    ISONumeric: 352,
    GeoNameID: 2629691,
    E164: 354,
    phoneCode: 354,
    Continent: 'Europe',
    Capital: 'Reykjavik',
    timeZoneInCapital: 'Atlantic/Reykjavik',
    Currency: 'Krona',
    languageCodes: 'is,en,de,da,sv,no',
    Languages: 'Icelandic, English, Nordic languages, German widely spoken',
    areaKM2: 103000,
    internetHosts: 369969,
    internetUsers: 301600,
    phonesMobile: 346000,
    phonesLandline: 189000,
    GDP: 14590000000,
    englishShortName: 'Iceland',
    frenchShortName: "Islande (l')",
    numeric: 352,
    japaneseShortName: 'アイスランド',
  },
  IN: {
    countryName: 'India',
    ISO2: 'IN',
    ISO3: 'IND',
    topLevelDomain: 'in',
    FIPS: 'IN',
    ISONumeric: 356,
    GeoNameID: 1269750,
    E164: 91,
    phoneCode: 91,
    Continent: 'Asia',
    Capital: 'New Delhi',
    timeZoneInCapital: 'Asia/Kolkata',
    Currency: 'Rupee',
    languageCodes: 'en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc',
    Languages:
      'Hindi 41%, Bengali 8.1%, Telugu 7.2%, Marathi 7%, Tamil 5.9%, Urdu 5%, Gujarati 4.5%, Kannada 3.7%, Malayalam 3.2%, Oriya 3.2%, Punjabi 2.8%, Assamese 1.3%, Maithili 1.2%, other 5.9%',
    areaKM2: 3287590,
    internetHosts: 6746000,
    internetUsers: 61338000,
    phonesMobile: 893862000,
    phonesLandline: 31080000,
    GDP: 1670000000000,
    englishShortName: 'India',
    frenchShortName: "Inde (l')",
    numeric: 356,
    japaneseShortName: 'インド',
  },
  ID: {
    countryName: 'Indonesia',
    ISO2: 'ID',
    ISO3: 'IDN',
    topLevelDomain: 'id',
    FIPS: 'ID',
    ISONumeric: 360,
    GeoNameID: 1643084,
    E164: 62,
    phoneCode: 62,
    Continent: 'Asia',
    Capital: 'Jakarta',
    timeZoneInCapital: 'Asia/Jakarta',
    Currency: 'Rupiah',
    languageCodes: 'id,en,nl,jv',
    Languages:
      'Bahasa Indonesia (official, modified form of Malay), English, Dutch, local dialects (of which the most widely spoken is Javanese)',
    areaKM2: 1919440,
    internetHosts: 1344000,
    internetUsers: 20000000,
    phonesMobile: 281960000,
    phonesLandline: 37983000,
    GDP: 867500000000,
    englishShortName: 'Indonesia',
    frenchShortName: "Indonésie (l')",
    numeric: 360,
    japaneseShortName: 'インドネシア',
  },
  IR: {
    countryName: 'Iran',
    ISO2: 'IR',
    ISO3: 'IRN',
    topLevelDomain: 'ir',
    FIPS: 'IR',
    ISONumeric: 364,
    GeoNameID: 130758,
    E164: 98,
    phoneCode: 98,
    Continent: 'Asia',
    Capital: 'Tehran',
    timeZoneInCapital: 'Asia/Tehran',
    Currency: 'Rial',
    languageCodes: 'fa-IR,ku',
    Languages:
      'Persian (official) 53%, Azeri Turkic and Turkic dialects 18%, Kurdish 10%, Gilaki and Mazandarani 7%, Luri 6%, Balochi 2%, Arabic 2%, other 2%',
    areaKM2: 1648000,
    internetHosts: 197804,
    internetUsers: 8214000,
    phonesMobile: 58160000,
    phonesLandline: 28760000,
    GDP: 411900000000,
    englishShortName: 'Iran (Islamic Republic of)',
    frenchShortName: "Iran (République Islamique d')",
    numeric: 364,
    japaneseShortName: 'イラン',
  },
  IQ: {
    countryName: 'Iraq',
    ISO2: 'IQ',
    ISO3: 'IRQ',
    topLevelDomain: 'iq',
    FIPS: 'IZ',
    ISONumeric: 368,
    GeoNameID: 99237,
    E164: 964,
    phoneCode: 964,
    Continent: 'Asia',
    Capital: 'Baghdad',
    timeZoneInCapital: 'Asia/Baghdad',
    Currency: 'Dinar',
    languageCodes: 'ar-IQ,ku,hy',
    Languages:
      'Arabic (official), Kurdish (official), Turkmen (a Turkish dialect) and Assyrian (Neo-Aramaic) are official in areas where they constitute a majority of the population), Armenian',
    areaKM2: 437072,
    internetHosts: 26,
    internetUsers: 325900,
    phonesMobile: 26760000,
    phonesLandline: 1870000,
    GDP: 221800000000,
    englishShortName: 'Iraq',
    frenchShortName: "Iraq (l')",
    numeric: 368,
    japaneseShortName: 'イラク',
  },
  IE: {
    countryName: 'Ireland',
    ISO2: 'IE',
    ISO3: 'IRL',
    topLevelDomain: 'ie',
    FIPS: 'EI',
    ISONumeric: 372,
    GeoNameID: 2963597,
    E164: 353,
    phoneCode: 353,
    Continent: 'Europe',
    Capital: 'Dublin',
    timeZoneInCapital: 'Europe/Dublin',
    Currency: 'Euro',
    languageCodes: 'en-IE,ga-IE',
    Languages:
      'English (official, the language generally used), Irish (Gaelic or Gaeilge) (official, spoken mainly in areas along the western coast)',
    areaKM2: 70280,
    internetHosts: 1387000,
    internetUsers: 3042000,
    phonesMobile: 4906000,
    phonesLandline: 2007000,
    GDP: 220900000000,
    englishShortName: 'Ireland',
    frenchShortName: "Irlande (l')",
    numeric: 372,
    japaneseShortName: 'アイルランド',
  },
  IM: {
    countryName: 'Isle of Man',
    ISO2: 'IM',
    ISO3: 'IMN',
    topLevelDomain: 'im',
    FIPS: 'IM',
    ISONumeric: 833,
    GeoNameID: 3042225,
    E164: 44,
    phoneCode: '44-1624',
    Continent: 'Europe',
    Capital: 'Douglas, Isle of Man',
    timeZoneInCapital: 'Europe/London',
    Currency: 'Pound',
    languageCodes: 'en,gv',
    Languages: 'English, Manx Gaelic (about 2% of the population has some knowledge)',
    areaKM2: 572,
    internetHosts: 895,
    GDP: 4076000000,
    englishShortName: 'Isle of Man',
    frenchShortName: 'Île de Man',
    numeric: 833,
    japaneseShortName: 'マン島',
  },
  IL: {
    countryName: 'Israel',
    ISO2: 'IL',
    ISO3: 'ISR',
    topLevelDomain: 'il',
    FIPS: 'IS',
    ISONumeric: 376,
    GeoNameID: 294640,
    E164: 972,
    phoneCode: 972,
    Continent: 'Asia',
    Capital: 'Jerusalem',
    timeZoneInCapital: 'Asia/Jerusalem',
    Currency: 'Shekel',
    languageCodes: 'he,ar-IL,en-IL,',
    Languages:
      'Hebrew (official), Arabic (used officially for Arab minority), English (most commonly used foreign language)',
    areaKM2: 20770,
    internetHosts: 2483000,
    internetUsers: 4525000,
    phonesMobile: 9225000,
    phonesLandline: 3594000,
    GDP: 272700000000,
    englishShortName: 'Israel',
    frenchShortName: 'Israël',
    numeric: 376,
    japaneseShortName: 'イスラエル',
  },
  IT: {
    countryName: 'Italy',
    ISO2: 'IT',
    ISO3: 'ITA',
    topLevelDomain: 'it',
    FIPS: 'IT',
    ISONumeric: 380,
    GeoNameID: 3175395,
    E164: 39,
    phoneCode: 39,
    Continent: 'Europe',
    Capital: 'Rome',
    timeZoneInCapital: 'Europe/Rome',
    Currency: 'Euro',
    languageCodes: 'it-IT,de-IT,fr-IT,sc,ca,co,sl',
    Languages:
      "Italian (official), German (parts of Trentino-Alto Adige region are predominantly German-speaking), French (small French-speaking minority in Valle d'Aosta region), Slovene (Slovene-speaking minority in the Trieste-Gorizia area)",
    areaKM2: 301230,
    internetHosts: 25662000,
    internetUsers: 29235000,
    phonesMobile: 97225000,
    phonesLandline: 21656000,
    GDP: 2068000000000,
    englishShortName: 'Italy',
    frenchShortName: "Italie (l')",
    numeric: 380,
    japaneseShortName: 'イタリア',
  },
  CI: {
    countryName: 'Ivory Coast',
    ISO2: 'CI',
    ISO3: 'CIV',
    topLevelDomain: 'ci',
    FIPS: 'IV',
    ISONumeric: 384,
    GeoNameID: 2287781,
    E164: 225,
    phoneCode: 225,
    Continent: 'Africa',
    Capital: 'Yamoussoukro',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Franc',
    languageCodes: 'fr-CI',
    Languages: 'French (official), 60 native dialects of which Dioula is the most widely spoken',
    areaKM2: 322460,
    internetHosts: 9115,
    internetUsers: 967300,
    phonesMobile: 19827000,
    phonesLandline: 268000,
    GDP: 28280000000,
    englishShortName: "Côte d'Ivoire",
    frenchShortName: "Côte d'Ivoire (la)",
    numeric: 384,
    japaneseShortName: 'コートジボワール',
  },
  JM: {
    countryName: 'Jamaica',
    ISO2: 'JM',
    ISO3: 'JAM',
    topLevelDomain: 'jm',
    FIPS: 'JM',
    ISONumeric: 388,
    GeoNameID: 3489940,
    E164: 1,
    phoneCode: '1-876',
    Continent: 'North America',
    Capital: 'Kingston',
    timeZoneInCapital: 'America/Jamaica',
    Currency: 'Dollar',
    languageCodes: 'en-JM',
    Languages: 'English, English patois',
    areaKM2: 10991,
    internetHosts: 3906,
    internetUsers: 1581000,
    phonesMobile: 2665000,
    phonesLandline: 265000,
    GDP: 14390000000,
    englishShortName: 'Jamaica',
    frenchShortName: 'Jamaïque (la)',
    numeric: 388,
    japaneseShortName: 'ジャマイカ',
  },
  JP: {
    countryName: 'Japan',
    ISO2: 'JP',
    ISO3: 'JPN',
    topLevelDomain: 'jp',
    FIPS: 'JA',
    ISONumeric: 392,
    GeoNameID: 1861060,
    E164: 81,
    phoneCode: 81,
    Continent: 'Asia',
    Capital: 'Tokyo',
    timeZoneInCapital: 'Asia/Tokyo',
    Currency: 'Yen',
    languageCodes: 'ja',
    Languages: 'Japanese',
    areaKM2: 377835,
    internetHosts: 64453000,
    internetUsers: 99182000,
    phonesMobile: 138363000,
    phonesLandline: 64273000,
    GDP: 5007000000000,
    englishShortName: 'Japan',
    frenchShortName: 'Japon (le)',
    numeric: 392,
    japaneseShortName: '日本',
  },
  JE: {
    countryName: 'Jersey',
    ISO2: 'JE',
    ISO3: 'JEY',
    topLevelDomain: 'je',
    FIPS: 'JE',
    ISONumeric: 832,
    GeoNameID: 3042142,
    E164: 44,
    phoneCode: '44-1534',
    Continent: 'Europe',
    Capital: 'Saint Helier',
    timeZoneInCapital: 'Europe/London',
    Currency: 'Pound',
    languageCodes: 'en,pt',
    Languages: 'English 94.5% (official), Portuguese 4.6%, other 0.9% (2001 census)',
    areaKM2: 116,
    internetHosts: 264,
    internetUsers: 29500,
    phonesMobile: 108000,
    phonesLandline: 73800,
    GDP: 5100000000,
    englishShortName: 'Jersey',
    frenchShortName: 'Jersey',
    numeric: 832,
    japaneseShortName: 'ジャージー',
  },
  JO: {
    countryName: 'Jordan',
    ISO2: 'JO',
    ISO3: 'JOR',
    topLevelDomain: 'jo',
    FIPS: 'JO',
    ISONumeric: 400,
    GeoNameID: 248816,
    E164: 962,
    phoneCode: 962,
    Continent: 'Asia',
    Capital: 'Amman',
    timeZoneInCapital: 'Asia/Amman',
    Currency: 'Dinar',
    languageCodes: 'ar-JO,en',
    Languages: 'Arabic (official), English (widely understood among upper and middle classes)',
    areaKM2: 92300,
    internetHosts: 69473,
    internetUsers: 1642000,
    phonesMobile: 8984000,
    phonesLandline: 435000,
    GDP: 34080000000,
    englishShortName: 'Jordan',
    frenchShortName: 'Jordanie (la)',
    numeric: 400,
    japaneseShortName: 'ヨルダン',
  },
  KZ: {
    countryName: 'Kazakhstan',
    ISO2: 'KZ',
    ISO3: 'KAZ',
    topLevelDomain: 'kz',
    FIPS: 'KZ',
    ISONumeric: 398,
    GeoNameID: 1522867,
    E164: 7,
    phoneCode: 7,
    Continent: 'Asia',
    Capital: 'Astana',
    timeZoneInCapital: 'Asia/Almaty',
    Currency: 'Tenge',
    languageCodes: 'kk,ru',
    Languages:
      'Kazakh (official, Qazaq) 64.4%, Russian (official, used in everyday business, designated the "language of interethnic communication") 95% (2001 est.)',
    areaKM2: 2717300,
    internetHosts: 67464,
    internetUsers: 5299000,
    phonesMobile: 28731000,
    phonesLandline: 4340000,
    GDP: 224900000000,
    englishShortName: 'Kazakhstan',
    frenchShortName: 'Kazakhstan (le)',
    numeric: 398,
    japaneseShortName: 'カザフスタン',
  },
  KE: {
    countryName: 'Kenya',
    ISO2: 'KE',
    ISO3: 'KEN',
    topLevelDomain: 'ke',
    FIPS: 'KE',
    ISONumeric: 404,
    GeoNameID: 192950,
    E164: 254,
    phoneCode: 254,
    Continent: 'Africa',
    Capital: 'Nairobi',
    timeZoneInCapital: 'Africa/Nairobi',
    Currency: 'Shilling',
    languageCodes: 'en-KE,sw-KE',
    Languages: 'English (official), Kiswahili (official), numerous indigenous languages',
    areaKM2: 582650,
    internetHosts: 71018,
    internetUsers: 3996000,
    phonesMobile: 30732000,
    phonesLandline: 251600,
    GDP: 45310000000,
    englishShortName: 'Kenya',
    frenchShortName: 'Kenya (le)',
    numeric: 404,
    japaneseShortName: 'ケニア',
  },
  KI: {
    countryName: 'Kiribati',
    ISO2: 'KI',
    ISO3: 'KIR',
    topLevelDomain: 'ki',
    FIPS: 'KR',
    ISONumeric: 296,
    GeoNameID: 4030945,
    E164: 686,
    phoneCode: 686,
    Continent: 'Oceania',
    Capital: 'Tarawa',
    timeZoneInCapital: 'Pacific/Tarawa',
    Currency: 'Dollar',
    languageCodes: 'en-KI,gil',
    Languages: 'I-Kiribati, English (official)',
    areaKM2: 811,
    internetHosts: 327,
    internetUsers: 7800,
    phonesMobile: 16000,
    phonesLandline: 9000,
    GDP: 173000000,
    englishShortName: 'Kiribati',
    frenchShortName: 'Kiribati',
    numeric: 296,
    japaneseShortName: 'キリバス',
  },
  // XK: {
  //   countryName: 'Kosovo',
  //   ISO2: 'XK',
  //   ISO3: 'XKX',
  //   topLevelDomain: null,
  //   FIPS: 'KV',
  //   ISONumeric: 0,
  //   GeoNameID: 831053,
  //   E164: 383,
  //   phoneCode: 383,
  //   Continent: 'Europe',
  //   Capital: 'Pristina',
  //   timeZoneInCapital: 'Europe/Belgrade',
  //   Currency: 'Euro',
  //   languageCodes: 'sq,sr',
  //   Languages:
  //     'Albanian (official), Serbian (official), Bosnian, Turkish, Roma',
  //   areaKM2: 10887,
  //   internetHosts: null,
  //   internetUsers: null,
  //   phonesMobile: 562000,
  //   phonesLandline: 106300,
  //   GDP: 7150000000,
  // },
  KW: {
    countryName: 'Kuwait',
    ISO2: 'KW',
    ISO3: 'KWT',
    topLevelDomain: 'kw',
    FIPS: 'KU',
    ISONumeric: 414,
    GeoNameID: 285570,
    E164: 965,
    phoneCode: 965,
    Continent: 'Asia',
    Capital: 'Kuwait City',
    timeZoneInCapital: 'Asia/Kuwait',
    Currency: 'Dinar',
    languageCodes: 'ar-KW,en',
    Languages: 'Arabic (official), English widely spoken',
    areaKM2: 17820,
    internetHosts: 2771,
    internetUsers: 1100000,
    phonesMobile: 5526000,
    phonesLandline: 510000,
    GDP: 179500000000,
    englishShortName: 'Kuwait',
    frenchShortName: 'Koweït (le)',
    numeric: 414,
    japaneseShortName: 'クウェート',
  },
  KG: {
    countryName: 'Kyrgyzstan',
    ISO2: 'KG',
    ISO3: 'KGZ',
    topLevelDomain: 'kg',
    FIPS: 'KG',
    ISONumeric: 417,
    GeoNameID: 1527747,
    E164: 996,
    phoneCode: 996,
    Continent: 'Asia',
    Capital: 'Bishkek',
    timeZoneInCapital: 'Asia/Bishkek',
    Currency: 'Som',
    languageCodes: 'ky,uz,ru',
    Languages: 'Kyrgyz (official) 64.7%, Uzbek 13.6%, Russian (official) 12.5%, Dungun 1%, other 8.2% (1999 census)',
    areaKM2: 198500,
    internetHosts: 115573,
    internetUsers: 2195000,
    phonesMobile: 6800000,
    phonesLandline: 489000,
    GDP: 7234000000,
    englishShortName: 'Kyrgyzstan',
    frenchShortName: 'Kirghizistan (le)',
    numeric: 417,
    japaneseShortName: 'キルギス',
  },
  LA: {
    countryName: 'Laos',
    ISO2: 'LA',
    ISO3: 'LAO',
    topLevelDomain: 'la',
    FIPS: 'LA',
    ISONumeric: 418,
    GeoNameID: 1655842,
    E164: 856,
    phoneCode: 856,
    Continent: 'Asia',
    Capital: 'Vientiane',
    timeZoneInCapital: 'Asia/Vientiane',
    Currency: 'Kip',
    languageCodes: 'lo,fr,en',
    Languages: 'Lao (official), French, English, various ethnic languages',
    areaKM2: 236800,
    internetHosts: 1532,
    internetUsers: 300000,
    phonesMobile: 6492000,
    phonesLandline: 112000,
    GDP: 10100000000,
    englishShortName: "Lao People's Democratic Republic (the)",
    frenchShortName: 'Lao (la République démocratique populaire)',
    numeric: 418,
    japaneseShortName: 'ラオス',
  },
  LV: {
    countryName: 'Latvia',
    ISO2: 'LV',
    ISO3: 'LVA',
    topLevelDomain: 'lv',
    FIPS: 'LG',
    ISONumeric: 428,
    GeoNameID: 458258,
    E164: 371,
    phoneCode: 371,
    Continent: 'Europe',
    Capital: 'Riga',
    timeZoneInCapital: 'Europe/Riga',
    Currency: 'Euro',
    languageCodes: 'lv,ru,lt',
    Languages:
      'Latvian (official) 56.3%, Russian 33.8%, other 0.6% (includes Polish, Ukrainian, and Belarusian), unspecified 9.4% (2011 est.)',
    areaKM2: 64589,
    internetHosts: 359604,
    internetUsers: 1504000,
    phonesMobile: 2310000,
    phonesLandline: 501000,
    GDP: 30380000000,
    englishShortName: 'Latvia',
    frenchShortName: 'Lettonie (la)',
    numeric: 428,
    japaneseShortName: 'ラトビア',
  },
  LB: {
    countryName: 'Lebanon',
    ISO2: 'LB',
    ISO3: 'LBN',
    topLevelDomain: 'lb',
    FIPS: 'LE',
    ISONumeric: 422,
    GeoNameID: 272103,
    E164: 961,
    phoneCode: 961,
    Continent: 'Asia',
    Capital: 'Beirut',
    timeZoneInCapital: 'Asia/Beirut',
    Currency: 'Pound',
    languageCodes: 'ar-LB,fr-LB,en,hy',
    Languages: 'Arabic (official), French, English, Armenian',
    areaKM2: 10400,
    internetHosts: 64926,
    internetUsers: 1000000,
    phonesMobile: 4000000,
    phonesLandline: 878000,
    GDP: 43490000000,
    englishShortName: 'Lebanon',
    frenchShortName: 'Liban (le)',
    numeric: 422,
    japaneseShortName: 'レバノン',
  },
  LS: {
    countryName: 'Lesotho',
    ISO2: 'LS',
    ISO3: 'LSO',
    topLevelDomain: 'ls',
    FIPS: 'LT',
    ISONumeric: 426,
    GeoNameID: 932692,
    E164: 266,
    phoneCode: 266,
    Continent: 'Africa',
    Capital: 'Maseru',
    timeZoneInCapital: 'Africa/Johannesburg',
    Currency: 'Loti',
    languageCodes: 'en-LS,st,zu,xh',
    Languages: 'Sesotho (official) (southern Sotho), English (official), Zulu, Xhosa',
    areaKM2: 30355,
    internetHosts: 11030,
    internetUsers: 76800,
    phonesMobile: 1312000,
    phonesLandline: 43100,
    GDP: 2457000000,
    englishShortName: 'Lesotho',
    frenchShortName: 'Lesotho (le)',
    numeric: 426,
    japaneseShortName: 'レソト',
  },
  LR: {
    countryName: 'Liberia',
    ISO2: 'LR',
    ISO3: 'LBR',
    topLevelDomain: 'lr',
    FIPS: 'LI',
    ISONumeric: 430,
    GeoNameID: 2275384,
    E164: 231,
    phoneCode: 231,
    Continent: 'Africa',
    Capital: 'Monrovia',
    timeZoneInCapital: 'Africa/Monrovia',
    Currency: 'Dollar',
    languageCodes: 'en-LR',
    Languages:
      'English 20% (official), some 20 ethnic group languages few of which can be written or used in correspondence',
    areaKM2: 111370,
    internetHosts: 7,
    internetUsers: 20000,
    phonesMobile: 2394000,
    phonesLandline: 3200,
    GDP: 1977000000,
    englishShortName: 'Liberia',
    frenchShortName: 'Libéria (le)',
    numeric: 430,
    japaneseShortName: 'リベリア',
  },
  LY: {
    countryName: 'Libya',
    ISO2: 'LY',
    ISO3: 'LBY',
    topLevelDomain: 'ly',
    FIPS: 'LY',
    ISONumeric: 434,
    GeoNameID: 2215636,
    E164: 218,
    phoneCode: 218,
    Continent: 'Africa',
    Capital: 'Tripolis',
    timeZoneInCapital: 'Africa/Tripoli',
    Currency: 'Dinar',
    languageCodes: 'ar-LY,it,en',
    Languages:
      'Arabic (official), Italian, English (all widely understood in the major cities); Berber (Nafusi, Ghadamis, Suknah, Awjilah, Tamasheq)',
    areaKM2: 1759540,
    internetHosts: 17926,
    internetUsers: 353900,
    phonesMobile: 9590000,
    phonesLandline: 814000,
    GDP: 70920000000,
    englishShortName: 'Libya',
    frenchShortName: 'Libye (la)',
    numeric: 434,
    japaneseShortName: 'リビア国',
  },
  LI: {
    countryName: 'Liechtenstein',
    ISO2: 'LI',
    ISO3: 'LIE',
    topLevelDomain: 'li',
    FIPS: 'LS',
    ISONumeric: 438,
    GeoNameID: 3042058,
    E164: 423,
    phoneCode: 423,
    Continent: 'Europe',
    Capital: 'Vaduz',
    timeZoneInCapital: 'Europe/Zurich',
    Currency: 'Franc',
    languageCodes: 'de-LI',
    Languages: 'German 94.5% (official) (Alemannic is the main dialect), Italian 1.1%, other 4.3% (2010 est.)',
    areaKM2: 160,
    internetHosts: 14278,
    internetUsers: 23000,
    phonesMobile: 38000,
    phonesLandline: 20000,
    GDP: 5113000000,
    englishShortName: 'Liechtenstein',
    frenchShortName: 'Liechtenstein (le)',
    numeric: 438,
    japaneseShortName: 'リヒテンシュタイン',
  },
  LT: {
    countryName: 'Lithuania',
    ISO2: 'LT',
    ISO3: 'LTU',
    topLevelDomain: 'lt',
    FIPS: 'LH',
    ISONumeric: 440,
    GeoNameID: 597427,
    E164: 370,
    phoneCode: 370,
    Continent: 'Europe',
    Capital: 'Vilnius',
    timeZoneInCapital: 'Europe/Vilnius',
    Currency: 'Euro',
    languageCodes: 'lt,ru,pl',
    Languages: 'Lithuanian (official) 82%, Russian 8%, Polish 5.6%, other 0.9%, unspecified 3.5% (2011 est.)',
    areaKM2: 65200,
    internetHosts: 1205000,
    internetUsers: 1964000,
    phonesMobile: 5000000,
    phonesLandline: 667300,
    GDP: 46710000000,
    englishShortName: 'Lithuania',
    frenchShortName: 'Lituanie (la)',
    numeric: 440,
    japaneseShortName: 'リトアニア',
  },
  LU: {
    countryName: 'Luxembourg',
    ISO2: 'LU',
    ISO3: 'LUX',
    topLevelDomain: 'lu',
    FIPS: 'LU',
    ISONumeric: 442,
    GeoNameID: 2960313,
    E164: 352,
    phoneCode: 352,
    Continent: 'Europe',
    Capital: 'Luxembourg',
    timeZoneInCapital: 'Europe/Luxembourg',
    Currency: 'Euro',
    languageCodes: 'lb,de-LU,fr-LU',
    Languages:
      'Luxembourgish (official administrative language and national language (spoken vernacular)), French (official administrative language), German (official administrative language)',
    areaKM2: 2586,
    internetHosts: 250900,
    internetUsers: 424500,
    phonesMobile: 761300,
    phonesLandline: 266700,
    GDP: 60540000000,
    englishShortName: 'Luxembourg',
    frenchShortName: 'Luxembourg (le)',
    numeric: 442,
    japaneseShortName: 'ルクセンブルク',
  },
  MO: {
    countryName: 'Macau',
    ISO2: 'MO',
    ISO3: 'MAC',
    topLevelDomain: 'mo',
    FIPS: 'MC',
    ISONumeric: 446,
    GeoNameID: 1821275,
    E164: 853,
    phoneCode: 853,
    Continent: 'Asia',
    Capital: 'Macao',
    timeZoneInCapital: 'Asia/Macau',
    Currency: 'Pataca',
    languageCodes: 'zh,zh-MO,pt',
    Languages:
      'Cantonese 83.3%, Mandarin 5%, Hokkien 3.7%, English 2.3%, other Chinese dialects 2%, Tagalog 1.7%, Portuguese 0.7%, other 1.3%',
    areaKM2: 254,
    internetHosts: 327,
    internetUsers: 270200,
    phonesMobile: 1613000,
    phonesLandline: 162500,
    GDP: 51680000000,
    englishShortName: 'Macao',
    frenchShortName: 'Macao',
    numeric: 446,
    japaneseShortName: 'マカオ',
  },
  MK: {
    countryName: 'Macedonia',
    ISO2: 'MK',
    ISO3: 'MKD',
    topLevelDomain: 'mk',
    FIPS: 'MK',
    ISONumeric: 807,
    GeoNameID: 718075,
    E164: 389,
    phoneCode: 389,
    Continent: 'Europe',
    Capital: 'Skopje',
    timeZoneInCapital: 'Europe/Belgrade',
    Currency: 'Denar',
    languageCodes: 'mk,sq,tr,rmm,sr',
    Languages:
      'Macedonian (official) 66.5%, Albanian (official) 25.1%, Turkish 3.5%, Roma 1.9%, Serbian 1.2%, other 1.8% (2002 census)',
    areaKM2: 25333,
    internetHosts: 62826,
    internetUsers: 1057000,
    phonesMobile: 2235000,
    phonesLandline: 407900,
    GDP: 10650000000,
    englishShortName: 'North Macedonia',
    frenchShortName: 'Macédoine du Nord (la)',
    numeric: 807,
    japaneseShortName: 'マケドニア',
  },
  MG: {
    countryName: 'Madagascar',
    ISO2: 'MG',
    ISO3: 'MDG',
    topLevelDomain: 'mg',
    FIPS: 'MA',
    ISONumeric: 450,
    GeoNameID: 1062947,
    E164: 261,
    phoneCode: 261,
    Continent: 'Africa',
    Capital: 'Antananarivo',
    timeZoneInCapital: 'Indian/Antananarivo',
    Currency: 'Ariary',
    languageCodes: 'fr-MG,mg',
    Languages: 'French (official), Malagasy (official), English',
    areaKM2: 587040,
    internetHosts: 38392,
    internetUsers: 319900,
    phonesMobile: 8564000,
    phonesLandline: 143700,
    GDP: 10530000000,
    englishShortName: 'Madagascar',
    frenchShortName: 'Madagascar',
    numeric: 450,
    japaneseShortName: 'マダガスカル',
  },
  MW: {
    countryName: 'Malawi',
    ISO2: 'MW',
    ISO3: 'MWI',
    topLevelDomain: 'mw',
    FIPS: 'MI',
    ISONumeric: 454,
    GeoNameID: 927384,
    E164: 265,
    phoneCode: 265,
    Continent: 'Africa',
    Capital: 'Lilongwe',
    timeZoneInCapital: 'Africa/Maputo',
    Currency: 'Kwacha',
    languageCodes: 'ny,yao,tum,swk',
    Languages:
      'English (official), Chichewa (common), Chinyanja, Chiyao, Chitumbuka, Chilomwe, Chinkhonde, Chingoni, Chisena, Chitonga, Chinyakyusa, Chilambya',
    areaKM2: 118480,
    internetHosts: 1099,
    internetUsers: 716400,
    phonesMobile: 4420000,
    phonesLandline: 227300,
    GDP: 3683000000,
    englishShortName: 'Malawi',
    frenchShortName: 'Malawi (le)',
    numeric: 454,
    japaneseShortName: 'マラウイ',
  },
  MY: {
    countryName: 'Malaysia',
    ISO2: 'MY',
    ISO3: 'MYS',
    topLevelDomain: 'my',
    FIPS: 'MY',
    ISONumeric: 458,
    GeoNameID: 1733045,
    E164: 60,
    phoneCode: 60,
    Continent: 'Asia',
    Capital: 'Kuala Lumpur',
    timeZoneInCapital: 'Asia/Kuala_Lumpur',
    Currency: 'Ringgit',
    languageCodes: 'ms-MY,en,zh,ta,te,ml,pa,th',
    Languages:
      'Bahasa Malaysia (official), English, Chinese (Cantonese, Mandarin, Hokkien, Hakka, Hainan, Foochow), Tamil, Telugu, Malayalam, Panjabi, Thai',
    areaKM2: 329750,
    internetHosts: 422470,
    internetUsers: 15355000,
    phonesMobile: 41325000,
    phonesLandline: 4589000,
    GDP: 312400000000,
    englishShortName: 'Malaysia',
    frenchShortName: 'Malaisie (la)',
    numeric: 458,
    japaneseShortName: 'マレーシア',
  },
  MV: {
    countryName: 'Maldives',
    ISO2: 'MV',
    ISO3: 'MDV',
    topLevelDomain: 'mv',
    FIPS: 'MV',
    ISONumeric: 462,
    GeoNameID: 1282028,
    E164: 960,
    phoneCode: 960,
    Continent: 'Asia',
    Capital: 'Male',
    timeZoneInCapital: 'Indian/Maldives',
    Currency: 'Rufiyaa',
    languageCodes: 'dv,en',
    Languages:
      'Dhivehi (official, dialect of Sinhala, script derived from Arabic), English (spoken by most government officials)',
    areaKM2: 300,
    internetHosts: 3296,
    internetUsers: 86400,
    phonesMobile: 560000,
    phonesLandline: 23140,
    GDP: 2270000000,
    englishShortName: 'Maldives',
    frenchShortName: 'Maldives (les)',
    numeric: 462,
    japaneseShortName: 'モルディブ',
  },
  ML: {
    countryName: 'Mali',
    ISO2: 'ML',
    ISO3: 'MLI',
    topLevelDomain: 'ml',
    FIPS: 'ML',
    ISONumeric: 466,
    GeoNameID: 2453866,
    E164: 223,
    phoneCode: 223,
    Continent: 'Africa',
    Capital: 'Bamako',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Franc',
    languageCodes: 'fr-ML,bm',
    Languages:
      'French (official), Bambara 46.3%, Peul/foulfoulbe 9.4%, Dogon 7.2%, Maraka/soninke 6.4%, Malinke 5.6%, Sonrhai/djerma 5.6%, Minianka 4.3%, Tamacheq 3.5%, Senoufo 2.6%, unspecified 0.6%, other 8.5%',
    areaKM2: 1240000,
    internetHosts: 437,
    internetUsers: 249800,
    phonesMobile: 14613000,
    phonesLandline: 112000,
    GDP: 11370000000,
    englishShortName: 'Mali',
    frenchShortName: 'Mali (le)',
    numeric: 466,
    japaneseShortName: 'マリ',
  },
  MT: {
    countryName: 'Malta',
    ISO2: 'MT',
    ISO3: 'MLT',
    topLevelDomain: 'mt',
    FIPS: 'MT',
    ISONumeric: 470,
    GeoNameID: 2562770,
    E164: 356,
    phoneCode: 356,
    Continent: 'Europe',
    Capital: 'Valletta',
    timeZoneInCapital: 'Europe/Malta',
    Currency: 'Euro',
    languageCodes: 'mt,en-MT',
    Languages: 'Maltese (official) 90.1%, English (official) 6%, multilingual 3%, other 0.9% (2005 est.)',
    areaKM2: 316,
    internetHosts: 14754,
    internetUsers: 240600,
    phonesMobile: 539500,
    phonesLandline: 229700,
    GDP: 9541000000,
    englishShortName: 'Malta',
    frenchShortName: 'Malte',
    numeric: 470,
    japaneseShortName: 'マルタ',
  },
  MH: {
    countryName: 'Marshall Islands',
    ISO2: 'MH',
    ISO3: 'MHL',
    topLevelDomain: 'mh',
    FIPS: 'RM',
    ISONumeric: 584,
    GeoNameID: 2080185,
    E164: 692,
    phoneCode: 692,
    Continent: 'Oceania',
    Capital: 'Majuro',
    timeZoneInCapital: 'Pacific/Majuro',
    Currency: 'Dollar',
    languageCodes: 'mh,en-MH',
    Languages: 'Marshallese (official) 98.2%, other languages 1.8% (1999 census)',
    areaKM2: 181,
    internetHosts: 3,
    internetUsers: 2200,
    phonesMobile: 3800,
    phonesLandline: 4400,
    GDP: 193000000,
    englishShortName: 'Marshall Islands (the)',
    frenchShortName: 'Marshall (les Îles)',
    numeric: 584,
    japaneseShortName: 'マーシャル諸島',
  },
  MR: {
    countryName: 'Mauritania',
    ISO2: 'MR',
    ISO3: 'MRT',
    topLevelDomain: 'mr',
    FIPS: 'MR',
    ISONumeric: 478,
    GeoNameID: 2378080,
    E164: 222,
    phoneCode: 222,
    Continent: 'Africa',
    Capital: 'Nouakchott',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Ouguiya',
    languageCodes: 'ar-MR,fuc,snk,fr,mey,wo',
    Languages:
      'Arabic (official and national), Pulaar, Soninke, Wolof (all national languages), French, Hassaniya (a variety of Arabic)',
    areaKM2: 1030700,
    internetHosts: 22,
    internetUsers: 75000,
    phonesMobile: 4024000,
    phonesLandline: 65100,
    GDP: 4183000000,
    englishShortName: 'Mauritania',
    frenchShortName: 'Mauritanie (la)',
    numeric: 478,
    japaneseShortName: 'モーリタニア',
  },
  MU: {
    countryName: 'Mauritius',
    ISO2: 'MU',
    ISO3: 'MUS',
    topLevelDomain: 'mu',
    FIPS: 'MP',
    ISONumeric: 480,
    GeoNameID: 934292,
    E164: 230,
    phoneCode: 230,
    Continent: 'Africa',
    Capital: 'Port Louis',
    timeZoneInCapital: 'Indian/Mauritius',
    Currency: 'Rupee',
    languageCodes: 'en-MU,bho,fr',
    Languages:
      'Creole 86.5%, Bhojpuri 5.3%, French 4.1%, two languages 1.4%, other 2.6% (includes English, the official language, which is spoken by less than 1% of the population), unspecified 0.1% (2011 est.)',
    areaKM2: 2040,
    internetHosts: 51139,
    internetUsers: 290000,
    phonesMobile: 1485000,
    phonesLandline: 349100,
    GDP: 11900000000,
    englishShortName: 'Mauritius',
    frenchShortName: 'Maurice',
    numeric: 480,
    japaneseShortName: 'モーリシャス',
  },
  YT: {
    countryName: 'Mayotte',
    ISO2: 'YT',
    ISO3: 'MYT',
    topLevelDomain: 'yt',
    FIPS: 'MF',
    ISONumeric: 175,
    GeoNameID: 1024031,
    E164: 262,
    phoneCode: 262,
    Continent: 'Africa',
    Capital: 'Mamoudzou',
    timeZoneInCapital: 'Indian/Mayotte',
    Currency: 'Euro',
    languageCodes: 'fr-YT',
    Languages: 'French',
    areaKM2: 374,
    englishShortName: 'Mayotte',
    frenchShortName: 'Mayotte',
    numeric: 175,
    japaneseShortName: 'マヨット',
  },
  MX: {
    countryName: 'Mexico',
    ISO2: 'MX',
    ISO3: 'MEX',
    topLevelDomain: 'mx',
    FIPS: 'MX',
    ISONumeric: 484,
    GeoNameID: 3996063,
    E164: 52,
    phoneCode: 52,
    Continent: 'North America',
    Capital: 'Mexico City',
    timeZoneInCapital: 'America/Mexico_City',
    Currency: 'Peso',
    languageCodes: 'es-MX',
    Languages: 'Spanish only 92.7%, Spanish and indigenous languages 5.7%, indigenous only 0.8%, unspecified 0.8%',
    areaKM2: 1972550,
    internetHosts: 16233000,
    internetUsers: 31020000,
    phonesMobile: 100786000,
    phonesLandline: 20220000,
    GDP: 1327000000000,
    englishShortName: 'Mexico',
    frenchShortName: 'Mexique (le)',
    numeric: 484,
    japaneseShortName: 'メキシコ',
  },
  FM: {
    countryName: 'Micronesia',
    ISO2: 'FM',
    ISO3: 'FSM',
    topLevelDomain: 'fm',
    FIPS: 'FM',
    ISONumeric: 583,
    GeoNameID: 2081918,
    E164: 691,
    phoneCode: 691,
    Continent: 'Oceania',
    Capital: 'Palikir',
    timeZoneInCapital: 'Pacific/Pohnpei',
    Currency: 'Dollar',
    languageCodes: 'en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg',
    Languages:
      'English (official and common language), Chuukese, Kosrean, Pohnpeian, Yapese, Ulithian, Woleaian, Nukuoro, Kapingamarangi',
    areaKM2: 702,
    internetHosts: 4668,
    internetUsers: 17000,
    phonesMobile: 27600,
    phonesLandline: 8400,
    GDP: 339000000,
    englishShortName: 'Micronesia (Federated States of)',
    frenchShortName: 'Micronésie (États fédérés de)',
    numeric: 583,
    japaneseShortName: 'ミクロネシア連邦',
  },
  MD: {
    countryName: 'Moldova',
    ISO2: 'MD',
    ISO3: 'MDA',
    topLevelDomain: 'md',
    FIPS: 'MD',
    ISONumeric: 498,
    GeoNameID: 617790,
    E164: 373,
    phoneCode: 373,
    Continent: 'Europe',
    Capital: 'Chisinau',
    timeZoneInCapital: 'Europe/Chisinau',
    Currency: 'Leu',
    languageCodes: 'ro,ru,gag,tr',
    Languages:
      'Moldovan 58.8% (official; virtually the same as the Romanian language), Romanian 16.4%, Russian 16%, Ukrainian 3.8%, Gagauz 3.1% (a Turkish language), Bulgarian 1.1%, other 0.3%, unspecified 0.4%',
    areaKM2: 33843,
    internetHosts: 711564,
    internetUsers: 1333000,
    phonesMobile: 4080000,
    phonesLandline: 1206000,
    GDP: 7932000000,
    englishShortName: 'Moldova (the Republic of)',
    frenchShortName: 'Moldova (la République de)',
    numeric: 498,
    japaneseShortName: 'モルドバ共和国',
  },
  MC: {
    countryName: 'Monaco',
    ISO2: 'MC',
    ISO3: 'MCO',
    topLevelDomain: 'mc',
    FIPS: 'MN',
    ISONumeric: 492,
    GeoNameID: 2993457,
    E164: 377,
    phoneCode: 377,
    Continent: 'Europe',
    Capital: 'Monaco',
    timeZoneInCapital: 'Europe/Monaco',
    Currency: 'Euro',
    languageCodes: 'fr-MC,en,it',
    Languages: 'French (official), English, Italian, Monegasque',
    areaKM2: 2,
    internetHosts: 26009,
    internetUsers: 23000,
    phonesMobile: 33200,
    phonesLandline: 44500,
    GDP: 5748000000,
    englishShortName: 'Monaco',
    frenchShortName: 'Monaco',
    numeric: 492,
    japaneseShortName: 'モナコ',
  },
  MN: {
    countryName: 'Mongolia',
    ISO2: 'MN',
    ISO3: 'MNG',
    topLevelDomain: 'mn',
    FIPS: 'MG',
    ISONumeric: 496,
    GeoNameID: 2029969,
    E164: 976,
    phoneCode: 976,
    Continent: 'Asia',
    Capital: 'Ulan Bator',
    timeZoneInCapital: 'Asia/Ulaanbaatar',
    Currency: 'Tugrik',
    languageCodes: 'mn,ru',
    Languages: 'Khalkha Mongol 90% (official), Turkic, Russian (1999)',
    areaKM2: 1565000,
    internetHosts: 20084,
    internetUsers: 330000,
    phonesMobile: 3375000,
    phonesLandline: 176700,
    GDP: 11140000000,
    englishShortName: 'Mongolia',
    frenchShortName: 'Mongolie (la)',
    numeric: 496,
    japaneseShortName: 'モンゴル',
  },
  ME: {
    countryName: 'Montenegro',
    ISO2: 'ME',
    ISO3: 'MNE',
    topLevelDomain: 'me',
    FIPS: 'MJ',
    ISONumeric: 499,
    GeoNameID: 3194884,
    E164: 382,
    phoneCode: 382,
    Continent: 'Europe',
    Capital: 'Podgorica',
    timeZoneInCapital: 'Europe/Belgrade',
    Currency: 'Euro',
    languageCodes: 'sr,hu,bs,sq,hr,rom',
    Languages:
      'Serbian 42.9%, Montenegrin (official) 37%, Bosnian 5.3%, Albanian 5.3%, Serbo-Croat 2%, other 3.5%, unspecified 4% (2011 est.)',
    areaKM2: 14026,
    internetHosts: 10088,
    internetUsers: 280000,
    phonesMobile: 1126000,
    phonesLandline: 163000,
    GDP: 4518000000,
    englishShortName: 'Montenegro',
    frenchShortName: 'Monténégro (le)',
    numeric: 499,
    japaneseShortName: 'モンテネグロ',
  },
  MS: {
    countryName: 'Montserrat',
    ISO2: 'MS',
    ISO3: 'MSR',
    topLevelDomain: 'ms',
    FIPS: 'MH',
    ISONumeric: 500,
    GeoNameID: 3578097,
    E164: 1,
    phoneCode: '1-664',
    Continent: 'North America',
    Capital: 'Plymouth',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-MS',
    Languages: 'English',
    areaKM2: 102,
    internetHosts: 2431,
    internetUsers: 1200,
    phonesMobile: 4000,
    phonesLandline: 3000,
    englishShortName: 'Montserrat',
    frenchShortName: 'Montserrat',
    numeric: 500,
    japaneseShortName: 'モントセラト',
  },
  MA: {
    countryName: 'Morocco',
    ISO2: 'MA',
    ISO3: 'MAR',
    topLevelDomain: 'ma',
    FIPS: 'MO',
    ISONumeric: 504,
    GeoNameID: 2542007,
    E164: 212,
    phoneCode: 212,
    Continent: 'Africa',
    Capital: 'Rabat',
    timeZoneInCapital: 'Africa/Casablanca',
    Currency: 'Dirham',
    languageCodes: 'ar-MA,fr',
    Languages:
      'Arabic (official), Berber languages (Tamazight (official), Tachelhit, Tarifit), French (often the language of business, government, and diplomacy)',
    areaKM2: 446550,
    internetHosts: 277338,
    internetUsers: 13213000,
    phonesMobile: 39016000,
    phonesLandline: 3280000,
    GDP: 104800000000,
    englishShortName: 'Morocco',
    frenchShortName: 'Maroc (le)',
    numeric: 504,
    japaneseShortName: 'モロッコ',
  },
  MZ: {
    countryName: 'Mozambique',
    ISO2: 'MZ',
    ISO3: 'MOZ',
    topLevelDomain: 'mz',
    FIPS: 'MZ',
    ISONumeric: 508,
    GeoNameID: 1036973,
    E164: 258,
    phoneCode: 258,
    Continent: 'Africa',
    Capital: 'Maputo',
    timeZoneInCapital: 'Africa/Maputo',
    Currency: 'Metical',
    languageCodes: 'pt-MZ,vmw',
    Languages:
      'Emakhuwa 25.3%, Portuguese (official) 10.7%, Xichangana 10.3%, Cisena 7.5%, Elomwe 7%, Echuwabo 5.1%, other Mozambican languages 30.1%, other 4% (1997 census)',
    areaKM2: 801590,
    internetHosts: 89737,
    internetUsers: 613600,
    phonesMobile: 8108000,
    phonesLandline: 88100,
    GDP: 14670000000,
    englishShortName: 'Mozambique',
    frenchShortName: 'Mozambique (le)',
    numeric: 508,
    japaneseShortName: 'モザンビーク',
  },
  MM: {
    countryName: 'Myanmar',
    ISO2: 'MM',
    ISO3: 'MMR',
    topLevelDomain: 'mm',
    FIPS: 'BM',
    ISONumeric: 104,
    GeoNameID: 1327865,
    E164: 95,
    phoneCode: 95,
    Continent: 'Asia',
    Capital: 'Nay Pyi Taw',
    timeZoneInCapital: 'Asia/Rangoon',
    Currency: 'Kyat',
    languageCodes: 'my',
    Languages: 'Burmese (official)',
    areaKM2: 678500,
    internetHosts: 1055,
    internetUsers: 110000,
    phonesMobile: 5440000,
    phonesLandline: 556000,
    GDP: 59430000000,
    englishShortName: 'Myanmar',
    frenchShortName: 'Myanmar (le)',
    numeric: 104,
    japaneseShortName: 'ミャンマー',
  },
  NA: {
    countryName: 'Namibia',
    ISO2: 'NA',
    ISO3: 'NAM',
    topLevelDomain: 'na',
    FIPS: 'WA',
    ISONumeric: 516,
    GeoNameID: 3355338,
    E164: 264,
    phoneCode: 264,
    Continent: 'Africa',
    Capital: 'Windhoek',
    timeZoneInCapital: 'Africa/Windhoek',
    Currency: 'Dollar',
    languageCodes: 'en-NA,af,de,hz,naq',
    Languages:
      'Oshiwambo languages 48.9%, Nama/Damara 11.3%, Afrikaans 10.4% (common language of most of the population and about 60% of the white population), Otjiherero languages 8.6%, Kavango languages 8.5%, Caprivi languages 4.8%, English (official) 3.4%, other African languages 2.3%, other 1.7%',
    areaKM2: 825418,
    internetHosts: 78280,
    internetUsers: 127500,
    phonesMobile: 2435000,
    phonesLandline: 171000,
    GDP: 12300000000,
    englishShortName: 'Namibia',
    frenchShortName: 'Namibie (la)',
    numeric: 516,
    japaneseShortName: 'ナミビア',
  },
  NR: {
    countryName: 'Nauru',
    ISO2: 'NR',
    ISO3: 'NRU',
    topLevelDomain: 'nr',
    FIPS: 'NR',
    ISONumeric: 520,
    GeoNameID: 2110425,
    E164: 674,
    phoneCode: 674,
    Continent: 'Oceania',
    Capital: 'Yaren',
    timeZoneInCapital: 'Pacific/Nauru',
    Currency: 'Dollar',
    languageCodes: 'na,en-NR',
    Languages:
      'Nauruan 93% (official, a distinct Pacific Island language), English 2% (widely understood, spoken, and used for most government and commercial purposes), other 5% (includes I-Kiribati 2% and Chinese 2%)',
    areaKM2: 21,
    internetHosts: 8162,
    phonesMobile: 6800,
    phonesLandline: 1900,
    englishShortName: 'Nauru',
    frenchShortName: 'Nauru',
    numeric: 520,
    japaneseShortName: 'ナウル',
  },
  NP: {
    countryName: 'Nepal',
    ISO2: 'NP',
    ISO3: 'NPL',
    topLevelDomain: 'np',
    FIPS: 'NP',
    ISONumeric: 524,
    GeoNameID: 1282988,
    E164: 977,
    phoneCode: 977,
    Continent: 'Asia',
    Capital: 'Kathmandu',
    timeZoneInCapital: 'Asia/Kathmandu',
    Currency: 'Rupee',
    languageCodes: 'ne,en',
    Languages:
      'Nepali (official) 44.6%, Maithali 11.7%, Bhojpuri 6%, Tharu 5.8%, Tamang 5.1%, Newar 3.2%, Magar 3%, Bajjika 3%, Urdu 2.6%, Avadhi 1.9%, Limbu 1.3%, Gurung 1.2%, other 10.4%, unspecified 0.2%',
    areaKM2: 140800,
    internetHosts: 41256,
    internetUsers: 577800,
    phonesMobile: 18138000,
    phonesLandline: 834000,
    GDP: 19340000000,
    englishShortName: 'Nepal',
    frenchShortName: 'Népal (le)',
    numeric: 524,
    japaneseShortName: 'ネパール',
  },
  NL: {
    countryName: 'Netherlands',
    ISO2: 'NL',
    ISO3: 'NLD',
    topLevelDomain: 'nl',
    FIPS: 'NL',
    ISONumeric: 528,
    GeoNameID: 2750405,
    E164: 31,
    phoneCode: 31,
    Continent: 'Europe',
    Capital: 'Amsterdam',
    timeZoneInCapital: 'Europe/Amsterdam',
    Currency: 'Euro',
    languageCodes: 'nl-NL,fy-NL',
    Languages: 'Dutch (official)',
    areaKM2: 41526,
    internetHosts: 13699000,
    internetUsers: 14872000,
    phonesMobile: 19643000,
    phonesLandline: 7086000,
    GDP: 722300000000,
    englishShortName: 'Netherlands (the)',
    frenchShortName: 'Pays-Bas (les)',
    numeric: 528,
    japaneseShortName: 'オランダ王国',
  },
  AN: {
    countryName: 'Netherlands Antilles',
    ISO2: 'AN',
    ISO3: 'ANT',
    topLevelDomain: 'an',
    FIPS: 'NT',
    ISONumeric: 530,
    E164: 599,
    phoneCode: 599,
    Continent: 'North America',
    Capital: 'Willemstad',
    timeZoneInCapital: 'America/Curacao',
    Currency: 'Guilder',
    languageCodes: 'nl-AN,en,es',
    Languages: 'Dutch, English, Spanish',
    areaKM2: 960,
    japaneseShortName: 'オランダ領アンティル',
  },
  NC: {
    countryName: 'New Caledonia',
    ISO2: 'NC',
    ISO3: 'NCL',
    topLevelDomain: 'nc',
    FIPS: 'NC',
    ISONumeric: 540,
    GeoNameID: 2139685,
    E164: 687,
    phoneCode: 687,
    Continent: 'Oceania',
    Capital: 'Noumea',
    timeZoneInCapital: 'Pacific/Noumea',
    Currency: 'Franc',
    languageCodes: 'fr-NC',
    Languages: 'French (official), 33 Melanesian-Polynesian dialects',
    areaKM2: 19060,
    internetHosts: 34231,
    internetUsers: 85000,
    phonesMobile: 231000,
    phonesLandline: 80000,
    GDP: 9280000000,
    englishShortName: 'New Caledonia',
    frenchShortName: 'Nouvelle-Calédonie (la)',
    numeric: 540,
    japaneseShortName: 'ニューカレドニア',
  },
  NZ: {
    countryName: 'New Zealand',
    ISO2: 'NZ',
    ISO3: 'NZL',
    topLevelDomain: 'nz',
    FIPS: 'NZ',
    ISONumeric: 554,
    GeoNameID: 2186224,
    E164: 64,
    phoneCode: 64,
    Continent: 'Oceania',
    Capital: 'Wellington',
    timeZoneInCapital: 'Pacific/Auckland',
    Currency: 'Dollar',
    languageCodes: 'en-NZ,mi',
    Languages:
      'English (de facto official) 89.8%, Maori (de jure official) 3.5%, Samoan 2%, Hindi 1.6%, French 1.2%, Northern Chinese 1.2%, Yue 1%, Other or not stated 20.5%, New Zealand Sign Language (de jure official)',
    areaKM2: 268680,
    internetHosts: 3026000,
    internetUsers: 3400000,
    phonesMobile: 4922000,
    phonesLandline: 1880000,
    GDP: 181100000000,
    englishShortName: 'New Zealand',
    frenchShortName: 'Nouvelle-Zélande (la)',
    numeric: 554,
    japaneseShortName: 'ニュージーランド',
  },
  NI: {
    countryName: 'Nicaragua',
    ISO2: 'NI',
    ISO3: 'NIC',
    topLevelDomain: 'ni',
    FIPS: 'NU',
    ISONumeric: 558,
    GeoNameID: 3617476,
    E164: 505,
    phoneCode: 505,
    Continent: 'North America',
    Capital: 'Managua',
    timeZoneInCapital: 'America/Managua',
    Currency: 'Cordoba',
    languageCodes: 'es-NI,en',
    Languages: 'Spanish (official) 95.3%, Miskito 2.2%, Mestizo of the Caribbean coast 2%, other 0.5%',
    areaKM2: 129494,
    internetHosts: 296068,
    internetUsers: 199800,
    phonesMobile: 5346000,
    phonesLandline: 320000,
    GDP: 11260000000,
    englishShortName: 'Nicaragua',
    frenchShortName: 'Nicaragua (le)',
    numeric: 558,
    japaneseShortName: 'ニカラグア',
  },
  NE: {
    countryName: 'Niger',
    ISO2: 'NE',
    ISO3: 'NER',
    topLevelDomain: 'ne',
    FIPS: 'NG',
    ISONumeric: 562,
    GeoNameID: 2440476,
    E164: 227,
    phoneCode: 227,
    Continent: 'Africa',
    Capital: 'Niamey',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'fr-NE,ha,kr,dje',
    Languages: 'French (official), Hausa, Djerma',
    areaKM2: 1267000,
    internetHosts: 454,
    internetUsers: 115900,
    phonesMobile: 5400000,
    phonesLandline: 100500,
    GDP: 7304000000,
    englishShortName: 'Niger (the)',
    frenchShortName: 'Niger (le)',
    numeric: 562,
    japaneseShortName: 'ニジェール',
  },
  NG: {
    countryName: 'Nigeria',
    ISO2: 'NG',
    ISO3: 'NGA',
    topLevelDomain: 'ng',
    FIPS: 'NI',
    ISONumeric: 566,
    GeoNameID: 2328926,
    E164: 234,
    phoneCode: 234,
    Continent: 'Africa',
    Capital: 'Abuja',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Naira',
    languageCodes: 'en-NG,ha,yo,ig,ff',
    Languages: 'English (official), Hausa, Yoruba, Igbo (Ibo), Fulani, over 500 additional indigenous languages',
    areaKM2: 923768,
    internetHosts: 1234,
    internetUsers: 43989000,
    phonesMobile: 112780000,
    phonesLandline: 418200,
    GDP: 502000000000,
    englishShortName: 'Nigeria',
    frenchShortName: 'Nigéria (le)',
    numeric: 566,
    japaneseShortName: 'ナイジェリア',
  },
  NU: {
    countryName: 'Niue',
    ISO2: 'NU',
    ISO3: 'NIU',
    topLevelDomain: 'nu',
    FIPS: 'NE',
    ISONumeric: 570,
    GeoNameID: 4036232,
    E164: 683,
    phoneCode: 683,
    Continent: 'Oceania',
    Capital: 'Alofi',
    timeZoneInCapital: 'Pacific/Niue',
    Currency: 'Dollar',
    languageCodes: 'niu,en-NU',
    Languages:
      'Niuean (official) 46% (a Polynesian language closely related to Tongan and Samoan), Niuean and English 32%, English (official) 11%, Niuean and others 5%, other 6% (2011 est.)',
    areaKM2: 260,
    internetHosts: 79508,
    internetUsers: 1100,
    GDP: 10010000,
    englishShortName: 'Niue',
    frenchShortName: 'Niue',
    numeric: 570,
    japaneseShortName: 'ニウエ',
  },
  KP: {
    countryName: 'North Korea',
    ISO2: 'KP',
    ISO3: 'PRK',
    topLevelDomain: 'kp',
    FIPS: 'KN',
    ISONumeric: 408,
    GeoNameID: 1873107,
    E164: 850,
    phoneCode: 850,
    Continent: 'Asia',
    Capital: 'Pyongyang',
    timeZoneInCapital: 'Asia/Pyongyang',
    Currency: 'Won',
    languageCodes: 'ko-KP',
    Languages: 'Korean',
    areaKM2: 120540,
    internetHosts: 8,
    phonesMobile: 1700000,
    phonesLandline: 1180000,
    GDP: 28000000000,
    englishShortName: "Korea (the Democratic People's Republic of)",
    frenchShortName: 'Corée (la République populaire démocratique de)',
    numeric: 408,
    japaneseShortName: '北朝鮮',
  },
  MP: {
    countryName: 'Northern Mariana Islands',
    ISO2: 'MP',
    ISO3: 'MNP',
    topLevelDomain: 'mp',
    FIPS: 'CQ',
    ISONumeric: 580,
    GeoNameID: 4041468,
    E164: 1,
    phoneCode: '1-670',
    Continent: 'Oceania',
    Capital: 'Saipan',
    timeZoneInCapital: 'Pacific/Saipan',
    Currency: 'Dollar',
    languageCodes: 'fil,tl,zh,ch-MP,en-MP',
    Languages:
      'Philippine languages 32.8%, Chamorro (official) 24.1%, English (official) 17%, other Pacific island languages 10.1%, Chinese 6.8%, other Asian languages 7.3%, other 1.9% (2010 est.)',
    areaKM2: 477,
    internetHosts: 17,
    GDP: 733000000,
    englishShortName: 'Northern Mariana Islands (the)',
    frenchShortName: 'Mariannes du Nord (les Îles)',
    numeric: 580,
    japaneseShortName: '北マリアナ諸島',
  },
  NO: {
    countryName: 'Norway',
    ISO2: 'NO',
    ISO3: 'NOR',
    topLevelDomain: 'no',
    FIPS: 'NO',
    ISONumeric: 578,
    GeoNameID: 3144096,
    E164: 47,
    phoneCode: 47,
    Continent: 'Europe',
    Capital: 'Oslo',
    timeZoneInCapital: 'Europe/Oslo',
    Currency: 'Krone',
    languageCodes: 'no,nb,nn,se,fi',
    Languages: 'Bokmal Norwegian (official), Nynorsk Norwegian (official), small Sami- and Finnish-speaking minorities',
    areaKM2: 324220,
    internetHosts: 3588000,
    internetUsers: 4431000,
    phonesMobile: 5732000,
    phonesLandline: 1465000,
    GDP: 515800000000,
    englishShortName: 'Norway',
    frenchShortName: 'Norvège (la)',
    numeric: 578,
    japaneseShortName: 'ノルウェー',
  },
  OM: {
    countryName: 'Oman',
    ISO2: 'OM',
    ISO3: 'OMN',
    topLevelDomain: 'om',
    FIPS: 'MU',
    ISONumeric: 512,
    GeoNameID: 286963,
    E164: 968,
    phoneCode: 968,
    Continent: 'Asia',
    Capital: 'Muscat',
    timeZoneInCapital: 'Asia/Muscat',
    Currency: 'Rial',
    languageCodes: 'ar-OM,en,bal,ur',
    Languages: 'Arabic (official), English, Baluchi, Urdu, Indian dialects',
    areaKM2: 212460,
    internetHosts: 14531,
    internetUsers: 1465000,
    phonesMobile: 5278000,
    phonesLandline: 305000,
    GDP: 81950000000,
    englishShortName: 'Oman',
    frenchShortName: 'Oman',
    numeric: 512,
    japaneseShortName: 'オマーン',
  },
  PK: {
    countryName: 'Pakistan',
    ISO2: 'PK',
    ISO3: 'PAK',
    topLevelDomain: 'pk',
    FIPS: 'PK',
    ISONumeric: 586,
    GeoNameID: 1168579,
    E164: 92,
    phoneCode: 92,
    Continent: 'Asia',
    Capital: 'Islamabad',
    timeZoneInCapital: 'Asia/Karachi',
    Currency: 'Rupee',
    languageCodes: 'ur-PK,en-PK,pa,sd,ps,brh',
    Languages:
      'Punjabi 48%, Sindhi 12%, Saraiki (a Punjabi variant) 10%, Pashto (alternate name, Pashtu) 8%, Urdu (official) 8%, Balochi 3%, Hindko 2%, Brahui 1%, English (official; lingua franca of Pakistani elite and most government ministries), Burushaski, and other 8%',
    areaKM2: 803940,
    internetHosts: 365813,
    internetUsers: 20431000,
    phonesMobile: 125000000,
    phonesLandline: 5803000,
    GDP: 236500000000,
    englishShortName: 'Pakistan',
    frenchShortName: 'Pakistan (le)',
    numeric: 586,
    japaneseShortName: 'パキスタン',
  },
  PW: {
    countryName: 'Palau',
    ISO2: 'PW',
    ISO3: 'PLW',
    topLevelDomain: 'pw',
    FIPS: 'PS',
    ISONumeric: 585,
    GeoNameID: 1559582,
    E164: 680,
    phoneCode: 680,
    Continent: 'Oceania',
    Capital: 'Melekeok',
    timeZoneInCapital: 'Pacific/Palau',
    Currency: 'Dollar',
    languageCodes: 'pau,sov,en-PW,tox,ja,fil,zh',
    Languages:
      'Palauan (official on most islands) 66.6%, Carolinian 0.7%, other Micronesian 0.7%, English (official) 15.5%, Filipino 10.8%, Chinese 1.8%, other Asian 2.6%, other 1.3%',
    areaKM2: 458,
    internetHosts: 4,
    phonesMobile: 17150,
    phonesLandline: 7300,
    GDP: 221000000,
    englishShortName: 'Palau',
    frenchShortName: 'Palaos (les)',
    numeric: 585,
    japaneseShortName: 'パラオ',
  },
  PS: {
    countryName: 'Palestine',
    ISO2: 'PS',
    ISO3: 'PSE',
    topLevelDomain: 'ps',
    FIPS: 'WE',
    ISONumeric: 275,
    GeoNameID: 6254930,
    E164: 970,
    phoneCode: 970,
    Continent: 'Asia',
    Capital: 'East Jerusalem',
    timeZoneInCapital: 'Asia/Hebron',
    Currency: 'Shekel',
    languageCodes: 'ar-PS',
    Languages: 'Arabic, Hebrew, English',
    areaKM2: 5970,
    internetUsers: 1379000,
    phonesMobile: 3041000,
    phonesLandline: 406000,
    GDP: 6641000000,
    englishShortName: 'Palestine, State of',
    frenchShortName: 'Palestine, État de',
    numeric: 275,
    japaneseShortName: 'パレスチナ国',
  },
  PA: {
    countryName: 'Panama',
    ISO2: 'PA',
    ISO3: 'PAN',
    topLevelDomain: 'pa',
    FIPS: 'PM',
    ISONumeric: 591,
    GeoNameID: 3703430,
    E164: 507,
    phoneCode: 507,
    Continent: 'North America',
    Capital: 'Panama City',
    timeZoneInCapital: 'America/Panama',
    Currency: 'Balboa',
    languageCodes: 'es-PA,en',
    Languages: 'Spanish (official), English 14%',
    areaKM2: 78200,
    internetHosts: 11022,
    internetUsers: 959800,
    phonesMobile: 6770000,
    phonesLandline: 640000,
    GDP: 40620000000,
    englishShortName: 'Panama',
    frenchShortName: 'Panama (le)',
    numeric: 591,
    japaneseShortName: 'パナマ',
  },
  PG: {
    countryName: 'Papua New Guinea',
    ISO2: 'PG',
    ISO3: 'PNG',
    topLevelDomain: 'pg',
    FIPS: 'PP',
    ISONumeric: 598,
    GeoNameID: 2088628,
    E164: 675,
    phoneCode: 675,
    Continent: 'Oceania',
    Capital: 'Port Moresby',
    timeZoneInCapital: 'Pacific/Port_Moresby',
    Currency: 'Kina',
    languageCodes: 'en-PG,ho,meu,tpi',
    Languages:
      "Tok Pisin (official), English (official), Hiri Motu (official), some 836 indigenous languages spoken (about 12% of the world's total); most languages have fewer than 1,000 speakers",
    areaKM2: 462840,
    internetHosts: 5006,
    internetUsers: 125000,
    phonesMobile: 2709000,
    phonesLandline: 139000,
    GDP: 16100000000,
    englishShortName: 'Papua New Guinea',
    frenchShortName: 'Papouasie-Nouvelle-Guinée (la)',
    numeric: 598,
    japaneseShortName: 'パプアニューギニア',
  },
  PY: {
    countryName: 'Paraguay',
    ISO2: 'PY',
    ISO3: 'PRY',
    topLevelDomain: 'py',
    FIPS: 'PA',
    ISONumeric: 600,
    GeoNameID: 3437598,
    E164: 595,
    phoneCode: 595,
    Continent: 'South America',
    Capital: 'Asuncion',
    timeZoneInCapital: 'America/Asuncion',
    Currency: 'Guarani',
    languageCodes: 'es-PY,gn',
    Languages: 'Spanish (official), Guarani (official)',
    areaKM2: 406750,
    internetHosts: 280658,
    internetUsers: 1105000,
    phonesMobile: 6790000,
    phonesLandline: 376000,
    GDP: 30560000000,
    englishShortName: 'Paraguay',
    frenchShortName: 'Paraguay (le)',
    numeric: 600,
    japaneseShortName: 'パラグアイ',
  },
  PE: {
    countryName: 'Peru',
    ISO2: 'PE',
    ISO3: 'PER',
    topLevelDomain: 'pe',
    FIPS: 'PE',
    ISONumeric: 604,
    GeoNameID: 3932488,
    E164: 51,
    phoneCode: 51,
    Continent: 'South America',
    Capital: 'Lima',
    timeZoneInCapital: 'America/Lima',
    Currency: 'Sol',
    languageCodes: 'es-PE,qu,ay',
    Languages:
      'Spanish (official) 84.1%, Quechua (official) 13%, Aymara (official) 1.7%, Ashaninka 0.3%, other native languages (includes a large number of minor Amazonian languages) 0.7%, other (includes foreign languages and sign language) 0.2% (2007 est.)',
    areaKM2: 1285220,
    internetHosts: 234102,
    internetUsers: 9158000,
    phonesMobile: 29400000,
    phonesLandline: 3420000,
    GDP: 210300000000,
    englishShortName: 'Peru',
    frenchShortName: 'Pérou (le)',
    numeric: 604,
    japaneseShortName: 'ペルー',
  },
  PH: {
    countryName: 'Philippines',
    ISO2: 'PH',
    ISO3: 'PHL',
    topLevelDomain: 'ph',
    FIPS: 'RP',
    ISONumeric: 608,
    GeoNameID: 1694008,
    E164: 63,
    phoneCode: 63,
    Continent: 'Asia',
    Capital: 'Manila',
    timeZoneInCapital: 'Asia/Manila',
    Currency: 'Peso',
    languageCodes: 'tl,en-PH,fil',
    Languages:
      'Filipino (official; based on Tagalog) and English (official); eight major dialects - Tagalog, Cebuano, Ilocano, Hiligaynon or Ilonggo, Bicol, Waray, Pampango, and Pangasinan',
    areaKM2: 300000,
    internetHosts: 425812,
    internetUsers: 8278000,
    phonesMobile: 103000000,
    phonesLandline: 3939000,
    GDP: 272200000000,
    englishShortName: 'Philippines (the)',
    frenchShortName: 'Philippines (les)',
    numeric: 608,
    japaneseShortName: 'フィリピン',
  },
  PN: {
    countryName: 'Pitcairn',
    ISO2: 'PN',
    ISO3: 'PCN',
    topLevelDomain: 'pn',
    FIPS: 'PC',
    ISONumeric: 612,
    GeoNameID: 4030699,
    E164: 64,
    phoneCode: 64,
    Continent: 'Oceania',
    Capital: 'Adamstown',
    timeZoneInCapital: 'Pacific/Pitcairn',
    Currency: 'Dollar',
    languageCodes: 'en-PN',
    Languages: 'English',
    areaKM2: 47,
    englishShortName: 'Pitcairn',
    frenchShortName: 'Pitcairn',
    numeric: 612,
    japaneseShortName: 'ピトケアン',
  },
  PL: {
    countryName: 'Poland',
    ISO2: 'PL',
    ISO3: 'POL',
    topLevelDomain: 'pl',
    FIPS: 'PL',
    ISONumeric: 616,
    GeoNameID: 798544,
    E164: 48,
    phoneCode: 48,
    Continent: 'Europe',
    Capital: 'Warsaw',
    timeZoneInCapital: 'Europe/Warsaw',
    Currency: 'Zloty',
    languageCodes: 'pl',
    Languages: 'Polish (official) 96.2%, Polish and non-Polish 2%, non-Polish 0.5%, unspecified 1.3%',
    areaKM2: 312685,
    internetHosts: 13265000,
    internetUsers: 22452000,
    phonesMobile: 50840000,
    phonesLandline: 6125000,
    GDP: 513900000000,
    englishShortName: 'Poland',
    frenchShortName: 'Pologne (la)',
    numeric: 616,
    japaneseShortName: 'ポーランド',
  },
  PT: {
    countryName: 'Portugal',
    ISO2: 'PT',
    ISO3: 'PRT',
    topLevelDomain: 'pt',
    FIPS: 'PO',
    ISONumeric: 620,
    GeoNameID: 2264397,
    E164: 351,
    phoneCode: 351,
    Continent: 'Europe',
    Capital: 'Lisbon',
    timeZoneInCapital: 'Europe/Lisbon',
    Currency: 'Euro',
    languageCodes: 'pt-PT,mwl',
    Languages: 'Portuguese (official), Mirandese (official, but locally used)',
    areaKM2: 92391,
    internetHosts: 3748000,
    internetUsers: 5168000,
    phonesMobile: 12312000,
    phonesLandline: 4558000,
    GDP: 219300000000,
    englishShortName: 'Portugal',
    frenchShortName: 'Portugal (le)',
    numeric: 620,
    japaneseShortName: 'ポルトガル',
  },
  PR: {
    countryName: 'Puerto Rico',
    ISO2: 'PR',
    ISO3: 'PRI',
    topLevelDomain: 'pr',
    FIPS: 'RQ',
    ISONumeric: 630,
    GeoNameID: 4566966,
    E164: 1,
    phoneCode: '1-787, 1-939',
    Continent: 'North America',
    Capital: 'San Juan',
    timeZoneInCapital: 'America/Puerto_Rico',
    Currency: 'Dollar',
    languageCodes: 'en-PR,es-PR',
    Languages: 'Spanish, English',
    areaKM2: 9104,
    internetHosts: 469,
    internetUsers: 1000000,
    phonesMobile: 3060000,
    phonesLandline: 780200,
    GDP: 93520000000,
    englishShortName: 'Puerto Rico',
    frenchShortName: 'Porto Rico',
    numeric: 630,
    japaneseShortName: 'プエルトリコ',
  },
  QA: {
    countryName: 'Qatar',
    ISO2: 'QA',
    ISO3: 'QAT',
    topLevelDomain: 'qa',
    FIPS: 'QA',
    ISONumeric: 634,
    GeoNameID: 289688,
    E164: 974,
    phoneCode: 974,
    Continent: 'Asia',
    Capital: 'Doha',
    timeZoneInCapital: 'Asia/Qatar',
    Currency: 'Rial',
    languageCodes: 'ar-QA,es',
    Languages: 'Arabic (official), English commonly used as a second language',
    areaKM2: 11437,
    internetHosts: 897,
    internetUsers: 563800,
    phonesMobile: 2600000,
    phonesLandline: 327000,
    GDP: 213100000000,
    englishShortName: 'Qatar',
    frenchShortName: 'Qatar (le)',
    numeric: 634,
    japaneseShortName: 'カタール',
  },
  CG: {
    countryName: 'Republic of the Congo',
    ISO2: 'CG',
    ISO3: 'COG',
    topLevelDomain: 'cg',
    FIPS: 'CF',
    ISONumeric: 178,
    GeoNameID: 2260494,
    E164: 242,
    phoneCode: 242,
    Continent: 'Africa',
    Capital: 'Brazzaville',
    timeZoneInCapital: 'Africa/Lagos',
    Currency: 'Franc',
    languageCodes: 'fr-CG,kg,ln-CG',
    Languages:
      'French (official), Lingala and Monokutuba (lingua franca trade languages), many local languages and dialects (of which Kikongo is the most widespread)',
    areaKM2: 342000,
    internetHosts: 45,
    internetUsers: 245200,
    phonesMobile: 4283000,
    phonesLandline: 14900,
    GDP: 14250000000,
    englishShortName: 'Congo (the)',
    frenchShortName: 'Congo (le)',
    numeric: 178,
    japaneseShortName: 'コンゴ',
  },
  RE: {
    countryName: 'Reunion',
    ISO2: 'RE',
    ISO3: 'REU',
    topLevelDomain: 're',
    FIPS: 'RE',
    ISONumeric: 638,
    GeoNameID: 935317,
    E164: 262,
    phoneCode: 262,
    Continent: 'Africa',
    Capital: 'Saint-Denis',
    timeZoneInCapital: 'Indian/Reunion',
    Currency: 'Euro',
    languageCodes: 'fr-RE',
    Languages: 'French',
    areaKM2: 2517,
    englishShortName: 'Réunion',
    frenchShortName: 'Réunion (La)',
    numeric: 638,
    japaneseShortName: 'レユニオン',
  },
  RO: {
    countryName: 'Romania',
    ISO2: 'RO',
    ISO3: 'ROU',
    topLevelDomain: 'ro',
    FIPS: 'RO',
    ISONumeric: 642,
    GeoNameID: 798549,
    E164: 40,
    phoneCode: 40,
    Continent: 'Europe',
    Capital: 'Bucharest',
    timeZoneInCapital: 'Europe/Bucharest',
    Currency: 'Leu',
    languageCodes: 'ro,hu,rom',
    Languages: 'Romanian (official) 85.4%, Hungarian 6.3%, Romany (Gypsy) 1.2%, other 1%, unspecified 6.1% (2011 est.)',
    areaKM2: 237500,
    internetHosts: 2667000,
    internetUsers: 7787000,
    phonesMobile: 22700000,
    phonesLandline: 4680000,
    GDP: 188900000000,
    englishShortName: 'Romania',
    frenchShortName: 'Roumanie (la)',
    numeric: 642,
    japaneseShortName: 'ルーマニア',
  },
  RU: {
    countryName: 'Russia',
    ISO2: 'RU',
    ISO3: 'RUS',
    topLevelDomain: 'ru',
    FIPS: 'RS',
    ISONumeric: 643,
    GeoNameID: 2017370,
    E164: 7,
    phoneCode: 7,
    Continent: 'Europe',
    Capital: 'Moscow',
    timeZoneInCapital: 'Europe/Moscow',
    Currency: 'Ruble',
    languageCodes: 'ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,ava,sah,nog',
    Languages: 'Russian (official) 96.3%, Dolgang 5.3%, German 1.5%, Chechen 1%, Tatar 3%, other 10.3%',
    areaKM2: 17100000,
    internetHosts: 14865000,
    internetUsers: 40853000,
    phonesMobile: 261900000,
    phonesLandline: 42900000,
    GDP: 2113000000000,
    englishShortName: 'Russian Federation (the)',
    frenchShortName: 'Russie (la Fédération de)',
    numeric: 643,
    japaneseShortName: 'ロシア連邦',
  },
  RW: {
    countryName: 'Rwanda',
    ISO2: 'RW',
    ISO3: 'RWA',
    topLevelDomain: 'rw',
    FIPS: 'RW',
    ISONumeric: 646,
    GeoNameID: 49518,
    E164: 250,
    phoneCode: 250,
    Continent: 'Africa',
    Capital: 'Kigali',
    timeZoneInCapital: 'Africa/Maputo',
    Currency: 'Franc',
    languageCodes: 'rw,en-RW,fr-RW,sw',
    Languages:
      'Kinyarwanda only (official, universal Bantu vernacular) 93.2%, Kinyarwanda and other language(s) 6.2%, French (official) and other language(s) 0.1%, English (official) and other language(s) 0.1%, Swahili (or Kiswahili, used in commercial centers) 0.02%, other 0.03%, unspecified 0.3% (2002 est.)',
    areaKM2: 26338,
    internetHosts: 1447,
    internetUsers: 450000,
    phonesMobile: 5690000,
    phonesLandline: 44400,
    GDP: 7700000000,
    englishShortName: 'Rwanda',
    frenchShortName: 'Rwanda (le)',
    numeric: 646,
    japaneseShortName: 'ルワンダ',
  },
  // BL: {
  //   countryName: 'Saint Barthelemy',
  //   ISO2: 'BL',
  //   ISO3: 'BLM',
  //   topLevelDomain: 'gp',
  //   FIPS: 'TB',
  //   ISONumeric: 652,
  //   GeoNameID: 3578476,
  //   E164: 590,
  //   phoneCode: 590,
  //   Continent: 'North America',
  //   Capital: 'Gustavia',
  //   timeZoneInCapital: 'America/Port_of_Spain',
  //   Currency: 'Euro',
  //   languageCodes: 'fr',
  //   Languages: 'French (primary), English',
  //   areaKM2: 21,
  //   internetHosts: null,
  //   internetUsers: null,
  //   phonesMobile: null,
  //   phonesLandline: null,
  //   GDP: null,
  //   englishShortName: 'Saint Barthélemy',
  //   frenchShortName: 'Saint-Barthélemy',
  //   numeric: 652,
  //   japaneseShortName: 'サン・バルテルミー',
  // },
  SH: {
    countryName: 'Saint Helena',
    ISO2: 'SH',
    ISO3: 'SHN',
    topLevelDomain: 'sh',
    FIPS: 'SH',
    ISONumeric: 654,
    GeoNameID: 3370751,
    E164: 290,
    phoneCode: 290,
    Continent: 'Africa',
    Capital: 'Jamestown',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Pound',
    languageCodes: 'en-SH',
    Languages: 'English',
    areaKM2: 410,
    englishShortName: 'Saint Helena, Ascension and Tristan da Cunha',
    frenchShortName: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    numeric: 654,
    japaneseShortName: 'セントヘレナ',
  },
  KN: {
    countryName: 'Saint Kitts and Nevis',
    ISO2: 'KN',
    ISO3: 'KNA',
    topLevelDomain: 'kn',
    FIPS: 'SC',
    ISONumeric: 659,
    GeoNameID: 3575174,
    E164: 1,
    phoneCode: '1-869',
    Continent: 'North America',
    Capital: 'Basseterre',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-KN',
    Languages: 'English (official)',
    areaKM2: 261,
    internetHosts: 54,
    internetUsers: 17000,
    phonesMobile: 84000,
    phonesLandline: 20000,
    GDP: 767000000,
    englishShortName: 'Saint Kitts and Nevis',
    frenchShortName: 'Saint-Kitts-et-Nevis',
    numeric: 659,
    japaneseShortName: 'セントクリストファー・ネイビス連邦',
  },
  LC: {
    countryName: 'Saint Lucia',
    ISO2: 'LC',
    ISO3: 'LCA',
    topLevelDomain: 'lc',
    FIPS: 'ST',
    ISONumeric: 662,
    GeoNameID: 3576468,
    E164: 1,
    phoneCode: '1-758',
    Continent: 'North America',
    Capital: 'Castries',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-LC',
    Languages: 'English (official), French patois',
    areaKM2: 616,
    internetHosts: 100,
    internetUsers: 142900,
    phonesMobile: 227000,
    phonesLandline: 36800,
    GDP: 1377000000,
    englishShortName: 'Saint Lucia',
    frenchShortName: 'Sainte-Lucie',
    numeric: 662,
    japaneseShortName: 'セントルシア',
  },
  MF: {
    countryName: 'Saint Martin',
    ISO2: 'MF',
    ISO3: 'MAF',
    topLevelDomain: 'gp',
    FIPS: 'RN',
    ISONumeric: 663,
    GeoNameID: 3578421,
    E164: 1,
    phoneCode: 590,
    Continent: 'North America',
    Capital: 'Marigot',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Euro',
    languageCodes: 'fr',
    Languages:
      'French (official), English, Dutch, French Patois, Spanish, Papiamento (dialect of Netherlands Antilles)',
    areaKM2: 53,
    GDP: 561500000,
    englishShortName: 'Saint Martin (French part)',
    frenchShortName: 'Saint-Martin (partie française)',
    numeric: 663,
    japaneseShortName: 'セント・マーチン',
  },
  // PM: {
  //   countryName: 'Saint Pierre and Miquelon',
  //   ISO2: 'PM',
  //   ISO3: 'SPM',
  //   topLevelDomain: 'pm',
  //   FIPS: 'SB',
  //   ISONumeric: 666,
  //   GeoNameID: 3424932,
  //   E164: 508,
  //   phoneCode: 508,
  //   Continent: 'North America',
  //   Capital: 'Saint-Pierre',
  //   timeZoneInCapital: 'America/Miquelon',
  //   Currency: 'Euro',
  //   languageCodes: 'fr-PM',
  //   Languages: 'French (official)',
  //   areaKM2: 242,
  //   internetHosts: 15,
  //   internetUsers: null,
  //   phonesMobile: null,
  //   phonesLandline: 4800,
  //   GDP: 215300000,
  //   englishShortName: 'Saint Pierre and Miquelon',
  //   frenchShortName: 'Saint-Pierre-et-Miquelon',
  //   numeric: 666,
  //   japaneseShortName: 'サンピエール島およびミクロン島',
  // },
  VC: {
    countryName: 'Saint Vincent and the Grenadines',
    ISO2: 'VC',
    ISO3: 'VCT',
    topLevelDomain: 'vc',
    FIPS: 'VC',
    ISONumeric: 670,
    GeoNameID: 3577815,
    E164: 1,
    phoneCode: '1-784',
    Continent: 'North America',
    Capital: 'Kingstown',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-VC,fr',
    Languages: 'English, French patois',
    areaKM2: 389,
    internetHosts: 305,
    internetUsers: 76000,
    phonesMobile: 135500,
    phonesLandline: 19400,
    GDP: 742000000,
    englishShortName: 'Saint Vincent and the Grenadines',
    frenchShortName: 'Saint-Vincent-et-les Grenadines',
    numeric: 670,
    japaneseShortName: 'セントビンセント及びグレナディーン諸島',
  },
  WS: {
    countryName: 'Samoa',
    ISO2: 'WS',
    ISO3: 'WSM',
    topLevelDomain: 'ws',
    FIPS: 'WS',
    ISONumeric: 882,
    GeoNameID: 4034894,
    E164: 685,
    phoneCode: 685,
    Continent: 'Oceania',
    Capital: 'Apia',
    timeZoneInCapital: 'Pacific/Apia',
    Currency: 'Tala',
    languageCodes: 'sm,en-WS',
    Languages: 'Samoan (Polynesian) (official), English',
    areaKM2: 2944,
    internetHosts: 18013,
    internetUsers: 9000,
    phonesMobile: 167400,
    phonesLandline: 35300,
    GDP: 705000000,
    englishShortName: 'Samoa',
    frenchShortName: 'Samoa (le)',
    numeric: 882,
    japaneseShortName: 'サモア',
  },
  SM: {
    countryName: 'San Marino',
    ISO2: 'SM',
    ISO3: 'SMR',
    topLevelDomain: 'sm',
    FIPS: 'SM',
    ISONumeric: 674,
    GeoNameID: 3168068,
    E164: 378,
    phoneCode: 378,
    Continent: 'Europe',
    Capital: 'San Marino',
    timeZoneInCapital: 'Europe/Rome',
    Currency: 'Euro',
    languageCodes: 'it-SM',
    Languages: 'Italian',
    areaKM2: 61,
    internetHosts: 11015,
    internetUsers: 17000,
    phonesMobile: 36000,
    phonesLandline: 18700,
    GDP: 1866000000,
    englishShortName: 'San Marino',
    frenchShortName: 'Saint-Marin',
    numeric: 674,
    japaneseShortName: 'サンマリノ',
  },
  ST: {
    countryName: 'Sao Tome and Principe',
    ISO2: 'ST',
    ISO3: 'STP',
    topLevelDomain: 'st',
    FIPS: 'TP',
    ISONumeric: 678,
    GeoNameID: 2410758,
    E164: 239,
    phoneCode: 239,
    Continent: 'Africa',
    Capital: 'Sao Tome',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Dobra',
    languageCodes: 'pt-ST',
    Languages:
      'Portuguese 98.4% (official), Forro 36.2%, Cabo Verdian 8.5%, French 6.8%, Angolar 6.6%, English 4.9%, Lunguie 1%, other (including sign language) 2.4%',
    areaKM2: 1001,
    internetHosts: 1678,
    internetUsers: 26700,
    phonesMobile: 122000,
    phonesLandline: 8000,
    GDP: 311000000,
    englishShortName: 'Sao Tome and Principe',
    frenchShortName: 'Sao Tomé-et-Principe',
    numeric: 678,
    japaneseShortName: 'サントメ・プリンシペ',
  },
  SA: {
    countryName: 'Saudi Arabia',
    ISO2: 'SA',
    ISO3: 'SAU',
    topLevelDomain: 'sa',
    FIPS: 'SA',
    ISONumeric: 682,
    GeoNameID: 102358,
    E164: 966,
    phoneCode: 966,
    Continent: 'Asia',
    Capital: 'Riyadh',
    timeZoneInCapital: 'Asia/Riyadh',
    Currency: 'Rial',
    languageCodes: 'ar-SA',
    Languages: 'Arabic (official)',
    areaKM2: 1960582,
    internetHosts: 145941,
    internetUsers: 9774000,
    phonesMobile: 53000000,
    phonesLandline: 4800000,
    GDP: 718500000000,
    englishShortName: 'Saudi Arabia',
    frenchShortName: "Arabie saoudite (l')",
    numeric: 682,
    japaneseShortName: 'サウジアラビア',
  },
  SN: {
    countryName: 'Senegal',
    ISO2: 'SN',
    ISO3: 'SEN',
    topLevelDomain: 'sn',
    FIPS: 'SG',
    ISONumeric: 686,
    GeoNameID: 2245662,
    E164: 221,
    phoneCode: 221,
    Continent: 'Africa',
    Capital: 'Dakar',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Franc',
    languageCodes: 'fr-SN,wo,fuc,mnk',
    Languages: 'French (official), Wolof, Pulaar, Jola, Mandinka',
    areaKM2: 196190,
    internetHosts: 237,
    internetUsers: 1818000,
    phonesMobile: 11470000,
    phonesLandline: 338200,
    GDP: 15360000000,
    englishShortName: 'Senegal',
    frenchShortName: 'Sénégal (le)',
    numeric: 686,
    japaneseShortName: 'セネガル',
  },
  RS: {
    countryName: 'Serbia',
    ISO2: 'RS',
    ISO3: 'SRB',
    topLevelDomain: 'rs',
    FIPS: 'RI',
    ISONumeric: 688,
    GeoNameID: 6290252,
    E164: 381,
    phoneCode: 381,
    Continent: 'Europe',
    Capital: 'Belgrade',
    timeZoneInCapital: 'Europe/Belgrade',
    Currency: 'Dinar',
    languageCodes: 'sr,hu,bs,rom',
    Languages:
      'Serbian (official) 88.1%, Hungarian 3.4%, Bosnian 1.9%, Romany 1.4%, other 3.4%, undeclared or unknown 1.8%',
    areaKM2: 88361,
    internetHosts: 1102000,
    internetUsers: 4107000,
    phonesMobile: 9138000,
    phonesLandline: 2977000,
    GDP: 43680000000,
    englishShortName: 'Serbia',
    frenchShortName: 'Serbie (la)',
    numeric: 688,
    japaneseShortName: 'セルビア',
  },
  SC: {
    countryName: 'Seychelles',
    ISO2: 'SC',
    ISO3: 'SYC',
    topLevelDomain: 'sc',
    FIPS: 'SE',
    ISONumeric: 690,
    GeoNameID: 241170,
    E164: 248,
    phoneCode: 248,
    Continent: 'Africa',
    Capital: 'Victoria',
    timeZoneInCapital: 'Indian/Mahe',
    Currency: 'Rupee',
    languageCodes: 'en-SC,fr-SC',
    Languages:
      'Seychellois Creole (official) 89.1%, English (official) 5.1%, French (official) 0.7%, other 3.8%, unspecified 1.4% (2010 est.)',
    areaKM2: 455,
    internetHosts: 247,
    internetUsers: 32000,
    phonesMobile: 138300,
    phonesLandline: 28900,
    GDP: 1271000000,
    englishShortName: 'Seychelles',
    frenchShortName: 'Seychelles (les)',
    numeric: 690,
    japaneseShortName: 'セーシェル',
  },
  SL: {
    countryName: 'Sierra Leone',
    ISO2: 'SL',
    ISO3: 'SLE',
    topLevelDomain: 'sl',
    FIPS: 'SL',
    ISONumeric: 694,
    GeoNameID: 2403846,
    E164: 232,
    phoneCode: 232,
    Continent: 'Africa',
    Capital: 'Freetown',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Leone',
    languageCodes: 'en-SL,men,tem',
    Languages:
      'English (official, regular use limited to literate minority), Mende (principal vernacular in the south), Temne (principal vernacular in the north), Krio (English-based Creole, spoken by the descendants of freed Jamaican slaves who were settled in the Freetown area, a lingua franca and a first language for 10% of the population but understood by 95%)',
    areaKM2: 71740,
    internetHosts: 282,
    internetUsers: 14900,
    phonesMobile: 2210000,
    phonesLandline: 18000,
    GDP: 4607000000,
    englishShortName: 'Sierra Leone',
    frenchShortName: 'Sierra Leone (la)',
    numeric: 694,
    japaneseShortName: 'シエラレオネ',
  },
  SG: {
    countryName: 'Singapore',
    ISO2: 'SG',
    ISO3: 'SGP',
    topLevelDomain: 'sg',
    FIPS: 'SN',
    ISONumeric: 702,
    GeoNameID: 1880251,
    E164: 65,
    phoneCode: 65,
    Continent: 'Asia',
    Capital: 'Singapore',
    timeZoneInCapital: 'Asia/Singapore',
    Currency: 'Dollar',
    languageCodes: 'cmn,en-SG,ms-SG,ta-SG,zh-SG',
    Languages:
      'Mandarin (official) 36.3%, English (official) 29.8%, Malay (official) 11.9%, Hokkien 8.1%, Tamil (official) 4.4%, Cantonese 4.1%, Teochew 3.2%, other Indian languages 1.2%, other Chinese dialects 1.1%, other 1.1% (2010 est.)',
    areaKM2: 693,
    internetHosts: 1960000,
    internetUsers: 3235000,
    phonesMobile: 8063000,
    phonesLandline: 1990000,
    GDP: 295700000000,
    englishShortName: 'Singapore',
    frenchShortName: 'Singapour',
    numeric: 702,
    japaneseShortName: 'シンガポール',
  },
  // SX: {
  //   countryName: 'Sint Maarten',
  //   ISO2: 'SX',
  //   ISO3: 'SXM',
  //   topLevelDomain: 'sx',
  //   FIPS: 'NN',
  //   ISONumeric: 534,
  //   GeoNameID: 7609695,
  //   E164: 1,
  //   phoneCode: '1-721',
  //   Continent: 'North America',
  //   Capital: 'Philipsburg',
  //   timeZoneInCapital: 'America/Curacao',
  //   Currency: 'Guilder',
  //   languageCodes: 'nl,en',
  //   Languages:
  //     'English (official) 67.5%, Spanish 12.9%, Creole 8.2%, Dutch (official) 4.2%, Papiamento (a Spanish-Portuguese-Dutch-English dialect) 2.2%, French 1.5%, other 3.5% (2001 census)',
  //   areaKM2: 34,
  //   internetHosts: null,
  //   internetUsers: null,
  //   phonesMobile: null,
  //   phonesLandline: null,
  //   GDP: 794700000,
  //   englishShortName: 'Sint Maarten (Dutch part)',
  //   frenchShortName: 'Saint-Martin (partie néerlandaise)',
  //   numeric: 534,
  // },
  SK: {
    countryName: 'Slovakia',
    ISO2: 'SK',
    ISO3: 'SVK',
    topLevelDomain: 'sk',
    FIPS: 'LO',
    ISONumeric: 703,
    GeoNameID: 3057568,
    E164: 421,
    phoneCode: 421,
    Continent: 'Europe',
    Capital: 'Bratislava',
    timeZoneInCapital: 'Europe/Prague',
    Currency: 'Euro',
    languageCodes: 'sk,hu',
    Languages:
      'Slovak (official) 78.6%, Hungarian 9.4%, Roma 2.3%, Ruthenian 1%, other or unspecified 8.8% (2011 est.)',
    areaKM2: 48845,
    internetHosts: 1384000,
    internetUsers: 4063000,
    phonesMobile: 6095000,
    phonesLandline: 975000,
    GDP: 96960000000,
    englishShortName: 'Slovakia',
    frenchShortName: 'Slovaquie (la)',
    numeric: 703,
    japaneseShortName: 'スロバキア',
  },
  SI: {
    countryName: 'Slovenia',
    ISO2: 'SI',
    ISO3: 'SVN',
    topLevelDomain: 'si',
    FIPS: 'SI',
    ISONumeric: 705,
    GeoNameID: 3190538,
    E164: 386,
    phoneCode: 386,
    Continent: 'Europe',
    Capital: 'Ljubljana',
    timeZoneInCapital: 'Europe/Belgrade',
    Currency: 'Euro',
    languageCodes: 'sl,sh',
    Languages:
      'Slovenian (official) 91.1%, Serbo-Croatian 4.5%, other or unspecified 4.4%, Italian (official, only in municipalities where Italian national communities reside), Hungarian (official, only in municipalities where Hungarian national communities reside) (2002 census)',
    areaKM2: 20273,
    internetHosts: 415581,
    internetUsers: 1298000,
    phonesMobile: 2246000,
    phonesLandline: 825000,
    GDP: 46820000000,
    englishShortName: 'Slovenia',
    frenchShortName: 'Slovénie (la)',
    numeric: 705,
    japaneseShortName: 'スロベニア',
  },
  SB: {
    countryName: 'Solomon Islands',
    ISO2: 'SB',
    ISO3: 'SLB',
    topLevelDomain: 'sb',
    FIPS: 'BP',
    ISONumeric: '090',
    GeoNameID: 2103350,
    E164: 677,
    phoneCode: 677,
    Continent: 'Oceania',
    Capital: 'Honiara',
    timeZoneInCapital: 'Pacific/Guadalcanal',
    Currency: 'Dollar',
    languageCodes: 'en-SB,tpi',
    Languages:
      'Melanesian pidgin (in much of the country is lingua franca), English (official but spoken by only 1%-2% of the population), 120 indigenous languages',
    areaKM2: 28450,
    internetHosts: 4370,
    internetUsers: 10000,
    phonesMobile: 302100,
    phonesLandline: 8060,
    GDP: 1099000000,
    englishShortName: 'Solomon Islands',
    frenchShortName: 'Salomon (les Îles)',
    numeric: 90,
    japaneseShortName: 'ソロモン諸島',
  },
  SO: {
    countryName: 'Somalia',
    ISO2: 'SO',
    ISO3: 'SOM',
    topLevelDomain: 'so',
    FIPS: 'SO',
    ISONumeric: 706,
    GeoNameID: 51537,
    E164: 252,
    phoneCode: 252,
    Continent: 'Africa',
    Capital: 'Mogadishu',
    timeZoneInCapital: 'Africa/Mogadishu',
    Currency: 'Shilling',
    languageCodes: 'so-SO,ar-SO,it,en-SO',
    Languages: 'Somali (official), Arabic (official, according to the Transitional Federal Charter), Italian, English',
    areaKM2: 637657,
    internetHosts: 186,
    internetUsers: 106000,
    phonesMobile: 658000,
    phonesLandline: 100000,
    GDP: 2372000000,
    englishShortName: 'Somalia',
    frenchShortName: 'Somalie (la)',
    numeric: 706,
    japaneseShortName: 'ソマリア',
  },
  ZA: {
    countryName: 'South Africa',
    ISO2: 'ZA',
    ISO3: 'ZAF',
    topLevelDomain: 'za',
    FIPS: 'SF',
    ISONumeric: 710,
    GeoNameID: 953987,
    E164: 27,
    phoneCode: 27,
    Continent: 'Africa',
    Capital: 'Pretoria',
    timeZoneInCapital: 'Africa/Johannesburg',
    Currency: 'Rand',
    languageCodes: 'zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr',
    Languages:
      'IsiZulu (official) 22.7%, IsiXhosa (official) 16%, Afrikaans (official) 13.5%, English (official) 9.6%, Sepedi (official) 9.1%, Setswana (official) 8%, Sesotho (official) 7.6%, Xitsonga (official) 4.5%, siSwati (official) 2.5%, Tshivenda (official) 2.4%, isiNdebele (official) 2.1%, sign language 0.5%, other 1.6% (2011 est.)',
    areaKM2: 1219912,
    internetHosts: 4761000,
    internetUsers: 4420000,
    phonesMobile: 68400000,
    phonesLandline: 4030000,
    GDP: 353900000000,
    englishShortName: 'South Africa',
    frenchShortName: "Afrique du Sud (l')",
    numeric: 710,
    japaneseShortName: '南アフリカ',
  },
  KR: {
    countryName: 'South Korea',
    ISO2: 'KR',
    ISO3: 'KOR',
    topLevelDomain: 'kr',
    FIPS: 'KS',
    ISONumeric: 410,
    GeoNameID: 1835841,
    E164: 82,
    phoneCode: 82,
    Continent: 'Asia',
    Capital: 'Seoul',
    timeZoneInCapital: 'Asia/Seoul',
    Currency: 'Won',
    languageCodes: 'ko-KR,en',
    Languages: 'Korean, English (widely taught in junior high and high school)',
    areaKM2: 98480,
    internetHosts: 315697,
    internetUsers: 39400000,
    phonesMobile: 53625000,
    phonesLandline: 30100000,
    GDP: 1198000000000,
    englishShortName: 'Korea (the Republic of)',
    frenchShortName: 'Corée (la République de)',
    numeric: 410,
    japaneseShortName: '韓国',
  },
  SS: {
    countryName: 'South Sudan',
    ISO2: 'SS',
    ISO3: 'SSD',
    topLevelDomain: 'ss',
    FIPS: 'OD',
    ISONumeric: 728,
    GeoNameID: 7909807,
    E164: 211,
    phoneCode: 211,
    Continent: 'Africa',
    Capital: 'Juba',
    timeZoneInCapital: 'Africa/Khartoum',
    Currency: 'Pound',
    languageCodes: 'en',
    Languages:
      'English (official), Arabic (includes Juba and Sudanese variants), regional languages include Dinka, Nuer, Bari, Zande, Shilluk',
    areaKM2: 644329,
    phonesMobile: 2000000,
    phonesLandline: 2200,
    GDP: 11770000000,
    englishShortName: 'South Sudan',
    frenchShortName: 'Soudan du Sud (le)',
    numeric: 728,
  },
  ES: {
    countryName: 'Spain',
    ISO2: 'ES',
    ISO3: 'ESP',
    topLevelDomain: 'es',
    FIPS: 'SP',
    ISONumeric: 724,
    GeoNameID: 2510769,
    E164: 34,
    phoneCode: 34,
    Continent: 'Europe',
    Capital: 'Madrid',
    timeZoneInCapital: 'Europe/Madrid',
    Currency: 'Euro',
    languageCodes: 'es-ES,ca,gl,eu,oc',
    Languages: 'Castilian Spanish (official) 74%, Catalan 17%, Galician 7%, and Basque 2%',
    areaKM2: 504782,
    internetHosts: 4228000,
    internetUsers: 28119000,
    phonesMobile: 50663000,
    phonesLandline: 19220000,
    GDP: 1356000000000,
    englishShortName: 'Spain',
    frenchShortName: "Espagne (l')",
    numeric: 724,
    japaneseShortName: 'スペイン',
  },
  LK: {
    countryName: 'Sri Lanka',
    ISO2: 'LK',
    ISO3: 'LKA',
    topLevelDomain: 'lk',
    FIPS: 'CE',
    ISONumeric: 144,
    GeoNameID: 1227603,
    E164: 94,
    phoneCode: 94,
    Continent: 'Asia',
    Capital: 'Colombo',
    timeZoneInCapital: 'Asia/Colombo',
    Currency: 'Rupee',
    languageCodes: 'si,ta,en',
    Languages: 'Sinhala (official and national language) 74%, Tamil (national language) 18%, other 8%',
    areaKM2: 65610,
    internetHosts: 9552,
    internetUsers: 1777000,
    phonesMobile: 19533000,
    phonesLandline: 2796000,
    GDP: 65120000000,
    englishShortName: 'Sri Lanka',
    frenchShortName: 'Sri Lanka',
    numeric: 144,
    japaneseShortName: 'スリランカ',
  },
  SD: {
    countryName: 'Sudan',
    ISO2: 'SD',
    ISO3: 'SDN',
    topLevelDomain: 'sd',
    FIPS: 'SU',
    ISONumeric: 729,
    GeoNameID: 366755,
    E164: 249,
    phoneCode: 249,
    Continent: 'Africa',
    Capital: 'Khartoum',
    timeZoneInCapital: 'Africa/Khartoum',
    Currency: 'Pound',
    languageCodes: 'ar-SD,en,fia',
    Languages: 'Arabic (official), English (official), Nubian, Ta Bedawie, Fur',
    areaKM2: 1861484,
    internetHosts: 99,
    internetUsers: 4200000,
    phonesMobile: 27659000,
    phonesLandline: 425000,
    GDP: 52500000000,
    englishShortName: 'Sudan (the)',
    frenchShortName: 'Soudan (le)',
    numeric: 729,
    japaneseShortName: 'スーダン',
  },
  SR: {
    countryName: 'Suriname',
    ISO2: 'SR',
    ISO3: 'SUR',
    topLevelDomain: 'sr',
    FIPS: 'NS',
    ISONumeric: 740,
    GeoNameID: 3382998,
    E164: 597,
    phoneCode: 597,
    Continent: 'South America',
    Capital: 'Paramaribo',
    timeZoneInCapital: 'America/Paramaribo',
    Currency: 'Dollar',
    languageCodes: 'nl-SR,en,srn,hns,jv',
    Languages:
      'Dutch (official), English (widely spoken), Sranang Tongo (Surinamese, sometimes called Taki-Taki, is native language of Creoles and much of the younger population and is lingua franca among others), Caribbean Hindustani (a dialect of Hindi), Javanese',
    areaKM2: 163270,
    internetHosts: 188,
    internetUsers: 163000,
    phonesMobile: 977000,
    phonesLandline: 83000,
    GDP: 5009000000,
    englishShortName: 'Suriname',
    frenchShortName: 'Suriname (le)',
    numeric: 740,
    japaneseShortName: 'スリナム',
  },
  // SJ: {
  //   countryName: 'Svalbard and Jan Mayen',
  //   ISO2: 'SJ',
  //   ISO3: 'SJM',
  //   topLevelDomain: 'sj',
  //   FIPS: 'SV',
  //   ISONumeric: 744,
  //   GeoNameID: 607072,
  //   E164: 47,
  //   phoneCode: 47,
  //   Continent: 'Europe',
  //   Capital: 'Longyearbyen',
  //   timeZoneInCapital: 'Europe/Oslo',
  //   Currency: 'Krone',
  //   languageCodes: 'no,ru',
  //   Languages: 'Norwegian, Russian',
  //   areaKM2: 62049,
  //   internetHosts: null,
  //   internetUsers: null,
  //   phonesMobile: null,
  //   phonesLandline: null,
  //   GDP: null,
  //   englishShortName: 'Svalbard and Jan Mayen',
  //   frenchShortName: "Svalbard et l'Île Jan Mayen (le)",
  //   numeric: 744,
  //   japaneseShortName: 'スヴァールバル諸島およびヤンマイエン島',
  // },
  SZ: {
    countryName: 'Swaziland',
    ISO2: 'SZ',
    ISO3: 'SWZ',
    topLevelDomain: 'sz',
    FIPS: 'WZ',
    ISONumeric: 748,
    GeoNameID: 934841,
    E164: 268,
    phoneCode: 268,
    Continent: 'Africa',
    Capital: 'Mbabane',
    timeZoneInCapital: 'Africa/Johannesburg',
    Currency: 'Lilangeni',
    languageCodes: 'en-SZ,ss-SZ',
    Languages: 'English (official, used for government business), siSwati (official)',
    areaKM2: 17363,
    internetHosts: 2744,
    internetUsers: 90100,
    phonesMobile: 805000,
    phonesLandline: 48600,
    GDP: 3807000000,
    englishShortName: 'Eswatini',
    frenchShortName: "Eswatini (l')",
    numeric: 748,
    japaneseShortName: 'スワジランド',
  },
  SE: {
    countryName: 'Sweden',
    ISO2: 'SE',
    ISO3: 'SWE',
    topLevelDomain: 'se',
    FIPS: 'SW',
    ISONumeric: 752,
    GeoNameID: 2661886,
    E164: 46,
    phoneCode: 46,
    Continent: 'Europe',
    Capital: 'Stockholm',
    timeZoneInCapital: 'Europe/Stockholm',
    Currency: 'Krona',
    languageCodes: 'sv-SE,se,sma,fi-SE',
    Languages: 'Swedish (official), small Sami- and Finnish-speaking minorities',
    areaKM2: 449964,
    internetHosts: 5978000,
    internetUsers: 8398000,
    phonesMobile: 11643000,
    phonesLandline: 4321000,
    GDP: 552000000000,
    englishShortName: 'Sweden',
    frenchShortName: 'Suède (la)',
    numeric: 752,
    japaneseShortName: 'スウェーデン',
  },
  CH: {
    countryName: 'Switzerland',
    ISO2: 'CH',
    ISO3: 'CHE',
    topLevelDomain: 'ch',
    FIPS: 'SZ',
    ISONumeric: 756,
    GeoNameID: 2658434,
    E164: 41,
    phoneCode: 41,
    Continent: 'Europe',
    Capital: 'Berne',
    timeZoneInCapital: 'Europe/Zurich',
    Currency: 'Franc',
    languageCodes: 'de-CH,fr-CH,it-CH,rm',
    Languages:
      'German (official) 64.9%, French (official) 22.6%, Italian (official) 8.3%, Serbo-Croatian 2.5%, Albanian 2.6%, Portuguese 3.4%, Spanish 2.2%, English 4.6%, Romansch (official) 0.5%, other 5.1%',
    areaKM2: 41290,
    internetHosts: 5301000,
    internetUsers: 6152000,
    phonesMobile: 10460000,
    phonesLandline: 4382000,
    GDP: 646200000000,
    englishShortName: 'Switzerland',
    frenchShortName: 'Suisse (la)',
    numeric: 756,
    japaneseShortName: 'スイス',
  },
  SY: {
    countryName: 'Syria',
    ISO2: 'SY',
    ISO3: 'SYR',
    topLevelDomain: 'sy',
    FIPS: 'SY',
    ISONumeric: 760,
    GeoNameID: 163843,
    E164: 963,
    phoneCode: 963,
    Continent: 'Asia',
    Capital: 'Damascus',
    timeZoneInCapital: 'Asia/Damascus',
    Currency: 'Pound',
    languageCodes: 'ar-SY,ku,hy,arc,fr,en',
    Languages:
      'Arabic (official), Kurdish, Armenian, Aramaic, Circassian (widely understood); French, English (somewhat understood)',
    areaKM2: 185180,
    internetHosts: 416,
    internetUsers: 4469000,
    phonesMobile: 12928000,
    phonesLandline: 4425000,
    GDP: 64700000000,
    englishShortName: 'Syrian Arab Republic (the)',
    frenchShortName: 'République arabe syrienne (la)',
    numeric: 760,
    japaneseShortName: 'シリア',
  },
  TW: {
    countryName: 'Taiwan',
    ISO2: 'TW',
    ISO3: 'TWN',
    topLevelDomain: 'tw',
    FIPS: 'TW',
    ISONumeric: 158,
    GeoNameID: 1668284,
    E164: 886,
    phoneCode: 886,
    Continent: 'Asia',
    Capital: 'Taipei',
    timeZoneInCapital: 'Asia/Taipei',
    Currency: 'Dollar',
    languageCodes: 'zh-TW,zh,nan,hak',
    Languages: 'Mandarin Chinese (official), Taiwanese (Min), Hakka dialects',
    areaKM2: 35980,
    internetHosts: 6272000,
    internetUsers: 16147000,
    phonesMobile: 29455000,
    phonesLandline: 15998000,
    GDP: 484700000000,
    englishShortName: 'Taiwan (Province of China)',
    frenchShortName: 'Taïwan (Province de Chine)',
    numeric: 158,
    japaneseShortName: '台湾',
  },
  TJ: {
    countryName: 'Tajikistan',
    ISO2: 'TJ',
    ISO3: 'TJK',
    topLevelDomain: 'tj',
    FIPS: 'TI',
    ISONumeric: 762,
    GeoNameID: 1220409,
    E164: 992,
    phoneCode: 992,
    Continent: 'Asia',
    Capital: 'Dushanbe',
    timeZoneInCapital: 'Asia/Dushanbe',
    Currency: 'Somoni',
    languageCodes: 'tg,ru',
    Languages: 'Tajik (official), Russian widely used in government and business',
    areaKM2: 143100,
    internetHosts: 6258,
    internetUsers: 700000,
    phonesMobile: 6528000,
    phonesLandline: 393000,
    GDP: 8513000000,
    englishShortName: 'Tajikistan',
    frenchShortName: 'Tadjikistan (le)',
    numeric: 762,
    japaneseShortName: 'タジキスタン',
  },
  TZ: {
    countryName: 'Tanzania',
    ISO2: 'TZ',
    ISO3: 'TZA',
    topLevelDomain: 'tz',
    FIPS: 'TZ',
    ISONumeric: 834,
    GeoNameID: 149590,
    E164: 255,
    phoneCode: 255,
    Continent: 'Africa',
    Capital: 'Dodoma',
    timeZoneInCapital: 'Africa/Dar_es_Salaam',
    Currency: 'Shilling',
    languageCodes: 'sw-TZ,en,ar',
    Languages:
      'Kiswahili or Swahili (official), Kiunguja (name for Swahili in Zanzibar), English (official, primary language of commerce, administration, and higher education), Arabic (widely spoken in Zanzibar), many local languages',
    areaKM2: 945087,
    internetHosts: 26074,
    internetUsers: 678000,
    phonesMobile: 27220000,
    phonesLandline: 161100,
    GDP: 31940000000,
    englishShortName: 'Tanzania, the United Republic of',
    frenchShortName: 'Tanzanie (la République-Unie de)',
    numeric: 834,
    japaneseShortName: 'タンザニア',
  },
  TH: {
    countryName: 'Thailand',
    ISO2: 'TH',
    ISO3: 'THA',
    topLevelDomain: 'th',
    FIPS: 'TH',
    ISONumeric: 764,
    GeoNameID: 1605651,
    E164: 66,
    phoneCode: 66,
    Continent: 'Asia',
    Capital: 'Bangkok',
    timeZoneInCapital: 'Asia/Bangkok',
    Currency: 'Baht',
    languageCodes: 'th,en',
    Languages: 'Thai (official) 90.7%, Burmese 1.3%, other 8%',
    areaKM2: 514000,
    internetHosts: 3399000,
    internetUsers: 17483000,
    phonesMobile: 84075000,
    phonesLandline: 6391000,
    GDP: 400900000000,
    englishShortName: 'Thailand',
    frenchShortName: 'Thaïlande (la)',
    numeric: 764,
    japaneseShortName: 'タイ',
  },
  TG: {
    countryName: 'Togo',
    ISO2: 'TG',
    ISO3: 'TGO',
    topLevelDomain: 'tg',
    FIPS: 'TO',
    ISONumeric: 768,
    GeoNameID: 2363686,
    E164: 228,
    phoneCode: 228,
    Continent: 'Africa',
    Capital: 'Lome',
    timeZoneInCapital: 'Africa/Abidjan',
    Currency: 'Franc',
    languageCodes: 'fr-TG,ee,hna,kbp,dag,ha',
    Languages:
      'French (official, the language of commerce), Ewe and Mina (the two major African languages in the south), Kabye (sometimes spelled Kabiye) and Dagomba (the two major African languages in the north)',
    areaKM2: 56785,
    internetHosts: 1168,
    internetUsers: 356300,
    phonesMobile: 3518000,
    phonesLandline: 225000,
    GDP: 4299000000,
    englishShortName: 'Togo',
    frenchShortName: 'Togo (le)',
    numeric: 768,
    japaneseShortName: 'トーゴ',
  },
  TK: {
    countryName: 'Tokelau',
    ISO2: 'TK',
    ISO3: 'TKL',
    topLevelDomain: 'tk',
    FIPS: 'TL',
    ISONumeric: 772,
    GeoNameID: 4031074,
    E164: 690,
    phoneCode: 690,
    Continent: 'Oceania',
    timeZoneInCapital: 'Pacific/Fakaofo',
    Currency: 'Dollar',
    languageCodes: 'tkl,en-TK',
    Languages:
      'Tokelauan 93.5% (a Polynesian language), English 58.9%, Samoan 45.5%, Tuvaluan 11.6%, Kiribati 2.7%, other 2.5%, none 4.1%, unspecified 0.6%',
    areaKM2: 10,
    internetHosts: 2069,
    internetUsers: 800,
    englishShortName: 'Tokelau',
    frenchShortName: 'Tokelau (les)',
    numeric: 772,
    japaneseShortName: 'トケラウ',
  },
  TO: {
    countryName: 'Tonga',
    ISO2: 'TO',
    ISO3: 'TON',
    topLevelDomain: 'to',
    FIPS: 'TN',
    ISONumeric: 776,
    GeoNameID: 4032283,
    E164: 676,
    phoneCode: 676,
    Continent: 'Oceania',
    Capital: "Nuku'alofa",
    timeZoneInCapital: 'Pacific/Tongatapu',
    Currency: "Pa'anga",
    languageCodes: 'to,en-TO',
    Languages:
      'English and Tongan 87%, Tongan (official) 10.7%, English (official) 1.2%, other 1.1%, uspecified 0.03% (2006 est.)',
    areaKM2: 748,
    internetHosts: 5367,
    internetUsers: 8400,
    phonesMobile: 56000,
    phonesLandline: 30000,
    GDP: 477000000,
    englishShortName: 'Tonga',
    frenchShortName: 'Tonga (les)',
    numeric: 776,
    japaneseShortName: 'トンガ王国',
  },
  TT: {
    countryName: 'Trinidad and Tobago',
    ISO2: 'TT',
    ISO3: 'TTO',
    topLevelDomain: 'tt',
    FIPS: 'TD',
    ISONumeric: 780,
    GeoNameID: 3573591,
    E164: 1,
    phoneCode: '1-868',
    Continent: 'North America',
    Capital: 'Port of Spain',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-TT,hns,fr,es,zh',
    Languages: 'English (official), Caribbean Hindustani (a dialect of Hindi), French, Spanish, Chinese',
    areaKM2: 5128,
    internetHosts: 241690,
    internetUsers: 593000,
    phonesMobile: 1884000,
    phonesLandline: 287000,
    GDP: 27130000000,
    englishShortName: 'Trinidad and Tobago',
    frenchShortName: 'Trinité-et-Tobago (la)',
    numeric: 780,
    japaneseShortName: 'トリニダード・トバゴ',
  },
  TN: {
    countryName: 'Tunisia',
    ISO2: 'TN',
    ISO3: 'TUN',
    topLevelDomain: 'tn',
    FIPS: 'TS',
    ISONumeric: 788,
    GeoNameID: 2464461,
    E164: 216,
    phoneCode: 216,
    Continent: 'Africa',
    Capital: 'Tunis',
    timeZoneInCapital: 'Africa/Tunis',
    Currency: 'Dinar',
    languageCodes: 'ar-TN,fr',
    Languages: 'Arabic (official, one of the languages of commerce), French (commerce), Berber (Tamazight)',
    areaKM2: 163610,
    internetHosts: 576,
    internetUsers: 3500000,
    phonesMobile: 12840000,
    phonesLandline: 1105000,
    GDP: 48380000000,
    englishShortName: 'Tunisia',
    frenchShortName: 'Tunisie (la)',
    numeric: 788,
    japaneseShortName: 'チュニジア',
  },
  TR: {
    countryName: 'Turkey',
    ISO2: 'TR',
    ISO3: 'TUR',
    topLevelDomain: 'tr',
    FIPS: 'TU',
    ISONumeric: 792,
    GeoNameID: 298795,
    E164: 90,
    phoneCode: 90,
    Continent: 'Asia',
    Capital: 'Ankara',
    timeZoneInCapital: 'Europe/Istanbul',
    Currency: 'Lira',
    languageCodes: 'tr-TR,ku,diq,az,av',
    Languages: 'Turkish (official), Kurdish, other minority languages',
    areaKM2: 780580,
    internetHosts: 7093000,
    internetUsers: 27233000,
    phonesMobile: 67680000,
    phonesLandline: 13860000,
    GDP: 821800000000,
    englishShortName: 'Turkey',
    frenchShortName: 'Turquie (la)',
    numeric: 792,
    japaneseShortName: 'トルコ',
  },
  TM: {
    countryName: 'Turkmenistan',
    ISO2: 'TM',
    ISO3: 'TKM',
    topLevelDomain: 'tm',
    FIPS: 'TX',
    ISONumeric: 795,
    GeoNameID: 1218197,
    E164: 993,
    phoneCode: 993,
    Continent: 'Asia',
    Capital: 'Ashgabat',
    timeZoneInCapital: 'Asia/Ashgabat',
    Currency: 'Manat',
    languageCodes: 'tk,ru,uz',
    Languages: 'Turkmen (official) 72%, Russian 12%, Uzbek 9%, other 7%',
    areaKM2: 488100,
    internetHosts: 714,
    internetUsers: 80400,
    phonesMobile: 3953000,
    phonesLandline: 575000,
    GDP: 40560000000,
    englishShortName: 'Turkmenistan',
    frenchShortName: 'Turkménistan (le)',
    numeric: 795,
    japaneseShortName: 'トルクメニスタン',
  },
  TC: {
    countryName: 'Turks and Caicos Islands',
    ISO2: 'TC',
    ISO3: 'TCA',
    topLevelDomain: 'tc',
    FIPS: 'TK',
    ISONumeric: 796,
    GeoNameID: 3576916,
    E164: 1,
    phoneCode: '1-649',
    Continent: 'North America',
    Capital: 'Cockburn Town',
    timeZoneInCapital: 'America/Grand_Turk',
    Currency: 'Dollar',
    languageCodes: 'en-TC',
    Languages: 'English (official)',
    areaKM2: 430,
    internetHosts: 73217,
    englishShortName: 'Turks and Caicos Islands (the)',
    frenchShortName: 'Turks-et-Caïcos (les Îles)',
    numeric: 796,
    japaneseShortName: 'タークス・カイコス諸島',
  },
  TV: {
    countryName: 'Tuvalu',
    ISO2: 'TV',
    ISO3: 'TUV',
    topLevelDomain: 'tv',
    FIPS: 'TV',
    ISONumeric: 798,
    GeoNameID: 2110297,
    E164: 688,
    phoneCode: 688,
    Continent: 'Oceania',
    Capital: 'Funafuti',
    timeZoneInCapital: 'Pacific/Funafuti',
    Currency: 'Dollar',
    languageCodes: 'tvl,en,sm,gil',
    Languages: 'Tuvaluan (official), English (official), Samoan, Kiribati (on the island of Nui)',
    areaKM2: 26,
    internetHosts: 145158,
    internetUsers: 4200,
    phonesMobile: 2800,
    phonesLandline: 1450,
    GDP: 38000000,
    englishShortName: 'Tuvalu',
    frenchShortName: 'Tuvalu (les)',
    numeric: 798,
    japaneseShortName: 'ツバル',
  },
  VI: {
    countryName: 'U.S. Virgin Islands',
    ISO2: 'VI',
    ISO3: 'VIR',
    topLevelDomain: 'vi',
    FIPS: 'VQ',
    ISONumeric: 850,
    GeoNameID: 4796775,
    E164: 1,
    phoneCode: '1-340',
    Continent: 'North America',
    Capital: 'Charlotte Amalie',
    timeZoneInCapital: 'America/Port_of_Spain',
    Currency: 'Dollar',
    languageCodes: 'en-VI',
    Languages: 'English 74.7%, Spanish or Spanish Creole 16.8%, French or French Creole 6.6%, other 1.9% (2000 census)',
    areaKM2: 352,
    internetHosts: 4790,
    internetUsers: 30000,
    phonesMobile: 80300,
    phonesLandline: 75800,
    englishShortName: 'Virgin Islands (U.S.)',
    frenchShortName: 'Vierges des États-Unis (les Îles)',
    numeric: 850,
    japaneseShortName: 'アメリカ領ヴァージン諸島',
  },
  UG: {
    countryName: 'Uganda',
    ISO2: 'UG',
    ISO3: 'UGA',
    topLevelDomain: 'ug',
    FIPS: 'UG',
    ISONumeric: 800,
    GeoNameID: 226074,
    E164: 256,
    phoneCode: 256,
    Continent: 'Africa',
    Capital: 'Kampala',
    timeZoneInCapital: 'Africa/Kampala',
    Currency: 'Shilling',
    languageCodes: 'en-UG,lg,sw,ar',
    Languages:
      'English (official national language, taught in grade schools, used in courts of law and by most newspapers and some radio broadcasts), Ganda or Luganda (most widely used of the Niger-Congo languages, preferred for native language publications in the capital and may be taught in school), other Niger-Congo languages, Nilo-Saharan languages, Swahili, Arabic',
    areaKM2: 236040,
    internetHosts: 32683,
    internetUsers: 3200000,
    phonesMobile: 16355000,
    phonesLandline: 315000,
    GDP: 22600000000,
    englishShortName: 'Uganda',
    frenchShortName: "Ouganda (l')",
    numeric: 800,
    japaneseShortName: 'ウガンダ',
  },
  UA: {
    countryName: 'Ukraine',
    ISO2: 'UA',
    ISO3: 'UKR',
    topLevelDomain: 'ua',
    FIPS: 'UP',
    ISONumeric: 804,
    GeoNameID: 690791,
    E164: 380,
    phoneCode: 380,
    Continent: 'Europe',
    Capital: 'Kiev',
    timeZoneInCapital: 'Europe/Kiev',
    Currency: 'Hryvnia',
    languageCodes: 'uk,ru-UA,rom,pl,hu',
    Languages:
      'Ukrainian (official) 67%, Russian (regional language) 24%, other (includes small Romanian-, Polish-, and Hungarian-speaking minorities) 9%',
    areaKM2: 603700,
    internetHosts: 2173000,
    internetUsers: 7770000,
    phonesMobile: 59344000,
    phonesLandline: 12182000,
    GDP: 175500000000,
    englishShortName: 'Ukraine',
    frenchShortName: "Ukraine (l')",
    numeric: 804,
    japaneseShortName: 'ウクライナ',
  },
  US: {
    countryName: 'United States',
    ISO2: 'US',
    ISO3: 'USA',
    topLevelDomain: 'us',
    FIPS: 'US',
    ISONumeric: 840,
    GeoNameID: 6252001,
    E164: 1,
    phoneCode: 1,
    Continent: 'North America',
    Capital: 'Washington',
    timeZoneInCapital: 'America/New_York',
    Currency: 'Dollar',
    languageCodes: 'en-US,es-US,haw,fr',
    Languages:
      'English 82.1%, Spanish 10.7%, other Indo-European 3.8%, Asian and Pacific island 2.7%, other 0.7% (2000 census)',
    areaKM2: 9629091,
    internetHosts: 505000000,
    internetUsers: 245000000,
    phonesMobile: 310000000,
    phonesLandline: 139000000,
    GDP: 16720000000000,
    englishShortName: 'United States of America (the)',
    frenchShortName: "États-Unis d'Amérique (les)",
    numeric: 840,
    japaneseShortName: 'アメリカ合衆国',
  },
  AE: {
    countryName: 'United Arab Emirates',
    ISO2: 'AE',
    ISO3: 'ARE',
    topLevelDomain: 'ae',
    FIPS: 'AE',
    ISONumeric: 784,
    GeoNameID: 290557,
    E164: 971,
    phoneCode: 971,
    Continent: 'Asia',
    Capital: 'Abu Dhabi',
    timeZoneInCapital: 'Asia/Dubai',
    Currency: 'Dirham',
    languageCodes: 'ar-AE,fa,en,hi,ur',
    Languages: 'Arabic (official), Persian, English, Hindi, Urdu',
    areaKM2: 82880,
    internetHosts: 337804,
    internetUsers: 3449000,
    phonesMobile: 13775000,
    phonesLandline: 1967000,
    GDP: 390000000000,
    englishShortName: 'United Arab Emirates (the)',
    frenchShortName: 'Émirats arabes unis (les)',
    numeric: 784,
    japaneseShortName: 'アラブ首長国連邦',
  },
  GB: {
    countryName: 'United Kingdom',
    ISO2: 'GB',
    ISO3: 'GBR',
    topLevelDomain: 'uk',
    FIPS: 'UK',
    ISONumeric: 826,
    GeoNameID: 2635167,
    E164: 44,
    phoneCode: 44,
    Continent: 'Europe',
    Capital: 'London',
    timeZoneInCapital: 'Europe/London',
    Currency: 'Pound',
    languageCodes: 'en-GB,cy-GB,gd',
    Languages: 'English',
    areaKM2: 244820,
    internetHosts: 8107000,
    internetUsers: 51444000,
    phonesMobile: 82109000,
    phonesLandline: 33010000,
    GDP: 2490000000000,
    englishShortName: 'United Kingdom of Great Britain and Northern Ireland (the)',
    frenchShortName: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
    numeric: 826,
    japaneseShortName: 'イギリス',
  },
  UY: {
    countryName: 'Uruguay',
    ISO2: 'UY',
    ISO3: 'URY',
    topLevelDomain: 'uy',
    FIPS: 'UY',
    ISONumeric: 858,
    GeoNameID: 3439705,
    E164: 598,
    phoneCode: 598,
    Continent: 'South America',
    Capital: 'Montevideo',
    timeZoneInCapital: 'America/Montevideo',
    Currency: 'Peso',
    languageCodes: 'es-UY',
    Languages: 'Spanish (official), Portunol, Brazilero (Portuguese-Spanish mix on the Brazilian frontier)',
    areaKM2: 176220,
    internetHosts: 1036000,
    internetUsers: 1405000,
    phonesMobile: 5000000,
    phonesLandline: 1010000,
    GDP: 57110000000,
    englishShortName: 'Uruguay',
    frenchShortName: "Uruguay (l')",
    numeric: 858,
    japaneseShortName: 'ウルグアイ',
  },
  UZ: {
    countryName: 'Uzbekistan',
    ISO2: 'UZ',
    ISO3: 'UZB',
    topLevelDomain: 'uz',
    FIPS: 'UZ',
    ISONumeric: 860,
    GeoNameID: 1512440,
    E164: 998,
    phoneCode: 998,
    Continent: 'Asia',
    Capital: 'Tashkent',
    timeZoneInCapital: 'Asia/Tashkent',
    Currency: 'Som',
    languageCodes: 'uz,ru,tg',
    Languages: 'Uzbek (official) 74.3%, Russian 14.2%, Tajik 4.4%, other 7.1%',
    areaKM2: 447400,
    internetHosts: 56075,
    internetUsers: 4689000,
    phonesMobile: 20274000,
    phonesLandline: 1963000,
    GDP: 55180000000,
    englishShortName: 'Uzbekistan',
    frenchShortName: "Ouzbékistan (l')",
    numeric: 860,
    japaneseShortName: 'ウズベキスタン',
  },
  VU: {
    countryName: 'Vanuatu',
    ISO2: 'VU',
    ISO3: 'VUT',
    topLevelDomain: 'vu',
    FIPS: 'NH',
    ISONumeric: 548,
    GeoNameID: 2134431,
    E164: 678,
    phoneCode: 678,
    Continent: 'Oceania',
    Capital: 'Port Vila',
    timeZoneInCapital: 'Pacific/Efate',
    Currency: 'Vatu',
    languageCodes: 'bi,en-VU,fr-VU',
    Languages:
      'local languages (more than 100) 63.2%, Bislama (official; creole) 33.7%, English (official) 2%, French (official) 0.6%, other 0.5% (2009 est.)',
    areaKM2: 12200,
    internetHosts: 5655,
    internetUsers: 17000,
    phonesMobile: 137000,
    phonesLandline: 5800,
    GDP: 828000000,
    englishShortName: 'Vanuatu',
    frenchShortName: 'Vanuatu (le)',
    numeric: 548,
    japaneseShortName: 'バヌアツ',
  },
  VA: {
    countryName: 'Vatican',
    ISO2: 'VA',
    ISO3: 'VAT',
    topLevelDomain: 'va',
    FIPS: 'VT',
    ISONumeric: 336,
    GeoNameID: 3164670,
    E164: 39,
    phoneCode: 379,
    Continent: 'Europe',
    Capital: 'Vatican City',
    timeZoneInCapital: 'Europe/Rome',
    Currency: 'Euro',
    languageCodes: 'la,it,fr',
    Languages: 'Latin, Italian, French',
    areaKM2: 0,
    englishShortName: 'Holy See (the)',
    frenchShortName: 'Saint-Siège (le)',
    numeric: 336,
    japaneseShortName: 'バチカン市国',
  },
  VE: {
    countryName: 'Venezuela',
    ISO2: 'VE',
    ISO3: 'VEN',
    topLevelDomain: 've',
    FIPS: 'VE',
    ISONumeric: 862,
    GeoNameID: 3625428,
    E164: 58,
    phoneCode: 58,
    Continent: 'South America',
    Capital: 'Caracas',
    timeZoneInCapital: 'America/Caracas',
    Currency: 'Bolivar',
    languageCodes: 'es-VE',
    Languages: 'Spanish (official), numerous indigenous dialects',
    areaKM2: 912050,
    internetHosts: 1016000,
    internetUsers: 8918000,
    phonesMobile: 30520000,
    phonesLandline: 7650000,
    GDP: 367500000000,
    englishShortName: 'Venezuela (Bolivarian Republic of)',
    frenchShortName: 'Venezuela (République bolivarienne du)',
    numeric: 862,
    japaneseShortName: 'ベネズエラ',
  },
  VN: {
    countryName: 'Vietnam',
    ISO2: 'VN',
    ISO3: 'VNM',
    topLevelDomain: 'vn',
    FIPS: 'VM',
    ISONumeric: 704,
    GeoNameID: 1562822,
    E164: 84,
    phoneCode: 84,
    Continent: 'Asia',
    Capital: 'Hanoi',
    timeZoneInCapital: 'Asia/Ho_Chi_Minh',
    Currency: 'Dong',
    languageCodes: 'vi,en,fr,zh,km',
    Languages:
      'Vietnamese (official), English (increasingly favored as a second language), some French, Chinese, and Khmer, mountain area languages (Mon-Khmer and Malayo-Polynesian)',
    areaKM2: 329560,
    internetHosts: 189553,
    internetUsers: 23382000,
    phonesMobile: 134066000,
    phonesLandline: 10191000,
    GDP: 170000000000,
    englishShortName: 'Viet Nam',
    frenchShortName: 'Viet Nam (le)',
    numeric: 704,
    japaneseShortName: 'ベトナム',
  },
  WF: {
    countryName: 'Wallis and Futuna',
    ISO2: 'WF',
    ISO3: 'WLF',
    topLevelDomain: 'wf',
    FIPS: 'WF',
    ISONumeric: 876,
    GeoNameID: 4034749,
    E164: 681,
    phoneCode: 681,
    Continent: 'Oceania',
    Capital: 'Mata Utu',
    timeZoneInCapital: 'Pacific/Wallis',
    Currency: 'Franc',
    languageCodes: 'wls,fud,fr-WF',
    Languages:
      'Wallisian (indigenous Polynesian language) 58.9%, Futunian 30.1%, French (official) 10.8%, other 0.2% (2003 census)',
    areaKM2: 274,
    internetHosts: 2760,
    internetUsers: 1300,
    englishShortName: 'Wallis and Futuna',
    frenchShortName: 'Wallis-et-Futuna',
    numeric: 876,
    japaneseShortName: 'ウォリス・フツナ',
  },
  EH: {
    countryName: 'Western Sahara',
    ISO2: 'EH',
    ISO3: 'ESH',
    topLevelDomain: 'eh',
    FIPS: 'WI',
    ISONumeric: 732,
    GeoNameID: 2461445,
    E164: 212,
    phoneCode: 212,
    Continent: 'Africa',
    Capital: 'El-Aaiun',
    timeZoneInCapital: 'Africa/El_Aaiun',
    Currency: 'Dirham',
    languageCodes: 'ar,mey',
    Languages: 'Standard Arabic (national), Hassaniya Arabic, Moroccan Arabic',
    areaKM2: 266000,
    englishShortName: 'Western Sahara*',
    frenchShortName: 'Sahara occidental (le)*',
    numeric: 732,
    japaneseShortName: '西サハラ',
  },
  YE: {
    countryName: 'Yemen',
    ISO2: 'YE',
    ISO3: 'YEM',
    topLevelDomain: 'ye',
    FIPS: 'YM',
    ISONumeric: 887,
    GeoNameID: 69543,
    E164: 967,
    phoneCode: 967,
    Continent: 'Asia',
    Capital: 'Sanaa',
    timeZoneInCapital: 'Asia/Aden',
    Currency: 'Rial',
    languageCodes: 'ar-YE',
    Languages: 'Arabic (official)',
    areaKM2: 527970,
    internetHosts: 33206,
    internetUsers: 2349000,
    phonesMobile: 13900000,
    phonesLandline: 1100000,
    GDP: 43890000000,
    englishShortName: 'Yemen',
    frenchShortName: 'Yémen (le)',
    numeric: 887,
    japaneseShortName: 'イエメン',
  },
  ZM: {
    countryName: 'Zambia',
    ISO2: 'ZM',
    ISO3: 'ZMB',
    topLevelDomain: 'zm',
    FIPS: 'ZA',
    ISONumeric: 894,
    GeoNameID: 895949,
    E164: 260,
    phoneCode: 260,
    Continent: 'Africa',
    Capital: 'Lusaka',
    timeZoneInCapital: 'Africa/Maputo',
    Currency: 'Kwacha',
    languageCodes: 'en-ZM,bem,loz,lun,lue,ny,toi',
    Languages:
      'Bembe 33.4%, Nyanja 14.7%, Tonga 11.4%, Lozi 5.5%, Chewa 4.5%, Nsenga 2.9%, Tumbuka 2.5%, Lunda (North Western) 1.9%, Kaonde 1.8%, Lala 1.8%, Lamba 1.8%, English (official) 1.7%, Luvale 1.5%, Mambwe 1.3%, Namwanga 1.2%, Lenje 1.1%, Bisa 1%, other 9.2%, unspecified 0.4%',
    areaKM2: 752614,
    internetHosts: 16571,
    internetUsers: 816200,
    phonesMobile: 10525000,
    phonesLandline: 82500,
    GDP: 22240000000,
    englishShortName: 'Zambia',
    frenchShortName: 'Zambie (la)',
    numeric: 894,
    japaneseShortName: 'ザンビア',
  },
  ZW: {
    countryName: 'Zimbabwe',
    ISO2: 'ZW',
    ISO3: 'ZWE',
    topLevelDomain: 'zw',
    FIPS: 'ZI',
    ISONumeric: 716,
    GeoNameID: 878675,
    E164: 263,
    phoneCode: 263,
    Continent: 'Africa',
    Capital: 'Harare',
    timeZoneInCapital: 'Africa/Maputo',
    Currency: 'Dollar',
    languageCodes: 'en-ZW,sn,nr,nd',
    Languages:
      'English (official), Shona, Sindebele (the language of the Ndebele, sometimes called Ndebele), numerous but minor tribal dialects',
    areaKM2: 390580,
    internetHosts: 30615,
    internetUsers: 1423000,
    phonesMobile: 12614000,
    phonesLandline: 301600,
    GDP: 10480000000,
    englishShortName: 'Zimbabwe',
    frenchShortName: 'Zimbabwe (le)',
    numeric: 716,
    japaneseShortName: 'ジンバブエ',
  },
}
