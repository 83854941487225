import { CommonModule } from '@angular/common'
import { AfterContentInit, Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { AuthService } from 'app/modules/auth'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { UserDashboardDto } from 'app/pages/core/dto/UserDashboardDto'
import { UserService } from 'app/pages/core/services/user.service'
import { ButtonComponent } from 'app/standalone/button/button.component'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonComponent, TranslateModule, RouterLink],
})
export class ChartWidgetComponent implements OnInit, AfterContentInit {
  @Input() cssClass: string = ''
  @Input() description: string
  @Input() chartSize: number = 120
  @Input() chartLine: number = 18
  @Input() chartRotate: number = 145
  @Input() label1: string
  @Input() label2: string
  @Input() label3: string
  @Input() label4: string
  @Input() showDescription: boolean = false
  @Input() isFromUser: boolean = false
  @Input() userId!: string
  @Input() dashboardInfo: BehaviorSubject<UserDashboardDto> = new BehaviorSubject({} as UserDashboardDto)
  stats1: number
  stats2: number
  stats3: number
  stats4: number

  projectNumber: number
  allProjectsButton: string = this.translate.instant('ALL_PROJECTS')
  constructor(
    public userService: UserService,
    public auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.stats1 = 0
    this.stats2 = 0
    this.projectNumber = 0
    var roleAdmin = this.auth.isProjectAdmin
    var roleNormalUser = this.auth.isNormalUser
    var roleBackoffice = this.auth.isProjectBackoffice
    if (roleAdmin && !this.router.url.includes('update-edit-user')) {
      this.dashboardInfo.subscribe((res: UserDashboardDto) => {
        this.stats1 = res?.runningProjects
        this.stats2 = res?.completedProjects
        this.stats3 = res?.draftedProjects
        this.stats4 = res?.pausedProjects
        this.projectNumber = res?.runningProjects + res?.completedProjects + res?.draftedProjects + res?.pausedProjects
        this.initChart()
      })
    } else if (roleAdmin && this.router.url.includes('update-edit-user')) {
      const id = this.activatedRoute.snapshot.paramMap.get('id')
      this.userService.getUserDashboardInfoId(id!).subscribe(
        (res: ApiResponse<UserDashboardDto>) => {
          this.stats1 = res?.response.managerCounter
          this.stats2 = res?.response.memberCounter
          this.projectNumber = res?.response.memberCounter + res?.response.managerCounter
          this.initChart()
        },
        error => {}
      )
    } else if (roleNormalUser || roleBackoffice) {
      this.dashboardInfo.subscribe((res: UserDashboardDto) => {
        if (res?.companyName) {
          this.stats1 = res?.managerCounter
          this.stats2 = res?.memberCounter
          this.projectNumber = res?.memberCounter + res?.managerCounter
          this.initChart()
        }
      })
    }
  }
  ngAfterContentInit() {
    this.translate.onLangChange.subscribe((res: any) => {
      this.allProjectsButton = this.translate.instant('ALL_PROJECTS')
    })
  }
  public initChart() {
    const el = document.getElementById('kt_card_widget_17_chart')

    if (!el || !this.projectNumber) {
      return
    }

    var options = {
      size: this.chartSize,
      lineWidth: this.chartLine,
      rotate: this.chartRotate,
    }

    const canvas = document.createElement('canvas')

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(canvas)

    ctx?.translate(options.size / 2, options.size / 2)

    let startAngle = (-1 / 2 + options.rotate / 180) * Math.PI

    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, percent: number, reverse: boolean = false) {
      percent = Math.min(Math.max(0, percent || 1), 1)
      if (!ctx) {
        return
      }

      const endAngle = startAngle + Math.PI * 2 * percent

      ctx.beginPath()
      ctx.arc(0, 0, radius, startAngle, endAngle, false)
      ctx.strokeStyle = color
      ctx.lineCap = 'round'
      ctx.lineWidth = lineWidth
      ctx.stroke()

      startAngle = endAngle
    }

    if (this.stats3) {
      drawCircle('#E4E6EF', options.lineWidth, 1)
    }
    if (this.stats1 && this.projectNumber) {
      drawCircle('#1BC5BD', options.lineWidth, this.stats1 / this.projectNumber)
    }
    if (this.stats2 && this.projectNumber) {
      drawCircle('#1E1E2D', options.lineWidth, this.stats2 / this.projectNumber, true)
    }
    if (this.stats4 && this.projectNumber) {
      drawCircle('#FFA5AA', options.lineWidth, this.stats4 / this.projectNumber, true)
    }
  }

  public navigateToProjects(): void {
    this.router.navigate(['/projects/projects-list'])
  }
}
