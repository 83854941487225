<div class="table-wrapper">
  <app-table
    class="project-members-table"
    [customHeadings]="tableHeadings"
    *ngIf="tableData"
    [rowClickable]="true"
    [dataList]="tableData"
    [hiddenColumns]="['statusColor', 'id']"
    [sortableColumns]="[]"
    (rowClick)="redirectToAnotherPage($event)"
  ></app-table>
</div>
<app-approve-timesheets-modal
  [openModalSubject$]="openModalApproveTimesheetsSubject$"
  (approveTimesheets)="approveTimesheets($event)"
></app-approve-timesheets-modal>
