<div
  class="button_wrapper"
  [ngClass]="{ 'disable-padding': disableWrapperPadding, 'full-width': fullWidth }"
  (click)="onClick($event)"
>
  <button
    nz-button
    [type]="type"
    [nzType]="buttonType"
    class="button"
    [ngClass]="[
      buttonType,
      size,
      additionalClass,
      fullWidth ? 'full-width' : '',
      active ? 'active' : '',
      noRadius ? 'no-radius' : '',
      alignLeft ? 'align-left' : ''
    ]"
    [disabled]="disabled"
  >
    <img class="button_icon" *ngIf="prefixIcon && !iconIsSvg(prefixIcon)" [src]="prefixIcon" />
    <div
      class="button_icon"
      *ngIf="prefixIcon && iconIsSvg(prefixIcon)"
      [inlineSVG]="prefixIcon"
      [setSVGAttributes]="svgAttributesBySize()"
    ></div>
    {{ title }}
    <img class="button_icon" *ngIf="suffixIcon && !iconIsSvg(suffixIcon)" [src]="suffixIcon" />
    <div
      class="button_icon"
      *ngIf="suffixIcon && iconIsSvg(suffixIcon)"
      [inlineSVG]="suffixIcon"
      [setSVGAttributes]="svgAttributesBySize()"
    ></div>
  </button>
</div>
