import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject } from 'rxjs'
import { ButtonComponent } from '../button/button.component'
import { DynamicModalContent } from './dynamic-modal-content/dynamic-modal-content.component'
import { DynamicContentService } from 'app/pages/core/services/dynamic-content.service'
import { InlineSVGModule } from 'ng-inline-svg-2'

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonComponent, DynamicModalContent, InlineSVGModule],
  template: ` <ng-template #modal let-modal>
    <div class="modal-content p-2">
      <div class="modal-header" style="border: none; padding-bottom: 0; justify-content: flex-end;">
        <button type="button" class="btn btn-sm btn-icon ng-star-inserted" (click)="modal.dismiss('Cross click')">
          <span
            class="svg-icon svg-icon-1"
            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
            onmouseover="this.style.color='#1bc5bd';"
            onmouseout="this.style.color='#c7c3c3';"
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <app-dynamic-modal-content [content]="content"></app-dynamic-modal-content>
      </div>
    </div>
  </ng-template>`,
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('modal') modal!: TemplateRef<any>

  @Input() openModalSubject$: BehaviorSubject<any> = new BehaviorSubject(false)
  @Input() content: any
  @Output() fetchResult: EventEmitter<any> = new EventEmitter<any>()

  constructor(private modalService: NgbModal, private dynamicContentService: DynamicContentService) {}

  ngAfterViewInit(): void {
    this.openModalSubject$.subscribe(res => {
      if (res) {
        this.modalService.open(this.modal, {
          centered: true,
          keyboard: true,
          size: 'lg',
          windowClass: 'custom-modal',
        })
      }
    })

    this.dynamicContentService.contentFormResult.subscribe(res => {
      if (res) {
        this.fetchResult.emit(res)
        this.modalService.dismissAll()
        this.dynamicContentService.setResult(null)
      }
    })
  }
}
