import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { User } from 'app/pages/core/model/flexc-user.model'
import { UserService } from 'app/pages/core/services/user.service'
import { UserSharedService } from '../user-shared.service'
import { BehaviorSubject } from 'rxjs'
import { ModalConfig } from 'app/_metronic/partials'
import { SuccessModalComponent } from 'app/_metronic/partials/layout/modals/success-modal/success-modal.component'
import { ErrorModalComponent } from 'app/_metronic/partials/layout/modals/error-modal/error-modal.component'
import { KeycloakService } from 'keycloak-angular'
import { UserImageSharedService } from '../user-image-shared.service'
import { AuthService } from 'app/modules/auth'
import { environment } from 'environments/environment'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'
import { UserDashboardDto } from 'app/pages/core/dto/UserDashboardDto'
import { CompanyService } from 'app/pages/core/services/company.service'
@Component({
  selector: 'app-update-edit-user',
  templateUrl: './update-edit-user.component.html',
  styleUrls: ['./update-edit-user.component.scss'],
})
export class UpdateEditUserComponent implements OnInit, AfterViewInit {
  public user: User = new User()
  public dataFetched: boolean = false

  @ViewChild('errorModal') private modalComponentError: ErrorModalComponent
  @ViewChild('successModal') private modalComponentSuccess: SuccessModalComponent

  @Output() modalCloseEmitter: EventEmitter<boolean> = new EventEmitter()
  @Output() modalSaveEmitter: EventEmitter<boolean> = new EventEmitter()

  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>('')
  secondaryTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')
  newModalTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')
  userDashboardInfo$: BehaviorSubject<UserDashboardDto> = new BehaviorSubject({} as UserDashboardDto)

  modalConfig: ModalConfig = {
    newModalTitle: this.newModalTitle,
    modalBodyTitle: this.titleForModal,
    secondaryTitle: this.secondaryTitle,
    closeButtonLabel: 'Ok',
    onClose: () => {
      this.modalClose()
      return false
    },
    onDismiss: () => {
      this.modalClose()
      return true
    },
  }

  modalClose() {
    this.modalCloseEmitter.emit(true)
  }

  @Input() userDetailsForm!: FormGroup
  @Input() expertiseProjectsForm!: FormGroup
  @Input() personalProfileForm!: FormGroup

  updateUser: boolean = false
  archived: boolean = false
  id: string = ''
  submitted: boolean = false
  activeUser: boolean | undefined = true
  updateUserForm: FormGroup
  openModalBool: boolean = false
  showCards: boolean = true
  userDashboardInfo: UserDashboardDto
  loggedInUser: User
  redirectUri: string = `${environment}/dashboard`
  currentUser?: User
  languages: any[] = []

  usersDescription: string = this.translate.instant('ACTIVE_USERS')
  projectsDescription: string = this.translate.instant('PROJECT')
  customersDescription: string = this.translate.instant('CUSTOMERS')
  activeDescription: string = this.translate.instant('ACTIVE')
  completedDescription: string = this.translate.instant('COMPLETED')
  draftDescription: string = this.translate.instant('DRAFT_LOWER_CASE')
  pausedDescription: string = this.translate.instant('PAUSED_LOWER_CASE')

  lastLoginDescription: string = this.translate.instant('YOUR_LAST_LOGIN')
  lastTimebookingDescription: string = this.translate.instant('YOUR_LAST_TIMEBOOKING')
  managingDescription: string = this.translate.instant('MANAGING')
  memberOfDescription: string = this.translate.instant('MEMBER_OF')
  yourAssignedProjectsDescription: string = this.translate.instant('YOUR_ASSIGNED_PROJECTS')

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private userSharedService: UserSharedService,
    private _router: Router,
    private keycloakService: KeycloakService,
    private userImageSharedService: UserImageSharedService,
    public authService: AuthService,
    private querySharedService: QuerySharedService,
    private companyService: CompanyService
  ) {
    this.userDetailsForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('^\\s*([a-zA-ZZüöäÜÖÄß-]+\\s*){1,3}$')]],
      lastName: ['', [Validators.required, Validators.pattern('^\\s*([a-zA-ZZZüöäÜÖÄß-]+\\s*){1,3}$')]],
      email: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$')]],
      noEmail: [false],
      jobTitle: ['', [Validators.required, Validators.maxLength(50)]],
      username: ['', [Validators.required]],
      profileImage: [''],
      userRole: [null, Validators.required],
      archived: [false, Validators.required],
      defaultLanguage: [''],
    })

    let languages = []
    let language = this.formBuilder.group({
      language: [, Validators.required],
      skill: [1, [Validators.required, Validators.min(1), Validators.max(5)]],
    })
    languages.push(language)

    this.personalProfileForm = this.formBuilder.group({
      languages: this.formBuilder.array(languages),
      experience: ['', [Validators.maxLength(100)]],
    })

    this.updateUserForm = this.formBuilder.group({
      userDetails: this.userDetailsForm,
      personalProfile: this.personalProfileForm,
    })

    this.expertiseProjectsForm = this.formBuilder.group({
      userKnowladge: this.formBuilder.array([]),
    })
  }

  ngOnInit(): void {
    this.userService.loggedInUser.subscribe(res => {
      this.loggedInUser = res
    })

    this.showCards = this._router.url.includes('profile')

    this.route.params.subscribe(params => {
      this.userDetailsForm.reset()
      this.id = params['id']
      this.userService.getUserByID(this.id).subscribe((res: ApiResponse<User>) => {
        if (res.response.imageUrl) {
          this.userImageSharedService.setImageUrl(res.response.imageUrl)
        } else {
          this.userImageSharedService.clearImageUrsl()
        }
        this.user = res.response
        this.activeUser = this.user.active
        this.fetchUserData(this.user)
      })
    })

    this.userService.getUserDashboardInfo().subscribe(({ response }) => {
      this.userDashboardInfo = response
      this.userDashboardInfo$.next(response)
    })
    this.companyService.getStandardLanguages().subscribe((response: ApiResponse<string[]>) => {
      if (response.response) {
        this.languages = response.response.map(lang => this.getTranslatedLang(lang))
      }
    })
    this.translate.onLangChange.subscribe(() => {
      this.userDetailsForm
        .get('defaultLanguage')
        ?.setValue(this.translateLang(this.userDetailsForm.get('defaultLanguage')?.value))
    })
  }

  ngAfterViewInit(): void {
    this.translate.onLangChange.subscribe(
      res => (this.languages = this.languages.map((i: string) => this.translateLang(i)))
    )
  }

  private fetchUserData(user: User) {
    if (user.noMail) {
      this.userDetailsForm
        .get('email')
        ?.removeValidators([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$'),
        ])
    }
    this.userDetailsForm.addControl('id', this.formBuilder.control(this.user.id, Validators.required))
    this.userDetailsForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      noEmail: user.noMail,
      username: user.username,
      profileImage: user.imageUrl,
      userRole: user.userRole?.id!,
      jobTitle: user.portfolio?.jobTitle,
      email: user.noMail ? '' : user.email,
      archived: user.archived,
      defaultLanguage: this.getTranslatedLang(user.defaultLanguage!),
    })
    this.expertiseProjectsForm.patchValue({
      userKnowladge: user.portfolio?.knowledges,
    })

    this.archived = user.archived
    if (user.portfolio?.spokenLanguages) {
      let languages = this.personalProfileForm.get('languages') as FormArray
      languages.removeAt(0)

      user?.portfolio?.spokenLanguages.forEach(language => {
        let spokenLanguage = this.formBuilder.group({
          id: [language.id, Validators.required],
          language: [language.language, Validators.required],
          skill: [language.level, [Validators.required, Validators.min(1), Validators.max(5)]],
        })
        languages.push(spokenLanguage)
      })
    }
    this.personalProfileForm.patchValue({
      experience: this.user.portfolio?.experience,
    })

    if (this.user.portfolio?.knowHows) {
      this.userSharedService.resetProjects()
      this.user.portfolio?.knowHows.forEach(knowHow => {
        this.userSharedService.setProject(knowHow)
      })
      if (this.user.portfolio?.knowledges) {
        this.userSharedService.setKowladge(this.user.portfolio?.knowledges)
      }
    }
    this.dataFetched = true
  }

  onSubmit() {
    this.submitted = true
    if (!this.userDetailsForm.value.id) {
      this.userDetailsForm.patchValue({
        id: this.id,
      })
      this.setUser()
    } else {
      this.setUser()
    }
  }

  setUser() {
    if (!this.userDetailsForm.valid) {
      return
    }

    const userDetailsFormValue = this.userDetailsForm.value
    const spokenLanguages = this.personalProfileForm.value.languages
      .filter((language: any) => language.language)
      .map((language: any) => ({
        level: language.skill,
        language: language.language,
      }))

    this.user = {
      ...this.user,
      ...userDetailsFormValue,
      email: this.userDetailsForm.get('noEmail')?.value
        ? this.userDetailsForm.get('username')?.value
        : this.userDetailsForm.get('email')?.value,
      userRole: {
        id: this.userDetailsForm.get('userRole')?.value,
      },
      portfolio: {
        ...this.user.portfolio,
        id: this.user.portfolio?.id || undefined,
        jobTitle: userDetailsFormValue.jobTitle,
        experience: this.personalProfileForm.get('experience')?.value,
        knowHows: this.userSharedService.getKnowHows(),
        knowledges: this.userSharedService.getKnowladge(),
        spokenLanguages,
      },
      noMail: this.userDetailsForm.get('noEmail')?.value,
    }
    this.user.defaultLanguage = this.setEnum(this.userDetailsForm.get('defaultLanguage')?.value)
    this.userService.updateUserByID(this.user).subscribe(({ response }: ApiResponse<User>) => {
      if (!response.id) {
        return
      }

      if (this.userImageSharedService.getImageFile()) {
        this.userService.uploadImage(response.id).subscribe(
          ({ response }: ApiResponse<User>) => {
            if (!response.id) {
              return
            }
            this.userImageSharedService.emitImageChange()
            this.openModal()
          },
          (error: any) => {
            if (error.status === 400) {
              this.handleImageBadRequestError()
            }
          }
        )
      } else {
        this.openModal()
      }
    })
  }

  openModal() {
    if (this.authService.currentUserRole === 'PROJECT_ADMIN' && this.id !== this.loggedInUser.id!) {
      this._router.navigate(['users/users-list'])
    }

    this.openSucccessModal()
    this.submitted = false
  }
  openModalDeactivate() {
    this.titleForModal.next('')
    this._router.navigate(['users/users-list'])
    this.submitted = false
  }

  async handleImageBadRequestError() {
    this.submitted = false
    this.titleForModal.next(this.translate.instant('UPDATE_IMAGE_SIZE_ERROR'))
    return await this.modalComponentError.open()
  }

  async openSucccessModal() {
    this.openModalBool = true
    this.modalConfig.closeButtonLabel = 'Ok'
    this.titleForModal.next('')
    return await this.modalComponentSuccess.open()
  }

  async openErrorModal() {
    return await this.modalComponentError.open()
  }
  get userDetailsFormControls() {
    return this.userDetailsForm.controls
  }

  updatePassword() {
    this.keycloakService.login({
      action: 'UPDATE_PASSWORD',
      redirectUri: environment.redirectUri,
    })
  }
  // Trigger only once when BE side if fix, without recursion
  archiveUser(archiveDb: boolean = true) {
    this.userService.archivingUser(this.id).subscribe(({ response }: ApiResponse<User>) => {
      if (!response.id) {
        return
      }
      archiveDb ? this.archiveUser(false) : this.openModalDeactivate()
    })
  }

  deleteUser(userId: string) {
    this.userService.deleteUserById(userId).subscribe((res: ApiResponse<User>) => {
      res.httpStatus === 'OK' ? this.openModalDeleteSuccess() : this.openErrorModal()
    })
  }

  activationMail(userId: string) {
    this.userService.resendActivation(userId).subscribe(({ httpStatus }: ApiResponse<User>) => {
      httpStatus === 'OK' ? this._router.navigate(['users/users-list']) : this.openErrorModal()
    })
  }

  openModalDeleteSuccess() {
    this.titleForModal.next(this.translate.instant('USER_DELETED_SUCCESSFULY'))
    this._router.navigate(['users/users-list'])
    this.modalComponentSuccess.open()
  }

  back(event: any) {
    let query = this.querySharedService.getQueryForRoute('users/users-list')
    this._router.navigate(['users/users-list'], {
      queryParams: query,
      queryParamsHandling: 'merge',
    })
  }

  ngAfterContentInit() {
    this.translate.onLangChange.subscribe((res: any) => {
      this.usersDescription = this.translate.instant('ACTIVE_USERS')
      this.projectsDescription = this.translate.instant('PROJECT')
      this.customersDescription = this.translate.instant('CUSTOMERS')
      this.activeDescription = this.translate.instant('ACTIVE')
      this.completedDescription = this.translate.instant('COMPLETED')
      this.draftDescription = this.translate.instant('DRAFT_LOWER_CASE')
      this.pausedDescription = this.translate.instant('PAUSED_LOWER_CASE')

      this.lastLoginDescription = this.translate.instant('YOUR_LAST_LOGIN')
      this.lastTimebookingDescription = this.translate.instant('YOUR_LAST_TIMEBOOKING')
      this.managingDescription = this.translate.instant('MANAGING')
      this.memberOfDescription = this.translate.instant('MEMBER_OF')
      this.yourAssignedProjectsDescription = this.translate.instant('YOUR_ASSIGNED_PROJECTS')
    })
  }

  getLanguage(event: any) {
    this.userDetailsForm.get('defaultLanguage')?.setValue(event)
  }

  getTranslatedLang(lang: string) {
    switch (lang) {
      case 'EN':
        return this.translate.instant('ENGLISH')
      case 'DE':
        return this.translate.instant('GERMAN')
      default:
        return this.translate.instant('SERBIAN')
    }
  }

  setEnum(lang: string) {
    switch (lang) {
      case 'English':
        return 'EN'
      case 'German':
        return 'DE'
      case 'Serbian':
        return 'SR'
      case 'Englisch':
        return 'EN'
      case 'Deutsch':
        return 'DE'
      case 'Serbisch':
        return 'SR'
      case 'Engleski':
        return 'EN'
      case 'Nemački':
        return 'DE'
      case 'Srpski':
        return 'SR'
    }
  }

  translateLang(lang: string) {
    switch (lang) {
      case 'English':
        return this.translate.instant('ENGLISH')
      case 'German':
        return this.translate.instant('GERMAN')
      case 'Serbian':
        return this.translate.instant('SERBIAN')
      case 'Englisch':
        return this.translate.instant('ENGLISH')
      case 'Deutsch':
        return this.translate.instant('GERMAN')
      case 'Serbisch':
        return this.translate.instant('SERBIAN')
      case 'Engleski':
        return this.translate.instant('ENGLISH')
      case 'Nemački':
        return this.translate.instant('GERMAN')
      case 'Srpski':
        return this.translate.instant('SERBIAN')
    }
  }
}
