import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { Notification } from '../model/notification.model'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationUrl: string = environment.apiBase + '/event'

  private _numberOfUnreadNotifications: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  constructor(private http: HttpClient) {}

  getAllNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>(this.notificationUrl + `/get-notifications`)
  }

  getNumberOfUnreadNotifications(): Observable<number> {
    return this.http.get<number>(this.notificationUrl + `/get-unread-notifications-count`)
  }

  getNumberOfUnreads(): Observable<number> {
    return this._numberOfUnreadNotifications.asObservable()
  }

  initNotifications() {
    this.getNumberOfUnreadNotifications().subscribe((res: number) => {
      this._numberOfUnreadNotifications.next(res)
    })
  }

  setAllNotificationsToRead(body: any) {
    this.http.post(this.notificationUrl + `/update-read-status`, body).subscribe((res: any) => {
      this.getNumberOfUnreadNotifications().subscribe(res => this._numberOfUnreadNotifications.next(res))
    })
  }

  increasNotificationsByOne() {
    let val = this._numberOfUnreadNotifications.getValue() + 1
    this._numberOfUnreadNotifications.next(val)
  }
}
