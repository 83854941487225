import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Customer } from 'app/pages/core/model/flexc-customer.model'
import { CustomerService } from 'app/pages/core/services/customer.service'
import { SharedService } from '../../shared.service'
import { Observable, debounceTime, switchMap } from 'rxjs'
import { ProjectService } from 'app/pages/core/services/project.service'
import { UserImageSharedService } from 'app/pages/users/user-image-shared.service'
import { CreateCustomerComponent } from 'app/pages/customers/createcustomer/createcustomer.component'

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss'],
})
export class ProjectInfoComponent implements OnInit {
  @Input() projectInfoForm: FormGroup
  @Input() submittedTrue: boolean
  @Input() projectOverview: boolean
  @Input() firstFormValid: boolean
  @Input() projectNameTaken: any
  @Input() projectNumberTaken: any
  @Input() projectOfferTaken: any

  public customers: Customer[] = []
  public customerPlaceholder: string = 'Select customer...'
  public imageSrc: string = ''
  projectNumberExist: boolean = false
  projectNumber: string = ''
  projectOffer: string = ''
  projectId?: string
  image?: File
  addNewOptions: any = {
    text: 'ADD_NEW_CUSTOMER',
    content: CreateCustomerComponent,
  }

  constructor(
    private customerService: CustomerService,
    private sharedService: SharedService,
    public projectService: ProjectService,
    private imageSharedService: UserImageSharedService
  ) {}

  ngOnInit(): void {
    this.projectInfoForm.controls['customer']?.valueChanges.subscribe(result => {
      if (result) {
        this.customerPlaceholder = ''
      } else {
        this.customerPlaceholder = 'Select customer...'
      }
    })
    this.sortCustomer()
  }

  sortCustomer() {
    this.customerService.getAllUnarchivedCustomers().subscribe((res: ApiResponse<Customer[]>) => {
      this.customers = res.response
      this.customers.sort((a: Customer, b: Customer) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase()))
      this.customers.forEach(element => {
        element.label = element.name
      })
    })
  }

  ngAfterViewInit() {
    this.projectInfoForm
      .get('projectNumber')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          this.projectNumberTaken = false
          this.projectNumber = value
          return this.searchRequestProjectNumber(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.projectNumberTaken = result
        this.sharedService.setProjectNumberExist(this.projectNumberTaken)
      })
    this.projectInfoForm
      .get('projectOffer')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          this.projectOfferTaken = false
          this.projectOffer = value
          return this.searchRequestProjectOffer(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.projectOfferTaken = result
        this.sharedService.setProjectOfferExist(this.projectOfferTaken)
      })
    this.projectInfoForm
      .get('projectName')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          this.projectNameTaken = false
          return this.searchRequestProjectName(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.projectNameTaken = result
        this.sharedService.setProjectNameExist(this.projectNameTaken)
      })
  }

  searchRequestProjectNumber(projectNumber: any): Observable<ApiResponse<Boolean>> {
    return this.projectService.isProjectNumberTaken(projectNumber)
  }

  searchRequestProjectOffer(projectOffer: any): Observable<ApiResponse<Boolean>> {
    return this.projectService.isProjectOfferTaken(projectOffer)
  }

  searchRequestProjectName(projectName: any): Observable<ApiResponse<Boolean>> {
    return this.projectService.isProjectNameTaken(projectName)
  }

  readURL(event: any) {
    const file = (event.target as HTMLInputElement).files![0]
    this.sharedService.setImageFile(file)
    const reader = new FileReader()
    reader.onload = () => {
      this.imageSrc = reader.result as string
      this.projectInfoForm.get('image')?.setValue(file)
    }
    reader.readAsDataURL(file)
  }

  setValueToCustomer(event: any) {
    this.projectInfoForm.controls['customer'].setValue(event)
  }

  get formControls() {
    return this.projectInfoForm.controls
  }

  deleteImage() {
    this.imageSrc = 'assets/media/logos/ICON_USER.svg'
    if (!this.projectId || !this.imageSrc) {
      return
    }
    this.projectService.deleteProjectImage(this.projectId).subscribe({
      next: res => {
        this.image = undefined
        this.imageSharedService.setImageFile(undefined)
      },
    })
  }
}
