import { Component, EventEmitter, forwardRef, Input, Output, Provider } from '@angular/core'
import { CommonModule } from '@angular/common'
import { coerceBooleanProperty, BooleanInput } from '@angular/cdk/coercion'
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms'
import { INPUT_SYZES, SEARCH_ICON } from './input.component.model'
import { InlineSVGModule } from 'ng-inline-svg-2'

export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true,
}

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, FormsModule, InlineSVGModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [WRAPPER_CONTROL_VALUE_ACCESSOR],
})
export class InputComponent implements ControlValueAccessor {
  @Input() type: string = 'text'
  @Input() size: string = INPUT_SYZES.MEDIUM
  @Input() label!: string
  @Input() name: string
  @Input() disabled: boolean = false
  @Input() placeholder: string = ''
  @Input() inputDisable: boolean = false
  @Output() statusSelectChange = new EventEmitter()
  private onChangeCallback: (_: number) => void = () => {}
  private onTouchedCallback: () => void = () => {}
  private _innerValue: number
  private _fullWidth: boolean
  private _search: boolean
  public searchIcon = SEARCH_ICON

  public get innerValue(): number {
    return this._innerValue
  }

  public set innerValue(newValue: number) {
    this._innerValue = newValue
    this.onChangeCallback(newValue)
  }

  @Input()
  get fullWidth() {
    return this._fullWidth
  }
  set fullWidth(value: BooleanInput) {
    this._fullWidth = coerceBooleanProperty(value)
  }

  @Input()
  get search() {
    return this._search
  }
  set search(value: BooleanInput) {
    this._search = coerceBooleanProperty(value)
  }

  public onBlur() {
    this.onTouchedCallback()
  }

  public writeValue(value: number) {
    if (value !== this.innerValue) {
      this.innerValue = value
    }
  }

  public registerOnChange(callback: (_: number) => void) {
    this.onChangeCallback = callback
  }

  public registerOnTouched(callback: () => void) {
    this.onTouchedCallback = callback
  }
  public modelChange(event: any) {
    this.statusSelectChange.emit(event)
  }
}
