<div class="container rounded mt-5 mb- 5">
  <app-error-modal #errorModal [modalConfig]="modalConfig"></app-error-modal>
  <app-success-modal #successModal [modalConfig]="modalConfig"></app-success-modal>
  <app-modal #modal [modalConfig]="modalConfig"></app-modal>
  <div class="card">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
      *ngIf="updateUser"
    >
      <div *ngIf="updateUser" class="archive-btn">
        <div class="card-title m-0 fw-bolder m-0">
          {{ 'USER.UPDATE_USER_CAPTION' | translate }}
        </div>
        <button *ngIf="!this.user.archived" type="button" class="btn btn-light ms-5" (click)="openModal()">
          {{ 'CUSTOMER.CONTACT_PERSON.ARCHIVE_BUTTON' | translate }}
        </button>
        <button *ngIf="this.user.archived" type="button" class="btn btn-light ms-5" (click)="openModal()">
          {{ 'CUSTOMER.CONTACT_PERSON.UNARCHIVE_BUTTON' | translate }}
        </button>
      </div>
    </div>
    <div class="collapse show">
      <div class="card-body border-top">
        <div class="d-flex flex-wrap align-items-center mb-10">
          <div id="kt_signin_password_edit" class="flex-row-fluid false">
            <app-button
              [title]="'BACK' | translate"
              buttonType="link"
              size="large"
              prefixIcon="assets/media/icons/duotune/arrows/arr074.svg"
              (handleClick)="back($event)"
            ></app-button>
            <app-user-details [userDetailsForm]="userDetailsForm" [submitted]="submitted"></app-user-details>
            <div class="save_button">
              <button type="button" class="btn btn-primary" (click)="nextStep()">
                {{ 'SAVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
