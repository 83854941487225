import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, finalize } from 'rxjs'
import { LoaderService } from './loader.service'
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0

  constructor(private loadingService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalRequests++
    if (
      !request.url.includes('api/users/is-username-taken') &&
      !request.url.includes('api/users/is-email-taken') &&
      !request.url.includes('api/companies/is-name-taken') &&
      !request.url.includes('api/users/get-users-not-in-project') &&
      !request.urlWithParams.includes('searchInput') &&
      !request.url.includes('/exists-by-project-field') &&
      !request.url.includes('/api/customers/is-name-taken')
    ) {
      this.loadingService.setLoading(true)
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false)
        }
      })
    )
  }
}
