import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'

@Pipe({
  name: 'tableFilter',
  standalone: true,
})
export class TableFilterPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(list: any[], query: string, hiddenColumns: string[] = []): any {
    return query?.length
      ? list.filter(item =>
          JSON.stringify(
            Object.keys(item)
              .filter(
                key =>
                  !hiddenColumns.includes(key) &&
                  !key.toLowerCase().includes('url') &&
                  !key.toLowerCase().includes('image') &&
                  !key.toLowerCase().includes('link') &&
                  !key.toLowerCase().includes('team')
              )
              .map(key =>
                item[key]?.type === TABLE_TYPE.TRANSLATED_STRING ? this.translate.instant(item[key].string) : item[key]
              )
          )
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      : list
  }
}
