import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ModalConfig } from '../modal.config'
import { ModalComponent } from '../modal/modal.component'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-modal-delete-superuser',
  templateUrl: './modal-delete-superuser.component.html',
  styleUrls: ['./modal-delete-superuser.component.scss'],
})
export class ModalDeleteSuperuserComponent implements OnInit {
  @Input() public modalConfig: ModalConfig
  @ViewChild('modalDeleteSuperUser') private modalContent: TemplateRef<ModalComponent>

  private modalRef: NgbModalRef
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}
  open(param?: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {
        backdrop: 'static',
        centered: true,
        keyboard: true,
        ...param,
      })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.disableDismissButton !== undefined) {
      return
    }

    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }
}
