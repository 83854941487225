import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { NzProgressModule } from 'ng-zorro-antd/progress'

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NzProgressModule],
})
export class ProgressBarComponent implements OnInit {
  @Input() percentage: any
  @Input() daysLeft: any
  @Input() total: any

  constructor() {}

  ngOnInit(): void {}
}
