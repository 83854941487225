import { AfterViewInit, Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Customer } from 'app/pages/core/model/flexc-customer.model'
import { CustomerService } from 'app/pages/core/services/customer.service'
import { ProjectService } from 'app/pages/core/services/project.service'
import { CreateCustomerComponent } from 'app/pages/customers/createcustomer/createcustomer.component'
import { UserImageSharedService } from 'app/pages/users/user-image-shared.service'
import { debounceTime, switchMap, Observable } from 'rxjs'
import { SharedService } from '../../shared.service'
import { Project } from 'app/pages/core/model/project.model'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-create-project-step-one',
  templateUrl: './create-project-step-one.component.html',
  styleUrls: ['./create-project-step-one.component.scss'],
})
export class CreateProjectStepOneComponent implements OnInit, AfterViewInit {
  projectNameTaken: any
  projectNumberTaken: any
  projectOfferTaken: any
  submitted: boolean = false
  projectInfoForm: FormGroup
  customers: Customer[] = []
  selectedCustomer: Customer
  imageSrc: string = ''
  projectNumber: string = ''
  projectOffer: string = ''
  projectId: string
  image?: File
  addNewOptions: any = {
    text: 'ADD_NEW_CUSTOMER',
    content: CreateCustomerComponent,
  }
  projectData: Project
  isEdit: boolean = false

  constructor(
    private customerService: CustomerService,
    private sharedService: SharedService,
    private projectService: ProjectService,
    private imageSharedService: UserImageSharedService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.projectId = params['id']
      //check if is from edit project, so we do not change to next step but go back to overview
      this.isEdit = this._router.url.includes('edit')
      this.setupForm()
      this.getProjectData()
    })
  }

  ngAfterViewInit() {
    this.projectInfoForm
      .get('projectNumber')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          this.projectNumberTaken = false
          this.projectNumber = value
          return this.searchRequestProjectNumber(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.projectNumberTaken = result
        if (
          this.projectData.projectNumber &&
          this.projectData.projectNumber === this.projectInfoForm.value.projectNumber
        ) {
          this.projectNumberTaken = false
        }
        this.sharedService.setProjectNumberExist(this.projectNumberTaken)
      })
    this.projectInfoForm
      .get('projectOffer')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          this.projectOfferTaken = false
          this.projectOffer = value
          return this.searchRequestProjectOffer(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.projectOfferTaken = result
        if (
          this.projectData.projectOffer &&
          this.projectData.projectOffer === this.projectInfoForm.value.projectOffer
        ) {
          this.projectOfferTaken = false
        }
        this.sharedService.setProjectOfferExist(this.projectOfferTaken)
      })
    this.projectInfoForm
      .get('name')!
      .valueChanges.pipe(
        debounceTime(500),
        switchMap(value => {
          this.projectNameTaken = false
          return this.searchRequestProjectName(value)
        })
      )
      .subscribe((result: ApiResponse<Boolean>) => {
        this.projectNameTaken = result
        if (this.projectData.name && this.projectData.name === this.projectInfoForm.value.name) {
          this.projectNameTaken = false
        }
        this.sharedService.setProjectNameExist(this.projectNameTaken)
      })
  }

  setupForm() {
    this.projectInfoForm = this.fb.group({
      id: this.projectId,
      name: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', [Validators.required, Validators.minLength(2)]],
      customer: ['', [Validators.required]],
      imageUrl: [''],
      archived: false,
      projectNumber: [''],
      projectOffer: [''],
    })
  }

  searchRequestProjectNumber(projectNumber: any): Observable<ApiResponse<Boolean>> {
    return this.projectService.isProjectNumberTaken(projectNumber)
  }

  searchRequestProjectOffer(projectOffer: any): Observable<ApiResponse<Boolean>> {
    return this.projectService.isProjectOfferTaken(projectOffer)
  }

  searchRequestProjectName(projectName: any): Observable<ApiResponse<Boolean>> {
    return this.projectService.isProjectNameTaken(projectName)
  }

  readURL(event: any) {
    const file = (event.target as HTMLInputElement).files![0]
    const reader = new FileReader()
    this.projectService.uploadProjectImage(this.projectId, file).subscribe(({ response }: any) => {
      this.projectInfoForm.controls['imageUrl'].setValue(response.imageUrl)
    })
    reader.onload = () => {
      this.imageSrc = reader.result as string
    }
    reader.readAsDataURL(file)
  }

  setValueToCustomer(event: any) {
    this.projectInfoForm.controls['customer'].setValue(event)
  }

  get formControls() {
    return this.projectInfoForm.controls
  }

  deleteImage() {
    this.imageSrc = 'assets/media/logos/ICON_PROJECT.svg'
    if (!this.projectId || !this.imageSrc) {
      return
    }
    this.projectService.deleteProjectImage(this.projectId).subscribe({
      next: res => {
        this.image = undefined
        this.imageSharedService.setImageFile(undefined)
      },
    })
  }

  sortCustomer() {
    this.customerService.getAllUnarchivedCustomers().subscribe((res: ApiResponse<Customer[]>) => {
      this.customers = res.response
      if (
        this.projectData?.customer &&
        this.customers.find((val: Customer) => val.id === this.projectData?.customer?.id) === undefined
      ) {
        this.customers.push(this.projectData.customer)
      }
      this.customers?.sort((a: Customer, b: Customer) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase()))
      this.customers?.forEach(element => {
        element.label = element.name
      })
    })
  }

  getProjectData() {
    this.projectService.getOneProject(this.projectId).subscribe(({ response }: ApiResponse<Project>) => {
      this.projectData = response
      this.projectInfoForm.patchValue(this.projectData)
      this.selectedCustomer = this.projectInfoForm.value.customer
      if (response.imageUrl) {
        this.imageSrc = environment.imgUrl + response.imageUrl
      }
      this.sortCustomer()
    })
  }

  saveProject() {
    this.submitted = true
    if (!this.projectInfoForm.valid) return
    this.projectData = { ...this.projectData, ...this.projectInfoForm.value }
    this.projectService.updateProject(this.projectData).subscribe(({ response }: ApiResponse<Project>) => {
      if (response) {
        if (this.isEdit) {
          this.sharedService.setProjectGuard(response)
        }
        this._router.navigate(['../../step-2/', this.projectId], { relativeTo: this.route })
      }
    })
  }
}
