export const locale = {
  lang: 'ru',
  data: {
    DATE_AND_STATUS: 'TEST_RU',
    DESCRIPTION: 'TEST_RU',
    START_TIME: 'TEST_RU',
    START_BREAK: 'TEST_RU',
    END_BREAK: 'TEST_RU',
    END_TIME: 'TEST_RU',
    SUM_HOURS: 'TEST_RU',
    PROGRESS: 'TEST_RU',
    APPROVED: 'TEST_RU',
    PDF_PRINT: 'TEST_RU',
    ADD_NEW_ENTRY: 'TEST_RU',
    ADD_NEW_ENTRY_FOR: 'TEST_RU',
    DATE: 'TEST_RU',
    FILE_UPLOAD: 'TEST_RU',
    TASK: 'TEST_RU',
    PLACEHOLDER: 'TEST_RU',
    ALL_PROJECTS: 'TEST_RU',
    ENTRIES: 'TEST_RU',
    TASK_OVERVIEW: 'TEST_RU',
    CUSTOMERS: 'TEST_RU',
    LIST_OF_CUSTOMERS: 'TEST_RU',
    COMPANY_TASK_OVERVIEW: 'TEST_RU',
    PROJECT_TASK_OVERVIEW: 'TEST_RU',
    CREATED: 'TEST_RU',
    TASK_NAME: 'TEST_RU',
    USAGE: 'TEST_RU',
    TASK_NAME_IS_REQUIRED: 'TEST_RU',
    DESCRIPTION_IS_REQUIRED: 'TEST_RU',
    PROJECT_IS_REQUIRED: 'TEST_RU',
    ENTER_NEW_COMPANY_TASK: 'TEST_RU',
    DISCARD: 'TEST_RU',
    SAVE_AND_CLOSE: 'TEST_RU',
    ADD_NEW_TASK: 'TEST_RU',
    ENTER_NEW_PROJECT_TASK: 'TEST_RU',
    NO_DATA_TO_SHOW: 'TEST_RU',
    PROJECT_WILL_START_SOON: 'TEST_RU',
    PROJECT: 'TEST_RU',
    SHOWING: 'TEST_RU',
    TO: 'TEST_RU',
    OF: 'TEST_RU',
    USERS: 'TEST_RU',
    COMPANIES: 'TEST_RU',
    ADD_SUPER_ADMIN: 'TEST_RU',
    SUPER_ADMIN_LIST: 'TEST_RU',
    ADD_COMPANY: 'TEST_RU',
    COMPANY_LIST: 'TEST_RU',
    SUPER_ADMIN_MENU: 'TEST_RU',
    SUPER_ADMIN_COMPANY_LIST: 'TEST_RU',
    ID: 'TEST_RU',
    USERNAME: 'TEST_RU',
    FIRST_NAME: 'TEST_RU',
    LAST_NAME: 'TEST_RU',
    COMPANY: 'TEST_RU',
    EMAIL: 'TEST_RU',
    ENTER_COMPANY_NAME: 'TEST_RU',
    COMPANY_ADMIN: 'TEST_RU',
    COMPANY_ADMIN_FULL_NAME: 'TEST_RU',
    ENTER_COMPANY_ADMIN_FULL_NAME: 'TEST_RU',
    COMPANY_ADMIN_EMAIL: 'TEST_RU',
    ENTER_COMPANY_ADMIN_EMAIL: 'TEST_RU',
    COMPANY_ADMIN_PHONE_NUMBER: 'TEST_RU',
    ENTER_COMPANY_ADMIN_PHONE_NUMBER: 'TEST_RU',
    COMPANY_NAME: 'TEST_RU',
    COMPANY_NAME_IS_REQUIRED: 'TEST_RU',
    STREET_NAME_IS_REQUIRED: 'TEST_RU',
    STREET_NUMBER_IS_REQUIRED: 'TEST_RU',
    POSTCODE_IS_REQUIRED: 'TEST_RU',
    CITY_IS_REQUIRED: 'TEST_RU',
    STATE_IS_REQUIRED: 'TEST_RU',
    COMPANY_ADMIN_FULL_NAME_IS_REQUIRED: 'TEST_RU',
    COMPANY_ADMIN_EMAIL_IS_REQUIRED: 'TEST_RU',
    COMPANY_ADMIN_PHONE_NUMBER_IS_REQUIRED: 'TEST_RU',
    CREATE_COMPANY: 'TEST_RU',
    SUPER_ADMIN_SUPER_USER_LIST: 'TEST_RU',
    COMPANY_NR: 'TEST_RU',
    CUSTOMER_SINCE: 'TEST_RU',
    STATUS: 'TEST_RU',
    ARCHIVED_USER: 'TEST_RU',
    ACCEPTED: 'TEST_RU',
    DECLINED: 'TEST_RU',
    DRAFT: 'TEST_RU',
    OFFER: 'TEST_RU',
    RUNNING: 'TEST_RU',
    PAUSED: 'TEST_RU',
    FINISHED: 'TEST_RU',
    CREATE_SUPER_USER_ADMIN: 'TEST_RU',
    SUPER_USER_USERNAME: 'TEST_RU',
    ENTER_SUPER_USER_USERNAME: 'TEST_RU',
    SUPER_USER_USERNAME_IS_REQUIRED: 'TEST_RU',
    SUPER_USER_FIRST_NAME: 'TEST_RU',
    ENTER_SUPER_USER_FIRST_NAME: 'TEST_RU',
    SUPER_USER_FIRST_NAME_IS_REQUIRED: 'TEST_RU',
    SUPER_USER_LAST_NAME: 'TEST_RU',
    ENTER_SUPER_USER_LAST_NAME: 'TEST_RU',
    SUPER_USER_LAST_NAME_IS_REQUIRED: 'TEST_RU',
    SUPER_USER_EMAIL: 'TEST_RU',
    ENTER_SUPER_USER_EMAIL: 'TEST_RU',
    SUPER_USER_EMAIL_IS_REQUIRED: 'TEST_RU',
    CREATE_SUPER_USER: 'TEST_RU',
    FIVE_DAYS_A_WEEK: 'TEST_RU',
    SIX_DAYS_A_WEEK: 'TEST_RU',
    SEVEN_DAYS_A_WEEK: 'TEST_RU',
    BOOK_YOUR_TIME: 'TEST_RU',
    DAYS: 'TEST_RU',
    PLANED_DAYS_GREATHER_THAN_TOTAL_WORKING_DAYS: 'TEST_RU',
    HELLO: 'TEST_RU',
    ENTER_JOB_TITLE: 'TEST_RU',
    JOB_TITLE_IS_REQUIRED: 'TEST_RU',
    SKILL_LEVEL: 'TEST_RU',
    COUNTRY: 'TEST_RU',
    START: 'TEST_RU',
    END: 'TEST_RU',
    PROJECT_DESCRIPTION: 'TEST_RU',
    ENTER_COUNTRY: 'TEST_RU',
    ENTER_PROJECT_NAME: 'TEST_RU',
    ENTER_PROJECT_DESCRIPTION: 'TEST_RU',
    CUSTOMER_CREATED_SUCCESSFULLY: 'TEST_RU',
    CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG: 'TEST_RU',
    USER_SAVED_SUCCESSFULY: 'TEST_RU',
    EXPERTISE: 'TEST_RU',
    KNOW_HOW: 'TEST_RU',
    PROJECTS: 'TEST_RU',
    SPOKEN_LANAGUES: 'TEST_RU',
    ADD_PERSONAL_EXPERIENCE: 'TEST_RU',
    ADD_LANGUAGE: 'TEST_RU',
    KNOWLEDGE_OVERVIEW: 'TEST_RU',
    NAME: 'TEST_RU',
    ADD_KNOWLEDGE: 'TEST_RU',
    KNOWLEDGE_NAME: 'TEST_RU',
    ENTER_NEW_KNOWLEDGE: 'TEST_RU',
    KNOWLEDGE_NAME_IS_REQUIRED: 'TEST_RU',
    KNOWLEDGE_CREATED_SUCCESSFULLY: 'TEST_RU',
    KNOWLEDGE_NOT_CREATED_SOMETHING_WENT_WRONG: 'TEST_RU',
    ENTER_COMPANY_TASK: 'TEST_RU',
    ENTER_PROJECT_TASK: 'TEST_RU',
    TASK_CREATED_SUCCESSFULLY: 'TEST_RU',
    TASK_NOT_CREATED_SOMETHING_WENT_WRONG: 'TEST_RU',
    OVERVIEW: 'TEST_RU',
    ADD: 'TEST_RU',
    USER: 'TEST_RU',
    CUSTOMER: 'TEST_RU',
    DASHBOARD: 'TEST_RU',
    PROJECT_OVERVIEW: 'TEST_RU',
    TASK_WITH_THAT_NAME_ALREADY_EXIST: 'TEST_RU',
    KNOWLEADGE_ALREADY_EXIST: 'TEST_RU',
    DATE_IS_REQUIRED: 'TEST_RU',
    LEFT: 'TEST_RU',
    DONE: 'TEST_RU',
    START_WORK: 'TEST_RU',
    START_WORK_TIME_IS_REQUIRED: 'TEST_RU',
    BREAK_TIME_CANT_START_BEFORE_YOU_START_WORKING: 'TEST_RU',
    BREAK_TIME_END_IS_BEFORE_BREAK_TIME_START: 'TEST_RU',
    END_WORK: 'TEST_RU',
    'END_ WORK_TIME_IS_REQUIRED': 'TEST_RU',
    END_WORKING_TIME_IS_BEFORE_END_BREAK_TIME: 'TEST_RU',
    TASKS: 'TEST_RU',
    CLICK: 'TEST_RU',
    ENTER_NEW_ENTERY_FOR: 'TEST_RU',
    PLEASE_CHOSE_PROJECT_FIRST: 'TEST_RU',
    THIS_ENTRY_ALREADY_EXISTS: 'TEST_RU',
    CREATED_BY: 'TEST_RU',
    KNOWHOW: 'TEST_RU',
    RECORDS_PER_PAGE: 'TEST_RU',
    IN_TOTAL: 'TEST_RU',
    JOB_TITLE: 'TEST_RU',
    ROLE: 'TEST_RU',
    INDUSTRY: 'TEST_RU',
    ADD_CONTACT: 'TEST_RU',
    PLEASE_CHOOSE_PROJECT_START_DATE: 'TEST_RU',
    SIGN_OUT: 'TEST_RU',
    First_name: 'TEST_RU',
    NO_TASKS_FOUND_FOR_SELECTED_PROJECT: 'TEST_RU',
    NO_PROJECTS_FOUND: 'TEST_RU',
    ROLE_PROJECT_ADMIN: 'TEST_RU',
    ROLE_PROJECT_MEMBER: 'TEST_RU',
    ROLE_PROJECT_BACKOFFICE: 'TEST_RU',
    NORMAL_USER: 'TEST_RU',
    ROLE_OWNER_ADMIN: 'TEST_RU',
    PLEASE_CHOOSE_CUSTOMER: 'TEST_RU',
    CHOOSE_AT_LEAST_ONE_CUSTOMER: 'TEST_RU',
    CUSTOMERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'TEST_RU',
    CHOOSE_AT_LEAST_ONE_USER_ADMINISTRATOR_CANNOT_BE_ARCHIVED: 'TEST_RU',
    USERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'TEST_RU',
    COMPANIES_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'TEST_RU',
    CHOOSE_AT_LEAST_ONE_COMPANY: 'TEST_RU',
    USER_MAIN_MENU_SIDEBAR: 'TEST_RU',
    E_MAIL: 'TEST_RU',
    BACK: 'TEST_RU',
    SAVE_AND_CONTINUE: 'TEST_RU',
    PROJECT_NUMBER: 'TEST_RU',
    PROJECT_OFFER: 'TEST_RU',
    PROJECT_NAME: 'TEST_RU',
    START_DATE: 'TEST_RU',
    END_DATE: 'TEST_RU',
    NO_END: 'TEST_RU',
    WEEK_MODE: 'TEST_RU',
    TIME_PERIOD: 'TEST_RU',
    PLANED_PEOPLE: 'TEST_RU',
    TOTAL_WORKING_DAYS: 'TEST_RU',
    ADD_USERS_TO_THE_TEAM: 'TEST_RU',
    ALLOCATE: 'TEST_RU',
    PLANNED: 'TEST_RU',
    EXPORT: 'TEST_RU',
    KNOWLEDGE_AND_THE_TEAM: 'TEST_RU',
    ALLOCATE_AND_FINISH: 'TEST_RU',
    TOPIC_KNOWHOW: 'TEST_RU',
    BUILD_THE_TEAM: 'TEST_RU',
    DUE_DATA: 'TEST_RU',
    OVERVIEW_APPROVALS: 'TEST_RU',
    EXPORT_AND_VERIFY_HOURS: 'TEST_RU',
    NO_FETCH_DATE: 'TEST_RU',
    ERROR_WHILE_SAVING_DATA: 'TEST_RU',
    SAVED_SUCCESSFULLY: 'TEST_RU',
    CREATE_NEW_PASSWORD: 'TEST_RU',
    PASSWORD: 'TEST_RU',
    CONFIRM_PASSWORD: 'TEST_RU',
    CREATE: 'TEST_RU',
    USER_PASSWORD_HAS_BEEN_SUCCESSFULLY_CREATED: 'TEST_RU',
    SHOW_ACTIVE: 'TEST_RU',
    SHOW_INACTIVE: 'TEST_RU',
    FRONTEND: 'TEST_RU',
    DRAFT_STATUS: 'TEST_RU',
    RUNNING_STATUS: 'TEST_RU',
    PAUSED_STATUS: 'TEST_RU',
    FINISHED_STATUS: 'TEST_RU',
    MY_PROFILE: 'TEST_RU',
    KNOWLEDGE: 'TEST_RU',
    LIGHT: 'TEST_RU',
    DARK: 'TEST_RU',
    SYSTEM: 'TEST_RU',
    NO_ITEMS_FOUND: 'TEST_RU',
    PASSWORD_DOES_NOT_MATCH: 'TEST_RU',
    PASSWORD_VALIDATOR: 'TEST_RU',
    RESET_PASSWORD: 'TEST_RU',
    CUSTOMER_NAME_MUST_BE_UNIQUE: 'TEST_RU',
    CUSTOMER_END_NAME_MUST_BE_UNIQUE: 'TEST_RU',
    MY_TIMESHEET: 'TEST_RU',
    MY_PROJECTS: 'TEST_RU',
    ADMIN_MENU: 'TEST_RU',
    ADD_USER: 'TEST_RU',
    ADD_CUSTOMER: 'TEST_RU',
    ADD_PROJECTS: 'TEST_RU',
    TEMPLATES: 'TEST_RU',
    ABOUT: 'TEST_RU',
    ENGLISH: 'TEST_RU',
    GERMAN: 'TEST_RU',
    SERBIAN: 'TEST_RU',
    USER_ROLE: 'TEST_RU',
    NO_MAIL: 'TEST_RU',
    SPOKEN_LANGUAGES: 'TEST_RU',
    SAVE: 'TEST_RU',
    PROJECT_LIST: 'TEST_RU',
    OVERVIEW_OF_ALL_PROJECTS: 'TEST_RU',
    PROJECT_STATUS_FILTER: 'TEST_RU',
    SEARCH: 'TEST_RU',
    NUMBERS: 'TEST_RU',
    STATE: 'TEST_RU',
    TEAM: 'TEST_RU',
    DAYS_LEFT: 'TEST_RU',
    END_CUSTOMER: 'TEST_RU',
    COMMENT: 'TEST_RU',
    STREET: 'TEST_RU',
    NUMBER: 'TEST_RU',
    ZIP: 'TEST_RU',
    CITY: 'TEST_RU',
    MASS_ACTIVITY: 'TEST_RU',
    USER_DETAILS: 'TEST_RU',
    PROJECT_DETAILS: 'TEST_RU',
    TIME_AND_BUDGET: 'TEST_RU',
    BASIC_DATA: 'TEST_RU',
    SHORT_DESCRIPTION: 'TEST_RU',
    PROJECT_NAME_IS_REQUIRED: 'TEST_RU',
    TOPIC_DESCRIPTION: 'TEST_RU',
    STAFF: 'TEST_RU',
    CAPACITY: 'TEST_RU',
    TIMESHEET: 'TEST_RU',
    ADD_PROJECT: 'TEST_RU',
    ARCHIVE: 'TEST_RU',
    UNARCHIVE: 'TEST_RU',
    CHANGE_PROFILE_IMAGE: 'TEST_RU',
    PHONE_NUMBER: 'TEST_RU',
    USERNAME_IS_REQUIRED: 'TEST_RU',
    USER_ROLE_IS_REQUIRED: 'TEST_RU',
    EMAIL_IS_REQUIRED: 'TEST_RU',
    FIRST_NAME_IS_REQUIRED: 'TEST_RU',
    LAST_NAME_IS_REQUIRED: 'TEST_RU',
    BACKOFFICE_MENU: 'TEST_RU',
    USER_MENU: 'TEST_RU',
    START_PROJECT: 'TEST_RU',
    MEMBERS: 'TEST_RU',
    ARCHIVED_PROJECTS: 'TEST_RU',
    EDIT_PROJECT: 'TEST_RU',
    PROJECT_PERIOD: 'TEST_RU',
    PROJECT_BUDGET: 'TEST_RU',
    USED: 'TEST_RU',
    REMAINING_DAYS: 'TEST_RU',
    COLLAGUES: 'TEST_RU',
    STATUS_BUDGET: 'TEST_RU',
    ACTIONS: 'TEST_RU',
    DAYS_TOTAL: 'TEST_RU',
    EDIT: 'TEST_RU',
    ADD_ADITIONAL_TIME_PERIOD: 'TEST_RU',
    DEFINE_PERIOD: 'TEST_RU',
    KNOW_HOW_DESCRIPTION: 'TEST_RU',
    USERNAME_EXIST: 'TEST_RU',
    CUSTOMER_REQUIRED: 'TEST_RU',
    MANAGER: 'TEST_RU',
    ENTER_COMPANY_ADMIN_FIRST_NAME: 'TEST_RU',
    ENTER_COMPANY_ADMIN_LAST_NAME: 'TEST_RU',
    ENTER_COMPANY_ADMIN_USERNAME: 'TEST_RU',
    COMPANY_ADMIN_FIRST_NAME_IS_REQUIRED: 'TEST_RU',
    COMPANY_ADMIN_LAST_NAME_IS_REQUIRED: 'TEST_RU',
    COMPANY_ADMIN_USERNAME_IS_REQUIRED: 'TEST_RU',
    TEAM_MEMBERS_PLAN: 'TEST_RU',
    PROJECT_START_DATE: 'TEST_RU',
    PROJECT_NUMBER_EXIST: 'TEST_RU',
    PROJECT_INFO_UPDATE_SUCESFULY: 'TEST_RU',
    ASSIGN: 'TEST_RU',
    PROJECT_SAVED_SUCCESSFULLY: 'TEST_RU',
    CAN_BE_USED: 'TEST_RU',
    ONLY_USABLE: 'TEST_RU',
    ACTION: 'TEST_RU',
    FIRST_LAST_NAME_MESSAGE: 'TEST_RU',
    SUCCESSFULLY_DELETED: 'TEST_RU',
    CANCEL: 'TEST_RU',
    DELETE_SUPER_ADMIN: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_USER: 'TEST_RU',
    THE_PASSWORD_MUST_INCLUDE_AT_LEAST_ONE_UPPERCASE_LETTER_ONE_NUMBER_AND_ONE_SPECIAL_CHARACTER: 'TEST_RU',
    MARK_AS_APPROVED: 'TEST_RU',
    MARK_AS_BILLED: 'TEST_RU',
    EXPORT_PDF: 'TEST_RU',
    EXPORT_CSV: 'TEST_RU',
    PRINT_PDF: 'TEST_RU',
    USERNAME_CANNOT_CONTAIN_WHITE_SPACE: 'TEST_RU',
    ARE_YOR_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_BILLED: 'TEST_RU',
    ARE_YOU_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_APPROVED: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_DELETE_TIMESHEET: 'TEST_RU',
    APPROVE_ALL: 'TEST_RU',
    TIMESHEET_ACTION: 'TEST_RU',
    ACTIVE_USER: 'TEST_RU',
    INACTIVE_USER: 'TEST_RU',
    PROJECT_NAME_ALREADY_TAKEN: 'TEST_RU',
    PROJECT_END: 'TEST_RU',
    PROJECT_START: 'TEST_RU',
    CUSTOMER_FIRST_NAME: 'TEST_RU',
    CUSTOMER_END_NAME: 'TEST_RU',
    DELETE: 'TEST_RU',
    CANNOT_DELETE_BILLED_OR_APPROVED_TIMESHEET: 'TEST_RU',
    WE_CANNOT_BILL_FOR_THE_UNAPPROVED_TIMESHEET: 'TEST_RU',
    NO: 'TEST_RU',
    YES: 'TEST_RU',
    LOCKED: 'TEST_RU',
    INFO: 'TEST_RU',
    THIS_VALUE_WILL_BE_USED_FOR_THE_CALCULATION_OF_THE_PROJECT_BUDGET_IN_DAYS: 'TEST_RU',
    WORKING_DAYS_ESTIMATION: 'TEST_RU',
    RESET_TO_CALCULATION: 'TEST_RU',
    CALCULATE_BUDGET: 'TEST_RU',
    ACTIVATE_USER: 'TEST_RU',
    DEACTIVATE_USER: 'TEST_RU',
    DELETE_USER: 'TEST_RU',
    RE_SEND_ACTIVATION_EMAIL: 'TEST_RU',
    USER_DELETED_SUCCESSFULY: 'TEST_RU',
    CHANGE_BUDGET: 'TEST_RU',
    BUDGET_OVERVIEW: 'TEST_RU',
    COLLEAGUES: 'TEST_RU',
    SUM_BUDGET_DISTRIBUTION_OF: 'TEST_RU',
    SUM_OF_ASSIGNED_DAYS_IS_HIGHER_THEN_TOTAL_WORKING_DAYS: 'TEST_RU',
    REVOKE_STATUS: 'TEST_RU',
    REVOKE_STATUS_MODAL: 'TEST_RU',
    ENTRY_IS_REQUIRED: 'TEST_RU',
    ENTRY_ALREADY_EXIST: 'TEST_RU',
    ATTENTION_YOU_ARE_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'TEST_RU',
    TIME: 'TEST_RU',
    ENDTIME: 'TEST_RU',
    STARTTIME: 'TEST_RU',
    HOUR: 'TEST_RU',
    BREAK: 'TEST_RU',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'TEST_RU',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_AND_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'TEST_RU',
    USER_HAS_BEEN_SUCCESSFULLY_UPDATED: 'TEST_RU',
    INVALID_EMAIL_FORMAT: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_TASK: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_KNOWLEDGE: 'TEST_RU',
    ALL_USERS: 'TEST_RU',
    ALL_CUSTOMERS: 'TEST_RU',
    ADD_NEW: 'TEST_RU',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS_EIGHT_HOURS: 'TEST_RU',
    ERROR_UNARCHIVING_KNOWLEDGE: 'TEST_RU',
    ERROR_DELETING_KNOWLEDGE: 'TEST_RU',
    ERROR_UNARCHIVING_TASK: 'TEST_RU',
    ERROR_DELETING_TASK: 'TEST_RU',
    COMPLETED: 'TEST_RU',
    ACTIVE: 'TEST_RU',
    DRAFT_LOWER_CASE: 'TEST_RU',
    YOUR_LAST_LOGIN: 'TEST_RU',
    YOUR_LAST_TIMEBOOKING: 'TEST_RU',
    YOUR_ASSIGNED_PROJECTS: 'TEST_RU',
    MANAGING: 'TEST_RU',
    MEMBER_OF: 'TEST_RU',
    WORKING_HOURS_ESTIMATION: 'TEST_RU',
    USERS_ACTIVE_INACTIVE_SUCCESSFULLY: 'TEST_RU',
    PAUSED_LOWER_CASE: 'TEST_RU',
    ACTIVE_USERS: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_TASK: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_KNOWLEDGE: 'TEST_RU',
    IN_USE: 'TEST_RU',
    NOT_IN_USE: 'TEST_RU',
    AFRICAN: 'TEST_RU',
    ALBANIAN: 'TEST_RU',
    ARABIC: 'TEST_RU',
    BELARUSIAN: 'TEST_RU',
    BOSNIAN: 'TEST_RU',
    BULGARIAN: 'TEST_RU',
    CHINESE: 'TEST_RU',
    CROATIAN: 'TEST_RU',
    CZECH: 'TEST_RU',
    DANISH: 'TEST_RU',
    DUTCH: 'TEST_RU',
    EGYPTIAN: 'TEST_RU',
    ESTONIAN: 'TEST_RU',
    FILIPINO: 'TEST_RU',
    FRENCH: 'TEST_RU',
    GREEK: 'TEST_RU',
    HUNGARIAN: 'TEST_RU',
    ICELANDIC: 'TEST_RU',
    INDIAN: 'TEST_RU',
    IRISH: 'TEST_RU',
    ITALIAN: 'TEST_RU',
    JAPANESE: 'TEST_RU',
    KOREAN: 'TEST_RU',
    KURDISH: 'TEST_RU',
    LATVIAN: 'TEST_RU',
    LITHUANIAN: 'TEST_RU',
    LUXEMBOURGISH: 'TEST_RU',
    MACEDONIAN: 'TEST_RU',
    NORWEGIAN: 'TEST_RU',
    PERSIAN: 'TEST_RU',
    POLISH: 'TEST_RU',
    PORTUGUESE: 'TEST_RU',
    ROMANIAN: 'TEST_RU',
    RUSSIAN: 'TEST_RU',
    SCOTTISH: 'TEST_RU',
    SLOVENIAN: 'TEST_RU',
    SLOVAK: 'TEST_RU',
    SPANISH: 'TEST_RU',
    SWEDISH: 'TEST_RU',
    SYRIAN: 'TEST_RU',
    THAI: 'TEST_RU',
    TURKISH: 'TEST_RU',
    UKRAINIAN: 'TEST_RU',
    VIETNAMESE: 'TEST_RU',
    UPDATE_IMAGE_SIZE_ERROR: 'TEST_RU',
    LOCK_USERS: 'TEST_RU',
    ADD_ENTRIES: 'TEST_RU',
    SAVE_DRAFT: 'TEST_RU',
    LAST_LOGIN: 'TEST_RU',
    MAXIMUM_CHARACTERS_IS_50: 'TEST_RU',
    CREATED_ON: 'TEST_RU',
    BLOCKED: 'TEST_RU',
    COMPANY_DATA: 'TEST_RU',
    FULL_COMPANY_NAME: 'TEST_RU',
    STREET_AND_NUMBER: 'TEST_RU',
    LICENSE_AND_SUBSCRIPTION: 'TEST_RU',
    CHANGE_SUBSCRIPTION: 'TEST_RU',
    WORKING_TIME: 'TEST_RU',
    WORKING_HOURS_DAY: 'TEST_RU',
    SETTINGS: 'TEST_RU',
    VAT_Number: 'TEST_RU',
    ATTENTION: 'TEST_RU',
    BUDGET_IS_CALCULATED_IN_WORKING_DAYS: 'TEST_RU',
    FOR_EXAMPLE: 'TEST_RU',
    WORKING_DAY: 'TEST_RU',
    THIS_CHANGE_HAS_A_DIRECT_EFFECT_ON_THE_DISPLAY_OF_THE_BUDGET_IN_DAYS: 'TEST_RU',
    FORR_ALL_USERS: 'TEST_RU',
    NEXT: 'TEST_RU',
    OVERVIEW_OF_LANGUAGE_SKIL: 'TEST_RU',
    NATIV_KNOWLEDGE: 'TEST_RU',
    NEGOTIABLE_KNOWLEDGE: 'TEST_RU',
    FLUENT_KNOWLEDGE: 'TEST_RU',
    GOOD_KNOWLEDGE: 'TEST_RU',
    BASIC_KNOWLEDGE: 'TEST_RU',
    DELETE_PROFILE_IMAGE: 'TEST_RU',
    SUM: 'TEST_RU',
    VALUE_MUST_BE_BETWEEN_1_AND_12: 'TEST_RU',
    PROJECT_FILTER: 'TEST_RU',
    HERE_YOU_CAN_CHANGE_THE_BASIS_ON_WHICH_THE_PROJECT: 'TEST_RU',
    THE_NEW_VALUE_FOR_A_USER_BUDGET_CANNOT_BE_ADJUSTED_TO_BE_LESS_THAN_THE_BUDGET_ALREADY_USED: 'TEST_RU',
    MINIMUM_CHARACTERS_IS_50: 'TEST_RU',
    HOURS: 'TEST_RU',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS: 'TEST_RU',
    COMPANY_UPDATED_SUCCESSFULLY: 'TEST_RU',
    UPDATE_ENTRY_INFORMATION_FOR: 'TEST_RU',
    CUSTOMER_UPDATED_SUCCESSFULLY: 'TEST_RU',
    ADMIN: 'TEST_RU',
    BACKOFFICE: 'TEST_RU',
    AGRICULTURAL_ECONOMICS: 'TEST_RU',
    SERVICES: 'TEST_RU',
    INSURANCE: 'TEST_RU',
    CONSTRUCTION_INDUSTRIES: 'TEST_RU',
    REAL_ESTATE: 'TEST_RU',
    METAL_AND_ELECTRICAL_INDUSTRY: 'TEST_RU',
    IT_SERVICES: 'TEST_RU',
    SPORTS_AND_FITNESS: 'TEST_RU',
    ADVERTISING_AND_MARKETING: 'TEST_RU',
    CRAFT: 'TEST_RU',
    TRANSPORTATION_AND_LOGISTICS: 'TEST_RU',
    AUTOMOTIVE_INDUSTRY: 'TEST_RU',
    CHEMISTRY: 'TEST_RU',
    PUBLIC_ADMINISTRATION: 'TEST_RU',
    RETAIL_AND_TRADE: 'TEST_RU',
    MEDIA: 'TEST_RU',
    HEALTHCARE: 'TEST_RU',
    FOOD_INDUSTRY: 'TEST_RU',
    PHARMACEUTICALS: 'TEST_RU',
    BANKING_AND_FINANCIAL_SERVICES: 'TEST_RU',
    ENERGY_AND_ENVIRONMENT: 'TEST_RU',
    NON_PROFIT_ORGANIZATIONS: 'TEST_RU',
    TELECOMMUNICATIONS: 'TEST_RU',
    TEXTILES_AND_APPAREL_INDUSTRY: 'TEST_RU',
    OTHER: 'TEST_RU',
    E_COMMERCE: 'TEST_RU',
    TOURISM_AND_GASTRONOMY: 'TEST_RU',
    PLANT_CONSTRUCTION_AND_MACHINERY_MANUFACTURING: 'TEST_RU',
    AVIATION_AND_ASTRONAUTIC: 'TEST_RU',
    DAYS_BOOKED: 'TEST_RU',
    ADD_NEW_CUSTOMER: 'TEST_RU',
    SYSTEM_ERROR: 'TEST_RU',
    PAGE_NOT_FOUND: 'TEST_RU',
    RETURN_TO: 'TEST_RU',
    HOME: 'TEST_RU',
    LOADING_PROJECTS: 'TEST_RU',
    FILE: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_CUSTOMER: 'TEST_RU',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_CUSTOMER: 'TEST_RU',
    SHOW_ARCHIVED: 'TEST_RU',
    ASSIGNED_PROJECTS: 'TEST_RU',
    ALL_ENTRIES: 'TEST_RU',
    STANDARD_LANGUAGE: 'TEST_RU',
    DEFAULT_SETTINGS: 'TEST_RU',
    COMPANY_REGISTRATION_CONFIRMATION: 'TEST_RU',
    DASHBOARD_PROJECTS: 'TEST_RU',
    DASHBOARD_PROJECT: 'TEST_RU',
    DASHBOARD_CUSTOMERS: 'TEST_RU',
    DASHBOARD_CUSTOMER: 'TEST_RU',
    DASHBOARD_USER: 'TEST_RU',
    DASHBOARD_USERS: 'TEST_RU',
    ACTIVE_PROJECTS: 'TEST_RU',
    INACTIVE_PROJECTS: 'TEST_RU',
    RUNNING_AND_PAUSED_STATUS: 'TEST_RU',
    DRAFT_AND_FINISHED_STATUS: 'TEST_RU',
    ALL_EXISTING_PROJECTS: 'TEST_RU',
    EMAIL_TAKEN: 'TEST_RU',
    COMPANY_NAME_TAKEN: 'TEST_RU',
    PASSWORD_COMPLEXITY_ERROR_TEXT: 'TEST_RU',
    APPROVE_ALL_TIMESHEET: 'TEST_RU',
    EXPORT_ALL_TIMESHEETS_EXCEL: 'TEST_RU',
    EXPORT_ALL_TIMESHEETS_CSV: 'TEST_RU',
    EXPORT_ALL_TIMESHEETS_PDF: 'TEST_RU',
    PENDING: 'TEST_RU',
    BILLED: 'TEST_RU',
    ASK_FOR_APPROAVAL_TEXT: 'TEST_RU',
    TEAM_MEMBERS: 'TEST_RU',
    ARCHIVED_CUSTOMERS: 'TEST_RU',
    ACTIVE_CUSTOMERS: 'TEST_RU',
    CREATE_YOUR_COMPANY_ACCOUNT: 'TEST_RU',
    QUICK_START_GUIDE: 'TEST_RU',
    DATA_EXPLORER: 'TEST_RU',
    REPORTS: 'TEST_RU',
    BACK_TO_PROJECTS: 'TEST_RU',
    USED_BUDGET: 'TEST_RU',
    BOOK: 'TEST_RU',
    BACK_TO_MY_PROJECTS: 'TEST_RU',
    USER_EMAIL_VALIDATION: 'TEST_RU',
    PLEASE_FILL_ALL: 'TEST_RU',
    TERMS_AND_PRIVACY_POLICY: 'TEST_RU',
    QUICK_START_TITLE: 'TEST_RU',
  },
}
