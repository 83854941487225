import { Component, OnInit } from '@angular/core'
import { ApprovalListResponse } from 'app/pages/core/model/TimeSheetApproval.model'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { SharedService } from 'app/pages/projects/shared.service'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-project-members-cards',
  templateUrl: './project-members-cards.component.html',
  styleUrls: ['./project-members-cards.component.scss'],
})
export class ProjectMembersCardsComponent implements OnInit {
  project: Project
  membersLength: boolean = false
  approvalList: BehaviorSubject<ApprovalListResponse[]> = new BehaviorSubject([] as ApprovalListResponse[])
  constructor(private sharedService: SharedService, private projectService: ProjectService) {}

  ngOnInit(): void {
    this.project = this.sharedService.getProject()
    this.membersLength = true
    this.fetchApprovals()
  }

  refreshApprovals(event: any) {
    this.fetchApprovals()
  }

  fetchApprovals() {
    this.projectService.getApprovalSummaryByMonth(this.project.id!).subscribe((results: any) => {
      results.response.content && this.approvalList.next(results.response.content)
    })
  }
}
