import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { ActivatedRoute, Router } from '@angular/router'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { environment } from 'environments/environment'
import { UserService } from 'app/pages/core/services/user.service'
import { AuthService } from 'app/modules/auth'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'
import { StatusPipe } from './status.pipe'
import { TranslateService } from '@ngx-translate/core'
import { User } from 'app/pages/core/model/flexc-user.model'
import { BehaviorSubject } from 'rxjs'
import { SharedService } from '../shared.service'

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit {
  @Input() statuses$: BehaviorSubject<any[]>

  searchTerm: any
  singleUser: boolean

  selectedStatus: any
  tableData: any[] = []
  projects: Project[]
  selectedFilter: any
  showUsersProjects: boolean = false
  userData: User
  userId: string

  allProjectStatues = [
    {
      value: 'a4956773-4af7-4c04-9587-b200dc93187b',
      label: 'DRAFT',
    },
    {
      value: 'd46058cd-4f73-4dab-b051-af0c97910da8',
      label: 'RUNNING',
    },
    {
      value: '0ad5387e-9f03-4ff7-af9d-504d4d899754',
      label: 'PAUSED',
    },
    {
      value: '18e397fe-6cfd-4b1c-ae10-fa9927745b7a',
      label: 'FINISHED',
    },
  ]
  projectStatuses: any[] = []

  paginationObject: any = {
    pageSize: 5,
    currentPage: 0,
    totalElements: 0,
  }

  sortObject: any = {
    column: 'project',
    order: 'ascend',
  }

  tableHeadings = {
    image: '',
    project: useStringAsTranslationObject('PROJECT'),
    customer: useStringAsTranslationObject('CUSTOMER'),
    state: useStringAsTranslationObject('STATE'),
    team: useStringAsTranslationObject('TEAM'),
    status: useStringAsTranslationObject('STATUS'),
  }

  sortableColumns = {
    project: 'title',
    customer: 'title',
    team: 'length',
  }

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private router: Router,
    public authService: AuthService,
    public querySharedService: QuerySharedService,
    private route: ActivatedRoute,
    private status: StatusPipe,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.projectStatuses = this.translateStatusLabels()
    this.translate.onLangChange.subscribe(res => {
      this.projectStatuses = this.translateStatusLabels()
    })

    this.statuses$.subscribe(res => {
      this.paginationObject.currentPage = 0
      this.searchTerm = ''
      this.allProjectStatues = res
      this.selectedStatus = { value: res.map(s => s.value).join(',') }
      this.route.params.subscribe(params => {
        if (params['id']) {
          this.userService.getUserByID(params['id']).subscribe((res: ApiResponse<User>) => {
            this.userId = params['id']
            this.userData = res.response
            this.userData.imageUrl = this.userData.imageUrl
              ? `${environment.imgUrl}${this.userData.imageUrl}`
              : 'assets/media/logos/ICON_USER.svg'
            this.showUsersProjects = true
            this.fetchRouteParams()
            this.fetchProjects()
          })
        } else {
          this.route.data.subscribe(props => {
            this.singleUser = props.currentUser
            this.sharedService.isFromMyProjects.next(this.singleUser)
            this.fetchRouteParams()
            this.fetchProjects()
          })
        }
      })
    })
  }

  translateStatusLabels() {
    return this.allProjectStatues.map(projectStatus => {
      return {
        ...projectStatus,
        label: this.translate.instant(this.allProjectStatues.find(aps => aps.value === projectStatus.value)?.label!),
      }
    })
  }

  fetchRouteParams() {
    if (this.route.snapshot.queryParamMap.get('pageSize')) {
      this.paginationObject.pageSize = +this.route.snapshot.queryParamMap.get('pageSize')!
    }
    if (this.route.snapshot.queryParamMap.get('currentPage')) {
      this.paginationObject.currentPage = +this.route.snapshot.queryParamMap.get('currentPage')!
    }
    if (this.route.snapshot.queryParamMap.get('column')) {
      this.sortObject.column = this.route.snapshot.queryParamMap.get('column')
    }
    if (this.route.snapshot.queryParamMap.get('order')) {
      this.sortObject.order = this.route.snapshot.queryParamMap.get('order')
    }
    if (this.route.snapshot.queryParamMap.get('filter')) {
      this.selectedStatus = this.allProjectStatues.find(
        project_status => project_status.value === this.route.snapshot.queryParamMap.get('filter')
      )
    }
    if (this.route.snapshot.queryParamMap.get('searchInput')) {
      this.searchTerm = this.route.snapshot.queryParamMap.get('searchInput')!
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchTerm && !changes.searchTerm.firstChange) {
      this.paginationObject.currentPage = 0
      this.searchTerm = changes.searchTerm.currentValue
      this.fetchProjects()
    }
  }

  mapPagination(event: any) {
    this.paginationObject = event
    this.fetchProjects()
  }

  mapSorting(event: any) {
    this.sortObject = event
    this.fetchProjects()
  }

  fetchProjectDataJustForOneUser() {
    this.userService.getLoggedInUser().subscribe(res => {
      if (!res?.id) {
        return
      }
      const projectObservable =
        res.userRole.name === 'ROLE_PROJECT_MEMBER'
          ? this.projectService.getManagerProject(res.id)
          : this.projectService.getUsersProject(
              res.id,
              this.paginationObject.currentPage,
              this.paginationObject.pageSize,
              this.mapSortBy(this.sortObject.column),
              this.mapSortDirection(this.sortObject.order),
              this.searchTerm,
              this.selectedStatus?.value
            )

      projectObservable.subscribe(this.handleProjectsResponse)
    })
  }

  fetchProjectDataForSpecificUser() {
    const projectObservable = this.projectService.getUsersProject(
      this.userId,
      this.paginationObject.currentPage,
      this.paginationObject.pageSize,
      this.mapSortBy(this.sortObject.column),
      this.mapSortDirection(this.sortObject.order),
      this.searchTerm,
      this.selectedStatus?.value
    )

    projectObservable.subscribe(this.handleProjectsResponse)
  }

  fetchAllProjects() {
    this.searchTerm = this.searchTerm ? this.searchTerm : ''
    this.projectService
      .getAllProjects(
        this.paginationObject.currentPage,
        this.paginationObject.pageSize,
        this.mapSortBy(this.sortObject.column),
        this.mapSortDirection(this.sortObject.order),
        this.selectedStatus?.value,
        this.searchTerm
      )
      .subscribe(this.handleProjectsResponse)
  }

  handleProjectsResponse = (res: ApiResponse<Project[]>) =>
    res && (this.tableData = this.mapProjectsForTable(res.content || res.response, res.totalElements))

  mapProjectsForTable(projects: Project[], totalProject?: number) {
    this.projects = projects
    this.paginationObject.totalElements = totalProject ? totalProject : this.paginationObject.totalElements
    this.filterStatuses()
    return projects.map(project => ({
      id: project.id,
      image: {
        type: project.imageUrl ? TABLE_TYPE.IMAGE : TABLE_TYPE.SVG,
        url: project.imageUrl ? `${environment.imgUrl}${project.imageUrl}` : './assets/media/logos/ICON_PROJECT.svg',
      },
      project: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: project.name,
        description: project.description,
        limit: 60,
      },
      customer: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: project.customer?.name,
      },
      state: {
        type: TABLE_TYPE.BAR_GRAPH,
        percentage:
          project.usedBudgetInPercentage !== undefined && project.usedBudgetInPercentage !== null
            ? project.usedBudgetInPercentage
            : 0,
        daysLeft:
          project.daysLeft !== undefined && project.daysLeft !== null
            ? project.daysLeft
            : project.budgetEstimation || 0,
        total: project.budgetEstimation || 0,
        booked: project.usedBudget || 0,
      },
      team: {
        type: TABLE_TYPE.USERS,
        list: project.projectMembers?.map(member => ({
          image: member.user.imageUrl
            ? `${environment.imgUrl}${member.user.imageUrl}`
            : 'assets/media/logos/ICON_USER.svg',
        })),
      },
      status: {
        type: TABLE_TYPE.STATUS,
        value: project.projectStatus?.id,
        projectId: project.id,
        statusChanges: this.changeProjectStatus,
      },
    }))
  }

  changeProjectStatus = (projectId: string, status: any) => {
    const projectToUpdate = this.projects.find(project => project.id === projectId)
    this.projectService
      .updateProjectStatus(projectId, { id: status.value, name: status.label })
      .subscribe(({ response }: ApiResponse<Project>) => {
        response!.projectMembers = projectToUpdate?.projectMembers!
        this.tableData = this.mapProjectsForTable(
          this.projects.map(project => (project.id === projectId ? response : project))
        )
        this.status.transform(this.tableData, this.selectedStatus)
        this.fetchAllProjects()
      })
  }

  calculatePercentage(project: Project) {
    const workDays = this.getBudget(project.start, project.end)
    const daysLeft = this.getBudget(new Date(), project.end)
    const budget = 100 - (daysLeft! / workDays!) * 100
    return isNaN(budget) || budget > 100 ? 0 : budget
  }

  getBudget(start: any, end: any) {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const numOfDates = this.getBusinessDatesCount(startDate, endDate)
    return numOfDates
  }

  getBusinessDatesCount(startDate: any, endDate: any) {
    let count = 0
    const curDate = new Date(startDate.getTime())
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++
      curDate.setDate(curDate.getDate() + 1)
    }
    return count
  }

  redirectToSingleProjectPage(project: any) {
    this.querySharedService.addQueryForRoute('projects/projects-list', {
      ...this.paginationObject,
      ...this.sortObject,
      searchInput: this.searchTerm,
    })
    if (this.selectedStatus) {
      this.querySharedService.addFilterForRoute('projects/projects-list', this.selectedStatus.value)
    }
    void this.router.navigate([`/projects/project-overview/${project.id}/team-members`], {
      queryParams: { myProjects: !!this.singleUser },
    })
  }

  filterStatuses() {
    this.projectStatuses = this.translateStatusLabels()
  }

  private mapSortBy(sortBy: string) {
    switch (sortBy) {
      case 'project':
        return 'name'
      case 'customer':
        return 'customer.name'
      case 'state':
        return 'usedBudget'
      case 'team':
        return 'currentTeamSize'
      case 'status':
        return 'projectStatus.name'
      default:
        return 'name'
    }
  }

  private mapSortDirection(order: string) {
    return order === 'descend' ? 'DESC' : order === 'ascend' ? 'ASC' : undefined
  }

  searchParam(event: any) {
    this.searchTerm = event
    if (this.searchTerm.charAt(this.searchTerm.length - 1) === ' ') {
      this.searchTerm = this.searchTerm.trim()
    }
    if (this.searchTerm.charAt(0) === ' ') {
      this.searchTerm = this.searchTerm.trimStart()
    }
    this.paginationObject.currentPage = 0
    this.fetchProjects()
  }

  filterList(event: any) {
    if (!event) {
      event = { value: this.allProjectStatues.map(s => s.value).join(',') }
    }
    this.selectedStatus = event
    this.paginationObject.currentPage = 0
    this.fetchProjects()
  }

  fetchProjects() {
    if (!this.showUsersProjects) {
      this.singleUser ? this.fetchProjectDataJustForOneUser() : this.fetchAllProjects()
    } else {
      this.fetchProjectDataForSpecificUser()
    }
  }

  createNewProjectTemplate() {
    this.projectService.createEmptyProject().subscribe((res: ApiResponse<string>) => {
      this.router.navigate(['projects/create-new-project/step-1', res.response])
    })
  }
}
