import { HttpParams } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { TimeSheetService } from 'app/pages/core/services/timesheet.service'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import * as dayjs from 'dayjs'
import { HoursConverterService } from 'app/pages/core/services/hours-converter.service'
import { BehaviorSubject } from 'rxjs'
import { SharedService } from '../../shared.service'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Column } from 'exceljs'

@Component({
  selector: 'app-project-approvals-card',
  templateUrl: './project-approvals-card.component.html',
  styleUrls: ['./project-approvals-card.component.scss'],
})
export class ProjectApprovalsCardComponent implements OnInit {
  @Input() project: Project
  @Output() refreshApprovals: EventEmitter<any> = new EventEmitter()

  openModalApproveTimesheetsSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  selectedApproval: any
  isFromMyProjects: boolean = false

  public approvalsWithActions: any[]
  public tableHeadings = {
    date: useStringAsTranslationObject('DATE'),
    days: useStringAsTranslationObject('DAYS'),
    hours: useStringAsTranslationObject('HOURS'),
    status: useStringAsTranslationObject('STATUS'),
    actions: useStringAsTranslationObject('ACTIONS'),
  }

  exportHeadings = {
    lastName: 'LAST_NAME',
    firstName: 'FIRST_NAME',
    date: 'DATE',
    description: 'DESCRIPTION',
    startWork: 'START_TIME',
    startPause: 'START_BREAK',
    endPause: 'END_BREAK',
    endWork: 'END_TIME',
    hourSummary: 'SUM_HOURS',
    projectName: 'PROJECT',
    companyName: 'COMPANY_NAME',
  }

  exportColumnOrder = [
    'date',
    'projectName',
    'companyName',
    'firstName',
    'lastName',
    'description',
    'startWork',
    'startPause',
    'endPause',
    'endWork',
    'hourSummary',
  ]

  tableData: any[] = []
  constructor(
    private timesheetService: TimeSheetService,
    private translate: TranslateService,
    private projectService: ProjectService,
    private router: Router,
    private hoursConverter: HoursConverterService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.sharedService.isFromMyProjects.subscribe((val: any) => (this.isFromMyProjects = val ?? false))
    this.project = this.sharedService.getProject()
    this.fetchApprovals()
  }

  mapStatuses(_approvals: any[]) {
    return _approvals.map(approval => ({
      id: approval.id,
      date: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: approval.formattedDate,
      },
      days: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: this.hoursConverter.convertDaysAndHours(approval.days),
      },
      hours: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: this.hoursConverter.convertDaysToHoursAndMinutes(approval.hours),
      },
      statuses: {
        type: TABLE_TYPE.BUDGET_OVERVIEW_STATUSES,
        data: [
          {
            string: this.hoursConverter.convertDaysToHoursAndMinutes(approval.pending),
            icon: 'assets/media/icons/duotune/general/DefaultTimesheet.svg',
          },
          {
            string: this.hoursConverter.convertDaysToHoursAndMinutes(approval.approved),
            icon: 'assets/media/icons/duotune/general/ApprovedTimesheet.svg',
          },
          {
            string: this.hoursConverter.convertDaysToHoursAndMinutes(approval.billed),
            icon: 'assets/media/icons/duotune/general/BilledTimesheet.svg',
          },
        ],
      },
      actions: {
        type: TABLE_TYPE.STATUS_TIMESHEET,
        value: 2,
        projectId: 2,
        statusChanges: this.getApprovalsWithActions(approval),
      },
    }))
  }

  getApprovalsWithActions(_approval: any) {
    let actions = [
      {
        title: 'APPROVE_ALL_TIMESHEET',
        onClick: () => {
          this.askForTimesheetApproval(_approval, true)
        },
        icon: 'assets/media/icons/duotune/general/file.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_PDF',
        onClick: () => {
          this.projectService
            .getTimeSheetForPDF(
              this.project.id,
              dayjs(`01 ${_approval.formattedDate}`, 'DD MMMM YYYY').format('YYYY-MM-DD')
            )
            .subscribe(({ response }: any) => {
              const separatedByUsers = this.timesheetService.separateByUser(
                this.timesheetService.mapResponseForExport(response)?.timeSheets || []
              )

              separatedByUsers.map((user: any) =>
                this.timesheetService.convertTimesheetToPDF({ timeSheets: user.data })
              )
            })
        },
        icon: 'assets/media/icons/duotune/general/export.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_CSV',
        onClick: () => {
          this.projectService
            .getTimeSheetForPDF(
              this.project.id,
              dayjs(`01 ${_approval.formattedDate}`, 'DD MMMM YYYY').format('YYYY-MM-DD')
            )
            .subscribe(({ response }: any) => {
              const separatedByUsers = this.timesheetService.separateByUser(
                this.timesheetService.mapResponseForExport(response)?.timeSheets || []
              )
              separatedByUsers.map((user: any) =>
                this.timesheetService.convertTimesheetToCSV({ timeSheets: user.data })
              )
            })
        },
        icon: 'assets/media/icons/duotune/general/export.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_EXCEL',
        onClick: () => {
          this.projectService
            .getTimeSheetForPDF(
              this.project.id,
              dayjs(`01 ${_approval.formattedDate}`, 'DD MMMM YYYY').format('YYYY-MM-DD')
            )
            .subscribe(({ response }: any) => {
              const separatedByUsers = this.timesheetService.separateByUser(
                this.timesheetService.mapResponseForExport(response)?.timeSheets || []
              )

              this.exportExcel(separatedByUsers)
            })
        },
        icon: 'assets/media/icons/duotune/general/printer.svg',
      },
    ]
    return actions
  }

  exportExcel(data: any) {
    const fileName = 'my-data'
    const workbook = new ExcelJS.Workbook()
    data.forEach((userData: any) => {
      const worksheet = workbook.addWorksheet(userData.name)

      const columns = Object.keys(userData.data[0])
        .filter(key => Object.keys(this.exportHeadings).includes(key))
        .sort((a: string, b: string) => this.exportColumnOrder.indexOf(a) - this.exportColumnOrder.indexOf(b))
        .map((key: string) => ({
          key,
          header: this.translate.instant(this.exportHeadings[key as keyof typeof this.exportHeadings]),
        }))

      worksheet.columns = columns

      // Add data
      userData.data.forEach((item: any) => {
        worksheet.addRow(item)
      })
    })

    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, `${fileName}.xlsx`)
    })
  }

  askForTimesheetApproval(approval: any, open: boolean) {
    this.selectedApproval = approval
    this.openModalApproveTimesheetsSubject$.next(open)
  }

  approveTimesheets(event: any) {
    const paramMap: HttpParams = new HttpParams()
      .append('monthStart', dayjs(`01 ${this.selectedApproval.formattedDate}`, 'DD MMMM YYYY').format('YYYY-MM-DD'))
      .append('projectId', this.project.id!)
    this.timesheetService.approveTimesheets(paramMap).subscribe(() => {
      this.refreshApprovals.emit()
      this.openModalApproveTimesheetsSubject$.next(false)
    })
  }

  determineIconByApprovalStatus(status: string) {
    switch (status) {
      case 'BILLED':
        return 'assets/media/icons/duotune/general/BilledTimesheet.svg'
      case 'APPROVED':
        return 'assets/media/icons/duotune/general/ApprovedTimesheet.svg'
      default:
        return 'assets/media/icons/duotune/general/DefaultTimesheet.svg'
    }
  }

  determineTextByApprovalStatus(status: string) {
    switch (status) {
      case 'BILLED':
        return this.translate.instant('BILLED')
      case 'APPROVED':
        return this.translate.instant('APPROVED')
      case 'PENDING':
        return this.translate.instant('PENDING')
      default:
        return this.translate.instant('DECLINED')
    }
  }

  redirectToAnotherPage(event: any) {
    void this.router.navigate([`/projects/project-overview/${this.project.id}/used-budget/overview-month-booked`], {
      queryParams: { projectId: this.project.id, date: event.date.string, myProjects: !!this.isFromMyProjects },
    })
  }

  fetchApprovals() {
    this.projectService.getApprovalSummaryByMonth(this.project.id!).subscribe((results: any) => {
      results.response.content && (this.tableData = this.mapStatuses(results.response.content))
    })
  }
}
