export enum EXPORT_TIMESHEET_HEADERS_CSV {
  lastName = 'LAST_NAME',
  firstName = 'FIRST_NAME',
  date = 'DATE',
  description = 'DESCRIPTION',
  startWork = 'START_TIME',
  startPause = 'START_BREAK',
  endPause = 'END_BREAK',
  endWork = 'END_TIME',
  hourSummary = 'SUM_HOURS',
  customerFullName = 'CUSTOMER',
  projectName = 'PROJECT',
  companyName = 'COMPANY',
}
