import { KnowladgeService } from '../../../core/services/knowledge.service'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { KnowledgeDto } from 'app/pages/core/model/knowledge.model'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { SharedService } from '../../shared.service'
import { UserService } from 'app/pages/core/services/user.service'
import { User } from 'app/pages/core/model/flexc-user.model'
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs'
import { ProjectService } from 'app/pages/core/services/project.service'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { environment } from 'environments/environment'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-project-team',
  templateUrl: './project-team.component.html',
  styleUrls: ['./project-team.component.scss'],
})
export class ProjectTeamComponent implements OnInit {
  @Input() submitted: boolean
  @Input() projectOverview: boolean

  @Output() selectedUser = new EventEmitter<User>()

  setProjectMembers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  usersChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  searchValue$ = new BehaviorSubject<string>('')
  itemsListdb: KnowledgeDto[] = []
  project: Project
  selectedUsers: User[] = []
  tableData: any[] = []
  selectedItems: any[] = []
  listOfKnowledge: KnowledgeDto[] = []
  searchTerm: string = ''
  selectedKnowledges: KnowledgeDto[]
  paginationObject: any = {
    pageSize: 5,
    currentPage: 0,
    totalElements: 0,
  }

  sortObject: any = {
    column: '',
    order: null,
  }

  tableHeadings = {
    image: '',
    firstName: useStringAsTranslationObject('FIRST_NAME'),
    lastName: useStringAsTranslationObject('LAST_NAME'),
    jobTitle: useStringAsTranslationObject('JOB_TITLE'),
    planned: useStringAsTranslationObject('KNOWLEDGE_OVERVIEW'),
  }

  constructor(
    public knowladgeService: KnowladgeService,
    public projectService: ProjectService,
    public sharedService: SharedService,
    public userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.searchUserCleanup()
    this.getKnowledgeData()
    this.fetchUserData()
  }

  fetchUserData() {
    this.project = this.sharedService.getProject()
    if (this.project.knowledges) {
      this.selectedKnowledges = this.project.knowledges
    }
    this.searchUsersHttp(this.searchTerm, this.selectedKnowledges)
  }

  mapPagination(event: any) {
    this.paginationObject = event
    this.fetchUserData()
  }

  mapSorting(event: any) {
    this.sortObject = event
    this.fetchUserData()
  }

  searchUsers(event: any) {
    if (event) {
      this.searchValue$.next(event)
    } else {
      setTimeout(() => {
        this.searchTerm = ''
        this.searchUsersHttp(this.searchTerm, this.selectedKnowledges)
      }, 550)
    }
  }

  searchUserCleanup() {
    this.searchValue$
      .pipe(
        filter(val => val !== ''),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((res: any) => {
        this.searchTerm = res
        this.paginationObject.currentPage = 0
        this.searchUsersHttp(this.searchTerm, this.selectedKnowledges)
      })
  }

  searchUsersHttp(searchTerm?: string, knowledges?: KnowledgeDto[]) {
    let body = {
      searchTerm: searchTerm,
      knowledgeIds: knowledges?.map((val: KnowledgeDto) => val.id),
    }
    this.knowladgeService
      .filterUsersByKnowledgeOrLanguageOrSearch(
        body,
        this.paginationObject.currentPage,
        this.paginationObject.pageSize,
        this.mapSortBy(this.sortObject.column),
        this.mapSortDirection(this.sortObject.order)
      )
      .subscribe((res: any) => {
        res.content.forEach((element: any) => {
          element.isChecked = this.project?.projectMembers.some(member => element.id === member.user.id)
        })
        this.mapData(res.content, res.totalElements)
      })
  }

  mapData(userList: any[], totalElements?: number) {
    this.paginationObject.totalElements = totalElements!
    if (userList) {
      this.tableData = userList.map(element => ({
        image: {
          type: element.imageUrl ? TABLE_TYPE.IMAGE : TABLE_TYPE.SVG,
          url: element.imageUrl ? `${environment.imgUrl}${element.imageUrl}` : './assets/media/logos/ICON_USER.svg',
        },
        firstName: {
          type: TABLE_TYPE.TRANSLATED_STRING,
          string: element.firstName,
        },
        lastName: {
          type: TABLE_TYPE.TRANSLATED_STRING,
          string: element.lastName,
        },
        jobTitle: {
          type: TABLE_TYPE.TRANSLATED_STRING,
          string: element.portfolio?.jobTitle,
        },
        planned: {
          type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
          string: `${!element.active ? `(${this.translate.instant('INACTIVE')}) ` : ''}${
            element.portfolio?.knowledges?.map((knowledge: { name: any }) => knowledge.name).join(', ') || ''
          }`,
        },
        id: element.id,
        knowledgeFlag: element.knowledgeFlag,
        isChecked: element.isChecked,
        active: element.active,
      }))
    } else {
      this.tableData = []
    }
  }

  onStatusSelectChange(selectedValues: KnowledgeDto[]) {
    this.selectedKnowledges = selectedValues
    this.paginationObject.currentPage = 0
    this.searchUsersHttp(this.searchTerm, this.selectedKnowledges)
    this.updateProjectKnowledges()
  }

  private updateProjectKnowledges() {
    this.project.knowledges = this.selectedKnowledges
    this.sharedService.setProject(this.project)
  }

  saveFinished(event: any) {
    if (event) {
      this.doUpdate()
    }
  }

  doUpdate() {
    this.usersChange.next(true)
    this.project = this.sharedService.getProject()
    this.sharedService.setProject(this.project)
    if (this.project.knowledges) {
      this.projectService.updateProject(this.project).subscribe((response: ApiResponse<Project>) => {})
    }
  }

  changeUserSelect(event: any) {
    this.project = this.sharedService.getProject()
    if (this.project.projectMembers.find((val: ProjectMember) => val.user.id === event.id) === undefined) {
      this.userService.getUserByID(event.id).subscribe(({ response }: ApiResponse<User>) => {
        response.knowledgeFlag = true
        response.isChecked = true
        this.project.projectMembers.push({ user: response } as ProjectMember)
        this.sharedService.setProject(this.project)
      })
    } else {
      this.project.projectMembers.splice(
        this.project.projectMembers.indexOf(
          this.project.projectMembers.find((val: ProjectMember) => val.user.id === event.id)!
        ),
        1
      )
    }
  }

  private mapSortBy(sortBy: string) {
    switch (sortBy) {
      case 'firstName':
        return 'firstName'
      case 'lastName':
        return 'lastName'
      case 'username':
        return 'username'
      case 'jobTitle':
        return 'portfolio.jobTitle'
      default:
        return 'firstName'
    }
  }

  private mapSortDirection(order: string) {
    return order === 'descend' ? 'DESC' : order === 'ascend' ? 'ASC' : undefined
  }

  getKnowledgeData() {
    this.knowladgeService.getAllKnowledgesNoPagination().subscribe((results: ApiResponse<KnowledgeDto[]>) => {
      if (results.response) {
        this.itemsListdb = results.response
      }
    })
  }
}
