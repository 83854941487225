<form [formGroup]="customerForm" class="customer-card">
  <app-modal #modal [modalConfig]="modalConfig"></app-modal>
  <!-- type="back" -->
  <app-button
    *ngIf="!isModal"
    [title]="'BACK' | translate"
    [buttonType]="'link'"
    size="large"
    prefixIcon="assets/media/icons/duotune/arrows/arr074.svg"
    (handleClick)="back($event)"
  ></app-button>
  <div class="row imageRow">
    <div class="col-md-3 mt-6 mb-8">
      <div class="image-input image-input-circle" data-kt-image-input="true">
        <img
          id="profile-image"
          class="border border-gray-500 border-active active"
          alt="company_logo"
          [src]="imageSrc || 'assets/media/logos/ICON_USER.svg'"
        />
        <label
          class="btn btn-icon btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow img"
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip"
          data-bs-dismiss="click"
          title="{{ 'CHANGE_PROFILE_IMAGE' | translate }}"
        >
          <i class="bi bi-pencil-fill fs-7"></i>
          <input type="file" accept="image/*" (change)="readURL($event)" />
        </label>
        <label
          class="btn btn-icon btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow delete_icon"
          data-kt-image-input-action="remove"
          data-bs-toggle="tooltip"
          data-bs-dismiss="click"
          (click)="setImageToDefault()"
          title="{{ 'DELETE_PROFILE_IMAGE' | translate }}"
        >
          <i class="bi bi-trash fs-7"></i>
        </label>
        <span
          class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow"
          data-kt-image-input-action="cancel"
          data-bs-toggle="tooltip"
          data-bs-dismiss="click"
          title="Cancel avatar"
        >
          <i class="bi bi-x fs-2"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="row formRow">
    <div class="row mt-2">
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder required marginTop"> {{ 'CUSTOMER' | translate }}</label>
        <app-input
          class="mb-4"
          formControlName="name"
          (input)="checkCustomerName($event)"
          [ngClass]="{ 'is-invalid': validateForm && formControls['name'].errors }"
        ></app-input>
        <div *ngIf="validateForm && formControls.name.errors?.required" class="invalid-feedback">
          {{ 'ENTRY_IS_REQUIRED' | translate }}
        </div>
        <div *ngIf="customerNameError" class="invalid-feedback d-block">
          {{ 'ENTRY_ALREADY_EXIST' | translate }}
        </div>
      </div>
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder margin marginTop"> {{ 'END_CUSTOMER' | translate }}</label>
        <app-input class="margin" formControlName="endName"></app-input>
        <div *ngIf="customerEndNameError" class="invalid-feedback d-block">
          {{ 'CUSTOMER_END_NAME_MUST_BE_UNIQUE' | translate }}
        </div>
      </div>
      <div class="col-md-12 mt-5">
        <label class="form-label fs-6 fw-bolder marginTop"> {{ 'COMMENT' | translate }}</label>
        <app-input formControlName="comment"></app-input>
      </div>
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder marginTop"> {{ 'STREET' | translate }}</label>
        <app-input formControlName="streetName"></app-input>
      </div>
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder marginTop">{{ 'NUMBER' | translate }}</label>
        <app-input formControlName="streetNumber"></app-input>
      </div>
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder marginTop">{{ 'ZIP' | translate }}</label>
        <app-input formControlName="postcode"></app-input>
      </div>
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder marginTop">{{ 'CITY' | translate }}</label>
        <app-input formControlName="city"></app-input>
      </div>
      <div class="col-md-6 mt-5">
        <label class="form-label fs-6 fw-bolder ms-2 mb-4">{{ 'COUNTRY' | translate }}</label>
        <app-select
          [label]="'label'"
          [selectedValue]="this.customerForm.get('state')?.value"
          [options]="countriesList"
          size="large"
          type="primary"
          (statusSelectChange)="changeCountry($event)"
        ></app-select>
      </div>
    </div>
    <div class="row mt-2 w-80">
      <div class="col-12">
        <div *ngIf="contactPerson.length > 0" class="contactPersonWraperInput p-2 mt-3" formArrayName="contactPerson">
          <div *ngFor="let person of contactPerson.controls; let i = index" [formGroupName]="i" class="p-2">
            <div class="ms-6 mt-5 d-flex justify-content-between">
              <div>
                <img src="../../../../assets/media/logos/CONTACT_PERSON_ADD.png" alt="contact_person_icon" />
              </div>
              <div class="x-icon">
                <span
                  class="svg-icon svg-icon-1 close-icon"
                  (click)="removeContactPerson(i)"
                  [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
                ></span>
              </div>
            </div>
            <div class="row mt-2" style="padding-right: 0">
              <div class="col-md-6 mt-5">
                <label class="fs-6 fw-bolder required">{{ 'FIRST_NAME' | translate }}</label>
                <app-input
                  formControlName="firstName"
                  [ngClass]="{
                    'is-invalid': validateForm && contactPerson.controls[i].get('firstName')?.errors?.required
                  }"
                ></app-input>
                <div
                  *ngIf="validateForm && contactPerson.controls[i].get('firstName')?.errors?.required"
                  class="invalid-feedback"
                >
                  {{ 'ENTRY_IS_REQUIRED' | translate }}
                </div>
              </div>
              <div class="col-md-6 mt-5">
                <label class="fs-6 fw-bolder"> {{ 'LAST_NAME' | translate }}</label>
                <app-input formControlName="lastName"></app-input>
              </div>
            </div>

            <div class="colWidth mt-5">
              <label class="fs-6 fw-bolder"> {{ 'COMMENT' | translate }}</label>
              <app-input formControlName="comment"></app-input>
            </div>

            <div class="colWidth mt-5">
              <label class="fs-6 fw-bolder"> {{ 'E_MAIL' | translate }}</label>
              <app-input formControlName="email"></app-input>
            </div>

            <div class="colWidth mt-5 mb-10">
              <label class="fs-6 fw-bolder"> {{ 'PHONE_NUMBER' | translate }}</label>
              <app-input formControlName="phoneNumber"></app-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="hasContactPeople" class="row mt-2">
      <div class="col-12">
        <div *ngFor="let person of contactPeople" class="contactPersonWraperShow mt-5 mb-5" (click)="send(person)">
          <div class="ms-6 mt-5">
            <img src="../../../../assets/media/logos/CONTACT_PERSON_ADD.png" alt="contact_person_icon" />
          </div>
          <div class="contactPersonInfromationWraper ms-5">
            <span>{{ person?.firstName }}</span>
            <span>{{ person?.lastName }}</span>
            <span>{{ person?.comment }}</span>
            <span>{{ person?.email }}</span>
            <span>{{ person?.phoneNumber }}</span>
          </div>
        </div>
      </div>
    </div>
    <app-cp-input-modal
      [openModalSubject]="openModalSubject"
      [contactPerson]="contactPersonSubject"
      (newItemEvent)="saveFinished($event)"
    ></app-cp-input-modal>
  </div>
  <div class="row formRow">
    <div class="row mt-2">
      <div class="col-sm-6 col-xs-6 mt-5">
        <div style="display: flex; flex-direction: row; justify-content: flex-start; width: 100%">
          <app-button
            (click)="showContactPersonInput()"
            size="large"
            disableWrapperPadding
            [title]="'ADD_CONTACT' | translate"
          ></app-button>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6 mt-5" style="border: 1px soid black">
        <div style="display: flex; flex-direction: row; justify-content: flex-end; width: 100%">
          <app-button
            (click)="onSubmit()"
            buttonType="primary"
            size="large"
            disableWrapperPadding
            [title]="'SAVE' | translate"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</form>
<app-success-modal #modalSuccess [modalConfig]="modalConfig"> </app-success-modal>
<app-error-modal #modalError [modalConfig]="modalConfig"> </app-error-modal>
