import { AuthService } from 'app/modules/auth'
import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { ProjectStatus } from 'app/pages/core/model/project-status.model'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { Router, RouterModule } from '@angular/router'
import { CountProjectDatesService } from 'app/pages/projects/count-project-dates.service'
import { TimesheetInputService } from '../timesheet-input.service'
import { ButtonComponent } from '../button/button.component'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'
import { environment } from 'environments/environment'
import { DaysCount } from 'app/pages/projects/getDaysCount.service'
import { Subscription } from 'rxjs'
import { SharedService } from 'app/pages/projects/shared.service'
@Component({
  selector: 'app-project-details-card',
  templateUrl: './project-details-card.component.html',
  styleUrls: ['./project-details-card.component.scss'],
  standalone: true,

  imports: [CommonModule, TranslateModule, NgbModule, RouterModule, ButtonComponent],
})
export class ProjectDetailsCardComponent implements OnInit, OnChanges {
  private subscription: Subscription

  @Input() project: Project
  @Input() daysLeft: number
  @Input() teamDaysLeft: number
  @Input() procentageLeft: number
  @Input() procentageTeamLeft: number
  @Input() notStarted: boolean = false
  @Input() formatedDate: any
  @Input() projectStatus: string
  @Input() formatedStartDate: any
  @Input() roleBackoffice: boolean
  @Input() totalDaysLeft: number

  isFromMyProjects: boolean = false
  public projectStatuses: ProjectStatus[] = [
    {
      id: 'a4956773-4af7-4c04-9587-b200dc93187b',
      name: 'DRAFT',
    },
    {
      id: 'd46058cd-4f73-4dab-b051-af0c97910da8',
      name: 'RUNNING',
    },
    {
      id: '0ad5387e-9f03-4ff7-af9d-504d4d899754',
      name: 'PAUSED',
    },
    {
      id: '18e397fe-6cfd-4b1c-ae10-fa9927745b7a',
      name: 'FINISHED',
    },
  ]

  getColorBasedOnPercentage(percentage: number): string {
    if (percentage === 0) {
      return 'gray'
    } else if (percentage >= 1 && percentage <= 49) {
      return '#50CD89'
    } else if (percentage >= 50 && percentage <= 84) {
      return '#F1C041'
    } else if (percentage >= 85) {
      return '#F64E60'
    }

    return 'gray'
  }
  imgSrc: string = ''
  constructor(
    private projectService: ProjectService,
    public authService: AuthService,
    private projectCountDates: CountProjectDatesService,
    private timesheetSharedService: TimesheetInputService,
    private router: Router,
    public querySharedService: QuerySharedService,
    private daysCount: DaysCount,
    private sharedService: SharedService
  ) {
    this.subscription = this.sharedService.modalSave$.subscribe(value => {
      if (value) {
        this.projectService.getOneProject(this.project.id).subscribe((res: ApiResponse<Project>) => {
          if (!res?.response) return
          this.project = res.response
          this.project.projectBudgetLeftDays =
            this.project.usedBudgetInPercentage && this.project.budgetEstimation && this.project.usedBudget
              ? this.project?.budgetEstimation! - this.project?.usedBudget!
              : this.project.budgetEstimation
        })
      }
    })
  }

  ngAfterViewInit() {
    this.timesheetSharedService.getTimeSheetValue().subscribe((res: boolean) => {
      if (res) {
        this.getProjectDaysLeft()
      }
    })
  }

  ngOnInit(): void {
    this.sharedService.isFromMyProjects.subscribe((val: any) => (this.isFromMyProjects = val ?? false))
    this.getProjectDaysLeft()
    this.setImgData()
  }

  setImgData() {
    this.imgSrc = ''
    if (this.project.imageUrl) {
      this.imgSrc = this.project.imageUrl ? `${environment.imgUrl}${this.project.imageUrl}` : ''
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //only if the project is changed
    if (changes.project) {
      this.project = changes.project.currentValue
      this.getProjectDaysLeft()
      this.setImgData()
    }
  }

  getProjectDaysLeft() {
    this.projectService.getOneProject(this.project.id).subscribe((res: ApiResponse<Project>) => {
      if (!res?.response) return
      this.project = res.response
      this.projectCountDates.getDaysLeftProjectBudget(this.project)
      if (!this.totalDaysLeft) {
        this.totalDaysLeft = this.daysCount.getBudget(this.project.start, this.project.end)
      }
      const today: Date = new Date()
      if (this.project.start) {
        const projectDate = new Date(this.project.start)
        if (projectDate > today) {
          this.notStarted = true
        } else {
          this.notStarted = false
        }
      }
    })
  }

  changeStatus(status: any) {
    this.projectStatus = status.name
    this.project.projectStatus = status
    this.projectService.updateProjectStatus(this.project.id, status).subscribe((res: ApiResponse<Project>) => {})
  }

  editProject() {
    this.router.navigate(['/projects/edit/' + this.project.id + '/step-1/' + this.project.id])
  }

  back() {
    if (this.isFromMyProjects) {
      this.router.navigate(['/projects/my-projects-list', { queryParams: { myProjects: true } }])
    } else {
      this.router.navigate(['/projects/projects-list'])
    }

    //code lefte because maybe it will be needed
    // if (this.router.url.includes('edit/step-2')) {
    //   this.router.navigate(['/projects/project-overview/', this.project.id, '/team-members'])
    // } else {
    //   this.location.back()
    // }

    // if (this.router.url.includes('timesheet/specific-timesheet') || this.router.url.includes('/edit')
    //   || this.router.url.includes('/add-user') || this.router.url.includes('/user-budget')) {
    //   this.router.navigate(['/projects/project-overview/', this.project.id])
    // } else {
    //   let query = this.querySharedService.getQueryForRoute('projects/projects-list')
    //   this.router.navigate(['projects/projects-list'], {
    //     queryParams: {
    //       ...query,
    //       filter: this.querySharedService.getFilterForRoute('projects/projects-list'),
    //     },
    //     queryParamsHandling: 'merge',
    //   })
    // }
  }
}
