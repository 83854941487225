import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { SharedService } from 'app/pages/projects/shared.service'
import { Observable, catchError, map, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class NoneManagerGuard implements CanActivate {
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.projectService.getOneProject(route.paramMap.get('id')!).pipe(
      map((res: any) => {
        if (res?.httpStatus === 'OK') {
          this.sharedService.setProjectGuard(res.response)
          return true
        }
        window.alert('You do not have permission to view this page!')
        return false
      }),
      catchError(error => {
        window.alert('You do not have permission to view this page!')
        this.router.navigate(['dashboard'])
        return of(false)
      })
    )
  }
}
