import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from './utility/app.guard'

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./modules/self-registration/self-registration.module').then(m => m.SelfRegistrationModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./_metronic/layout/layout.module').then(m => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
