import { Company } from 'app/pages/core/model/company.model'
import { Injectable } from '@angular/core'
import { User } from '../core/model/flexc-user.model'
import { Project } from '../core/model/project.model'
import { Language } from '../core/model/language'
import { BehaviorSubject, Observable } from 'rxjs'
import { ProjectMember } from '../core/model/projectUsers.model'

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private numofdays: number = 0
  private ProjectShared: Project
  private timePeriod: number = 0
  private calculatedBudget: number = 0
  private days: number = 0
  private noEnd: boolean = false
  private allSelected: boolean = false
  private projectStatus: any[] = new Array()
  private taskLenght: number = 0
  private noEmail: boolean = false
  private users: User[] = []
  private superUsers: User[] = []
  private company: Company[] = []
  private projectId: string
  private languages: Language[] = []
  private searchValue: string
  private selectedFile: File
  private showInactive: boolean = true
  private projectFromGuard: BehaviorSubject<any> = new BehaviorSubject(null)
  isFromMyProjects: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private projectForMembers: Project
  private projectMembersManager: ProjectMember[]
  public weekMode: Event
  private _workingHours: number = 0

  projectNumberExist: boolean = false
  projectOfferExist: boolean = false
  projectNameExist: boolean = false

  private modalSaveSubject = new BehaviorSubject<boolean>(false)
  modalSave$: Observable<boolean> = this.modalSaveSubject.asObservable()
  constructor() {}

  setWeekMode(e: any) {
    this.weekMode = e
  }

  triggerModalSave() {
    this.modalSaveSubject.next(true)
  }

  getWeekMode() {
    return this.weekMode
  }

  setInputSearchValue(inputValue: string) {
    this.searchValue = inputValue
  }

  getInputSearchValue() {
    return this.searchValue
  }

  setProjectGuard(project: Project) {
    this.projectFromGuard.next(project)
  }

  getProjectGuard(): Observable<any> {
    return this.projectFromGuard as Observable<any>
  }

  getProjectId() {
    return this.ProjectShared.id!
  }
  setNumbOfDays(days: number) {
    this.numofdays = days
    this.ProjectShared.weekMode = this.numofdays
  }
  clearNumOfDays() {
    this.numofdays = 0
    this.ProjectShared.weekMode = this.numofdays
  }
  setProject(project: Project) {
    this.ProjectShared = project
  }
  getProject() {
    return this.ProjectShared
  }
  setTimePeriod(days: number) {
    this.timePeriod = days
    this.ProjectShared.time_period = this.timePeriod
    this.ProjectShared.timePeriod = this.timePeriod
  }
  getTimePeriod() {
    return this.timePeriod
  }
  clearTimePeriod() {
    this.timePeriod = 0
  }
  setCalculatedBudget(totalWorkingDays: number) {
    this.calculatedBudget = totalWorkingDays
    this.ProjectShared.budgetEstimation = this.calculatedBudget
  }
  getCalculatedBudget() {
    return this.calculatedBudget
  }
  clearCalculatedBudget() {
    this.calculatedBudget = 0
  }
  setDays(days: number) {
    if (days === 0) {
      this.days = 0
    } else {
      this.days += days
    }
  }
  getDays() {
    return this.days
  }
  clearDays() {
    this.calculatedBudget = 0
    this.days = 0
    this.noEnd = false
  }

  setNumberOfPeople(numberOfPeople: number) {
    this.ProjectShared.plannedPeople = numberOfPeople
  }
  setNoEnd() {
    this.noEnd = !this.noEnd
  }
  getNoEnd() {
    return this.noEnd
  }
  setAllSelected(selected: boolean) {
    this.allSelected = selected
  }
  getAllSelected() {
    return this.allSelected
  }
  setStatus(status: any) {
    this.projectStatus.push(status)
  }
  getStatus() {
    return this.projectStatus
  }
  setTaskLenght(taskLenght: number) {
    this.taskLenght = taskLenght
  }
  getTaskLenght() {
    return this.taskLenght
  }

  setNoEmail() {
    this.noEmail = !this.noEmail
  }

  getNoEmail() {
    return this.noEmail
  }

  setUsers(users: User[]) {
    this.users = users
  }

  getAllLanguages() {
    return this.languages
  }

  setSuperUsers(superUsers: User[]) {
    this.superUsers = superUsers
  }

  getAllUsers() {
    return this.users
  }

  getSuperUsers() {
    return this.superUsers
  }

  setCompany(company: Company[]) {
    this.company = company
  }

  getCompany() {
    return this.company
  }

  setProjectId(id: string) {
    this.projectId = id
  }

  getProjectIdParam() {
    return this.projectId
  }

  setImageFile(file: File) {
    this.selectedFile = file
  }

  getImageFile() {
    return this.selectedFile
  }
  formatDate(dateString: any) {
    const dateParts = dateString.split('-')
    const day = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1
    const year = parseInt(dateParts[2], 10)
    const parsedDate = new Date(year, month, day, 13, 0, 0)
    return parsedDate
  }
  setShowInactive(showInactive: boolean) {
    this.showInactive = showInactive
  }
  getShowInactive() {
    return this.showInactive
  }
  setProjectMembers(project: Project) {
    this.projectForMembers = project
  }
  getProjectMembers() {
    return this.projectForMembers
  }
  clearProject() {
    let project: Project
    this.projectForMembers = project!
  }

  setProjectMembersManager(projectMembers: ProjectMember[]) {
    this.projectMembersManager = projectMembers
  }

  getProjectMembersManager() {
    return this.projectMembersManager
  }

  getProjectNaumberExist() {
    return this.projectNumberExist
  }

  setProjectNumberExist(projectNumber: boolean) {
    this.projectNumberExist = projectNumber
  }

  getProjectOfferExist() {
    return this.projectOfferExist
  }

  setProjectOfferExist(projectOffer: boolean) {
    this.projectOfferExist = projectOffer
  }

  getProjectNameExist() {
    return this.projectNameExist
  }

  setProjectNameExist(projectName: boolean) {
    return (this.projectNameExist = projectName)
  }

  get workingHours() {
    return this._workingHours
  }

  set workingHours(workingHours: number) {
    this._workingHours = workingHours
  }
}
