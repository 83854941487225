import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { Observable } from 'rxjs'
import { ApiResponse } from '../dto/ApiResponse'
import { Company } from '../model/company.model'
import { companyImageShared } from '../../company/comapnyImageShared.service'
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  companyUrl: string = environment.apiBase + '/companies'

  constructor(private http: HttpClient, private companyImageShared: companyImageShared) {}

  createCompany(body: any): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(this.companyUrl + '/create-company', body)
  }

  checkCompanyName(body: Company): Observable<any> {
    return this.http.post<any>(this.companyUrl + '/is-name-taken-registration', body)
  }

  getAllCompanys(): Observable<ApiResponse<Company[]>> {
    return this.http.get<ApiResponse<Company[]>>(this.companyUrl + '/get-all')
  }

  isNameTaken(name: string): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.companyUrl + '/is-name-taken', { name })
  }

  getOneCompany(schemaName: string): Observable<ApiResponse<Company[]>> {
    return this.http.get<ApiResponse<Company[]>>(this.companyUrl + `/${schemaName}`)
  }

  updateCompany(body: any): Observable<ApiResponse<Company>> {
    return this.http.put<ApiResponse<Company>>(this.companyUrl + '/update-company', body)
  }

  getCompanySettings(): Observable<ApiResponse<Company>> {
    return this.http.get<ApiResponse<Company>>(this.companyUrl + '/get-settings')
  }

  getIndustries(): Observable<string[]> {
    return this.http.get<string[]>(this.companyUrl + '/get-industries')
  }

  uploadImage(id: string): Observable<ApiResponse<Company>> {
    const selectedFile: File = this.companyImageShared.getImageFile()
    const fd = new FormData()
    fd.append('image', selectedFile, selectedFile.name)
    return this.http.put<ApiResponse<Company>>(this.companyUrl + '/update-company/' + id + '/' + 'image', fd)
  }

  deleteImage(companyId: string): Observable<ApiResponse<Company>> {
    return this.http.put<ApiResponse<Company>>(this.companyUrl + `/update-company/${companyId}` + '/remove-image', {})
  }
  public getStandardLanguages(): Observable<ApiResponse<string[]>> {
    return this.http.get<ApiResponse<string[]>>(this.companyUrl + '/get-default-languages')
  }
  //add type
  registerCompany(companyForRegister: any): Observable<any> {
    return this.http.post<any>(this.companyUrl + '/register-company', companyForRegister)
  }
}
