<div class="row">
  <div class="col-12 mb-5">
    <app-project-details-card
      *ngIf="project"
      [project]="project"
      [daysLeft]="daysLeft"
      [totalDaysLeft]="totalDaysLeft"
      [teamDaysLeft]="teamDaysLeft"
      [procentageLeft]="procentageLeft"
      [procentageTeamLeft]="procentageTeamLeft"
      [notStarted]="notStarted"
      [formatedDate]="formatedDate"
      [projectStatus]="projectStatus"
      [formatedStartDate]="formatedStartDate"
      class="card card-xxl-stretch mb-xl-3"
    ></app-project-details-card>
  </div>
</div>
<div class="card mt-5">
  <div class="card-body">
    <div class="task-list">
      <div class="task-list_controls">
        <div class="task-list_controls_column">
          <button
            class="nav-button"
            [routerLink]="'/projects/project-overview/' + this.project.id + '/team-members'"
            [queryParams]="{ myProjects: !!isFromMyProjects }"
            [class.active]="isLinkActive('TEAM_MEMBERS')"
          >
            <h3 class="stepper-title uppercase">{{ 'TEAM_MEMBERS' | translate | uppercase }}</h3>
          </button>
          <button
            class="nav-button"
            routerLink="used-budget"
            [queryParams]="{ myProjects: !!isFromMyProjects }"
            [class.active]="isLinkActive('USED_BUDGET')"
          >
            <h3 class="stepper-title uppercase">{{ 'USED_BUDGET' | translate | uppercase }}</h3>
          </button>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
