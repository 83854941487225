<form [formGroup]="form" *ngIf="form">
  <div class="row s_language">
    <label class="form-label fs-6 fw-bolder">
      {{ 'SPOKEN_LANAGUES' | translate }}
    </label>
  </div>
  <div class="row mt-2" formArrayName="languages">
    <div *ngFor="let language of languagesArray.controls; let i = index" [formGroupName]="i" class="row">
      <div class="col-md-6 mt-2">
        <app-select
          [options]="items$ | async"
          [selectedValue]="language.value.language"
          [label]="'label'"
          type="primary"
          size="large"
          (statusSelectChange)="setNewValueForChoosenLanguage($event, i)"
          [placeholder]="'ADD_LANGUAGE' | translate"
        ></app-select>
      </div>
      <div class="col-md-4">
        <input name="skill" type="range" class="form-range mt-1" min="1" max="5" formControlName="skill" />
        <output name="skill" for="a b" min="1" max="5" class="skill text-muted"
          >{{ 'SKILL_LEVEL' | translate }} {{ languagesArray.controls[i].value.skill }}
        </output>
      </div>
      <div class="col-md-2 delete-button-wrapper">
        <img
          src="../../../../../assets/media/logos/minusSquare.svg"
          alt="minus icon"
          class="language delete_language_button"
          (click)="deleteLenguage(i)"
          *ngIf="i >= 0"
        />
      </div>
    </div>
  </div>
</form>
<div class="wraperAddLanguage mt-5">
  <div>
    <img
      src="../../../../../assets/media/logos/plusSquare.svg"
      alt="plus icon"
      class="language"
      (click)="addLanguage()"
    />
  </div>
  <div class="infoMessage">
    <div class="language me-5">
      <img src="../../../../../assets/media/logos/com007.svg" />
    </div>
    <div class="d-flex flex-column">
      <span>{{ 'OVERVIEW_OF_LANGUAGE_SKIL' | translate }}</span>
      <span> 1 - {{ 'BASIC_KNOWLEDGE' | translate }}</span>
      <span> 2 - {{ 'GOOD_KNOWLEDGE' | translate }}</span>
      <span> 3 - {{ 'FLUENT_KNOWLEDGE' | translate }}</span>
      <span> 4 - {{ 'NEGOTIABLE_KNOWLEDGE' | translate }}</span>
      <span> 5 - {{ 'NATIV_KNOWLEDGE' | translate }}</span>
    </div>
  </div>
</div>
