import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { CompanyService } from 'app/pages/core/services/company.service'
import { TimeService } from '../../project-overview/project-modify/time-service.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ProjectService } from 'app/pages/core/services/project.service'
import { SharedService } from '../../shared.service'

@Component({
  selector: 'app-create-project-step-two',
  templateUrl: './create-project-step-two.component.html',
  styleUrls: ['./create-project-step-two.component.scss'],
})
export class CreateProjectStepTwoComponent implements OnInit {
  projectTimeForm: FormGroup
  items: any[] = [
    {
      label: this.translate.instant('FIVE_DAYS_A_WEEK'),
      value: '5',
    },
    {
      label: this.translate.instant('SIX_DAYS_A_WEEK'),
      value: '6',
    },
    {
      label: this.translate.instant('SEVEN_DAYS_A_WEEK'),
      value: '7',
    },
  ]

  numberOfDays = 0
  totalWorkingDays = 0
  days = 0
  plannedPeople = 0
  weekModeValue: any = this.items[0]
  projectId: string
  noEnd: boolean = false
  calculatedBudget: number = 0
  startDateCheck: NgbDateStruct
  showInactive: boolean = false
  totalWorkingDaysEstimate: number
  workingHour: number
  startTime: any
  endTime: any
  submitted: boolean = false
  projectData: Project
  endGratherThenStart: boolean = false
  isEdit: boolean = false

  constructor(
    private translate: TranslateService,
    private timeService: TimeService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.projectId = params['id']
      //check if is from edit project, so we do not change to next step but go back to overview
      this.isEdit = this._router.url.includes('edit')
      this.setupForm()
      this.getCompanyHoursSettings()
      this.getProjectData()
    })

    this.translate.onLangChange.subscribe(() => {
      this.items = [
        {
          label: this.translate.instant('FIVE_DAYS_A_WEEK'),
          value: '5',
        },
        {
          label: this.translate.instant('SIX_DAYS_A_WEEK'),
          value: '6',
        },
        {
          label: this.translate.instant('SEVEN_DAYS_A_WEEK'),
          value: '7',
        },
      ]
    })
  }

  setupForm() {
    this.projectTimeForm = this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
      planedPeople: [''],
      weekMode: [],
      noEndDate: [],
      projectDays: [],
      calculatedBudget: [],
      budgetEstimation: [],
    })
  }

  getCompanyHoursSettings() {
    this.companyService.getCompanySettings().subscribe(({ response }: ApiResponse<any>) => {
      this.workingHour = response.workingHours
    })
  }

  getWeekDays(e: any) {
    if (e) {
      this.weekModeValue = e
      this.days = this.timeService.countWeekdays(this.startTime, this.endTime, +this.weekModeValue.value)
      this.totalWorkingDays = this.days * (this.plannedPeople ?? 0)
      this.totalWorkingDaysEstimate = this.totalWorkingDays
      this.projectTimeForm.controls['budgetEstimation'].setValue(this.totalWorkingDaysEstimate)
    }
  }

  calculateDays() {
    let startDate = this.projectTimeForm.value.start.split('-')
    this.startTime = new Date(startDate[2], startDate[1] - 1, startDate[0])

    if (!this.noEnd) {
      let endDate = this.projectTimeForm.value.end.split('-')
      this.endTime = new Date(endDate[2], endDate[1] - 1, endDate[0])
      this.days = this.timeService.countWeekdays(this.startTime, this.endTime, +this.weekModeValue.value)
      this.totalWorkingDays = this.days * (this.plannedPeople ?? 0)
    } else {
      this.projectTimeForm.get('end')?.setValue('')
      this.projectTimeForm.controls['end'].disable()
    }

    this.chekIfEndIsGratherThenStart()
  }

  chekIfEndIsGratherThenStart() {
    if (this.isValidDate(this.startTime) && this.isValidDate(this.endTime) && !this.noEnd) {
      this.endGratherThenStart = this.startTime > this.endTime
    }
  }

  isValidDate(date: Date) {
    return date instanceof Date && !isNaN(date.getTime())
  }

  daysInput($event: any) {
    this.totalWorkingDaysEstimate = $event.target.value
  }

  resetTimePeriodAndWeekMode() {
    this.weekModeValue = null
    this.projectTimeForm.get('weekMode')?.reset()
    this.days = 0
    this.plannedPeople = 0
    this.totalWorkingDays = 0
  }

  totalWorking(e: any) {
    this.plannedPeople = e.target.value
    this.totalWorkingDays = this.days * (this.plannedPeople ?? 0)
    this.totalWorkingDaysEstimate = this.totalWorkingDays
    this.projectTimeForm.controls['budgetEstimation'].setValue(this.totalWorkingDaysEstimate)
  }

  changeNoEnd(event: any) {
    this.noEnd = event.target.checked

    if (this.noEnd) {
      this.days = 0
      this.totalWorkingDays = 0
    }
    this.projectTimeForm.get('noEnd')?.setValue(this.noEnd)
    if (this.noEnd) {
      this.projectTimeForm.get('end')?.setValue('')
      this.projectTimeForm.controls['end'].disable()
    } else {
      this.projectTimeForm.controls['end'].enable()
    }
  }

  get formValue() {
    return this.projectTimeForm.value
  }

  calculateBudget() {
    this.showInactive = !this.showInactive
    this.noEnd = false
  }

  resetDays() {
    this.totalWorkingDaysEstimate = this.totalWorkingDays
  }

  getProjectData() {
    this.projectService.getOneProject(this.projectId).subscribe(({ response }: ApiResponse<Project>) => {
      this.projectData = response
      this.projectTimeForm.patchValue(this.projectData)
      if (this.projectData?.start) {
        this.formControls['start'].setValue(this.datePipe.transform(this.projectData?.start, 'dd-MM-yyyy'))
        this.formControls['end'].setValue(this.datePipe.transform(this.projectData?.end, 'dd-MM-yyyy'))
        this.plannedPeople = this.formControls['planedPeople'].value
        this.noEnd = this.formControls['noEndDate'].value
        this.totalWorkingDays = this.formControls['budgetEstimation'].value
        this.calculateDays()
      }
    })
  }

  saveProject() {
    this.submitted = true
    if (!this.projectTimeForm.valid || this.endGratherThenStart) return
    this.projectData = { ...this.projectData, ...this.projectTimeForm.value }
    this.projectData.start = this.formatDate(this.projectTimeForm.value.start)
    if (!this.noEnd) {
      this.projectData.end = this.formatDate(this.projectTimeForm.value.end)
    }
    this.projectService.updateProject(this.projectData).subscribe(({ response }: ApiResponse<Project>) => {
      if (response) {
        if (!this.isEdit) {
          this._router.navigate(['../../step-3/', this.projectId], { relativeTo: this.route })
        } else {
          this.sharedService.setProjectGuard(response)
          this._router.navigate(['/projects/project-overview/', this.projectId, 'team-members'])
        }
      }
    })
  }

  formatDate(dateString: any) {
    const dateParts = dateString.split('-')
    const day = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1
    const year = parseInt(dateParts[2], 10)
    const parsedDate = new Date(year, month, day, 13, 0, 0)
    return parsedDate
  }

  get formControls() {
    return this.projectTimeForm.controls
  }

  goBack() {
    this._router.navigate(['../../step-1/', this.projectId], { relativeTo: this.route })
  }
}
