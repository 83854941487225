// USA
export const locale = {
  lang: 'en',
  data: {
    DATE_AND_STATUS: 'Date',
    DESCRIPTION: 'Description',
    START_TIME: 'Start Time',
    START_BREAK: 'Start Break',
    END_BREAK: 'End Break',
    END_TIME: 'End Time',
    SUM_HOURS: 'Sum Hours',
    PROGRESS: 'Progress',
    APPROVED: 'Approved',
    PDF_PRINT: 'PDF Print',
    ADD_NEW_ENTRY: 'Add New Entry',
    ADD_NEW_ENTRY_FOR: 'Add new entry for',
    DATE: 'Date',
    FILE_UPLOAD: 'File Upload',
    TASK: 'Task',
    PLACEHOLDER: 'Placeholder',
    ALL_PROJECTS: 'All Projects',
    ENTRIES: 'Entries',
    TASK_OVERVIEW: 'Task Overview',
    CUSTOMERS: 'Customers',
    LIST_OF_CUSTOMERS: 'List of Customers',
    COMPANY_TASK_OVERVIEW: 'Company',
    PROJECT_TASK_OVERVIEW: 'Project',
    CREATED: 'Created',
    TASK_NAME: 'Task Name',
    USAGE: 'Usage',
    TASK_NAME_IS_REQUIRED: 'Entry is required',
    DESCRIPTION_IS_REQUIRED: 'Entry is required',
    PROJECT_IS_REQUIRED: 'Entry is required',
    ENTER_NEW_COMPANY_TASK: 'Enter New Company Task',
    DISCARD: 'Discard',
    SAVE_AND_CLOSE: 'Save And Close',
    ADD_NEW_TASK: 'Add New Task',
    ENTER_NEW_PROJECT_TASK: 'Enter new project task',
    NO_DATA_TO_SHOW: 'No data to show',
    PROJECT_WILL_START_SOON: 'Project will start soon',
    PROJECT: 'Project',
    SHOWING: 'SHOWING',
    TO: 'TO',
    OF: 'of',
    USERS: 'Users',
    COMPANIES: 'Companies',
    ADD_SUPER_ADMIN: 'Add Super Admin',
    SUPER_ADMIN_LIST: 'Super Admin List',
    ADD_COMPANY: 'Add Comapny',
    COMPANY_LIST: 'Company List',
    SUPER_ADMIN_MENU: 'SUPER ADMIN MENU',
    SUPER_ADMIN_COMPANY_LIST: 'Super Admin Company List',
    ID: 'ID',
    USERNAME: 'Username',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    COMPANY: 'COMPANY',
    EMAIL: 'E-Mail',
    ENTER_COMPANY_NAME: 'Enter company name',
    COMPANY_ADMIN: 'Company Admin',
    COMPANY_ADMIN_FULL_NAME: 'Company Admin full name',
    ENTER_COMPANY_ADMIN_FULL_NAME: 'Enter company admin full name',
    COMPANY_ADMIN_EMAIL: 'Company admin email',
    ENTER_COMPANY_ADMIN_EMAIL: 'Enter company admin email',
    COMPANY_ADMIN_PHONE_NUMBER: 'Company admin phone number',
    ENTER_COMPANY_ADMIN_PHONE_NUMBER: 'Enter company admin phone number',
    COMPANY_NAME: 'Company Name',
    COMPANY_NAME_IS_REQUIRED: 'Entry is required',
    STREET_NAME_IS_REQUIRED: 'Entry is required',
    STREET_NUMBER_IS_REQUIRED: 'Entry is required',
    POSTCODE_IS_REQUIRED: 'Entry is required',
    CITY_IS_REQUIRED: 'Entry is required',
    STATE_IS_REQUIRED: 'Entry is required',
    COMPANY_ADMIN_FULL_NAME_IS_REQUIRED: 'Entry is required',
    COMPANY_ADMIN_EMAIL_IS_REQUIRED: 'Entry is required',
    COMPANY_ADMIN_PHONE_NUMBER_IS_REQUIRED: 'Entry is required',
    CREATE_COMPANY: 'Create Company',
    SUPER_ADMIN_SUPER_USER_LIST: 'SUPER ADMIN SUPER USER LIST',
    COMPANY_NR: 'COMPANY NR',
    CUSTOMER_SINCE: 'CUSTOMER SINCE',
    STATUS: 'Status',
    ARCHIVED_USER: 'Archived User',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    DRAFT: 'DRAFT',
    OFFER: 'OFFER',
    RUNNING: 'RUNNING',
    PAUSED: 'PAUSED',
    FINISHED: 'FINISHED',
    CREATE_SUPER_USER_ADMIN: 'Create Super User Admin',
    SUPER_USER_USERNAME: 'Super user username',
    ENTER_SUPER_USER_USERNAME: 'Enter super user username',
    SUPER_USER_USERNAME_IS_REQUIRED: 'Entry is required',
    SUPER_USER_FIRST_NAME: 'Super user first name',
    ENTER_SUPER_USER_FIRST_NAME: 'Enter super user first name',
    SUPER_USER_FIRST_NAME_IS_REQUIRED: 'Entry is required',
    SUPER_USER_LAST_NAME: 'Super user last name',
    ENTER_SUPER_USER_LAST_NAME: 'Enter super user last name',
    SUPER_USER_LAST_NAME_IS_REQUIRED: 'Entry is required',
    SUPER_USER_EMAIL: 'Super user email',
    ENTER_SUPER_USER_EMAIL: 'Enter super user email',
    SUPER_USER_EMAIL_IS_REQUIRED: 'Entry is required',
    CREATE_SUPER_USER: 'Create Super User',
    FIVE_DAYS_A_WEEK: '5 days a week',
    SIX_DAYS_A_WEEK: '6 days a week',
    SEVEN_DAYS_A_WEEK: '7 days a week',
    BOOK_YOUR_TIME: 'Book your time',
    DAYS: 'days',
    PLANED_DAYS_GREATHER_THAN_TOTAL_WORKING_DAYS: 'Planed days greather than total working days',
    HELLO: 'Hello',
    ENTER_JOB_TITLE: 'Enter job title',
    JOB_TITLE_IS_REQUIRED: 'Entry is required',
    SKILL_LEVEL: 'Skill Level',
    COUNTRY: 'Country',
    START: 'Getting started',
    END: 'End',
    PROJECT_DESCRIPTION: 'Project Description',
    ENTER_COUNTRY: 'Enter country',
    ENTER_PROJECT_NAME: 'Enter project name',
    ENTER_PROJECT_DESCRIPTION: 'Enter project description',
    CUSTOMER_CREATED_SUCCESSFULLY: 'Customer created successfully',
    CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG: 'Customer not created, something went wrong',
    USER_SAVED_SUCCESSFULY: 'User saved successfully',
    EXPERTISE: 'EXPERTISE',
    KNOW_HOW: 'Know How',
    PROJECTS: 'Projects',
    SPOKEN_LANAGUES: 'Spoken Lanagues',
    ADD_PERSONAL_EXPERIENCE: 'Add personal expirience',
    ADD_LANGUAGE: 'Add Language',
    KNOWLEDGE_OVERVIEW: 'Knowledge Overview',
    NAME: 'Name',
    ADD_KNOWLEDGE: 'Add Knowledge',
    KNOWLEDGE_NAME: 'Knowledge Name',
    ENTER_NEW_KNOWLEDGE: 'Enter New Knowledge',
    KNOWLEDGE_NAME_IS_REQUIRED: 'Entry is required',
    KNOWLEDGE_CREATED_SUCCESSFULLY: 'Knowledge created successfully',
    KNOWLEDGE_NOT_CREATED_SOMETHING_WENT_WRONG: 'Knowledge not created, something went wrong',
    ENTER_COMPANY_TASK: 'Enter Company Task',
    ENTER_PROJECT_TASK: 'Enter Project Task',
    TASK_CREATED_SUCCESSFULLY: 'Task created successfully',
    TASK_NOT_CREATED_SOMETHING_WENT_WRONG: 'Task not created, something went wrong',
    OVERVIEW: 'Overview',
    ADD: 'Add',
    USER: 'User',
    CUSTOMER: 'Customer',
    DASHBOARD: 'Dashboard',
    PROJECT_OVERVIEW: 'Overview',
    TASK_WITH_THAT_NAME_ALREADY_EXIST: 'Task with that name already exist',
    KNOWLEADGE_ALREADY_EXIST: 'Knowleadge already exist',
    DATE_IS_REQUIRED: 'Entry is required',
    LEFT: 'Left',
    DONE: 'Done',
    START_WORK: 'Start Work',
    START_WORK_TIME_IS_REQUIRED: 'Entry is required',
    BREAK_TIME_CANT_START_BEFORE_YOU_START_WORKING: 'Break time cant start before you start working',
    BREAK_TIME_END_IS_BEFORE_BREAK_TIME_START: 'Break time end is before break time start',
    END_WORK: 'End Work',
    'END_ WORK_TIME_IS_REQUIRED': 'Entry is required',
    END_WORKING_TIME_IS_BEFORE_END_BREAK_TIME: 'End working time is before end break time',
    TASKS: 'Tasks',
    CLICK: 'click or drag & drop your file here with available formats: .jpg .jpeg .png .pdf',
    ENTER_NEW_ENTERY_FOR: 'New entry for',
    PLEASE_CHOSE_PROJECT_FIRST: 'Please choose project first',
    THIS_ENTRY_ALREADY_EXISTS: 'This entry already exists',
    CREATED_BY: 'CREATED BY',
    KNOWHOW: 'KNOWHOW',
    RECORDS_PER_PAGE: 'Records per page',
    IN_TOTAL: 'in entries',
    JOB_TITLE: 'Job Title',
    ROLE: 'Role',
    INDUSTRY: 'Industry',
    ADD_CONTACT: 'Add Contact',
    PLEASE_CHOOSE_PROJECT_START_DATE: 'Please choose project start date',
    SIGN_OUT: 'Sign Out',
    First_name: 'First name',
    NO_TASKS_FOUND_FOR_SELECTED_PROJECT: 'No tasks found for selected project',
    NO_PROJECTS_FOUND: 'No projects found - please contact your company administrator',
    ROLE_PROJECT_ADMIN: 'Administrator',
    ROLE_PROJECT_MEMBER: 'User',
    ROLE_PROJECT_BACKOFFICE: 'Backoffice',
    NORMAL_USER: 'User',
    ROLE_OWNER_ADMIN: 'Superadmin',
    PLEASE_CHOOSE_CUSTOMER: 'Please choose customer',
    CHOOSE_AT_LEAST_ONE_CUSTOMER: 'Choose at least one customer',
    CUSTOMERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Customers archive/unarchive successfully',
    CHOOSE_AT_LEAST_ONE_USER_ADMINISTRATOR_CANNOT_BE_ARCHIVED:
      'Choose at least one user administrator can not be archived',
    USERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Users archive/unarchive successfully',
    COMPANIES_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Companies archive/unarchive succesfully',
    CHOOSE_AT_LEAST_ONE_COMPANY: 'Choose at least one company',
    USER_MAIN_MENU_SIDEBAR: 'Users',
    E_MAIL: 'E-Mail',
    BACK: 'Back',
    SAVE_AND_CONTINUE: 'Save and Continue',
    PROJECT_NUMBER: 'Project Number',
    PROJECT_OFFER: 'Project Offer',
    PROJECT_NAME: 'Project Name',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    NO_END: 'No End',
    WEEK_MODE: 'Week mode',
    TIME_PERIOD: 'Time Period',
    PLANED_PEOPLE: 'Planed People',
    TOTAL_WORKING_DAYS: 'Total working days',
    ADD_USERS_TO_THE_TEAM: 'Add users to the team',
    ALLOCATE: 'Allocate',
    PLANNED: 'Planned',
    EXPORT: 'Export',
    KNOWLEDGE_AND_THE_TEAM: 'Knowledge and the team',
    ALLOCATE_AND_FINISH: 'Allocate and Finish',
    TOPIC_KNOWHOW: 'Topic/KnowHow',
    BUILD_THE_TEAM: 'Build the team',
    DUE_DATA: 'Due Date',
    OVERVIEW_APPROVALS: 'Overview Approvals',
    EXPORT_AND_VERIFY_HOURS: 'Export and verify hours',
    NO_FETCH_DATE: 'No fetch date',
    ERROR_WHILE_SAVING_DATA: 'Error while saving data',
    SAVED_SUCCESSFULLY: 'Saved Successfully',
    CREATE_NEW_PASSWORD: 'CREATE NEW PASSWORD',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    CREATE: 'Create',
    USER_PASSWORD_HAS_BEEN_SUCCESSFULLY_CREATED: 'User password has been successfully created!',
    SHOW_ACTIVE: 'Active',
    SHOW_INACTIVE: 'Inactive',
    FRONTEND: 'Frontend',
    DRAFT_STATUS: 'DRAFT',
    RUNNING_STATUS: 'RUNNING',
    PAUSED_STATUS: 'PAUSED',
    FINISHED_STATUS: 'FINISHED',
    MY_PROFILE: 'My Profile',
    KNOWLEDGE: 'Knowledge',
    LIGHT: 'Light',
    DARK: 'Dark',
    SYSTEM: 'System',
    NO_ITEMS_FOUND: 'No items found',
    PASSWORD_DOES_NOT_MATCH: 'Password does not match',
    PASSWORD_VALIDATOR:
      'Password complexity: at least one uppercase letter [A-Z], at least one lowercase letter [a-z], at least one special character and at least 8 characters long. White spaces are not allowed!',
    RESET_PASSWORD: 'Reset Password',
    CUSTOMER_NAME_MUST_BE_UNIQUE: 'Customer name must be unique',
    CUSTOMER_END_NAME_MUST_BE_UNIQUE: 'Customer end name must be unique',
    MY_TIMESHEET: 'My Timesheet',
    MY_PROJECTS: 'My Projects',
    ADMIN_MENU: 'ADMIN MENU',
    ADD_USER: 'Add User',
    ADD_CUSTOMER: 'Add Customer',
    ADD_PROJECTS: 'Add Projects',
    TEMPLATES: 'Templates',
    ABOUT: 'About',
    ENGLISH: 'English',
    GERMAN: 'German',
    SERBIAN: 'Serbian',
    USER_ROLE: 'User Role',
    NO_MAIL: 'No Mail',
    SPOKEN_LANGUAGES: 'Spoken Languages',
    SAVE: 'Save',
    PROJECT_LIST: 'Project List',
    OVERVIEW_OF_ALL_PROJECTS: 'Overview of all projects',
    PROJECT_STATUS_FILTER: 'Project Status Filter',
    SEARCH: 'Search',
    NUMBERS: 'numbers',
    STATE: 'Status',
    TEAM: 'Team',
    DAYS_LEFT: 'days Left',
    END_CUSTOMER: 'End Customer',
    COMMENT: 'Comment',
    STREET: 'Street',
    NUMBER: 'Number',
    ZIP: 'ZIP',
    CITY: 'City',
    MASS_ACTIVITY: 'Mass Activity',
    USER_DETAILS: 'User Details',
    PROJECT_DETAILS: 'Project Details',
    TIME_AND_BUDGET: 'Time and Budget',
    BASIC_DATA: 'Basic data can be added here',
    SHORT_DESCRIPTION: 'Short Description (public visible for all project members)',
    PROJECT_NAME_IS_REQUIRED: 'Entry is required',
    TOPIC_DESCRIPTION: 'What kind of knowledge is needed for this project',
    STAFF: 'Stuff',
    CAPACITY: 'Capacity',
    TIMESHEET: 'Timesheet',
    ADD_PROJECT: 'Add Project',
    ARCHIVE: 'Archive',
    UNARCHIVE: 'Unarchive',
    CHANGE_PROFILE_IMAGE: 'Change profile image',
    PHONE_NUMBER: 'Phone Number',
    USERNAME_IS_REQUIRED: 'Entry is required',
    USER_ROLE_IS_REQUIRED: 'Entry is required',
    EMAIL_IS_REQUIRED: 'Entry is required',
    FIRST_NAME_IS_REQUIRED: 'Entry is required',
    LAST_NAME_IS_REQUIRED: 'Last name is required',
    BACKOFFICE_MENU: 'BACKOFFICE MENU',
    USER_MENU: 'USER MENU',
    START_PROJECT: 'Start Project',
    MEMBERS: 'members',
    ARCHIVED_PROJECTS: 'Archived Projects',
    EDIT_PROJECT: 'Edit Project',
    PROJECT_PERIOD: 'Project Period',
    PROJECT_BUDGET: 'Project Budget',
    USED: 'used',
    REMAINING_DAYS: 'working days are remaining',
    COLLAGUES: 'Collagues',
    STATUS_BUDGET: 'Status budget',
    ACTIONS: 'Actions',
    DAYS_TOTAL: 'days total',
    EDIT: 'Edit',
    ADD_ADITIONAL_TIME_PERIOD: 'Add Aditional Time Period',
    DEFINE_PERIOD: 'Define the period and the budget',
    KNOW_HOW_DESCRIPTION: 'E .g. senior level, qualification, zertificates, special knowledge, language etc',
    USERNAME_EXIST: 'Username is alerady taken',
    CUSTOMER_REQUIRED: 'Entry is required',
    MANAGER: 'Manager',
    ENTER_COMPANY_ADMIN_FIRST_NAME: 'Enter company admin first name',
    ENTER_COMPANY_ADMIN_LAST_NAME: 'Enter company admin last name',
    ENTER_COMPANY_ADMIN_USERNAME: 'Enter company admin username',
    COMPANY_ADMIN_FIRST_NAME_IS_REQUIRED: 'Entry is required',
    COMPANY_ADMIN_LAST_NAME_IS_REQUIRED: 'Entry is required',
    COMPANY_ADMIN_USERNAME_IS_REQUIRED: 'Entry is required',
    TEAM_MEMBERS_PLAN: 'Which team members do you plan for this project',
    PROJECT_START_DATE: 'Project Start Date',
    PROJECT_NUMBER_EXIST: 'Project number or offer already exists',
    PROJECT_INFO_UPDATE_SUCESFULY: 'Project info update sucessfully',
    ASSIGN: 'Assign',
    PROJECT_SAVED_SUCCESSFULLY: 'Project saved successfully',
    CAN_BE_USED: 'Can be used in any project',
    ONLY_USABLE: 'Only usable in the project',
    ACTION: 'Action',
    FIRST_LAST_NAME_MESSAGE: 'Words and up to three words are allowed',
    SUCCESSFULLY_DELETED: 'Successfully deleted',
    CANCEL: 'Cancel',
    DELETE_SUPER_ADMIN: 'Delete super admin',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_USER: 'Do you really want to delete this user',
    THE_PASSWORD_MUST_INCLUDE_AT_LEAST_ONE_UPPERCASE_LETTER_ONE_NUMBER_AND_ONE_SPECIAL_CHARACTER:
      'Please use at least: 8 characters, 1 uppercase letter (A-Z), 1 lowercase letter (a-z), 1 number (0-9) and 1 special character (@#$%^&+=!._)',
    MARK_AS_APPROVED: 'Mark as approved',
    MARK_AS_BILLED: 'Mark as billed',
    EXPORT_PDF: 'Export PDF',
    EXPORT_CSV: 'Export .csv',
    PRINT_PDF: 'Print PDF',
    USERNAME_CANNOT_CONTAIN_WHITE_SPACE: 'Username can not contain white space!',
    ARE_YOR_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_BILLED: 'Do you really want to set the status to billed?',
    ARE_YOU_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_APPROVED: 'Do you really want to set the status to approved?',
    DO_YOU_REALLY_WANT_TO_DELETE_TIMESHEET: 'Do you really want to delete timesheet?',
    APPROVE_ALL: 'Approve All',
    TIMESHEET_ACTION: 'Timesheet Action',
    ACTIVE_USER: 'Activate',
    INACTIVE_USER: 'Deactivate',
    PROJECT_NAME_ALREADY_TAKEN: 'Project name is already taken',
    PROJECT_END: 'Project End',
    PROJECT_START: 'Project Start',
    CUSTOMER_FIRST_NAME: 'Customer First Name',
    CUSTOMER_END_NAME: 'Customer Last Name',
    DELETE: 'Delete',
    CANNOT_DELETE_BILLED_OR_APPROVED_TIMESHEET: 'Can not delete billed or approved timesheet',
    WE_CANNOT_BILL_FOR_THE_UNAPPROVED_TIMESHEET: 'We can not bill for the unapproved timesheet',
    NO: 'No',
    YES: 'Yes',
    LOCKED: 'Locked',
    INFO: 'INFO',
    THIS_VALUE_WILL_BE_USED_FOR_THE_CALCULATION_OF_THE_PROJECT_BUDGET_IN_DAYS:
      'This value will be used for the calculation of the project budget in days',
    WORKING_DAYS_ESTIMATION: 'Working Days Estimation',
    RESET_TO_CALCULATION: 'Reset to Calculation',
    CALCULATE_BUDGET: 'Calculate Budget',
    ACTIVATE_USER: 'Activate User',
    DEACTIVATE_USER: 'Deactivate User',
    DELETE_USER: 'Delete User',
    RE_SEND_ACTIVATION_EMAIL: 'Re-send Activation E-mail',
    USER_DELETED_SUCCESSFULY: 'User Deleted Successfuly',
    CHANGE_BUDGET: 'Change Budget',
    BUDGET_OVERVIEW: 'Budget Overview',
    COLLEAGUES: 'COLLEAGUES',
    SUM_BUDGET_DISTRIBUTION_OF: 'SUM budget distribution of',
    SUM_OF_ASSIGNED_DAYS_IS_HIGHER_THEN_TOTAL_WORKING_DAYS: 'SUM of assigned day is higher then total working days',
    REVOKE_STATUS: 'Revoke Status',
    REVOKE_STATUS_MODAL: 'Do you want to revoke your status?',
    ENTRY_IS_REQUIRED: 'Entry is required.',
    ENTRY_ALREADY_EXIST: 'Entry already exist.',
    ATTENTION_YOU_ARE_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'Attention: You are booking outside of the project period. You can proceed, but your Project Manager will be informed.',
    TIME: 'TIME',
    ENDTIME: 'END',
    STARTTIME: 'START',
    HOUR: 'HOUR',
    BREAK: 'BREAK',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'Attention: You are overbooking your project budget. You can proceed, but your Project Manager will be informed.',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_AND_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'Attention: You are overbooking your project budget and booking outside of the project period. You can proceed, but your Project Manager will be informed.',
    USER_HAS_BEEN_SUCCESSFULLY_UPDATED: 'User has been successfuly updated',
    INVALID_EMAIL_FORMAT: 'Invalid e-mail format',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_TASK: 'Are you sure you want to delete this task?',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_KNOWLEDGE: 'Are you sure you want to delete this knowledge?',
    ALL_USERS: 'All Users',
    ALL_CUSTOMERS: 'All Customers',
    ADD_NEW: 'Add New',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS_EIGHT_HOURS:
      'The calculation is based on 1 working day equals 8 hours',
    ERROR_UNARCHIVING_KNOWLEDGE: 'Error unarchiving knowledge',
    ERROR_DELETING_KNOWLEDGE: 'Error deleting knowledge',
    ERROR_UNARCHIVING_TASK: 'Error unarchiving task',
    ERROR_DELETING_TASK: 'Error deleting task',
    COMPLETED: 'Completed',
    ACTIVE: 'Active',
    DRAFT_LOWER_CASE: 'Draft',
    YOUR_LAST_LOGIN: 'Your Last Login',
    YOUR_LAST_TIMEBOOKING: 'Your Last Timebooking',
    YOUR_ASSIGNED_PROJECTS: 'Your Assigned Projects',
    MANAGING: 'Managing',
    MEMBER_OF: 'Member of',
    WORKING_HOURS_ESTIMATION: 'Working Hours Estimation',
    USERS_ACTIVE_INACTIVE_SUCCESSFULLY: 'User has been successfully activated/inactivated',
    PAUSED_LOWER_CASE: 'Paused',
    ACTIVE_USERS: 'Active Users',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_TASK:
      'Are you sure you want to archive this task? It will not be available for new time bookings',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_KNOWLEDGE:
      'Are you sure you want to archive this knowledge? It will not be available for new projects and portfolios',
    IN_USE: 'in use',
    NOT_IN_USE: 'not in use',
    AFRICAN: 'African',
    ALBANIAN: 'Albanian',
    ARABIC: 'Arabic',
    BELARUSIAN: 'Belarusian',
    BOSNIAN: 'Bosnian',
    BULGARIAN: 'Bulgarian',
    CHINESE: 'Chinese',
    CROATIAN: 'Croatian',
    CZECH: 'Czech',
    DANISH: 'Danish',
    DUTCH: 'Dutch',
    EGYPTIAN: 'Egyptian',
    ESTONIAN: 'Estonian',
    FILIPINO: 'Filipino',
    FRENCH: 'French',
    GREEK: 'Greek',
    HUNGARIAN: 'Hungarian',
    ICELANDIC: 'Icelandic',
    INDIAN: 'Indian',
    IRISH: 'Irish',
    ITALIAN: 'Italian',
    JAPANESE: 'Japanese',
    KOREAN: 'Korean',
    KURDISH: 'Kurdish',
    LATVIAN: 'Latvian',
    LITHUANIAN: 'Lithuanian',
    LUXEMBOURGISH: 'Luxembourgish',
    MACEDONIAN: 'Madedonian',
    NORWEGIAN: 'Norwegian',
    PERSIAN: 'Persian',
    POLISH: 'Polish',
    PORTUGUESE: 'Portuguese',
    ROMANIAN: 'Romanian',
    RUSSIAN: 'Russian',
    SCOTTISH: 'Scottish',
    SLOVENIAN: 'Slovenian',
    SLOVAK: 'Slovak',
    SPANISH: 'Spanisch',
    SWEDISH: 'Swedish',
    SYRIAN: 'Syrian',
    THAI: 'Thai',
    TURKISH: 'Turkish',
    UKRAINIAN: 'Ukrainian',
    VIETNAMESE: 'Vietnames',
    UPDATE_IMAGE_SIZE_ERROR: 'The file is too large.  Allowed maximum size is 5 MB.',
    LOCK_USERS: 'Lock / Unlock',
    ADD_ENTRIES: 'Add Entries',
    SAVE_DRAFT: 'Save Draft',
    LAST_LOGIN: 'LAST LOGIN',
    MAXIMUM_CHARACTERS_IS_50: 'Please use a maximum of 50 characters',
    CREATED_ON: 'CREATED ON',
    BLOCKED: 'Locked',
    COMPANY_DATA: 'Company Data',
    FULL_COMPANY_NAME: 'Full Company Name',
    STREET_AND_NUMBER: 'Street and Number',
    LICENSE_AND_SUBSCRIPTION: 'License and Subscription',
    CHANGE_SUBSCRIPTION: 'Change Subscription',
    WORKING_TIME: 'Working Time',
    WORKING_HOURS_DAY: 'Working Hours / Day',
    SETTINGS: 'Settings',
    VAT_Number: 'VAT Number',
    ATTENTION: 'ATTENTION:',
    BUDGET_IS_CALCULATED_IN_WORKING_DAYS: 'budget is calculated in working days.',
    FOR_EXAMPLE: 'For example: 8h = 1 working day',
    WORKING_DAY: 'working day',
    THIS_CHANGE_HAS_A_DIRECT_EFFECT_ON_THE_DISPLAY_OF_THE_BUDGET_IN_DAYS:
      'This change has a direct effect on the display of the "Budget in days" for all users.',
    FORR_ALL_USERS: 'for all users.',
    NEXT: 'Next',
    OVERVIEW_OF_LANGUAGE_SKIL: 'Overiew of language skill',
    NATIV_KNOWLEDGE: 'Native language level',
    NEGOTIABLE_KNOWLEDGE: 'Negotiable',
    FLUENT_KNOWLEDGE: 'Fluent in speech and writing',
    GOOD_KNOWLEDGE: 'Good knowledge',
    BASIC_KNOWLEDGE: 'Basic knowledge',
    DELETE_PROFILE_IMAGE: 'Delete Profile Image',
    SUM: 'SUM',
    VALUE_MUST_BE_BETWEEN_1_AND_12: 'Value must be between 1 and 12',
    PROJECT_FILTER: 'Project Filter',
    HERE_YOU_CAN_CHANGE_THE_BASIS_ON_WHICH_THE_PROJECT:
      'Here you can change the basis on which the project budget is calculated in working days.',
    THE_NEW_VALUE_FOR_A_USER_BUDGET_CANNOT_BE_ADJUSTED_TO_BE_LESS_THAN_THE_BUDGET_ALREADY_USED:
      'The new value for a user budget can not be adjusted to be less than the budget already used',
    MINIMUM_CHARACTERS_IS_50: 'Please use a minimum 2 characters',
    HOURS: 'hours',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS: 'The calculation is based on 1 working day equals:',
    COMPANY_UPDATED_SUCCESSFULLY: 'Company updated successfully!',
    UPDATE_ENTRY_INFORMATION_FOR: 'Update entry information for',
    CUSTOMER_UPDATED_SUCCESSFULLY: 'Customer updated successfully',
    ADMIN: 'Admin',
    BACKOFFICE: 'Backoffice',
    AGRICULTURAL_ECONOMICS: 'Agricultural Economics',
    SERVICES: 'Services',
    INSURANCE: 'Insurance',
    CONSTRUCTION_INDUSTRIES: 'Construction Industries',
    REAL_ESTATE: 'Real Estate',
    METAL_AND_ELECTRICAL_INDUSTRY: 'Metal and Electrical Industry',
    IT_SERVICES: 'IT Services',
    SPORTS_AND_FITNESS: 'Sports and Fitness',
    ADVERTISING_AND_MARKETING: 'Advertising and Marketing',
    CRAFT: 'Craft',
    TRANSPORTATION_AND_LOGISTICS: 'Transportation and Logistics',
    AUTOMOTIVE_INDUSTRY: 'Automotive Industry',
    CHEMISTRY: 'Chemistry',
    PUBLIC_ADMINISTRATION: 'Public Administration',
    RETAIL_AND_TRADE: 'Retail and Trade',
    MEDIA: 'Media',
    HEALTHCARE: 'Healthcare',
    FOOD_INDUSTRY: 'Food Industry',
    PHARMACEUTICALS: 'Pharmaceuticals',
    BANKING_AND_FINANCIAL_SERVICES: 'Banking and Financial Services',
    ENERGY_AND_ENVIRONMENT: 'Energy and Environment',
    NON_PROFIT_ORGANIZATIONS: 'Non-Profit Organizations',
    TELECOMMUNICATIONS: 'Telecommunications',
    TEXTILES_AND_APPAREL_INDUSTRY: 'Textiles and Apparel Industry',
    OTHER: 'Other',
    E_COMMERCE: 'E-Commerce',
    TOURISM_AND_GASTRONOMY: 'Tourism and Gastronomy',
    PLANT_CONSTRUCTION_AND_MACHINERY_MANUFACTURING: 'Plant Construction and Machinery Manufacturing',
    AVIATION_AND_ASTRONAUTIC: 'Aviation and Astronautic',
    DAYS_BOOKED: 'days booked',
    ADD_NEW_CUSTOMER: 'Add new customer',
    SYSTEM_ERROR: 'System error.',
    PAGE_NOT_FOUND: 'Page not found.',
    RETURN_TO: 'Return to',
    HOME: 'home',
    LOADING_PROJECTS: 'Loading projects',
    FILE: 'File',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_CUSTOMER: 'Are you sure you want to delete this cusomer?',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_CUSTOMER: 'Are you sure you want to archive this customer?',
    SHOW_ARCHIVED: 'Show Archived',
    ASSIGNED_PROJECTS: 'Assigned Projects',
    ALL_ENTRIES: 'All Entries',
    STANDARD_LANGUAGE: 'Standard Language',
    DEFAULT_SETTINGS: 'Default Settings',
    COMPANY_REGISTRATION_CONFIRMATION:
      'Thank you, your Account was successfully created. Please check your email mailbox for the next steps.',
    DASHBOARD_PROJECTS: 'Projects',
    DASHBOARD_PROJECT: 'Project',
    DASHBOARD_CUSTOMERS: 'Customers',
    DASHBOARD_CUSTOMER: 'Customer',
    DASHBOARD_USER: 'Active User',
    DASHBOARD_USERS: 'Active Users',
    ACTIVE_PROJECTS: 'Active Projects',
    INACTIVE_PROJECTS: 'Inactive Projects',
    RUNNING_AND_PAUSED_STATUS: 'Running and Paused Status',
    DRAFT_AND_FINISHED_STATUS: 'Draft and Finished Status',
    ALL_EXISTING_PROJECTS: 'All Existing Projects',
    EMAIL_TAKEN: 'Email is already taken!',
    COMPANY_NAME_TAKEN: 'Company name is already taken!',
    PASSWORD_COMPLEXITY_ERROR_TEXT: 'Does not meet password complexity',
    APPROVE_ALL_TIMESHEET: 'Approve all Timesheets ',
    EXPORT_ALL_TIMESHEETS_EXCEL: 'Export all Timesheets ( Excel )',
    EXPORT_ALL_TIMESHEETS_CSV: 'Export all Timesheets ( Csv )',
    EXPORT_ALL_TIMESHEETS_PDF: 'Export all Timesheets ( Pdf )',
    PENDING: 'Pending',
    BILLED: 'Billed',
    ASK_FOR_APPROAVAL_TEXT: 'Only timesheets with approval status pending will be approved!',
    TEAM_MEMBERS: 'Team Members',
    ARCHIVED_CUSTOMERS: 'Archived Customers',
    ACTIVE_CUSTOMERS: 'Active Customers',
    CREATE_YOUR_COMPANY_ACCOUNT: 'CREATE YOUR COMPANY ACCOUNT',
    DATA_EXPLORER: 'Data Explorer',
    REPORTS: 'Reports',
    BACK_TO_PROJECTS: 'back to projects',
    USED_BUDGET: 'Used Budget',
    BOOK: 'book',
    BACK_TO_MY_PROJECTS: 'back to my projects',
    USER_EMAIL_VALIDATION: 'Please use a valid email',
    PLEASE_FILL_ALL: 'Please fill in all fields to start with 9to5.cloud',
    TERMS_AND_PRIVACY_POLICY:
      '<span class="marked">*</span> By submitting the form, I confirm that I have read the <a href="https://www.9to5.cloud/datenschutz" target="_blank">data privacy policy</a> and that I agree to the processing of my personal data by 9to5.cloud for the purposes stated. If I consent, I can revoke my consent at any time. Furthermore, by submitting the form, I agree to the <a href="https://www.9to5.cloud/agb" target="_blank">general terms and conditions</a>.',
    QUICK_START_TITLE: 'QUICK START GUIDE',
    QUICK_START_GUIDE: "Get started and learn more about 9to5's features!",
    MOBILE_CHOOSE_PROJECT: 'Choose project ',
    SELECT_TIME: 'Select Time',
    MOBILE_SELECT_TASK: 'Select Task',
    MOBILE_CHOOSE_FILE: 'Choose a file',
    MOBILE_GALLERY: 'Gallery',
    MOBILE_CAMERA: 'Camera',
    MOBILE_DOCUMENT: 'File',
    MOBILE_PROJECTS_MANAGED_BY_ME: 'Projects managed by me:',
    MOBILE_HOME_LAST_ENTRY: 'Last entry:',
    MOBILE_PROJECTS_NOBODY_ASSIGNED: 'NOBODY ASSIGNED',
    MOBILE_PROJECTS_SELECT_ALL: 'Select all',
    MOBILE_PROJECTS_ENTRY_FOR: 'ENTRY FOR',
    MOBILE_PROJECTS_OF: 'of',
    MOBILE_BOOK_TIME: 'BOOK TIME',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
  },
}
