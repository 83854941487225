<div class="image-input image-input-circle" data-kt-image-input="true" *ngIf="form">
  <img
    id="profile-image"
    class="border border-gray-500 border-active active"
    [src]="imageSrc || 'assets/media/logos/ICON_USER.svg'"
  />
  <label
    class="btn btn-icon btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow img"
    data-kt-image-input-action="change"
    data-bs-toggle="tooltip"
    data-bs-dismiss="click"
    title="{{ 'CHANGE_PROFILE_IMAGE' | translate }}"
  >
    <i class="bi bi-pencil-fill fs-7"></i>
    <input type="file" accept="image/*" (change)="readURL($event)" />
  </label>
  <label
    class="btn btn-icon btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow img"
    data-kt-image-input-action="remove"
    data-bs-toggle="tooltip"
    data-bs-dismiss="click"
    (click)="deleteImage()"
    title="{{ 'DELETE_PROFILE_IMAGE' | translate }}"
  >
    <i class="bi bi-trash fs-7"></i>
  </label>
  <span
    class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-20px h-20px bg-body shadow"
    data-kt-image-input-action="cancel"
    data-bs-toggle="tooltip"
    data-bs-dismiss="click"
    title="Cancel avatar"
  >
    <i class="bi fs-2"></i>
  </span>
</div>
<app-error-modal #errorModal [modalConfig]="modalConfig"></app-error-modal>
