import { Component, OnInit, ViewChild } from '@angular/core'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { SharedService } from 'app/pages/projects/shared.service'
import { environment } from 'environments/environment'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { BehaviorSubject } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from 'app/modules/auth'
import { UserService } from 'app/pages/core/services/user.service'
import { ActivatedRoute, Router } from '@angular/router'
import { StepService } from 'app/pages/core/services/step.service'

@Component({
  selector: 'app-project-users-budget',
  templateUrl: './project-users-budget.component.html',
  styleUrls: ['./project-users-budget.component.scss'],
})
export class ProjectUsersBudgetComponent implements OnInit {
  modalBodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')

  @ViewChild('modalSuccess') private modalComponentSucess: ModalComponent
  @ViewChild('modalError') private modalComponentError: ModalComponent

  isManager: boolean
  isBackoffice: boolean
  lowerValue: boolean = false
  tableRowHight: number = 77.3
  headerRowHight: number = 50
  paginationRowHight: number = 73

  project: Project
  originalProjectMembers: ProjectMember[]
  sumOfAlocatedDays: any
  greatheBudgetThenTotal: boolean = false
  inputError: boolean = false
  tableData: any[] = []
  searchTerm: string = ''
  allocationChanged: boolean = false

  paginationObject: any = {
    pageSize: 5,
    currentPage: 0,
    totalElements: 0,
  }

  sortObject: any = {
    column: 'user.firstName',
    order: 'ascend',
  }

  tableHeadings = {
    image: '',
    colleagues: useStringAsTranslationObject('COLLEAGUES'),
    status_budget: useStringAsTranslationObject('STATUS_BUDGET'),
    planned: useStringAsTranslationObject('PLANNED'),
  }

  constructor(
    private sharedService: SharedService,
    public projectService: ProjectService,
    private translate: TranslateService,
    public authService: AuthService,
    private userService: UserService,
    public route: ActivatedRoute,
    private _router: Router,
    private stepService: StepService
  ) {}

  modalConfig: ModalConfig = {
    modalBodyTitle: this.modalBodyTitle,
  }

  ngOnInit(): void {
    this.ajustRowHight()
    this.translate.onLangChange.subscribe(() => {
      this.ajustRowHight()
    })
    this.isBackoffice = this.authService.isProjectBackoffice
    this.sumOfAlocatedDays = 0
    this.projectService.getOneProject(this.sharedService.getProjectIdParam()).subscribe((res: ApiResponse<Project>) => {
      this.project = res.response
      this.projectService.isProjectManager(this.project.id).subscribe(res => {
        this.isManager = res.response
      })
      this.getProjectMembers()
      this.projectService.getSumOfAssignedDays(this.project.id).subscribe((res: ApiResponse<Project[]>) => {
        this.sumOfAlocatedDays = res.response
      })
    })
  }

  ajustRowHight() {
    if (this.translate.currentLang === 'de') {
      this.tableRowHight = 80
      this.headerRowHight = 80
      this.paginationRowHight = 80
    } else {
      this.tableRowHight = 77.3
      this.headerRowHight = 50
      this.paginationRowHight = 73
    }
  }

  getProjectMembers() {
    this.userService
      .getUsersOnProject(
        this.project.id,
        this.paginationObject.currentPage,
        this.paginationObject.pageSize,
        this.mapSortBy(this.sortObject.column),
        this.mapSortDirection(this.sortObject.order),
        this.searchTerm
      )
      .subscribe(
        (response: ApiResponse<ProjectMember[]>) => {
          this.allocationChanged = false
          this.paginationObject.totalElements = response.totalElements
          this.tableData = response.content.map(element => ({
            image: {
              type: element.user.imageUrl ? TABLE_TYPE.IMAGE : TABLE_TYPE.SVG,
              url: element.user.imageUrl
                ? `${environment.imgUrl}${element.user.imageUrl}`
                : './assets/media/logos/ICON_USER.svg',
            },
            colleagues: {
              type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
              string: `${element.user.firstName} ${element.user.lastName}`,
            },
            status_budget: {
              type: TABLE_TYPE.BAR_GRAPH,
              percentage: element.usedAllocationInPercentage! || 0,
              daysLeft: element.allocation ? element.allocation - element.usedAllocation! : 0,
              total: element?.allocation || 0,
            },
            planned: {
              type: TABLE_TYPE.INPUT,
              string: element?.allocation || 0,
              readonly: this.isBackoffice && !this.isManager,
              step: 0.01,
            },
            userId: element.user.id,
            userAllocation: element.allocation ? element.allocation : 0,
            userConsumedBudget: element.usedAllocation ? element.usedAllocation : 0,
          }))
        },
        error => {}
      )
  }

  updateAndMapPagination(event: any) {
    this.mapPagination(event)
  }

  mapPagination(event: any) {
    this.paginationObject = event
    this.getProjectMembers()
  }

  mapSorting(event: any) {
    this.sortObject = event
    this.getProjectMembers()
  }

  onInputValueChanged(eventData: { data: any; inputValue: any }) {
    this.handleAllocationChange(eventData.data.userId, eventData.inputValue)
  }

  handleAllocationChange(userId: any, newValue: any) {
    const originalPerson = this.project.projectMembers.find(element => element.user.id === userId)
    const originalValue = originalPerson!.usedAllocationInHours
      ? originalPerson?.usedAllocationInHours! / (originalPerson!.allocationInHours! / originalPerson?.allocation!)
      : 0
    if (isNaN(newValue)) {
      newValue = 0
    }
    if (originalValue > 0 && newValue < originalValue!) {
      this.inputError = true
      this.modalBodyTitle.next(
        this.translate.instant(
          'THE_NEW_VALUE_FOR_A_USER_BUDGET_CANNOT_BE_ADJUSTED_TO_BE_LESS_THAN_THE_BUDGET_ALREADY_USED'
        )
      )
      this.openModalError()
    } else {
      const allocatedDaysDifference =
        originalPerson!.allocatinGreather! > 0 ? originalPerson!.allocatinGreather! : originalPerson!.allocation!
      this.sumOfAlocatedDays -= allocatedDaysDifference ?? 0
      this.sumOfAlocatedDays += newValue
      this.greatheBudgetThenTotal = this.sumOfAlocatedDays > this.project.budgetEstimation!
      if (!this.greatheBudgetThenTotal) {
        originalPerson!.allocatinGreather = 0
        originalPerson!.allocation = newValue
      } else {
        originalPerson!.allocation = newValue
      }
      this.inputError = false
      this.allocationChanged = true
    }
  }

  updateProject(showModal: boolean) {
    if (!this.greatheBudgetThenTotal) {
      this.projectService.updateProject(this.project).subscribe((res: ApiResponse<Project>) => {
        res?.response! ? showModal && this.openModalSuccess() : this.openModalError()
        this._router.navigate(['/projects/project-overview', this.project.id, 'team-members'])
      })
    } else {
      this.modalBodyTitle.next(this.translate.instant('SUM_OF_ASSIGNED_DAYS_IS_HIGHER_THEN_TOTAL_WORKING_DAYS'))
      this.openModalError()
    }
  }

  async openModalError() {
    return await this.modalComponentError.open()
  }

  async openModalSuccess() {
    this.modalBodyTitle.next('')
    this.ngOnInit()
    return await this.modalComponentSucess.open()
  }

  searchParam(event: any) {
    this.paginationObject.currentPage = 0
    this.searchTerm = event
    //TODO: Ask for backend help for search
    this.getProjectMembers()
  }

  back() {
    if (this.route.snapshot.routeConfig!.path?.includes('add-user')) {
      this.stepService.setStep(1)
    } else {
      this._router.navigate(['/projects/project-overview', this.project.id, 'team-members'])
    }
  }

  private mapSortBy(sortBy: string) {
    switch (sortBy) {
      case 'colleagues':
        return 'user.firstName'
      case 'planned':
        return 'allocation'
      default:
        return 'user.firstName'
    }
  }

  private mapSortDirection(order: string) {
    return order === 'descend' ? 'DESC' : order === 'ascend' ? 'ASC' : undefined
  }
}
