<div class="card">
  <div class="card-body">
    <div class="task-list">
      <div class="task-list_controls">
        <div class="task-list_controls_column">
          <button
            class="nav-button"
            [ngClass]="{
              current: currentStep$.value === 1,
              completed: currentStep$.value > 1
            }"
            (click)="setStep(1)"
          >
            <h3 class="stepper-title uppercase">{{ 'ACTIVE_PROJECTS' | translate }}</h3>
            <span class="text-muted stepper-description">{{ 'RUNNING_AND_PAUSED_STATUS' | translate }}</span>
          </button>
          <button
            class="nav-button"
            [ngClass]="{
              current: currentStep$.value === 2,
              completed: currentStep$.value > 2
            }"
            (click)="setStep(2)"
          >
            <h3 class="stepper-title uppercase">{{ 'INACTIVE_PROJECTS' | translate }}</h3>
            <span class="text-muted stepper-description">{{ 'DRAFT_AND_FINISHED_STATUS' | translate }}</span>
          </button>
          <button
            class="nav-button"
            [ngClass]="{
              current: currentStep$.value === 3,
              completed: currentStep$.value > 3
            }"
            (click)="setStep(3)"
          >
            <h3 class="stepper-title uppercase">{{ 'ALL_PROJECTS' | translate }}</h3>
            <span class="text-muted stepper-description">{{ 'ALL_EXISTING_PROJECTS' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-projects-list [statuses$]="statuses$"></app-projects-list>
</div>
