<app-button
  [title]="title"
  nz-dropdown
  fullWidth
  [nzTrigger]="'click'"
  [nzDropdownMenu]="dropdownMenu"
  suffixIcon="assets/media/icons/duotune/arrows/arr072.svg"
>
  <span nz-icon nzType="down"></span>
</app-button>
<nz-dropdown-menu #dropdownMenu="nzDropdownMenu">
  <ul nz-menu nzSelectable class="buttons-wrapper">
    <app-button
      nz-menu-item
      *ngFor="let action of actions"
      fullWidth
      disableWrapperPadding
      noRadius
      alignLeft
      [title]="action.title | translate"
      (handleClick)="action.onClick()"
      [prefixIcon]="action.icon || ''"
      [disabled]="action.disabled"
      class="action-buttons"
    >
    </app-button>
  </ul>
</nz-dropdown-menu>
