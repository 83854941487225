import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'textBreak',
  standalone: true,
})
export class TextBreakPipe implements PipeTransform {
  transform(value: string, limit?: number, lineLimit: number = 2): string {
    const splitString = value?.split('')
    if (limit && splitString.length > limit) {
      let insertLineBrake = splitString
      const substringForCheckOfSpaceOrDash = insertLineBrake
        .join('')
        .substring(Math.round(limit / 2) - 3, Math.round(limit / 2) + 3)
      if (substringForCheckOfSpaceOrDash.includes(' ')) {
        const replacedString = substringForCheckOfSpaceOrDash.replace(' ', '<br />')
        return insertLineBrake.join('').replace(substringForCheckOfSpaceOrDash, replacedString)
      } else if (substringForCheckOfSpaceOrDash.includes('-')) {
        const replacedString = substringForCheckOfSpaceOrDash.replace('-', '-<br />')
        return insertLineBrake.join('').replace(substringForCheckOfSpaceOrDash, replacedString)
      } else {
        insertLineBrake.splice(Math.round(limit / 2), 0, '<br />')
        return insertLineBrake.join('')
      }
    }
    return value
  }
}
