<span class="marginLeft"> {{ 'ADD_USERS_TO_THE_TEAM' | translate }}</span>
<p class="marginLeft">{{ 'TEAM_MEMBERS_PLAN' | translate }}</p>
<div class="modalTableMargin table" *ngIf="firstModal">
  <div class="users-list">
    <div class="d-flex justify-content-between">
      <div>
        <app-button
          [title]="'BACK' | translate | titlecase"
          style="margin-right: 15px"
          (handleClick)="back()"
        ></app-button>
      </div>
      <div class="searchWrapper">
        <app-input search [placeholder]="'SEARCH' | translate" (statusSelectChange)="searchParam($event)"></app-input>
      </div>
    </div>
    <div class="table-wrapper">
      <app-table
        class="add-users-table select-selector"
        [customHeadings]="tableHeadings"
        [dataList]="tableData"
        [selectable]="true"
        [hiddenColumns]="['id', 'userAllocation', 'userConsumedBudget', 'isChecked']"
        [pageSize]="5"
        [rowClickable]="true"
        [selectable]="true"
        (selectAll)="checkAll($event)"
        (selectionChangeOneItem)="oneSelectedUser($event)"
        [frontendPagination]="false"
        [pagination]="paginationObject"
        (paginationChange)="pageChange($event)"
      >
      </app-table>
    </div>
  </div>
  <div [ngClass]="firstModal ? 'button-wraper' : 'button-second-step'">
    <button class="btn backButton" *ngIf="firstModal" (click)="back()">
      {{ 'BACK' | translate }}
    </button>
    <button class="btn btn-primary" *ngIf="firstModal" (click)="openModalProjectUsers()">
      {{ 'NEXT' | translate }}
    </button>
  </div>
</div>
<div *ngIf="!firstModal">
  <app-project-users-budget></app-project-users-budget>
</div>
