import { DatePipe, Location } from '@angular/common'
import { HttpParams } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HoursConverterService } from 'app/pages/core/services/hours-converter.service'
import { ProjectService } from 'app/pages/core/services/project.service'
import { TimeSheetService } from 'app/pages/core/services/timesheet.service'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { environment } from 'environments/environment'
import { BehaviorSubject } from 'rxjs'
import { SharedService } from '../../shared.service'

@Component({
  selector: 'app-month-overview',
  templateUrl: './month-overview.component.html',
  styleUrls: ['./month-overview.component.scss'],
})
export class MonthOverviewComponent implements OnInit {
  tableData: any[] = []
  usersList: any[] = []
  projectId: any
  fullDate: any
  monthDate: any
  days: number = 0
  hours: number = 0
  year: any
  monthOfYear: any

  openModalApproveTimesheetsSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  selectedApproval: any
  isFromMyProjects: boolean = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private timesheetService: TimeSheetService,
    private hoursConverter: HoursConverterService,
    private datePipe: DatePipe,
    private location: Location,
    private sharedService: SharedService
  ) {}

  tableHeadings = {
    image: '',
    colleagues: useStringAsTranslationObject('COLLEAGUES'),
    days: useStringAsTranslationObject('DAYS'),
    hours: useStringAsTranslationObject('HOURS'),
    budget: useStringAsTranslationObject('STATUS_BUDGET'),
    status: useStringAsTranslationObject('STATUS'),
    actions: useStringAsTranslationObject('ACTIONS'),
  }
  ngOnInit(): void {
    this.sharedService.isFromMyProjects.subscribe((val: any) => (this.isFromMyProjects = val ?? false))
    this.route.params.subscribe(params => {
      this.projectId = this.route.snapshot.queryParamMap.get('projectId')
      this.fullDate = this.route.snapshot.queryParamMap.get('date')

      const dateArray = this.fullDate.split(' ')
      this.monthOfYear = dateArray[0]
      this.year = dateArray[1]

      this.monthDate = this.datePipe.transform(`${this.year}-${this.monthOfYear}-01`, 'yyyy-MM-dd')
      this.fetchSummary()
    })
  }

  fetchSummary() {
    this.projectService.getApprovalUsersSummary(this.monthDate, this.projectId).subscribe((res: any) => {
      if (res.content) {
        this.usersList = res.content
        this.usersList.forEach(element => {
          this.days += element.days
          this.hours += element.hours
        })
        this.tableData = this.mapStatuses()
      }
    })
  }

  mapStatuses() {
    return this.usersList.map(user => ({
      statusColor: user.approvalStatus.name === 'BILLED' ? true : false,
      id: user.projectUser.user.id,
      image: {
        type: TABLE_TYPE.IMAGE,
        url: user.projectUser?.user?.imageUrl
          ? `${environment.imgUrl}${user.projectUser.user.imageUrl}`
          : 'assets/media/logos/ICON_USER.svg',
      },
      colleagues: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: user.projectUser.user.firstName + ' ' + user.projectUser.user.lastName,
        description: user.projectUser.user.portfolio.jobTitle,
      },
      days: this.hoursConverter.convertDaysAndHours(user.days) || 0,
      hours: this.hoursConverter.convertDaysToHoursAndMinutes(user.hours) || 0,
      statusBudget: {
        type: TABLE_TYPE.BAR_GRAPH,
        percentage: user.projectUser.usedAllocationInPercentage || 0,
        daysLeft: user.projectUser.daysLeft || 0,
        total: user.projectUser.allocation || 0,
        booked: user.projectUser.usedAllocation || 0,
      },
      status: {
        type: TABLE_TYPE.STATUS_ICON,
        string: user.projectUser.status,
        icon: this.determineIconByApprovalStatus(user.approvalStatus.name),
      },
      actions: {
        type: TABLE_TYPE.STATUS_TIMESHEET,
        value: 2,
        projectId: 2,
        statusChanges: this.getUserApprovalsWithActions(user),
      },
    }))
  }

  determineIconByApprovalStatus(status: string) {
    switch (status) {
      case 'BILLED':
        return 'assets/media/icons/duotune/general/BilledTimesheet.svg'
      case 'APPROVED':
        return 'assets/media/icons/duotune/general/ApprovedTimesheet.svg'
      default:
        return 'assets/media/icons/duotune/general/DefaultTimesheet.svg'
    }
  }

  getUserApprovalsWithActions(user: any) {
    let actions = [
      {
        title: 'APPROVE_ALL_TIMESHEET',
        onClick: () => {
          this.askForTimesheetApproval(user, true)
        },
        icon: 'assets/media/icons/duotune/general/file.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_PDF',
        onClick: () => {
          this.projectService
            .exportApprovalTimesheets(this.monthDate, this.projectId, user.projectUser.user.id)
            .subscribe(({ response }: any) => {
              this.timesheetService.convertTimesheetToPDF(this.timesheetService.mapResponseForExport(response))
            })
        },
        icon: 'assets/media/icons/duotune/general/export.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_CSV',
        onClick: () => {
          this.projectService
            .exportApprovalTimesheets(this.monthDate, this.projectId, user.projectUser.user.id)
            .subscribe(({ response }: any) => {
              if (response) {
                this.timesheetService.convertTimesheetToCSV(this.timesheetService.mapResponseForExport(response))
              }
            })
        },
        icon: 'assets/media/icons/duotune/general/export.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_EXCEL',
        onClick: () => {
          this.projectService
            .exportApprovalTimesheets(this.monthDate, this.projectId, user.projectUser.user.id)
            .subscribe(({ response }: any) => {
              if (response) {
                this.timesheetService.convertTimesheetToCSV(this.timesheetService.mapResponseForExport(response))
              }
            })
        },
        icon: 'assets/media/icons/duotune/general/printer.svg',
      },
    ]
    return actions
  }

  redirectToAnotherPage(event: any) {
    this.router.navigate(
      ['projects/project-overview/' + this.projectId + '/used-budget/specific-timesheet/', event.id, this.projectId],
      { queryParams: { selectedMonth: this.monthOfYear, selectedYear: this.year, myProjects: !!this.isFromMyProjects } }
    )
  }

  askForTimesheetApproval(approval: any, open: boolean) {
    this.selectedApproval = approval
    this.openModalApproveTimesheetsSubject$.next(open)
  }

  approveTimesheets(event: any) {
    const paramMap: HttpParams = new HttpParams()
      .append('monthStart', this.monthDate)
      .append('projectId', this.projectId!)
      .append('userId', this.selectedApproval.projectUser.user.id)
    this.timesheetService.approveTimesheets(paramMap).subscribe(() => {
      this.fetchSummary()
      this.openModalApproveTimesheetsSubject$.next(false)
    })
  }

  back() {
    this.location.back()
  }
}
