import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Project } from 'app/pages/core/model/project.model'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs'
import { ProjectService } from 'app/pages/core/services/project.service'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { AuthService } from 'app/modules/auth'
import { UserService } from 'app/pages/core/services/user.service'
import { User } from 'app/pages/core/model/flexc-user.model'
import { environment } from 'environments/environment'
import { TranslateService } from '@ngx-translate/core'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { Router } from '@angular/router'
import { IButtonAction } from 'app/standalone/menu-dropdown/menu-dropdown.component.model'
import { SharedService } from '../../shared.service'

@Component({
  selector: 'app-project-members-card',
  templateUrl: './project-members-card.component.html',
  styleUrls: ['./project-members-card.component.scss'],
})
export class ProjectMembersCardComponent implements OnInit {
  @Input() project: Project

  @ViewChild('modalSuccess') private modalComponent: ModalComponent
  @ViewChild('modalError') private modalComponentError: ModalComponent

  openModalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  openModalSubjectUsers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  menuActions: IButtonAction[] = this.generateActionByMassActivity()

  selectedMembers: ProjectMember[] = []
  userId: string
  manager: boolean = false
  tableData: any[]
  isManager: boolean = false
  membersLength: boolean = false
  isFromMyProjects: boolean = false

  public tableHeadings = {
    image: '',
    firstName: useStringAsTranslationObject('FIRST_NAME'),
    lastName: useStringAsTranslationObject('LAST_NAME'),
    jobTitle: useStringAsTranslationObject('JOB_TITLE'),
    manager: useStringAsTranslationObject('MANAGER'),
    statusBudget: useStringAsTranslationObject('STATUS_BUDGET'),
    actions: useStringAsTranslationObject('ACTIONS'),
  }

  modalConfig: ModalConfig = {
    onClose: () => {
      return true
    },
    onDismiss: () => {
      return true
    },
  }

  paginationObject: any = {
    pageSize: 5,
    currentPage: 0,
    totalElements: 0,
  }

  sortObject: any = {
    column: 'firstName',
    order: 'ascend',
  }

  constructor(
    private projectService: ProjectService,
    public authService: AuthService,
    private userService: UserService,
    public translate: TranslateService,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.sharedService.isFromMyProjects.subscribe((val: any) => (this.isFromMyProjects = val ?? false))
    this.translate.onLangChange.subscribe((res: any) => {
      this.menuActions = this.generateActionByMassActivity()
    })
    this.userService.getLoggedInUser().subscribe((res: User) => {
      if (res.id) {
        this.userId = res.id
        this.getProjectMembers()
      }
    })
  }

  getProjectMembers() {
    this.userService
      .getUsersOnProject(
        this.project.id,
        0,
        this.project.projectMembers?.length,
        this.mapSortBy(this.sortObject.column),
        this.mapSortDirection(this.sortObject.order)
      )
      .subscribe((response: ApiResponse<ProjectMember[]>) => {
        if (response.content) {
          this.membersLength = true
          this.isManager = this.project.projectMembers.find(member => member.user.id === this.userId)?.manager || false
        }
        response.content.forEach(element => {
          if (element.user.imageUrl) {
            element.user.imageUrl = `${environment.imgUrl}${element.user.imageUrl}`
          }
        })
        this.paginationObject.totalElements = response.totalElements
        this.tableData = this.mapProjectMemberDataForTable(response.content)
      })
  }

  mapPagination(event: any) {
    this.paginationObject = event
    this.getProjectMembers()
  }

  mapSorting(event: any) {
    this.sortObject = event
    this.getProjectMembers()
  }

  mapProjectMemberDataForTable(projectMembers: ProjectMember[]) {
    return projectMembers.map(projectMembers => ({
      id: projectMembers.id,
      hasUsedAllocation: !!projectMembers.usedAllocation,
      image: {
        type: projectMembers.user.imageUrl ? TABLE_TYPE.IMAGE : TABLE_TYPE.SVG,
        url: projectMembers.user.imageUrl ? `${projectMembers.user.imageUrl}` : 'assets/media/logos/ICON_USER.svg',
      },
      firstName: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: projectMembers.user.firstName,
      },
      lastName: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: projectMembers.user.lastName,
      },
      jobTitle: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: projectMembers.user.portfolio?.jobTitle,
      },
      manager: {
        type: TABLE_TYPE.CHECK,
        checked: projectMembers.manager,
        class: `form-check-input widget-9-check ${
          this.authService.currentUserRole !== 'PROJECT_ADMIN' ? 'readonly-checkbox' : ''
        }`,
      },
      user: projectMembers.user.id,
      statusBudget: {
        type: TABLE_TYPE.BAR_GRAPH,
        percentage:
          projectMembers.usedAllocationInPercentage !== undefined && projectMembers.usedAllocationInPercentage !== null
            ? projectMembers.usedAllocationInPercentage
            : projectMembers.allocation !== undefined && projectMembers.allocation !== null
            ? 0
            : 0,
        daysLeft:
          projectMembers.daysLeft !== undefined && projectMembers.daysLeft !== null
            ? projectMembers.daysLeft
            : projectMembers.allocation || '',
        total: projectMembers.allocation || 0,
        booked: projectMembers.usedAllocation || 0,
      },
      actions: {
        type: TABLE_TYPE.CLICKABLE_IMAGE_LIST,
        items: [
          {
            url: projectMembers?.blocked
              ? './assets/media/logos/padlock_green.svg'
              : './assets/media/logos/padlock_gray.svg',
            onClick: () => this.toggleStatusChange(projectMembers.user.id),
          },
          !projectMembers.usedAllocation
            ? {
                url: './assets/media/icons/duotune/general/gen027.svg',
                onClick: () => this.deleteUserFromProject(projectMembers.user.id!),
              }
            : null,
        ].filter(Boolean),
      },
    }))
  }

  generateActionByMassActivity() {
    return [
      {
        title: 'ADD_ENTRIES',
        onClick: () => this.changeAddEntriesSelection(),
        icon: './assets/media/icons/duotune/general/clock.svg',
        disabled: this.selectedMembers?.length < 2,
      },
      {
        title: 'LOCK_USERS',
        onClick: () => this.changeBlockUserSelection(),
        icon: './assets/media/icons/duotune/general/lock.svg',
        disabled: this.selectedMembers?.length < 2,
      },
    ]
  }

  checkMassActivity() {
    this.menuActions = this.generateActionByMassActivity()
  }

  checkIfManager(userId: string): Observable<boolean> {
    return this.projectService.isProjectManager(userId).pipe(
      map((res: ApiResponse<boolean>) => res.response),
      catchError(() => of(false))
    )
  }

  toggleStatusChange(memberId: any) {
    this.updateProjects({
      ...this.project,
      projectMembers: this.project.projectMembers.map(projectMember =>
        projectMember.user.id === memberId
          ? {
              ...projectMember,
              blocked: !projectMember.blocked,
            }
          : projectMember
      ),
    })
  }

  updateProjects(mappedProjects: Project, manager?: boolean) {
    this.projectService.updateProject(mappedProjects).subscribe((res: ApiResponse<Project>) => {
      if (!res.response) {
        this.openModalError()
      } else if (this.authService.currentUserRole === 'PROJECT_MEMBER' && !!manager) {
        this.checkIfManager(this.project.id).subscribe(isManager => {
          isManager && this.openModal()
        })
      } else {
        this.openModal()
      }
      this.project = res.response
      this.getProjectMembers()
    })
  }

  deleteUserFromProject(userId: string) {
    this.projectService.deleteUserFromProject(userId, this.project.id!).subscribe({
      next: (res: ApiResponse<Project>) => {
        if (res.response.id) {
          this.project.projectMembers = this.project.projectMembers.filter(element => element.user.id !== userId)
          this.openModal()
          this.getProjectMembers()
        }
      },
      error: () => {
        this.openModalError()
      },
    })
  }

  async openModal() {
    return await this.modalComponent.open()
  }

  async openModalError() {
    return await this.modalComponentError.open()
  }

  modalSave(event: any) {
    event ? this.openModal() : this.openModalError()
  }

  changeAddEntriesSelection() {
    this.openModalSubject.next(true)
  }

  changeBlockUserSelection() {
    this.updateProjects({
      ...this.project,
      projectMembers: this.project.projectMembers.map(projectMember =>
        this.selectedMembers?.find(selected => selected.id === projectMember.id)
          ? { ...projectMember, blocked: !projectMember.blocked }
          : projectMember
      ),
    })
  }

  openModalProjectUsers() {
    this.openModalSubjectUsers.next(true)
  }

  saveFinished(event: any) {
    event ? this.openModal() : this.openModalError()
  }

  projectDate(project: Project) {
    if (this.project) {
      this.project = project
    }
  }

  redirectToSpecificTimesheetPage(event: any) {
    let member: ProjectMember = this.project.projectMembers.find(projectMember => projectMember.id === event.id)!
    this.router.navigate(['projects/project-overview/' + this.project.id + '/team-members/overview-month-user'], {
      queryParams: {
        projectId: this.project.id,
        userId: member?.user?.id || event.user,
        myProjects: !!this.isFromMyProjects,
      },
    })
  }

  onMangerChanged(event: any) {
    const member: ProjectMember = this.project.projectMembers.find(projectMember => projectMember.id === event.data.id)!
    this.updateProjects(
      {
        ...this.project,
        projectMembers: this.project.projectMembers.map(projectMember =>
          projectMember.user.id === member.user.id
            ? { ...projectMember, manager: !projectMember.manager }
            : projectMember
        ),
      },
      member.manager
    )
  }

  selectionChange(selectedMembers: any[]) {
    const selectedIds = selectedMembers.map(member => member.id)
    this.selectedMembers = this.project.projectMembers.filter(member => selectedIds.includes(member.id))
    this.checkMassActivity()
  }

  private mapSortBy(sortBy: string) {
    switch (sortBy) {
      case 'firstName':
        return 'user.firstName'
      case 'lastName':
        return 'user.lastName'
      case 'jobTitle':
        return 'user.portfolio.jobTitle'
      case 'planned':
        return 'allocation'
      case 'blocked':
        return 'blocked'
      case 'manager':
        return 'manager'
      case 'actions':
        return 'user.firstName'
      case 'statusBudget':
        return 'usedAllocationInPercentage'
      default:
        return 'user.firstName'
    }
  }

  private mapSortDirection(order: string) {
    return order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : undefined
  }
}
