import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ProjectsRoutingModule } from './projects-routing.module'
import { ProjectsListComponent } from './projects-list/projects-list.component'
import { CreateProjectComponent } from './create-project/create-project.component'
import { ProjectInfoComponent } from './create-project/project-info/project-info.component'
import { ProjectTimeComponent } from './create-project/project-time/project-time.component'
import { ProjectWorkersComponent } from './create-project/project-workers/project-workers.component'
import { AngularDualListBoxModule } from 'angular-dual-listbox'
import { ProjectOverviewComponent } from './project-overview/project-overview.component'
import { ProjectMembersCardComponent } from './project-overview/project-members-card/project-members-card.component'
import { ProjectApprovalsCardComponent } from './project-overview/project-approvals-card/project-approvals-card.component'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { TagInputModule } from 'ngx-chips'
import { ProjectTeamComponent } from './create-project/project-team/project-team.component'
import { TranslateModule } from '@ngx-translate/core'
import { ProjectDetailsCardComponent } from 'app/standalone/project-details-card/project-details-card.component'
import { ProjectsCardsComponent } from './projects-cards/projects-cards.component'
import { ProjectCardComponent } from './project-card/project-card.component'
import { TimesheetInputComponent } from 'app/standalone/timesheet-input/timesheet-input.component'
import { ModalsModule } from 'app/_metronic/partials/layout/modals/modals.module'
import { NgxPaginationModule } from 'ngx-pagination'
import { StatusPipe } from './projects-list/status.pipe'
import { SearchPipe } from './projects-list/search.pipe'
import { NoFetchDateComponent } from '../../standalone/no-fetch-date/no-fetch-date.component'
import { ProjectModifyComponent } from './project-overview/project-modify/project-modify.component'
import { ProjectKnowledgeComponent } from './project-overview/project-modify/project-knowledge/project-knowledge.component'
import { ProjectTimeModifyComponent } from './project-overview/project-modify/project-time-modify/project-time-modify.component'
import { AditionalTimePeriodInputComponent } from './project-overview/project-modify/aditional-time-period-input/aditional-time-period-input.component'
import { ProjectMembersCardsComponent } from './project-overview/project-members-cards/project-members-cards.component'
import { ProjectUsersComponent } from './project-overview/project-modify/project-users/project-users.component'
import { MenuDropdownComponent } from 'app/standalone/menu-dropdown/menu-dropdown.component'
import { TableComponent } from 'app/standalone/table/table.component'
import { SwitchComponent } from 'app/standalone/switch/switch.component'
import { InputComponent } from 'app/standalone/input/input.component'
import { SelectComponent } from 'app/standalone/select/select.component'
import { ButtonComponent } from 'app/standalone/button/button.component'
import { ProjectUsersBudgetComponent } from './project-overview/project-users-budget/project-users-budget.component'
import { MultiSelectComponent } from 'app/standalone/multi-select/multi-select.component'
import { AddUsersComponent } from './project-overview/add-users/add-users.component'
import { ProgressBarComponent } from 'app/standalone/progress-bar/progress-bar.component'
import { SelectWithNewComponent } from 'app/standalone/select-with-new/select-with-new.component'
import { AllProjectsOverviewComponent } from './all-projects-overview/all-projects-overview.component'
import { MonthOverviewComponent } from './project-overview/month-overview/month-overview.component'
import { UserMonthOverviewComponent } from './project-overview/user-month-overview/user-month-overview.component'
import { CreateNewProjectFlowComponent } from './create-new-project-flow/create-new-project-flow.component'
import { CreateProjectStepOneComponent } from './create-new-project-flow/create-project-step-one/create-project-step-one.component'
import { CreateProjectStepTwoComponent } from './create-new-project-flow/create-project-step-two/create-project-step-two.component'
import { CreateProjectStepThreeComponent } from './create-new-project-flow/create-project-step-three/create-project-step-three.component'
import { CreateProjectStepFourComponent } from './create-new-project-flow/create-project-step-four/create-project-step-four.component'
import { TruncatePipe } from 'app/standalone/table/truncate.pipe'
@NgModule({
  declarations: [
    ProjectsListComponent,
    CreateProjectComponent,
    ProjectInfoComponent,
    ProjectApprovalsCardComponent,
    ProjectMembersCardComponent,
    ProjectOverviewComponent,
    ProjectTimeComponent,
    ProjectWorkersComponent,
    ProjectTeamComponent,
    ProjectsCardsComponent,
    ProjectCardComponent,
    StatusPipe,
    SearchPipe,
    ProjectModifyComponent,
    ProjectKnowledgeComponent,
    ProjectTimeModifyComponent,
    AditionalTimePeriodInputComponent,
    ProjectMembersCardsComponent,
    ProjectUsersComponent,
    ProjectUsersBudgetComponent,
    AddUsersComponent,
    AllProjectsOverviewComponent,
    MonthOverviewComponent,
    UserMonthOverviewComponent,
    CreateNewProjectFlowComponent,
    CreateProjectStepOneComponent,
    CreateProjectStepTwoComponent,
    CreateProjectStepThreeComponent,
    CreateProjectStepFourComponent,
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ProjectsRoutingModule,
    NgbModule,
    AngularDualListBoxModule,
    TagInputModule,
    InlineSVGModule,
    TranslateModule,
    ProjectDetailsCardComponent,
    ModalsModule,
    TimesheetInputComponent,
    NgxPaginationModule,
    NoFetchDateComponent,
    MenuDropdownComponent,
    TableComponent,
    SwitchComponent,
    InputComponent,
    SelectComponent,
    SelectWithNewComponent,
    ButtonComponent,
    MultiSelectComponent,
    ProgressBarComponent,
    TruncatePipe,
  ],
  providers: [StatusPipe, TruncatePipe],
})
export class ProjectsModule {}
