import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CreateUserComponent } from './create-user/create-user.component'
import { UsersListComponent } from './users-list/users-list.component'
import { UpdateEditUserComponent } from './update-edit-user/update-edit-user.component'
import { SpecificUserTimesheetViewComponent } from './specific-user-timesheet-view/specific-user-timesheet-view.component'

const routes: Routes = [
  {
    path: 'create-user',
    component: CreateUserComponent,
  },
  {
    path: 'users-list',
    component: UsersListComponent,
  },
  {
    path: 'update-edit-user/:id',
    component: UpdateEditUserComponent,
  },
  {
    path: 'update-edit-user-profile/:id',
    component: UpdateEditUserComponent,
  },
  {
    path: 'update-edit-user/:id/view-timesheet-data',
    component: SpecificUserTimesheetViewComponent,
  },
  { path: 'users', redirectTo: 'users/users-list', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
