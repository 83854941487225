import { CommonModule } from '@angular/common'
import { AfterContentInit, Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { AuthService } from 'app/modules/auth'
import { UserDashboardDto } from 'app/pages/core/dto/UserDashboardDto'
import { UserService } from 'app/pages/core/services/user.service'
import { environment } from 'environments/environment'
import { BehaviorSubject } from 'rxjs'
import { ButtonComponent } from '../button/button.component'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonComponent, TranslateModule, RouterModule],
})
export class DashboardWidgetComponent implements OnInit, AfterContentInit {
  @Input() cssClass: string = ''
  @Input() icon: boolean = false
  @Input() stats: number | string | undefined
  @Input() statsText: string | undefined
  @Input() description: string
  @Input() labelColor: string = 'dark'
  @Input() textColor: string = 'gray-300'
  @Input() isFirstCard: boolean
  @Input() showDescriptionStringSecond: boolean = false
  @Input() chartSize: number = 70
  @Input() chartLine: number = 11
  @Input() chartRotate?: number = 145
  @Input() label1: string
  @Input() label2: string
  @Input() label3: string
  @Input() showDescriptionString: boolean = false
  @Input() showDescriptionDate: boolean = false
  @Input() isFromUser: boolean = false
  @Input() showUsersGroup: boolean = false
  @Input() showButtons: boolean = false
  @Input() showUsersButton: boolean = false
  @Input() showCustomersButton: boolean = false
  @Input() dashboardInfo: BehaviorSubject<UserDashboardDto> = new BehaviorSubject({} as UserDashboardDto)
  stats1: number
  stats2: number
  stats3: number

  projectNumber: number

  allUsersButtonLabel: string = this.translate.instant('ALL_USERS')
  allCustomersButtonLabel: string = this.translate.instant('ALL_CUSTOMERS')
  newButtonLabel: string = this.translate.instant('ADD_NEW')

  items: Array<{ name: string; initials?: string; state?: string; src?: string }>

  remainingUsersNumber: number

  constructor(
    private _router: Router,
    public userService: UserService,
    public auth: AuthService,
    public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var roleAdmin = this.auth.isProjectAdmin
    var roleNormalUser = this.auth.isNormalUser
    var roleBackoffice = this.auth.isProjectBackoffice

    if (roleAdmin && !this.router.url.includes('update-edit-user')) {
      this.dashboardInfo.subscribe((res: UserDashboardDto) => {
        this.stats1 = res?.runningProjects
        this.stats2 = res?.completedProjects
        this.stats3 = res?.draftedProjects
        this.projectNumber = (res?.runningProjects || 0) + (res?.completedProjects || 0) + (res?.draftedProjects || 0)

        if (res?.users) {
          this.items = res?.users?.slice(0, 7).map((user?: any) => ({
            ...user,
            name: `${user?.firstName} ${user?.lastName}`,
            initials: user?.firstName?.substring(0, 1),
            src: user?.imageUrl ? `${environment.imgUrl}${user?.imageUrl}` : '',
            state: 'primary',
          }))
          this.remainingUsersNumber = res.usersCounter - 8
        } else {
          this.items = []
          this.remainingUsersNumber = 0
        }
      })
    } else if (roleNormalUser || roleBackoffice) {
      this.dashboardInfo.subscribe((res: UserDashboardDto) => {
        this.stats1 = res?.memberCounter
        this.stats2 = res?.managerCounter
        this.projectNumber = res?.memberCounter + res?.managerCounter
      })
    } else {
      this.stats = ''
      this.statsText = ''
      const id = this.activatedRoute.snapshot.paramMap.get('id')
      this.userService.getUserDashboardInfoId(id!).subscribe(
        (res: ApiResponse<UserDashboardDto>) => {
          this.stats = res?.response.lastLogin
          this.statsText = res?.response.lastTimeSheetEntry
        },
        error => {}
      )
    }
  }

  ngAfterContentInit() {
    this.translate.onLangChange.subscribe((res: any) => {
      this.allUsersButtonLabel = this.translate.instant('ALL_USERS')
      this.allCustomersButtonLabel = this.translate.instant('ALL_CUSTOMERS')
      this.newButtonLabel = this.translate.instant('ADD_NEW')
    })
  }

  public navigateToAll(): void {
    if (this.showUsersButton) {
      this._router.navigate(['/users/users-list'])
    } else if (this.showCustomersButton) {
      this._router.navigate(['/customer/customer-list'])
    }
  }

  public navigateToAddNew(): void {
    if (this.showUsersButton) {
      this._router.navigate(['/users/create-user'])
    } else if (this.showCustomersButton) {
      this._router.navigate(['/customer/create-customer'])
    }
  }
}
