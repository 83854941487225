import { ContactPeron } from 'app/pages/core/model/contact-person.model'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { Observable, map } from 'rxjs'
import { Customer } from '../model/flexc-customer.model'
import { ApiResponse } from '../dto/ApiResponse'
import { Address } from '../model/address.model'
import { SharedService } from 'app/pages/customers/shared.service'

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private customerUrl: string = environment.apiBase + '/customers'
  private contactPersonUrl: string = environment.apiBase + '/contact-persons'
  private addressUrl: string = environment.apiBase + '/addresses'

  constructor(private http: HttpClient, private sharedService: SharedService) {}

  createCustomer(body: Customer): Observable<ApiResponse<Customer>> {
    return this.http.post<ApiResponse<Customer>>(this.customerUrl + '/create-customer', body)
  }

  getAllCustomers(
    page: number,
    size: number,
    sortBy: string,
    sortDirection?: string,
    search?: string,
    archived?: boolean
  ): Observable<ApiResponse<Customer[]>> {
    let paramMap: HttpParams = new HttpParams()
      .set('page', page)
      .append('size', size)
      .append('sort', sortBy + (sortDirection ? `,${sortDirection}` : ''))
    if (archived != undefined) {
      paramMap = paramMap.append('archived', archived)
    }
    if (search && search != '') {
      paramMap = paramMap.append('searchInput', search)
    }
    return this.http.get<ApiResponse<Customer[]>>(this.customerUrl + '/find-all', {
      params: paramMap,
    })
  }

  updateContactPerson(body: ContactPeron): Observable<ApiResponse<ContactPeron>> {
    return this.http.put<ApiResponse<ContactPeron>>(this.contactPersonUrl + '/update-contact-person', body)
  }

  updateCustomer(body: Customer): Observable<ApiResponse<Customer>> {
    return this.http.put<ApiResponse<Customer>>(this.customerUrl + '/update-customer', body)
  }

  createContactPerson(body: ContactPeron): Observable<ApiResponse<ContactPeron>> {
    return this.http.post<ApiResponse<ContactPeron>>(this.contactPersonUrl + '/create-contact-person', body)
  }

  getOneCustomer(id: string): Observable<ApiResponse<Customer>> {
    return this.http.get<ApiResponse<Customer>>(this.customerUrl + '/' + id)
  }

  getAllCustomersNoPagination(): Observable<ApiResponse<Customer[]>> {
    return this.http.get<ApiResponse<Customer[]>>(this.customerUrl + '/get-all')
  }

  getAllUnarchivedCustomers(): Observable<ApiResponse<Customer[]>> {
    return this.http.get<ApiResponse<Customer[]>>(this.customerUrl + '/get-all-unarchived-customers')
  }

  getAllArchivedCustomers(): Observable<ApiResponse<Customer[]>> {
    return this.http.get<ApiResponse<Customer[]>>(this.customerUrl + '/get-all-archived-customers')
  }

  archiveContactPerson(contactPersonId: string) {
    return this.http.put(this.contactPersonUrl + '/archiving-contact-person/' + contactPersonId, null)
  }

  archiveCustomer(customerId: string) {
    return this.http.put(this.customerUrl + '/archiving-customer/' + customerId, null)
  }

  getAllContactPeople(customerId: string): Observable<ApiResponse<ContactPeron[]>> {
    return this.http.get<ApiResponse<ContactPeron[]>>(this.contactPersonUrl + '/get-all-by-customer/' + customerId)
  }

  removeContactPerson(Id: string): Observable<ApiResponse<ContactPeron>> {
    return this.http.delete<ApiResponse<ContactPeron>>(this.contactPersonUrl + '/delete-contact-person/' + Id)
  }

  updateAddress(body: Address): Observable<ApiResponse<Address>> {
    return this.http.put<ApiResponse<Address>>(this.addressUrl + '/update-address', body)
  }

  archiveCustomers(body: Customer[]): Observable<ApiResponse<Customer[]>> {
    return this.http.put<ApiResponse<Customer[]>>(this.customerUrl + '/archiving-mass-activity/', body)
  }

  uploadImage(id: string): Observable<ApiResponse<Customer>> {
    const selectedFile: File = this.sharedService.getImageFile()
    const fd = new FormData()
    fd.append('image', selectedFile, selectedFile.name)
    return this.http.put<ApiResponse<Customer>>(this.customerUrl + '/update-customer/' + id + '/' + 'image', fd)
  }

  deleteCustomerImage(customerId: string): Observable<ApiResponse<Customer>> {
    return this.http.delete<ApiResponse<Customer>>(
      this.customerUrl + `/update-customer/${customerId}` + '/delete-image'
    )
  }

  deleteCustomerById(customerId: string): Observable<ApiResponse<Customer>> {
    return this.http.delete<ApiResponse<Customer>>(this.customerUrl + '/delete-customer/' + customerId)
  }

  checkIfCustomerNameIsTaken(name: string): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.customerUrl + '/is-name-taken', { name: name })
  }
}
