import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { Timesheet } from 'app/pages/core/model/timesheet.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { UserService } from 'app/pages/core/services/user.service'
import { DaysCount } from 'app/pages/projects/getDaysCount.service'
import { SharedService } from 'app/pages/projects/shared.service'
@Component({
  selector: 'app-specific-timesheet',
  templateUrl: './specific-timesheet.component.html',
  styleUrls: ['./specific-timesheet.component.scss'],
})
export class SpecificTimesheetComponent implements OnInit {
  @Output() changeBudget: EventEmitter<boolean> = new EventEmitter()
  userId!: string
  projectId!: string
  manager: boolean
  timeSheet!: Timesheet
  project!: Project
  daysLeft: number = 0
  teamDaysLeft: number = 0
  procentageLeft: number = 0
  procentageTeamLeft: number = 0
  totalDaysLeft: number
  notStarted: boolean = false
  formatedDate: any
  formatedStartDate: any
  budget: number
  daysLeftUser: number
  daysTotal: number
  blocked: boolean = false
  workingDaysLeft: number

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private daysCount: DaysCount,
    private userService: UserService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id')!
    this.projectId = this.route.snapshot.paramMap.get('projectId')!
    this.projectService.getOneProject(this.projectId).subscribe((res: ApiResponse<Project>) => {
      this.project = res.response
      this.userService
        .getUsersOnProject(this.projectId, 0, 50, 'user.firstName')
        .subscribe((res: ApiResponse<ProjectMember[]>) => {
          this.project.projectMembers = res.content
          this.setupProjectPage()
        })
    })
  }

  setupProjectPage() {
    let projectMember = this.project.projectMembers.find((val: ProjectMember) => val.user.id === this.userId)
    let total = projectMember?.allocation!
    this.budget = projectMember?.usedAllocationInPercentage!
    this.daysLeftUser = total * ((100 - projectMember?.usedAllocationInPercentage!) / 100)
    this.daysTotal = projectMember?.allocation ?? 0

    this.getDaysLeft()

    this.teamDaysLeft = this.daysCount.getTeamDaysLeft(this.daysLeft, this.project.plannedPeople!)

    this.procentageTeamLeft = this.daysCount.getprocentageTeamLeft(
      this.teamDaysLeft,
      this.project.timePeriod!,
      this.project.plannedPeople!
    )

    if (this.daysLeft > this.project.timePeriod!) {
      this.notStarted = true
    }

    this.projectService.isProjectManager(this.project.id).subscribe(({ response }: ApiResponse<boolean>) => {
      if (response) {
        this.manager = true
      }
    })

    this.route.queryParams.subscribe(params => {
      this.blocked = params['blocked'] ? params['blocked'].toLocaleLowerCase() === 'true' : false
    })

    this.formatedDate = this.daysCount.getDate(new Date(this.project.end))
    this.formatedStartDate = this.daysCount.getDate(new Date(this.project.start))
  }

  getDaysLeft() {
    let today = new Date()
    let endDate = this.project.end
    this.daysLeft = this.daysCount.getBudget(today, endDate)
    this.totalDaysLeft = this.daysCount.getBudget(this.project.start, this.project.end)
    this.procentageLeft = this.daysCount.getProcentageLeft(this.daysLeft, this.totalDaysLeft)
  }

  formatDate() {
    let date = new Date(this.project.end)
    let dateStart = new Date(this.project.start)
    this.formatedDate = this.daysCount.getDate(date)
    this.formatedStartDate = this.daysCount.getDate(dateStart)
  }

  modalSave(event: any) {
    if (event) {
      let projectMember = this.project.projectMembers.find((val: ProjectMember) => val.user.id === this.userId)
      this.budget = projectMember?.usedAllocationInPercentage!
      this.daysLeftUser =
        projectMember?.allocation && projectMember.usedAllocation
          ? projectMember.allocation - projectMember.usedAllocation
          : 0
    }
    this.sharedService.triggerModalSave()
  }
}
