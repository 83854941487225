import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { User } from 'app/pages/core/model/flexc-user.model'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { UserService } from 'app/pages/core/services/user.service'
import { SharedService } from '../../shared.service'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { environment } from 'environments/environment'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import { StepService } from 'app/pages/core/services/step.service'

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss'],
})
export class AddUsersComponent implements OnInit {
  @ViewChild('search', { static: false }) searchInput: ElementRef

  project: Project
  allUsersList: any[] = []
  allSelected: boolean = false
  firstModal: boolean = true
  selectedUsersList: User[] = []
  tableData: any[] = []
  searchTerm: string = ''

  paginationObject: any = {
    pageSize: 5,
    currentPage: 0,
    totalElements: 0,
  }

  tableHeadings = {
    image: '',
    firstName: useStringAsTranslationObject('FIRST_NAME'),
    lastName: useStringAsTranslationObject('LAST_NAME'),
    jobTitle: useStringAsTranslationObject('JOB_TITLE'),
    knowledge: useStringAsTranslationObject('KNOWLEDGE'),
    planned: useStringAsTranslationObject('PLANNED'),
    manager: useStringAsTranslationObject('MANAGER'),
  }

  constructor(
    public translate: TranslateService,
    public userService: UserService,
    private projectService: ProjectService,
    private sharedService: SharedService,
    private router: Router,
    private stepService: StepService
  ) {}

  ngOnInit(): void {
    this.stepService.step.subscribe(res => {
      if (res === 1) {
        this.showFirstStep()
      }
    })
  }

  fetchData() {
    this.sharedService.clearProject()
    this.project = this.sharedService.getProject()

    this.userService
      .getUsersNotInProject(
        this.project.id,
        this.paginationObject.currentPage,
        this.paginationObject.pageSize,
        this.searchTerm
      )
      .subscribe((response: ApiResponse<any[]>) => {
        this.paginationObject.totalElements = response.totalElements
        this.allUsersList = response.content
        this.allUsersList.forEach((element: any) => {
          element.isChecked = this.selectedUsersList.some(slectedUser => element.id === slectedUser.id)
        })

        this.projectBudgetCalculate()
        this.mapData()
      })
  }

  pageChange(event: any) {
    this.paginationObject = event
    this.fetchData()
  }

  mapData() {
    this.tableData = this.allUsersList.map(element => ({
      image: {
        type: element.imageUrl ? TABLE_TYPE.IMAGE : TABLE_TYPE.SVG,
        url: element.imageUrl ? `${environment.imgUrl}${element.imageUrl}` : './assets/media/logos/ICON_USER.svg',
      },
      firstName: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: element.firstName,
      },
      lastName: {
        type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
        string: element.lastName,
      },
      jobTitle: {
        type: TABLE_TYPE.TRANSLATED_STRING,
        string: element.portfolio?.jobTitle,
      },
      id: element.id,
      isChecked: element.isChecked,
      userAllocation: element.allocation ? element.allocation : 0,
      userConsumedBudget: element.usedAllocation ? element.usedAllocation : 0,
      ...(this.firstModal
        ? {
            knowledge: {
              type: TABLE_TYPE.TEXT_WITH_DESCRIPTION,
              string: element.portfolio?.knowledges?.map((knowledge: { name: any }) => knowledge.name).join(', ') || [],
            },
          }
        : {
            planned: {
              type: TABLE_TYPE.INPUT,
              string: element?.allocation!,
            },
            manager: {
              type: TABLE_TYPE.CHECK,
            },
          }),
    }))
  }

  oneSelectedUser(selectedUser: any) {
    let foundSelectedUser = this.selectedUsersList.find((user: User) => user.id === selectedUser.id)
    if (foundSelectedUser === undefined) {
      this.selectedUsersList.push(this.allUsersList.find((user: User) => user.id === selectedUser.id))
    } else {
      this.selectedUsersList.splice(this.selectedUsersList.indexOf(foundSelectedUser), 1)
    }
  }

  checkAll(event: any) {
    this.allSelected = !this.allSelected

    this.allUsersList.map(user => {
      user.isChecked = this.allSelected
      let foundUser = this.selectedUsersList.find((slectedUser: User) => slectedUser.id === user.id)
      if (user.isChecked) {
        if (foundUser === undefined) {
          this.selectedUsersList.push(user)
        }
      } else if (foundUser) {
        this.selectedUsersList.splice(this.selectedUsersList.indexOf(foundUser), 1)
      }
    })
  }

  backOrShowFirstScreen() {
    this.firstModal ? this.back() : this.showFirstScreen()
  }

  showFirstScreen() {
    this.firstModal = true
    this.mapData()
  }

  back() {
    this.router.navigate(['/projects/project-overview/', this.project.id, 'team-members'])
  }

  openModalProjectUsers() {
    if (this.firstModal) {
      this.mapData()
      this.addUsersToProject()
    } else {
      this.stepService.setStep(2)
      this.updateProject()
    }
  }

  addUsersToProject() {
    this.selectedUsersList.forEach(selectedUser =>
      this.project.projectMembers.push({
        user: selectedUser,
        manager: false,
        blocked: false,
      })
    )
    this.updateProject()
  }

  updateProject() {
    this.projectService.updateProject(this.project).subscribe((res: ApiResponse<Project>) => {
      if (res.response) {
        this.project.projectMembers.forEach(element => {
          if (element.user.imageUrl && !element.user.imageUrl?.startsWith(environment.imgUrl)) {
            element.user.imageUrl = `${environment.imgUrl}${element.user.imageUrl}`
          }
        })
        this.selectedUsersList = []
        this.sharedService.clearProject()
        if (!this.firstModal) {
          this.firstModal = true
          this.router.navigate(['projects/project-overview', this.project.id, '/team-members'])
        }
        this.firstModal = !this.firstModal
      }
    })
  }

  projectBudgetCalculate() {
    this.allUsersList.forEach(element => {
      element.availableAllocation = 100 - (element.availableAllocation / 249) * 100
    })
  }

  searchParam(event: any) {
    this.paginationObject.currentPage = 0
    this.searchTerm = event
    if (this.searchTerm.endsWith(' ')) {
      this.searchTerm = this.searchTerm.trim()
    }
    if (this.searchTerm.startsWith(' ')) {
      this.searchTerm = this.searchTerm.trimStart()
    }
    this.fetchData()
  }

  showFirstStep() {
    this.allUsersList = []
    this.tableData = []
    this.firstModal = true
    this.fetchData()
  }
}
