import { Component, Input, OnInit, ViewChild, Output, EventEmitter, AfterContentInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { ContactPeron } from 'app/pages/core/model/contact-person.model'
import { CustomerService } from 'app/pages/core/services/customer.service'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-cp-input-modal',
  templateUrl: './cp-input-modal.component.html',
  styleUrls: ['./cp-input-modal.component.scss'],
})
export class CpInputModalComponent implements OnInit, AfterContentInit {
  @ViewChild('modal') private modalComponent: ModalComponent

  @Input() openModalSubject!: BehaviorSubject<boolean>
  @Input() contactPerson!: BehaviorSubject<any>
  @Output() newItemEvent = new EventEmitter<boolean>()

  modalConfig: ModalConfig
  submit: boolean = false
  contactPersonForm: FormGroup
  id: string
  formObject: ContactPeron = {
    firstName: '',
    email: '',
    phoneNumber: '',
    lastName: '',
    id: '',
  }
  constructor(private translate: TranslateService, private fb: FormBuilder, private customerService: CustomerService) {}

  ngOnInit(): void {
    this.contactPersonForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: [''],
      comment: [''],
      email: [''],
      phoneNumber: [''],
    })
  }

  async openModal() {
    return await this.modalComponent.open()
  }

  async closeModal() {
    return await this.modalComponent.closeModal()
  }

  ngAfterContentInit(): void {
    this.openModalSubject.subscribe(res => {
      this.submit = false
      if (res) {
        this.contactPerson.subscribe(res => {
          if (res) {
            this.contactPersonForm.reset()
            this.contactPersonForm.patchValue(res)
            this.id = res.id
          }
        })
        this.openModal()
      }
    })
    this.modalConfig = {
      deleteButtonLabel: this.translate.instant('DELETE'),
      onDelete: () => {
        this.delete()
        return false
      },
      closeButtonLabel: this.translate.instant('SAVE'),
      onClose: () => {
        this.submitForm()
        return false
      },
    }
    this.translate.onLangChange.subscribe((res: any) => {
      this.modalConfig = {
        closeButtonLabel: this.translate.instant('SAVE'),
        deleteButtonLabel: this.translate.instant('DELETE'),
        onDelete: () => {
          this.delete()
          return false
        },
        onClose: () => {
          this.submitForm()
          return false
        },
      }
    })
  }

  delete() {
    this.customerService.removeContactPerson(this.id).subscribe((res: ApiResponse<ContactPeron>) => {
      if ((res.httpStatus = 'OK')) {
        this.newItemEvent.emit(true)
        this.closeModal()
      }
    })
  }

  submitForm() {
    this.submit = true
    if (this.contactPersonForm.valid) {
      this.formObject = this.contactPersonForm.value
      this.formObject.id = this.id
      this.customerService.updateContactPerson(this.formObject).subscribe((response: ApiResponse<ContactPeron>) => {
        if (response.response.id) {
          this.submit = false
          this.newItemEvent.emit(true)
          this.closeModal()
        }
      })
    }
  }
}
