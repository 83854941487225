import { DatePipe, Location } from '@angular/common'
import { HttpParams } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from 'app/modules/auth'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { User } from 'app/pages/core/model/flexc-user.model'
import { HoursConverterService } from 'app/pages/core/services/hours-converter.service'
import { ProjectService } from 'app/pages/core/services/project.service'
import { TimeSheetService } from 'app/pages/core/services/timesheet.service'
import { UserService } from 'app/pages/core/services/user.service'
import { useStringAsTranslationObject } from 'app/standalone/table/table.component'
import { TABLE_TYPE } from 'app/standalone/table/table.component.model'
import * as dayjs from 'dayjs'
import { environment } from 'environments/environment'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-user-month-overview',
  templateUrl: './user-month-overview.component.html',
  styleUrls: ['./user-month-overview.component.scss'],
})
export class UserMonthOverviewComponent implements OnInit {
  projectId: any
  userId: any
  tableHeadings = {
    year: useStringAsTranslationObject('YEAR'),
    month: useStringAsTranslationObject('MONTH'),
    days: useStringAsTranslationObject('DAYS'),
    hours: useStringAsTranslationObject('HOURS'),
    status: useStringAsTranslationObject('STATUS'),
    actions: useStringAsTranslationObject('ACTIONS'),
  }
  tableData: any[] = []
  usersMonthList: any[] = []
  userData: User
  daysLeft: number = 0
  projectBudget: number = 0
  total: number = 0
  usedAllocationInPercentage: number = 0
  usedAllocation: number = 0
  monthDate: any

  openModalApproveTimesheetsSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  selectedApproval: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private userService: UserService,
    private timesheetService: TimeSheetService,
    private hoursConverter: HoursConverterService,
    private location: Location,
    public authService: AuthService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.projectId = this.route.snapshot.queryParamMap.get('projectId')
      this.userId = this.route.snapshot.queryParamMap.get('userId')
      this.userService.getUserByID(this.userId).subscribe((res: ApiResponse<User>) => {
        this.userData = res.response
        this.userData.imageUrl = this.userData.imageUrl
          ? `${environment.imgUrl}${this.userData.imageUrl}`
          : 'assets/media/logos/ICON_USER.svg'
      })
      this.projectService.getProgresBarValue(this.userId, this.projectId).subscribe((res: any) => {
        this.total = res.response.allocation
        this.daysLeft = res.response.daysLeft
        this.projectBudget = res.response.usedAllocationInPercentage ?? 0
        this.usedAllocationInPercentage = res.response.usedAllocationInPercentage
        this.usedAllocation = res.response.usedAllocation
      })
      this.fetchSummary()
    })
    this.tableData = this.mapStatuses()
  }

  fetchSummary() {
    this.projectService.getUsersTimesheetSummary(this.userId, this.projectId).subscribe((res: any) => {
      if (res.response) {
        this.usersMonthList = res.response
        this.tableData = this.mapStatuses()
      }
    })
  }
  mapStatuses() {
    return this.usersMonthList.map(user => ({
      statusColor: user.status === 'BILLED' ? true : false,
      year: user.year,
      month: user.month,
      days: this.hoursConverter.convertDaysAndHours(user.days),
      hours: this.hoursConverter.convertDaysToHoursAndMinutes(user.hours),
      status: {
        type: TABLE_TYPE.STATUS_ICON,
        string: user.status,
        icon: this.determineIconByApprovalStatus(user.status),
      },
      actions: {
        type: TABLE_TYPE.STATUS_TIMESHEET,
        value: 2,
        projectId: 2,
        statusChanges: this.getApprovalsWithActions(user),
      },
    }))
  }

  getApprovalsWithActions(_approval: any) {
    const dateObject = new Date(dayjs(`01 ${_approval?.month} ${_approval?.year}`, 'DD MMMM YYYY').format('YYYY-MM-DD'))
    const year = dateObject.getFullYear()
    const month = dateObject.getMonth() + 1
    const day = dateObject.getDate()
    const monthDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`

    return [
      {
        title: 'APPROVE_ALL_TIMESHEET',
        onClick: () => {
          this.askForTimesheetApproval(_approval, true)
        },
        icon: 'assets/media/icons/duotune/general/file.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_PDF',
        onClick: () => {
          this.projectService
            .exportApprovalTimesheets(monthDate, this.projectId, this.userId)
            .subscribe(({ response }: any) => {
              this.timesheetService.convertTimesheetToPDF(this.timesheetService.mapResponseForExport(response))
            })
        },
        icon: 'assets/media/icons/duotune/general/export.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_CSV',
        onClick: () => {
          this.projectService
            .exportApprovalTimesheets(monthDate, this.projectId, this.userId)
            .subscribe(({ response }: any) => {
              if (response) {
                this.timesheetService.convertTimesheetToCSV(this.timesheetService.mapResponseForExport(response))
              }
            })
        },
        icon: 'assets/media/icons/duotune/general/export.svg',
      },
      {
        title: 'EXPORT_ALL_TIMESHEETS_EXCEL',
        onClick: () => {
          this.projectService.exportApprovalTimesheetsExcel(monthDate, this.projectId, this.userId).subscribe(blob => {
            const filename = 'your-filename.xlsx'
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
            window.URL.revokeObjectURL(link.href)
          })
        },
        icon: 'assets/media/icons/duotune/general/printer.svg',
      },
    ]
  }

  askForTimesheetApproval(approval: any, open: boolean) {
    this.selectedApproval = approval
    this.openModalApproveTimesheetsSubject$.next(open)
  }

  approveTimesheets(event: any) {
    const paramMap: HttpParams = new HttpParams()
      .append('monthStart', this.monthDate)
      .append('projectId', this.projectId!)
      .append('userId', this.userId)
    this.timesheetService.approveTimesheets(paramMap).subscribe(() => {
      this.fetchSummary()
      this.openModalApproveTimesheetsSubject$.next(false)
    })
  }

  determineIconByApprovalStatus(status: string) {
    switch (status) {
      case 'BILLED':
        return 'assets/media/icons/duotune/general/BilledTimesheet.svg'
      case 'APPROVED':
        return 'assets/media/icons/duotune/general/ApprovedTimesheet.svg'
      default:
        return 'assets/media/icons/duotune/general/DefaultTimesheet.svg'
    }
  }

  exportOrAprruves(event: any) {
    if (event.action === 'EXPORT_ALL_TIMESHEETS_EXCEL') {
      this.projectService.exportApprovalTimesheetsExcel(this.monthDate, this.projectId, this.userId).subscribe(blob => {
        const filename = 'your-filename.xlsx'
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
        window.URL.revokeObjectURL(link.href)
      })
    } else if (event.action === 'EXPORT_ALL_TIMESHEETS_CSV') {
      this.projectService
        .exportApprovalTimesheets(this.monthDate, this.projectId, this.userId)
        .subscribe(({ response }: any) => {
          if (response) {
            this.timesheetService.convertTimesheetToCSV(this.timesheetService.mapResponseForExport(response))
          }
        })
    } else if (event.action === 'APPROVE_ALL_TIMESHEET') {
    } else if (event.action === 'EXPORT_ALL_TIMESHEETS_PDF') {
      this.projectService
        .exportApprovalTimesheets(this.monthDate, this.projectId, this.userId)
        .subscribe(({ response }: any) => {
          this.timesheetService.convertTimesheetToPDF(this.timesheetService.mapResponseForExport(response))
        })
    }
  }

  redirectToAnotherPage(data: any) {
    this.router.navigate(
      [
        'projects/project-overview/' + this.projectId + '/team-members/specific-timesheet/',
        this.userId,
        this.projectId,
      ],
      { queryParams: { selectedMonth: data.month, selectedYear: data.year } }
    )
  }

  redirectToAnotherPageWithModalOpen() {
    this.router.navigate(
      [
        'projects/project-overview/' + this.projectId + '/team-members/specific-timesheet/',
        this.userId,
        this.projectId,
      ],
      {
        queryParams: {
          selectedMonth: this.datepipe.transform(new Date(), 'MMMM'),
          selectedYear: new Date().getFullYear().toString(),
          showModal: 'trueCreate',
        },
      }
    )
  }

  back() {
    this.location.back()
  }
}
