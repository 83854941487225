import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Timesheet } from 'app/pages/core/model/timesheet.model'
import { BehaviorSubject } from 'rxjs'
import { ModalComponent } from '../modal/modal.component'

@Component({
  selector: 'app-approve-timesheets-modal',
  templateUrl: './approve-timesheets-modal.component.html',
  styleUrls: ['./approve-timesheets-modal.component.scss'],
})
export class ApproveTimesheetsModalComponent {
  @ViewChild('modalApproveOnlyPending') private modalContent: TemplateRef<ModalComponent>

  @ViewChild('shareModal') shareModal!: TemplateRef<any>

  @Input() openModalSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({})

  @Output() approveTimesheets: EventEmitter<any> = new EventEmitter<any>()

  modalReference: any

  constructor(private modalService: NgbModal) {}

  ngAfterViewInit(): void {
    this.modalReference = this.openModalSubject$.subscribe((res: boolean) => {
      if (res === true) {
        this.modalService.open(this.modalContent, {
          centered: true,
          size: 'md',
          windowClass: 'add-new-modal',
        })
      }
    })
  }
}
