import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class TimesheetInputService {
  constructor(private http: HttpClient) {}
  private _inputTimesheeet = new BehaviorSubject<boolean>(false)

  emitInputTimesheet() {
    this._inputTimesheeet.next(true)
  }

  getTimeSheetValue(): Observable<boolean> {
    return this._inputTimesheeet.asObservable()
  }

  downloadFile(url: string) {
    return this.http.get(url, { responseType: 'blob' })
  }
}
