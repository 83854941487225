import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UserImageSharedService {
  private _changeImage = new BehaviorSubject<boolean>(false)
  selectedFile: File | undefined
  imageUrl: string

  setImageFile(file: File | undefined) {
    this.selectedFile = file
    if (!file) {
      this._changeImage.next(true)
    }
  }

  getImageFile() {
    return this.selectedFile
  }
  setImageUrl(imageUrl: string) {
    this.imageUrl = imageUrl
  }
  clearImageUrsl() {
    this.imageUrl = ''
  }

  getImageUrl() {
    return this.imageUrl
  }
  emitImageChange() {
    this._changeImage.next(true)
  }

  getImageChange(): Observable<boolean> {
    return this._changeImage.asObservable()
  }
}
