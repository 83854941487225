// Germany
export const locale = {
  lang: 'de',
  data: {
    DATE_AND_STATUS: 'Datum',
    DESCRIPTION: 'Beschreibung',
    START_TIME: 'ARBEITS ANFANG',
    START_BREAK: 'PAUSEN ANFANG',
    END_BREAK: 'PAUSEN ENDE',
    END_TIME: 'ARBEITS ENDE',
    SUM_HOURS: 'Summe Stunden',
    PROGRESS: 'Fortschritt',
    APPROVED: 'Genehmigt',
    PDF_PRINT: 'PDF Druck',
    ADD_NEW_ENTRY: 'Neuer Eintrag',
    ADD_NEW_ENTRY_FOR: 'Neuer Eintrag für',
    DATE: 'Datum',
    FILE_UPLOAD: 'Datei Upload',
    TASK: 'Aufgabe',
    PLACEHOLDER: 'Platzhalter',
    ALL_PROJECTS: 'Alle Projekte',
    ENTRIES: 'Einträge',
    TASK_OVERVIEW: 'Aufgaben',
    CUSTOMERS: 'Kunden',
    LIST_OF_CUSTOMERS: 'Kundenliste',
    COMPANY_TASK_OVERVIEW: 'Unternehmen',
    PROJECT_TASK_OVERVIEW: 'Projekte',
    CREATED: 'Erstellt',
    TASK_NAME: 'Aufgaben',
    USAGE: 'Nutzung',
    TASK_NAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    DESCRIPTION_IS_REQUIRED: 'Eingabe ist erforderlich',
    PROJECT_IS_REQUIRED: 'Eingabe ist erforderlich',
    ENTER_NEW_COMPANY_TASK: 'Eingabe neue Unternehmensaufgabe',
    DISCARD: 'Abbrechen',
    SAVE_AND_CLOSE: 'Speichern und schließen',
    ADD_NEW_TASK: 'Neue Aufgabe hinzufügen',
    ENTER_NEW_PROJECT_TASK: 'Eingabe neue Projektaufgabe',
    NO_DATA_TO_SHOW: 'Keine Daten vorhanden',
    PROJECT_WILL_START_SOON: 'Projekt startet demnächst',
    PROJECT: 'Projekt',
    SHOWING: 'Zeige',
    TO: 'bis',
    OF: 'von',
    USERS: 'Benutzer',
    COMPANIES: 'Unternehmen',
    ADD_SUPER_ADMIN: 'Super Admin hinzufügen',
    SUPER_ADMIN_LIST: 'Super Admin Liste',
    ADD_COMPANY: 'Unternehmen hinzufügen',
    COMPANY_LIST: 'Unternehmensliste',
    SUPER_ADMIN_MENU: 'Super Admin Menü',
    SUPER_ADMIN_COMPANY_LIST: 'Super Admin Unternehmensliste',
    ID: 'Personalnummer',
    USERNAME: 'Benutzername',
    FIRST_NAME: 'Vorname',
    LAST_NAME: 'Nachname',
    COMPANY: 'Unternehmen',
    EMAIL: 'E-Mail',
    ENTER_COMPANY_NAME: 'Unternehmensnamen eingeben',
    COMPANY_ADMIN: 'Unternehmensadmin',
    COMPANY_ADMIN_FULL_NAME: 'Vollständiger Name Unternehmensadmin',
    ENTER_COMPANY_ADMIN_FULL_NAME: 'Eingabe vollständiger Name Unternehmensadmin',
    COMPANY_ADMIN_EMAIL: 'Unternehmensadmin E-Mail',
    ENTER_COMPANY_ADMIN_EMAIL: 'Eingabe vollständiger Name Unternehmensadmin',
    COMPANY_ADMIN_PHONE_NUMBER: 'Unternehmensadmin Telefonnummer',
    ENTER_COMPANY_ADMIN_PHONE_NUMBER: 'Eingabe vollständiger Name Unternehmensadmin',
    COMPANY_NAME: 'Unternehmensname',
    COMPANY_NAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    STREET_NAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    STREET_NUMBER_IS_REQUIRED: 'Eingabe ist erforderlich',
    POSTCODE_IS_REQUIRED: 'Eingabe ist erforderlich',
    CITY_IS_REQUIRED: 'Eingabe ist erforderlich',
    STATE_IS_REQUIRED: 'Eingabe ist erforderlich',
    COMPANY_ADMIN_FULL_NAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    COMPANY_ADMIN_EMAIL_IS_REQUIRED: 'Eingabe ist erforderlich',
    COMPANY_ADMIN_PHONE_NUMBER_IS_REQUIRED: 'Eingabe ist erforderlich',
    CREATE_COMPANY: 'Erstelle Unternehmen',
    SUPER_ADMIN_SUPER_USER_LIST: 'Super Admin Super User List',
    COMPANY_NR: 'Unternehmensnummer',
    CUSTOMER_SINCE: 'Kunde seit',
    STATUS: 'Status',
    ARCHIVED_USER: 'Archivierter User',
    ACCEPTED: 'Akzeptiert',
    DECLINED: 'Abgelehnt',
    DRAFT: 'ENTWURF',
    OFFER: 'Angebot',
    RUNNING: 'AKTIV',
    PAUSED: 'PAUSE',
    FINISHED: 'BEENDET',
    CREATE_SUPER_USER_ADMIN: 'Erstelle Super User Admin',
    SUPER_USER_USERNAME: 'Benutzer Super User',
    ENTER_SUPER_USER_USERNAME: 'Benutzername eingeben',
    SUPER_USER_USERNAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    SUPER_USER_FIRST_NAME: 'Super User Vorname',
    ENTER_SUPER_USER_FIRST_NAME: 'Super User Nachname',
    SUPER_USER_FIRST_NAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    SUPER_USER_LAST_NAME: 'Super User Nachname',
    ENTER_SUPER_USER_LAST_NAME: 'Eingabe Super User Nachname',
    SUPER_USER_LAST_NAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    SUPER_USER_EMAIL: 'Super User E-Mail',
    ENTER_SUPER_USER_EMAIL: 'Eingabe Super User E-Mail',
    SUPER_USER_EMAIL_IS_REQUIRED: 'Eingabe ist erforderlich',
    CREATE_SUPER_USER: 'Erstelle Super User',
    FIVE_DAYS_A_WEEK: '5-Tage-Woche',
    SIX_DAYS_A_WEEK: '6-Tage-Woche',
    SEVEN_DAYS_A_WEEK: '7-Tage-Woche',
    BOOK_YOUR_TIME: 'Buche deine Zeit',
    DAYS: 'Tage',
    PLANED_DAYS_GREATHER_THAN_TOTAL_WORKING_DAYS:
      'Summe geplanter Arbeitstage größer als die Gesamtsumme der Arbeitstage',
    HELLO: 'Hallo',
    ENTER_JOB_TITLE: 'Eintragung Berufsbezeichnung',
    JOB_TITLE_IS_REQUIRED: 'Eingabe ist erforderlich',
    SKILL_LEVEL: 'Kompetenzlevel',
    COUNTRY: 'Land',
    START: 'Loslegen',
    END: 'Projektende',
    PROJECT_DESCRIPTION: 'Projektbeschreibung',
    ENTER_COUNTRY: 'Land eingeben',
    ENTER_PROJECT_NAME: 'Projektnamen eingeben',
    ENTER_PROJECT_DESCRIPTION: 'Projektbeschreibung eingeben',
    CUSTOMER_CREATED_SUCCESSFULLY: 'Kunde erfolgreich erstellt',
    CUSTOMER_NOT_CREATED_SOMETHING_WENT_WRONG: 'Kunde nicht erstellt, irgendetwas ist schiefgelaufen',
    USER_SAVED_SUCCESSFULY: 'Benutzer erfolgreich gespeichert',
    EXPERTISE: 'Expertise',
    KNOW_HOW: 'Wissen',
    PROJECTS: 'Projekte',
    SPOKEN_LANAGUES: 'Fremdsprachen',
    ADD_PERSONAL_EXPERIENCE: 'Expertise hinzufügen',
    ADD_LANGUAGE: 'Sprache hinzufügen',
    KNOWLEDGE_OVERVIEW: 'Qualifikationen',
    NAME: 'Name',
    ADD_KNOWLEDGE: 'Fähigkeiten hinzufügen',
    KNOWLEDGE_NAME: 'Bezeichnung Fähigkeiten',
    ENTER_NEW_KNOWLEDGE: 'Neue Fähigkeiten hinzufügen',
    KNOWLEDGE_NAME_IS_REQUIRED: 'Eingabe ist erforderlich',
    KNOWLEDGE_CREATED_SUCCESSFULLY: 'Fähigkeiten erfolgreich hinzugefügt',
    KNOWLEDGE_NOT_CREATED_SOMETHING_WENT_WRONG: 'Fähigkeit konnte nicht hinzugefügt werden',
    ENTER_COMPANY_TASK: 'Eingabe Unternehmensaufgabe',
    ENTER_PROJECT_TASK: 'Eingabe Projektaufgabe',
    TASK_CREATED_SUCCESSFULLY: 'Aufgabe erfolgreich erstellt',
    TASK_NOT_CREATED_SOMETHING_WENT_WRONG: 'Aufgabe nicht erstellt, etwas ist schief gelaufen',
    OVERVIEW: 'Übersicht',
    ADD: 'Hinzufügen',
    USER: 'Benutzer',
    CUSTOMER: 'Kunde',
    DASHBOARD: 'Dashboard',
    PROJECT_OVERVIEW: 'Übersicht',
    TASK_WITH_THAT_NAME_ALREADY_EXIST: 'Eingabe bereits vorhanden',
    KNOWLEADGE_ALREADY_EXIST: 'Eingabe bereits vorhanden',
    DATE_IS_REQUIRED: 'Eingabe ist erforderlich',
    LEFT: 'übrig',
    DONE: 'Erledigt',
    START_WORK: 'ARBEITS ANFANG',
    START_WORK_TIME_IS_REQUIRED: 'Eingabe ist erforderlich',
    BREAK_TIME_CANT_START_BEFORE_YOU_START_WORKING: 'Pausenzeit kann nicht vor Arbeitsbeginn starten',
    BREAK_TIME_END_IS_BEFORE_BREAK_TIME_START: 'Pausenende ist vor Pausenbeginn',
    END_WORK: 'ARBEITS ENDE',
    END_WORK_TIME_IS_REQUIRED: 'Arbeitszeitende ist erforderlich',
    END_WORKING_TIME_IS_BEFORE_END_BREAK_TIME: 'Arbeitszeitende ist vor Pausenende',
    TASKS: 'Aufgaben',
    CLICK: 'Klicke hier oder zieh die Dateien direkt in dieses Feld. Verfügbare Formate sind .jpg .jpeg .png .pdf',
    ENTER_NEW_ENTERY_FOR: 'Neue Eingabe für',
    PLEASE_CHOSE_PROJECT_FIRST: 'Bitte wählen Sie zuerst ein Projekt aus',
    THIS_ENTRY_ALREADY_EXISTS: 'Eingabe bereits vorhanden',
    CREATED_BY: 'Erstellt von',
    KNOWHOW: 'Fachwissen',
    RECORDS_PER_PAGE: 'Einträge pro Seite',
    IN_TOTAL: 'Gesamteinträge',
    JOB_TITLE: 'Berufsbezeichnung',
    ROLE: 'Berechtigung',
    INDUSTRY: 'Branche',
    ADD_CONTACT: 'Kontakt hinzufügen',
    PLEASE_CHOOSE_PROJECT_START_DATE: 'Eingabe ist erforderlich',
    SIGN_OUT: 'Abmelden',
    First_name: 'Vorname',
    NO_TASKS_FOUND_FOR_SELECTED_PROJECT: 'Keine Aufgabe für das ausgewählte Projekt vorhanden',
    NO_PROJECTS_FOUND: 'Keine Projekte vorhanden - bitte kontaktieren Sie Ihren Unternehmensadministrator',
    ROLE_PROJECT_ADMIN: 'Administrator',
    ROLE_PROJECT_MEMBER: 'Benutzer',
    ROLE_PROJECT_BACKOFFICE: 'Verwaltung',
    NORMAL_USER: 'Benutzer',
    ROLE_OWNER_ADMIN: 'Superadmin',
    PLEASE_CHOOSE_CUSTOMER: 'Kunden auswählen',
    CHOOSE_AT_LEAST_ONE_CUSTOMER: 'Mindestens einen Kunden auswählen',
    CUSTOMERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Änderung erfolgreich',
    CHOOSE_AT_LEAST_ONE_USER_ADMINISTRATOR_CANNOT_BE_ARCHIVED: 'Mindestens einen Benutzer auswählen',
    USERS_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Änderung erfolgreich',
    COMPANIES_ARCHIVE_UNARCHIVE_SUCCESSFULLY: 'Änderung erfolgreich',
    CHOOSE_AT_LEAST_ONE_COMPANY: 'Mindestens ein Unternehmen auswählen',
    USER_MAIN_MENU_SIDEBAR: 'Benutzer',
    E_MAIL: 'E-Mail',
    BACK: 'Zurück',
    SAVE_AND_CONTINUE: 'Speichern und weiter',
    PROJECT_NUMBER: 'Projektnummer',
    PROJECT_OFFER: 'Projektangebot',
    PROJECT_NAME: 'Projektname',
    START_DATE: 'Projektstart',
    END_DATE: 'Projektende',
    NO_END: 'Kein Ende bekannt',
    WEEK_MODE: 'Arbeitstage je Woche',
    TIME_PERIOD: 'Zeitraum',
    PLANED_PEOPLE: 'Geplante Personen',
    TOTAL_WORKING_DAYS: 'Anzahl der Arbeitstage',
    ADD_USERS_TO_THE_TEAM: 'Benutzer hinzufügen',
    ALLOCATE: 'Zuordnen',
    PLANNED: 'Geplant',
    EXPORT: 'Export',
    KNOWLEDGE_AND_THE_TEAM: 'Know-how und Team',
    ALLOCATE_AND_FINISH: 'Zuordnen und Abschließen',
    TOPIC_KNOWHOW: 'Know-how',
    BUILD_THE_TEAM: 'Team zusammenstellen',
    DUE_DATA: 'Projektende',
    OVERVIEW_APPROVALS: 'Monatsübersicht',
    EXPORT_AND_VERIFY_HOURS: 'Stunden exportieren und verifizieren',
    NO_FETCH_DATE: 'Keine Daten vorhanden',
    ERROR_WHILE_SAVING_DATA: 'Fehler bei Datenspeicherung',
    SAVED_SUCCESSFULLY: 'Gespeichert',
    CREATE_NEW_PASSWORD: 'Neues Passwort erstellen',
    PASSWORD: 'Passwort',
    CONFIRM_PASSWORD: 'Passwort bestätigen',
    CREATE: 'Erstellen',
    USER_PASSWORD_HAS_BEEN_SUCCESSFULLY_CREATED: 'Benutzer Passwort erfolgreich erstellt',
    SHOW_ACTIVE: 'Aktiv',
    SHOW_INACTIVE: 'Inaktiv',
    FRONTEND: 'Frontend',
    DRAFT_STATUS: 'Entwurf',
    RUNNING_STATUS: 'Aktiv',
    PAUSED_STATUS: 'Pause',
    FINISHED_STATUS: 'Abgeschlossen',
    MY_PROFILE: 'Mein Profil',
    KNOWLEDGE: 'Know-how',
    LIGHT: 'Dunkel',
    DARK: 'Hell',
    SYSTEM: 'System',
    NO_ITEMS_FOUND: 'Keine Elemente gefunden',
    PASSWORD_DOES_NOT_MATCH: 'Passwort stimmt nicht überein',
    PASSWORD_VALIDATOR:
      'Bitte verwenden Sie mindestens: 8 Zeichen, 1 Großbuchstaben (A-Z), 1 Kleinbuchstaben [a-z], 1 Zahl (0-9) und 1 Sonderzeichen (@#$%^&+=!._). Leerzeichen sind nicht zulässig.',
    RESET_PASSWORD: 'Passwort zurücksetzen',
    CUSTOMER_NAME_MUST_BE_UNIQUE: 'Eingabe bereits vorhanden',
    CUSTOMER_END_NAME_MUST_BE_UNIQUE: 'Eingabe bereits vorhanden',
    MY_TIMESHEET: 'Meine Stunden',
    MY_PROJECTS: 'Meine Projekte',
    ADMIN_MENU: 'ADMIN MENÜ',
    ADD_USER: 'Benutzer hinzufügen',
    ADD_CUSTOMER: 'Kunden hinzufügen',
    ADD_PROJECTS: 'Projekt hinzufügen',
    TEMPLATES: 'Vorlagen',
    ABOUT: 'Über uns',
    ENGLISH: 'Englisch',
    GERMAN: 'Deutsch',
    SERBIAN: 'Serbisch',
    USER_ROLE: 'Benutzerrolle',
    NO_MAIL: 'Keine E-Mail',
    SPOKEN_LANGUAGES: 'Sprachkenntnisse',
    SAVE: 'Speichern',
    PROJECT_LIST: 'Projektliste',
    OVERVIEW_OF_ALL_PROJECTS: 'Projektübersicht',
    PROJECT_STATUS_FILTER: 'Filter Projektstatus',
    SEARCH: 'Suche',
    NUMBERS: 'Nummer',
    STATE: 'Status',
    TEAM: 'Team',
    DAYS_LEFT: 'Verbleibende Tage',
    END_CUSTOMER: 'Endkunde',
    COMMENT: 'Kommentar',
    STREET: 'Straße',
    NUMBER: 'Hausnummer',
    ZIP: 'PLZ',
    CITY: 'Stadt',
    MASS_ACTIVITY: 'Massenbearbeitung',
    USER_DETAILS: 'Benutzerdetails',
    PROJECT_DETAILS: 'Projektdetails',
    TIME_AND_BUDGET: 'Zeit und Budget',
    BASIC_DATA: 'Grundlegende Daten können hier hinzugefügt werden',
    SHORT_DESCRIPTION: 'Kurzbeschreibung (Sichtbar für alle Projektmitglieder)',
    PROJECT_NAME_IS_REQUIRED: 'Projektname ist erforderlich',
    TOPIC_DESCRIPTION: 'Welche Kenntnisse werden für dieses Projekt benötigt',
    STAFF: 'Personal',
    CAPACITY: 'Kapazität',
    TIMESHEET: 'Zeiterfassung',
    ADD_PROJECT: 'Projekt hinzufügen',
    ARCHIVE: 'Deaktivieren',
    UNARCHIVE: 'Aktivieren',
    CHANGE_PROFILE_IMAGE: 'Profilbild ändern',
    PHONE_NUMBER: 'Telefonnummer',
    USERNAME_IS_REQUIRED: 'Benutzername ist erforderlich',
    USER_ROLE_IS_REQUIRED: 'Benutzerrolle ist erforderlich',
    EMAIL_IS_REQUIRED: 'E-Mail Adresse ist erforderlich',
    FIRST_NAME_IS_REQUIRED: 'Vorname ist erforderlich',
    LAST_NAME_IS_REQUIRED: 'Nachname ist erforderlich',
    BACKOFFICE_MENU: 'BACKOFFICE MENÜ',
    USER_MENU: 'BENUTZER MENÜ',
    START_PROJECT: 'Projekt starten',
    MEMBERS: 'Teammitglieder',
    ARCHIVED_PROJECTS: 'Archivierte Projekte',
    EDIT_PROJECT: 'Projekt anpassen',
    PROJECT_PERIOD: 'Projektzeitraum',
    PROJECT_BUDGET: 'Projektbudget',
    USED: 'Verbraucht',
    REMAINING_DAYS: 'Arbeitstagen verbleibend',
    COLLAGUES: 'Kollegen',
    STATUS_BUDGET: 'Budgetstatus',
    ACTIONS: 'Aktivitäten',
    DAYS_TOTAL: 'Tage in Summe',
    EDIT: 'ÄNDERN',
    ADD_ADITIONAL_TIME_PERIOD: 'Zeitraum hinzufügen',
    DEFINE_PERIOD: 'Zeitraum und Budget festlegen',
    KNOW_HOW_DESCRIPTION: 'z.B.: Berufserfahrung, Qualifikation, Kenntnisse, Sprache usw.',
    USERNAME_EXIST: 'Benutzername ist bereits vergeben',
    CUSTOMER_REQUIRED: 'Kundenname ist erforderlich',
    MANAGER: 'Manager',
    ENTER_COMPANY_ADMIN_FIRST_NAME: 'Vorname eingeben',
    ENTER_COMPANY_ADMIN_LAST_NAME: 'Nachname eingeben',
    ENTER_COMPANY_ADMIN_USERNAME: 'Benutzernamen eingeben',
    COMPANY_ADMIN_FIRST_NAME_IS_REQUIRED: 'Vorname ist erforderlich',
    COMPANY_ADMIN_LAST_NAME_IS_REQUIRED: 'Nachname ist erforderlich',
    COMPANY_ADMIN_USERNAME_IS_REQUIRED: 'Benutzername ist erforderlich',
    TEAM_MEMBERS_PLAN: 'Welche Teammitglieder benötigen Sie für dieses Projekt?',
    PROJECT_START_DATE: 'Datum Projektstart',
    PROJECT_NUMBER_EXIST: 'Eingabe bereits vorhanden',
    PROJECT_INFO_UPDATE_SUCESFULY: 'Projekt erfolgreich aktualisiert',
    ASSIGN: 'Zuordnen',
    PROJECT_SAVED_SUCCESSFULLY: 'Projekt erfolgreich gespeichert',
    CAN_BE_USED: 'Für alle Projekte nutzbar',
    ONLY_USABLE: 'Nur für das jeweilige Projekt nutzbar',
    ACTION: 'Aktion',
    FIRST_LAST_NAME_MESSAGE: 'Buchstaben und bis zu drei Wörter sind zulässig',
    SUCCESSFULLY_DELETED: 'Erfolgreich gelöscht',
    CANCEL: 'Abbrechen',
    DELETE_SUPER_ADMIN: 'Löschen',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_USER: 'Soll der Benutzer unwiderruflich gelöscht werden?',
    THE_PASSWORD_MUST_INCLUDE_AT_LEAST_ONE_UPPERCASE_LETTER_ONE_NUMBER_AND_ONE_SPECIAL_CHARACTER:
      'Bitte verwenden Sie mindestens: 8 Zeichen, 1 Großbuchstaben (A-Z), 1 Kleinbuchstaben [a-z], 1 Zahl (0-9) und 1 Sonderzeichen (@#$%^&+=!._)',
    MARK_AS_APPROVED: 'Genehmigen',
    MARK_AS_BILLED: 'Abgerechnet',
    EXPORT_PDF: 'Export PDF',
    EXPORT_CSV: 'Export CSV',
    PRINT_PDF: 'PDF-Druck',
    USERNAME_CANNOT_CONTAIN_WHITE_SPACE: 'Der Benutzername darf kein Leerzeichen enthalten',
    ARE_YOR_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_BILLED:
      'Sind Sie sicher, dass Sie den Status auf „Abgerechnet“ setzen möchten?',
    ARE_YOU_SURE_YOU_WANT_TO_SET_THE_STATUS_TO_APPROVED:
      'Sollen die ausgewählten Einträge auf „Genehmigt“ gesetzt werden?',
    DO_YOU_REALLY_WANT_TO_DELETE_TIMESHEET: 'Sollen die ausgewählten Einträge wirklich gelöscht werden?',
    APPROVE_ALL: 'Alle genehmigen',
    TIMESHEET_ACTION: 'Bearbeitung der Stundennachweise',
    ACTIVE_USER: 'Aktivieren',
    INACTIVE_USER: 'Deaktivieren',
    PROJECT_NAME_ALREADY_TAKEN: 'Projektname existiert bereits',
    PROJECT_END: 'Projektende',
    PROJECT_START: 'Projektstart',
    CUSTOMER_FIRST_NAME: 'Vorname',
    CUSTOMER_END_NAME: 'Nachname',
    DELETE: 'Löschen',
    CANNOT_DELETE_BILLED_OR_APPROVED_TIMESHEET: 'Abgerechnete oder genehmigte Einträge können nicht gelöscht werden',
    WE_CANNOT_BILL_FOR_THE_UNAPPROVED_TIMESHEET:
      'Einträge müssen erst genehmigt werden bevor sie in Rechnung gestellt werden',
    NO: 'Nein',
    YES: 'Ja',
    LOCKED: 'Gesperrt',
    INFO: 'INFO',
    THIS_VALUE_WILL_BE_USED_FOR_THE_CALCULATION_OF_THE_PROJECT_BUDGET_IN_DAYS:
      'Dieser Wert wird für die Berechnung des Projektbudgets in Tagen verwendet',
    WORKING_DAYS_ESTIMATION: 'Geschätzte Arbeitstage',
    RESET_TO_CALCULATION: 'Auf Kalkulation zurücksetzen',
    CALCULATE_BUDGET: 'Budget kalkulieren',
    ACTIVATE_USER: 'Aktiviere Benutzer',
    DEACTIVATE_USER: 'Deaktiviere Benutzer',
    DELETE_USER: 'Benutzer löschen',
    RE_SEND_ACTIVATION_EMAIL: 'Aktivierung erneut versenden',
    USER_DELETED_SUCCESSFULY: 'Benutzer erfolgreich gelöscht',
    CHANGE_BUDGET: 'Budgetanpassung',
    BUDGET_OVERVIEW: 'Budgetübersicht',
    COLLEAGUES: 'Kollegen',
    SUM_BUDGET_DISTRIBUTION_OF: 'Summe der zugewiesenen Tage',
    SUM_OF_ASSIGNED_DAYS_IS_HIGHER_THEN_TOTAL_WORKING_DAYS:
      'Die Summe der zugewiesenen Tage ist höher als die Gesamtzahl der Arbeitstage.',
    REVOKE_STATUS: 'Status widerrufen',
    REVOKE_STATUS_MODAL: 'Möchten Sie Ihren Status widerrufen?',
    ENTRY_IS_REQUIRED: 'Eingabe ist erforderlich.',
    ENTRY_ALREADY_EXIST: 'Eingabe bereits vorhanden.',
    ATTENTION_YOU_ARE_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'Achtung: Buchung erfolgt außerhalb des Projektzeitraums; Ihr Projektmanager wird darüber informiert werden',
    TIME: 'Zeit',
    ENDTIME: 'Ende',
    STARTTIME: 'Beginn',
    HOUR: 'Stunden',
    BREAK: 'Pause',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'ACHTUNG: Das Projektbudget ist überschritten. Sie können fortfahren, aber Ihr Projektmanager wird darüber informiert werden.',
    ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_AND_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED:
      'ACHTUNG: Das Projektbudget ist überschritten und die Buchung ist außerhalb des Projektzeitraumes. Sie können fortfahren, aber Ihr Projektmanager wird darüber informiert werden.',
    USER_HAS_BEEN_SUCCESSFULLY_UPDATED: 'Der Benutzer wurde erfolgreich aktualisiert.',
    INVALID_EMAIL_FORMAT: 'Ungültiges E-Mail Format',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_TASK: 'Soll die Aufgabe wirklich gelöscht werden?',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_KNOWLEDGE: 'Soll die Fähigkeit wirklich gelöscht werden?',
    ALL_USERS: 'Alle Benutzer',
    ALL_CUSTOMERS: 'Alle Kunden',
    ADD_NEW: 'Hinzufügen',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS_EIGHT_HOURS:
      'Grundlage der Berechnung entspricht einem Arbeitstag von 8 Stunden',
    ERROR_UNARCHIVING_KNOWLEDGE: 'Beim Löschen ist ein Fehler aufgetreten',
    ERROR_DELETING_KNOWLEDGE: 'Beim Löschen ist ein Fehler aufgetreten',
    ERROR_UNARCHIVING_TASK: 'Beim Löschen ist ein Fehler aufgetreten',
    ERROR_DELETING_TASK: 'Beim Löschen ist ein Fehler aufgetreten',
    COMPLETED: 'Abgeschlossen',
    ACTIVE: 'Aktiv',
    DRAFT_LOWER_CASE: 'Entwurf',
    YOUR_LAST_LOGIN: 'Letzte Anmeldung',
    YOUR_LAST_TIMEBOOKING: 'Letzte Zeitbuchung',
    YOUR_ASSIGNED_PROJECTS: 'Ihre zugewiesenen Projekte',
    MANAGING: 'Manager',
    MEMBER_OF: 'Mitglied',
    WORKING_HOURS_ESTIMATION: 'Einschätzung der Arbeitsstunden',
    USERS_ACTIVE_INACTIVE_SUCCESSFULLY: 'Benutzer wurde erfolgreich aktiviert/deaktiviert',
    PAUSED_LOWER_CASE: 'Pause',
    ACTIVE_USERS: 'Aktive Benutzer',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_TASK: 'Soll die Aufgabe wirklich archiviert werden?',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_KNOWLEDGE: 'Soll die Fähigkeit wirklich archiviert werden?',
    IN_USE: 'wird genutzt',
    NOT_IN_USE: 'nicht genutzt',
    AFRICAN: 'Afrikanisch',
    ALBANIAN: 'Albanisch',
    ARABIC: 'Arabisch',
    BELARUSIAN: 'Belarussisch',
    BOSNIAN: 'Bosnisch',
    BULGARIAN: 'Bulgarisch',
    CHINESE: 'Chinesisch',
    CROATIAN: 'Kroatisch',
    CZECH: 'Tschechisch',
    DANISH: 'Dänisch',
    DUTCH: 'Niederländisch',
    EGYPTIAN: 'Ägyptisch',
    ESTONIAN: 'Estnisch',
    FILIPINO: 'Philippinisch',
    FRENCH: 'Französisch',
    GREEK: 'Griechisch',
    HUNGARIAN: 'Ungarisch',
    ICELANDIC: 'Isländisch',
    INDIAN: 'Indisch',
    IRISH: 'Irisch',
    ITALIAN: 'Italienisch',
    JAPANESE: 'Japanisch',
    KOREAN: 'Koreanisch',
    KURDISH: 'Kurdisch',
    LATVIAN: 'Lettisch',
    LITHUANIAN: 'Litauisch',
    LUXEMBOURGISH: 'Luxemburgisch',
    MACEDONIAN: 'Mazedonisch',
    NORWEGIAN: 'Norwegisch',
    PERSIAN: 'Persisch',
    POLISH: 'Polnisch',
    PORTUGUESE: 'Portugisisch',
    ROMANIAN: 'Rumänisch',
    RUSSIAN: 'Russisch',
    SCOTTISH: 'Schottisch',
    SLOVENIAN: 'Slovenisch',
    SLOVAK: 'Slowakisch',
    SPANISH: 'Spanisch',
    SWEDISH: 'Schwedisch',
    SYRIAN: 'Syrisch',
    THAI: 'Thailändisch',
    TURKISH: 'Türkisch',
    UKRAINIAN: 'Ukrainisch',
    VIETNAMESE: 'Vietnamesisch',
    UPDATE_IMAGE_SIZE_ERROR: 'Die Datei ist zu groß. Die maximal zulässige Größe beträgt 5 MB.',
    LOCK_USERS: 'Sperren / Entsperren',
    ADD_ENTRIES: 'Einträge hinzufügen',
    SAVE_DRAFT: 'Entwurf speichern',
    LAST_LOGIN: 'Letzte Anmeldung',
    MAXIMUM_CHARACTERS_IS_50: 'Bitte verwenden Sie maximal 50 Zeichen',
    CREATED_ON: 'Erstellt am',
    BLOCKED: 'Gesperrt',
    COMPANY_DATA: 'Unternehmensdaten',
    FULL_COMPANY_NAME: 'Vollständiger Firmenname',
    STREET_AND_NUMBER: 'Straße und Hausnummer',
    LICENSE_AND_SUBSCRIPTION: 'Lizenz und Abonnement',
    CHANGE_SUBSCRIPTION: 'Abonnement ändern',
    WORKING_TIME: 'Arbeitszeit',
    WORKING_HOURS_DAY: 'Arbeitsstunden je Tag',
    SETTINGS: 'Einstellungen',
    VAT_Number: 'Umsatzsteuer-Identifikationsnummer',
    ATTENTION: 'ACHTUNG:',
    BUDGET_IS_CALCULATED_IN_WORKING_DAYS: 'in Arbeitstagen berechnet wird.',
    FOR_EXAMPLE: 'Zum Beispiel : 8h = 1 Arbeitstag',
    WORKING_DAY: 'Arbeitstag',
    THIS_CHANGE_HAS_A_DIRECT_EFFECT_ON_THE_DISPLAY_OF_THE_BUDGET_IN_DAYS:
      'Diese Änderung hat für alle Benutzer eine direkte Auswirkung auf die Darstellung des "Budget in Tagen".',
    FORR_ALL_USERS: 'Für alle Benutzer',
    NEXT: 'Weiter',
    OVERVIEW_OF_LANGUAGE_SKIL: 'Übersicht der Sprachkenntnisse',
    NATIV_KNOWLEDGE: 'Muttersprachniveau',
    NEGOTIABLE_KNOWLEDGE: 'Verhandlungssicher',
    FLUENT_KNOWLEDGE: 'Fließend in Wort und Schrift',
    GOOD_KNOWLEDGE: 'Gute Kenntnisse',
    BASIC_KNOWLEDGE: 'Grundkenntnisse',
    DELETE_PROFILE_IMAGE: 'Profilbild entfernen',
    SUM: 'SUMME',
    VALUE_MUST_BE_BETWEEN_1_AND_12: 'Eingabe zwischen 1 und 12 möglich',
    PROJECT_FILTER: 'Projekt Filter',
    HERE_YOU_CAN_CHANGE_THE_BASIS_ON_WHICH_THE_PROJECT:
      'Hier können Sie die Basisdaten ändern, auf der das Projektbudget in Arbeitstagen berechnet wird',
    THE_NEW_VALUE_FOR_A_USER_BUDGET_CANNOT_BE_ADJUSTED_TO_BE_LESS_THAN_THE_BUDGET_ALREADY_USED:
      'Der neue wert für ein user budget kann nicht geringer angepasst werden als das bereits verbrauchte budget',
    MINIMUM_CHARACTERS_IS_50: 'Bitte verwenden Sie minimum 2 Zeichen',
    HOURS: 'Stunden',
    THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS: 'Grundlage der Berechnung entspricht einem Arbeitstag von:',
    COMPANY_UPDATED_SUCCESSFULLY: 'Firma erfolgreich aktualisiert',
    UPDATE_ENTRY_INFORMATION_FOR: 'Neue Eingabe für',
    CUSTOMER_UPDATED_SUCCESSFULLY: 'Kunde erfolgreich aktualisiert',
    ADMIN: 'Administrator',
    BACKOFFICE: 'Verwaltung',
    AGRICULTURAL_ECONOMICS: 'Agrarwirtschaft',
    SERVICES: 'Dienstleistungen',
    INSURANCE: 'Versicherungen',
    CONSTRUCTION_INDUSTRIES: 'Bauindustrie',
    REAL_ESTATE: 'Immobilien',
    METAL_AND_ELECTRICAL_INDUSTRY: 'Metall- und Elektroindustrie',
    IT_SERVICES: 'IT-Dienstleistungen',
    SPORTS_AND_FITNESS: 'Sport und Fitness',
    ADVERTISING_AND_MARKETING: 'Werbung und Marketing',
    CRAFT: 'Handwerk',
    TRANSPORTATION_AND_LOGISTICS: 'Verkehr und Logistik',
    AUTOMOTIVE_INDUSTRY: 'Automobilindustrie',
    CHEMISTRY: 'Chemie',
    PUBLIC_ADMINISTRATION: 'Öffentlicher Dienst',
    RETAIL_AND_TRADE: 'Handel',
    MEDIA: 'Medien',
    HEALTHCARE: 'Gesundheitswesen',
    FOOD_INDUSTRY: 'Lebensmittel',
    PHARMACEUTICALS: 'Pharma',
    BANKING_AND_FINANCIAL_SERVICES: 'Banken und Finanzdienstleistungen',
    ENERGY_AND_ENVIRONMENT: 'Energie und Umwelt',
    NON_PROFIT_ORGANIZATIONS: 'Gemeinnützige Organisationen',
    TELECOMMUNICATIONS: 'Telekommunikation',
    TEXTILES_AND_APPAREL_INDUSTRY: 'Textil- und Bekleidungsindustrie',
    OTHER: 'Andere',
    E_COMMERCE: 'E-Commerce',
    TOURISM_AND_GASTRONOMY: 'Tourismus und Gastronomie',
    PLANT_CONSTRUCTION_AND_MACHINERY_MANUFACTURING: 'Maschinen- und Anlagebau',
    AVIATION_AND_ASTRONAUTIC: 'Luftfahrt und Raumfahrt',
    DAYS_BOOKED: 'Tage gebucht',
    ADD_NEW_CUSTOMER: 'Neuen Kunden anlegen',
    SYSTEM_ERROR: 'Systemfehler',
    PAGE_NOT_FOUND: 'Seite nicht gefunden',
    RETURN_TO: 'Zurück zur',
    HOME: 'Startseite',
    LOADING_PROJECTS: 'Projekte laden',
    FILE: 'Datei',
    DO_YOU_REALLY_WANT_TO_DELETE_THIS_CUSTOMER: 'Möchten Sie diesen Kunden wirklich löschen?',
    DO_YOU_REALLY_WANT_TO_ARCHIVE_THIS_CUSTOMER: 'Möchten Sie diesen Kunden wirklich archivieren?',
    SHOW_ARCHIVED: 'Archivierte anzeigen',
    ASSIGNED_PROJECTS: 'Zugewiesene Projekte',
    ALL_ENTRIES: 'Alle Einträge',
    STANDARD_LANGUAGE: 'Standardsprache',
    DEFAULT_SETTINGS: 'Standardeinstellungen',
    COMPANY_REGISTRATION_CONFIRMATION:
      'Vielen Dank, Ihr Konto wurde erfolgreich erstellt. Bitte überprüfen Sie Ihr E-Mail-Postfach für die nächsten Schritte.',
    DASHBOARD_PROJECTS: 'Projekte',
    DASHBOARD_PROJECT: 'Projekt',
    DASHBOARD_CUSTOMERS: 'Kunden',
    DASHBOARD_CUSTOMER: 'Kunde',
    DASHBOARD_USER: 'Aktiver Benutzer',
    DASHBOARD_USERS: 'Aktive Benutzer',
    ACTIVE_PROJECTS: 'Aktive Projekte',
    INACTIVE_PROJECTS: 'Inaktive Projekte',
    RUNNING_AND_PAUSED_STATUS: 'Laufende und pausierte Projekte',
    DRAFT_AND_FINISHED_STATUS: 'Status Entwurf oder Abgeschlossen',
    ALL_EXISTING_PROJECTS: 'Alle vorhandenen Projekte',
    EMAIL_TAKEN: 'E-Mail ist bereits vergeben!',
    COMPANY_NAME_TAKEN: 'Das Unternehmen ist bereits vorhanden!',
    PASSWORD_COMPLEXITY_ERROR_TEXT: 'Entspricht nicht der benötigten Passwort-Komplexität',
    APPROVE_ALL_TIMESHEET: 'Alle Stundennachweise genehmigen',
    EXPORT_ALL_TIMESHEETS_EXCEL: 'Export aller Stundennachweise in Excel',
    EXPORT_ALL_TIMESHEETS_CSV: 'Export aller Stundennachweise in CSV',
    EXPORT_ALL_TIMESHEETS_PDF: 'Export aller Stundennachweise in PDF',
    PENDING: 'Ausstehend',
    BILLED: 'In Rechnung gestellt',
    ASK_FOR_APPROAVAL_TEXT: 'Nur Stundennachweise mit dem Status „ausstehend“ können genehmigt werden!',
    TEAM_MEMBERS: 'Teammitglieder',
    ARCHIVED_CUSTOMERS: 'Achivierte Kunden',
    ACTIVE_CUSTOMERS: 'Aktive Kunden',
    CREATE_YOUR_COMPANY_ACCOUNT: 'ERSTELLEN SIE IHR FIRMENKONTO',
    DATA_EXPLORER: 'Datenexplorer',
    REPORTS: 'Berichte',
    BACK_TO_PROJECTS: 'Zurück zu Projekte',
    USED_BUDGET: 'Verbrauchtes Budget',
    BOOK: 'Buche',
    BACK_TO_MY_PROJECTS: 'Zurück zu Meine Projekte',
    USER_EMAIL_VALIDATION: 'Bitte eine gültige E-Mail verwenden',
    PLEASE_FILL_ALL: 'Bitte füllen Sie alle Felder aus, um mit 9to5.cloud zu starten',
    TERMS_AND_PRIVACY_POLICY:
      '<span class="marked">*</span> Durch Absenden des Formulars bestätige ich, dass ich die <a href="https://www.9to5.cloud/datenschutz" target="_blank">Datenschutzerklärung</a> zur Kenntnis genommen habe und mit der Verarbeitung meiner personenbezogenen Daten durch 9to5.cloud zu den genannten Zwecken einverstanden bin. Im Falle einer Einwilligung kann ich meine Zustimmung hierzu jederzeit widerrufen. Darüber hinaus stimme ich durch Absenden des Formulars den <a href="https://www.9to5.cloud/agb" target="_blank">allgemeinen Geschäftsbedingungen</a> zu.',
    QUICK_START_TITLE: 'QUICK START GUIDE',
    QUICK_START_GUIDE: 'Loslegen und die Funktionen von 9to5 kennenlernen!',
    MOBILE_CHOOSE_PROJECT: 'Projekt auswählen',
    SELECT_TIME: 'Uhrzeit auswählen',
    MOBILE_SELECT_TASK: 'Aufgabe auswählen',
    MOBILE_CHOOSE_FILE: 'Datei auswählen',
    MOBILE_GALLERY: 'Galerie',
    MOBILE_CAMERA: 'Kamera',
    MOBILE_DOCUMENT: 'Datei',
    MOBILE_PROJECTS_MANAGED_BY_ME: 'Von mir betreute Projekte:',
    MOBILE_HOME_LAST_ENTRY: 'Letzter Eintrag:',
    MOBILE_PROJECTS_NOBODY_ASSIGNED: 'NIEMAND ZUGEWIESEN',
    MOBILE_PROJECTS_SELECT_ALL: 'Alle auswählen',
    MOBILE_PROJECTS_ENTRY_FOR: 'EINTRAG FÜR',
    MOBILE_PROJECTS_OF: 'von',
    MOBILE_BOOK_TIME: 'ZEIT BUCHEN',
    JANUARY: 'Januar',
    FEBRUARY: 'Februar',
    MARCH: 'März',
    APRIL: 'April',
    MAY: 'Mai',
    JUNE: 'Juni',
    JULY: 'Juli',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'Oktober',
    NOVEMBER: 'November',
    DECEMBER: 'Dezember',
  },
}
