<div class="input_wrapper" [ngClass]="[size, disabled ? 'disabled' : '']">
  <label class="input_label" [for]="name">{{ label }}</label>
  <nz-input-group [nzSuffix]="suffixTemplate" class="input_group">
    <input
      [type]="passwordVisible ? 'text' : 'password'"
      nz-input
      [placeholder]="placeholder || 'Password'"
      [(ngModel)]="innerValue"
    />
  </nz-input-group>
  <ng-template #suffixTemplate>
    <span
      nz-icon
      style="cursor: pointer"
      [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
      (click)="passwordVisible = !passwordVisible"
    ></span>
  </ng-template>
</div>
