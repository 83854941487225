import { Component, OnInit } from '@angular/core'
import { User } from 'app/pages/core/model/flexc-user.model'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { environment } from 'environments/environment'
import { cloneDeep } from 'lodash'
import { ProjectService } from 'app/pages/core/services/project.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { PROJECTSTATUS } from 'app/pages/statusProject'
import { ProjectStatus } from 'app/pages/core/model/project-status.model'

@Component({
  selector: 'app-create-project-step-four',
  templateUrl: './create-project-step-four.component.html',
  styleUrls: ['./create-project-step-four.component.scss'],
})
export class CreateProjectStepFourComponent implements OnInit {
  submitted: boolean
  projectId: string
  projectData: Project
  projectMembers: ProjectMember[]
  totalWorkingDays: number
  planedDays: number = 0
  procentageProgresBar: number = 0
  greather: boolean = false

  constructor(private projectService: ProjectService, private _router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.projectId = params['id']
      this.getProjectData()
    })
    this.planedDays = 0
  }

  getProjectData() {
    this.projectService.getOneProject(this.projectId).subscribe(({ response }: ApiResponse<Project>) => {
      this.projectData = response
      this.projectMembers = []
      this.projectMembers = cloneDeep(this.projectData.projectMembers)
      for (const iterator of this.projectMembers) {
        if (iterator.user.imageUrl!) {
          iterator.user.imageUrl = environment.imgUrl + iterator.user.imageUrl!
        }
      }
      this.totalWorkingDays = this.projectData.budgetEstimation ?? 0
      this.setAllocation()
      this.setProcentageBar()
    })
  }

  calculateDays(event: any, worker: User) {
    this.greather = false
    this.planedDays = 0
    let day: number
    day = parseInt(event.target.value)

    if (Number.isNaN(day)) {
      this.projectMembers.forEach(element => {
        if (element.user.id === worker.id) {
          element.allocation = 0
        }
      })
    } else {
      this.projectMembers.forEach(element => {
        if (element.user.id === worker.id) {
          element.allocation = day
        }
      })
    }
    this.setAllocation()
    this.setProcentageBar()

    if (this.planedDays > this.totalWorkingDays) {
      this.greather = true
      this.planedDays = 0
      this.projectMembers.forEach(element => {
        if (element.user.id === worker.id) {
          element.allocation = 0
        }
      })
      this.setAllocation()
      this.setProcentageBar()
    }
  }

  setProcentageBar() {
    if (this.totalWorkingDays > 0) {
      this.procentageProgresBar = this.planedDays / this.totalWorkingDays
      if (this.procentageProgresBar > 1) {
        this.procentageProgresBar = 100
      } else {
        this.procentageProgresBar = this.procentageProgresBar * 100
      }
    }
  }

  checkBoxChange(worker: ProjectMember) {
    const foundWorker = this.projectMembers.find(element => element === worker)
    if (foundWorker) {
      foundWorker.manager = true
    }
    this.projectData.projectMembers = this.projectMembers
  }

  setAllocation() {
    this.projectMembers.forEach(element => {
      this.planedDays += element.allocation ?? 0
    })
    this.projectData.projectMembers = this.projectMembers
  }

  updateProject(startProject: boolean = false) {
    if (this.greather) return
    if (startProject) {
      this.projectData.projectStatus = { id: PROJECTSTATUS.RUNNING } as ProjectStatus
    }
    this.projectService.updateProject(this.projectData).subscribe((response: ApiResponse<Project>) => {
      this._router.navigate(['/projects/projects-list'])
    })
  }

  goBack() {
    this._router.navigate(['../../step-3/', this.projectId], { relativeTo: this.route })
  }
}
