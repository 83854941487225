import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { SharedService } from '../../shared.service'
import { TimeService } from '../../project-overview/project-modify/time-service.service'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { CompanyService } from 'app/pages/core/services/company.service'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { Project } from 'app/pages/core/model/project.model'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-project-time',
  templateUrl: './project-time.component.html',
  styleUrls: ['./project-time.component.scss'],
})
export class ProjectTimeComponent implements OnInit {
  @Input() projectTimeForm: FormGroup
  @Input() submittedTrue: boolean

  items: any[] = [
    {
      label: this.translate.instant('FIVE_DAYS_A_WEEK'),
      value: '5',
    },
    {
      label: this.translate.instant('SIX_DAYS_A_WEEK'),
      value: '6',
    },
    {
      label: this.translate.instant('SEVEN_DAYS_A_WEEK'),
      value: '7',
    },
  ]

  numberOfDays = 0
  totalWorkingDays = 0
  days = 0
  plannedPeople = 0
  weekModeValue: any = this.items[0]
  noEnd: boolean = false
  calculatedBudget: number = 0
  startDateCheck: NgbDateStruct
  showInactive: boolean = false
  totalWorkingDaysEstimate: number
  workingHour: number
  startTime: any
  endTime: any
  project: Project

  constructor(
    public sharedService: SharedService,
    private translate: TranslateService,
    private timeService: TimeService,
    private companyService: CompanyService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.companyService.getCompanySettings().subscribe(({ response }: ApiResponse<any>) => {
      this.workingHour = response.workingHours
    })

    this.setupProjectData()

    this.translate.onLangChange.subscribe(() => {
      this.items = [
        {
          label: this.translate.instant('FIVE_DAYS_A_WEEK'),
          value: '5',
        },
        {
          label: this.translate.instant('SIX_DAYS_A_WEEK'),
          value: '6',
        },
        {
          label: this.translate.instant('SEVEN_DAYS_A_WEEK'),
          value: '7',
        },
      ]
    })
  }

  setupProjectData() {
    this.project = this.sharedService.getProject()
    if (this.project.start) {
      this.formControls['startDate'].setValue(this.datePipe.transform(this.project?.start, 'dd-MM-yyyy'))
      this.formControls['endDate'].setValue(this.datePipe.transform(this.project?.end, 'dd-MM-yyyy'))
      this.plannedPeople = this.formControls['planedPeople'].value
      this.noEnd = this.formControls['noEnd'].value
      this.calculateDays()
    }
  }

  get formControls() {
    return this.projectTimeForm.controls
  }

  getWeekDays(e: any) {
    if (e) {
      this.weekModeValue = e
      this.calculateDays()
    }
  }

  calculateDays() {
    let startDate = this.projectTimeForm.value.startDate.split('-')
    this.startTime = new Date(startDate[2], startDate[1] - 1, startDate[0])

    if (this.noEnd !== true) {
      let endDate = this.projectTimeForm.value.endDate.split('-')
      this.endTime = new Date(endDate[2], endDate[1] - 1, endDate[0])
      this.days = this.timeService.countWeekdays(this.startTime, this.endTime, +this.weekModeValue.value)
      this.totalWorkingDays = this.days * (this.plannedPeople ?? 0)
    }
  }

  daysInput($event: any) {
    this.totalWorkingDaysEstimate = $event.target.value
    this.sharedService.setCalculatedBudget(this.totalWorkingDaysEstimate)
  }

  resetTimePeriodAndWeekMode() {
    this.weekModeValue = null
    this.projectTimeForm.get('weekMode')?.reset()
    this.days = 0
    this.plannedPeople = 0
    this.totalWorkingDays = 0
  }

  totalWorking(e: any) {
    this.plannedPeople = e.target.value
    this.totalWorkingDays = this.days * (this.plannedPeople ?? 0)
    this.totalWorkingDaysEstimate = this.totalWorkingDays
    this.sharedService.setNumberOfPeople(this.plannedPeople)
    this.sharedService.setCalculatedBudget(this.totalWorkingDays)
  }

  changeNoEnd(event: any) {
    this.noEnd = event.target.checked

    if (this.noEnd) {
      this.days = 0
      this.totalWorkingDays = 0
    }
    this.timeService.blur(this.projectTimeForm)
  }

  get formValue() {
    return this.projectTimeForm.value
  }

  calculateBudget() {
    this.showInactive = !this.showInactive
    this.noEnd = false
  }

  resetDays() {
    this.totalWorkingDaysEstimate = this.totalWorkingDays
    this.sharedService.setCalculatedBudget(this.totalWorkingDays)
  }
}
