<div class="input_wrapper" [ngClass]="[size, disabled ? 'disabled' : '']">
  <label class="input_label" [for]="name">{{ label }}</label>
  <div *ngIf="search; else inputField" class="search_container">
    <div
      [inlineSVG]="searchIcon"
      [setSVGAttributes]="{ height: '20px', width: '20px' }"
      class="search_icon"
      [ngClass]="[size, disabled ? 'disabled' : '']"
    ></div>
    <ng-container *ngTemplateOutlet="inputField"></ng-container>
  </div>
  <ng-template #inputField>
    <input
      type="{{ type }}"
      (blur)="onBlur()"
      [(ngModel)]="innerValue"
      [name]="name"
      class="input"
      [ngClass]="[type, size]"
      [disabled]="disabled"
      [placeholder]="placeholder"
      (ngModelChange)="modelChange($event)"
    />
  </ng-template>
</div>
