<ng-template #modalCheckForDoubleBooking let-modal>
  <div class="modal-content">
    <div class="x-icon" (click)="modal.dismiss('cancel')">
      <span class="svg-icon svg-icon-1" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"></span>
    </div>
    <div class="modal-body">
      <div class="imageModal">
        <span [inlineSVG]="'./assets/media/icons/duotune/arrows/delete_modal.svg'"></span>
      </div>
      <p>The entry is already booked, do you want to book it again?</p>
    </div>
    <div class="buttons_footer">
      <button type="button" class="btn color" (click)="modal.dismiss('cancel')">
        {{ 'BACK' | translate }}
      </button>
      <button
        type="button"
        class="btn color"
        (click)="saveDoubleBooking.emit(this.timesheetForSaving); modal.dismiss('cancel')"
      >
        {{ 'OK' | translate }}
      </button>
    </div>
  </div>
</ng-template>
