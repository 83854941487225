export const TABLE_TYPE = {
  IMAGE: 'IMAGE',
  SVG: 'SVG',
  ADVANCED_TRANSLATED_STRING: 'ADVANCED_TRANSLATED',
  TRANSLATED_STRING: 'TRANSLATED',
  MULTILINE_TRANSLATED_STRING: 'MULTILINE_TRANSLATED',
  TEXT_WITH_DESCRIPTION: 'TEXT_WITH_DESCRIPTION',
  BAR_GRAPH: 'BAR_GRAPH',
  USERS: 'USERS',
  STATUS: 'STATUS',
  CLICKABLE_IMAGE: 'CLICKABLE_IMAGE',
  CLICKABLE_SVG: 'CLICKABLE_SVG',
  INPUT: 'INPUT',
  DATE: 'DATE',
  CHECK: 'CHECK',
  DATE_PARSED: 'DATE_PARSED',
  CLICKABLE_IMAGE_LIST: 'CLICKABLE_IMAGE_LIST',
  STATUS_TIMESHEET: 'STATUS_TIMESHEET',
  STATUS_ICON: 'STATUS_ICON',
  BUDGET_OVERVIEW_STATUSES: 'BUDGET_OVERVIEW_STATUSES',
  NUMBER: 'NUMBER',
}
