import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserService } from '../../core/services/user.service'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { KnowledgeDto } from 'app/pages/core/model/knowledge.model'
import { KnowladgeService } from 'app/pages/core/services/knowledge.service'
import { BehaviorSubject } from 'rxjs'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { User } from 'app/pages/core/model/flexc-user.model'
import { SpokenLanguages } from 'app/pages/core/model/spoken-languages'
import { UserSharedService } from '../user-shared.service'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { SuccessModalComponent } from 'app/_metronic/partials/layout/modals/success-modal/success-modal.component'
import { ErrorModalComponent } from 'app/_metronic/partials/layout/modals/error-modal/error-modal.component'
import { UserImageSharedService } from '../user-image-shared.service'
import { QuerySharedService } from 'app/pages/core/services/query-shared.service'

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  public user: User = new User()

  @ViewChild('errorModal') private modalComponentError: ErrorModalComponent
  @ViewChild('successModal') private modalComponentSuccess: SuccessModalComponent
  @ViewChild('modal') private modalComponent: ModalComponent

  //Step counter
  currentStep$: BehaviorSubject<number> = new BehaviorSubject(1)
  @Output() modalCloseEmitter: EventEmitter<boolean> = new EventEmitter()
  @Output() modalSaveEmitter: EventEmitter<boolean> = new EventEmitter()

  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>('User saved successfully!')
  secondaryTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')

  newModalTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')

  modalConfig: ModalConfig = {
    newModalTitle: this.newModalTitle,
    modalBodyTitle: this.titleForModal,
    secondaryTitle: this.secondaryTitle,
    closeButtonLabel: 'Ok',
    onClose: () => {
      this.modalClose()
      return false
    },
    onDismiss: () => {
      this.modalClose()
      return true
    },
  }

  modalClose() {
    this.modalCloseEmitter.emit(true)
  }

  public knowladgePlaceholder: string = this.translate.instant('PROJECTS.PROJECT_LIST.PLACEHOLDER')

  public updateUser: boolean = false
  public knowledgeArr: KnowledgeDto[] = []

  public userDetailsForm: FormGroup
  public personalProfileForm: FormGroup
  public expertiseProjectsForm: FormGroup

  public submitted: boolean = false
  public archived: boolean = false
  public archivedUser: boolean = true
  public id: string = ''

  public currentStep = 0
  public portfoloId: number = 0

  public openModalBool: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbModal,
    private userService: UserService,
    private _router: Router,
    private translate: TranslateService,
    public knowledgeService: KnowladgeService,
    private route: ActivatedRoute,
    private userSharedService: UserSharedService,
    private imageSharedService: UserImageSharedService,
    private querySharedService: QuerySharedService
  ) {
    this.userDetailsForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('^\\s*([a-zA-ZZüöäÜÖÄß-]+\\s*){1,3}$')]],
      lastName: ['', [Validators.required, Validators.pattern('^\\s*([a-zA-ZZZüöäÜÖÄß-]+\\s*){1,3}$')]],
      email: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$')]],
      noEmail: [false],
      jobTitle: ['', [Validators.required, Validators.maxLength(50)]],
      username: ['', [Validators.required]],
      profileImage: [''],
      userRole: [null, Validators.required],
      archived: [false, Validators.required],
    })

    let languages = []
    let langauge = this.formBuilder.group({
      language: [, Validators.required],
      skill: [1, [Validators.required, Validators.min(1), Validators.max(5)]],
    })
    languages.push(langauge)

    this.personalProfileForm = this.formBuilder.group({
      languages: this.formBuilder.array(languages),
      experience: ['', [Validators.maxLength(100)]],
    })

    this.expertiseProjectsForm = this.formBuilder.group({
      knowHows: this.formBuilder.array([]),
      userKnowladge: this.formBuilder.array([]),
    })
  }

  ngOnInit(): void {
    if (this.route.snapshot.routeConfig!.path === 'update-user/:id') {
      this.id = this.route.snapshot.paramMap.get('id')!

      if (this.id != null) {
        this.userService.getUserByID(this.id).subscribe((res: ApiResponse<User>) => {
          this.updateUser = true
          this.user = res.response
          if (res.response.noMail) {
            this.userDetailsForm
              .get('email')
              ?.removeValidators([
                Validators.required,
                Validators.pattern(
                  /^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
                ),
              ])
          }
          this.userDetailsForm.addControl('id', this.formBuilder.control(res.response.id, Validators.required))
          this.userDetailsForm.get('firstName')?.setValue(res.response.firstName)
          this.userDetailsForm.get('lastName')?.setValue(res.response.lastName)
          this.userDetailsForm.get('noEmail')?.setValue(res.response.noMail)
          this.userDetailsForm.get('username')?.setValue(res.response.username)
          this.userDetailsForm.get('profileImage')?.setValue(res.response.imageUrl)
          this.userDetailsForm.get('userRole')?.setValue(res.response.userRole.id)
          this.expertiseProjectsForm.get('userKnowledges')?.setValue(res.response.portfolio?.knowledges)
          this.userDetailsForm.get('archived')?.setValue(res.response.archived)
          this.userDetailsForm.get('jobTitle')?.setValue(res.response.portfolio?.jobTitle)
          this.personalProfileForm.get('experience')?.setValue(res.response.portfolio?.experience)
          this.archived = res.response.archived

          if (res.response.portfolio?.spokenLanguages) {
            let languages = this.personalProfileForm.get('languages') as FormArray
            languages.removeAt(0)
            for (let langauge of res.response.portfolio?.spokenLanguages) {
              let spokenLanguage = this.formBuilder.group({
                id: [langauge.id, Validators.required],
                language: [langauge.language, Validators.required],
                skill: [langauge.level, [Validators.required, Validators.min(1), Validators.max(5)]],
              })
              languages.push(spokenLanguage)
            }
          }

          if (res.response.portfolio?.knowHows) {
            this.userSharedService.resetProjects()
            let knowHows = this.expertiseProjectsForm.get('knowHows') as FormArray
            for (let knowHow of res.response.portfolio?.knowHows) {
              let knowHowGroup = this.formBuilder.group({
                id: [knowHow.id, Validators.required],
                country: [knowHow.country],
                start: [knowHow.start, Validators.required],
                end: [knowHow.end],
                name: [knowHow.name, Validators.required],
                description: [[knowHow.description], [Validators.required, Validators.maxLength(50)]],
              })
              this.userSharedService.setProject(knowHow)
              knowHows.push(knowHowGroup)
            }

            if (res.response.portfolio?.knowledges) {
              let userKnowladge = this.expertiseProjectsForm.get('userKnowladge') as FormArray
              for (let knowladges of res.response.portfolio?.knowledges) {
                let knowHowGroup = this.formBuilder.group({
                  id: [knowladges.id, Validators.required],
                  knowledges: [knowladges.name, Validators.required],
                })
                userKnowladge.push(knowHowGroup)
              }
              this.userSharedService.setKowladge(res.response.portfolio?.knowledges)
            }
          }
        })
      }
    }
  }

  nextStep() {
    this.submitted = true

    this.submitForm()
  }

  prevStep() {
    this.submitted = false
    if (this.currentStep$.value === 1) {
      return
    }
    this.currentStep$.next(this.currentStep$.value - 1)
  }

  public submitForm() {
    this.user.username = this.userDetailsForm.get('username')?.value.trim()
    this.user.firstName = this.userDetailsForm.get('firstName')?.value.trim()
    this.user.lastName = this.userDetailsForm.get('lastName')?.value
    this.user.noMail = this.userDetailsForm.get('noEmail')?.value
    this.user.email = this.user.noMail
      ? this.userDetailsForm.get('username')?.value
      : this.userDetailsForm.get('email')?.value.trim()
    this.user.archived = this.archived
    this.user.profileImage = this.userDetailsForm.get('profileImage')?.value
    this.user.userRole = {
      id: this.userDetailsForm.get('userRole')?.value,
    }
    if (this.user.portfolio) {
      this.user.portfolio.jobTitle = this.userDetailsForm.get('jobTitle')?.value
      this.user.portfolio.experience = this.personalProfileForm.get('experience')?.value
    } else {
      this.user.portfolio = {
        jobTitle: this.userDetailsForm.get('jobTitle')?.value,
        knowledges: this.userDetailsForm.get('userKnowledges')?.value,
        experience: this.personalProfileForm.get('experience')?.value,
      }
    }

    let languages = this.personalProfileForm.get('languages')?.value
    let spokenLanguages = []
    for (let language of languages) {
      if (language.language) {
        let spokenLanguage = new SpokenLanguages()
        spokenLanguage.level = language.skill
        spokenLanguage.language = language.language
        spokenLanguages.push(spokenLanguage)
      }
    }
    this.user.portfolio.spokenLanguages = spokenLanguages
    if (this.updateUser) {
      if (this.currentStep === 1) {
        this.userService.updateUserByID(this.user).subscribe((res: ApiResponse<User>) => {
          if (res.response.id) {
            this.user = res.response
            this.portfoloId = res.response.portfolio?.id!
          }
        })
      }
      if (this.currentStep === 2) {
        this.user.portfolio.id = this.portfoloId
        this.userService.updateUserByID(this.user).subscribe((res: ApiResponse<User>) => {
          if (res.response.id) {
            this.submitted = false
          }
        })
      }

      if (this.currentStep === 3) {
        this.user.portfolio.id = this.portfoloId
        this.user.portfolio.knowHows = this.userSharedService.getKnowHows()
        this.user.portfolio.knowledges = this.userSharedService.getKnowladge()
        this.userService.updateUserByID(this.user).subscribe((res: ApiResponse<User>) => {
          if (res.response.id) {
            if (this.currentStep$.value === 4) {
              this.titleForModal.next(this.translate.instant('USER_SAVED_SUCCESSFULY'))
              this.openSucccessModal()
              this._router.navigate(['users/users-list'])
            }
            this.submitted = false
          }
        })
      }
    } else {
      if (this.validUserNameAndEmailInput() && this.userDetailsForm.valid) {
        this.userService.createUser(this.user).subscribe((response: ApiResponse<User>) => {
          if (response.response.id && this.imageSharedService.getImageFile()) {
            this.userService.uploadImage(response.response.id).subscribe(
              (res: ApiResponse<User>) => this.handleSuccessResponse(response.response),
              (error: any) => {
                if (error.status === 400) this.handleImageBadRequestError()
              }
            )
          } else {
            this.handleSuccessResponse(response.response)
          }
        }, this.handleErrorResponse)
      }
    }
  }

  validUserNameAndEmailInput(): boolean {
    return !this.userSharedService.getUsernameExist() && !this.userSharedService.getEmailExist() && !!this.user.email
  }

  handleImageBadRequestError() {
    this.submitted = false
    this.titleForModal.next(this.translate.instant('UPDATE_IMAGE_SIZE_ERROR'))
    this.openSucccessModal()
    this._router.navigate(['users/users-list'])
  }

  handleSuccessResponse(response: User) {
    this.submitted = false
    this.id = response.id!
    this.user.id = this.id
    this.updateUser = true
    this.portfoloId = response.portfolio?.id!
    this.userDetailsForm.get('email')?.setValue(response.email)
    this.titleForModal.next(this.translate.instant('USER_SAVED_SUCCESSFULY'))
    this.openSucccessModal()
    this._router.navigate(['users/users-list'])
  }

  handleErrorResponse(error: any) {
    this.submitted = false
    this.newModalTitle.next(this.translate.instant(''))
    this.openErrorModal()
  }

  get userDetailsFormControls() {
    return this.userDetailsForm.controls
  }

  archiveUser() {
    this.userService.archivingUser(this.id!).subscribe()
    if (this.archived) {
      this.archived = false
    } else {
      this.archived = true
    }
  }

  async openSucccessModal() {
    this.openModalBool = true
    this.modalConfig.closeButtonLabel = 'Ok'
    return await this.modalComponentSuccess.open()
  }

  async openErrorModal() {
    this.openModalBool = true
    return await this.modalComponentError.open()
  }

  async openModal() {
    this.openModalBool = true
    if (!this.user.archived) {
      this.titleForModal.next('Do yo want to archive user?')
      this.newModalTitle.next('Archive user')
    } else {
      this.titleForModal.next('Do yo want to unarchive user?')
      this.newModalTitle.next('Unarchive user')
    }
    this.modalConfig.closeButtonLabel = 'Yes'
    this.modalConfig.dismissButtonLabel = 'No'
    this.modalConfig.onClose = () => {
      this.userService.archivingUser(this.user.id!).subscribe(
        (res: ApiResponse<User>) => {
          if (res.response.archived) {
            this.titleForModal.next('User is succsefully archived!')
          } else {
            this.titleForModal.next('User is succsefully unarchived!')
          }
          this.openSucccessModal()
          this._router.navigate(['users/users-list'])
        },
        err => {
          this.titleForModal.next(err)
          this.openErrorModal()
        }
      )
      return true
    }
    return await this.modalComponent.open()
  }

  arhiveUser() {
    this.userService.archivingUser(this.id!).subscribe()
    if (this.user.archived) {
      this.user.archived = false
    } else {
      this.user.archived = true
    }
  }

  back(event: any) {
    let query = this.querySharedService.getQueryForRoute('users/users-list')
    this._router.navigate(['users/users-list'], {
      queryParams: query,
      queryParamsHandling: 'merge',
    })
  }
}
