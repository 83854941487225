<div class="row-wraper">
  <div class="card-body border-top p-9 w-900">
    <div class="d-flex flex-wrap align-items-center mb-10">
      <div id="kt_signin_password_edit" class="flex-row-fluid false">
        <form [formGroup]="projectTimeForm">
          <section class="projectTimeWraper">
            <div class="wraper">
              <span>{{ 'TIME_AND_BUDGET' | translate }}</span>
              <p>{{ 'DEFINE_PERIOD' | translate }}</p>
            </div>
            <div class="row mt-7">
              <div class="dateColumn">
                <label class="form-label fs-6 fw-bolder required font">{{ 'START_DATE' | translate }} </label>
                <div class="input-group">
                  <input
                    class="form-control form-control-lg form-control-solid"
                    placeholder="dd.mm.yyyy"
                    name="d3"
                    ngbDatepicker
                    #d3="ngbDatepicker"
                    formControlName="startDate"
                    [readonly]="true"
                    (dateSelect)="calculateDays()"
                    autocomplete="off"
                  />
                  <button class="btn bi-calendar3" (click)="d3.toggle()" type="button"></button>
                </div>
                <div
                  *ngIf="submittedTrue && formControls['startDate']?.errors?.required"
                  value="dd-mm-yyyy"
                  style="display: block"
                  class="invalid-feedback"
                >
                  {{ 'ENTRY_IS_REQUIRED' | translate }}
                </div>
              </div>
              <div class="spacing"></div>
              <div class="dateColumn">
                <label class="form-label fs-6 fw-bolder required font"> {{ 'END_DATE' | translate }} </label>
                <div class="input-group">
                  <input
                    class="form-control form-control-lg form-control-solid"
                    placeholder="dd.mm.yyyy"
                    name="d2"
                    [readonly]="true"
                    ngbDatepicker
                    #d2="ngbDatepicker"
                    formControlName="endDate"
                    (dateSelect)="calculateDays()"
                    [minDate]="startDateCheck"
                    autocomplete="off"
                  />
                  <button class="btn bi-calendar3" (click)="d2.toggle()" type="button"></button>
                </div>
                <div
                  *ngIf="submittedTrue && formControls['endDate']?.errors?.required"
                  value="dd-mm-yyyy"
                  style="display: block"
                  class="invalid-feedback"
                >
                  {{ 'ENTRY_IS_REQUIRED' | translate }}
                </div>
              </div>
              <div class="spacing"></div>
              <div class="col-md-2 noEnd">
                <label class="form-label fw-bolder" for="flexCheckDefault">
                  {{ 'NO_END' | translate }}
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  formControlName="noEnd"
                  (change)="changeNoEnd($event)"
                />
              </div>
              <div class="col-md-5 switch mt-3" *ngIf="!noEnd">
                <app-switch [checked]="showInactive" (handleChange)="calculateBudget()"></app-switch>
                <div class="mt-4">{{ 'CALCULATE_BUDGET' | translate }}</div>
              </div>

              <div class="d-flex flex-column columnWidth">
                <div *ngIf="!noEnd">
                  <div *ngIf="showInactive" style="border-radius: 12px; border: 1px dashed #b5b5c3; padding: 20px">
                    <div class="col-md-12">
                      <label class="form-label fs-6 fw-bolder mt-5 font">{{ 'WEEK_MODE' | translate }} </label>
                      <app-select
                        [options]="items"
                        [label]="'label'"
                        type="primary"
                        size="large"
                        [selectedValue]="weekModeValue"
                        (statusSelectChange)="getWeekDays($event)"
                      ></app-select>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="mt-7 plannedPeopleInputs">
                        <label class="form-label fs-6 fw-bolder">{{ 'TIME_PERIOD' | translate }}</label>
                        <input
                          style="background: #1bc5bd26 !important"
                          type="number"
                          class="form-control background-color"
                          [value]="days | number : '1.0-0'"
                          readonly
                        />
                      </div>
                      <div class="mt-7 plannedPeopleInputs">
                        <label class="form-label fs-6 fw-bolder">{{ 'PLANED_PEOPLE' | translate }}</label>
                        <input
                          type="number"
                          class="form-control background-color"
                          min="1"
                          [value]="plannedPeople"
                          formControlName="planedPeople"
                          (input)="totalWorking($event)"
                        />
                      </div>
                    </div>

                    <div class="col-md-12 mt-7">
                      <div class="workingDaysWraper">
                        <span class="font-color">{{ 'TOTAL_WORKING_DAYS' | translate }}</span>
                        <span>{{ this.totalWorkingDays | number : '1.0-0' }} {{ 'DAYS' | translate }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="col-md-5 mt-7 marginRight">
                    <label class="form-label fs-6 fw-bolder">{{ 'WORKING_DAYS_ESTIMATION' | translate }}</label>
                    <input
                      formControlName="budgetEstimation"
                      type="number"
                      class="form-control background-color"
                      [value]="totalWorkingDaysEstimate"
                      (input)="daysInput($event)"
                    />
                  </div>
                  <div class="spacing"></div>
                  <ng-container *ngIf="!noEnd">
                    <ng-container *ngIf="showInactive">
                      <div class="col-md-5 mt-7 marginLeft" (click)="resetDays()">
                        <button type="button" class="btn col-md-5 mt-10 background-color w-100" (click)="resetDays()">
                          {{ 'RESET_TO_CALCULATION' | translate }}
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="col-md-12 mt-7">
                  <div class="infoMessage">
                    <div class="d-flex">
                      <div class="d-flex align-items-center justify-content-center" style="margin-right: 8px">
                        <img
                          src="../../../../../../assets/media/icons/duotune/general/gen044.svg"
                          alt="exclamation_icon"
                        />
                      </div>
                      <div>
                        {{ 'INFO' | translate }} {{ 'THE_CALCULATION_IS_BASED_ON_ONE_WORKING_DAY_EQUALS' | translate }}
                        {{ workingHour }} {{ 'HOURS' | translate }}.<br />{{
                          'THIS_VALUE_WILL_BE_USED_FOR_THE_CALCULATION_OF_THE_PROJECT_BUDGET_IN_DAYS' | translate
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  </div>
</div>
