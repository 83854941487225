import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private _step: BehaviorSubject<number> = new BehaviorSubject(1)

  constructor(private http: HttpClient) {}

  get step() {
    return this._step.asObservable()
  }

  setStep(step: number) {
    this._step.next(step)
  }
}
