<div class="card-header border-0 pt-5 d-flex">
  <div
    [ngClass]="{ endFlex: !membersLength }"
    class="card-toolbar"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-trigger="hover"
  >
    <div *ngIf="membersLength" class="alignMassActivity">
      <app-menu-dropdown [title]="'MASS_ACTIVITY' | translate" [actions]="menuActions"></app-menu-dropdown>
    </div>
    <div>
      <a
        *ngIf="this.authService.currentUserRole === 'PROJECT_ADMIN'"
        class="btn btn-sm btn-light-primary bg-color text-color"
        routerLink="add-user"
      >
        <span [inlineSVG]="'./assets/media/icons/duotune/general/addUser.svg'" class="svg-icon svg-icon-3"></span>
        {{ 'ADD' | translate }}
      </a>
      <a
        *ngIf="project?.budgetEstimation && (this.authService.currentUserRole !== 'PROJECT_MEMBER' || isManager)"
        class="btn btn-sm userBudgetModify ms-2"
        routerLink="user-budget"
      >
        {{ 'CHANGE_BUDGET' | translate }}
      </a>
    </div>
  </div>
</div>
<div class="table-wrapper">
  <app-table
    class="project-members-table"
    [customHeadings]="tableHeadings"
    [selectable]="true"
    *ngIf="tableData"
    (checkedValueChanged)="onMangerChanged($event)"
    (selectionChange)="selectionChange($event)"
    [rowClickable]="true"
    (rowClick)="redirectToSpecificTimesheetPage($event)"
    [dataList]="tableData"
    [hiddenColumns]="['id', 'user', 'hasUsedAllocation']"
    [frontendPagination]="true"
  ></app-table>
</div>

<app-timesheet-input
  [openModalSubject]="openModalSubject"
  [userList]="selectedMembers"
  (modalSaveEmitter)="modalSave($event)"
></app-timesheet-input>
<app-success-modal #modalSuccess [modalConfig]="modalConfig">
  <div class="modal_css">Saved successfully!</div>
</app-success-modal>
<app-error-modal #modalError [modalConfig]="modalConfig">
  <div class="modal_css">Error while saving data.</div>
</app-error-modal>
