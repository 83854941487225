<div>
  <div class="d-flex align-items-center me-5 me-xl-13 mt-2 mb-2" style="padding-left: 35px">
    <div class="d-flex">
      <app-button
        [title]="'BACK' | translate | titlecase"
        style="margin-right: 15px"
        (handleClick)="back()"
      ></app-button>
    </div>
    <div class="symbol symbol-50px symbol-circle me-3">
      <img [src]="this.userData?.imageUrl" alt="user_img" />
    </div>
    <div *ngIf="userData?.firstName" class="m-0">
      <span class="fw-bold text-gray-800 text-hover-primary">{{ userData?.firstName! }} {{ userData?.lastName! }}</span>
      <span class="fw-semibold text-gray-400 d-block">{{ userData?.portfolio?.jobTitle! }}</span>
    </div>
    <div style="width: 300px; margin-left: 20px">
      <app-progress-bar
        *ngIf="usedAllocationInPercentage || daysLeft"
        [percentage]="usedAllocationInPercentage"
        [daysLeft]="daysLeft"
        [total]="total"
      ></app-progress-bar>
      <div *ngIf="!daysLeft" class="days_booked">
        <span>{{ usedAllocation || 0 | number : '1.2-2' }} {{ 'DAYS_BOOKED' | translate }}</span>
      </div>
    </div>
    <div class="flex-grow-1"></div>
    <div>
      <ng-container
        *ngIf="
          authService.currentUserRole !== 'PROJECT_BACKOFFICE' || authService.currentUserRole === 'PROJECT_BACKOFFICE'
        "
      >
        <app-button
          [title]="'ADD' | translate"
          buttonType="primary"
          class="button-timesheet"
          (click)="redirectToAnotherPageWithModalOpen()"
          prefixIcon="assets/media/icons/duotune/text/txt001.svg"
          style="height: 40px"
        ></app-button>
      </ng-container>
    </div>
  </div>
  <div class="table-wrapper">
    <app-table
      [customHeadings]="tableHeadings"
      *ngIf="tableData"
      [rowClickable]="true"
      [dataList]="tableData"
      [hiddenColumns]="['id', 'statusColor']"
      (rowClick)="redirectToAnotherPage($event)"
      [sortableColumns]="[]"
    ></app-table>
  </div>
</div>
<app-approve-timesheets-modal
  [openModalSubject$]="openModalApproveTimesheetsSubject$"
  (approveTimesheets)="approveTimesheets($event)"
></app-approve-timesheets-modal>
